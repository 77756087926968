import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import SideBar from "../other/SideBar";
import NavBar from "../other/NavBar";
import LoadingModal from "../../loader/LoadingModal";
import DeleteModal from "../../deleteModal/DeleteModal";
import printJS from "print-js";
import { useToast } from "../../../context/ToastContext";
import {
  GetLiveOrdersURL,
  UpdateLiveOrdersStatusURL,
  GetOrderDetailsURL,
  DeleteLiveOrderItemURL,
  ApplyLiveOrderDiscountURL,
  UpdateLiveOrderPaymentURL,
  UpdateLiveOrderQuantityURL,
  // GetProductListURL,
  AddNewItemInLiveOrderURL,
  ScriptId,
  ScriptSRC,
  SearchProductListURL,
  GetOrderKOTDetailsURL,
  LiveOrderNewPaymentURL,
  LiveOrderDuePaymentSubmitURl,
  orderWwhatssappMmassageHistoryURL,
  // CancelOrderURL,
} from "../../assets/api/BaseURL";
import Notification from "../other/Notification";

const LiveOrders = () => {
  const navigate = useNavigate();
  const { addToast } = useToast();
  const [token, setToken] = useState("");
  const [toggleSideBar, setToggleSideBar] = useState(false);
  const [canvas, setCanvas] = useState(false);
  const [currencySymbol, setCurrencySymbol] = useState("");
  const [data, setData] = useState([]);
  const [orderData, setOrderData] = useState({});
  // const [orderKOTData, setOrderKOTData] = useState({});
  const [orderID, setOrderID] = useState("");
  const [flag, setFlag] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isModal, setIsModal] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState();
  // const [productList, setProductList] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [filteredData, setFilteredData] = useState(null);
  const [showDropDown, setShowDropDown] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState({});
  // const [productId, setProductId] = useState("");
  const [quantity, setQuantity] = useState("1");
  const [price, setPrice] = useState("0");
  const [allOrders, setAllOrders] = useState(0);
  const [pendingOrders, setPendingOrders] = useState(0);
  const [acceptOrders, setAcceptOrders] = useState(0);
  const [readyOrders, setReadyOrders] = useState(0);
  // const [deliveredOrders, setDeliveredOrders] = useState(0);
  // const [rejectedOrders, setRejectedOrders] = useState(0);
  const [pendingOrdersData, setPendingOrdersData] = useState([]);
  const [acceptOrdersData, setAcceptOrdersData] = useState([]);
  const [readyOrdersData, setReadyOrdersData] = useState([]);
  // const [deliveredOrdersData, setDeliveredOrdersData] = useState([]);
  // const [rejectedOrdersData, setRejectedOrdersData] = useState([]);
  const [discountValues, setDiscountValues] = useState({
    type: "0",
    rate: "0",
  });

  const [subTotal, setSubTotal] = useState(0);
  const [SCHRate, setSCHRate] = useState(0);
  const [discountRate, setDiscountRate] = useState();
  const [CGSTRate, setCGSTRate] = useState(0);
  const [SGSTRate, setSGSTRate] = useState(0);
  const [discountAmount, setDiscountAmount] = useState();
  const [schAmount, setSchAmount] = useState();
  const [cgstAmount, setCGSTAmount] = useState();
  const [sgstAmount, setSGSTAmount] = useState();
  const [totalAmountPaid, setTotalAmountPaid] = useState();

  const [isKOTModal, setIsKOTModal] = useState(false);
  const [dataKOTDetails, setDataKOTDetails] = useState([]);
  const [printKOTDetails2, setPrintKOTDetails2] = useState(null);

  // const [isCancelOrderModal, setIsCancelOrderModal] = useState(false);
  // const [cancelReason, setCancelReason] = useState("");
  // const maxCharacterLimit = 200;



  const [whatsappApiAccessToken, setWhatsappApiAccessToken] = useState("");
  const [whatsappApiVendorUid, setWhatsappApiVendorUid] = useState("");
  const [whatsappMassageingApiBaseUrl, setWhatsappMassageingApiBaseUrl] = useState("");
  const [whatsappMassageingApiUrl, setWhatsappMassageingApiUrl] = useState("");
  const [fooderUpiId, setFooderUpiId] = useState("");
  const [sendWhatesappMessage, setSendWhatesappMessage] = useState("0");
  const [advertisementShow, setAdvertisementShow] = useState(false);




  const handleToggleSidebar = (e) => {
    e.preventDefault();
    setToggleSideBar(!toggleSideBar);
  };

  const handleToggleOffCanvas = (e) => {
    e.preventDefault();
    setCanvas(!canvas);
  };

  const formatTimestamp = (timestampStr) => {
    const timestamp = parseInt(timestampStr, 10);
    const dateObject = new Date(timestamp * 1000);

    const day = dateObject.getDate();
    const month = dateObject.toLocaleString("default", { month: "short" });
    const year = dateObject.getFullYear();
    let hours = dateObject.getHours();
    const minutes = dateObject.getMinutes();
    const ampm = hours >= 12 ? "pm" : "am";

    if (hours > 12) {
      hours -= 12;
    }

    return `${day} ${month} ${year}, ${hours}:${minutes
      .toString()
      .padStart(2, "0")} ${ampm}`;
  };

  const formatTimestampForDate = (timestampStr) => {
    const timestamp = parseInt(timestampStr, 10);
    const dateObject = new Date(timestamp * 1000);

    const day = dateObject.getDate();
    const month = dateObject.toLocaleString("default", { month: "short" });
    const year = dateObject.getFullYear();

    return `${day} ${month} ${year}`;
  };

  const formatTimestampForTime = (timestampStr) => {
    const timestamp = parseInt(timestampStr, 10);
    const dateObject = new Date(timestamp * 1000);

    let hours = dateObject.getHours();
    const minutes = dateObject.getMinutes();
    const ampm = hours >= 12 ? "pm" : "am";

    if (hours > 12) {
      hours -= 12;
    }

    return `${hours}:${minutes.toString().padStart(2, "0")} ${ampm}`;
  };

  const capitalizeFirstLetter = (str) => {
    if (str) {
      const words = str.split(" ");

      const capitalizedWords = words.map((word) => {
        if (word.length === 0) {
          return "";
        }
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      });

      const capitalizedString = capitalizedWords.join(" ");

      return capitalizedString;
    }
  };

  const isValidNumber = (value) => {
    return !isNaN(parseFloat(value)) && isFinite(value);
  };

  function isValidPrice(price) {
    const pricePattern = /^\d+(\.\d{1,2})?$/;

    if (!pricePattern.test(price)) {
      // return parseFloat(price).toFixed(2);
      return price;
    }
    return price;
  }

  const handlePrintBill = async () => {
    printJS({
      printable: "contentToPrintBill",
      type: "html",
      targetStyles: ["*"],
      font_size: '8pt'
    });
  };

  // const handlePrintKOT = async () => {
  //   printJS({
  //     printable: "contentToPrintKOT",
  //     type: "html",
  //     targetStyles: ["*"],
  //   });
  // };

  // const handleViewDetailsClick = (e, id) => {
  //   e.preventDefault();
  //   localStorage.setItem("orderID", id);
  //   navigate(`/order_details`);
  // };

  // const handlePrintKOTClick = async (id) => {
  //   try {
  //     const response = await axios.get(GetOrderDetailsURL, {
  //       params: {
  //         id: id,
  //       },
  //       headers: { authorization: `Bearer ${token}` },
  //     });

  //     if (response.status === 200 && response.data.data) {
  //       setOrderKOTData(response.data.data);
  //     }
  //   } catch (error) {
  //     if (error.response && error.response.data.message) {
  //       addToast("error", error.response.data.message);
  //     } else {
  //       addToast("error", error.message);
  //     }
  //     console.log(error);
  //   }
  // };

  const handlePrintKOTClick = async (id) => {
    try {
      const response = await axios.get(GetOrderKOTDetailsURL, {
        params: {
          order_id: id,
        },
        headers: { authorization: `Bearer ${token}` },
      });

      if (response.status === 200 && response.data.data) {
        setDataKOTDetails(response.data.data);
        setIsKOTModal(true);
      } else if (response.status === 200 && response.data.message) {
        addToast("success", response.data.message);
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        addToast("error", error.response.data.message);
      } else {
        addToast("error", error.message);
      }
      console.log(error);
    }
  };

  const handleViewDetailsClick = async (id) => {
    try {
      const response = await axios.get(GetOrderDetailsURL, {
        params: {
          id: id,
        },
        headers: { authorization: `Bearer ${token}` },
      });

      if (response.status === 200 && response.data.data) {
        // clearTaxData()


        setOrderID(id);
        setOrderData(response.data.data);

        if (response.data.data.payment_status === 1 || response.data.data.payment_status === 3) {
          setOnlySavePaymentRoundUpAmount(0)
        }

        if (response.data.data.partialPaymentDetail) {
          let amount = 0;

          response.data.data.partialPaymentDetail.forEach((i) => {
            if (i.paidAmount) {
              amount += parseFloat(i.paidAmount);
            }
          });

          setPartialPaymentDetailTotalAmount(amount);
        }
        if (
          response.data.data.service_charge_details.percentage !== "0" &&
          !isNaN(
            parseFloat(response.data.data.service_charge_details.percentage)
          ) &&
          isFinite(response.data.data.service_charge_details.percentage)
        ) {
          setSCHRate(
            parseFloat(response.data.data.service_charge_details.percentage)
          );
        }

        if (
          response.data.data.discount_rate !== "0" &&
          !isNaN(parseFloat(response.data.data.discount_rate)) &&
          isFinite(response.data.data.discount_rate)
        ) {
          setDiscountRate(parseFloat(response.data.data.discount_rate));
        } else {
          setDiscountRate();
        }

        if (
          response.data.data.tax_details &&
          response.data.data.tax_details.length !== 0
        ) {
          response.data.data.tax_details.forEach((item) => {
            if (item.name === "CGST") {
              setCGSTRate(parseFloat(item.percentage));
            } else if (item.name === "SGST") {
              setSGSTRate(parseFloat(item.percentage));
            }
          });
        }
        setIsVisible(false);
        setSelectedProduct({});
        setSearchInput("");
        setFilteredData(null);
        setShowDropDown(false);
        // setProductId("");
        setQuantity("1");
        setPrice("0");
        setIsModal(true);
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        addToast("error", error.response.data.message);
      } else {
        addToast("error", error.message);
      }
      console.log(error);
    }
  };

  const handleChangeOrderStatus = async (e, id, status, order_data) => {
    e.preventDefault();

    const data = { id: id, status: status };
    setIsLoading(true);
    try {
      const response = await axios({
        method: "put",
        url: UpdateLiveOrdersStatusURL,
        data: data,
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        if (isModal) {
          if (status === 4) {
            setIsModal(false);
          } else {
            handleViewDetailsClick(orderID);
          }
        }
        setFlag(!flag);

        if (sendWhatesappMessage === "1" || sendWhatesappMessage === 1) {
          if (status === 1) {
            sendOrderStatusChangeWhateappMassage2("Accept", order_data, id)
          }
          if (status === 2) {
            sendOrderStatusChangeWhateappMassage2("Ready & Delivered", order_data, id)
          }
          if (status === 4) {
            sendOrderStatusChangeWhateappMassage2("Reject", order_data, id)
          }
        }




      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        addToast("error", error.response.data.message);
      } else {
        addToast("error", error.message);
      }
      console.log(error);
    }
    setIsLoading(false);
  };

  const updateQuantityOrderData = (itemId, newQuantity) => {
    const updatedOrderData = { ...orderData };

    if (updatedOrderData && updatedOrderData.details) {
      updatedOrderData.details = updatedOrderData.details.map((order) => {
        if (order.id === itemId) {
          return { ...order, quantity: newQuantity };
        }
        return order;
      });
    }

    setOrderData(updatedOrderData);
    setIsVisible(true);
  };

  const handlePlusClick = (itemId) => {
    if (orderData && orderData.status === 0) {
      addToast("error", "Please accept the order first");
    } else if (orderData && orderData.payment_status === 1) {
      addToast("error", "Order already Paid");
    } else if (orderData && orderData.payment_status === 2) {
      addToast("error", "Order already Hold");
    } else if (orderData && orderData.payment_status === 3) {
      addToast("error", "Order already Partially Paid");
    } else {
      const orderItem = (orderData.details || []).find(
        (order) => order.id === itemId
      );

      if (orderItem) {
        const newQuantity = orderItem.quantity + 1;
        updateQuantityOrderData(itemId, newQuantity);
      }
    }
  };

  const handleMinusClick = (itemId) => {
    if (orderData && orderData.status === 0) {
      addToast("error", "Please accept the order first");
    } else if (orderData && orderData.payment_status === 1) {
      addToast("error", "Order already Paid");
    } else if (orderData && orderData.payment_status === 2) {
      addToast("error", "Order already Hold");
    } else if (orderData && orderData.payment_status === 3) {
      addToast("error", "Order already Partially Paid");
    } else {
      const orderItem = (orderData.details || []).find(
        (order) => order.id === itemId
      );

      if (orderItem && orderItem.quantity > 1) {
        const newQuantity = orderItem.quantity - 1;
        updateQuantityOrderData(itemId, newQuantity);
      }
    }
  };

  const handleDelete = (e, id) => {
    e.preventDefault();
    if (orderData && orderData.status === 0) {
      addToast("error", "Please accept the order first");
    } else if (orderData && orderData.payment_status === 1) {
      addToast("error", "Order already Paid");
    } else if (orderData && orderData.payment_status === 2) {
      addToast("error", "Order already Hold");
    } else if (orderData && orderData.payment_status === 3) {
      addToast("error", "Order already Partially Paid");
    } else if (orderData && !(orderData.details.length > 1)) {
      addToast("error", "Please add more items to delete this item!");
    } else {
      setDeleteId([id]);
      setShowDeleteModal(!showDeleteModal);
    }
  };

  const handleDeleteOrderItem = async () => {
    try {
      const response = await axios({
        method: "delete",
        url: DeleteLiveOrderItemURL,
        data: deleteId,
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 200 && response.data.message) {
        setFlag(!flag);
        setShowDeleteModal(!showDeleteModal);
        addToast("success", response.data.message);
        handleViewDetailsClick(orderID);
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        addToast("error", error.response.data.message);
      } else {
        addToast("error", error.message);
      }
      console.log(error);
    }
  };

  const handleDiscountValuesChange = (event) => {
    const { name, value } = event.target;

    setDiscountValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleApplyDiscountClick = async (e) => {
    e.preventDefault();
    if (orderData && orderData.status === 0) {
      addToast("error", "Please accept the order first");
    } else if (orderData && orderData.payment_status === 1) {
      addToast("error", "Order already Paid");
    } else if (subTotal === 0) {
      addToast("error", "Please add items first to apply discount");
    } else if (!isValidNumber(discountValues.rate)) {
      addToast("error", "Please enter a valid number");
    }
    else if (discountValues.type === "1" && (parseFloat(discountValues.rate) > subTotal)) {
      addToast("error", "Please enter a valid discount");
    }
    else if (discountValues.type === "0" && (discountValues.rate < 0 || discountValues.rate > 100)) {
      addToast("error", "Please enter a valid discount");
    } else if (
      discountValues.type === "0" &&
      parseInt(discountValues.rate) > 100
    ) {
      addToast("error", "Discount rate cannot exceed 100%");
    } else if (
      discountValues.type === "1" &&
      parseInt(discountValues.rate) > subTotal
    ) {
      addToast("error", "Discount rate cannot exceed subtotal");
    } else {
      const dataToSend = {
        id: orderID,
        discount_type: discountValues.type,
        discount_rate: discountValues.rate <= 0 ? 0 : discountValues.rate,
      };

      try {
        const response = await axios({
          method: "put",
          url: ApplyLiveOrderDiscountURL,
          data: dataToSend,
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${token}`,
          },
        });
        if (response.status === 200 && response.data.message) {
          addToast("success", response.data.message);
          handleViewDetailsClick(orderID);
          setFlag(!flag);
          setDiscountValues({
            type: "0",
            rate: "0",
          });
        }
      } catch (error) {
        if (error.response && error.response.data.message) {
          addToast("error", error.response.data.message);
        } else {
          addToast("error", error.message);
        }
        console.log(error);
      }
    }
  };

  const handlePaymentMethodClick = async (type) => {
    if (orderData && orderData.status === 0) {
      addToast("error", "Please accept the order first");
    } else {
      const dataToSend = {
        id: orderID,
        payment_type: type,
        payment_status: 1,
      };

      try {
        const response = await axios({
          method: "put",
          url: UpdateLiveOrderPaymentURL,
          data: dataToSend,
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${token}`,
          },
        });
        if (response.status === 200 && response.data.message) {
          addToast("success", response.data.message);
          setIsModal(false);
          setFlag(!flag);
        }
      } catch (error) {
        if (error.response && error.response.data.message) {
          addToast("error", error.response.data.message);
        } else {
          addToast("error", error.message);
        }
        console.log(error);
      }
    }
  };

  const handleUpdateQuantityClick = async () => {
    if (orderData && orderData.status === 0) {
      addToast("error", "Please accept the order first");
    } else if (orderData && orderData.payment_status === 1) {
      addToast("error", "Order already Paid");
    } else {
      const modifiedData = orderData.details.map((item) => ({
        id: item.id,
        quantity: item.quantity,
      }));

      try {
        const response = await axios({
          method: "put",
          url: UpdateLiveOrderQuantityURL,
          data: modifiedData,
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${token}`,
          },
        });

        if (response.status === 200 && response.data.message) {
          addToast("success", response.data.message);
          handleViewDetailsClick(orderID);
          setFlag(!flag);
        }
      } catch (error) {
        if (error.response && error.response.data.message) {
          addToast("error", error.response.data.message);
        } else {
          addToast("error", error.message);
        }
        console.log(error);
      }
    }
  };

  const handleAddNewItemClick = async (e) => {
    e.preventDefault();
    if (orderData && orderData.status === 0) {
      addToast("error", "Please accept the order first");
    } else if (orderData && orderData.payment_status === 1) {
      addToast("error", "Order already Paid");
    } else if (
      searchInput === "" ||
      !selectedProduct ||
      Object.keys(selectedProduct).length === 0
    ) {
      addToast("error", "Please select items first");
    } else if (!isValidNumber(quantity)) {
      addToast("error", "Please enter a valid quantity");
    } else if (quantity.trim() !== "" && quantity.trim() === "0") {
      addToast("error", "Quantity should be greater than 0");
    } else if (orderData.order_mode_label == "POS" || orderData.order_mode_label == "MOBILE POS") {
      addToast("error", "Add item only on POS");
    } else {
      let item;

      if (orderData.order_type === "dine_in") {
        item = {
          ...selectedProduct,
          quantity: quantity,
          order_type: orderData.order_type,
          fooder_id: orderData.fooder_id,
          fooder_name: orderData.fooders_name,
          table_id: orderData.table_id,
          product_special_note: "",
        };
      } else {
        item = {
          ...selectedProduct,
          quantity: quantity,
          order_type: orderData.order_type,
          fooder_id: orderData.fooder_id,
          fooder_name: orderData.fooders_name,
          product_special_note: "",
        };
      }

      const dataToSend = {
        id: orderID,
        eater_phonenumber: orderData.eater_phonenumber,
        item: item,
      };

      try {
        const response = await axios({
          method: "post",
          url: AddNewItemInLiveOrderURL,
          data: dataToSend,
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${token}`,
          },
        });
        if (response.status === 200 && response.data.message) {
          addToast("success", response.data.message);
          setSelectedProduct({});
          setSearchInput("");
          setFilteredData(null);
          setShowDropDown(false);
          // setProductId("");
          setQuantity("1");
          setPrice("0");
          handleViewDetailsClick(orderID);
          setFlag(!flag);
        }
      } catch (error) {
        if (error.response && error.response.data.message) {
          addToast("error", error.response.data.message);
        } else {
          addToast("error", error.message);
        }
        console.log(error);
      }
    }
  };

  // const handleCancelOrderReasonChange = (e) => {
  //   const inputValue = e.target.value;

  //   if (inputValue.length <= maxCharacterLimit) {
  //     setCancelReason(inputValue);
  //   }
  // };

  // const handleCancelOrderClick = (item) => {
  //   setCancelReason("");
  //   setIsCancelOrderModal(true);
  // };

  // const handleCancelOrder = async (e) => {
  //   e.preventDefault();

  //   if (cancelReason === "" || cancelReason.trim() === "") {
  //     addToast("error", "Please enter reason first");
  //   } else {
  //     const dataToSend = {
  //       order_id: orderID,
  //       cancelled_reason: cancelReason,
  //     };

  //     try {
  //       const response = await axios({
  //         method: "put",
  //         url: CancelOrderURL,
  //         data: dataToSend,
  //         headers: {
  //           "Content-Type": "application/json",
  //           authorization: `Bearer ${token}`,
  //         },
  //       });

  //       if (response.status === 200 && response.data.message) {
  //         addToast("success", response.data.message);
  //         setIsCancelOrderModal(false);
  //         setIsModal(false);
  //         setFlag(!flag);
  //       }
  //     } catch (error) {
  //       if (error.response && error.response.data.message) {
  //         addToast("error", error.response.data.message);
  //       } else {
  //         addToast("error", error.message);
  //       }
  //       console.log(error);
  //     }
  //   }
  // };

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/");
    }
  }, [navigate]);

  useEffect(() => {
    setToken(localStorage.getItem("token"));
    if (localStorage.getItem('round_off_amount') === "1") {
      setOnlySavePaymentRoundUpCheck(true)
    }
    setWhatsappApiAccessToken(localStorage.getItem("whatsapp_api_access_token"));
    setWhatsappApiVendorUid(localStorage.getItem("whatsapp_vendor_uid"));
    setWhatsappMassageingApiBaseUrl(localStorage.getItem("whatsapp_massageing_api_base_url"));
    setWhatsappMassageingApiUrl(localStorage.getItem("whatsapp_massageing_api_url"));
    setSendWhatesappMessage(localStorage.getItem("whatesapp_message"));



    const advertisement_name = localStorage.getItem("advertisement_name");

    if (advertisement_name) {
      if (advertisement_name !== "whatsapp_marketing") {
        setAdvertisementShow(true);
      }
    } else {
      setAdvertisementShow(true);
      localStorage.setItem("advertisement_name", "whatsapp_marketing")
    }



    if (localStorage.getItem("upi_address")) {
      setFooderUpiId(localStorage.getItem("upi_address"));
    }
  }, [token]);

  useEffect(() => {
    const existingScript = document.getElementById(ScriptId);
    if (!existingScript && localStorage.getItem("userId")) {
      const userId = localStorage.getItem("userId");
      const script = document.createElement("script");
      script.src = ScriptSRC + userId.replace(/"/g, "");
      script.defer = true;
      script.id = ScriptId;
      document.head.appendChild(script);
    }
  }, []);

  useEffect(() => {
    if (localStorage.getItem("currencySymbol")) {
      setCurrencySymbol(localStorage.getItem("currencySymbol"));
    }
  }, []);

  // useEffect(() => {
  //   if (token) {
  //     async function getProductData() {
  //       try {
  //         const response = await axios.get(GetProductListURL, {
  //           headers: { authorization: `Bearer ${token}` },
  //         });

  //         if (response.status === 200 && response.data.data) {
  //           setProductList(response.data.data);
  //         }
  //       } catch (error) {
  //         if (error.response && error.response.data.message) {
  //           addToast("error", error.response.data.message);
  //         } else {
  //           addToast("error", error.message);
  //         }
  //         console.log(error);
  //       }
  //     }
  //     getProductData();
  //   }
  // }, [token]);

  useEffect(() => {
    if (token) {
      async function getData() {
        setIsLoading(true);
        try {
          const response = await axios.get(GetLiveOrdersURL, {
            headers: { authorization: `Bearer ${token}` },
          });

          if (response.status === 200 && response.data.data) {
            setData(response.data.data);
          } else if (response.status === 200 && response.data.message) {
            setData([]);
          }
        } catch (error) {
          if (error.response && error.response.data.message) {
            addToast("error", error.response.data.message);
          } else {
            addToast("error", error.message);
          }
          console.log(error);
        }
        setIsLoading(false);
      }
      getData();
    }
  }, [token, flag, addToast]);

  useEffect(() => {
    if (data) {
      const calculateOrderNumbers = () => {
        let pendingCount = 0;
        let acceptCount = 0;
        let readyCount = 0;
        // let deliveredCount = 0;
        // let rejectedCount = 0;

        data.forEach((item) => {
          switch (item.status) {
            case 0:
              pendingCount++;
              break;
            case 1:
              acceptCount++;
              break;
            case 2:
              readyCount++;
              break;
            // case 3:
            //   deliveredCount++;
            //   break;
            // case 4:
            //   rejectedCount++;
            //   break;
            default:
              break;
          }
        });

        setAllOrders(data.length);
        setPendingOrders(pendingCount);
        setAcceptOrders(acceptCount);
        setReadyOrders(readyCount);
        // setDeliveredOrders(deliveredCount);
        // setRejectedOrders(rejectedCount);
      };
      calculateOrderNumbers();
    }
  }, [data]);

  useEffect(() => {
    if (data) {
      const filterDataByStatus = () => {
        const pendingOrders = [];
        const acceptOrders = [];
        const readyOrders = [];
        // const deliveredOrders = [];
        // const rejectedOrders = [];

        data.forEach((item) => {
          switch (item.status) {
            case 0:
              pendingOrders.push(item);
              break;
            case 1:
              acceptOrders.push(item);
              break;
            case 2:
              readyOrders.push(item);
              break;
            // case 3:
            //   deliveredOrders.push(item);
            //   break;
            // case 4:
            //   rejectedOrders.push(item);
            //   break;
            default:
              break;
          }
        });

        setPendingOrdersData(pendingOrders);
        setAcceptOrdersData(acceptOrders);
        setReadyOrdersData(readyOrders);
        // setDeliveredOrdersData(deliveredOrders);
        // setRejectedOrdersData(rejectedOrders);
      };
      filterDataByStatus();
    }
  }, [data]);



  const [cartProductPackingCharges, setCartProductPackingCharges] = useState(0);

  const [CGSTTaxT1, setCGSTTaxt1] = useState(0);
  const [CGSTTaxT2, setCGSTTaxt2] = useState(0);
  const [CGSTTaxT3, setCGSTTaxt3] = useState(0);
  const [CGSTTaxT4, setCGSTTaxt4] = useState(0);
  const [CGSTTaxT5, setCGSTTaxt5] = useState(0);


  const [SGSTTaxT1, setSGSTTaxt1] = useState(0);
  const [SGSTTaxT2, setSGSTTaxt2] = useState(0);
  const [SGSTTaxT3, setSGSTTaxt3] = useState(0);
  const [SGSTTaxT4, setSGSTTaxt4] = useState(0);
  const [SGSTTaxT5, setSGSTTaxt5] = useState(0);


  const clearTaxData = () => {

    setCGSTTaxt1(0)
    setCGSTTaxt2(0)
    setCGSTTaxt3(0)
    setCGSTTaxt4(0)
    setCGSTTaxt5(0)

    setSGSTTaxt1(0)
    setSGSTTaxt2(0)
    setSGSTTaxt3(0)
    setSGSTTaxt4(0)
    setSGSTTaxt5(0)


  }
  useEffect(() => {
    // if (orderData && orderData.details) {
    //   const subtotal = orderData.details.reduce(
    //     (total, item) =>
    //       total +
    //       (item.product_proprice
    //         ? item.product_proprice * item.quantity
    //         : item.product_price * item.quantity),
    //     0
    //   );

    //   setSubTotal(parseFloat(subtotal).toFixed(2));

    //   let discountedAmount = 0;
    //   let discount = 0;
    //   let deliveryCharge = 0;
    //   let packagingFee = 0;

    //   if (orderData.discount_type === "percent") {
    //     discount =
    //       (parseFloat(subtotal).toFixed(2) * (discountRate || 0)) / 100;
    //     discountedAmount = parseFloat(subtotal).toFixed(2) - discount;
    //   } else if (orderData.discount_type === "amount") {
    //     discount = parseFloat(discountRate) || 0;
    //     discountedAmount = parseFloat(subtotal).toFixed(2) - discount;
    //   }

    //   const sch = (discountedAmount * (SCHRate || 0)) / 100;
    //   const taxableAmount = discountedAmount + sch;

    //   const cgst = (taxableAmount * (CGSTRate || 0)) / 100;
    //   const sgst = (taxableAmount * (SGSTRate || 0)) / 100;

    //   if (
    //     orderData &&
    //     orderData.delivery_charge &&
    //     parseFloat(orderData.delivery_charge) > 0
    //   ) {
    //     deliveryCharge = parseFloat(orderData.delivery_charge).toFixed(2);
    //   }

    //   if (
    //     orderData &&
    //     orderData.packaging_fee &&
    //     parseFloat(orderData.packaging_fee) > 0
    //   ) {
    //     packagingFee = parseFloat(orderData.packaging_fee).toFixed(2);
    //   }

    //   const totalPaid =
    //     parseFloat(taxableAmount) +
    //     parseFloat(cgst) +
    //     parseFloat(sgst) +
    //     parseFloat(deliveryCharge) +
    //     parseFloat(packagingFee);

    //   setDiscountAmount(parseFloat(discount).toFixed(2));
    //   setSchAmount(parseFloat(sch).toFixed(2));
    //   setCGSTAmount(parseFloat(cgst).toFixed(2));
    //   setSGSTAmount(parseFloat(sgst).toFixed(2));
    //   setTotalAmountPaid(parseFloat(totalPaid).toFixed(2));
    // }










    if (orderData.details) {

      clearTaxData()

      let withOutTaxPrice = 0
      let subTotal = 0;
      let tempDiscount = 0;
      let tempDiscountRow = 0;


      let tempServicCharge = 0;
      let tempServicChargeRow = 0;

      let tempTax = 0;
      let tempTaxRow = 0;

      let GSTTaxT1 = 0;
      let GSTTaxT2 = 0;
      let GSTTaxT3 = 0;
      let GSTTaxT4 = 0;
      let GSTTaxT5 = 0;

      let packingCharges = 0;


      //  for flate amount discount

      let withOutTaxPriceForAmount = 0
      let subTotalForAmount = 0;
      let discountRateForAmount = 0;

      if (orderData.discount_type === "amount") {
        orderData.details.forEach((i) => {
          if (i.product_proprice) {
            withOutTaxPriceForAmount = parseInt(i.item_tax_type) === 0 ? parseFloat(i.product_proprice) : (parseFloat(i.product_proprice) * parseFloat(100)) / (parseFloat(100) + parseFloat(i.item_tax_percent))
          } else {
            withOutTaxPriceForAmount = parseInt(i.item_tax_type) === 0 ? parseFloat(i.product_price) : (parseFloat(i.product_price) * parseFloat(100)) / (parseFloat(100) + parseFloat(i.item_tax_percent))
          }
          subTotalForAmount += (i.quantity) * withOutTaxPriceForAmount
        })
        discountRateForAmount = (parseFloat(orderData.discount_rate) * 100) / subTotalForAmount
      }
      // end


      orderData.details.forEach((i) => {


        // newPrice: selectedProduct.proprice
        // ? selectedProduct.proprice
        // : selectedProduct.price,

        packingCharges += i.quantity * parseFloat(i.packaging_fee)

        if (i.product_proprice) {
          withOutTaxPrice = parseInt(i.item_tax_type) === 0 ? parseFloat(i.product_proprice) : (parseFloat(i.product_proprice) * parseFloat(100)) / (parseFloat(100) + parseFloat(i.item_tax_percent))

        } else {
          withOutTaxPrice = parseInt(i.item_tax_type) === 0 ? parseFloat(i.product_price) : (parseFloat(i.product_price) * parseFloat(100)) / (parseFloat(100) + parseFloat(i.item_tax_percent))
        }


        subTotal += (i.quantity) * withOutTaxPrice

        if (orderData.discount_type === "percent") {
          tempDiscount += (((i.quantity) * withOutTaxPrice) * parseFloat(orderData.discount_rate)) / 100
          tempDiscountRow = (((i.quantity) * withOutTaxPrice) * parseFloat(orderData.discount_rate)) / 100
        } else {
          // tempDiscount += (((i.quantity) * withOutTaxPrice) - parseFloat(orderData.discount_rate))
          // tempDiscountRow = (((i.quantity) * withOutTaxPrice) - parseFloat(orderData.discount_rate))
          // tempDiscount +=  ((i.quantity * withOutTaxPrice) - ((i.quantity * withOutTaxPrice) - parseFloat(orderData.discount_rate))) * i.quantity
          // tempDiscountRow = ((i.quantity * withOutTaxPrice) - ((i.quantity * withOutTaxPrice) - parseFloat(orderData.discount_rate)))* i.quantity
          tempDiscount += (((i.quantity) * withOutTaxPrice) * parseFloat(discountRateForAmount)) / 100
          tempDiscountRow = (((i.quantity) * withOutTaxPrice) * parseFloat(discountRateForAmount)) / 100
        }


        tempServicCharge += ((((i.quantity) * withOutTaxPrice) - tempDiscountRow) * parseFloat(SCHRate)) / 100
        tempServicChargeRow = ((((i.quantity) * withOutTaxPrice) - tempDiscountRow) * parseFloat(SCHRate)) / 100



        tempTax += ((((i.quantity) * withOutTaxPrice) + tempServicChargeRow - tempDiscountRow) * parseFloat(i.item_tax_percent)) / 100
        tempTaxRow = ((((i.quantity) * withOutTaxPrice) + tempServicChargeRow - tempDiscountRow) * parseFloat(i.item_tax_percent)) / 100

        if (parseInt(i.item_tax_percent) === 0) {

          GSTTaxT1 += ((((i.quantity) * withOutTaxPrice) + tempServicChargeRow - tempDiscountRow) * parseFloat(i.item_tax_percent)) / 100
          setCGSTTaxt1(GSTTaxT1 / 2)
          setSGSTTaxt1(GSTTaxT1 / 2)

        }

        if (parseInt(i.item_tax_percent) === 5) {

          GSTTaxT2 += ((((i.quantity) * withOutTaxPrice) + tempServicChargeRow - tempDiscountRow) * parseFloat(i.item_tax_percent)) / 100

          setCGSTTaxt2((GSTTaxT2 / 2).toFixed(2))
          setSGSTTaxt2((GSTTaxT2 / 2).toFixed(2))
        }

        if (parseInt(i.item_tax_percent) === 12) {

          GSTTaxT3 += ((((i.quantity) * withOutTaxPrice) + tempServicChargeRow - tempDiscountRow) * parseFloat(i.item_tax_percent)) / 100

          setCGSTTaxt3((GSTTaxT3 / 2).toFixed(2))
          setSGSTTaxt3((GSTTaxT3 / 2).toFixed(2))
        }

        if (parseInt(i.item_tax_percent) === 18) {

          GSTTaxT4 += ((((i.quantity) * withOutTaxPrice) + tempServicChargeRow - tempDiscountRow) * parseFloat(i.item_tax_percent)) / 100
          setCGSTTaxt4((GSTTaxT4 / 2).toFixed(2))
          setSGSTTaxt4((GSTTaxT4 / 2).toFixed(2))
        }

        if (parseInt(i.item_tax_percent) === 28) {

          GSTTaxT5 += ((((i.quantity) * withOutTaxPrice) + tempServicChargeRow - tempDiscountRow) * parseFloat(i.item_tax_percent)) / 100
          setCGSTTaxt5((GSTTaxT5 / 2).toFixed(2))
          setSGSTTaxt5((GSTTaxT5 / 2).toFixed(2))
        }



      })
      setSubTotal(subTotal.toFixed(2))
      setDiscountAmount(tempDiscount.toFixed(2))
      setSchAmount(tempServicCharge.toFixed(2))
      setTotalAmountPaid((subTotal + tempServicCharge - tempDiscount + tempTax + orderData.round_up_amount).toFixed(2))

      if (orderData.order_type !== "dine_in") {
        // setCartProductPackingCharges(packingCharges)
        setCartProductPackingCharges(packingCharges.toFixed(2))
        setTotalAmountPaid((parseFloat(subTotal + tempServicCharge - tempDiscount + tempTax + orderData.round_up_amount) + parseFloat(packingCharges)).toFixed(2))
      }
    }





















  }, [orderData, discountRate, SCHRate, CGSTRate, SGSTRate]);

  // useEffect(() => {
  //   if (productList && productId) {
  //     const item = productList.filter(
  //       (item) => item.product_id === parseInt(productId)
  //     );

  //     setSelectedProduct(item[0]);
  //   }
  // }, [productList, productId]);

  useEffect(() => {
    if (selectedProduct) {
      setPrice(
        selectedProduct.product_proprice
          ? selectedProduct.product_proprice
          : selectedProduct.product_price
      );
    }
  }, [selectedProduct]);

  useEffect(() => {
    const handleSearchChange = async () => {
      const searchTerm = searchInput.toLowerCase();

      if (searchTerm.length >= 3) {
        try {
          const response = await axios.get(SearchProductListURL, {
            params: {
              name: searchTerm,
            },
            headers: { authorization: `Bearer ${token}` },
          });

          if (response.status === 200 && response.data.data) {
            setFilteredData(response.data.data);
          } else if (response.status === 200 && response.data.message) {
            setFilteredData([]);
          }
        } catch (error) {
          if (error.response && error.response.data.message) {
            addToast("error", error.response.data.message);
          } else {
            addToast("error", error.message);
          }
          console.log(error);
        }
      } else {
        setFilteredData(null);
      }
    };
    handleSearchChange();
  }, [token, searchInput, addToast]);

  // useEffect(() => {
  //   if (orderKOTData && orderKOTData.details) {
  //     printJS({
  //       printable: "contentToPrintKOTForLiveOrder",
  //       type: "html",
  //       targetStyles: ["*"],
  //     });

  //     setOrderKOTData({});
  //   }
  // }, [orderKOTData]);

  useEffect(() => {
    if (printKOTDetails2) {
      const handlePrintKOT = async () => {
        if (printKOTDetails2) {
          printJS({
            printable: "contentToPrintKOT2",
            type: "html",
            targetStyles: ["*"],
          });

          setPrintKOTDetails2(null);
        }
      };
      handlePrintKOT();
    }
  }, [printKOTDetails2]);


  const [partialPaymentDetailModel, setPartialPaymentDetailModel] = useState(false);

  const [partialPaymentDetailTotalAmount, setPartialPaymentDetailTotalAmount] = useState(0);








  const [partialPaymentModel, setPartialPaymentlModel] = useState(false);


  const [selectedPaymentOption, setSelectedPaymentOption] = useState("Cash");


  const [onlySavePaymentPaidedAmount, setOnlySavePaymentPaidedAmount] = useState(0);
  const [onlySavePaymentTotalPaidedAmount, setOnlySavePaymentTotalPaidedAmount] = useState(0);


  const [onlySavePaymentTipAmount, setOnlySavePaymentTipAmount] = useState(0);
  const [onlySavePaymentTipTotalAmount, setOnlySavePaymentTipTotalAmount] = useState(0);

  const [onlySavePaymentNotes, setOnlySavePaymentNotes] = useState("");


  const [onlySavePaymentUPIMode, setOnlySavePaymentUPIMode] = useState("");
  const [onlySavePaymentUPIModeOther, setOnlySavePaymentUPIOther] = useState("");



  const [paymentSettleTransactionId, setPaymentSettleTransactionId] = useState("")

  const [onlySavePaymentData, setSavePaymentData] = useState([]);

  const [onlySavePaymentRoundUpAmount, setOnlySavePaymentRoundUpAmount] = useState(0);
  const [onlySavePaymentRoundUpCheck, setOnlySavePaymentRoundUpCheck] = useState(false);

  const handlePaymentOptionChange = (event) => {

    setSelectedPaymentOption(event.target.value);

    setOnlySavePaymentUPIOther("")
    setOnlySavePaymentUPIMode("")

    // setUnpaidReason("");
    // setPaymentSettleTransactionId("");
  };
  const [submitpartialPaymentButtonDisable, setSubmitpartialPaymentButtonDisable] = useState(false)


  const submitpartialPayment = async (type) => {

    const modifiedDetails = orderData.details.map((item) => {
      const { id, product_name, ...rest } = item;
      return {
        product_id: id,
        product_name: product_name,
      };
    });

    const dataToSend = {

      id: orderID,
      payment_details: onlySavePaymentData,
      due_amount: parseFloat(totalAmountPaid - onlySavePaymentTotalPaidedAmount).toFixed(2),
      paided_amount: parseFloat(onlySavePaymentTotalPaidedAmount).toFixed(2),
      total: parseFloat(totalAmountPaid).toFixed(2),
      // payment_status: parseFloat(totalAmountPaid - onlySavePaymentTotalPaidedAmount).toFixed(2) > 0 ? 3 : 1,
      payment_status: parseFloat(totalAmountPaid - onlySavePaymentTotalPaidedAmount + onlySavePaymentRoundUpAmount).toFixed(2) > 0 ? 3 : 1,
      round_up_amount: onlySavePaymentRoundUpAmount,
      item: modifiedDetails,
      discountRate: orderData.discount_rate,
      discountType: orderData.discount_type === "amount" ? 1 : 0,


    };
    setSubmitpartialPaymentButtonDisable(true)

    try {

      const response = await axios({
        method: "put",
        url: LiveOrderNewPaymentURL,
        data: dataToSend,
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 200) {
        setFlag(!flag);

        // Bill Settlement
        handleViewDetailsClick(orderID)

        addToast("success", response.data.message);
        setPartialPaymentlModel(false)
        // setOnlySavePaymentRoundUpCheck(false)
        // setOnlySavePaymentRoundUpAmount(0)

      }
      setSubmitpartialPaymentButtonDisable(false)

    } catch (error) {
      if (error.response && error.response.data.message) {
        addToast("error", error.response.data.message);
      } else {
        addToast("error", error.message);
      }
      console.log(error);
      setSubmitpartialPaymentButtonDisable(false)

    }
  };



  // const isValidNumber = (value) => {
  //   return !isNaN(parseFloat(value)) && isFinite(value);
  // };



  // useEffect(() => {
  //   if (onlySavePaymentRoundUpCheck) {
  //   } else {
  //     setOnlySavePaymentRoundUpAmount(0)
  //     setOnlySavePaymentPaidedAmount(parseFloat(totalAmountPaid - onlySavePaymentTotalPaidedAmount).toFixed(2))
  //   }
  // }, [onlySavePaymentRoundUpCheck]);

  // const handleRoundupAmountCheck = async (e) => {
  //   // alert(totalAmountPaid)
  //   var res = getDecimalPart(totalAmountPaid)
  //   // alert(res)
  //   console.log(e.target.checked)
  //   if (e.target.checked) {
  //     // setOnlySavePaymentRoundUpFixAmount(res)
  //     setOnlySavePaymentRoundUpAmount(res)
  //     setOnlySavePaymentPaidedAmount(parseFloat(totalAmountPaid) + res)
  //     setOnlySavePaymentRoundUpCheck(true)

  //   } else {

  //     setOnlySavePaymentRoundUpCheck(false)
  //   }

  // }

  function getDecimalPart(number) {
    // Extract the integer part using Math.floor() and subtract it from the original number
    const integerPart = Math.floor(number);
    let decimalPart = number - integerPart;
    // decimalPart = decimalPart.toFixed(2)
    if (decimalPart < 0.5) {
      return -decimalPart
    }
    else {
      return 1 - decimalPart
    }
  }

  const handleOnlySavePayment = async (e) => {
    let tempOnlySavePaymentRoundUpAmount = onlySavePaymentRoundUpAmount


    if (selectedPaymentOption === "UPI" && onlySavePaymentUPIMode === '') {

      addToast("error", "Select upi method");

    }
    else if (!isValidNumber(onlySavePaymentPaidedAmount)) {

      addToast("error", "Please enter valid Paid amount");

    }
    else if (!isValidNumber(onlySavePaymentTipAmount) && onlySavePaymentTipAmount !== '') {
      addToast("error", "Please enter valid Tip amount");
    }

    else if ((parseFloat(onlySavePaymentPaidedAmount)).toFixed(2) > parseFloat(totalAmountPaid - onlySavePaymentTotalPaidedAmount) + (tempOnlySavePaymentRoundUpAmount) + 0.00001) {
      addToast("error", "Amount cannot be greater than Total Amount");
    }
    else {
      setSavePaymentData([...onlySavePaymentData, {
        method: selectedPaymentOption,
        amount: parseFloat(onlySavePaymentPaidedAmount).toFixed(2),
        tip: onlySavePaymentTipAmount ? parseFloat(onlySavePaymentTipAmount).toFixed(2) : 0,
        upiType: onlySavePaymentUPIModeOther === "Other" ? onlySavePaymentUPIModeOther : onlySavePaymentUPIMode,
        note: onlySavePaymentNotes,
        transaction_id: paymentSettleTransactionId
      }])
      let temp = parseFloat(onlySavePaymentTotalPaidedAmount) + parseFloat(onlySavePaymentPaidedAmount)
      setOnlySavePaymentTotalPaidedAmount(temp)

      // setOnlySavePaymentPaidedAmount(totalAmountPaid - temp > 0 ? (totalAmountPaid - temp).value.toFixed(2) : 0)
      // setOnlySavePaymentPaidedAmount(totalAmountPaid - temp > 0 ? parseFloat(totalAmountPaid - temp).toFixed(2) : 0)
      setOnlySavePaymentPaidedAmount(totalAmountPaid - temp > 0 ? parseFloat((totalAmountPaid - temp) + tempOnlySavePaymentRoundUpAmount).toFixed(2) : 0)


      let tempTip = onlySavePaymentTipAmount + onlySavePaymentTipTotalAmount
      setOnlySavePaymentTipTotalAmount(tempTip)
      setOnlySavePaymentTipAmount(0)
      setOnlySavePaymentNotes("")
      setPaymentSettleTransactionId("")
      setOnlySavePaymentUPIOther("")
      setOnlySavePaymentUPIMode("")
    }




    // console.log(onlySavePaymentData)


  }

  const handleOnlySavePaymentRemove = async (i) => {

    let tempArray = onlySavePaymentData.filter((item, index) => index === i);
    // console.log(tempArray)

    // setOnlySavePaymentPaidedAmount((parseFloat(totalAmountPaid) - parseFloat(onlySavePaymentTotalPaidedAmount) + parseFloat(tempArray[0].amount)).toFixed(2))
    setOnlySavePaymentPaidedAmount((parseFloat(totalAmountPaid) - parseFloat(onlySavePaymentTotalPaidedAmount) + parseFloat(tempArray[0].amount) + onlySavePaymentRoundUpAmount).toFixed(2))


    let temp = parseFloat(onlySavePaymentTotalPaidedAmount) - parseFloat(tempArray[0].amount)
    setOnlySavePaymentTotalPaidedAmount(temp)



    let myArray = onlySavePaymentData.filter((item, index) => index !== i);
    setSavePaymentData(myArray)





  }


  useEffect(() => {
    // setOnlySavePaymentPaidedAmount(totalAmountPaid - onlySavePaymentTotalPaidedAmount)
    // setOnlySavePaymentPaidedAmount(totalAmountPaid)
    // setOnlySavePaymentPaidedAmount((parseFloat(totalAmountPaid) + parseFloat(getDecimalPart(totalAmountPaid))).toFixed(2))
    if (onlySavePaymentRoundUpCheck && (orderData.payment_status === 0 || orderData.payment_status === 2)) {
      setOnlySavePaymentPaidedAmount((parseFloat(totalAmountPaid) + parseFloat(getDecimalPart(totalAmountPaid))).toFixed(2))
    } else {
      setOnlySavePaymentPaidedAmount(totalAmountPaid)
    }

    setSavePaymentData([]);

    setOnlySavePaymentTotalPaidedAmount(0)
    setOnlySavePaymentTipAmount(0)
    setOnlySavePaymentNotes("")
    setOnlySavePaymentTipTotalAmount(0)
    setOnlySavePaymentUPIOther("")
    setOnlySavePaymentUPIMode("")

    if (onlySavePaymentRoundUpCheck && (orderData.payment_status === 0 || orderData.payment_status === 2)) {
      setOnlySavePaymentRoundUpAmount(getDecimalPart(totalAmountPaid))
    }



  }, [totalAmountPaid]);




  const [showDuePaymentMode, setShowDuePaymentMode] = useState(false);
  const [disabledDuePaymentButton, setDisabledDuePaymentButton] = useState(false);





  const submitDuePayment = async () => {

    const modifiedDetails = orderData.details.map((item) => {
      const { id, product_name, ...rest } = item;
      return {
        product_id: id,
        product_name: product_name,
      };
    });

    const dataToSend = {

      id: orderID,
      payment_details: {
        method: selectedPaymentOption,
        amount: parseFloat(totalAmountPaid - partialPaymentDetailTotalAmount).toFixed(2),
        upiType: onlySavePaymentUPIModeOther === "Other" ? onlySavePaymentUPIModeOther : onlySavePaymentUPIMode,
        note: "",
        transaction_id: ""
      },
      due_amount: parseFloat(totalAmountPaid - partialPaymentDetailTotalAmount).toFixed(2),
      total: parseFloat(totalAmountPaid).toFixed(2),
      payment_status: 1,
      item: modifiedDetails,
      allPaymentDetail: orderData.partialPaymentDetail


    };


    try {

      setDisabledDuePaymentButton(true)

      const response = await axios({
        method: "put",
        url: LiveOrderDuePaymentSubmitURl,
        data: dataToSend,
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 200) {
        resetPaymentDetails()
        // setFlag(!flag);
        handleViewDetailsClick(orderID)


        addToast("success", response.data.message);
        setPartialPaymentlModel(false)

      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        addToast("error", error.response.data.message);
      } else {
        addToast("error", error.message);
      }
      console.log(error);
    }
  };






  const sendOrderStatusChangeWhateappMassage2 = async (order_status, order_data, oid) => {
    let itemsText = ""
    let details = order_data.details

    console.log(typeof (order_data.details))


    if (typeof (order_data.details) === 'string') {
      details = JSON.parse(order_data.details)
    }

    details.forEach((item, index) => {
      itemsText += index + 1 + ". " + item.product_name + " x " + item.quantity + "\\n"
    });


    if (order_data.eater_phonenumber) {


      setIsLoading(true);

      try {
        const response = await axios({
          method: "post",
          url: whatsappMassageingApiUrl + '/contact/send-template-message',
          data: {
            phone_number: order_data.eater_phonenumber,
            template_name: "update_order_status"	,
            template_language: "en",
            header_document_name: "Order # Placed Successfully",
            header_field_1: order_status,
            field_1: order_data.order_number_qrcode,
            field_2: capitalizeFirstLetter(localStorage.getItem('restaurantName')),
            field_3: itemsText,
            // if you want to create contact if it does not exist
            contact: {
              first_name: order_data.eater_name ? order_data.eater_name : "no name",

              last_name: "_",
              country: "india",
              language_code: "en",
              groups: ""
            }
          },
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${whatsappApiAccessToken}`,
          },
        });

        await axios({
          method: "post",
          url: orderWwhatssappMmassageHistoryURL,
          data: {
            order_id: oid,
            mobile: order_data.eater_phonenumber,
            templats_name: "update_order_status"	,
            w_data: response.data,

          },
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${token}`,
          },
        });
        if (response.data.result) {
          addToast("success", "Message send successfully");
        }


        setIsLoading(false);

      } catch (error) {
        if (error.response && error.response.data.message) {
          addToast("error", error.response.data.message);
        } else {
          addToast("error", error.message);
        }
        console.log(error);
      }
      setIsLoading(false);
    }
  }


  const sendCreateOrderWhateappMassage = async () => {
    // console.log("1. Butter x 1\n2. Chicken Biryani x 1\n3. Palak Paneer x 1\n4. Chole Bhature x 1\n5. Samosa x 1\n6. Dosa x 1\n7. Naan x 1\n8. Aloo Gobi x 1\n9. Rogan Josh x 1")
    let itemsText = ""
    orderData.details.forEach((item, index) => {
      itemsText += index + 1 + ". " + item.product_name + " x " + item.quantity + "\\n"
    });
    setIsLoading(true);

    try {
      const response = await axios({
        method: "post",
        url: whatsappMassageingApiUrl + '/contact/send-template-message',
        data: {
          phone_number: orderData.eater_phonenumber,
          template_name: "order_create",
          template_language: "en",
          header_document_name: "Order # Placed Successfully",
          header_field_1: orderData.order_number_qrcode,
          field_1: orderData.order_number_qrcode,
          field_2: capitalizeFirstLetter(orderData.fooders_name),
          field_3: itemsText,
          // if you want to create contact if it does not exist
          contact: {
            first_name: orderData.eater_name ? orderData.eater_name : "no name",
            last_name: "_",
            country: "india",
            language_code: "en",
            groups: ""
          }
        },
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${whatsappApiAccessToken}`,
        },
      });
      console.log(orderData)
      await axios({
        method: "post",
        url: orderWwhatssappMmassageHistoryURL,
        data: {
          order_id: orderData.id,
          mobile: orderData.eater_phonenumber,
          templats_name: "order_create",
          w_data: response.data,

        },
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${token}`,
        },
      });
      console.log(response)

      if (response.data.result) {
        addToast("success", "Message send successfully");

      }
      setIsLoading(false);

    } catch (error) {
      if (error.response && error.response.data.message) {
        addToast("error", error.response.data.message);
      } else {
        addToast("error", error.message);
      }
      console.log(error);
    }
    setIsLoading(false);

  }




  const sendOrderPaymentWhateappMassage = async () => {
    // console.log("1. Butter x 1\n2. Chicken Biryani x 1\n3. Palak Paneer x 1\n4. Chole Bhature x 1\n5. Samosa x 1\n6. Dosa x 1\n7. Naan x 1\n8. Aloo Gobi x 1\n9. Rogan Josh x 1")
    let itemsText = ""
    orderData.details.forEach((item, index) => {
      itemsText += index + 1 + ". " + item.product_name + " x " + item.quantity + "\\n"
    });
    setIsLoading(true);

    try {
      const response = await axios({
        method: "post",
        url: whatsappMassageingApiUrl + '/contact/send-template-message',
        data: {
          phone_number: orderData.eater_phonenumber,
          template_name: "order_payment",
          template_language: "en",
          header_field_1: orderData.order_number_qrcode,
          field_1: orderData.eater_name ? "Hi" + orderData.eater_name : "Hi",
          field_2: capitalizeFirstLetter(orderData.fooders_name),
          field_3: orderData.order_number_qrcode,
          field_4: (parseFloat(totalAmountPaid) + parseFloat(onlySavePaymentRoundUpAmount)).toFixed(2),
          field_5: itemsText,
          field_6: "upi://pay/?pa=" + fooderUpiId + "&am=" + (parseFloat(totalAmountPaid) + parseFloat(onlySavePaymentRoundUpAmount)).toFixed(2) + "&cu=INR",

          // button_0: "{upi://pay/?pa=vineetkrishnagupta@okhdfcbank&am=100.23&cu=INR}",



          // if you want to create contact if it does not exist
          contact: {
            first_name: orderData.eater_name ? orderData.eater_name : "no name",

            last_name: ".",
            country: "india",
            language_code: "en",
            groups: ""
          }
        },
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${whatsappApiAccessToken}`,
        },
      });
      await axios({
        method: "post",
        url: orderWwhatssappMmassageHistoryURL,
        data: {
          order_id: orderData.id,
          mobile: orderData.eater_phonenumber,
          templats_name: "order_payment",
          w_data: response.data,

        },
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${token}`,
        },
      });

      if (response.data.result) {
        addToast("success", "Message send successfully");
      }
      setIsLoading(false);

    } catch (error) {
      if (error.response && error.response.data.message) {
        addToast("error", error.response.data.message);
      } else {
        addToast("error", error.message);
      }
      console.log(error);
    }
    setIsLoading(false);

  }















  const resetPaymentDetails = () => {
    setPartialPaymentDetailModel(false)
    setPartialPaymentlModel(false)
    setSelectedPaymentOption("Cash")
    setOnlySavePaymentPaidedAmount(0)
    setOnlySavePaymentTotalPaidedAmount(0)
    setOnlySavePaymentTipAmount(0)
    setOnlySavePaymentTipTotalAmount(0)
    setOnlySavePaymentNotes("")
    setOnlySavePaymentUPIMode("")
    setOnlySavePaymentUPIOther("")
    setPaymentSettleTransactionId("")
    setSavePaymentData([])
  }




  return (
    <>
      {token && (
        <>
          <div
            className={
              toggleSideBar
                ? "container-scroller sidebar-icon-only"
                : "container-scroller"
            }
          >
            <nav className="navbar default-layout col-lg-12 col-12 p-0 fixed-top d-flex align-items-top flex-row">
              <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-start">


                <Link className="navbar-brand brand-logo-mini" to="/live_orders">
                  <img src="images/mini-kr-logo.svg" alt="logo" />
                </Link>

                <div className="me-1">
                  <button
                    className={
                      toggleSideBar
                        ? "navbar-toggler navbar-toggler align-self-center"
                        : "navbar-toggler navbar-toggler align-self-center text-white"
                    }
                    type="button"
                    onClick={handleToggleSidebar}
                  >
                    <span className="icon-menu"></span>
                  </button>
                </div>
                <div>
                  <Link to="/live_orders">
                    <span className="navbar-brand brand-logo">
                      <img src="images/Khateraho_logo_white.png" alt="logo" />
                    </span>
                  </Link>
                </div>
              </div>

              <NavBar handleToggle={handleToggleOffCanvas} />
            </nav>
            <div className="container-fluid page-body-wrapper px-0">
              <SideBar active="live_orders" toggleCanvas={toggleSideBar} />

              <div className="main-panel">
                <div className="content-wrapper">
                  <h3 className="ukhd mb-3">Live Orders</h3>
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="home-tab">
                        <div className="d-sm-flex align-items-center justify-content-between border-bottom">
                          <ul className="nav nav-tabs" role="tablist">
                            <li className="nav-item">
                              <a
                                className="nav-link active ps-0"
                                id="home-tab"
                                data-bs-toggle="tab"
                                href="#allOrders"
                                role="tab"
                                aria-controls="allOrders"
                                aria-selected="true"
                              >
                                All Orders
                              </a>
                            </li>
                            <li className="nav-item">
                              <a
                                className="nav-link"
                                id="profile-tab"
                                data-bs-toggle="tab"
                                href="#pendingOrders"
                                role="tab"
                                aria-selected="false"
                              >
                                Pending Orders
                              </a>
                            </li>
                            <li className="nav-item">
                              <a
                                className="nav-link"
                                id="contact-tab"
                                data-bs-toggle="tab"
                                href="#acceptedOrders"
                                role="tab"
                                aria-selected="false"
                              >
                                Accepted Orders
                              </a>
                            </li>
                            <li className="nav-item">
                              <a
                                className="nav-link border-0"
                                id="more-tab"
                                data-bs-toggle="tab"
                                href="#readyOrders"
                                role="tab"
                                aria-selected="false"
                              >
                                Ready Orders
                              </a>
                            </li>
                          </ul>
                          <div></div>
                        </div>

                        <div className="tab-content tab-content-basic">
                          <div
                            className="tab-pane fade show active"
                            id="allOrders"
                            role="tabpanel"
                            aria-labelledby="allOrders"
                          >
                            <div className="row flex-grow countord">
                              <div className="col-md-6 col-lg-3 grid-margin">
                                <div className="card card-rounded">
                                  <div className="card-body">
                                    <div className="d-flex">
                                      <i className="mdi mdi-speedometer-medium menu-icon"></i>
                                      <div className="wrapper ms-3">
                                        <p className="mb-2 fw-bold">
                                          {allOrders}
                                        </p>
                                        <small className="text-muted mb-0">
                                          All Orders
                                        </small>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6 col-lg-3 grid-margin">
                                <div className="card card-rounded">
                                  <div className="card-body">
                                    <div className="d-flex">
                                      <i className="mdi mdi-calendar-clock"></i>
                                      <div className="wrapper ms-3">
                                        <p className="mb-2 fw-bold">
                                          {pendingOrders}
                                        </p>
                                        <small className="text-muted mb-0">
                                          Pending Orders
                                        </small>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-6 col-lg-3 grid-margin">
                                <div className="card card-rounded">
                                  <div className="card-body">
                                    <div className="d-flex">
                                      <i className="mdi mdi-bus-clock"></i>
                                      <div className="wrapper ms-3">
                                        <p className="mb-2 fw-bold">
                                          {acceptOrders}
                                        </p>
                                        <small className="text-muted mb-0">
                                          Accepted Orders
                                        </small>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-6 col-lg-3 grid-margin">
                                <div className="card card-rounded">
                                  <div className="card-body">
                                    <div className="d-flex">
                                      <i className="mdi mdi-speedometer-medium menu-icon"></i>
                                      <div className="wrapper ms-3">
                                        <p className="mb-2 fw-bold">
                                          {readyOrders}
                                        </p>
                                        <small className="text-muted mb-0">
                                          Order Ready
                                        </small>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="ordertbl">
                              <h4 className="card-title card-title-dash mb-3">
                                Orders
                              </h4>
                              <div className="row">
                                <div className="col-12 grid-margin">
                                  <div className="card card-rounded">
                                    <div className="card-body thhd">
                                      <div className="row">
                                        <div className="col-12 d-flex align-items-center justify-content-between">
                                          <div className="col-2">Order Id</div>
                                          <div className="col-2">
                                            Name & Amount
                                          </div>
                                          <div className="col-2">
                                            Payment Status
                                          </div>
                                          <div className="col-2">Status</div>
                                          <div className="col-4 text-right">
                                            Action
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div id="new_order_div_notification">
                                {data.length === 0 ? (
                                  <div className="row">
                                    <div className="col-12 grid-margin">
                                      <div className="card card-rounded">
                                        <div className="card-body trcnt">
                                          <div className="row">
                                            <div className="col-12 d-flex justify-content-between">
                                              <div className="col-2"></div>
                                              <div className="col-3"></div>
                                              <div className="col-2 vsalign">
                                                No data found
                                              </div>
                                              <div className="col-2 vsalign"></div>
                                              <div className="col-3 vsalign"></div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  data.map((item) => (
                                    <div key={item.id} className="row">
                                      <div className="col-12 grid-margin">
                                        <div className="card card-rounded">
                                          <div className="card-body trcnt">
                                            <div className="row">
                                              <div className="col-12 d-flex justify-content-between">
                                                <div className="col-2">
                                                  <h6>
                                                    Order #
                                                    {item.order_number_qrcode}
                                                  </h6>
                                                  <h6>#{item.order_number}</h6>
                                                  {/* <span
                                                    style={{
                                                      display: "block",
                                                    }}
                                                  >
                                                    {item.order_type} | {item.table_no}
                                                  </span> */}

                                                  {item.order_type == 'dine_in' ? <>

                                                    <span
                                                      style={{
                                                        display: "block",
                                                      }}
                                                    >

                                                      Dine In({item.table_no})
                                                    </span>

                                                  </> : <></>}

                                                  {item.order_type == 'take_away' ? <>
                                                    <span
                                                      style={{
                                                        display: "block",
                                                      }}
                                                    >
                                                      Take Away

                                                    </span>

                                                  </> : <></>}

                                                  {item.order_type == 'delivery' ? <>

                                                    <span
                                                      style={{
                                                        display: "block",
                                                      }}
                                                    >
                                                      Delivery

                                                    </span>




                                                  </> : <></>}
                                                  <span>
                                                    {formatTimestamp(
                                                      item.creation_date
                                                    )}
                                                  </span>
                                                  <span
                                                    style={{
                                                      display: "block",
                                                    }}
                                                  >
                                                    Platform -{" "}
                                                    {item.order_mode_label}
                                                  </span>
                                                </div>
                                                <div className="col-2">
                                                  <div>
                                                    <h6>{item.eater_name}</h6>
                                                    {(item.total ||
                                                      item.total === 0) && (
                                                        <h6>
                                                          {currencySymbol}{" "}
                                                          {onlySavePaymentRoundUpCheck && (item.payment_status === 0 || item.payment_status === 2) ? <>

                                                            {(parseFloat(item.total) + parseFloat(getDecimalPart(item.total))).toFixed(2)}
                                                          </> : <>

                                                            {parseFloat(
                                                              item.total
                                                            ).toFixed(2)}

                                                          </>}


                                                        </h6>
                                                      )}
                                                  </div>
                                                </div>
                                                <div className="col-2 vsalign">
                                                  {/* {item.payment_status ===
                                                    1 && (
                                                      <div className="badge badge-info">
                                                        {
                                                          item.payment_status_label
                                                        }
                                                      </div>
                                                    )}
                                                  {item.payment_status ===
                                                    0 && (
                                                      <div className="badge badge-opacity-danger">
                                                        {
                                                          item.payment_status_label
                                                        }
                                                      </div>
                                                    )} */}
                                                  {item.payment_status === 0 && (<div className="badge badge-opacity-danger">Unpaid</div>)}
                                                  {item.payment_status === 1 && (<div className="badge badge-opacity-success">Paid</div>)}
                                                  {item.payment_status === 2 && (<div className="badge badge-opacity-warning">Hold</div>)}
                                                  {item.payment_status === 3 && (<div className="badge badge-dark partially-paid-bg-collor">Partially Paid</div>)}
                                                </div>
                                                <div className="col-2 vsalign">
                                                  {item.status === 0 && (
                                                    <div className="badge badge-opacity-secondary">
                                                      {item.order_status_lable}
                                                    </div>
                                                  )}
                                                  {item.status === 1 && (
                                                    <div className="badge badge-theme">
                                                      {item.order_status_lable}
                                                    </div>
                                                  )}
                                                  {item.status === 2 && (
                                                    <div className="badge badge-info">
                                                      {item.order_status_lable}
                                                    </div>
                                                  )}
                                                  {item.status === 3 && (
                                                    <div className="badge badge-opacity-warning">
                                                      {item.order_status_lable}
                                                    </div>
                                                  )}
                                                  {item.status === 4 && (
                                                    <div className="badge badge-danger">
                                                      {item.order_status_lable}
                                                    </div>
                                                  )}
                                                </div>
                                                <div className="col-4 text-right vsalign">
                                                  {item.status === 0 && (
                                                    <>
                                                      <button
                                                        type="submit"
                                                        className="btn btn-success text-white"
                                                        onClick={(e) =>
                                                          handleChangeOrderStatus(
                                                            e,
                                                            item.id,
                                                            1,
                                                            item
                                                          )
                                                        }
                                                      >
                                                        Accept
                                                      </button>
                                                      &nbsp;
                                                      <button
                                                        className="btn btn btn-secondary"
                                                        onClick={(e) =>
                                                          handleChangeOrderStatus(
                                                            e,
                                                            item.id,
                                                            4,
                                                            item
                                                          )
                                                        }
                                                      >
                                                        Reject
                                                      </button>
                                                    </>
                                                  )}
                                                  {item.status === 1 && (
                                                    <>
                                                      <button
                                                        type="submit"
                                                        className="btn btn-success text-white"
                                                        onClick={(e) =>
                                                          handleChangeOrderStatus(
                                                            e,
                                                            item.id,
                                                            2,
                                                            item
                                                          )
                                                        }
                                                      >
                                                        Mark as Order Ready
                                                      </button>
                                                    </>
                                                  )}
                                                  &nbsp;
                                                  <button
                                                    className="btn btn btn-primary text-white"
                                                    onClick={(e) => {
                                                      e.preventDefault();
                                                      handleViewDetailsClick(
                                                        item.id
                                                      );
                                                    }}
                                                  >
                                                    View
                                                  </button>
                                                  &nbsp;
                                                  {item.status !== 0 && (
                                                    <button
                                                      className="btn btn-dark text-white"
                                                      onClick={(e) => {
                                                        e.preventDefault();
                                                        handlePrintKOTClick(
                                                          item.id
                                                        );
                                                      }}
                                                    >
                                                      Print KOT
                                                    </button>
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ))
                                )}
                              </div>
                            </div>
                          </div>

                          <div
                            className="tab-pane fade"
                            id="pendingOrders"
                            role="tabpanel"
                            aria-labelledby="pendingOrders"
                          >
                            <div className="row flex-grow countord">
                              <div className="col-md-6 col-lg-3 grid-margin">
                                <div className="card card-rounded">
                                  <div className="card-body">
                                    <div className="d-flex">
                                      <i className="mdi mdi-speedometer-medium menu-icon"></i>
                                      <div className="wrapper ms-3">
                                        <p className="mb-2 fw-bold">
                                          {allOrders}
                                        </p>
                                        <small className="text-muted mb-0">
                                          All Orders
                                        </small>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6 col-lg-3 grid-margin">
                                <div className="card card-rounded">
                                  <div className="card-body">
                                    <div className="d-flex">
                                      <i className="mdi mdi-calendar-clock"></i>
                                      <div className="wrapper ms-3">
                                        <p className="mb-2 fw-bold">
                                          {pendingOrders}
                                        </p>
                                        <small className="text-muted mb-0">
                                          Pending Orders
                                        </small>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-6 col-lg-3 grid-margin">
                                <div className="card card-rounded">
                                  <div className="card-body">
                                    <div className="d-flex">
                                      <i className="mdi mdi-bus-clock"></i>
                                      <div className="wrapper ms-3">
                                        <p className="mb-2 fw-bold">
                                          {acceptOrders}
                                        </p>
                                        <small className="text-muted mb-0">
                                          Accepted Orders
                                        </small>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-6 col-lg-3 grid-margin">
                                <div className="card card-rounded">
                                  <div className="card-body">
                                    <div className="d-flex">
                                      <i className="mdi mdi-speedometer-medium menu-icon"></i>
                                      <div className="wrapper ms-3">
                                        <p className="mb-2 fw-bold">
                                          {readyOrders}
                                        </p>
                                        <small className="text-muted mb-0">
                                          Order Ready
                                        </small>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="ordertbl">
                              <h4 className="card-title card-title-dash mb-3">
                                Orders
                              </h4>
                              <div className="row">
                                <div className="col-12 grid-margin">
                                  <div className="card card-rounded">
                                    <div className="card-body thhd">
                                      <div className="row">
                                        <div className="col-12 d-flex align-items-center justify-content-between">
                                          <div className="col-2">Order Id</div>
                                          <div className="col-2">
                                            Name & Amount
                                          </div>
                                          <div className="col-2">
                                            Payment Status
                                          </div>
                                          <div className="col-2">Status</div>
                                          <div className="col-4 text-right">
                                            Action
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              {pendingOrdersData.length === 0 ? (
                                <div className="row">
                                  <div className="col-12 grid-margin">
                                    <div className="card card-rounded">
                                      <div className="card-body trcnt">
                                        <div className="row">
                                          <div className="col-12 d-flex justify-content-between">
                                            <div className="col-2"></div>
                                            <div className="col-3"></div>
                                            <div className="col-2 vsalign">
                                              No data found
                                            </div>
                                            <div className="col-2 vsalign"></div>
                                            <div className="col-3 vsalign"></div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                pendingOrdersData.map((item) => (
                                  <div key={item.id} className="row">
                                    <div className="col-12 grid-margin">
                                      <div className="card card-rounded">
                                        <div className="card-body trcnt">
                                          <div className="row">
                                            <div className="col-12 d-flex justify-content-between">
                                              <div className="col-2">
                                                <h6>
                                                  Order #
                                                  {item.order_number_qrcode}
                                                </h6>
                                                <h6>#{item.order_number}</h6>
                                                <span
                                                  style={{
                                                    display: "block",
                                                  }}
                                                >
                                                  {item.table_no}
                                                </span>
                                                <span>
                                                  {formatTimestamp(
                                                    item.creation_date
                                                  )}
                                                </span>
                                                <span
                                                  style={{ display: "block" }}
                                                >
                                                  Platform -{" "}
                                                  {item.order_mode_label}
                                                </span>
                                              </div>
                                              <div className="col-2">
                                                <div>
                                                  <h6>{item.eater_name}</h6>
                                                  {(item.total ||
                                                    item.total === 0) && (
                                                      <h6>
                                                        {currencySymbol}{" "}
                                                        {parseFloat(
                                                          item.total
                                                        ).toFixed(2)}
                                                      </h6>
                                                    )}
                                                </div>
                                              </div>
                                              <div className="col-2 vsalign">
                                                {/* {item.payment_status ===
                                                    1 && (
                                                      <div className="badge badge-info">
                                                        {
                                                          item.payment_status_label
                                                        }
                                                      </div>
                                                    )}
                                                  {item.payment_status ===
                                                    0 && (
                                                      <div className="badge badge-opacity-danger">
                                                        {
                                                          item.payment_status_label
                                                        }
                                                      </div>
                                                    )} */}

                                                {item.payment_status === 0 && (<div className="badge badge-opacity-danger">Unpaid</div>)}
                                                {item.payment_status === 1 && (<div className="badge badge-opacity-success">Paid</div>)}
                                                {item.payment_status === 2 && (<div className="badge badge-opacity-warning">Hold</div>)}
                                                {item.payment_status === 3 && (<div className="badge badge-dark partially-paid-bg-collor">Partially Paid</div>)}
                                              </div>
                                              <div className="col-2 vsalign">
                                                {item.status === 0 && (
                                                  <div className="badge badge-opacity-secondary">
                                                    {item.order_status_lable}
                                                  </div>
                                                )}
                                                {item.status === 1 && (
                                                  <div className="badge badge-theme">
                                                    {item.order_status_lable}
                                                  </div>
                                                )}
                                                {item.status === 2 && (
                                                  <div className="badge badge-info">
                                                    {item.order_status_lable}
                                                  </div>
                                                )}
                                                {item.status === 3 && (
                                                  <div className="badge badge-opacity-warning">
                                                    {item.order_status_lable}
                                                  </div>
                                                )}
                                                {item.status === 4 && (
                                                  <div className="badge badge-danger">
                                                    {item.order_status_lable}
                                                  </div>
                                                )}
                                              </div>
                                              <div className="col-4 text-right vsalign">
                                                {item.status === 0 && (
                                                  <>
                                                    <button
                                                      type="submit"
                                                      className="btn btn-success text-white"
                                                      onClick={(e) =>
                                                        handleChangeOrderStatus(
                                                          e,
                                                          item.id,
                                                          1,
                                                          item
                                                        )
                                                      }
                                                    >
                                                      Accept
                                                    </button>
                                                    &nbsp;
                                                    <button
                                                      className="btn btn btn-secondary"
                                                      onClick={(e) =>
                                                        handleChangeOrderStatus(
                                                          e,
                                                          item.id,
                                                          4,
                                                          item
                                                        )
                                                      }
                                                    >
                                                      Reject
                                                    </button>
                                                  </>
                                                )}
                                                {item.status === 1 && (
                                                  <>
                                                    <button
                                                      type="submit"
                                                      className="btn btn-success text-white"
                                                      onClick={(e) =>
                                                        handleChangeOrderStatus(
                                                          e,
                                                          item.id,
                                                          2,
                                                          item
                                                        )
                                                      }
                                                    >
                                                      Mark as Order Ready
                                                    </button>
                                                  </>
                                                )}
                                                &nbsp;
                                                <button
                                                  className="btn btn btn-dark text-white"
                                                  onClick={(e) => {
                                                    e.preventDefault();
                                                    handleViewDetailsClick(
                                                      item.id
                                                    );
                                                  }}
                                                >
                                                  View
                                                </button>
                                                &nbsp;
                                                {item.status !== 0 && (
                                                  <button
                                                    className="btn btn-primary text-white"
                                                    onClick={(e) => {
                                                      e.preventDefault();
                                                      handlePrintKOTClick(
                                                        item.id
                                                      );
                                                    }}
                                                  >
                                                    Print KOT
                                                  </button>
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ))
                              )}
                            </div>
                          </div>

                          <div
                            className="tab-pane fade"
                            id="acceptedOrders"
                            role="tabpanel"
                            aria-labelledby="acceptedOrders"
                          >
                            <div className="row flex-grow countord">
                              <div className="col-md-6 col-lg-3 grid-margin">
                                <div className="card card-rounded">
                                  <div className="card-body">
                                    <div className="d-flex">
                                      <i className="mdi mdi-speedometer-medium menu-icon"></i>
                                      <div className="wrapper ms-3">
                                        <p className="mb-2 fw-bold">
                                          {allOrders}
                                        </p>
                                        <small className="text-muted mb-0">
                                          All Orders
                                        </small>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6 col-lg-3 grid-margin">
                                <div className="card card-rounded">
                                  <div className="card-body">
                                    <div className="d-flex">
                                      <i className="mdi mdi-calendar-clock"></i>
                                      <div className="wrapper ms-3">
                                        <p className="mb-2 fw-bold">
                                          {pendingOrders}
                                        </p>
                                        <small className="text-muted mb-0">
                                          Pending Orders
                                        </small>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-6 col-lg-3 grid-margin">
                                <div className="card card-rounded">
                                  <div className="card-body">
                                    <div className="d-flex">
                                      <i className="mdi mdi-bus-clock"></i>
                                      <div className="wrapper ms-3">
                                        <p className="mb-2 fw-bold">
                                          {acceptOrders}
                                        </p>
                                        <small className="text-muted mb-0">
                                          Accepted Orders
                                        </small>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-6 col-lg-3 grid-margin">
                                <div className="card card-rounded">
                                  <div className="card-body">
                                    <div className="d-flex">
                                      <i className="mdi mdi-speedometer-medium menu-icon"></i>
                                      <div className="wrapper ms-3">
                                        <p className="mb-2 fw-bold">
                                          {readyOrders}
                                        </p>
                                        <small className="text-muted mb-0">
                                          Order Ready
                                        </small>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="ordertbl">
                              <h4 className="card-title card-title-dash mb-3">
                                Orders
                              </h4>
                              <div className="row">
                                <div className="col-12 grid-margin">
                                  <div className="card card-rounded">
                                    <div className="card-body thhd">
                                      <div className="row">
                                        <div className="col-12 d-flex align-items-center justify-content-between">
                                          <div className="col-2">Order Id</div>
                                          <div className="col-2">
                                            Name & Amount
                                          </div>
                                          <div className="col-2">
                                            Payment Status
                                          </div>
                                          <div className="col-2">Status</div>
                                          <div className="col-4 text-right">
                                            Action
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              {acceptOrdersData.length === 0 ? (
                                <div className="row">
                                  <div className="col-12 grid-margin">
                                    <div className="card card-rounded">
                                      <div className="card-body trcnt">
                                        <div className="row">
                                          <div className="col-12 d-flex justify-content-between">
                                            <div className="col-2"></div>
                                            <div className="col-3"></div>
                                            <div className="col-2 vsalign">
                                              No data found
                                            </div>
                                            <div className="col-2 vsalign"></div>
                                            <div className="col-3 vsalign"></div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                acceptOrdersData.map((item) => (
                                  <div key={item.id} className="row">
                                    <div className="col-12 grid-margin">
                                      <div className="card card-rounded">
                                        <div className="card-body trcnt">
                                          <div className="row">
                                            <div className="col-12 d-flex justify-content-between">
                                              <div className="col-2">
                                                <h6>
                                                  Order #
                                                  {item.order_number_qrcode}
                                                </h6>
                                                <h6>#{item.order_number}</h6>
                                                <span
                                                  style={{
                                                    display: "block",
                                                  }}
                                                >
                                                  {item.table_no}
                                                </span>
                                                <span>
                                                  {formatTimestamp(
                                                    item.creation_date
                                                  )}
                                                </span>
                                                <span
                                                  style={{ display: "block" }}
                                                >
                                                  Platform -{" "}
                                                  {item.order_mode_label}
                                                </span>
                                              </div>
                                              <div className="col-2">
                                                <div>
                                                  <h6>{item.eater_name}</h6>
                                                  {(item.total ||
                                                    item.total === 0) && (
                                                      <h6>
                                                        {currencySymbol}{" "}
                                                        {parseFloat(
                                                          item.total
                                                        ).toFixed(2)}
                                                      </h6>
                                                    )}
                                                </div>
                                              </div>
                                              <div className="col-2 vsalign">
                                                {/* {item.payment_status ===
                                                    1 && (
                                                      <div className="badge badge-info">
                                                        {
                                                          item.payment_status_label
                                                        }
                                                      </div>
                                                    )}
                                                  {item.payment_status ===
                                                    0 && (
                                                      <div className="badge badge-opacity-danger">
                                                        {
                                                          item.payment_status_label
                                                        }
                                                      </div>
                                                    )} */}

                                                {item.payment_status === 0 && (<div className="badge badge-opacity-danger">Unpaid</div>)}
                                                {item.payment_status === 1 && (<div className="badge badge-opacity-success">Paid</div>)}
                                                {item.payment_status === 2 && (<div className="badge badge-opacity-warning">Hold</div>)}
                                                {item.payment_status === 3 && (<div className="badge badge-dark partially-paid-bg-collor">Partially Paid</div>)}
                                              </div>
                                              <div className="col-2 vsalign">
                                                {item.status === 0 && (
                                                  <div className="badge badge-opacity-secondary">
                                                    {item.order_status_lable}
                                                  </div>
                                                )}
                                                {item.status === 1 && (
                                                  <div className="badge badge-theme">
                                                    {item.order_status_lable}
                                                  </div>
                                                )}
                                                {item.status === 2 && (
                                                  <div className="badge badge-info">
                                                    {item.order_status_lable}
                                                  </div>
                                                )}
                                                {item.status === 3 && (
                                                  <div className="badge badge-opacity-warning">
                                                    {item.order_status_lable}
                                                  </div>
                                                )}
                                                {item.status === 4 && (
                                                  <div className="badge badge-danger">
                                                    {item.order_status_lable}
                                                  </div>
                                                )}
                                              </div>
                                              <div className="col-4 text-right vsalign">
                                                {item.status === 0 && (
                                                  <>
                                                    <button
                                                      type="submit"
                                                      className="btn btn-success text-white"
                                                      onClick={(e) =>
                                                        handleChangeOrderStatus(
                                                          e,
                                                          item.id,
                                                          1,
                                                          item
                                                        )
                                                      }
                                                    >
                                                      Accept
                                                    </button>
                                                    &nbsp;
                                                    <button
                                                      className="btn btn btn-secondary"
                                                      onClick={(e) =>
                                                        handleChangeOrderStatus(
                                                          e,
                                                          item.id,
                                                          4,
                                                          item
                                                        )
                                                      }
                                                    >
                                                      Reject
                                                    </button>
                                                  </>
                                                )}
                                                {item.status === 1 && (
                                                  <>
                                                    <button
                                                      type="submit"
                                                      className="btn btn-success text-white"
                                                      onClick={(e) =>
                                                        handleChangeOrderStatus(
                                                          e,
                                                          item.id,
                                                          2,
                                                          item
                                                        )
                                                      }
                                                    >
                                                      Mark as Order Ready
                                                    </button>
                                                  </>
                                                )}
                                                &nbsp;
                                                <button
                                                  className="btn btn btn-dark text-white"
                                                  onClick={(e) => {
                                                    e.preventDefault();
                                                    handleViewDetailsClick(
                                                      item.id
                                                    );
                                                  }}
                                                >
                                                  View
                                                </button>
                                                &nbsp;
                                                {item.status !== 0 && (
                                                  <button
                                                    className="btn btn-primary text-white"
                                                    onClick={(e) => {
                                                      e.preventDefault();
                                                      handlePrintKOTClick(
                                                        item.id
                                                      );
                                                    }}
                                                  >
                                                    Print KOT
                                                  </button>
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ))
                              )}
                            </div>
                          </div>

                          <div
                            className="tab-pane fade"
                            id="readyOrders"
                            role="tabpanel"
                            aria-labelledby="readyOrders"
                          >
                            <div className="row flex-grow countord">
                              <div className="col-md-6 col-lg-3 grid-margin">
                                <div className="card card-rounded">
                                  <div className="card-body">
                                    <div className="d-flex">
                                      <i className="mdi mdi-speedometer-medium menu-icon"></i>
                                      <div className="wrapper ms-3">
                                        <p className="mb-2 fw-bold">
                                          {allOrders}
                                        </p>
                                        <small className="text-muted mb-0">
                                          All Orders
                                        </small>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6 col-lg-3 grid-margin">
                                <div className="card card-rounded">
                                  <div className="card-body">
                                    <div className="d-flex">
                                      <i className="mdi mdi-calendar-clock"></i>
                                      <div className="wrapper ms-3">
                                        <p className="mb-2 fw-bold">
                                          {pendingOrders}
                                        </p>
                                        <small className="text-muted mb-0">
                                          Pending Orders
                                        </small>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-6 col-lg-3 grid-margin">
                                <div className="card card-rounded">
                                  <div className="card-body">
                                    <div className="d-flex">
                                      <i className="mdi mdi-bus-clock"></i>
                                      <div className="wrapper ms-3">
                                        <p className="mb-2 fw-bold">
                                          {acceptOrders}
                                        </p>
                                        <small className="text-muted mb-0">
                                          Accepted Orders
                                        </small>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-6 col-lg-3 grid-margin">
                                <div className="card card-rounded">
                                  <div className="card-body">
                                    <div className="d-flex">
                                      <i className="mdi mdi-speedometer-medium menu-icon"></i>
                                      <div className="wrapper ms-3">
                                        <p className="mb-2 fw-bold">
                                          {readyOrders}
                                        </p>
                                        <small className="text-muted mb-0">
                                          Order Ready
                                        </small>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="ordertbl">
                              <h4 className="card-title card-title-dash mb-3">
                                Orders
                              </h4>
                              <div className="row">
                                <div className="col-12 grid-margin">
                                  <div className="card card-rounded">
                                    <div className="card-body thhd">
                                      <div className="row">
                                        <div className="col-12 d-flex align-items-center justify-content-between">
                                          <div className="col-2">Order Id</div>
                                          <div className="col-2">
                                            Name & Amount
                                          </div>
                                          <div className="col-2">
                                            Payment Status
                                          </div>
                                          <div className="col-2">Status</div>
                                          <div className="col-4 text-right">
                                            Action
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              {readyOrdersData.length === 0 ? (
                                <div className="row">
                                  <div className="col-12 grid-margin">
                                    <div className="card card-rounded">
                                      <div className="card-body trcnt">
                                        <div className="row">
                                          <div className="col-12 d-flex justify-content-between">
                                            <div className="col-2"></div>
                                            <div className="col-3"></div>
                                            <div className="col-2 vsalign">
                                              No data found
                                            </div>
                                            <div className="col-2 vsalign"></div>
                                            <div className="col-3 vsalign"></div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                readyOrdersData.map((item) => (
                                  <div key={item.id} className="row">
                                    <div className="col-12 grid-margin">
                                      <div className="card card-rounded">
                                        <div className="card-body trcnt">
                                          <div className="row">
                                            <div className="col-12 d-flex justify-content-between">
                                              <div className="col-2">
                                                <h6>
                                                  Order #
                                                  {item.order_number_qrcode}
                                                </h6>
                                                <h6>#{item.order_number}</h6>
                                                <span
                                                  style={{
                                                    display: "block",
                                                  }}
                                                >
                                                  {item.table_no}
                                                </span>
                                                <span>
                                                  {formatTimestamp(
                                                    item.creation_date
                                                  )}
                                                </span>
                                                <span
                                                  style={{ display: "block" }}
                                                >
                                                  Platform -{" "}
                                                  {item.order_mode_label}
                                                </span>
                                              </div>
                                              <div className="col-2">
                                                <div>
                                                  <h6>{item.eater_name}</h6>
                                                  {(item.total ||
                                                    item.total === 0) && (
                                                      <h6>
                                                        {currencySymbol}{" "}
                                                        {parseFloat(
                                                          item.total
                                                        ).toFixed(2)}
                                                      </h6>
                                                    )}
                                                </div>
                                              </div>
                                              <div className="col-2 vsalign">
                                                {/* {item.payment_status ===
                                                    1 && (
                                                      <div className="badge badge-info">
                                                        {
                                                          item.payment_status_label
                                                        }
                                                      </div>
                                                    )}
                                                  {item.payment_status ===
                                                    0 && (
                                                      <div className="badge badge-opacity-danger">
                                                        {
                                                          item.payment_status_label
                                                        }
                                                      </div>
                                                    )} */}

                                                {item.payment_status === 0 && (<div className="badge badge-opacity-danger">Unpaid</div>)}
                                                {item.payment_status === 1 && (<div className="badge badge-opacity-success">Paid</div>)}
                                                {item.payment_status === 2 && (<div className="badge badge-opacity-warning">Hold</div>)}
                                                {item.payment_status === 3 && (<div className="badge badge-dark partially-paid-bg-collor">Partially Paid</div>)}

                                              </div>
                                              <div className="col-2 vsalign">
                                                {item.status === 0 && (
                                                  <div className="badge badge-opacity-secondary">
                                                    {item.order_status_lable}
                                                  </div>
                                                )}
                                                {item.status === 1 && (
                                                  <div className="badge badge-theme">
                                                    {item.order_status_lable}
                                                  </div>
                                                )}
                                                {item.status === 2 && (
                                                  <div className="badge badge-info">
                                                    {item.order_status_lable}
                                                  </div>
                                                )}
                                                {item.status === 3 && (
                                                  <div className="badge badge-opacity-warning">
                                                    {item.order_status_lable}
                                                  </div>
                                                )}
                                                {item.status === 4 && (
                                                  <div className="badge badge-danger">
                                                    {item.order_status_lable}
                                                  </div>
                                                )}
                                              </div>
                                              <div className="col-4 text-right vsalign">
                                                {item.status === 0 && (
                                                  <>
                                                    <button
                                                      type="submit"
                                                      className="btn btn-success text-white"
                                                      onClick={(e) =>
                                                        handleChangeOrderStatus(
                                                          e,
                                                          item.id,
                                                          1,
                                                          item
                                                        )
                                                      }
                                                    >
                                                      Accept
                                                    </button>
                                                    &nbsp;
                                                    <button
                                                      className="btn btn btn-secondary"
                                                      onClick={(e) =>
                                                        handleChangeOrderStatus(
                                                          e,
                                                          item.id,
                                                          4,
                                                          item
                                                        )
                                                      }
                                                    >
                                                      Reject
                                                    </button>
                                                  </>
                                                )}
                                                {item.status === 1 && (
                                                  <>
                                                    <button
                                                      type="submit"
                                                      className="btn btn-success text-white"
                                                      onClick={(e) =>
                                                        handleChangeOrderStatus(
                                                          e,
                                                          item.id,
                                                          2,
                                                          item
                                                        )
                                                      }
                                                    >
                                                      Mark as Order Ready
                                                    </button>
                                                  </>
                                                )}
                                                &nbsp;
                                                <button
                                                  className="btn btn btn-dark text-white"
                                                  onClick={(e) => {
                                                    e.preventDefault();
                                                    handleViewDetailsClick(
                                                      item.id
                                                    );
                                                  }}
                                                >
                                                  View
                                                </button>
                                                &nbsp;
                                                {item.status !== 0 && (
                                                  <button
                                                    className="btn btn-primary text-white"
                                                    onClick={(e) => {
                                                      e.preventDefault();
                                                      handlePrintKOTClick(
                                                        item.id
                                                      );
                                                    }}
                                                  >
                                                    Print KOT
                                                  </button>
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ))
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>




          <div
            className={advertisementShow ? "modal fade show d-block" : "modal fade"}
            tabIndex={-1}
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
            
          >
            <div className="modal-dialog   " role="document" style={{ height: "50vh", minWidth: "70vw" }} >
              <div className="modal-content w-85" >
                <div className="modal-header py-2">
                  <h5 className="modal-title" id="exampleModalLabel">

                  </h5>

                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() => { setAdvertisementShow(false) }}
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body py-2" >
                  <div className="  container-fluid">
                    <form className="forms-sample">
                      <div className="row">
                        <h4>WhatsApp Marketing</h4>
                        <img src="./images/whatspp_marketing.png" />
                      </div>
                    </form>
                  </div>

                </div>





                <div className="modal-footer py-1">

                   


                  <a
                    className="btn btn-success"
                    href="/whatsapp_marketing"
                  >
                    Go
                  </a>



                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-dismiss="modal"
                    onClick={() => { setAdvertisementShow(false) }}
                  >
                    Cancel
                  </button>

                </div>
              </div>
            </div>
          </div>





          <div
            className={isModal ? "modal fade show d-block" : "modal fade"}
            id="exampleModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >











            <div className="modal-dialog modal-lg" role="document">
              <div className="modal-content w-85">
                <div className="modal-header py-2">
                  <h5 className="modal-title" id="exampleModalLabel">
                    Order Details
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() => {
                      setIsVisible(false);
                      setSelectedProduct({});
                      setSearchInput("");
                      setFilteredData(null);
                      setShowDropDown(false);
                      // setProductId("");
                      setQuantity("1");
                      setPrice("0");
                      setIsModal(false);
                    }}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>





                <div
                  className={partialPaymentDetailModel ? "modal fade show d-block" : "modal fade"}
                  id="exampleModal"
                  tabIndex={-1}
                  role="dialog"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog" role="document">
                    <div className="modal-content w-85">
                      <div className="modal-header py-2">
                        <h5 className="modal-title" id="exampleModalLabel">
                          Payment Details (Order Id #{orderData && orderData.order_number_qrcode})
                        </h5>
                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                          onClick={() => { setPartialPaymentDetailModel(false) }}
                        >
                          <span aria-hidden="true">×</span>
                        </button>
                      </div>
                      <div className="modal-body py-2">
                        <div className="container">


                        </div>

                      </div>

                      <div className="itemdiv mx-2">
                        <div className="row">
                          <div className="col-lg-2">
                            <p className="itemnm">No</p>
                          </div>
                          <div className="col-lg-5">
                            <p className="itemnm">Payment Method</p>
                          </div>
                          <div className="col-lg-5">
                            <p className="itemnm">Amount</p>
                          </div>
                          {/* <div className="col-lg-4">
                            <p className="itemnm">Tip Amount</p>
                          </div> */}

                        </div>
                      </div>
                      {orderData.partialPaymentDetail &&

                        orderData.partialPaymentDetail.map((item, index) => (


                          <div key={index} className="itemdiv mx-2">
                            <div className="row">
                              <div className="col-lg-2">
                                <p className="itemnm">{index + 1}</p>
                              </div>
                              <div className="col-lg-5">
                                <p className="itemnm">

                                  {item.paymentType === 'cash' && "Cash"}
                                  {item.paymentType === 'card' && "Card"}
                                  {item.paymentType === 'neft' && "NEFT"}
                                  {item.paymentType === 'upi' && "UPI"}

                                </p>




                              </div>
                              <div className="col-lg-5">
                                <p className="itemnm">₹{item.paidAmount}</p>
                              </div>


                            </div>
                          </div>
                        ))
                      }

                      {((orderData.partialPaymentDetail && orderData.partialPaymentDetail.length <= 0) || (orderData.partialPaymentDetail === undefined)) &&


                        <div className="itemdiv mx-2">
                          <div className="row">
                            <div className="col-lg-2">
                              <p className="itemnm">{1}</p>
                            </div>
                            <div className="col-lg-5">
                              <p className="itemnm">

                                {orderData.payment_type === 'cash' && "Cash"}
                                {orderData.payment_type === 'card' && "Card"}
                                {orderData.payment_type === 'neft' && "NEFT"}
                                {orderData.payment_type === 'upi' && "UPI"}

                              </p>

                            </div>
                            <div className="col-lg-5">
                              <p className="itemnm">₹{totalAmountPaid}</p>
                            </div>


                          </div>
                        </div>
                      }


                      <div className="modal-footer pymtftr" style={{ padding: "15px 0px" }}>
                        <div className="row" style={{ width: "100%" }}>
                          <div className="col-6 valign">
                            <div className="kr-partial-payment-detail-font-size">
                              <b> Total: {currencySymbol}{totalAmountPaid}</b>
                              <br />
                              {/* Paid: {currencySymbol}{partialPaymentDetailTotalAmount.toFixed(2)} &ensp; */}

                              {/* {totalAmountPaid - partialPaymentDetailTotalAmount > 0 && `Due: ${totalAmountPaid - partialPaymentDetailTotalAmount}`} */}

                              {/* {data.payment_type === 'partial' && `Due: ${currencySymbol}${parseFloat(totalAmountPaid - partialPaymentDetailTotalAmount).toFixed(2)}`} */}
                              {/* Due: {currencySymbol}{parseFloat(totalAmountPaid - partialPaymentDetailTotalAmount).toFixed(2)} */}
                              {/* Due: {currencySymbol}{Math.abs(parseFloat(totalAmountPaid - partialPaymentDetailTotalAmount).toFixed(2))} */}

                              {(orderData.partialPaymentDetail && orderData.partialPaymentDetail.length > 0) &&
                                // <div>
                                //   Paid: {currencySymbol}{partialPaymentDetailTotalAmount.toFixed(2)} &ensp;
                                //   Due: {currencySymbol}{Math.abs(parseFloat(totalAmountPaid - partialPaymentDetailTotalAmount).toFixed(2))}
                                // </div>

                                <div>
                                  Paid: {currencySymbol}{partialPaymentDetailTotalAmount.toFixed(2)} &ensp;
                                  {parseFloat(totalAmountPaid - partialPaymentDetailTotalAmount).toFixed(2) >= 0 ? (
                                    <>Due: {currencySymbol}{Math.abs(parseFloat(totalAmountPaid - partialPaymentDetailTotalAmount).toFixed(2))}</>
                                  ) : <>Return To: {currencySymbol}{Math.abs(parseFloat(totalAmountPaid - partialPaymentDetailTotalAmount).toFixed(2))}</>}
                                </div>
                              }

                            </div>
                          </div>
                          <div className="col-lg-6 text-right">
                            {orderData.payment_status === 3 && (
                              showDuePaymentMode ? (
                                <button type="button" className="btn btn-success mx-1" disabled={disabledDuePaymentButton} onClick={submitDuePayment}>
                                  Submit
                                </button>
                              ) : (
                                <button type="button" className="btn btn-primary mx-1" onClick={() => setShowDuePaymentMode(true)}>
                                  Pay Due
                                </button>
                              )
                            )}
                            <button
                              type="button"
                              className="btn btn-secondary"
                              data-dismiss="modal"
                              onClick={() => { setPartialPaymentDetailModel(false) }}
                            >
                              Cancel
                            </button>
                          </div>
                        </div>

                        {showDuePaymentMode && orderData.payment_status === 3 &&
                          <div className="row" style={{ width: "100%" }}>
                            <div className="">
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="inlineRadioOptions"
                                  value="Cash"
                                  checked={selectedPaymentOption === "Cash"}
                                  onChange={handlePaymentOptionChange}
                                />
                                <label className="form-check-label">Cash</label>
                              </div>
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="inlineRadioOptions"

                                  value="Card"
                                  checked={selectedPaymentOption === "Card"}
                                  onChange={handlePaymentOptionChange}

                                />
                                <label className="form-check-label">Card</label>
                              </div>

                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="inlineRadioOptions"


                                  value="NEFT"
                                  checked={selectedPaymentOption === "NEFT"}
                                  onChange={handlePaymentOptionChange}
                                />
                                <label className="form-check-label">NEFT</label>
                              </div>


                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="inlineRadioOptions"
                                  value="UPI"
                                  checked={selectedPaymentOption === "UPI"}
                                  onChange={handlePaymentOptionChange}
                                />
                                <label className="form-check-label">UPI</label>
                              </div>

                            </div>
                            {selectedPaymentOption === 'UPI' && (

                              <div className="col-12  mt-3">
                                <label className="">
                                  Select Method
                                  <span className="text-danger">*</span>
                                </label>
                                <select
                                  className="form-select"
                                  name="method"
                                  value={onlySavePaymentUPIMode}
                                  onChange={(e) => { setOnlySavePaymentUPIMode(e.target.value) }}
                                >
                                  <option value="">Select Method</option>
                                  <option value="Google Pay">
                                    Google Pay
                                  </option>
                                  <option value="PhonePe">PhonePe</option>
                                  <option value="Paytm">Paytm</option>
                                  <option value="Other">Other</option>
                                </select>
                                {onlySavePaymentUPIMode === "Other" && (
                                  <>
                                    &nbsp;
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Enter Method"
                                      name="other_method"
                                      value={
                                        onlySavePaymentUPIModeOther
                                      }
                                      onChange={
                                        (e) => { setOnlySavePaymentUPIOther(e.target.value) }
                                      }
                                    />
                                  </>
                                )}
                              </div>

                            )}


                          </div>
                        }
                      </div>



                    </div>
                  </div>
                </div>







                <div
                  className={partialPaymentModel ? "modal fade show d-block" : "modal fade"}
                  id="exampleModal"
                  tabIndex={-1}
                  role="dialog"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog" role="document">
                    <div className="modal-content w-85">
                      <div className="modal-header py-2">
                        <h5 className="modal-title" id="exampleModalLabel">
                          Bill Settlement (Order Id #{orderData && orderData.order_number_qrcode})
                        </h5>

                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                          onClick={() => { setPartialPaymentlModel(false) }}
                        >
                          <span aria-hidden="true">×</span>
                        </button>
                      </div>
                      <div className="modal-body py-2">
                        <div className="container">
                          <form className="forms-sample">
                            <h4 className="card-title m-3 fw-bold ms-0">
                              Select Payment Option
                            </h4>
                            <h6 className="">
                              {/* Total Amount: ₹ {totalAmountPaid} */}
                              Total Amount: ₹ {(parseFloat(totalAmountPaid) + parseFloat(onlySavePaymentRoundUpAmount)).toFixed(2)}

                            </h6>
                            <div className="row">
                              <div className="">
                                <div className="form-check form-check-inline">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="inlineRadioOptions"
                                    value="Cash"
                                    checked={selectedPaymentOption === "Cash"}
                                    onChange={handlePaymentOptionChange}
                                  />
                                  <label className="form-check-label">Cash</label>
                                </div>
                                <div className="form-check form-check-inline">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="inlineRadioOptions"

                                    value="Card"
                                    checked={selectedPaymentOption === "Card"}
                                    onChange={handlePaymentOptionChange}

                                  />
                                  <label className="form-check-label">Card</label>
                                </div>

                                <div className="form-check form-check-inline">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="inlineRadioOptions"


                                    value="NEFT"
                                    checked={selectedPaymentOption === "NEFT"}
                                    onChange={handlePaymentOptionChange}
                                  />
                                  <label className="form-check-label">NEFT</label>
                                </div>


                                <div className="form-check form-check-inline">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="inlineRadioOptions"
                                    value="UPI"
                                    checked={selectedPaymentOption === "UPI"}
                                    onChange={handlePaymentOptionChange}
                                  />
                                  <label className="form-check-label">UPI</label>
                                </div>

                              </div>
                            </div>


                            <div className="row">

                              {selectedPaymentOption === 'UPI' && (

                                <div className="col-12  mt-3">
                                  <label className="">
                                    Select Method
                                    <span className="text-danger">*</span>
                                  </label>
                                  <select
                                    className="form-select"
                                    name="method"
                                    value={onlySavePaymentUPIMode}
                                    onChange={(e) => { setOnlySavePaymentUPIMode(e.target.value) }}
                                  >
                                    <option value="">Select Method</option>
                                    <option value="Google Pay">
                                      Google Pay
                                    </option>
                                    <option value="PhonePe">PhonePe</option>
                                    <option value="Paytm">Paytm</option>
                                    <option value="Other">Other</option>
                                  </select>
                                  {onlySavePaymentUPIMode === "Other" && (
                                    <>
                                      &nbsp;
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Method"
                                        name="other_method"
                                        value={
                                          onlySavePaymentUPIModeOther
                                        }
                                        onChange={
                                          (e) => { setOnlySavePaymentUPIOther(e.target.value) }
                                        }
                                      />
                                    </>
                                  )}
                                </div>
                              )}








                              <div className="col-12  mt-3">
                                <label className="">
                                  Customer Paid<span className="text-danger">*</span>
                                </label>
                                <div className="input-group">
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Customer Paid"
                                    name="customerPaid"
                                    value={onlySavePaymentPaidedAmount}
                                    onChange={(e) => { setOnlySavePaymentPaidedAmount(e.target.value) }}
                                  />
                                  <span className="input-group-text">₹</span>
                                </div>
                              </div>

                              <div className="col-12  mt-3">
                                <label className="">Tip</label>
                                <div className="input-group">
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Tip"
                                    name="tip"
                                    value={onlySavePaymentTipAmount}
                                    onChange={(e) => { setOnlySavePaymentTipAmount(e.target.value) }}
                                  />
                                  <span className="input-group-text">₹</span>
                                </div>
                              </div>


                              <div className="col-12  mt-3">
                                <label className="">Transaction / Reference ID</label>
                                <div className="input-group">
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Transaction / Reference ID"
                                    name="transaction_id"
                                    value={paymentSettleTransactionId}
                                    onChange={(e) => { setPaymentSettleTransactionId(e.target.value) }}
                                  />
                                </div>
                              </div>



                            </div>

                            {selectedPaymentOption === 'Cash' ?

                              <div className="col-12  mt-3">
                                <label className="">
                                  {parseFloat(onlySavePaymentTotalPaidedAmount) + parseFloat(onlySavePaymentPaidedAmount) < parseFloat(totalAmountPaid)
                                    ? "Due"
                                    : "Return to Customer"}
                                </label>


                                <div className="input-group">
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Return to Customer"
                                    name="returnToCustomer"
                                    value={
                                      // Math.abs((totalAmountPaid - onlySavePaymentTotalPaidedAmount) - onlySavePaymentPaidedAmount)
                                      Math.abs(parseFloat((totalAmountPaid - onlySavePaymentTotalPaidedAmount) - onlySavePaymentPaidedAmount + onlySavePaymentRoundUpAmount).toFixed(2))
                                    }
                                    readOnly={true}
                                  />
                                  <span className="input-group-text">
                                    {currencySymbol}
                                  </span>
                                </div>
                              </div>

                              :
                              <div className="col-12  mt-3">
                                <label className="">Note</label>
                                <div className="input-group">
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Note"
                                    name="note"


                                    value={onlySavePaymentNotes}
                                    onChange={(e) => { setOnlySavePaymentNotes(e.target.value) }}

                                  />
                                </div>
                              </div>

                            }





                          </form>

                          {selectedPaymentOption !== "Hold" && onlySavePaymentData.length > 0 && (

                            <button
                              className="btn btn-primary mt-1"
                              onClick={handleOnlySavePayment}
                              type="button"
                              disabled={
                                // onlySavePaymentPaidedAmount <= 0
                                totalAmountPaid - onlySavePaymentTotalPaidedAmount <= 0 || onlySavePaymentPaidedAmount <= 0

                              }
                            >
                              Add
                            </button>
                          )}



                          {onlySavePaymentData.length > 0 && (<>
                            <div className="itemdiv">
                              <div className="row">

                                <div className="col-lg-4">
                                  <p className="itemnm">Payment Method</p>
                                </div>

                                <div className="col-lg-3">
                                  <p className="itemnm">Amount</p>
                                </div>

                                <div className="col-lg-3">
                                  <p className="itemnm">Tip Amount</p>
                                </div>

                                <div className="col-lg-2">
                                  <p className="itemnm">Action</p>
                                </div>

                              </div>


                            </div>
                          </>)}



                          {onlySavePaymentData.map((item, index) => (
                            <div className="itemdiv" key={index}>

                              <div className="row">
                                <div className="col-lg-4">
                                  <p>{item.method === "UPI" ? item.method + " by " + item.upiType : item.method}</p>
                                </div>
                                <div className="col-lg-3">

                                  <p>{item.amount}</p>

                                </div>
                                <div className="col-lg-3">
                                  <p>{item.tip}</p>
                                </div>
                                <div className="col-lg-2">
                                  <span className="" style={{ cursor: "pointer" }} onClick={() => { handleOnlySavePaymentRemove(index) }}>
                                    <i className="fa fa-window-close closeicon" aria-hidden="true" />
                                  </span>
                                </div>
                              </div>
                            </div>
                          ))}





                        </div>

                      </div>

                      {/* <div className="itemdiv mx-2">
                        <div className="row">
                          <div className="col-lg-2">
                            <p className="itemnm">No</p>
                          </div>
                          <div className="col-lg-5">
                            <p className="itemnm">Payment Method</p>
                          </div>
                          <div className="col-lg-5">
                            <p className="itemnm">Amount</p>
                          </div>
                          

                        </div>
                      </div>
                       */}



                      <div className="modal-footer py-1">



                        {onlySavePaymentData.length > 0 ? <>




                          <button
                            className="btn btn-primary"
                            disabled={submitpartialPaymentButtonDisable}

                            onClick={submitpartialPayment}
                          >
                            Paid
                          </button>
                        </> : <>

                          {/* <div className="form-check form-check-inline mx-2">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              name="delivery"
                              defaultValue="delivery"
                              checked={onlySavePaymentRoundUpCheck}
                              onChange={handleRoundupAmountCheck}
                            />
                            <label className="form-check-label">Click here to Round Off amount.</label>
                          </div> */}
                          <button
                            className="btn btn-primary mx-1"
                            onClick={handleOnlySavePayment}
                            type="button"
                            disabled={
                              onlySavePaymentPaidedAmount <= 0
                            }
                          >
                            Save
                          </button>

                        </>}





                        <button
                          type="button"
                          className="btn btn-secondary"
                          data-dismiss="modal"
                          onClick={() => { setPartialPaymentlModel(false) }}
                        >
                          Cancel
                        </button>

                      </div>
                    </div>
                  </div>
                </div>






                <div className="modal-body py-2">
                  <div className="content-wrapper">
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="row">
                          <div className="col-sm-4">
                            <h3 className="ukhd mb-2">
                              Order Id #
                              {orderData && orderData.order_number_qrcode}
                            </h3>
                            <h5 className="mb-2">
                              #{orderData && orderData.order_number}
                            </h5>
                            {/* <p>{orderData && orderData.order_type} | {orderData && orderData.table_no}</p> */}

                            {orderData.order_type == 'dine_in' ? <>

                              <p
                                style={{
                                  display: "block",
                                }}
                              >
                                Dine In(

                                {orderData.table_no})
                              </p>

                            </> : <></>}

                            {orderData.order_type == 'take_away' ? <>
                              <p
                                style={{
                                  display: "block",
                                }}
                              >
                                Take Away

                              </p>

                            </> : <></>}

                            {orderData.order_type == 'delivery' ? <>

                              <p
                                style={{
                                  display: "block",
                                }}
                              >
                                Delivery
                              </p>
                            </> : <></>}

                            <p>Platform: {orderData && orderData.order_mode_label}</p>


                            {orderData.order_type == 'dine_in' ? <>

                              <p> {orderData && "No of Person Served:" + orderData.totalPerson}</p>

                            </> : <></>}


                          </div>
                          <div className="col-sm-4 text-center">
                            <h5 className="">
                              Status :
                              {orderData && orderData.status === 0 && (
                                <div className="badge badge-opacity-primary">
                                  {orderData.status_lable}
                                </div>
                              )}
                              {orderData && orderData.status === 1 && (
                                <div className="badge badge badge-theme">
                                  {orderData.status_lable}
                                </div>
                              )}
                              {orderData && orderData.status === 2 && (
                                <div className="badge badge-info">
                                  {orderData.status_lable}
                                </div>
                              )}
                              {orderData && orderData.status === 3 && (
                                <div className="badge badge-opacity-warning">
                                  {orderData.status_lable}
                                </div>
                              )}
                              {orderData && orderData.status === 4 && (
                                <div className="badge badge-danger">
                                  {orderData.status_lable}
                                </div>
                              )}
                            </h5>
                            <p>
                              Placed on:
                              <span className="text-primary">
                                {orderData &&
                                  formatTimestamp(orderData.creation_date)}
                              </span>
                            </p>
                          </div>
                          <div className="col-sm-4 text-right">
                            <div className="actionli">
                              <button
                                type="button"
                                className="btn btn-warning text-dark"
                                onClick={handlePrintBill}
                              >
                                <i className="mdi mdi-printer vsalign"></i>{" "}
                                Print BILL
                              </button>
                              &nbsp;
                              {orderData && orderData.status !== 0 && (
                                <button
                                  type="button"
                                  className="btn btn-dark"
                                  // onClick={handlePrintKOT}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    handlePrintKOTClick(orderID);
                                  }}
                                >
                                  <i className="mdi mdi-printer vsalign"></i>{" "}
                                  Print KOT
                                </button>
                              )}
                              &nbsp;

                              {whatsappApiAccessToken && whatsappMassageingApiUrl && sendWhatesappMessage === "1" && orderData.eater_phonenumber && orderData.status !== 0 && orderData.status !== 4 ? <button
                                type="submit mx-2 my-2"
                                className="btn btn-info"
                                onClick={() => {
                                  sendCreateOrderWhateappMassage();
                                }}
                              >
                                <i className="mdi mdi-whatsapp vsalign"></i>{" "}
                                Send Details
                              </button> : <></>}
                              {/* &nbsp;
                              {whatsappApiAccessToken && whatsappMassageingApiUrl && sendWhatesappMessage === "1"   && orderData.eater_phonenumber && fooderUpiId && orderData.status !== 0 && orderData.status !== 4 && orderData.paid !== 2? <button
                                type="submit mx-2 my-2"
                                className="btn btn-info"
                                onClick={() => {
                                  sendOrderPaymentWhateappMassage();
                                }}
                              >
                                <i className="mdi mdi-whatsapp vsalign"></i>{" "}
                                Send Payment
                              </button> : <></>} */}
                              &nbsp;
                            </div>
                          </div>
                        </div>

                        <div className="row flex-grow">
                          <div className="col-12 grid-margin stretch-card">
                            <div className="card card-rounded">
                              <div className="card-body">
                                <div className="d-sm-flex justify-content-between align-items-start mb-3 liveorder">
                                  <div className="">
                                    {orderData &&
                                      orderData.payment_status === 0 && (
                                        <>
                                          {orderData &&
                                            orderData.status === 0 && (
                                              <>
                                                <button
                                                  type="submit"
                                                  className="btn btn-success text-white"
                                                  onClick={(e) =>
                                                    handleChangeOrderStatus(
                                                      e,
                                                      orderID,
                                                      1,
                                                      orderData
                                                    )
                                                  }
                                                >
                                                  Accept
                                                </button>
                                                &nbsp;
                                                <button
                                                  type="submit"
                                                  className="btn btn-danger"
                                                  onClick={(e) =>
                                                    handleChangeOrderStatus(
                                                      e,
                                                      orderID,
                                                      4,
                                                      orderData
                                                    )
                                                  }
                                                >
                                                  Reject
                                                </button>
                                              </>
                                            )}
                                          {orderData &&
                                            orderData.status === 1 && (
                                              <button
                                                type="submit"
                                                className="btn btn-success text-white"
                                                onClick={(e) =>
                                                  handleChangeOrderStatus(
                                                    e,
                                                    orderID,
                                                    2,
                                                    orderData
                                                  )
                                                }
                                              >
                                                Mark as Order Ready
                                              </button>
                                            )}
                                        </>
                                      )}
                                  </div>

                                  <div className="">
                                    {/* {orderData &&
                                      orderData.payment_status === 0 &&
                                      orderData.status !== 0
                                      && (orderData.payment_type === "" || orderData.payment_type === 'hold')
                                      ? (
                                        <>


                                          <div>

                                            <div className="badge badge-opacity-danger kr-mouse-pointer" title={orderData.holdReason && orderData.holdReason}>
                                              Unpaid
                                              {orderData.payment_type === 'hold' && " / Hold"}{' '}
                                              {orderData.payment_type === 'hold' && <i className="fa fa-info-circle " ></i>}
                                            </div>
                                            {" "}

                                            <button
                                              type="submit"
                                              className="btn btn-info"
                                              onClick={(e) => {
                                                setPartialPaymentlModel(true)
                                                // e.preventDefault();
                                                // handlePaymentMethodClick("cash");
                                              }}
                                            >
                                              Mark as Paid
                                            </button>


                                          </div>
                                          <button
                                          type="submit"
                                          className="btn btn-info"
                                          onClick={(e) => {
                                            e.preventDefault();
                                            handlePaymentMethodClick("cash");
                                          }}
                                        >
                                          Pay By Cash
                                        </button>
                                        &nbsp;
                                        <button
                                          type="submit"
                                          className="btn btn-info"
                                          onClick={(e) => {
                                            e.preventDefault();
                                            handlePaymentMethodClick("card");
                                          }}
                                        >
                                          Pay By Card
                                        </button>
                                        &nbsp;
                                        <button
                                          type="submit"
                                          className="btn btn-info"
                                          onClick={(e) => {
                                            e.preventDefault();
                                            handlePaymentMethodClick("upi");
                                          }}
                                        >
                                          Pay By UPI
                                        </button>


                                        </>
                                      ) : (
                                        orderData &&
                                        // orderData.payment_status === 1 && 
                                        (

                                          orderData.partialPaymentDetail &&



                                            orderData.partialPaymentDetail.length > 0 ?

                                            // <div className="badge badge-opacity-success">
                                            //   {orderData.payment_status_label}
                                            // </div>

                                            <div className="badge badge-opacity-success kr-mouse-pointer" onClick={() => { setPartialPaymentDetailModel(true) }} title="Click here to view details">
                                              <u>Paid</u>
                                              {" "}
                                              <i className="fa fa-info-circle " ></i>
                                            </div>

                                            :
                                            <div>

                                              <div className="badge badge-opacity-success kr-mouse-pointer" onClick={() => { setPartialPaymentDetailModel(true) }} title="Click here to view details">
                                                <u>Paid</u>
                                                {" "}
                                                <i className="fa fa-info-circle " ></i>
                                              </div>



                                            </div>








                                          // <div className="badge badge-opacity-success">
                                          //   {orderData.payment_status_label}
                                          // </div>
                                        )
                                      )} */}

                                    {orderData &&
                                      orderData.payment_status === 0 &&
                                      (<div>
                                        <div className="badge badge-opacity-danger kr-mouse-pointer mx-2">
                                          Unpaid
                                        </div>
                                        {" "}
                                        {orderData.status !== 0 &&
                                          <button
                                            type="submit"
                                            className="btn btn-info"
                                            onClick={(e) => {
                                              setPartialPaymentlModel(true)
                                            }}
                                          >
                                            Mark as Paid
                                          </button>
                                        }
                                      </div>)
                                    }

                                    {orderData &&
                                      orderData.payment_status === 2 &&
                                      (<div>
                                        <div className="badge badge-opacity-warning kr-mouse-pointer mx-2" title={orderData.holdReason && orderData.holdReason}>
                                          Hold <i className="fa fa-info-circle " ></i>
                                        </div>
                                        {" "}
                                        {orderData.status !== 0 &&
                                          <button
                                            type="submit"
                                            className="btn btn-info"
                                            onClick={(e) => {
                                              setPartialPaymentlModel(true)
                                            }}
                                          >
                                            Mark as Paid
                                          </button>
                                        }
                                      </div>)
                                    }

                                    {orderData &&
                                      orderData.payment_status === 1 &&
                                      (<div>
                                        <div className="badge badge-opacity-success kr-mouse-pointer" onClick={() => { setPartialPaymentDetailModel(true) }} title="Click here to view details">
                                          Paid
                                          {" "}
                                          <i className="fa fa-info-circle " ></i>
                                        </div>
                                      </div>)
                                    }

                                    {orderData &&
                                      orderData.payment_status === 3 &&
                                      (<div>
                                        <div className="badge badge-opacity-success kr-mouse-pointer partially-paid-bg-collor" onClick={() => { setPartialPaymentDetailModel(true) }} title="Click here to view details">
                                          Partially Paid
                                          {" "}
                                          <i className="fa fa-info-circle " ></i>
                                        </div>
                                      </div>)
                                    }









                                    {/* {orderData &&
                                    orderData.is_cancelled === 0 &&
                                    orderData.status !== 4 ? (
                                      <>
                                        &nbsp;
                                        <button
                                          type="submit"
                                          className="btn btn-danger"
                                          onClick={(e) => {
                                            e.preventDefault();
                                            handleCancelOrderClick();
                                          }}
                                        >
                                          Cancel Order
                                        </button>
                                      </>
                                    ) : (
                                      orderData &&
                                      orderData.is_cancelled === 1 && (
                                        <div className="badge badge-danger">
                                          Order Cancelled
                                        </div>
                                      )
                                    )} */}
                                  </div>
                                </div>
                                <div className="d-sm-flex justify-content-between align-items-start mb-3">
                                  <div className="">
                                    <h4 className="card-title card-title-dash">
                                      {orderData && orderData.fooders_name}
                                    </h4>
                                    <p>
                                      {orderData && orderData.f_landline && (
                                        <>
                                          <span className="mdi mdi-phone"></span>{" "}
                                          Phone: {orderData.f_landline}
                                          <br />
                                        </>
                                      )}
                                      {orderData && orderData.f_address && (
                                        <>
                                          <span className="mdi mdi-office-building"></span>
                                          {orderData.f_address} <br />
                                          {orderData &&
                                            (orderData.f_city ||
                                              orderData.f_state ||
                                              orderData.f_zipcode) && (
                                              <>
                                                <span className="mdi mdi-home-account"></span>
                                                {orderData.f_city &&
                                                  `${orderData.f_city} ,`}{" "}
                                                {orderData.f_state &&
                                                  capitalizeFirstLetter(
                                                    orderData.f_state
                                                  )}{" "}
                                                {orderData.f_zipcode &&
                                                  ` , ${orderData.f_zipcode}`}
                                              </>
                                            )}
                                        </>
                                      )}
                                    </p>
                                  </div>
                                  <div className="">
                                    {orderData && orderData.eater_name && (
                                      <h4 className="card-title card-title-dash">
                                        {orderData.eater_name}
                                      </h4>
                                    )}

                                    {orderData &&
                                      orderData.eater_phonenumber && (
                                        <p>
                                          <span className="mdi mdi-phone"></span>{" "}
                                          Phone:{orderData.eater_phonenumber}
                                        </p>
                                      )}
                                  </div>
                                </div>

                                <div className="table-responsive orderlv">
                                  <table className="table">
                                    <thead>
                                      <tr>
                                        <th>S.No.</th>
                                        <th style={{ width: "40%" }}>
                                          Product
                                        </th>
                                        <th>Quantity</th>
                                        <th>Price</th>
                                        <th>Total</th>
                                        {/* <th className="text-right">Action</th> */}
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {orderData &&
                                        orderData.details &&
                                        orderData.details.map((item, index) => (
                                          <tr key={index}>
                                            <td className="py-1">
                                              {index + 1}
                                            </td>
                                            <td>
                                              {item.product_name}

                                              <p className="kr-none">

                                                {item.variant_details && <>
                                                  {JSON.parse(item.variant_details).combination_details && JSON.parse(item.variant_details).combination_details.map((i) => (
                                                    <><b>{i.attribute_name}: </b> {i.attribute_value_name} <br /></>
                                                  ))}
                                                </>}



                                                {item.addons_items_details && JSON.parse(item.addons_items_details).map((i, no) => (
                                                  <><b>{no + 1}.</b> {i.addon_item_name} <br /></>
                                                ))}

                                              </p>
                                            </td>
                                            <td>
                                              {/* <div className="quandiv">
                                                <button
                                                  type="button"
                                                  className="minus"
                                                  onClick={() =>
                                                    handleMinusClick(item.id)
                                                  }
                                                >
                                                  {" "}
                                                  -{" "}
                                                </button>
                                                <input
                                                  type="text"
                                                  name="quantity"
                                                  value={item.quantity}
                                                  title="Qty"
                                                  className="qty"
                                                  size="4"
                                                  readOnly={true}
                                                />
                                                <button
                                                  type="button"
                                                  className="plus"
                                                  onClick={() =>
                                                    handlePlusClick(item.id)
                                                  }
                                                >
                                                  {" "}
                                                  +{" "}
                                                </button>
                                              </div> */}
                                              <p className="kr-none">
                                                {item.quantity}
                                              </p>
                                            </td>
                                            <td>
                                              {currencySymbol}{" "}
                                              {item.product_proprice
                                                ? isValidPrice(
                                                  item.product_proprice
                                                )
                                                : isValidPrice(
                                                  item.product_price
                                                )}
                                            </td>
                                            <td>
                                              {currencySymbol}{" "}
                                              {item.product_proprice
                                                ? isValidPrice(
                                                  item.product_proprice *
                                                  item.quantity
                                                ).toFixed(2)
                                                : isValidPrice(
                                                  item.product_price *
                                                  item.quantity
                                                ).toFixed(2)}
                                            </td>
                                            {/* <td className="text-right">
                                              <i
                                                className="fs-20 mdi mdi-archive text-danger"
                                                title="Delete"
                                                style={{
                                                  cursor: "pointer",
                                                }}
                                                onClick={(e) =>
                                                  handleDelete(e, item.id)
                                                }
                                              ></i>
                                            </td> */}
                                          </tr>
                                        ))}
                                    </tbody>
                                  </table>
                                  {isVisible && (
                                    <button
                                      type="submit"
                                      className="btn btn-primary me-2"
                                      onClick={handleUpdateQuantityClick}
                                    >
                                      Update Quantity
                                    </button>
                                  )}

                                  {orderData &&
                                    orderData.payment_status === 0 && (
                                      <div>
                                        <h4 className="card-title card-title-dash mt-4 mb-2">
                                          Add New Items
                                        </h4>
                                        <form
                                          className="forms-sample"
                                          style={{ overflow: "hidden" }}
                                        >
                                          <div className="row">
                                            <div className="col-5">
                                              {/* <div className="form-group">
                                            <label>Items</label>
                                            <select
                                              className="form-select"
                                              value={productId}
                                              onChange={(e) => {
                                                setPrice("0");
                                                setProductId(e.target.value);
                                              }}
                                            >
                                              <option value="">
                                                Select Item{" "}
                                              </option>
                                              {productList &&
                                                productList.map(
                                                  (item, index) => (
                                                    <option
                                                      key={index}
                                                      value={item.product_id}
                                                    >
                                                      {item.product_name}
                                                    </option>
                                                  )
                                                )}
                                            </select>
                                          </div> */}
                                              <div className="form-group">
                                                <label>Search Items</label>
                                                <input
                                                  type="text"
                                                  className="form-control"
                                                  placeholder="Type to search"
                                                  value={searchInput}
                                                  onChange={(e) => {
                                                    setSearchInput(
                                                      e.target.value
                                                    );
                                                    setSelectedProduct({});
                                                    setShowDropDown(true);
                                                  }}
                                                />
                                                {searchInput.length >= 3 &&
                                                  showDropDown && (
                                                    <>
                                                      <div className="dropdown-menu dropdown-menu-right navbar-dropdown preview-list mt-2 p-0 show">
                                                        {filteredData ? (
                                                          <>
                                                            {filteredData.length ===
                                                              0 ? (
                                                              <>
                                                                <span className="dropdown-item py-3 border-bottom">
                                                                  <p className="mb-0 font-weight-medium float-left">
                                                                    No Data
                                                                    Found
                                                                  </p>
                                                                </span>
                                                              </>
                                                            ) : (
                                                              filteredData.map(
                                                                (
                                                                  item,
                                                                  index
                                                                ) => (
                                                                  <span
                                                                    key={index}
                                                                    style={{
                                                                      cursor:
                                                                        "pointer",
                                                                    }}
                                                                    className="dropdown-item py-3 border-bottom"
                                                                    onClick={(
                                                                      e
                                                                    ) => {
                                                                      e.preventDefault();
                                                                      setSelectedProduct(
                                                                        item
                                                                      );
                                                                      setSearchInput(
                                                                        item.product_name
                                                                      );
                                                                      setShowDropDown(
                                                                        false
                                                                      );
                                                                    }}
                                                                  >
                                                                    <p className="mb-0 font-weight-medium float-left">
                                                                      {
                                                                        item.product_name
                                                                      }
                                                                    </p>
                                                                  </span>
                                                                )
                                                              )
                                                            )}
                                                          </>
                                                        ) : (
                                                          <>
                                                            <span className="dropdown-item py-3 border-bottom">
                                                              <p className="mb-0 font-weight-medium float-left">
                                                                Loading...
                                                              </p>
                                                            </span>
                                                          </>
                                                        )}
                                                      </div>
                                                    </>
                                                  )}
                                              </div>
                                            </div>
                                            <div className="col-2">
                                              <div className="form-group">
                                                <label>Qty</label>
                                                <input
                                                  type="text"
                                                  className="form-control"
                                                  placeholder="Enter Qty"
                                                  value={quantity}
                                                  onChange={(e) =>
                                                    setQuantity(e.target.value)
                                                  }
                                                />
                                              </div>
                                            </div>
                                            <div className="col-2">
                                              <div className="form-group">
                                                <label>&nbsp;Amount</label>
                                                <input
                                                  type="text"
                                                  className="form-control"
                                                  value={

                                                    (!isNaN(quantity * price) && quantity && quantity.trim() !== ""
                                                      ? "₹" + parseFloat(quantity) * parseFloat(price)
                                                      : "")
                                                  }

                                                  readOnly={true}
                                                />
                                              </div>
                                            </div>
                                            <div className="col-3 vsalign">
                                              <button
                                                type="submit"
                                                className="btn btn-primary me-2"
                                                onClick={handleAddNewItemClick}
                                              >
                                                Add
                                              </button>
                                            </div>
                                          </div>
                                        </form>
                                      </div>
                                    )}
                                </div>

                                <hr />

                                <div className="text-right amtdiv">
                                  <h4 className="">
                                    Subtotal &nbsp;&nbsp;
                                    <span className="amount">
                                      {currencySymbol}
                                      {subTotal}
                                    </span>
                                  </h4>
                                  {discountRate &&
                                    orderData.discount_type === "percent" && (
                                      <h4 className="">
                                        Discount ({discountRate.toFixed(2)}%) &nbsp;&nbsp;
                                        <span className="amount">
                                          - {currencySymbol}
                                          {discountAmount}
                                        </span>
                                      </h4>
                                    )}

                                  {discountRate &&
                                    orderData.discount_type === "amount" && (
                                      <h4 className="">
                                        Discount ({currencySymbol}) &nbsp;&nbsp;
                                        <span className="amount">
                                          - {currencySymbol}
                                          {discountAmount}
                                        </span>
                                      </h4>
                                    )}

                                  {SCHRate !== 0 && (
                                    <h4 className="">
                                      {orderData.service_charge_details &&
                                        orderData.service_charge_details.name}
                                      ({SCHRate}
                                      %) &nbsp;&nbsp;
                                      <span className="amount">
                                        {currencySymbol}
                                        {schAmount}
                                      </span>
                                    </h4>
                                  )}

                                  {/* {CGSTRate !== 0 && (
                                    <h4 className="">
                                      CGST({CGSTRate}%) &nbsp;&nbsp;
                                      <span className="amount">
                                        {currencySymbol}
                                        {cgstAmount}
                                      </span>
                                    </h4>
                                  )}

                                  {SGSTRate !== 0 && (
                                    <h4 className="">
                                      SGST({SGSTRate}%) &nbsp;&nbsp;
                                      <span className="amount">
                                        {currencySymbol}
                                        {sgstAmount}
                                      </span>
                                    </h4>
                                  )} */}

                                  {CGSTTaxT2 !== 0 && (
                                    <h4 className="">
                                      CGST(2.5%) &nbsp;&nbsp;
                                      <span className="amount">
                                        {currencySymbol}
                                        {CGSTTaxT2}
                                      </span>
                                    </h4>
                                  )}

                                  {SGSTTaxT2 !== 0 && (
                                    <h4 className="">
                                      SGST(2.5%) &nbsp;&nbsp;
                                      <span className="amount">
                                        {currencySymbol}
                                        {SGSTTaxT2}
                                      </span>
                                    </h4>
                                  )}

                                  {CGSTTaxT3 !== 0 && (
                                    <h4 className="">
                                      CGST(6%) &nbsp;&nbsp;
                                      <span className="amount">
                                        {currencySymbol}
                                        {CGSTTaxT3}
                                      </span>
                                    </h4>
                                  )}

                                  {SGSTTaxT3 !== 0 && (
                                    <h4 className="">
                                      SGST(6%) &nbsp;&nbsp;
                                      <span className="amount">
                                        {currencySymbol}
                                        {SGSTTaxT3}
                                      </span>
                                    </h4>
                                  )}


                                  {CGSTTaxT4 !== 0 && (
                                    <h4 className="">
                                      CGST(9%) &nbsp;&nbsp;
                                      <span className="amount">
                                        {currencySymbol}
                                        {CGSTTaxT4}
                                      </span>
                                    </h4>
                                  )}

                                  {SGSTTaxT4 !== 0 && (
                                    <h4 className="">
                                      SGST(9%) &nbsp;&nbsp;
                                      <span className="amount">
                                        {currencySymbol}
                                        {SGSTTaxT4}
                                      </span>
                                    </h4>
                                  )}


                                  {CGSTTaxT5 !== 0 && (
                                    <h4 className="">
                                      CGST(14%) &nbsp;&nbsp;
                                      <span className="amount">
                                        {currencySymbol}
                                        {CGSTTaxT5}
                                      </span>
                                    </h4>
                                  )}

                                  {SGSTTaxT5 !== 0 && (
                                    <h4 className="">
                                      SGST(14%) &nbsp;&nbsp;
                                      <span className="amount">
                                        {currencySymbol}
                                        {SGSTTaxT5}
                                      </span>
                                    </h4>
                                  )}

                                  {cartProductPackingCharges !== 0 && data.order_type !== "dine_in" && (
                                    <h4 className="">
                                      Packaging Charge &nbsp;&nbsp;
                                      <span className="amount">
                                        {currencySymbol}
                                        {cartProductPackingCharges}
                                      </span>
                                    </h4>
                                  )}

                                  {orderData &&
                                    orderData.delivery_charge &&
                                    parseFloat(orderData.delivery_charge) >
                                    0 && (
                                      <h4 className="">
                                        Delivery Charge &nbsp;&nbsp;
                                        <span className="amount">
                                          {currencySymbol}
                                          {parseFloat(
                                            orderData.delivery_charge
                                          ).toFixed(2)}
                                        </span>
                                      </h4>
                                    )}

                                  {orderData &&
                                    orderData.round_up_amount &&
                                    parseFloat(orderData.round_up_amount) !==
                                    0 ?
                                    <h4 className="">
                                      Round off Amount &nbsp;&nbsp;
                                      <span className="amount">
                                        {currencySymbol}
                                        {parseFloat(
                                          orderData.round_up_amount
                                        ).toFixed(2)}
                                      </span>
                                    </h4>
                                    : <></>
                                  }

                                  {orderData &&
                                    orderData.packaging_fee &&
                                    parseFloat(orderData.packaging_fee) > 0 && (
                                      <h4 className="">
                                        Packaging Fee &nbsp;&nbsp;
                                        <span className="amount">
                                          {currencySymbol}
                                          {parseFloat(
                                            orderData.packaging_fee
                                          ).toFixed(2)}
                                        </span>
                                      </h4>
                                    )}

                                  {orderData &&
                                    (orderData.payment_status === 0 || orderData.payment_status === 2) &&
                                    parseFloat(onlySavePaymentRoundUpAmount) !==
                                    0 ?
                                    <h4 className="">
                                      Round off Amount &nbsp;&nbsp;
                                      <span className="amount">
                                        {currencySymbol}
                                        {parseFloat(
                                          onlySavePaymentRoundUpAmount
                                        ).toFixed(2)}
                                      </span>
                                    </h4>
                                    : <></>
                                  }

                                  <h3 className="mt-2 fw-bold">
                                    Total Amount : &nbsp;&nbsp;
                                    {currencySymbol}
                                    {/* {totalAmountPaid} */}

                                    {(parseFloat(totalAmountPaid) + parseFloat(onlySavePaymentRoundUpAmount)).toFixed(2)}
                                  </h3>

                                </div>

                                {orderData &&
                                  orderData.payment_status === 0 &&
                                  !orderData.coupon_code && (
                                    <form className="forms-sample">
                                      <h4 className="card-title card-title-dash mt-4 mb-2">
                                        Add Discount
                                      </h4>
                                      <div className="row">
                                        <div className="col-3">
                                          <div className="input-group mb-3 disselect">
                                            <button
                                              className="btn btn-outline-secondary dropdown-toggle"
                                              type="button"
                                              data-bs-toggle="dropdown"
                                              aria-expanded="false"
                                            >
                                              {discountValues.type === "0"
                                                ? "%"
                                                : `${currencySymbol}`}
                                            </button>
                                            <ul className="dropdown-menu">
                                              <li
                                                onClick={() =>
                                                  setDiscountValues(
                                                    (prevValues) => ({
                                                      ...prevValues,
                                                      type: "0",
                                                    })
                                                  )
                                                }
                                              >
                                                <span className="dropdown-item">
                                                  %
                                                </span>
                                              </li>
                                              <li
                                                onClick={() =>
                                                  setDiscountValues(
                                                    (prevValues) => ({
                                                      ...prevValues,
                                                      type: "1",
                                                    })
                                                  )
                                                }
                                              >
                                                <span className="dropdown-item">
                                                  {currencySymbol}
                                                </span>
                                              </li>
                                            </ul>
                                            <input
                                              type="text"
                                              className="form-control discnt"
                                              name="rate"
                                              onChange={
                                                handleDiscountValuesChange
                                              }
                                              value={discountValues.rate}
                                            />
                                          </div>
                                        </div>
                                        <div className="col-4">
                                          <button
                                            type="submit"
                                            className="btn btn-primary me-2"
                                            onClick={handleApplyDiscountClick}
                                          >
                                            Apply
                                          </button>
                                        </div>
                                      </div>
                                    </form>
                                  )}

                                {orderData.eater_suggestions && (
                                  <>
                                    <h6>Eater Suggestions*</h6>
                                    <p>{orderData.eater_suggestions}</p>
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer py-1">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-dismiss="modal"
                    onClick={() => {
                      setIsVisible(false);
                      setSelectedProduct({});
                      setSearchInput("");
                      setFilteredData(null);
                      setShowDropDown(false);
                      // setProductId("");
                      setQuantity("1");
                      setPrice("0");
                      setIsModal(false);
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>

          {/* <div
            className={
              isCancelOrderModal ? "modal fade show d-block" : "modal fade"
            }
            id="exampleModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content w-85">
                <div className="modal-header py-2">
                  <h5 className="modal-title" id="exampleModalLabel">
                    Are you sure ? You want to cancel this order ?
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() => {
                      setIsCancelOrderModal(false);
                    }}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body py-2">
                  <div className="container">
                    <div className="row">
                      <div className="row my-2">
                        <div className="col-sm-2 d-flex align-items-center">
                          <h5>Reason</h5>
                        </div>
                        <div className="col-sm-10">
                          <div className="form-group mb-0">
                            <textarea
                              className="form-control"
                              placeholder="Enter Reason"
                              rows={4}
                              value={cancelReason}
                              onChange={handleCancelOrderReasonChange}
                              maxLength={maxCharacterLimit}
                            ></textarea>
                            <p className="text-right m-0">
                              {cancelReason.length}/{maxCharacterLimit}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer py-1">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-dismiss="modal"
                    onClick={() => {
                      setIsCancelOrderModal(false);
                    }}
                  >
                    Close
                  </button>
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={handleCancelOrder}
                  >
                    Cancel Order
                  </button>
                </div>
              </div>
            </div>
          </div> */}

          <div
            className={isKOTModal ? "modal fade show d-block" : "modal fade"}
            id="exampleModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-lg" role="document">
              <div className="modal-content w-85">
                <div className="modal-header py-2">
                  <h5 className="modal-title" id="exampleModalLabel">
                    Order #
                    {dataKOTDetails &&
                      dataKOTDetails.length !== 0 &&
                      dataKOTDetails[0].order_number}{" "}
                    KOT's
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() => {
                      setIsKOTModal(false);
                    }}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body py-2">
                  <div
                    className="filter_scrl ps-2 mt-2"
                    style={{ height: "70vh" }}
                  >
                    <div className="tablenames">
                      <>
                        <div>
                          <div className="row flex-grow">
                            {dataKOTDetails.length === 0 ? (
                              <>
                                <div>No KOT Data Found</div>
                              </>
                            ) : (
                              <div>
                                <div className="row stretch-card mr10">
                                  {dataKOTDetails.map((item, index) => (
                                    <div
                                      key={index}
                                      className="col-lg-4 stretch-card"
                                    >
                                      <div className="card card-rounded grid-margin">
                                        <div className="card-body">
                                          <div className="row">
                                            <div className="col-12 text-center">
                                              <h6 className="fw-bold">
                                                {item.kot}
                                              </h6>
                                            </div>
                                            <div className="col-12 text-center">




                                              {item.table_no ? (
                                                <h6 className="fw-bold">
                                                  {/* Table No -  */}
                                                  {item.table_no}
                                                </h6>
                                              ) : (
                                                <h6 className="fw-bold">
                                                  {item.order_number
                                                    ? `#${item.order_number}`
                                                    : ""}
                                                </h6>
                                              )}




                                            </div>
                                            <div className="col-6 text-left">
                                              <h6 className="fw-bold">
                                                {item.order_type}
                                              </h6>
                                            </div>
                                            <div className="col-6 text-right">
                                              <h6 className="fw-bold">
                                                {item.time}
                                              </h6>
                                            </div>
                                          </div>
                                          <div className="row mt-2">
                                            <div className="col-5">
                                              <p>Item</p>
                                            </div>
                                            <div className="col-2 text-center">
                                              <p>Qty</p>
                                            </div>
                                            <div className="col-4 text-center">
                                              <p>Price</p>
                                            </div>
                                          </div>
                                          <div className="crdscrl">
                                            {item.final_kot_details.map(
                                              (kotItem, index) => (
                                                <div
                                                  key={index}
                                                  className="row"
                                                >
                                                  <div className="col-5">
                                                    <p>
                                                      {capitalizeFirstLetter(
                                                        kotItem.product_name
                                                      )}
                                                    </p>
                                                  </div>
                                                  <div className="col-2 text-center">
                                                    <p>{kotItem.quantity}</p>
                                                  </div>
                                                  <div className="col-4 text-center">

                                                    <p>{currencySymbol}
                                                      {kotItem.product_proprice
                                                        ? isValidPrice(
                                                          kotItem.product_proprice
                                                        )
                                                        : isValidPrice(
                                                          kotItem.product_price
                                                        )}
                                                    </p>
                                                  </div>
                                                </div>
                                              )
                                            )}
                                          </div>
                                          <div className="row mt-2">
                                            <div className="col-lg-12 text-center">
                                              <button
                                                type="submit"
                                                className="btn btn-success"
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  setPrintKOTDetails2(item);
                                                }}
                                              >
                                                Print
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </>
                    </div>
                  </div>
                </div>
                <div className="modal-footer py-1">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-dismiss="modal"
                    onClick={() => {
                      setIsKOTModal(false);
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div style={{ display: "none" }}>
            <div id="contentToPrintBill">
              <p className="name" style={{ textAlign: "end" }}>

                {orderData &&
                  orderData.invoice_number}

              </p>
              <div className="printcss">

                <div className="col-12">


                  <p className="name">
                    <strong>
                      {orderData &&
                        capitalizeFirstLetter(orderData.fooders_name)}
                    </strong>
                  </p>

                  <p className="name">

                    {orderData &&
                      capitalizeFirstLetter(orderData.Fooder_name2)}

                  </p>

                  <p className="name">
                    {orderData && capitalizeFirstLetter(orderData.f_address)}{', '}
                    {orderData &&
                      (orderData.f_city || orderData.f_state || orderData.f_zipcode) && (
                        <>
                          {orderData.f_city && `${orderData.f_city}, `}
                          {orderData.f_state && capitalizeFirstLetter(orderData.f_state)}
                          {orderData.f_zipcode && `, ${orderData.f_zipcode}`}
                        </>
                      )}
                  </p>
                  {orderData.f_landline ? <p className="name">Phone No : {' '}
                    {orderData.f_landline}

                  </p> : <></>}
                </div>



                <div className="col-12">
                  {orderData && orderData.fooders_gstin && (
                    <p className="gst thank">
                      GST Number : {orderData && orderData.fooders_gstin}
                    </p>
                  )}
                </div>
                <table className="tblecss info">

                  <tbody>


                    <tr>
                      <td>
                        Name : {" "}
                        {capitalizeFirstLetter(orderData.eater_name)}
                      </td>
                    </tr>

                    {orderData.eater_phonenumber && orderData.eater_phonenumber !== '' ? <tr>
                      <td>
                        Phone : {" "}
                        {capitalizeFirstLetter(orderData.eater_phonenumber)}
                      </td>
                    </tr> : <></>}


                    {orderData.address && orderData.address !== '' ? <tr>
                      <td>
                        Address : {" "}
                        {capitalizeFirstLetter(orderData.address)}
                      </td>
                    </tr> : <></>}



                  </tbody>

                </table>
                <table className="tblecss info">
                  <tbody>
                    <tr>
                      <td>
                        Date :{" "}
                        <span>
                          {formatTimestampForDate(orderData.creation_date)}
                        </span>
                      </td>
                      <td className="txtrg">
                        Time :{" "}
                        <span>
                          {formatTimestampForTime(orderData.creation_date)}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      {/* <td>
                        {orderData && orderData.order_type && (
                          <strong>
                            {orderData.order_type === "dine_in"
                              ? orderData.table_no
                              : orderData.order_type
                                .toUpperCase()
                                .replace("_", " ")}
                          </strong>
                        )}
                      </td> */}
                      {orderData.order_type == 'dine_in' ? <>

                        <td
                          style={{
                            display: "block",
                          }}
                        >
                          Dine In(

                          {orderData.table_no})
                        </td>

                      </> : <></>}

                      {orderData.order_type == 'take_away' ? <>
                        <td
                          style={{
                            display: "block",
                          }}
                        >
                          Take Away

                        </td>

                      </> : <></>}

                      {orderData.order_type == 'delivery' ? <>

                        <td
                          style={{
                            display: "block",
                          }}
                        >
                          Delivery
                        </td>
                      </> : <></>}
                      <td className="txtrg">
                        Order : #{orderData && orderData.order_number_qrcode}
                      </td>
                    </tr>
                    <tr>
                      <th colSpan="2">
                        <p
                          style={{
                            textAlign: "center",
                            padding: "5px 2px",
                            margin: "0",
                            fontSize: "medium",
                          }}
                        >
                          Original Receipt
                        </p>
                      </th>
                    </tr>
                  </tbody>
                </table>
                <table className="tblecss2">
                  <thead>
                    <tr>
                      <th className="thcss itemth">Item</th>
                      <th className="thcss qtyth txtct">Qty</th>
                      <th className="thcss rateth txtrg">Rate</th>
                      <th className="thcss amtth txtrg">Amt</th>
                    </tr>
                  </thead>

                  <tbody>
                    {orderData &&
                      orderData.details &&
                      orderData.details.map((order, index) => (
                        <tr key={index}>
                          <td className="tdcss nametd">
                            {capitalizeFirstLetter(order.product_name)}
                            {order.variant_details && <><br /></>}
                            {order.variant_details && <>
                              {JSON.parse(order.variant_details).combination_details && JSON.parse(order.variant_details).combination_details.map((i) => (
                                <><b>{i.attribute_name}: </b> {i.attribute_value_name} <br /></>
                              ))}
                            </>}

                            {order.addons_items_details && JSON.parse(order.addons_items_details).map((i, no) => (
                              <><b>{no + 1}.</b> {i.addon_item_name} <br /></>
                            ))}

                          </td>
                          <td className="tdcss qtytd txtct">
                            {order.quantity}
                          </td>
                          <td className="tdcss ratetd txtrg">
                            {currencySymbol}
                            {order.product_proprice
                              ? isValidPrice(order.product_proprice)
                              : isValidPrice(order.product_price)}
                          </td>
                          <td className="tdcss amttd txtrg">
                            {currencySymbol}
                            {isValidPrice(
                              (order.product_proprice ? order.product_proprice : order.product_price) * order.quantity
                            ).toFixed(2)}

                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
                <table className="tblecss2">
                  <tbody>
                    <tr>
                      <td className="txtrg brtp subtl">Subtotal</td>
                      <td className="txtrg brtp">
                        {currencySymbol}
                        {subTotal}
                      </td>
                    </tr>
                    {discountRate && orderData.discount_type === "percent" && (
                      <tr>
                        <td className="txtrg subtl">
                          Discount ({discountRate.toFixed(2)}%)
                        </td>
                        <td className="txtrg">
                          {currencySymbol}
                          {discountAmount}
                        </td>
                      </tr>
                    )}
                    {discountRate && orderData.discount_type === "amount" && (
                      <tr>
                        <td className="txtrg subtl">
                          Discount ({currencySymbol})
                        </td>
                        <td className="txtrg">
                          {currencySymbol}
                          {discountAmount}
                        </td>
                      </tr>
                    )}
                    {SCHRate !== 0 && (
                      <tr>
                        <td className="txtrg subtl">
                          {orderData.service_charge_details &&
                            orderData.service_charge_details.name}
                          ({SCHRate}
                          %)
                        </td>
                        <td className="txtrg">
                          {currencySymbol}
                          {schAmount}
                        </td>
                      </tr>
                    )}
                    {/* {CGSTRate !== 0 && (
                      <tr>
                        <td className="txtrg subtl">CGST({CGSTRate}%)</td>
                        <td className="txtrg">
                          {currencySymbol}
                          {cgstAmount}
                        </td>
                      </tr>
                    )}
                    {SGSTRate !== 0 && (
                      <tr>
                        <td className="txtrg subtl">SGST({SGSTRate}%)</td>
                        <td className="txtrg">
                          {currencySymbol}
                          {sgstAmount}
                        </td>
                      </tr>
                    )} */}


                    {CGSTTaxT2 !== 0 &&
                      (<tr>
                        <td className="txtrg subtl">CGST(2.5%)</td>
                        <td className="txtrg">
                          {currencySymbol}
                          {CGSTTaxT2}
                        </td>
                      </tr>)
                    }

                    {SGSTTaxT2 !== 0 &&
                      (<tr>
                        <td className="txtrg subtl">SGST(2.5%)</td>
                        <td className="txtrg">
                          {currencySymbol}
                          {SGSTTaxT2}
                        </td>
                      </tr>)
                    }


                    {CGSTTaxT3 !== 0 &&
                      (<tr>
                        <td className="txtrg subtl">CGST(6%)</td>
                        <td className="txtrg">
                          {currencySymbol}
                          {CGSTTaxT3}
                        </td>
                      </tr>)
                    }

                    {SGSTTaxT3 !== 0 &&
                      (<tr>
                        <td className="txtrg subtl">SGST(6%)</td>
                        <td className="txtrg">
                          {currencySymbol}
                          {SGSTTaxT3}
                        </td>
                      </tr>)
                    }


                    {CGSTTaxT4 !== 0 &&
                      (<tr>
                        <td className="txtrg subtl">CGST(9%)</td>
                        <td className="txtrg">
                          {currencySymbol}
                          {CGSTTaxT4}
                        </td>
                      </tr>)
                    }

                    {SGSTTaxT4 !== 0 &&
                      (<tr>
                        <td className="txtrg subtl">SGST(9%)</td>
                        <td className="txtrg">
                          {currencySymbol}
                          {SGSTTaxT4}
                        </td>
                      </tr>)
                    }


                    {CGSTTaxT5 !== 0 &&
                      (<tr>
                        <td className="txtrg subtl">CGST(14%)</td>
                        <td className="txtrg">
                          {currencySymbol}
                          {CGSTTaxT5}
                        </td>
                      </tr>)
                    }

                    {SGSTTaxT5 !== 0 &&
                      (<tr>
                        <td className="txtrg subtl">SGST(14%)</td>
                        <td className="txtrg">
                          {currencySymbol}
                          {SGSTTaxT5}
                        </td>
                      </tr>)
                    }

                    {cartProductPackingCharges !== 0 && orderData.order_type !== "dine_in" &&
                      (<tr>
                        <td className="txtrg subtl">Packaging Charges</td>
                        <td className="txtrg">
                          {currencySymbol}
                          {cartProductPackingCharges}
                        </td>
                      </tr>)
                    }



                    {orderData &&
                      orderData.delivery_charge &&
                      parseFloat(orderData.delivery_charge) > 0 && (
                        <tr>
                          <td className="txtrg subtl">Delivery Charge</td>
                          <td className="txtrg">
                            {currencySymbol}
                            {parseFloat(orderData.delivery_charge).toFixed(2)}
                          </td>
                        </tr>
                      )}
                    {orderData &&
                      orderData.packaging_fee &&
                      parseFloat(orderData.packaging_fee) > 0 && (
                        <tr>
                          <td className="txtrg subtl">Packaging Fee</td>
                          <td className="txtrg">
                            {currencySymbol}
                            {parseFloat(orderData.packaging_fee).toFixed(2)}
                          </td>
                        </tr>
                      )}
                    {orderData &&
                      orderData.round_up_amount &&
                      parseFloat(orderData.round_up_amount) !== 0 ? <>
                      <tr>
                        <td className="txtrg subtl">Round off</td>
                        <td className="txtrg">
                          {currencySymbol}
                          {parseFloat(orderData.round_up_amount).toFixed(2)}
                        </td>
                      </tr>
                    </> : <></>}

                    {orderData &&
                      (orderData.payment_status === 0 || orderData.payment_status === 2) &&
                      parseFloat(onlySavePaymentRoundUpAmount) !== 0 ? <>
                      <tr>
                        <td className="txtrg subtl">Round off</td>
                        <td className="txtrg">
                          {currencySymbol}
                          {parseFloat(onlySavePaymentRoundUpAmount).toFixed(2)}
                        </td>
                      </tr>
                    </> : <></>}







                    <tr>
                      <th className="tlt">Total</th>
                      <th className="tlt txtrg pd0">
                        {currencySymbol}
                        {/* {totalAmountPaid} */}
                        {(parseFloat(totalAmountPaid) + parseFloat(onlySavePaymentRoundUpAmount)).toFixed(2)}
                      </th>
                    </tr>
                  </tbody>
                </table>
                <table className="tblecss">
                  <tbody>
                    <tr>
                      <td>
                        {orderData && orderData.fooders_fssai_number && (
                          <p className="thank">
                            FSSAI Number : {orderData.fooders_fssai_number}
                          </p>
                        )}
                      </td>
                    </tr>
                    {/* {orderData.payment_type ? <tr>
                      <td>
                        <p className="thank">Paid By {orderData.payment_type}</p>
                      </td>
                    </tr> : <></>} */}

                    {orderData ? <tr>
                      <td>
                        {/* <p className="thank">Paid By {data.payment_type}</p> */}
                        {orderData.payment_status === 0 && (
                          <p className="thank">Unpaid</p>
                        )
                        }
                        {orderData.payment_status === 1 && (
                          <p className="thank">Paid</p>
                        )
                        }
                        {orderData.payment_status === 3 && (
                          <p className="thank">Partially Paid</p>
                        )
                        }
                        {orderData.payment_status === 2 && (
                          <p className="thank">Hold</p>
                        )
                        }
                      </td>
                    </tr> : <></>}
                    <tr>
                      <td>
                        <p className="thank">{orderData.billing_notes}</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p className="thank">Thank you for your visit!</p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div style={{ display: "none" }}>
            <div id="contentToPrintKOT">
              <div className="printcss">
                <div className="col-12">
                  <p className="name">
                    <strong>
                      {orderData &&
                        capitalizeFirstLetter(orderData.fooders_name)}
                    </strong>
                  </p>
                </div>
                <div className="col-12">
                  {orderData && orderData.fooders_gstin && (
                    <p className="gst thank">
                      GST Number : {orderData && orderData.fooders_gstin}
                    </p>
                  )}
                </div>

                <table className="tblecss info">
                  <tbody>
                    <tr>
                      <td>
                        Date :{" "}
                        <span>
                          {formatTimestampForDate(orderData.creation_date)}
                        </span>
                      </td>
                      <td className="txtrg">
                        Time :{" "}
                        <span>
                          {formatTimestampForTime(orderData.creation_date)}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {orderData && orderData.order_type && (
                          <strong>
                            {orderData.order_type === "dine_in"
                              ? orderData.table_no
                              : orderData.order_type
                                .toUpperCase()
                                .replace("_", " ")}
                          </strong>
                        )}
                      </td>
                      <td className="txtrg">
                        Order : #{orderData && orderData.order_number}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table className="tblecss2">
                  <thead>
                    <tr>
                      <th className="thcss itemth">Item</th>
                      <th className="thcss qtyth txtrg">Qty</th>
                      {/* <th className="thcss rateth txtrg">Rate</th> */}
                    </tr>
                  </thead>

                  <tbody>
                    {orderData &&
                      orderData.details &&
                      orderData.details.map((order, index) => (
                        <tr key={index}>
                          <td className="tdcss nametd">
                            {capitalizeFirstLetter(order.product_name)}
                            <br />
                            <i>
                              {order.product_special_note}
                            </i>
                          </td>
                          <td className="tdcss qtytd txtrg">
                            {order.quantity}
                          </td>
                          {/* <td className="tdcss ratetd txtrg">
                            {currencySymbol}{" "}
                            {order.product_proprice
                              ? order.product_proprice
                              : order.product_price}
                          </td> */}
                        </tr>
                      ))}
                  </tbody>
                </table>
                <table className="tblecss">
                  <tbody>
                    <tr>
                      <td>
                        {orderData && orderData.eater_suggestions && (
                          <p className="brtpd">
                            INSTRUCTIONS : {orderData.eater_suggestions}
                          </p>
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div style={{ display: "none" }}>
            <div id="contentToPrintKOT2">
              <div className="printcss">
                <div className="col-12">
                  {printKOTDetails2 && (
                    <p className="gst thank">
                      <strong>{printKOTDetails2.kot}</strong>
                    </p>
                  )}
                </div>

                <table className="tblecss info">
                  <tbody>
                    <tr>
                      <td>
                        Date :{" "}
                        <span>
                          {printKOTDetails2 &&
                            printKOTDetails2.date &&
                            formatTimestampForDate(printKOTDetails2.date)}
                        </span>
                      </td>
                      <td className="txtrg">
                        Time :{" "}
                        <span>{printKOTDetails2 && printKOTDetails2.time}</span>
                      </td>
                    </tr>
                    <tr>
                      {printKOTDetails2 && printKOTDetails2.order_type && (
                        <td>
                          <strong>
                            {printKOTDetails2.order_type === "DINE IN"
                              ?

                              // `Table No: ${printKOTDetails2.table_no}`
                              `${printKOTDetails2.table_no}`

                              : printKOTDetails2.order_type}
                          </strong>
                        </td>
                      )}

                      <td className="txtrg">
                        Order : #
                        {printKOTDetails2 && printKOTDetails2.order_number}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table className="tblecss2">
                  <thead>
                    <tr>
                      <th className="thcss itemth">Item</th>
                      <th className="thcss qtyth txtrg">Qty</th>
                    </tr>
                  </thead>

                  <tbody>
                    {printKOTDetails2 &&
                      printKOTDetails2.final_kot_details.map((order, index) => (
                        <tr key={index}>
                          <td className="tdcss nametd">
                            {capitalizeFirstLetter(order.product_name)}




                            {order.selectedvariants && order.selectedvariants.combination_details && order.selectedvariants.combination_details.map((i) => (
                              <> <br /><i>{i.attribute_name} :  {i.attribute_value_name} </i> </>
                            ))}

                            {order.selectedAddons && order.selectedAddons.map((i) => (
                              <> <br /> <i>{i.addon_item_name}</i></>
                            ))}

                            {order.product_special_note ? <> <br /> <i> {order.product_special_note} </i> </> : <></>}
                            {/* <br />
                            <i>
                              {order.product_special_note}
                            </i> */}
                          </td>
                          <td className="tdcss qtytd txtrg">
                            {order.quantity}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          {/* <div style={{ display: "none" }}>
            <div id="contentToPrintKOTForLiveOrder">
              <div className="printcss">
                <div className="col-12">
                  <p className="name">
                    <strong>
                      {orderKOTData &&
                        capitalizeFirstLetter(orderKOTData.fooders_name)}
                    </strong>
                  </p>
                </div>
                <div className="col-12">
                  {orderKOTData && orderKOTData.fooders_gstin && (
                    <p className="gst thank">
                      GST Number : {orderKOTData && orderKOTData.fooders_gstin}
                    </p>
                  )}
                </div>

                <table className="tblecss info">
                  <tbody>
                    <tr>
                      <td>
                        Date :{" "}
                        <span>
                          {formatTimestampForDate(orderKOTData.creation_date)}
                        </span>
                      </td>
                      <td className="txtrg">
                        Time :{" "}
                        <span>
                          {formatTimestampForTime(orderKOTData.creation_date)}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {orderKOTData && orderKOTData.order_type && (
                          <strong>
                            {orderKOTData.order_type === "dine_in"
                              ? orderKOTData.table_no
                              : orderKOTData.order_type
                                  .toUpperCase()
                                  .replace("_", " ")}
                          </strong>
                        )}
                      </td>
                      <td className="txtrg">
                        Order : #{orderKOTData && orderKOTData.order_number}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table className="tblecss2">
                  <thead>
                    <tr>
                      <th className="thcss itemth">Item</th>
                      <th className="thcss qtyth txtrg">Qty</th>
                    </tr>
                  </thead>

                  <tbody>
                    {orderKOTData &&
                      orderKOTData.details &&
                      orderKOTData.details.map((order, index) => (
                        <tr key={index}>
                          <td className="tdcss nametd">
                            {capitalizeFirstLetter(order.product_name)}
                          </td>
                          <td className="tdcss qtytd txtrg">
                            {order.quantity}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
                <table className="tblecss">
                  <tbody>
                    <tr>
                      <td>
                        {orderKOTData && orderKOTData.eater_suggestions && (
                          <p className="brtpd">
                            INSTRUCTIONS : {orderKOTData.eater_suggestions}
                          </p>
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div> */}

          {showDeleteModal && (
            <DeleteModal
              handleModal={setShowDeleteModal}
              handleDelete={handleDeleteOrderItem}
            />
          )}

          <LoadingModal isLoading={isLoading} />

          <Notification handleFlag={setFlag} flagValue={flag} />
        </>
      )}
    </>
  );
};

export default LiveOrders;
