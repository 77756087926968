import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import SideBar from "../../other/SideBar";
import NavBar from "../../other/NavBar";
import {
    GetSalesReportDailyURL,
    ScriptId,
    ScriptSRC,
} from "../../../assets/api/BaseURL";
import LoadingModal from "../../../loader/LoadingModal";
import Notification from "../../other/Notification";
import { useToast } from "../../../../context/ToastContext";
import jsPDF from "jspdf";
import "jspdf-autotable";
import * as XLSX from "xlsx";

const ReportByDailySales = () => {
    const navigate = useNavigate();
    const { addToast } = useToast();
    const [token, setToken] = useState("");
    const [toggleSideBar, setToggleSideBar] = useState(false);
    const [canvas, setCanvas] = useState(false);
    const [data, setData] = useState({});
    const [orderTypeCounts, setOrderTypeCounts] = useState({});

    const [reportData, setReportData] = useState({
        totalSubtotal: "",
        totalDiscount: "",
        totalTotal: "",
        totalServiceCharge: "",
        totalTax: "",
        currency_symbol: "",
        start_date: "",
        end_date: "",
    });
    const [reportDataByType, setReportDataByType] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState({
        start_date: getToday(),
        end_date: "",
        order_mode: "",
    });

    const handleToggleSidebar = (e) => {
        e.preventDefault();
        setToggleSideBar(!toggleSideBar);
    };

    const handleToggleOffCanvas = (e) => {
        e.preventDefault();
        setCanvas(!canvas);
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleBackClick = (e) => {
        e.preventDefault();
        navigate("/reports");
    };

    function formatDateRange(inputDate) {
        const parsedDate = new Date(inputDate);
        const day = parsedDate.getDate().toString().padStart(2, "0");
        const month = (parsedDate.getMonth() + 1).toString().padStart(2, "0");
        const year = parsedDate.getFullYear().toString();

        return day + month + year;
    }

    function getToday() {
        const today = new Date();
        const yyyy = today.getFullYear();
        let mm = today.getMonth() + 1;
        let dd = today.getDate();

        if (mm < 10) mm = "0" + mm;
        if (dd < 10) dd = "0" + dd;

        return `${yyyy}-${mm}-${dd}`;
    }

    function getCurrentDateFormatted() {
        const today = new Date();
        const day = today.getDate();
        const month = today.getMonth() + 1;
        const year = today.getFullYear();

        const formattedDate = `${day < 10 ? "0" : ""}${day}/${month < 10 ? "0" : ""
            }${month}/${year}`;

        return formattedDate;
    }

    function formatDate(inputDate) {
        const [year, month, day] = inputDate.split("-");

        const date = new Date(year, month - 1, day);

        const formattedDay = date.getDate().toString().padStart(2, "0");
        const formattedMonth = (date.getMonth() + 1).toString().padStart(2, "0");
        const formattedYear = date.getFullYear();

        const formattedDate = `${formattedDay}/${formattedMonth}/${formattedYear}`;

        return formattedDate;
    }

    const handleResetClick = (e) => {
        e.preventDefault();
        setFormData({
            start_date: getToday(),
            end_date: "",
            order_mode: "",
        });
        setData({});

    };

    const handleSubmitClick = async (e) => {
        e.preventDefault();


        setIsLoading(true);
        try {
            const response = await axios.get(GetSalesReportDailyURL, {
                params: {
                    date: formData.start_date,
                    // end_date: formData.end_date,
                    // order_mode: formData.order_mode,
                },
                headers: { authorization: `Bearer ${token}` },
            });
            setIsLoading(false);
            // console.log(response.data.data)

            if (response.status === 200 && response.data.salesByOrderType) {
                // console.log("++++++++++++++++++++++++")

                // console.log(response.data)
                setData(response.data);
                setOrderTypeCounts(response.data.orderTypeCounts)
                setReportDataByType(
                    response.data.orderTypeTotals ? response.data.orderTypeTotals : null
                );

            } else if (response.status === 200 && response.data.message) {
                addToast("success", response.data.message);
                setData({});
                setData({});

            }
        } catch (error) {
            if (error.response && error.response.data.message) {
                addToast("error", error.response.data.message);
            } else {
                addToast("error", error.message);
            }
            console.log(error);
            setIsLoading(false);
        }
    };

    const downloadPdfDocument = () => {
        const doc = new jsPDF();

        doc.autoTable({
            html: "#pdfContent",
            styles: {
                font: "times",
                fontSize: 10,
                cellPadding: 1,
                overflow: "linebreak",
            },
            headStyles: {
                fillColor: "#006495",
                textColor: "#ffffff",
                halign: "right",
                valign: "middle",
                fontStyle: "bold",
                fontSize: 7,
                margin: 0

            },
            bodyStyles: {
                fillColor: [255, 255, 255],
                textColor: [0, 0, 0],
                halign: "right",
                valign: "middle",
            },
            footStyles: {
                fillColor: [220, 220, 220],
                textColor: [0, 0, 0],
                halign: "left",
                valign: "middle",
                fontStyle: "bold",
            },


            margin: { top: 10, left: 10, right: 10 },

            didParseCell: function (data) {
                if (data.row.section === "body" && data.column.dataKey === 0) {
                    // Left align the first column cells
                    data.cell.styles.halign = "left";
                }
                if (data.row.section === "head" && data.row.index === 0) {
                    // Center align the cells in the first row (header row)
                    data.cell.styles.halign = "center";
                }
                if (data.cell && data.cell.raw instanceof HTMLTableCellElement) {
                    const h4 = data.cell.raw.querySelector('h4');
                    if (h4) {
                        data.cell.styles.fontSize = 16;
                        data.cell.styles.halign = 'center';
                        data.cell.styles.textColor = '#ffcb44';
                        data.cell.styles.font = 'gilroy';
                    }
                }
                if (data.cell && data.cell.raw instanceof HTMLTableCellElement) {
                    const h5 = data.cell.raw.querySelector('h5');
                    if (h5) {
                        data.cell.styles.fontSize = 9;
                        data.cell.styles.halign = 'left';
                        data.cell.styles.fontStyle = 'bold';

                    }
                }
                if (data.cell && data.cell.raw instanceof HTMLTableCellElement) {
                    const h6 = data.cell.raw.querySelector('h6');
                    if (h6) {
                        data.cell.styles.fontSize = 12;
                        data.cell.styles.halign = 'center';
                        data.cell.styles.fontStyle = 'bold';

                    }
                }
            },

        });

        doc.save(
            `Daily-Sales-Report-${formatDateRange(formData.start_date)}.pdf`
        );


    };

    // const exportToExcel = () => {
    //     var table_elt = document.getElementById("pdfContent");

    //     var workbook = XLSX.utils.table_to_book(table_elt);

    //     XLSX.writeFile(
    //         workbook,
    //         `Dalily-Sales-Report-${getToday()}.xlsx`
    //     );
    // };
    const exportToExcel = () => {
        var table_elt = document.getElementById("excelContent");

        // Get the first row of the table
        var firstRow = table_elt.rows[0];

        // Apply text-align: center style to each cell in the first row
        for (var i = 0; i < firstRow.cells.length; i++) {
            firstRow.cells[i].style.textAlign = "center";
        }

        var workbook = XLSX.utils.table_to_book(table_elt);

        XLSX.writeFile(
            workbook,
            `Daily-Sales-Report-${getToday()}.xlsx`
        );
    };

    useEffect(() => {
        if (!localStorage.getItem("token")) {
            navigate("/");
        }
    }, [navigate]);

    useEffect(() => {
        setToken(localStorage.getItem("token"));
    }, [token]);

    useEffect(() => {
        const existingScript = document.getElementById(ScriptId);
        if (!existingScript && localStorage.getItem("userId")) {
            const userId = localStorage.getItem("userId");
            const script = document.createElement("script");
            script.src = ScriptSRC + userId.replace(/"/g, "");
            script.defer = true;
            script.id = ScriptId;
            document.head.appendChild(script);
        }
    }, []);

    return (
        <>
            {token && (
                <>
                    <div
                        className={
                            toggleSideBar
                                ? "container-scroller sidebar-icon-only"
                                : "container-scroller"
                        }
                    >
                        <nav className="navbar default-layout col-lg-12 col-12 p-0 fixed-top d-flex align-items-top flex-row">

                            <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-start">
                                <Link className="navbar-brand brand-logo-mini" to="/live_orders">
                                    <img src="images/mini-kr-logo.svg" alt="logo" />
                                </Link>
                                <div className="me-1">
                                    <button
                                        className={
                                            toggleSideBar
                                                ? "navbar-toggler navbar-toggler align-self-center"
                                                : "navbar-toggler navbar-toggler align-self-center text-white"
                                        }
                                        type="button"
                                        onClick={handleToggleSidebar}
                                    >
                                        <span className="icon-menu"></span>
                                    </button>
                                </div>
                                <div>
                                    <Link to="/live_orders">
                                        <span className="navbar-brand brand-logo">
                                            <img src="images/Khateraho_logo_white.png" alt="logo" />
                                        </span>
                                    </Link>
                                </div>
                            </div>
                            <NavBar handleToggle={handleToggleOffCanvas} />
                        </nav>
                        <div className="container-fluid page-body-wrapper px-0">
                            <SideBar active="reports" toggleCanvas={toggleSideBar} />

                            <div className="main-panel">
                                <div className="content-wrapper">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="d-flex justify-content-between align-items-start mb-2">
                                                <div className="vsalign col-sm-8">
                                                    <h3 className="ukhd mb-3">Daily Sales Report</h3>
                                                    <p className="list-group-item-text mb-0">
                                                        A daily sales report is a record of a business' sales activity for any one day. It includes statistics on the amount of products sold, revenue generated, applicable discounts given, and other relevant factors.                                                    </p>
                                                </div>
                                                <div className="vsalign">
                                                    <span
                                                        className="btn btn-danger mb-0 me-0"
                                                        onClick={handleBackClick}
                                                    >
                                                        <i className="menu-icon mdi mdi-arrow-left vsalign"></i>
                                                        Go Back
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="row flex-grow">
                                                <div className="col-12 grid-margin stretch-card">
                                                    <div className="card card-rounded">
                                                        <div className="card-body">
                                                            <div className="row">
                                                                <div className="col-3 vsalign">
                                                                    <div className="form-group">
                                                                        <label>
                                                                            Date
                                                                            <span className="text-danger">*</span>
                                                                        </label>
                                                                        <input
                                                                            type="date"
                                                                            className="form-control"
                                                                            name="start_date"
                                                                            max={getToday()}
                                                                            value={formData.start_date}
                                                                            onChange={handleChange}
                                                                        />
                                                                    </div>
                                                                </div>

                                                                <div className="col-lg-6 vsalign">
                                                                    <button
                                                                        type="submit"
                                                                        className="btn btn-primary me-2"
                                                                        onClick={handleSubmitClick}
                                                                    >
                                                                        Submit
                                                                    </button>
                                                                    <button
                                                                        className="btn btn btn-secondary"
                                                                        onClick={handleResetClick}
                                                                    >
                                                                        Reset
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            {Object.keys(data).length > 0 ? <>
                                                                <div className="d-sm-flex justify-content-between align-items-start">
                                                                    <div className="vsalign"></div>
                                                                    <div className="vsalign">
                                                                        <button
                                                                            type="submit"
                                                                            className="btn btn-primary me-2"
                                                                            onClick={downloadPdfDocument}
                                                                        >
                                                                            Generate PDF
                                                                        </button>
                                                                        <button
                                                                            type="submit"
                                                                            className="btn btn-primary me-2"
                                                                            onClick={exportToExcel}
                                                                        >
                                                                            Export To Excel
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="table-responsive mt-1">
                                                                        <table id="tempPdfContent" className="table table-bordered">
                                                                            <thead>
                                                                                <tr className="text-center">
                                                                                    <th colSpan={8}>
                                                                                        <h4 className="p-0 mb-0"> {localStorage.getItem('restaurantName')} (Daily Sales Report)</h4>
                                                                                    </th>
                                                                                </tr>


                                                                                <tr className="text-center">
                                                                                    <th colSpan={8}>Report's Date : {formatDate(formData.start_date)}</th>

                                                                                </tr>
                                                                                <tr></tr>
                                                                            </thead>
                                                                            <tbody>

                                                                                <tr>

                                                                                    <td>
                                                                                        <ol className="mainli">

                                                                                            <li>

                                                                                                <u>
                                                                                                    <b>Summary:</b>
                                                                                                </u>
                                                                                                <ul className="lstdot">

                                                                                                    <li>Total Sales: ₹{data.totalOfAllTotals}</li>
                                                                                                    <li>Total Transactions: {data.totalTransaction}</li>
                                                                                                    <li>Average Transaction Value: ₹{data.average_transation}</li>
                                                                                                    <li>No of Person Served: {data.totalEaters}</li>
                                                                                                    <li>Aquire Customer: {data.totalAquireCustomerCount}</li>
                                                                                                </ul>
                                                                                            </li>
                                                                                            <li className="mt10">

                                                                                                <u>
                                                                                                    <b>Sales Breakdown by Order Types:</b>
                                                                                                </u>
                                                                                                <ul className="lstdot">
                                                                                                    <li>
                                                                                                        Delivery:
                                                                                                        <ul>
                                                                                                            {/* 
                                                                                                            <li>Subtotal: {data.salesByOrderType.delivery.subtotal}</li>
                                                                                                            <li>Discount: {data.salesByOrderType.delivery.discount}</li>
                                                                                                            <li>Total: {data.salesByOrderType.delivery.total}</li>
                                                                                                            <li>ServiceCharge: {data.salesByOrderType.delivery.serviceCharge}</li>
                                                                                                            <li>Subtotal: {data.salesByOrderType.delivery.subtotal}</li>
                                                                                                            <li>Tax: {data.salesByOrderType.delivery.tax}</li> */}

                                                                                                            {/* {data.salesByOrderType.delivery.total ? <li>Total Sales: {data.salesByOrderType.delivery.total}</li> : <></>} */}
                                                                                                            {orderTypeCounts.delivery ? <>

                                                                                                                <li>Total Sales: ₹{data.salesByOrderType.delivery.total}</li>
                                                                                                                <li>Transactions: {data.orderTypeCounts.delivery}</li>

                                                                                                            </> : <>
                                                                                                                <li>Total Sales: ₹0</li>
                                                                                                                <li>Transactions: 0</li>
                                                                                                            </>}



                                                                                                        </ul>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        Dine In:
                                                                                                        <ul>
                                                                                                            {/* <li>Subtotal: {data.salesByOrderType.dine_in.subtotal}</li>
                                                                                                            <li>Discount: {data.salesByOrderType.dine_in.discount}</li>
                                                                                                            <li>Total: {data.salesByOrderType.dine_in.total}</li>
                                                                                                            <li>ServiceCharge: {data.salesByOrderType.dine_in.serviceCharge}</li>
                                                                                                            <li>Subtotal: {data.salesByOrderType.dine_in.subtotal}</li>
                                                                                                            <li>Tax: {data.salesByOrderType.dine_in.tax}</li> */}
                                                                                                            {/* <li>Total Sales: {data.salesByOrderType.dine_in.total}</li>


                                                                                                            <li>Transactions: {data.orderTypeCounts.dine_in}</li> */}

                                                                                                            {orderTypeCounts.dine_in ? <>

                                                                                                                <li>Total Sales: ₹{data.salesByOrderType.dine_in.total}</li>
                                                                                                                <li>Transactions: {data.orderTypeCounts.dine_in}</li>

                                                                                                            </> : <>
                                                                                                                <li>Total Sales: ₹0</li>
                                                                                                                <li>Transactions: 0</li>
                                                                                                            </>}


                                                                                                        </ul>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        Take Away:
                                                                                                        <ul>
                                                                                                            {/* <li>Subtotal: {data.salesByOrderType.take_away.subtotal}</li>
                                                                                                            <li>Discount: {data.salesByOrderType.take_away.discount}</li>
                                                                                                            <li>Total: {data.salesByOrderType.take_away.total}</li>
                                                                                                            <li>ServiceCharge: {data.salesByOrderType.take_away.serviceCharge}</li>
                                                                                                            <li>Subtotal: {data.salesByOrderType.take_away.subtotal}</li>
                                                                                                            <li>Tax: {data.salesByOrderType.take_away.tax}</li> */}
                                                                                                            {/* {data.salesByOrderType.take_away.total ? <li>Total Sales: {data.salesByOrderType.take_away.total}</li> : <></>} */}

                                                                                                            {/* <li>Total Sales: {data.salesByOrderType.take_away.total}</li> */}
                                                                                                            {/* <li>Transactions: {data.orderTypeCounts.take_away}</li> */}



                                                                                                            {orderTypeCounts.take_away ? <>

                                                                                                                <li>Total Sales: ₹{data.salesByOrderType.take_away.total}</li>
                                                                                                                <li>Transactions: {data.orderTypeCounts.take_away}</li>

                                                                                                            </> : <>
                                                                                                                <li>Total Sales: ₹0</li>
                                                                                                                <li>Transactions: 0</li>
                                                                                                            </>}

                                                                                                        </ul>
                                                                                                    </li>
                                                                                                </ul>
                                                                                            </li>

                                                                                            <li className="mt10">
                                                                                                {" "}
                                                                                                <u>
                                                                                                    <b>Category Sales Breakdown:</b>
                                                                                                </u>
                                                                                                <ul className="lstdot">

                                                                                                    {data.menuTotalsArray.map(item => (
                                                                                                        <li key={item.menu_name}>{item.menu_name}: ₹{item.total}</li>
                                                                                                    ))}
                                                                                                </ul>
                                                                                            </li>

                                                                                            {data.paymentType ? <li className="mt10">
                                                                                                {" "}
                                                                                                <u>
                                                                                                    <b>Payment Type Breakdown:</b>
                                                                                                </u>
                                                                                                <ul className="lstdot">
                                                                                                     

                                                                                                    <li>Card: {data.currency_symbol}{parseFloat(data.paymentType.card).toFixed(2)}</li>
                                                                                                    <li>Cash: {data.currency_symbol}{parseFloat(data.paymentType.cash).toFixed(2)}</li>
                                                                                                    <li>UPI: {data.currency_symbol}{parseFloat(data.paymentType.upi).toFixed(2)}</li>
                                                                                                    <li>NEFT: {data.currency_symbol}{parseFloat(data.paymentType.neft).toFixed(2)}</li>

                                                                                                </ul>
                                                                                            </li> : <></>}




                                                                                            <li className="mt10">
                                                                                                {" "}
                                                                                                <u>
                                                                                                    <b>Discounts:</b>
                                                                                                </u>
                                                                                                <ul className="lstdot">
                                                                                                    <li>Total Discounts: ₹
                                                                                                        {(
                                                                                                            (orderTypeCounts.dine_in ? parseFloat(data.salesByOrderType.dine_in.discount) : 0) +
                                                                                                            (orderTypeCounts.take_away ? parseFloat(data.salesByOrderType.take_away.discount) : 0) +
                                                                                                            (orderTypeCounts.delivery ? parseFloat(data.salesByOrderType.delivery.discount) : 0)
                                                                                                        ).toFixed(2)}
                                                                                                    </li>


                                                                                                </ul>
                                                                                            </li>
                                                                                            <li className="mt10">
                                                                                                {" "}
                                                                                                <u>
                                                                                                    <b>Service Charge:</b>
                                                                                                </u>
                                                                                                <ul className="lstdot">
                                                                                                    <li>Total Service Charge: ₹
                                                                                                        {(
                                                                                                            (orderTypeCounts.dine_in ? parseFloat(data.salesByOrderType.dine_in.serviceCharge) : 0) +
                                                                                                            (orderTypeCounts.take_away ? parseFloat(data.salesByOrderType.take_away.serviceCharge) : 0) +
                                                                                                            (orderTypeCounts.delivery ? parseFloat(data.salesByOrderType.delivery.serviceCharge) : 0)
                                                                                                        ).toFixed(2)}
                                                                                                    </li>


                                                                                                </ul>
                                                                                            </li>

                                                                                            <li className="mt10">
                                                                                                {" "}
                                                                                                <u>
                                                                                                    <b>Taxes: </b>
                                                                                                </u>
                                                                                                <ul className="lstdot">
                                                                                                    <li>Total Tax: ₹
                                                                                                        {(
                                                                                                            (orderTypeCounts.dine_in ? parseFloat(data.salesByOrderType.dine_in.tax) : 0) +
                                                                                                            (orderTypeCounts.take_away ? parseFloat(data.salesByOrderType.take_away.tax) : 0) +
                                                                                                            (orderTypeCounts.delivery ? parseFloat(data.salesByOrderType.delivery.tax) : 0)
                                                                                                        ).toFixed(2)}
                                                                                                    </li>


                                                                                                </ul>
                                                                                            </li>




                                                                                            {/* 
                                                                                            <li className="mt10">
                                                                                                {" "}
                                                                                                <u>
                                                                                                    <b>Discounts and Promotions:</b>
                                                                                                </u>
                                                                                                <ul className="lstdot">
                                                                                                    <li>Total Discounts: $________</li>
                                                                                                    <li>Promotion A - Sales Impact: $________</li>
                                                                                                    <li>Promotion B - Sales Impact: $________</li>
                                                                                                </ul>
                                                                                            </li>
                                                                                            <li className="mt10">
                                                                                                {" "}
                                                                                                <u>
                                                                                                    <b>Sales by Server:</b>
                                                                                                </u>
                                                                                                <ul className="lstdot">
                                                                                                    <li>Server A: $________</li>
                                                                                                    <li>Server B: $________</li>
                                                                                                    <li>Server C: $________</li>
                                                                                                </ul>
                                                                                            </li>
                                                                                            <li className="mt10">
                                                                                                {" "}
                                                                                                <u>
                                                                                                    <b>Sales by Time of Day:</b>
                                                                                                </u>
                                                                                                <ul className="lstdot">
                                                                                                    <li>Morning (6 am - 12 pm): $________</li>
                                                                                                    <li>Afternoon (12 pm - 4 pm): $________</li>
                                                                                                    <li>Evening (4 pm - 10 pm): $________</li>
                                                                                                </ul>
                                                                                            </li>
                                                                                            <li className="mt10">
                                                                                                {" "}
                                                                                                <u>
                                                                                                    <b> Notes and Observations:</b>
                                                                                                </u>
                                                                                                <ul className="lstdot">
                                                                                                    <li>
                                                                                                        [Include any notable incidents or observations that may impact
                                                                                                        sales, such as events, promotions, or issues.]
                                                                                                    </li>
                                                                                                </ul>
                                                                                            </li>
                                                                                            <li className="mt10">
                                                                                                {" "}
                                                                                                <u>
                                                                                                    <b> Closing Comments:</b>
                                                                                                </u>
                                                                                                <ul className="lstdot">
                                                                                                    <li>
                                                                                                        [Any additional comments or insights regarding the day's sales
                                                                                                        performance.]
                                                                                                    </li>
                                                                                                </ul>
                                                                                            </li> */}
                                                                                        </ol>
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>

                                                                        <div style={{ display: 'none' }}>
                                                                            <table id="pdfContent" className="table table-bordered">
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th colSpan={8}>
                                                                                            <center>
                                                                                                <h4> {localStorage.getItem('restaurantName')} (Daily Sales Report)</h4>
                                                                                            </center>
                                                                                        </th>
                                                                                    </tr>

                                                                                    <tr style={{ textAlign: 'right' }}>
                                                                                        <th colSpan={8}>Download Date : {formatDate(getToday())}</th>
                                                                                    </tr>
                                                                                    <tr style={{ textAlign: 'right' }}>
                                                                                        <th colSpan={8}>Report's Date : {formatDate(formData.start_date)}</th>

                                                                                    </tr>

                                                                                </thead>
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <th colSpan={8}>
                                                                                            <h6>Summary</h6>
                                                                                        </th>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td colSpan={4}>
                                                                                            Total Sales:
                                                                                        </td>
                                                                                        <td colSpan={4}>
                                                                                            {"Rs."}{data.totalOfAllTotals}
                                                                                        </td>
                                                                                    </tr>




                                                                                    <tr>
                                                                                        <td colSpan={4}>
                                                                                            Total Transactions:
                                                                                        </td>
                                                                                        <td colSpan={4}>
                                                                                            {data.totalTransaction}
                                                                                        </td>

                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td colSpan={4}>
                                                                                            Average Transaction Value:
                                                                                        </td>
                                                                                        <td colSpan={4}>
                                                                                            {"Rs."}{data.average_transation}
                                                                                        </td>

                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td colSpan={4}>
                                                                                            No of Person Served:
                                                                                        </td>
                                                                                        <td colSpan={4}>
                                                                                            {data.totalEaters}
                                                                                        </td>

                                                                                    </tr>

                                                                                    <tr>
                                                                                        <td colSpan={4}>
                                                                                            Aquire Customer:
                                                                                        </td>
                                                                                        <td colSpan={4}>
                                                                                            {data.totalAquireCustomerCount}
                                                                                        </td>

                                                                                    </tr>




                                                                                    <tr>
                                                                                        <th colSpan={8}>
                                                                                            <h6>Sales Breakdown by Order Types</h6>
                                                                                        </th>
                                                                                    </tr>

                                                                                    <tr>
                                                                                        <th>
                                                                                            Delivery
                                                                                        </th>
                                                                                    </tr>


                                                                                    {orderTypeCounts.delivery ?
                                                                                        <tr>
                                                                                            <th colSpan={4}>

                                                                                                Total Sales: {"Rs."}{data.salesByOrderType.delivery.total}</th>
                                                                                            <th colSpan={4}>

                                                                                                Transactions: {data.orderTypeCounts.delivery}</th>
                                                                                        </tr>

                                                                                        : <tr>
                                                                                            <th colSpan={4}>

                                                                                                Total Sales: {"Rs."}0</th>
                                                                                            <th colSpan={4}>

                                                                                                Transactions: 0</th>
                                                                                        </tr>}


                                                                                    <tr>
                                                                                        <th>
                                                                                            Dine In:
                                                                                        </th>
                                                                                    </tr>

                                                                                    {orderTypeCounts.dine_in ?
                                                                                        <tr>
                                                                                            <th colSpan={4}>

                                                                                                Total Sales: {"Rs."}{data.salesByOrderType.dine_in.total}</th>
                                                                                            <th colSpan={4}>

                                                                                                Transactions: {data.orderTypeCounts.dine_in}</th>
                                                                                        </tr>

                                                                                        : <tr>
                                                                                            <th colSpan={4}>

                                                                                                Total Sales: {"Rs."}0</th>
                                                                                            <th colSpan={4}>

                                                                                                Transactions: 0</th>
                                                                                        </tr>}



                                                                                    <tr>
                                                                                        <th colSpan={4}>
                                                                                            Take Away:
                                                                                        </th >
                                                                                    </tr>
                                                                                    {orderTypeCounts.take_away ?
                                                                                        <tr>
                                                                                            <th colSpan={4}>

                                                                                                Total Sales: {"Rs."}{data.salesByOrderType.take_away.total}
                                                                                            </th>
                                                                                            <th colSpan={4}>

                                                                                                Transactions: {data.orderTypeCounts.take_away}
                                                                                            </th>
                                                                                        </tr>

                                                                                        : <tr>
                                                                                            <th colSpan={4}>

                                                                                                Total Sales: {"Rs."}0</th>
                                                                                            <th colSpan={4}>

                                                                                                Transactions: 0</th>
                                                                                        </tr>}

                                                                                    <tr>
                                                                                        <th colSpan={8}>
                                                                                            <h6 >Category Sales Breakdown</h6>


                                                                                        </th>
                                                                                    </tr>

                                                                                    {data.menuTotalsArray.map(item => (
                                                                                        <tr key={item.menu_name}>
                                                                                            <th colSpan={4}>
                                                                                                {item.menu_name}:
                                                                                            </th>
                                                                                            <th colSpan={4}>
                                                                                                {"Rs."}{item.total}
                                                                                            </th>
                                                                                        </tr>
                                                                                    ))}

                                                                                    {data.paymentType ? <>


                                                                                        <tr>
                                                                                            <th colSpan={8}>
                                                                                                <h6 >Payment Type Breakdown</h6>


                                                                                            </th>
                                                                                        </tr>

                                                                                        <tr>
                                                                                            <th colSpan={4}>
                                                                                                Card:
                                                                                            </th>
                                                                                            <th colSpan={4}>
                                                                                                {"Rs."}{parseFloat(data.paymentType.card).toFixed(2)}
                                                                                            </th>
                                                                                        </tr>

                                                                                        <tr>
                                                                                            <th colSpan={4}>
                                                                                                Cash:
                                                                                            </th>
                                                                                            <th colSpan={4}>
                                                                                               
                                                                                                {"Rs."}{parseFloat(data.paymentType.cash).toFixed(2)}
                                                                                            </th>
                                                                                        </tr>

                                                                                        <tr>
                                                                                            <th colSpan={4}>
                                                                                                UPI:
                                                                                            </th>
                                                                                            <th colSpan={4}>
                                                                                            {"Rs."}{parseFloat(data.paymentType.upi).toFixed(2)}
                                                                                                 
                                                                                            </th>
                                                                                        </tr>

                                                                                        <tr>
                                                                                            <th colSpan={4}>
                                                                                                NEFT:
                                                                                            </th>
                                                                                            <th colSpan={4}>
                                                                                               
                                                                                                {"Rs."}{parseFloat(data.paymentType.neft).toFixed(2)}
                                                                                            </th>
                                                                                        </tr>
                                                                                    </> : <></>}





                                                                                    <tr >
                                                                                        <th colSpan={8}>
                                                                                            <h6 > Discounts</h6>
                                                                                        </th>
                                                                                    </tr>

                                                                                    <tr >
                                                                                        <th colSpan={4}>
                                                                                            Total Discounts:
                                                                                        </th>
                                                                                        <th colSpan={4}>
                                                                                            {"Rs."}


                                                                                            {(
                                                                                                (orderTypeCounts.dine_in ? parseFloat(data.salesByOrderType.dine_in.discount) : 0) +
                                                                                                (orderTypeCounts.take_away ? parseFloat(data.salesByOrderType.take_away.discount) : 0) +
                                                                                                (orderTypeCounts.delivery ? parseFloat(data.salesByOrderType.delivery.discount) : 0)
                                                                                            ).toFixed(2)}
                                                                                        </th>
                                                                                    </tr>
                                                                                    {/* ////////////////// */}


                                                                                    <tr >
                                                                                        <th colSpan={8}>
                                                                                            <h6 >
                                                                                                Service Charge
                                                                                            </h6>
                                                                                        </th>
                                                                                    </tr>

                                                                                    <tr >
                                                                                        <th colSpan={4}>
                                                                                            Total Service Charge:
                                                                                        </th>
                                                                                        <th colSpan={4}>
                                                                                            {"Rs."}

                                                                                            {(
                                                                                                (orderTypeCounts.dine_in ? parseFloat(data.salesByOrderType.dine_in.serviceCharge) : 0) +
                                                                                                (orderTypeCounts.take_away ? parseFloat(data.salesByOrderType.take_away.serviceCharge) : 0) +
                                                                                                (orderTypeCounts.delivery ? parseFloat(data.salesByOrderType.delivery.serviceCharge) : 0)
                                                                                            ).toFixed(2)}
                                                                                        </th>
                                                                                    </tr>
                                                                                    <tr >
                                                                                        <th colSpan={8}>
                                                                                            <h6 >
                                                                                                Tax
                                                                                            </h6>
                                                                                        </th>
                                                                                    </tr>
                                                                                    <tr >
                                                                                        <th colSpan={4}>
                                                                                            Total Taxes:
                                                                                        </th>
                                                                                        <th colSpan={4}>
                                                                                            {"Rs."}
                                                                                            {(
                                                                                                (orderTypeCounts.dine_in ? parseFloat(data.salesByOrderType.dine_in.tax) : 0) +
                                                                                                (orderTypeCounts.take_away ? parseFloat(data.salesByOrderType.take_away.tax) : 0) +
                                                                                                (orderTypeCounts.delivery ? parseFloat(data.salesByOrderType.delivery.tax) : 0)
                                                                                            ).toFixed(2)}
                                                                                        </th>
                                                                                    </tr>





                                                                                </tbody>
                                                                            </table>

                                                                        </div>
                                                                        <div style={{ display: 'none' }}>
                                                                            <table id="excelContent">
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th>
                                                                                            <center>
                                                                                                <h4> {localStorage.getItem('restaurantName')} (Daily Sales Report)</h4>
                                                                                            </center>
                                                                                        </th>
                                                                                    </tr>


                                                                                    <tr>
                                                                                        <th>Download Date : {formatDate(getToday())}</th>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <th>Report's Date : {formatDate(formData.start_date)}</th>
                                                                                    </tr>

                                                                                </thead>
                                                                                <tbody>
                                                                                    <tr /><tr />
                                                                                    <tr>
                                                                                        <th>
                                                                                            <b>Summary:</b>
                                                                                        </th>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td>
                                                                                            Total Sales: {"Rs."}{data.totalOfAllTotals}
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td>
                                                                                            Total Transactions: {data.totalTransaction}
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td>
                                                                                            Average Transaction Value: {"Rs."}{data.average_transation}
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td>
                                                                                            No of Person Served: {data.totalEaters}
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td>
                                                                                            Aquire Customer: {data.totalAquireCustomerCount}
                                                                                        </td>
                                                                                    </tr>


                                                                                    <tr /><tr />
                                                                                    <tr>
                                                                                        <th>
                                                                                            <b>Sales Breakdown by Order Types:</b>
                                                                                        </th>
                                                                                    </tr>
                                                                                    <tr />

                                                                                    <tr>
                                                                                        <th>
                                                                                            Delivery
                                                                                        </th>
                                                                                    </tr>

                                                                                    {orderTypeCounts.delivery ?
                                                                                        <tr>
                                                                                            <th colSpan={4}>

                                                                                                Total Sales: {"Rs."}{data.salesByOrderType.delivery.total}</th>
                                                                                            <th colSpan={4}>

                                                                                                Transactions: {data.orderTypeCounts.delivery}</th>
                                                                                        </tr>

                                                                                        : <tr>
                                                                                            <th colSpan={4}>

                                                                                                Total Sales: {"Rs."}0</th>
                                                                                            <th colSpan={4}>

                                                                                                Transactions: 0</th>
                                                                                        </tr>}

                                                                                    <tr />
                                                                                    <tr>
                                                                                        <th>
                                                                                            Dine In:
                                                                                        </th>
                                                                                    </tr>

                                                                                    {orderTypeCounts.dine_in ?
                                                                                        <tr>
                                                                                            <th>
                                                                                                Total Sales: {"Rs."}{data.salesByOrderType.dine_in.total}</th>
                                                                                            <th>
                                                                                                Transactions: {data.orderTypeCounts.dine_in}</th>
                                                                                        </tr>

                                                                                        : <tr>
                                                                                            <th>

                                                                                                Total Sales: {"Rs."}0</th>
                                                                                            <th>

                                                                                                Transactions: 0</th>
                                                                                        </tr>}


                                                                                    <tr />

                                                                                    <tr>
                                                                                        <th>
                                                                                            Take Away:
                                                                                        </th>
                                                                                    </tr>
                                                                                    {orderTypeCounts.take_away ?
                                                                                        <tr>
                                                                                            <th>
                                                                                                Total Sales: {"Rs."}{data.salesByOrderType.take_away.total}</th>
                                                                                            <th>
                                                                                                Transactions: {data.orderTypeCounts.take_away}</th>
                                                                                        </tr>

                                                                                        : <tr>
                                                                                            <th>

                                                                                                Total Sales: {"Rs."}0</th>
                                                                                            <th>

                                                                                                Transactions: 0</th>
                                                                                        </tr>
                                                                                    }
                                                                                    <tr />

                                                                                    <tr>
                                                                                        <th>
                                                                                            <b>Category Sales Breakdown:</b>
                                                                                        </th>
                                                                                    </tr>
                                                                                    {data.menuTotalsArray.map(item => (
                                                                                        <tr key={item.menu_name}>
                                                                                            <th>
                                                                                                {item.menu_name}:
                                                                                            </th>
                                                                                            <th>
                                                                                                {"Rs."}{item.total}
                                                                                            </th>
                                                                                        </tr>
                                                                                    ))}

                                                                                    {data.paymentType ? <>
                                                                                        <tr />


                                                                                        <tr>
                                                                                            <th>
                                                                                                <h6 >Payment Type Breakdown</h6>


                                                                                            </th>
                                                                                        </tr>

                                                                                        <tr>
                                                                                            <th>
                                                                                                Card:
                                                                                            </th>
                                                                                            <th>
                                                                                                {"Rs."}{parseFloat(data.paymentType.card).toFixed(2)}
                                                                                            </th>
                                                                                        </tr>

                                                                                        <tr>
                                                                                            <th>
                                                                                                Cash:
                                                                                            </th>
                                                                                            <th>
                                                                                             
                                                                                                {"Rs."}{parseFloat(data.paymentType.cash).toFixed(2)}
                                                                                            </th>
                                                                                        </tr>

                                                                                        <tr>
                                                                                            <th>
                                                                                                UPI:
                                                                                            </th>
                                                                                            <th>
                                                                                            {"Rs."}{parseFloat(data.paymentType.upi).toFixed(2)}
                                                                                                 
                                                                                                
                                                                                            </th>
                                                                                        </tr>

                                                                                        <tr>
                                                                                            <th>
                                                                                                NEFT:
                                                                                            </th>
                                                                                            <th>
                                                                                            {"Rs."}{parseFloat(data.paymentType.neft).toFixed(2)}

                                                                                                
                                                                                            </th>
                                                                                        </tr>

                                                                                    </> : <></>}


                                                                                    <tr />

                                                                                    <tr>
                                                                                        <th>
                                                                                            Discounts:
                                                                                        </th>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <th>
                                                                                            Total Discounts:
                                                                                        </th>
                                                                                        <th>
                                                                                            {"Rs."}

                                                                                            {(
                                                                                                (orderTypeCounts.dine_in ? parseFloat(data.salesByOrderType.dine_in.discount) : 0) +
                                                                                                (orderTypeCounts.take_away ? parseFloat(data.salesByOrderType.take_away.discount) : 0) +
                                                                                                (orderTypeCounts.delivery ? parseFloat(data.salesByOrderType.delivery.discount) : 0)
                                                                                            ).toFixed(2)}
                                                                                        </th>
                                                                                    </tr>
                                                                                    <tr />


                                                                                    <tr>
                                                                                        <th>
                                                                                            ServiceCharge:
                                                                                        </th>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <th>
                                                                                            Service Charge:
                                                                                        </th>
                                                                                        <th>
                                                                                            {"Rs."}
                                                                                            {(
                                                                                                (orderTypeCounts.dine_in ? parseFloat(data.salesByOrderType.dine_in.serviceCharge) : 0) +
                                                                                                (orderTypeCounts.take_away ? parseFloat(data.salesByOrderType.take_away.serviceCharge) : 0) +
                                                                                                (orderTypeCounts.delivery ? parseFloat(data.salesByOrderType.delivery.serviceCharge) : 0)
                                                                                            ).toFixed(2)}


                                                                                        </th>
                                                                                    </tr>


                                                                                    <tr />

                                                                                    <tr>
                                                                                        <th>
                                                                                            Taxes:
                                                                                        </th>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <th>
                                                                                            Total Tax:
                                                                                        </th>
                                                                                        <th>
                                                                                            {"Rs."}

                                                                                            {(
                                                                                                (orderTypeCounts.dine_in ? parseFloat(data.salesByOrderType.dine_in.tax) : 0) +
                                                                                                (orderTypeCounts.take_away ? parseFloat(data.salesByOrderType.take_away.tax) : 0) +
                                                                                                (orderTypeCounts.delivery ? parseFloat(data.salesByOrderType.delivery.tax) : 0)
                                                                                            ).toFixed(2)}
                                                                                        </th>
                                                                                    </tr>




                                                                                </tbody>
                                                                            </table>

                                                                        </div>


                                                                    </div>
                                                                </div>
                                                            </> : <></>}



                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <LoadingModal isLoading={isLoading} />

                    <Notification />
                </>
            )}
        </>
    );
};

export default ReportByDailySales;
