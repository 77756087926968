import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import SideBar from "../other/SideBar";
import NavBar from "../other/NavBar";
import {
  AddVerificationDetailsURL,
  GetVerificationDetailsURL,
  ScriptId,
  ScriptSRC,
} from "../../assets/api/BaseURL";
import LoadingModal from "../../loader/LoadingModal";
import Notification from "../other/Notification";
import { useToast } from "../../../context/ToastContext";

const Verification = () => {
  const navigate = useNavigate();
  const { addToast } = useToast();
  const [token, setToken] = useState("");
  const [data, setData] = useState([]);
  const [flag, setFlag] = useState(false);
  const fileInputRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [canvas, setCanvas] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [toggleSideBar, setToggleSideBar] = useState(false);
  const [documentType1Data, setDocumentType1Data] = useState([]);
  const [documentType2Data, setDocumentType2Data] = useState([]);
  const [documentType3Data, setDocumentType3Data] = useState([]);
  const [documentType4Data, setDocumentType4Data] = useState([]);
  const [formData, setFormData] = useState({
    documentType: "",
    documentFile: "",
  });

  const handleToggleSidebar = (e) => {
    e.preventDefault();
    setToggleSideBar(!toggleSideBar);
  };

  const handleToggleOffCanvas = (e) => {
    e.preventDefault();
    setCanvas(!canvas);
  };

  const handleSelectChange = (e) => {
    setFormData({
      ...formData,
      documentType: e.target.value,
    });
  };

  const handleFileChange = (e) => {
    setFormData({
      ...formData,
      documentFile: e.target.files[0],
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { documentType, documentFile } = formData;
    if (documentType === "") {
      setError("Document Type can not be empty");
      setSuccess("");
    } else if (documentFile === "") {
      setError("Document File can not be empty");
      setSuccess("");
    } else {
      const dataToSend = new FormData();
      dataToSend.append("document_type", documentType);
      dataToSend.append("document_name", documentFile);

      try {
        const response = await axios({
          method: "post",
          url: AddVerificationDetailsURL,
          data: dataToSend,
          headers: {
            "Content-Type": "multipart/form-data",
            authorization: `Bearer ${token}`,
          },
        });

        if (response.status === 200 && response.data.message) {
          setError("");
          setSuccess(response.data.message);
          setFormData({
            documentType: "",
            documentFile: "",
          });
          fileInputRef.current.value = null;
          setFlag(!flag);
        }
      } catch (error) {
        if (error.response && error.response.data.message) {
          setError(error.response.data.message);
          setSuccess("");
        } else {
          setError(error.message);
          setSuccess("");
        }
        console.log(error);
      }
    }
  };

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/");
    }
  }, [navigate]);

  useEffect(() => {
    setToken(localStorage.getItem("token"));
  }, [token]);

  useEffect(() => {
    const existingScript = document.getElementById(ScriptId);
    if (!existingScript && localStorage.getItem("userId")) {
      const userId = localStorage.getItem("userId");
      const script = document.createElement("script");
      script.src = ScriptSRC + userId.replace(/"/g, "");
      script.defer = true;
      script.id = ScriptId;
      document.head.appendChild(script);
    }
  }, []);

  useEffect(() => {
    if (token) {
      async function getData() {
        setIsLoading(true);
        try {
          const response = await axios.get(GetVerificationDetailsURL, {
            headers: { authorization: `Bearer ${token}` },
          });

          if (response.status === 200 && response.data.data) {
            setData(response.data.data);
          }
        } catch (error) {
          if (error.response && error.response.data.message) {
            addToast("error", error.response.data.message);
          } else {
            addToast("error", error.message);
          }
          console.log(error);
        }
        setIsLoading(false);
      }
      getData();
    }
  }, [token, flag, addToast]);

  useEffect(() => {
    const categorizeData = () => {
      const dataByDocumentType = {
        1: [],
        2: [],
        3: [],
        4: [],
      };

      data.forEach((item) => {
        dataByDocumentType[item.document_type].push(item);
      });

      setDocumentType1Data(dataByDocumentType["1"]);
      setDocumentType2Data(dataByDocumentType["2"]);
      setDocumentType3Data(dataByDocumentType["3"]);
      setDocumentType4Data(dataByDocumentType["4"]);
    };

    categorizeData();
  }, [data]);

  useEffect(() => {
    if (error || success) {
      window.scrollTo(0, 0);
    }
  }, [error, success]);

  return (
    <>
      {token && (
        <>
          <div
            className={
              toggleSideBar
                ? "container-scroller sidebar-icon-only"
                : "container-scroller"
            }
          >
            <nav className="navbar default-layout col-lg-12 col-12 p-0 fixed-top d-flex align-items-top flex-row">
              <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-start">
                <div className="me-1">
                  <button
                    className={
                      toggleSideBar
                        ? "navbar-toggler navbar-toggler align-self-center"
                        : "navbar-toggler navbar-toggler align-self-center text-white"
                    }
                    type="button"
                    onClick={handleToggleSidebar}
                  >
                    <span className="icon-menu"></span>
                  </button>
                </div>
                <div>
                  <Link to="/live_orders">
                    <span className="navbar-brand brand-logo">
                      <img src="images/Khateraho_logo_white.png" alt="logo" />
                    </span>
                  </Link>
                </div>
              </div>
              <NavBar handleToggle={handleToggleOffCanvas} />
            </nav>
            <div className="container-fluid page-body-wrapper px-0">
              <SideBar active="verification" toggleCanvas={toggleSideBar} />

              <div className="main-panel">
                <div className="content-wrapper">
                  <div className="row">
                    <div className="col-sm-12">
                      <h3 className="ukhd mb-3">Verification</h3>
                      <div className="row">
                        <div className="col-md-5">
                          <div className="row flex-grow">
                            <div className="col-12 grid-margin stretch-card">
                              <div className="card card-rounded">
                                <div className="card-body">
                                  <div className="d-sm-flex justify-content-between align-items-start">
                                    <div className="vsalign">
                                      <h4 className="card-title card-title-dash mb-3">
                                        Upload documents
                                      </h4>
                                    </div>
                                  </div>
                                  <form className="forms-sample">
                                    {error && (
                                      <div className="d-flex justify-content-between align-items-start alert alert-danger">
                                        <div className="vsalign">{error}</div>
                                        <div className="vsalign">
                                          <span
                                            className=".h3 text-muted"
                                            style={{ cursor: "pointer" }}
                                            onClick={() => setError("")}
                                          >
                                            x
                                          </span>
                                        </div>
                                      </div>
                                    )}
                                    {success && (
                                      <div className="d-flex justify-content-between align-items-start alert alert-success">
                                        <div className="vsalign">{success}</div>
                                        <div className="vsalign">
                                          <span
                                            className=".h3 text-muted"
                                            style={{ cursor: "pointer" }}
                                            onClick={() => setSuccess("")}
                                          >
                                            x
                                          </span>
                                        </div>
                                      </div>
                                    )}
                                    <div className="row">
                                      <div className="col-12">
                                        <div className="form-group">
                                          <label>
                                            Document Type
                                            <span className="text-danger">
                                              *
                                            </span>
                                          </label>
                                          <select
                                            className="form-select"
                                            onChange={handleSelectChange}
                                            value={formData.documentType}
                                          >
                                            <option value="">
                                              Select Document Type
                                            </option>
                                            <option value="1">
                                              FSSAI License
                                            </option>
                                            <option value="2">PAN Card</option>
                                            <option value="3">GST</option>
                                            <option value="4">Menu</option>
                                          </select>
                                        </div>
                                      </div>

                                      <div className="col-12">
                                        <div className="form-group">
                                          <label> Choose Document </label>
                                          <input
                                            className="form-control"
                                            type="file"
                                            accept=".jpeg, .jpg, .png, .pdf"
                                            id="formFile"
                                            onChange={handleFileChange}
                                            ref={fileInputRef}
                                          />
                                          <label className="text-danger">
                                            Only pdf, jpeg, jpg and png files
                                            allowed
                                          </label>
                                        </div>
                                      </div>
                                    </div>

                                    <button
                                      type="submit"
                                      className="btn btn-primary me-2"
                                      onClick={handleSubmit}
                                    >
                                      Submit
                                    </button>
                                    {/* <button className="btn btn btn-secondary">
                                  Cancel
                                </button> */}
                                  </form>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-7">
                          <div className="row flex-grow">
                            <div className="col-12 grid-margin stretch-card">
                              <div className="card card-rounded">
                                <div className="card-body">
                                  <div
                                    className="accordion"
                                    id="accordionPanelsStayOpenExample"
                                  >
                                    <div className="accordion-item">
                                      <h2
                                        className="accordion-header"
                                        id="panelsStayOpen-headingOne"
                                      >
                                        <button
                                          className="accordion-button collapsed"
                                          type="button"
                                          data-bs-toggle="collapse"
                                          data-bs-target="#panelsStayOpen-collapseOne"
                                          aria-expanded="false"
                                          aria-controls="panelsStayOpen-collapseOne"
                                        >
                                          FSSAI License
                                        </button>
                                      </h2>
                                      <div
                                        id="panelsStayOpen-collapseOne"
                                        className="accordion-collapse collapse"
                                        aria-labelledby="panelsStayOpen-headingOne"
                                      >
                                        <div className="accordion-body">
                                          {documentType1Data.length === 0 ? (
                                            <span className="text-danger">
                                              FSSAI License files not uploaded!
                                            </span>
                                          ) : (
                                            <>
                                              {documentType1Data.map(
                                                (item, index) => (
                                                  <div
                                                    key={item.id}
                                                    className="mb-3"
                                                  >
                                                    <div className="row">
                                                      <div className="col-sm-6">
                                                        <h4 className="mb-3">
                                                          File #{index + 1}
                                                        </h4>
                                                      </div>
                                                      <div className="col-sm-6">
                                                        <h5 className="">
                                                          {item.verification_status ===
                                                            0 && (
                                                            <div className="badge badge-opacity-warning">
                                                              Not Verified
                                                            </div>
                                                          )}
                                                          {item.verification_status ===
                                                            1 && (
                                                            <div className="badge badge-opacity-success">
                                                              Verified
                                                            </div>
                                                          )}
                                                        </h5>
                                                      </div>
                                                    </div>
                                                    {item.document_name &&
                                                    item.document_name
                                                      .toLowerCase()
                                                      .endsWith(".pdf") ? (
                                                      <iframe
                                                        src={item.document_name}
                                                        width="100%"
                                                        height="500"
                                                        title={`PDF-${index}`}
                                                      ></iframe>
                                                    ) : item.document_name ? (
                                                      <img
                                                        src={item.document_name}
                                                        alt="doc_img"
                                                        style={{
                                                          maxWidth: "600px",
                                                        }}
                                                      />
                                                    ) : (
                                                      ""
                                                    )}
                                                  </div>
                                                )
                                              )}
                                            </>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="accordion-item">
                                      <h2
                                        className="accordion-header"
                                        id="panelsStayOpen-headingTwo"
                                      >
                                        <button
                                          className="accordion-button collapsed"
                                          type="button"
                                          data-bs-toggle="collapse"
                                          data-bs-target="#panelsStayOpen-collapseTwo"
                                          aria-expanded="false"
                                          aria-controls="panelsStayOpen-collapseTwo"
                                        >
                                          PAN Card
                                        </button>
                                      </h2>
                                      <div
                                        id="panelsStayOpen-collapseTwo"
                                        className="accordion-collapse collapse"
                                        aria-labelledby="panelsStayOpen-headingTwo"
                                      >
                                        <div className="accordion-body">
                                          {documentType2Data.length === 0 ? (
                                            <span className="text-danger">
                                              PAN Card files not uploaded!
                                            </span>
                                          ) : (
                                            <>
                                              {documentType2Data.map(
                                                (item, index) => (
                                                  <div
                                                    key={item.id}
                                                    className="mb-3"
                                                  >
                                                    <div className="row">
                                                      <div className="col-sm-6">
                                                        <h4 className="mb-3">
                                                          File #{index + 1}
                                                        </h4>
                                                      </div>
                                                      <div className="col-sm-6">
                                                        <h5 className="">
                                                          {item.verification_status ===
                                                            0 && (
                                                            <div className="badge badge-opacity-warning">
                                                              Not Verified
                                                            </div>
                                                          )}
                                                          {item.verification_status ===
                                                            1 && (
                                                            <div className="badge badge-opacity-success">
                                                              Verified
                                                            </div>
                                                          )}
                                                        </h5>
                                                      </div>
                                                    </div>
                                                    {item.document_name &&
                                                    item.document_name
                                                      .toLowerCase()
                                                      .endsWith(".pdf") ? (
                                                      <iframe
                                                        src={item.document_name}
                                                        width="100%"
                                                        height="500"
                                                        title={`PDF-${index}`}
                                                      ></iframe>
                                                    ) : item.document_name ? (
                                                      <img
                                                        src={item.document_name}
                                                        alt="doc_img"
                                                        style={{
                                                          maxWidth: "600px",
                                                        }}
                                                      />
                                                    ) : (
                                                      ""
                                                    )}
                                                  </div>
                                                )
                                              )}
                                            </>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="accordion-item">
                                      <h2
                                        className="accordion-header"
                                        id="panelsStayOpen-headingThree"
                                      >
                                        <button
                                          className="accordion-button collapsed"
                                          type="button"
                                          data-bs-toggle="collapse"
                                          data-bs-target="#panelsStayOpen-collapseThree"
                                          aria-expanded="false"
                                          aria-controls="panelsStayOpen-collapseThree"
                                        >
                                          GST
                                        </button>
                                      </h2>
                                      <div
                                        id="panelsStayOpen-collapseThree"
                                        className="accordion-collapse collapse"
                                        aria-labelledby="panelsStayOpen-headingThree"
                                      >
                                        <div className="accordion-body">
                                          {documentType3Data.length === 0 ? (
                                            <span className="text-danger">
                                              GST files not uploaded!
                                            </span>
                                          ) : (
                                            <>
                                              {documentType3Data.map(
                                                (item, index) => (
                                                  <div
                                                    key={item.id}
                                                    className="mb-3"
                                                  >
                                                    <div className="row">
                                                      <div className="col-sm-6">
                                                        <h4 className="mb-3">
                                                          File #{index + 1}
                                                        </h4>
                                                      </div>
                                                      <div className="col-sm-6">
                                                        <h5 className="">
                                                          {item.verification_status ===
                                                            0 && (
                                                            <div className="badge badge-opacity-warning">
                                                              Not Verified
                                                            </div>
                                                          )}
                                                          {item.verification_status ===
                                                            1 && (
                                                            <div className="badge badge-opacity-success">
                                                              Verified
                                                            </div>
                                                          )}
                                                        </h5>
                                                      </div>
                                                    </div>
                                                    {item.document_name &&
                                                    item.document_name
                                                      .toLowerCase()
                                                      .endsWith(".pdf") ? (
                                                      <iframe
                                                        src={item.document_name}
                                                        width="100%"
                                                        height="500"
                                                        title={`PDF-${index}`}
                                                      ></iframe>
                                                    ) : item.document_name ? (
                                                      <img
                                                        src={item.document_name}
                                                        alt="doc_img"
                                                        style={{
                                                          maxWidth: "600px",
                                                        }}
                                                      />
                                                    ) : (
                                                      ""
                                                    )}
                                                  </div>
                                                )
                                              )}
                                            </>
                                          )}
                                        </div>
                                      </div>
                                    </div>

                                    <div className="accordion-item">
                                      <h2
                                        className="accordion-header"
                                        id="panelsStayOpen-headingFour"
                                      >
                                        <button
                                          className="accordion-button collapsed"
                                          type="button"
                                          data-bs-toggle="collapse"
                                          data-bs-target="#panelsStayOpen-collapsefour"
                                          aria-expanded="false"
                                          aria-controls="panelsStayOpen-collapsefour"
                                        >
                                          Menu
                                        </button>
                                      </h2>
                                      <div
                                        id="panelsStayOpen-collapsefour"
                                        className="accordion-collapse collapse"
                                        aria-labelledby="panelsStayOpen-headingFour"
                                      >
                                        <div className="accordion-body">
                                          {documentType4Data.length === 0 ? (
                                            <span className="text-danger">
                                              Menu files not uploaded!
                                            </span>
                                          ) : (
                                            <>
                                              {documentType4Data.map(
                                                (item, index) => (
                                                  <div
                                                    key={item.id}
                                                    className="mb-3"
                                                  >
                                                    <div className="row">
                                                      <div className="col-sm-6">
                                                        <h4 className="mb-3">
                                                          File #{index + 1}
                                                        </h4>
                                                      </div>
                                                      <div className="col-sm-6">
                                                        <h5 className="">
                                                          {item.verification_status ===
                                                            0 && (
                                                            <div className="badge badge-opacity-warning">
                                                              Not Verified
                                                            </div>
                                                          )}
                                                          {item.verification_status ===
                                                            1 && (
                                                            <div className="badge badge-opacity-success">
                                                              Verified
                                                            </div>
                                                          )}
                                                        </h5>
                                                      </div>
                                                    </div>
                                                    {item.document_name &&
                                                    item.document_name
                                                      .toLowerCase()
                                                      .endsWith(".pdf") ? (
                                                      <iframe
                                                        src={item.document_name}
                                                        width="100%"
                                                        height="500"
                                                        title={`PDF-${index}`}
                                                      ></iframe>
                                                    ) : item.document_name ? (
                                                      <img
                                                        src={item.document_name}
                                                        alt="doc_img"
                                                        style={{
                                                          maxWidth: "600px",
                                                        }}
                                                      />
                                                    ) : (
                                                      ""
                                                    )}
                                                  </div>
                                                )
                                              )}
                                            </>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <LoadingModal isLoading={isLoading} />

          <Notification />
        </>
      )}
    </>
  );
};

export default Verification;
