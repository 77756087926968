import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import SideBar from "../other/SideBar";
import NavBar from "../other/NavBar";
import LoadingModal from "../../loader/LoadingModal";
import {
  AddStaffURL,
  GetStaffTypeListURL,
  ScriptId,
  ScriptSRC,
} from "../../assets/api/BaseURL";
import Notification from "../other/Notification";
import { useToast } from "../../../context/ToastContext";

const AddStaff = () => {
  const navigate = useNavigate();
  const { addToast } = useToast();
  const imageRef = useRef(null);
  const [token, setToken] = useState("");
  const [toggleSideBar, setToggleSideBar] = useState(false);
  const [canvas, setCanvas] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [image, setImage] = useState("");
  const maxImageSize = 1024 * 1024;
  const maxImageWidth = 500;
  const maxImageHeight = 500;
  const [staffTypeOptions, setStaffTypeOptions] = useState([]);
  const [formData, setFormData] = useState({
    name: "",
    phoneNumber: "",
    email: "",
    address: "",
    userName: "",
    password: "",
    description: "",
    status: true,
    type: "",
    image: "",
  });

  const handleToggleSidebar = (e) => {
    e.preventDefault();
    setToggleSideBar(!toggleSideBar);
  };

  const handleToggleOffCanvas = (e) => {
    e.preventDefault();
    setCanvas(!canvas);
  };

  const handleBackClick = (e) => {
    e.preventDefault();
    navigate("/view_staff");
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (type === "checkbox") {
      setFormData({
        ...formData,
        [name]: checked,
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
    setError("");
    setSuccess("");
  };

  function isImageSizeValid(file) {
    if (file && file.size) {
      return file.size <= maxImageSize;
    }
    return false;
  }

  function isImageDimensionsValid(file) {
    if (file) {
      const image = new Image();
      image.src = URL.createObjectURL(file);

      return new Promise((resolve) => {
        image.onload = () => {
          const width = image.width;
          const height = image.height;

          const isValidDimensions =
            width <= maxImageWidth && height <= maxImageHeight;

          resolve(isValidDimensions);
        };
      });
    }

    return false;
  }

  const handleAddStaff = async (e) => {
    e.preventDefault();

    const mobileNumberPattern = /^\d{10}$/;

    if (formData.name === "") {
      setError("Name can not be empty");
      setSuccess("");
    }if (formData.name.length > 30) {
      setError("Name can not be not be more 30 characters");
      setSuccess("");
    } else if (formData.phoneNumber === "") {
      setError("Phone Number can not be empty");
      setSuccess("");
    } else if (!mobileNumberPattern.test(formData.phoneNumber)) {
      setError("Please enter a valid 10-digit Phone number.");
      setSuccess("");
    } else if (formData.userName === "") {
      setError("User Name can not be empty");
      setSuccess("");
    } else if (formData.password === "") {
      setError("Password can not be empty");
      setSuccess("");
    } else if (formData.type === "") {
      setError("Please Select Staff Type");
      setSuccess("");
    } else if (image && !isImageSizeValid(image)) {
      setError(`Image size can not be greater than ${maxImageSize / 1024}kb`);
      imageRef.current.value = "";
      setImage("");
      setSuccess("");
    } else if (image && !(await isImageDimensionsValid(image))) {
      setError(
        `Image Dimensions can not be greater than ${maxImageWidth}*${maxImageHeight}`
      );
      imageRef.current.value = "";
      setImage("");
      setSuccess("");
    } else {
      // const data = {
      //   name: formData.name,
      //   phone_number: formData.phoneNumber,
      //   email: formData.email,
      //   address: formData.address,
      //   username: formData.userName,
      //   password: formData.password,
      //   description: formData.description,
      //   type: formData.type,
      //   status: formData.status ? 1 : 0,
      // };

      const dataToSend = new FormData();
      dataToSend.append("name", formData.name);
      dataToSend.append("phone_number", formData.phoneNumber);
      dataToSend.append("email", formData.email);
      dataToSend.append("address", formData.address);
      dataToSend.append("username", formData.userName);
      dataToSend.append("password", formData.password);
      dataToSend.append("description", formData.description);
      dataToSend.append("type", formData.type);
      dataToSend.append("status", formData.status ? 1 : 0);
      dataToSend.append("staff_image", image ? image : formData.image);

      try {
        const response = await axios({
          method: "post",
          url: AddStaffURL,
          data: dataToSend,
          headers: {
            "Content-Type": "multipart/form-data",
            authorization: `Bearer ${token}`,
          },
        });

        if (response.status === 200 && response.data.message) {
          setError("");
          setSuccess(response.data.message);
          setFormData({
            name: "",
            phoneNumber: "",
            email: "",
            address: "",
            userName: "",
            password: "",
            description: "",
            type: "",
            status: true,
          });
          imageRef.current.value = "";
          setImage("");
        }
      } catch (error) {
        if (error.response && error.response.data.message) {
          setError(error.response.data.message);
          setSuccess("");
        } else {
          setError(error.message);
          setSuccess("");
        }
        console.log(error);
      }
    }
  };

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/");
    }
  }, [navigate]);

  useEffect(() => {
    setToken(localStorage.getItem("token"));
  }, [token]);

  useEffect(() => {
    const existingScript = document.getElementById(ScriptId);
    if (!existingScript && localStorage.getItem("userId")) {
      const userId = localStorage.getItem("userId");
      const script = document.createElement("script");
      script.src = ScriptSRC + userId.replace(/"/g, "");
      script.defer = true;
      script.id = ScriptId;
      document.head.appendChild(script);
    }
  }, []);

  useEffect(() => {
    if (token) {
      async function getData() {
        setIsLoading(true);
        try {
          const response = await axios.get(GetStaffTypeListURL, {
            headers: { authorization: `Bearer ${token}` },
          });

          if (response.status === 200 && response.data.staffTypes) {
            setStaffTypeOptions(response.data.staffTypes);
          }
        } catch (error) {
          if (error.response && error.response.data.message) {
            addToast("error", error.response.data.message);
          } else {
            addToast("error", error.message);
          }
          console.log(error);
        }
        setIsLoading(false);
      }
      getData();
    }
  }, [token, addToast]);

  useEffect(() => {
    if (error || success) {
      window.scrollTo(0, 0);
    }
  }, [error, success]);

  return (
    <>
      {token && (
        <>
          <div
            className={
              toggleSideBar
                ? "container-scroller sidebar-icon-only"
                : "container-scroller"
            }
          >
            <nav className="navbar default-layout col-lg-12 col-12 p-0 fixed-top d-flex align-items-top flex-row">
            
              <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-start">
              <Link className="navbar-brand brand-logo-mini" to="/live_orders">
                  <img src="images/mini-kr-logo.svg" alt="logo" />
                </Link>
                <div className="me-1">
                  <button
                    className={
                      toggleSideBar
                        ? "navbar-toggler navbar-toggler align-self-center"
                        : "navbar-toggler navbar-toggler align-self-center text-white"
                    }
                    type="button"
                    onClick={handleToggleSidebar}
                  >
                    <span className="icon-menu"></span>
                  </button>
                </div>
                <div>
                  <Link to="/live_orders">
                    <span className="navbar-brand brand-logo">
                      <img src="images/Khateraho_logo_white.png" alt="logo" />
                    </span>
                  </Link>
                </div>
              </div>
              <NavBar handleToggle={handleToggleOffCanvas} />
            </nav>
            <div className="container-fluid page-body-wrapper px-0">
              <SideBar active="view_staff" toggleCanvas={toggleSideBar} />

              <div className="main-panel">
                <div className="content-wrapper">
                  <div className="row">
                    <div className="col-sm-12">
                      <h3 className="ukhd mb-3">Staff</h3>
                      <div className="row flex-grow">
                        <div className="col-12 grid-margin stretch-card">
                          <div className="card card-rounded">
                            <div className="card-body">
                              <div className="d-flex justify-content-between align-items-start mb-2">
                                <div className="vsalign">
                                  <h4 className="card-title card-title-dash">
                                    Add Staff
                                  </h4>
                                </div>
                                <div className="vsalign">
                                  <span
                                    className="btn btn-danger mb-0 me-0"
                                    onClick={handleBackClick}
                                  >
                                    <i className="menu-icon mdi mdi-arrow-left vsalign"></i>
                                    Go Back
                                  </span>
                                </div>
                              </div>
                              <form className="forms-sample">
                                {error && (
                                  <div className="d-flex justify-content-between align-items-start alert alert-danger">
                                    <div className="vsalign">{error}</div>
                                    <div className="vsalign">
                                      <span
                                        className=".h3 text-muted"
                                        style={{ cursor: "pointer" }}
                                        onClick={() => setError("")}
                                      >
                                        x
                                      </span>
                                    </div>
                                  </div>
                                )}
                                {success && (
                                  <div className="d-flex justify-content-between align-items-start alert alert-success">
                                    <div className="vsalign">{success}</div>
                                    <div className="vsalign">
                                      <span
                                        className=".h3 text-muted"
                                        style={{ cursor: "pointer" }}
                                        onClick={() => setSuccess("")}
                                      >
                                        x
                                      </span>
                                    </div>
                                  </div>
                                )}
                                <div className="row">
                                  <div className="col-12 col-md-6 col-lg-6">
                                    <div className="form-group">
                                      <label>
                                        Name
                                        <span className="text-danger">*</span>
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter  Name"
                                        name="name"
                                        value={formData.name}
                                        onChange={handleInputChange}
                                      />
                                    </div>
                                  </div>

                                  <div className="col-12 col-md-6 col-lg-6">
                                    <div className="form-group">
                                      <label>
                                        Phone Number
                                        <span className="text-danger">*</span>
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Phone Number"
                                        maxLength={10}
                                        name="phoneNumber"
                                        value={formData.phoneNumber}
                                        onChange={handleInputChange}
                                      />
                                    </div>
                                  </div>

                                  <div className="col-12 col-md-6 col-lg-6">
                                    <div className="form-group">
                                      <label> Email </label>
                                      <input
                                        type="email"
                                        className="form-control"
                                        placeholder="Enter Email"
                                        name="email"
                                        value={formData.email}
                                        onChange={handleInputChange}
                                      />
                                    </div>
                                  </div>

                                  <div className="col-12 col-md-6 col-lg-6">
                                    <div className="form-group">
                                      <label> Address </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Address"
                                        name="address"
                                        value={formData.address}
                                        onChange={handleInputChange}
                                      />
                                    </div>
                                  </div>

                                  <div className="col-12 col-md-6 col-lg-6">
                                    <div className="form-group">
                                      <label>
                                        User Name
                                        <span className="text-danger">*</span>
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter User Name"
                                        name="userName"
                                        value={formData.userName}
                                        onChange={handleInputChange}
                                      />
                                    </div>
                                  </div>

                                  <div className="col-12 col-md-6 col-lg-6">
                                    <div className="form-group">
                                      <label>
                                        Password
                                        <span className="text-danger">*</span>
                                      </label>
                                      <input
                                        type="password"
                                        className="form-control"
                                        placeholder="Enter  Password"
                                        name="password"
                                        value={formData.password}
                                        onChange={handleInputChange}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-12 col-md-6 col-lg-6">
                                    <div className="form-group">
                                      <label>
                                        Select Staff Type
                                        <span className="text-danger">*</span>
                                      </label>
                                      <select
                                        className="form-select"
                                        name="type"
                                        value={formData.type}
                                        onChange={handleInputChange}
                                      >
                                        <option value="">
                                          Select Staff Type
                                        </option>
                                        {staffTypeOptions.map((item) => (
                                          <option
                                            key={item.numeric}
                                            value={item.numeric}
                                          >
                                            {item.name}
                                          </option>
                                        ))}
                                      </select>
                                    </div>
                                  </div>

                                  <div className="col-12 col-md-6 col-lg-6">
                                    <div className="form-group togglecss">
                                      <label>Status</label>
                                      <div className="button r" id="button-1">
                                        <input
                                          type="checkbox"
                                          className="checkbox"
                                          name="status"
                                          checked={formData.status}
                                          onChange={handleInputChange}
                                        />
                                        <div className="knobs"></div>
                                        <div className="layer"></div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-12 col-md-6 col-lg-6">
                                    <div className="form-group">
                                      <label>Upload Image </label>
                                      <br />
                                      {formData.image && (
                                        <img
                                          src={formData.image}
                                          className="mb-2"
                                          alt="staff_image"
                                          style={{
                                            width: "120px",
                                            height: "auto",
                                          }}
                                        />
                                      )}

                                      <input
                                        className="form-control"
                                        type="file"
                                        accept=".jpeg, .jpg, .png"
                                        id="formFile"
                                        ref={imageRef}
                                        name="image"
                                        onChange={(e) =>
                                          setImage(e.target.files[0])
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>

                                <div className="form-group">
                                  <label>Description</label>
                                  <textarea
                                    className="form-control"
                                    placeholder="Enter Description"
                                    name="description"
                                    value={formData.description}
                                    onChange={handleInputChange}
                                  ></textarea>
                                </div>

                                <button
                                  type="submit"
                                  className="btn btn-primary me-2"
                                  onClick={handleAddStaff}
                                >
                                  Submit
                                </button>
                                <button
                                  className="btn btn btn-secondary"
                                  onClick={handleBackClick}
                                >
                                  Cancel
                                </button>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <LoadingModal isLoading={isLoading} />

          <Notification />
        </>
      )}
    </>
  );
};

export default AddStaff;
