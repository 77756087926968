import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import SideBar from "../other/SideBar";
import NavBar from "../other/NavBar";
import LoadingModal from "../../loader/LoadingModal";
import {
  GetQRCodeSettingsURL,
  UpdateQRCodeSettingsURL,
  ScriptId,
  ScriptSRC,
} from "../../assets/api/BaseURL";
import Notification from "../other/Notification";
import { useToast } from "../../../context/ToastContext";

const QRCodeSettings = () => {
  const navigate = useNavigate();
  const { addToast } = useToast();
  const [token, setToken] = useState("");
  const [toggleSideBar, setToggleSideBar] = useState(false);
  const [canvas, setCanvas] = useState(false);
  const [flag, setFlag] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    qrEnable: true,
    callWaiter: true,
    waitingTime: "",
    perimeter: "",
    orderRange: "",
  });

  const handleToggleSidebar = (e) => {
    e.preventDefault();
    setToggleSideBar(!toggleSideBar);
  };

  const handleToggleOffCanvas = (e) => {
    e.preventDefault();
    setCanvas(!canvas);
  };

  const handleFormChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (type === "checkbox" && name === "qrEnable" && !checked) {
      setFormData((prevData) => ({
        ...prevData,
        [name]: checked,
        callWaiter: checked,
      }));
    } else {
      const newValue = type === "checkbox" ? checked : value;

      setFormData((prevData) => ({
        ...prevData,
        [name]: newValue,
      }));
    }
    setError("");
    setSuccess("");
  };

  const isValidNumber = (value) => {
    return !isNaN(parseFloat(value)) && isFinite(value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (formData.waitingTime && !isValidNumber(formData.waitingTime)) {
      setError("Please enter valid Call Waiter Waiting Time");
      setSuccess("");
    } else if (formData.perimeter && !isValidNumber(formData.perimeter)) {
      setError("Please enter valid Call Waiter Perimeter");
      setSuccess("");
    } else if (formData.orderRange && !isValidNumber(formData.orderRange)) {
      setError("Please enter valid Order Perimeter");
      setSuccess("");
    } else {
      const data = {
        enable_qr: formData.qrEnable ? 1 : 0,
        callwaiter: formData.callWaiter ? 1 : 0,
        callwaiter_waiting_time: formData.waitingTime,
        perimeter: formData.perimeter,
        order_range: formData.orderRange,
      };

      try {
        const response = await axios({
          method: "patch",
          url: UpdateQRCodeSettingsURL,
          data: data,
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${token}`,
          },
        });

        if (response.status === 200 && response.data.message) {
          setError("");
          setSuccess(response.data.message);
          setFlag(!flag);
        }
      } catch (error) {
        if (error.response && error.response.data.message) {
          setError(error.response.data.message);
          setSuccess("");
        } else {
          setError(error.message);
          setSuccess("");
        }
        console.log(error);
      }
    }
  };

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/");
    }
  }, [navigate]);

  useEffect(() => {
    setToken(localStorage.getItem("token"));
  }, [token]);

  useEffect(() => {
    const existingScript = document.getElementById(ScriptId);
    if (!existingScript && localStorage.getItem("userId")) {
      const userId = localStorage.getItem("userId");
      const script = document.createElement("script");
      script.src = ScriptSRC + userId.replace(/"/g, "");
      script.defer = true;
      script.id = ScriptId;
      document.head.appendChild(script);
    }
  }, []);

  useEffect(() => {
    if (token) {
      async function getData() {
        setIsLoading(true);
        try {
          const response = await axios.get(GetQRCodeSettingsURL, {
            headers: { authorization: `Bearer ${token}` },
          });

          if (response.status === 200 && response.data.data) {
            setFormData({
              qrEnable: response.data.data.enable_qr === 0 ? false : true,
              callWaiter: response.data.data.callwaiter === 0 ? false : true,
              waitingTime: response.data.data.callwaiter_waiting_time,
              perimeter: response.data.data.perimeter,
              orderRange: response.data.data.order_range,
            });
          }
        } catch (error) {
          if (error.response && error.response.data.message) {
            addToast("error", error.response.data.message);
          } else {
            addToast("error", error.message);
          }
          console.log(error);
        }
        setIsLoading(false);
      }
      getData();
    }
  }, [token, flag, addToast]);

  useEffect(() => {
    if (error || success) {
      window.scrollTo(0, 0);
    }
  }, [error, success]);

  return (
    <>
      {token && (
        <>
          <div
            className={
              toggleSideBar
                ? "container-scroller sidebar-icon-only"
                : "container-scroller"
            }
          >
            <nav className="navbar default-layout col-lg-12 col-12 p-0 fixed-top d-flex align-items-top flex-row">

              <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-start">
                <Link className="navbar-brand brand-logo-mini" to="/live_orders">
                  <img src="images/mini-kr-logo.svg" alt="logo" />
                </Link>
                <div className="me-1">
                  <button
                    className={
                      toggleSideBar
                        ? "navbar-toggler navbar-toggler align-self-center"
                        : "navbar-toggler navbar-toggler align-self-center text-white"
                    }
                    type="button"
                    onClick={handleToggleSidebar}
                  >
                    <span className="icon-menu"></span>
                  </button>
                </div>
                <div>
                  <Link to="/live_orders">
                    <span className="navbar-brand brand-logo">
                      <img src="images/Khateraho_logo_white.png" alt="logo" />
                    </span>
                  </Link>
                </div>
              </div>
              <NavBar handleToggle={handleToggleOffCanvas} />
            </nav>
            <div className="container-fluid page-body-wrapper px-0">
              <SideBar active="qrcode_settings" toggleCanvas={toggleSideBar} />

              <div className="main-panel">
                <div className="content-wrapper">
                  <div className="row">
                    <div className="col-sm-12">
                      <h3 className="ukhd mb-3">QR Code Settings</h3>
                      <div className="row flex-grow">
                        <div className="col-12 grid-margin stretch-card">
                          <div className="card card-rounded">
                            <div className="card-body">
                              <form className="forms-sample">
                                <div className="row">
                                  {error && (
                                    <div className="d-flex justify-content-between align-items-start alert alert-danger">
                                      <div className="vsalign">{error}</div>
                                      <div className="vsalign">
                                        <span
                                          className=".h3 text-muted"
                                          style={{ cursor: "pointer" }}
                                          onClick={() => setError("")}
                                        >
                                          x
                                        </span>
                                      </div>
                                    </div>
                                  )}

                                  {success && (
                                    <div className="d-flex justify-content-between align-items-start alert alert-success">
                                      <div className="vsalign">{success}</div>
                                      <div className="vsalign">
                                        <span
                                          className=".h3 text-muted"
                                          style={{ cursor: "pointer" }}
                                          onClick={() => setSuccess("")}
                                        >
                                          x
                                        </span>
                                      </div>
                                    </div>
                                  )}
                                </div>
                                <div className="row">
                                  <div className="col-lg-4">
                                    <div className="form-group togglecss">
                                      <label>QR Code Enable</label>
                                      <div className="button r" id="button-1">
                                        <input
                                          type="checkbox"
                                          className="checkbox"
                                          name="qrEnable"
                                          checked={formData.qrEnable}
                                          onChange={handleFormChange}
                                        />
                                        <div className="knobs"></div>
                                        <div className="layer"></div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-lg-4">
                                    <div className="form-group togglecss">
                                      <label>Call Waiter</label>
                                      <div className="button r" id="button-1">
                                        <input
                                          type="checkbox"
                                          className="checkbox"
                                          name="callWaiter"
                                          disabled={
                                            formData.qrEnable ? false : true
                                          }
                                          checked={formData.callWaiter}
                                          onChange={handleFormChange}
                                        />
                                        <div className="knobs"></div>
                                        <div className="layer"></div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-lg-4">
                                    <div className="form-group">
                                      <label>Call Waiter Waiting Time </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Waiting Time"
                                        name="waitingTime"
                                        readOnly={
                                          formData.qrEnable ? false : true
                                        }
                                        value={formData.waitingTime}
                                        onChange={handleFormChange}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-lg-4">
                                    <div className="form-group">
                                      <label>
                                        Call Waiter Perimeter(Meter){" "}
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Perimeter(Meter)"
                                        name="perimeter"
                                        readOnly={
                                          formData.qrEnable ? false : true
                                        }
                                        value={formData.perimeter}
                                        onChange={handleFormChange}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-lg-4">
                                    <div className="form-group">
                                      <label>Order Perimeter(Meter) </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Order Perimeter(Meter)"
                                        name="orderRange"
                                        readOnly={
                                          formData.qrEnable ? false : true
                                        }
                                        value={formData.orderRange}
                                        onChange={handleFormChange}
                                      />
                                    </div>
                                  </div>
                                </div>

                                <button
                                  type="submit"
                                  className="btn btn-primary me-2"
                                  onClick={handleSubmit}
                                >
                                  Submit
                                </button>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <LoadingModal isLoading={isLoading} />

          <Notification />
        </>
      )}
    </>
  );
};

export default QRCodeSettings;
