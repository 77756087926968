import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./components/pages/unprotected/Login";
import Signup from "./components/pages/unprotected/Signup";
import Dashboard from "./components/pages/dashboard/Dashboard";
import ForgotPage from "./components/pages/unprotected/ForgotPage";
import POS from "./components/pages/pos/POS";
import LoginWithOTP from "./components/pages/unprotected/LoginWithOTP";
import CategoryMenu from "./components/pages/category/CategoryMenu";
import DishesProducts from "./components/pages/product/DishesProducts";
import Orders from "./components/pages/orders/Orders";
import FoodersCP from "./components/pages/fooder_cp/FoodersCP";
import WhatsappMarketing from "./components/pages/whatsapp_marketing/WhatsappMarketing";

import Customers from "./components/pages/customers/Customers";
import Verification from "./components/pages/verification/Verification";
import ManageTables from "./components/pages/manage_tables/ManageTables";
import QRCodeSettings from "./components/pages/call_waiter_settings/QRCodeSettings";
import OnlineOrdering from "./components/pages/online_delivery_settings/OnlineOrdering";
import Profile from "./components/pages/profile/Profile";
import LiveOrders from "./components/pages/live_orders/LiveOrders";
import AddMenu from "./components/pages/category/AddMenu";
import EditMenu from "./components/pages/category/EditMenu";
import AddProduct from "./components/pages/product/AddProduct";
import EditProduct from "./components/pages/product/EditProduct";
import OrderDetails from "./components/pages/orders/OrderDetails";
import Staff from "./components/pages/staff/Staff";
import AddStaff from "./components/pages/staff/AddStaff";
import EditStaff from "./components/pages/staff/EditStaff";
import AddManageTables from "./components/pages/manage_tables/AddManageTables";
import AssignTablesToStaff from "./components/pages/staff/AssignTablesToStaff";
import RawMaterial from "./components/pages/inventory/raw_materials/RawMaterial";
import AddRawMaterial from "./components/pages/inventory/raw_materials/AddRawMaterial";
import EditRawMaterial from "./components/pages/inventory/raw_materials/EditRawMaterial";
import Suppliers from "./components/pages/inventory/suppliers/Suppliers";
import AddSupplier from "./components/pages/inventory/suppliers/AddSupplier";
import EditSupplier from "./components/pages/inventory/suppliers/EditSupplier";
import Recipes from "./components/pages/inventory/recipes/Recipes";
import AddRecipe from "./components/pages/inventory/recipes/AddRecipe";
import EditRecipe from "./components/pages/inventory/recipes/EditRecipe";
import ResetPage from "./components/pages/unprotected/ResetPage";
import NotFoundPage from "./components/pages/unprotected/NotFoundPage";
import { useToast } from "./context/ToastContext";
import ManageCoupons from "./components/pages/manage_coupons/ManageCoupons";
import GSTReports from "./components/pages/reports/gst_reports/GSTReports";
import Reports from "./components/pages/reports/Reports";
import ReportByOrderType from "./components/pages/reports/order_reports/ReportByOrderType";
import PendingAmountReport from "./components/pages/reports/order_reports/PendingAmountReport";

import ReportByDailySales from "./components/pages/reports/seals_reports/ReportByDailySales";
import CustomSalesRepost from "./components/pages/reports/seals_reports/CustomSalesRepost";



import Stocks from "./components/pages/inventory/stocks/Stocks";
import ManageVariants from "./components/pages/product/ManageVariants";
import AddOns from "./components/pages/add_ons/AddOns";
import Purchase from "./components/pages/inventory/purchase/Purchase";
import EditPurchase from "./components/pages/inventory/purchase/EditPurchase";
import CreatePurchase from "./components/pages/inventory/purchase/CreatePurchase";
import CreatePurchaseReturn from "./components/pages/inventory/purchase_return/CreatePurchaseReturn";
import PurchaseReturn from "./components/pages/inventory/purchase_return/PurchaseReturn";
import VerUpdates from "./components/pages/ver_updates/VerUpdates";
import KDS from "./components/pages/pos/KDS";
import ItemsReport from "./components/pages/reports/items_reports/ItemsReport";
import FoodersPlans from "./components/pages/fooders_plans/FoodersPlans";
import EditOrder from "./components/pages/orders/EditOrder";

const App = () => {
  const { planData } = useToast();
  return (
    <>
      <BrowserRouter>
        <div>
          <Routes>
            <Route path="/" element={<Login />}></Route>
            <Route path="/login" element={<Login />}></Route>
            <Route path="/login_with_otp" element={<LoginWithOTP />}></Route>
            <Route path="/signup" element={<Signup />}></Route>

            {planData && planData.dashboard === 1 && (
              <Route path="/dashboard" element={<Dashboard />}></Route>
            )}

            {planData && planData.live_orders === 1 && (
              <Route path="/live_orders" element={<LiveOrders />}></Route>
            )}

            {planData && planData.staff_manager === 1 && (
              <>
                <Route path="/view_staff" element={<Staff />}></Route>
                <Route path="/add_staff" element={<AddStaff />}></Route>
                <Route path="/edit_staff" element={<EditStaff />}></Route>
                <Route
                  path="/assign_tables"
                  element={<AssignTablesToStaff />}
                ></Route>
              </>
            )}

            {planData && planData.menu_products === 1 && (
              <>
                <Route path="/view_menus" element={<CategoryMenu />}></Route>
                <Route path="/add_menu" element={<AddMenu />}></Route>
                <Route path="/edit_menu" element={<EditMenu />}></Route>
                <Route
                  path="/view_products"
                  element={<DishesProducts />}
                ></Route>
                <Route path="/add_product" element={<AddProduct />}></Route>
                <Route path="/edit_product" element={<EditProduct />}></Route>
                <Route
                  path="/manage_variant"
                  element={<ManageVariants />}
                ></Route>
                <Route path="/add_ons" element={<AddOns />}></Route>
              </>
            )}

            {planData && planData.orders === 1 && (
              <>
                <Route path="/orders" element={<Orders />}></Route>
                <Route path="/order_details" element={<OrderDetails />}></Route>
                <Route path="/edit_details" element={<EditOrder />}></Route>

              </>
            )}

            {planData && planData.inventory === 1 && (
              <>
                <Route path="/raw_material" element={<RawMaterial />}></Route>
                <Route
                  path="/add_material"
                  element={<AddRawMaterial />}
                ></Route>
                <Route
                  path="/edit_material"
                  element={<EditRawMaterial />}
                ></Route>
                <Route path="/suppliers" element={<Suppliers />}></Route>
                <Route path="/add_supplier" element={<AddSupplier />}></Route>
                <Route path="/edit_supplier" element={<EditSupplier />}></Route>
                <Route path="/recipes" element={<Recipes />}></Route>
                <Route path="/add_recipe" element={<AddRecipe />}></Route>
                <Route path="/edit_recipe" element={<EditRecipe />}></Route>
                <Route path="/stocks" element={<Stocks />}></Route>
                <Route path="/view_purchase" element={<Purchase />}></Route>
                <Route path="/edit_purchase" element={<EditPurchase />}></Route>
                <Route
                  path="/create_purchase"
                  element={<CreatePurchase />}
                ></Route>
                <Route
                  path="/view_purchase_return"
                  element={<PurchaseReturn />}
                ></Route>
                <Route
                  path="/create_purchase_return"
                  element={<CreatePurchaseReturn />}
                ></Route>
              </>
            )}

            {planData && planData.pos === 1 && (
              <>
                <Route path="/pos" element={<POS />}></Route>

                <Route path="/kds" element={<KDS />}></Route>
              </>
            )}

            {planData && planData.fooder_cp === 1 && (
              <Route path="/fooders_cp" element={<FoodersCP />}></Route>
            )}

            {planData && planData.fooder_cp === 1 && (
              <Route path="/whatsapp_marketing" element={<WhatsappMarketing />}></Route>
            )}

            {planData && planData.customers === 1 && (
              <Route path="/customers" element={<Customers />}></Route>
            )}

            {planData && planData.verififation_documents === 1 && (
              <Route path="/verification" element={<Verification />}></Route>
            )}

            {planData && planData.manage_tables === 1 && (
              <>
                <Route path="/manage_tables" element={<ManageTables />}></Route>
                <Route
                  path="/add_manage_tables"
                  element={<AddManageTables />}
                ></Route>
              </>
            )}

            {planData && planData.callwaiter_settings === 1 && (
              <Route
                path="/qrcode_settings"
                element={<QRCodeSettings />}
              ></Route>
            )}

            {planData && planData.online_delivery_settings === 1 && (
              <Route
                path="/online_ordering"
                element={<OnlineOrdering />}
              ></Route>
            )}

            {planData && planData.reports === 1 && (
              <>
                <Route path="/reports" element={<Reports />}></Route>
                <Route path="/gst_report" element={<GSTReports />}></Route>
                <Route
                  path="/reports_by_order_type"
                  element={<ReportByOrderType />}
                ></Route>
                <Route
                  path="/reports_by_daily_sales"
                  element={<ReportByDailySales />}
                ></Route>
                <Route
                  path="/reports_by_custom_sales"
                  element={<CustomSalesRepost />}
                ></Route>
                <Route
                  path="/reports_by_due_amount"
                  element={<PendingAmountReport />}
                ></Route>

                <Route
                  path="/reports_by_items_types"
                  element={<ItemsReport />}
                ></Route>
              </>
            )}

            {planData && planData.coupons === 1 && (
              <Route path="/manage_coupons" element={<ManageCoupons />}></Route>
            )}

            <Route path="/account_management" element={<Profile />}></Route>

            <Route path="/updates" element={<VerUpdates />}></Route>
            <Route path="/fooders_plans" element={<FoodersPlans />}></Route>


            <Route path="/forgot_password" element={<ForgotPage />}></Route>
            <Route
              path="/reset_password/:token"
              element={<ResetPage />}
            ></Route>
            <Route path="*" element={<NotFoundPage />}></Route>
          </Routes>
        </div>
      </BrowserRouter>
    </>
  );
};

export default App;
