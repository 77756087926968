import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import SideBar from "../other/SideBar";
import NavBar from "../other/NavBar";
import {
  DeleteCategoryMenuURL,
  GetCategoryMenuURL,
  SearchCategoryMenuURL,
  UpdateCategoryMenuStatusURL,
  ScriptId,
  ScriptSRC,
  GetCategoryMenuListURL,
} from "../../assets/api/BaseURL";
import LoadingModal from "../../loader/LoadingModal";
// import DeleteModal from "../deleteModal/DeleteModal";
import Notification from "../other/Notification";
import { useToast } from "../../../context/ToastContext";
import Select from "react-select";

const CategoryMenu = () => {
  const navigate = useNavigate();
  const { addToast } = useToast();
  const [token, setToken] = useState("");
  const [toggleSideBar, setToggleSideBar] = useState(false);
  const [canvas, setCanvas] = useState(false);
  const [data, setData] = useState([]);
  const [flag, setFlag] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const availableRecordsPerPageOptions = [10, 25, 50, 100];
  const [isLoading, setIsLoading] = useState(false);
  // const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [totalPages, setTotalPages] = useState(1);
  const [totalrecords, setTotalRecords] = useState(0);
  const [pageNumbersBatch, setPageNumbersBatch] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [filteredData, setFilteredData] = useState(null);

  const [selectedDeleteOption, setSelectedDeleteOption] = useState("");
  const [selectedMenu, setSelectedMenu] = useState(null);
  const [selectedMenuName, setSelectedMenuName] = useState("");
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [menuList, setMenuList] = useState([]);

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const calculateStartSerialNumber = () => {
    return (currentPage - 1) * recordsPerPage + 1;
  };

  const handleToggleSidebar = (e) => {
    e.preventDefault();
    setToggleSideBar(!toggleSideBar);
  };

  const handleToggleOffCanvas = (e) => {
    e.preventDefault();
    setCanvas(!canvas);
  };

  const handleAddMenuClick = (e) => {
    e.preventDefault();
    navigate("/add_menu");
  };

  // const handleDelete = (e, id) => {
  //   e.preventDefault();
  //   setDeleteId([id]);
  //   setShowDeleteModal(!showDeleteModal);
  // };

  const handleDelete = async (e, id, name) => {
    e.preventDefault();
    try {
      const response = await axios.get(GetCategoryMenuListURL, {
        headers: { authorization: `Bearer ${token}` },
      });

      if (response.status === 200 && response.data.data) {
        const modifiedData = response.data.data
          .filter((item) => item.id !== id)
          .map((item) => ({
            value: item.id,
            label: item.name,
          }));
        setMenuList(modifiedData);
        setDeleteId([id]);
        setSelectedMenuName(name);
        setSelectedDeleteOption("");
        setSelectedMenu(null);
        setIsDeleteModal(true);
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        addToast("error", error.response.data.message);
      } else {
        addToast("error", error.message);
      }
      console.log(error);
    }
  };

  // const handleDeleteCategoryMenu = async () => {
  //   try {
  //     const response = await axios({
  //       method: "delete",
  //       url: DeleteCategoryMenuURL,
  //       data: deleteId,
  //       headers: {
  //         "Content-Type": "application/json",
  //         authorization: `Bearer ${token}`,
  //       },
  //     });
  //     if (response.status === 200 && response.data.message) {
  //       setFlag(!flag);
  //       setShowDeleteModal(!showDeleteModal);
  //       addToast("success", response.data.message);
  //     }
  //   } catch (error) {
  //     if (error.response && error.response.data.message) {
  //       addToast("error", error.response.data.message);
  //     } else {
  //       addToast("error", error.message);
  //     }
  //     console.log(error);
  //   }
  // };

  const handleDeleteCategoryMenu = async (e) => {
    e.preventDefault();
    if (selectedDeleteOption === "") {
      addToast("error", "Select Delete option first");
    } else if (selectedDeleteOption === "Assign" && selectedMenu === null) {
      addToast("error", "Select Category");
    } else {
      let dataToSend;
      if (selectedDeleteOption === "Assign") {
        dataToSend = {
          delete_id: deleteId,
          menu_id: selectedMenu.value,
        };
      } else if (selectedDeleteOption === "Delete") {
        dataToSend = {
          delete_id: deleteId,
        };
      }

      try {
        const response = await axios({
          method: "delete",
          url: DeleteCategoryMenuURL,
          data: dataToSend,
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${token}`,
          },
        });
        if (response.status === 200 && response.data.message) {
          setFlag(!flag);
          setIsDeleteModal(false);
          addToast("success", response.data.message);
        }
      } catch (error) {
        if (error.response && error.response.data.message) {
          addToast("error", error.response.data.message);
        } else {
          addToast("error", error.message);
        }
        console.log(error);
      }
    }
  };

  const handleEditClick = (e, id) => {
    e.preventDefault();
    localStorage.setItem("menuID", id);
    navigate(`/edit_menu`);
  };

  const handleStatusToggle = async (e, itemId) => {
    setIsLoading(true);

    const dataU = { id: itemId, status: e.target.checked ? 1 : 0 };

    try {
      const response = await axios({
        method: "put",
        url: UpdateCategoryMenuStatusURL,
        data: dataU,
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        setFlag(!flag);
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        addToast("error", error.response.data.message);
      } else {
        addToast("error", error.message);
      }
      console.log(error);
    }
    setIsLoading(false);
  };

  const handleDeleteOptionChange = (event) => {
    setSelectedDeleteOption(event.target.value);
    setSelectedMenu(null);
  };

  const handleSelectMenu = (selectedOption) => {
    setSelectedMenu(selectedOption);
  };

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/");
    }
  }, [navigate]);

  useEffect(() => {
    setToken(localStorage.getItem("token"));
  }, [token]);

  useEffect(() => {
    const existingScript = document.getElementById(ScriptId);
    if (!existingScript && localStorage.getItem("userId")) {
      const userId = localStorage.getItem("userId");
      const script = document.createElement("script");
      script.src = ScriptSRC + userId.replace(/"/g, "");
      script.defer = true;
      script.id = ScriptId;
      document.head.appendChild(script);
    }
  }, []);

  useEffect(() => {
    if (token) {
      async function getData() {
        setIsLoading(true);
        try {
          const response = await axios.get(GetCategoryMenuURL, {
            params: {
              page: currentPage,
              limit: recordsPerPage,
            },
            headers: { authorization: `Bearer ${token}` },
          });

          if (response.status === 200 && response.data.data) {
            setData(response.data.data);
            setTotalRecords(response.data.totalRecord);
          } else if (response.status === 200 && response.data.message) {
            setData([]);
            setTotalRecords(0);
          }
        } catch (error) {
          if (error.response && error.response.data.message) {
            addToast("error", error.response.data.message);
          } else {
            addToast("error", error.message);
          }
          console.log(error);
        }
        setIsLoading(false);
      }
      getData();
    }
  }, [token, flag, currentPage, recordsPerPage, addToast]);

  useEffect(() => {
    if (totalrecords) {
      const totalPages = Math.ceil(totalrecords / recordsPerPage);
      setTotalPages(totalPages);

      if (currentPage > totalPages) {
        setCurrentPage(totalPages);
      } else if (currentPage < 1) {
        setCurrentPage(1);
      }

      const maxPageNumbers = 1;
      let startPage = Math.max(currentPage - maxPageNumbers, 1);
      let endPage = Math.min(currentPage + maxPageNumbers, totalPages);

      if (endPage - startPage + 1 <= maxPageNumbers * 2) {
        if (startPage === 1) {
          endPage = Math.min(
            endPage + (maxPageNumbers * 2 - endPage + startPage - 1),
            totalPages
          );
        } else if (endPage === totalPages) {
          startPage = Math.max(
            startPage - (maxPageNumbers * 2 - endPage + startPage - 1),
            1
          );
        }
      }

      if (currentPage === 1) {
        endPage = Math.min(3, totalPages);
      }

      if (currentPage === totalPages && totalPages >= 3) {
        startPage = Math.max(currentPage - 2, 1);
        endPage = totalPages;
      }

      const batch = Array.from(
        { length: endPage - startPage + 1 },
        (_, i) => startPage + i
      );
      setPageNumbersBatch(batch);
    }
  }, [currentPage, recordsPerPage, totalrecords, totalPages]);

  useEffect(() => {
    const handleSearchChange = async () => {
      const searchTerm = searchInput.toLowerCase();

      if (searchTerm.length >= 3) {
        try {
          const response = await axios.get(SearchCategoryMenuURL, {
            params: {
              name: searchTerm,
            },
            headers: { authorization: `Bearer ${token}` },
          });

          if (response.status === 200 && response.data.data) {
            setFilteredData(response.data.data);
          } else if (response.status === 200 && response.data.message) {
            setFilteredData([]);
          }
        } catch (error) {
          if (error.response && error.response.data.message) {
            addToast("error", error.response.data.message);
          } else {
            addToast("error", error.message);
          }
          console.log(error);
        }
      } else {
        setFilteredData(null);
      }
    };
    handleSearchChange();
  }, [token, searchInput, flag, addToast]);

  return (
    <>
      {token && (
        <>
          <div
            className={
              toggleSideBar
                ? "container-scroller sidebar-icon-only"
                : "container-scroller"
            }
          >
            <nav className="navbar default-layout col-lg-12 col-12 p-0 fixed-top d-flex align-items-top flex-row">
              <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-start">
                <Link className="navbar-brand brand-logo-mini" to="/live_orders">
                  <img src="images/mini-kr-logo.svg" alt="logo" />
                </Link>
                <div className="me-1">
                  <button
                    className={
                      toggleSideBar
                        ? "navbar-toggler navbar-toggler align-self-center"
                        : "navbar-toggler navbar-toggler align-self-center text-white"
                    }
                    type="button"
                    onClick={handleToggleSidebar}
                  >
                    <span className="icon-menu"></span>
                  </button>
                </div>
                <div>
                  <Link to="/live_orders">
                    <span className="navbar-brand brand-logo">
                      <img src="images/Khateraho_logo_white.png" alt="logo" />
                    </span>
                  </Link>
                </div>
              </div>
              <NavBar handleToggle={handleToggleOffCanvas} />
            </nav>
            <div className="container-fluid page-body-wrapper px-0">
              <SideBar active="view_menus" toggleCanvas={toggleSideBar} />

              <div className="main-panel">
                <div className="content-wrapper">
                  <div className="row">
                    <div className="col-sm-12">
                      <h3 className="ukhd mb-3">Category</h3>
                      <div className="row flex-grow">
                        <div className="col-12 grid-margin stretch-card">
                          <div className="card card-rounded">
                            <div className="card-body">
                              <div className="d-flex justify-content-between align-items-start">
                                <div className="vsalign">
                                  <h4 className="card-title card-title-dash">
                                    All Category
                                  </h4>
                                </div>
                                <div className="vsalign">
                                  <span
                                    className="btn btn-primary text-white mb-0 me-0"
                                    onClick={handleAddMenuClick}
                                  >
                                    <i className="menu-icon mdi mdi-floor-plan vsalign"></i>
                                    Add Category
                                  </span>
                                </div>
                              </div>
                              <div className="table-responsive mt-1">
                                <div className="d-flex justify-content-between align-items-start">
                                  <div className="vsalign">
                                    {searchInput === "" && (
                                      <>
                                        <label>Show records per page:</label>
                                        <select
                                          value={recordsPerPage}
                                          onChange={(e) =>
                                            setRecordsPerPage(
                                              parseInt(e.target.value)
                                            )
                                          }
                                        >
                                          {availableRecordsPerPageOptions.map(
                                            (option) => (
                                              <option
                                                key={option}
                                                value={option}
                                              >
                                                {option}
                                              </option>
                                            )
                                          )}
                                        </select>
                                      </>
                                    )}
                                  </div>
                                  <div className="vsalign mt-2">
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Search Here"
                                      value={searchInput}
                                      onChange={(e) =>
                                        setSearchInput(e.target.value)
                                      }
                                    />
                                  </div>
                                </div>
                                <table className="table select-table">
                                  <thead>
                                    <tr>
                                      <th>S.No.</th>
                                      <th>Menu</th>
                                      <th style={{ width: "30%" }}>
                                        Description
                                      </th>
                                      <th>Status</th>
                                      <th className="text-right">Action</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {searchInput.length >= 3 ? (
                                      <>
                                        {filteredData ? (
                                          <>
                                            {filteredData.length === 0 ? (
                                              <tr>
                                                <td
                                                  colSpan="5"
                                                  className="text-center"
                                                >
                                                  No data found
                                                </td>
                                              </tr>
                                            ) : (
                                              filteredData.map(
                                                (item, index) => (
                                                  <tr key={item.menu_id}>
                                                    <td>
                                                      {calculateStartSerialNumber() +
                                                        index}
                                                    </td>
                                                    <td>
                                                      <div>
                                                        <h6>
                                                          {item.menu_name}
                                                        </h6>
                                                        <mark className="bg-warning text-white border borderrds ps-2 pe-2">
                                                          Product(s):
                                                          {item.product_count}
                                                        </mark>
                                                      </div>
                                                    </td>
                                                    <td>
                                                      <p>
                                                        {item.menu_description}
                                                      </p>
                                                    </td>
                                                    <td className="togglecss">
                                                      <div
                                                        className={`button r ${item.menu_status === 1
                                                            ? "active"
                                                            : ""
                                                          }`}
                                                        id="button-1"
                                                      >
                                                        <input
                                                          type="checkbox"
                                                          className="checkbox"
                                                          checked={
                                                            item.menu_status ===
                                                            1
                                                          }
                                                          onChange={(e) =>
                                                            handleStatusToggle(
                                                              e,
                                                              item.menu_id
                                                            )
                                                          }
                                                        />
                                                        <div className="knobs"></div>
                                                        <div className="layer"></div>
                                                      </div>
                                                    </td>
                                                    <td className="text-right pe-0">
                                                      <i
                                                        className="fs-20 mdi mdi-content-save-edit-outline text-success"
                                                        title="Edit"
                                                        style={{
                                                          cursor: "pointer",
                                                        }}
                                                        onClick={(e) =>
                                                          handleEditClick(
                                                            e,
                                                            item.menu_id
                                                          )
                                                        }
                                                      ></i>
                                                      &nbsp;&nbsp;
                                                      <i
                                                        className="fs-20 mdi mdi-archive text-danger"
                                                        title="Delete"
                                                        style={{
                                                          cursor: "pointer",
                                                        }}
                                                        onClick={(e) =>
                                                          handleDelete(
                                                            e,
                                                            item.menu_id
                                                          )
                                                        }
                                                      ></i>
                                                    </td>
                                                  </tr>
                                                )
                                              )
                                            )}
                                          </>
                                        ) : (
                                          <>
                                            <tr>
                                              <td
                                                colSpan="5"
                                                className="text-center"
                                              >
                                                Loading...
                                              </td>
                                            </tr>
                                          </>
                                        )}
                                      </>
                                    ) : (
                                      <>
                                        {data.length === 0 ? (
                                          <tr>
                                            <td
                                              colSpan="5"
                                              className="text-center"
                                            >
                                              No data found
                                            </td>
                                          </tr>
                                        ) : (
                                          data.map((item, index) => (
                                            <tr key={item.menu_id}>
                                              <td>
                                                {calculateStartSerialNumber() +
                                                  index}
                                              </td>
                                              <td>
                                                <div>
                                                  <h6>{item.menu_name}</h6>
                                                  <mark className="bg-warning text-white border borderrds ps-2 pe-2">
                                                    Product(s):
                                                    {item.product_count}
                                                  </mark>
                                                </div>
                                              </td>
                                              <td>
                                                <p>{item.menu_description}</p>
                                              </td>
                                              <td className="togglecss">
                                                <div
                                                  className={`button r ${item.menu_status === 1
                                                      ? "active"
                                                      : ""
                                                    }`}
                                                  id="button-1"
                                                >
                                                  <input
                                                    type="checkbox"
                                                    className="checkbox"
                                                    checked={
                                                      item.menu_status === 1
                                                    }
                                                    onChange={(e) =>
                                                      handleStatusToggle(
                                                        e,
                                                        item.menu_id
                                                      )
                                                    }
                                                  />
                                                  <div className="knobs"></div>
                                                  <div className="layer"></div>
                                                </div>
                                              </td>
                                              <td className="text-right pe-0">
                                                <i
                                                  className="fs-20 mdi mdi-content-save-edit-outline text-success"
                                                  title="Edit"
                                                  style={{ cursor: "pointer" }}
                                                  onClick={(e) =>
                                                    handleEditClick(
                                                      e,
                                                      item.menu_id
                                                    )
                                                  }
                                                ></i>
                                                &nbsp;&nbsp;
                                                <i
                                                  className="fs-20 mdi mdi-archive text-danger"
                                                  title="Delete"
                                                  style={{ cursor: "pointer" }}
                                                  onClick={(e) =>
                                                    handleDelete(
                                                      e,
                                                      item.menu_id,
                                                      item.menu_name
                                                    )
                                                  }
                                                ></i>
                                              </td>
                                            </tr>
                                          ))
                                        )}
                                      </>
                                    )}
                                  </tbody>
                                </table>
                              </div>

                              {searchInput === "" && (
                                <div className="row">
                                  <div className="col-6">
                                    {data.length > 0 && (
                                      <p>
                                        Showing{" "}
                                        {(currentPage - 1) * recordsPerPage + 1}{" "}
                                        to{" "}
                                        {(currentPage - 1) * recordsPerPage +
                                          data.length}{" "}
                                        of {totalrecords} entries
                                      </p>
                                    )}
                                  </div>
                                  <div className="col-6">
                                    <div className="pagination">
                                      <button
                                        onClick={handlePrevPage}
                                        disabled={currentPage === 1}
                                        className={
                                          currentPage === 1 ? "disabled" : ""
                                        }
                                      >
                                        {`<<`}
                                      </button>
                                      {pageNumbersBatch.map((pageNumber) => (
                                        <button
                                          key={pageNumber}
                                          onClick={() =>
                                            handlePageClick(pageNumber)
                                          }
                                          className={
                                            pageNumber === currentPage
                                              ? "active"
                                              : ""
                                          }
                                        >
                                          {pageNumber}
                                        </button>
                                      ))}
                                      <button
                                        onClick={handleNextPage}
                                        disabled={currentPage === totalPages}
                                        className={
                                          currentPage === totalPages
                                            ? "disabled"
                                            : ""
                                        }
                                      >
                                        {`>>`}
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className={isDeleteModal ? "modal fade show d-block" : "modal fade"}
            id="exampleModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content w-85">
                <div className="modal-header py-2">
                  <h6 className="modal-title" id="exampleModalLabel">
                    Are you sure ? You want to delete {selectedMenuName}{" "}
                    category.
                  </h6>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() => {
                      setIsDeleteModal(false);
                    }}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body py-2">
                  <div className="container">
                    <form className="forms-sample">
                      <h4 className="card-title m-3 fw-bold ms-0">
                        Select Delete option
                      </h4>
                      <div className="row">
                        <div className="">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="inlineRadioOptions"
                              value="Delete"
                              checked={selectedDeleteOption === "Delete"}
                              onChange={handleDeleteOptionChange}
                            />
                            <label className="form-check-label">
                              Delete category with there products
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="inlineRadioOptions"
                              value="Assign"
                              checked={selectedDeleteOption === "Assign"}
                              onChange={handleDeleteOptionChange}
                            />
                            <label className="form-check-label">
                              Assign products to other category
                            </label>
                          </div>
                        </div>
                      </div>

                      {selectedDeleteOption === "Assign" && (
                        <>
                          <div className="row">
                            <div className="col-12  mt-3">
                              <label className="">
                                Select Category
                                <span className="text-danger">*</span>
                              </label>
                              <Select
                                options={menuList}
                                value={selectedMenu}
                                onChange={handleSelectMenu}
                              />
                            </div>
                          </div>
                        </>
                      )}
                    </form>
                  </div>
                </div>
                <div className="modal-footer py-1">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-dismiss="modal"
                    onClick={() => {
                      setIsDeleteModal(false);
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={handleDeleteCategoryMenu}
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </div>

          {/* {showDeleteModal && (
            <DeleteModal
              handleModal={setShowDeleteModal}
              handleDelete={handleDeleteCategoryMenu}
            />
          )} */}

          <LoadingModal isLoading={isLoading} />

          <Notification />
        </>
      )}
    </>
  );
};

export default CategoryMenu;
