import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import SideBar from "../other/SideBar";
import NavBar from "../other/NavBar";
import {


    GetFoodersPlans,
    ScriptId,
    ScriptSRC,
} from "../../assets/api/BaseURL";
import LoadingModal from "../../loader/LoadingModal";
import Notification from "../other/Notification";
import { useToast } from "../../../context/ToastContext";
import axios from "axios";

const VarUpdates = () => {
    const navigate = useNavigate();
    const { addToast } = useToast();

    const [token, setToken] = useState("");
    const [toggleSideBar, setToggleSideBar] = useState(false);
    const [canvas, setCanvas] = useState(false);

    const [foodersPlans, setFoodersPlans] = useState([]);

    const [isLoading, setIsLoading] = useState(false);
    const [currencySymbol, setCurrencySymbol] = useState("");


    const handleToggleSidebar = (e) => {
        e.preventDefault();
        setToggleSideBar(!toggleSideBar);
    };

    const handleToggleOffCanvas = (e) => {
        e.preventDefault();
        setCanvas(!canvas);
    };





    useEffect(() => {
        if (!localStorage.getItem("token")) {
            navigate("/");
        }
    }, [navigate]);

    useEffect(() => {
        setToken(localStorage.getItem("token"));
        setCurrencySymbol(localStorage.getItem("currencySymbol"));
    }, [token]);







    useEffect(() => {
        const existingScript = document.getElementById(ScriptId);
        if (!existingScript && localStorage.getItem("userId")) {
            const userId = localStorage.getItem("userId");
            const script = document.createElement("script");
            script.src = ScriptSRC + userId.replace(/"/g, "");
            script.defer = true;
            script.id = ScriptId;
            document.head.appendChild(script);
        }
    }, []);




    useEffect(() => {
        if (token) {

            getData();
        }
    }, [token]);





    async function getData() {
        setIsLoading(true);
        try {
            const response = await axios.get(GetFoodersPlans, {

                headers: { authorization: `Bearer ${token}` },
            });

            if (response.status === 200 && response.data.data) {
                // setProductData(response.data.data);

                let tempData = response.data.data
                const keys = Object.keys(tempData[0]);
                // console.log(keys);
                let modifiedDetails = [];

                keys.forEach((key) => {
                    let tempRow = {}
                    tempData.forEach((item) => {
                        // console.log(item[key]);
                        // console.log(item.plan_name)
                        tempRow[convertToSnakeCase(item.plan_id.toString())] = item[key]

                    });
                    tempRow.feature_name = key
                    if (key !== 'plan_id') {
                        modifiedDetails.push(tempRow);
                    }

                });

                console.log(modifiedDetails)


                // setFoodersPlans(response.data.data);
                setFoodersPlans(modifiedDetails);


            } else if (response.status === 200 && response.data.message) {
                setFoodersPlans([]);
            }


        } catch (error) {
            if (error.response && error.response.data.message) {
                addToast("error", error.response.data.message);
            } else {
                addToast("error", error.message);
            }
            console.log(error);
        }
        setIsLoading(false);
    }
    function convertToSnakeCase(inputString) {
        return inputString.replace(/ /g, '_');
    }

    function convertToTitleCase(text) {
        // Split the text into words based on underscores
        let words = text.split('_');

        // Capitalize the first letter of each word
        let capitalizedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));

        // Join the words back together with spaces
        let titleCaseText = capitalizedWords.join(' ');

        return titleCaseText;
    }


    return (
        <>
            {token && (
                <>
                    <div
                        className={
                            toggleSideBar
                                ? "container-scroller sidebar-icon-only"
                                : "container-scroller"
                        }
                    >
                        <nav className="navbar default-layout col-lg-12 col-12 p-0 fixed-top d-flex align-items-top flex-row">
                            <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-start">
                                <Link className="navbar-brand brand-logo-mini" to="/live_orders">
                                    <img src="images/mini-kr-logo.svg" alt="logo" />
                                </Link>
                                <div className="me-1">
                                    <button
                                        className={
                                            toggleSideBar
                                                ? "navbar-toggler navbar-toggler align-self-center"
                                                : "navbar-toggler navbar-toggler align-self-center text-white"
                                        }
                                        type="button"
                                        onClick={handleToggleSidebar}
                                    >
                                        <span className="icon-menu"></span>
                                    </button>
                                </div>
                                <div>
                                    <Link to="/live_orders">
                                        <span className="navbar-brand brand-logo">
                                            <img src="images/Khateraho_logo_white.png" alt="logo" />
                                        </span>
                                    </Link>
                                </div>
                            </div>
                            <NavBar handleToggle={handleToggleOffCanvas} />
                        </nav>
                        <div className="container-fluid page-body-wrapper px-0">
                            <SideBar active="fooders_plans" toggleCanvas={toggleSideBar} />

                            <div className="main-panel">
                                <div className="content-wrapper">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <h3 className="ukhd mb-3">Fooder plans</h3>
                                            <div className="row flex-grow">
                                                <div className="col-12 grid-margin">

                                                    <div className={'my-2'}>
                                                        <div className="card card-rounded">
                                                            <div className="card-body trcnt">
                                                                {/* <div className="row">

                                                                    <div className="table-responsive mt-1">
                                                                        <table id="pdfContent" className="table table-bordered">
                                                                            <thead>



                                                                                <tr>
                                                                                    <th>Plan Name</th>
                                                                                    <th>Plan Status</th>
                                                                                    <th>price</th>
                                                                                    <th>Comments Reviews</th>
                                                                                    <th>Khateraho Exposure</th>
                                                                                    <th>Number of Menus</th>
                                                                                    <th>Number of Products</th>

                                                                                    <th>Ordering Facility</th>
                                                                                    <th>SMS Notifications</th>
                                                                                    <th>Call Notifications</th>
                                                                                    <th>Specialized Reports</th>
                                                                                    <th>Dashboard</th>
                                                                                    <th>Live Orders</th>
                                                                                    <th>Menu Products</th>
                                                                                    <th>Staff Manager</th>


                                                                                    <th>Orders</th>
                                                                                    <th>Inventory</th>
                                                                                    <th>Pos</th>
                                                                                    <th>Fooder CP</th>
                                                                                    <th>Customers</th>
                                                                                    <th>Verififation Documents</th>
                                                                                    <th>Manage Tables</th>
                                                                                    <th>Table Booking</th>
                                                                                    <th>Callwaiter Settings</th>
                                                                                    <th>Online Delivery Settings</th>
                                                                                    <th>Coupons</th>
                                                                                    <th>Number Of Qrcode</th>
                                                                                    <th>Reports</th>
                                                                                    <th>Report Notification On Email</th>


                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {foodersPlans.map((item, index) => (
                                                                                    <tr key={index}>
                                                                                        <td>{item.plan_name}</td>
                                                                                        <td>
                                                                                            <div className="form-check form-check-inline __web-inspector-hide-shortcut__">
                                                                                                <input
                                                                                                    className="form-check-input"
                                                                                                    type="checkbox"

                                                                                                    checked={item.plan_status}
                                                                                                    readOnly={true}
                                                                                                />
                                                                                            </div>
                                                                                        </td>
                                                                                        <td>{currencySymbol}{item.price}</td>
                                                                                        <td>
                                                                                            <div className="form-check form-check-inline __web-inspector-hide-shortcut__">
                                                                                                <input
                                                                                                    className="form-check-input"
                                                                                                    type="checkbox"

                                                                                                    checked={item.comments_reviews}
                                                                                                    readOnly={true}
                                                                                                />
                                                                                            </div>
                                                                                        </td>

                                                                                        <td>
                                                                                            <div className="form-check form-check-inline __web-inspector-hide-shortcut__">
                                                                                                <input
                                                                                                    className="form-check-input"
                                                                                                    type="checkbox"

                                                                                                    checked={item.khateraho_exposure}
                                                                                                    readOnly={true}
                                                                                                />
                                                                                            </div>
                                                                                        </td>

                                                                                        <td>{item.number_of_menus}</td>
                                                                                        <td>{item.number_of_products}</td>

                                                                                        <td>
                                                                                            <div className="form-check form-check-inline __web-inspector-hide-shortcut__">
                                                                                                <input
                                                                                                    className="form-check-input"
                                                                                                    type="checkbox"

                                                                                                    checked={item.ordering_facility}
                                                                                                    readOnly={true}
                                                                                                />
                                                                                            </div>
                                                                                        </td>

                                                                                        <td>
                                                                                            <div className="form-check form-check-inline __web-inspector-hide-shortcut__">
                                                                                                <input
                                                                                                    className="form-check-input"
                                                                                                    type="checkbox"

                                                                                                    checked={item.sms_notifications}
                                                                                                    readOnly={true}
                                                                                                />
                                                                                            </div>
                                                                                        </td>

                                                                                        <td>
                                                                                            <div className="form-check form-check-inline __web-inspector-hide-shortcut__">
                                                                                                <input
                                                                                                    className="form-check-input"
                                                                                                    type="checkbox"

                                                                                                    checked={item.call_notifications}
                                                                                                    readOnly={true}
                                                                                                />
                                                                                            </div>
                                                                                        </td>

                                                                                        <td>
                                                                                            <div className="form-check form-check-inline __web-inspector-hide-shortcut__">
                                                                                                <input
                                                                                                    className="form-check-input"
                                                                                                    type="checkbox"

                                                                                                    checked={item.specialized_reports}
                                                                                                    readOnly={true}
                                                                                                />
                                                                                            </div>
                                                                                        </td>

                                                                                        <td>
                                                                                            <div className="form-check form-check-inline __web-inspector-hide-shortcut__">
                                                                                                <input
                                                                                                    className="form-check-input"
                                                                                                    type="checkbox"

                                                                                                    checked={item.dashboard}
                                                                                                    readOnly={true}
                                                                                                />
                                                                                            </div>
                                                                                        </td>

                                                                                        <td>
                                                                                            <div className="form-check form-check-inline __web-inspector-hide-shortcut__">
                                                                                                <input
                                                                                                    className="form-check-input"
                                                                                                    type="checkbox"

                                                                                                    checked={item.live_orders}
                                                                                                    readOnly={true}
                                                                                                />
                                                                                            </div>
                                                                                        </td>

                                                                                        <td>
                                                                                            <div className="form-check form-check-inline __web-inspector-hide-shortcut__">
                                                                                                <input
                                                                                                    className="form-check-input"
                                                                                                    type="checkbox"

                                                                                                    checked={item.menu_products}
                                                                                                    readOnly={true}
                                                                                                />
                                                                                            </div>
                                                                                        </td>

                                                                                        <td>
                                                                                            <div className="form-check form-check-inline __web-inspector-hide-shortcut__">
                                                                                                <input
                                                                                                    className="form-check-input"
                                                                                                    type="checkbox"

                                                                                                    checked={item.staff_manager}
                                                                                                    readOnly={true}
                                                                                                />
                                                                                            </div>
                                                                                        </td>

                                                                                        <td>
                                                                                            <div className="form-check form-check-inline __web-inspector-hide-shortcut__">
                                                                                                <input
                                                                                                    className="form-check-input"
                                                                                                    type="checkbox"

                                                                                                    checked={item.orders}
                                                                                                    readOnly={true}
                                                                                                />
                                                                                            </div>
                                                                                        </td>

                                                                                        <td>
                                                                                            <div className="form-check form-check-inline __web-inspector-hide-shortcut__">
                                                                                                <input
                                                                                                    className="form-check-input"
                                                                                                    type="checkbox"

                                                                                                    checked={item.inventory}
                                                                                                    readOnly={true}
                                                                                                />
                                                                                            </div>
                                                                                        </td>

                                                                                        <td>
                                                                                            <div className="form-check form-check-inline __web-inspector-hide-shortcut__">
                                                                                                <input
                                                                                                    className="form-check-input"
                                                                                                    type="checkbox"

                                                                                                    checked={item.pos}
                                                                                                    readOnly={true}
                                                                                                />
                                                                                            </div>
                                                                                        </td>

                                                                                        <td>
                                                                                            <div className="form-check form-check-inline __web-inspector-hide-shortcut__">
                                                                                                <input
                                                                                                    className="form-check-input"
                                                                                                    type="checkbox"

                                                                                                    checked={item.fooder_cp}
                                                                                                    readOnly={true}
                                                                                                />
                                                                                            </div>
                                                                                        </td>

                                                                                        <td>
                                                                                            <div className="form-check form-check-inline __web-inspector-hide-shortcut__">
                                                                                                <input
                                                                                                    className="form-check-input"
                                                                                                    type="checkbox"

                                                                                                    checked={item.customers}
                                                                                                    readOnly={true}
                                                                                                />
                                                                                            </div>
                                                                                        </td>

                                                                                        <td>
                                                                                            <div className="form-check form-check-inline __web-inspector-hide-shortcut__">
                                                                                                <input
                                                                                                    className="form-check-input"
                                                                                                    type="checkbox"

                                                                                                    checked={item.verififation_documents}
                                                                                                    readOnly={true}
                                                                                                />
                                                                                            </div>
                                                                                        </td>

                                                                                        <td>
                                                                                            <div className="form-check form-check-inline __web-inspector-hide-shortcut__">
                                                                                                <input
                                                                                                    className="form-check-input"
                                                                                                    type="checkbox"

                                                                                                    checked={item.manage_tables}
                                                                                                    readOnly={true}
                                                                                                />
                                                                                            </div>
                                                                                        </td>

                                                                                        <td>
                                                                                            <div className="form-check form-check-inline __web-inspector-hide-shortcut__">
                                                                                                <input
                                                                                                    className="form-check-input"
                                                                                                    type="checkbox"

                                                                                                    checked={item.table_booking}
                                                                                                    readOnly={true}
                                                                                                />
                                                                                            </div>
                                                                                        </td>

                                                                                        <td>
                                                                                            <div className="form-check form-check-inline __web-inspector-hide-shortcut__">
                                                                                                <input
                                                                                                    className="form-check-input"
                                                                                                    type="checkbox"

                                                                                                    checked={item.callwaiter_settings}
                                                                                                    readOnly={true}
                                                                                                />
                                                                                            </div>
                                                                                        </td>

                                                                                        <td>
                                                                                            <div className="form-check form-check-inline __web-inspector-hide-shortcut__">
                                                                                                <input
                                                                                                    className="form-check-input"
                                                                                                    type="checkbox"

                                                                                                    checked={item.online_delivery_settings}
                                                                                                    readOnly={true}
                                                                                                />
                                                                                            </div>
                                                                                        </td>

                                                                                        <td>
                                                                                            <div className="form-check form-check-inline __web-inspector-hide-shortcut__">
                                                                                                <input
                                                                                                    className="form-check-input"
                                                                                                    type="checkbox"

                                                                                                    checked={item.coupons}
                                                                                                    readOnly={true}
                                                                                                />
                                                                                            </div>
                                                                                        </td>

                                                                                        <td>
                                                                                            <div className="form-check form-check-inline __web-inspector-hide-shortcut__">
                                                                                                <input
                                                                                                    className="form-check-input"
                                                                                                    type="checkbox"

                                                                                                    checked={item.number_of_qrcode}
                                                                                                    readOnly={true}
                                                                                                />
                                                                                            </div>
                                                                                        </td>

                                                                                        <td>
                                                                                            <div className="form-check form-check-inline __web-inspector-hide-shortcut__">
                                                                                                <input
                                                                                                    className="form-check-input"
                                                                                                    type="checkbox"

                                                                                                    checked={item.reports}
                                                                                                    readOnly={true}
                                                                                                />
                                                                                            </div>
                                                                                        </td>

                                                                                        <td>
                                                                                            <div className="form-check form-check-inline __web-inspector-hide-shortcut__">
                                                                                                <input
                                                                                                    className="form-check-input"
                                                                                                    type="checkbox"

                                                                                                    checked={item.report_notification_on_email}
                                                                                                    readOnly={true}
                                                                                                />
                                                                                            </div>
                                                                                        </td>

                                                                                        

                                                                                        



                                                                                    </tr>

                                                                                ))}

                                                                            </tbody>
                                                                        </table>
                                                                    </div>


                                                                </div> */}

























                                                                <div className="row">

                                                                    <div className="table-responsive mt-1">
                                                                        <table id="pdfContent" className="table table-bordered">
                                                                            <thead>



                                                                                <tr>
                                                                                    <th>Name</th>
                                                                                    <th>Free</th>
                                                                                    <th>Freemium</th>
                                                                                    <th>Silver	</th>
                                                                                    <th>Gold</th>
                                                                                    <th>Platinum	</th>
                                                                                    <th>Platinum gold	</th>



                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {foodersPlans.map((item, index) => (
                                                                                    <tr key={index}>

                                                                                        <td>
                                                                                            {convertToTitleCase(item.feature_name)}
                                                                                        </td>
                                                                                        <td>
                                                                                            <div className="form-check form-check-inline __web-inspector-hide-shortcut__">
                                                                                                 
                                                                                                {item[0] ?
                                                                                                    <i className="fa fa-check fa-2x text-success" aria-hidden="true"></i>
                                                                                                    :

                                                                                                    <i className="fa fa-times fa-2x text-danger" aria-hidden="true"></i>
                                                                                                }


                                                                                            </div>
                                                                                        </td>
                                                                                        <td>
                                                                                            <div className="form-check form-check-inline __web-inspector-hide-shortcut__">
                                                                                                 

                                                                                                {item[1] ?
                                                                                                    <i className="fa fa-check fa-2x text-success" aria-hidden="true"></i>
                                                                                                    :

                                                                                                    <i className="fa fa-times fa-2x text-danger" aria-hidden="true"></i>
                                                                                                }
                                                                                            </div>
                                                                                        </td>
                                                                                        <td>
                                                                                            <div className="form-check form-check-inline __web-inspector-hide-shortcut__">
                                                                                                
                                                                                                {item[2] ?
                                                                                                    <i className="fa fa-check fa-2x text-success" aria-hidden="true"></i>
                                                                                                    :

                                                                                                    <i className="fa fa-times fa-2x text-danger" aria-hidden="true"></i>
                                                                                                }
                                                                                            </div>
                                                                                        </td>
                                                                                        <td>
                                                                                            <div className="form-check form-check-inline __web-inspector-hide-shortcut__">
                                                                                                 
                                                                                                {item[3] ?
                                                                                                    <i className="fa fa-check fa-2x text-success" aria-hidden="true"></i>
                                                                                                    :

                                                                                                    <i className="fa fa-times fa-2x text-danger" aria-hidden="true"></i>
                                                                                                }
                                                                                            </div>
                                                                                        </td>
                                                                                        <td>
                                                                                            <div className="form-check form-check-inline __web-inspector-hide-shortcut__">
                                                                                                 
                                                                                                {item[4] ?
                                                                                                    <i className="fa fa-check fa-2x text-success" aria-hidden="true"></i>
                                                                                                    :

                                                                                                    <i className="fa fa-times fa-2x text-danger" aria-hidden="true"></i>
                                                                                                }
                                                                                            </div>
                                                                                        </td>
                                                                                        <td>
                                                                                            <div className="form-check form-check-inline __web-inspector-hide-shortcut__">
                                                                                                 
                                                                                                {item[5] ?
                                                                                                    <i className="fa fa-check fa-2x text-success" aria-hidden="true"></i>
                                                                                                    :

                                                                                                    <i className="fa fa-times fa-2x text-danger" aria-hidden="true"></i>
                                                                                                }
                                                                                            </div>
                                                                                        </td>






                                                                                    </tr>

                                                                                ))}

                                                                            </tbody>
                                                                        </table>
                                                                    </div>


                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>




                                                </div>



                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <LoadingModal isLoading={isLoading} />

                    <Notification />
                </>
            )}
        </>
    );
};

export default VarUpdates;
