import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import SideBar from "../other/SideBar";
import NavBar from "../other/NavBar";
import { ScriptId, ScriptSRC } from "../../assets/api/BaseURL";
import Notification from "../other/Notification";

const Reports = () => {
  const navigate = useNavigate();
  const [token, setToken] = useState("");
  const [toggleSideBar, setToggleSideBar] = useState(false);
  const [canvas, setCanvas] = useState(false);

  const handleToggleSidebar = (e) => {
    e.preventDefault();
    setToggleSideBar(!toggleSideBar);
  };

  const handleToggleOffCanvas = (e) => {
    e.preventDefault();
    setCanvas(!canvas);
  };

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/");
    }
  }, [navigate]);

  useEffect(() => {
    setToken(localStorage.getItem("token"));
  }, [token]);

  useEffect(() => {
    const existingScript = document.getElementById(ScriptId);
    if (!existingScript && localStorage.getItem("userId")) {
      const userId = localStorage.getItem("userId");
      const script = document.createElement("script");
      script.src = ScriptSRC + userId.replace(/"/g, "");
      script.defer = true;
      script.id = ScriptId;
      document.head.appendChild(script);
    }
  }, []);

  return (
    <>
      {token && (
        <>
          <div
            className={
              toggleSideBar
                ? "container-scroller sidebar-icon-only"
                : "container-scroller"
            }
          >
            <nav className="navbar default-layout col-lg-12 col-12 p-0 fixed-top d-flex align-items-top flex-row">
              <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-start">
                <Link className="navbar-brand brand-logo-mini" to="/live_orders">
                  <img src="images/mini-kr-logo.svg" alt="logo" />
                </Link>
                <div className="me-1">
                  <button
                    className={
                      toggleSideBar
                        ? "navbar-toggler navbar-toggler align-self-center"
                        : "navbar-toggler navbar-toggler align-self-center text-white"
                    }
                    type="button"
                    onClick={handleToggleSidebar}
                  >
                    <span className="icon-menu"></span>
                  </button>
                </div>
                <div>
                  <Link to="/live_orders">
                    <span className="navbar-brand brand-logo">
                      <img src="images/Khateraho_logo_white.png" alt="logo" />
                    </span>
                  </Link>
                </div>
              </div>
              <NavBar handleToggle={handleToggleOffCanvas} />
            </nav>
            <div className="container-fluid page-body-wrapper px-0">
              <SideBar active="reports" toggleCanvas={toggleSideBar} />

              <div className="main-panel">
                <div className="content-wrapper">
                  <div className="row">
                    <div className="col-sm-12">
                      <h3 className="ukhd mb-3">Reports</h3>
                      <div className="row flex-grow">
                        <div className="col-4 grid-margin stretch-card">
                          <div className="card card-rounded">
                            <div className="card-body ">
                              <div className="row text-center">
                                <h4 className="mb-2">GST Reports</h4>
                              </div>
                              <div className="row">
                                <div className="list-group">
                                  <Link
                                    to="/gst_report"
                                    className="list-group-item"
                                  >
                                    <h5 className="list-group-item-heading mb-0">
                                      GST Report
                                    </h5>
                                    <p className="list-group-item-text mb-0">
                                      This GST Report include tax details by
                                      orders with date range
                                    </p>
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>


                        <div className="col-4 grid-margin stretch-card">
                          <div className="card card-rounded">
                            <div className="card-body ">
                              <div className="row text-center">
                                <h4 className="mb-2">Sales Reports</h4>
                              </div>
                              <div className="row">
                                <div className="list-group">
                                  <Link
                                    to="/reports_by_daily_sales"
                                    className="list-group-item"
                                  >
                                    <h5 className="list-group-item-heading mb-0">
                                      Daily Sales Report
                                    </h5>
                                    <p className="list-group-item-text mb-0">
                                      A daily sales report is a record of a business' sales activity for any one day. It includes statistics on the amount of products sold, revenue generated, applicable discounts given, and other relevant factors.                                    </p>
                                    {/* <p className="list-group-item-text mb-0">
                                      Breaks down sales by meal periods (breakfast, lunch, dinner).
                                    </p>
                                    <p className="list-group-item-text mb-0">
                                      Includes total revenue, average transaction value, and customer counts.
                                    </p> */}
                                  </Link>
                                </div>
                              </div>

                            </div>
                          </div>
                        </div>

                        <div className="col-4 grid-margin stretch-card">
                          <div className="card card-rounded">
                            <div className="card-body ">
                              <div className="row text-center">
                                <h4 className="mb-2">Items Reports</h4>
                              </div>
                              <div className="row">
                                <div className="list-group">
                                  <Link
                                    to="/reports_by_items_types"
                                    className="list-group-item"
                                  >
                                    <h5 className="list-group-item-heading mb-0">
                                    Items Reports
                                    </h5>
                                    <p className="list-group-item-text mb-0">
                                    This report shows sales of each items with their respective quantities sold.</p>
                                    
                                  </Link>
                                </div>
                              </div>

                            </div>
                          </div>
                        </div>

                        <div className="col-4 grid-margin stretch-card">
                          <div className="card card-rounded">
                            <div className="card-body ">
                              <div className="row text-center">
                                <h4 className="mb-2">Orders Reports</h4>
                              </div>
                              <div className="row">
                                <div className="list-group">
                                  <Link
                                    to="/reports_by_order_type"
                                    className="list-group-item"
                                  >
                                    <h5 className="list-group-item-heading mb-0">
                                      Orders Report
                                    </h5>
                                    <p className="list-group-item-text mb-0">
                                    This report provides insights into orders categorized by type and payment status, offering a comprehensive overview for informed decision-making.
                                    </p>
                                  </Link>
                                </div>
                              </div>



                              {/* <div className="row my-1">
                                <div className="list-group">
                                  <Link
                                    to="/reports_by_due_amount"
                                    className="list-group-item"
                                  >
                                    <h5 className="list-group-item-heading mb-0">
                                      Due Amount Report
                                    </h5>
                                    <p className="list-group-item-text mb-0">
                                      Report by due amount
                                    </p>
                                  </Link>
                                </div>
                              </div> */}

                            </div>
                          </div>
                        </div>

                        <div className="col-4 grid-margin stretch-card">
                          <div className="card card-rounded">
                            <div className="card-body ">
                              <div className="row text-center">
                                <h4 className="mb-2">Customised Reports</h4>
                              </div>
                              <div className="row">
                                <div className="list-group">
                                  <Link
                                    to="/reports_by_custom_sales"
                                    className="list-group-item"
                                  >
                                    <h5 className="list-group-item-heading mb-0">
                                      Customised Sales Reports
                                    </h5>
                                    <p className="list-group-item-text mb-0">
                                      A Customized Sales Report provides detailed insights into a business's sales performance within a specified date and time range, encompassing product sales, revenue, discounts, and more.
                                    </p>


                                  </Link>
                                </div>
                              </div>

                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Notification />
        </>
      )}
    </>
  );
};

export default Reports;
