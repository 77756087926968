import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import SideBar from "../other/SideBar";
import NavBar from "../other/NavBar";
import {
  AddonGroupDataItemAddURL,
  CreateAddonGroupURL,
  DeleteAddonGroupItemURL,
  DeleteAddonGroupURL,
  GetAddonGroupListURL,
  ScriptId,
  ScriptSRC,
  SearchProductListForAddonsURL,
  UpdateAddonGroupDataURL,
  UpdateAddonGroupStatusURL,
} from "../../assets/api/BaseURL";
import Notification from "../other/Notification";
import { useToast } from "../../../context/ToastContext";
import LoadingModal from "../../loader/LoadingModal";
import DeleteModal from "../../deleteModal/DeleteModal";

const AddOns = () => {
  const navigate = useNavigate();
  const { addToast } = useToast();
  const [token, setToken] = useState("");
  const [toggleSideBar, setToggleSideBar] = useState(false);
  const [canvas, setCanvas] = useState(false);
  const [currencySymbol, setCurrencySymbol] = useState("");
  const [data, setData] = useState([]);
  const [allMenuProductData, setAllMenuProductData] = useState([]);
  const [addOnGroupDataId, setAddOnGroupDataId] = useState();
  const [addOnGroupData, setAddOnGroupData] = useState({});
  const [flag, setFlag] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [showAddOnForm, setShowAddOnForm] = useState(false);
  const [addOnType, setAddOnType] = useState("");
  const [deleteAddOnId, setDeleteAddOnId] = useState();
  const [deleteAddOnItemId, setDeleteAddOnItemId] = useState();
  const [showDeleteAddOnModal, setShowDeleteAddOnModal] = useState(false);
  const [showDeleteAddOnItemModal, setShowDeleteAddOnItemModal] =
    useState(false);

  const [searchInput, setSearchInput] = useState("");
  const [filteredData, setFilteredData] = useState(null);
  const [showDropDown, setShowDropDown] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState({});
  const [addOnMenuFormData, setAddOnMenuFormData] = useState({
    groupName: "",
    min: "",
    max: "",
    items: [],
  });
  const [addOnNewFormData, setAddOnNewFormData] = useState({
    groupName: "",
    min: "",
    max: "",
    items: [],
  });
  const [addOnNewItemData, setAddOnNewItemData] = useState({
    name: "",
    type: "",
    price: "",
  });

  const handleToggleSidebar = (e) => {
    e.preventDefault();
    setToggleSideBar(!toggleSideBar);
  };

  const handleToggleOffCanvas = (e) => {
    e.preventDefault();
    setCanvas(!canvas);
  };

  const isValidNumber = (value) => {
    return !isNaN(parseFloat(value)) && isFinite(value);
  };

  function isValidPrice(price) {
    const pricePattern = /^\d+(\.\d{1,2})?$/;

    if (!pricePattern.test(price)) {
      return parseFloat(price).toFixed(2);
    }
    return price;
  }

  // const handleDishesProductsClick = (e) => {
  //   e.preventDefault();
  //   navigate("/view_products");
  // };

  const handleAddOnMenuGroupButtonClick = () => {
    setAddOnType("Menu");
    setSelectedProduct({});
    setSearchInput("");
    setFilteredData(null);
    setShowDropDown(false);
    setAddOnMenuFormData({
      groupName: "",
      min: "",
      max: "",
      items: [],
    });
    setAddOnGroupDataId("");
    setAddOnGroupData({});
    setShowAddOnForm(true);
  };

  const handleAddOnNewGroupButtonClick = () => {
    setAddOnType("New");
    setAddOnNewFormData({
      groupName: "",
      min: "",
      max: "",
      items: [],
    });
    setAddOnNewItemData({
      name: "",
      type: "",
      price: "",
    });
    setAddOnGroupDataId("");
    setAddOnGroupData({});
    setShowAddOnForm(true);
  };

  const handleChangeAddOnMenuFormData = (e) => {
    const { name, value } = e.target;
    setAddOnMenuFormData({
      ...addOnMenuFormData,
      [name]: value,
    });
    setError("");
    setSuccess("");
  };

  const handleChangeAddOnNewFormData = (e) => {
    const { name, value } = e.target;
    setAddOnNewFormData({
      ...addOnNewFormData,
      [name]: value,
    });
    setError("");
    setSuccess("");
  };

  const handleChangeAddOnNewItemData = (e) => {
    const { name, value } = e.target;
    setAddOnNewItemData({
      ...addOnNewItemData,
      [name]: value,
    });
    setError("");
    setSuccess("");
  };

  const handleAddOnMenuItemAddClick = () => {
    if (
      searchInput === "" ||
      !selectedProduct ||
      Object.keys(selectedProduct).length === 0
    ) {
      setError("Please select items first");
      setSuccess("");
    } else {
      addOnMenuFormData.items.push(selectedProduct);
      setSelectedProduct({});
      setSearchInput("");
      setFilteredData(null);
      setShowDropDown(false);
    }
  };

  const handleAddOnNewItemAddClick = () => {
    if (addOnNewItemData.name === "" || addOnNewItemData.name.trim() === "") {
      setError("Item name can not be empty");
      setSuccess("");
    } else if (
      addOnNewItemData.price === "" ||
      addOnNewItemData.price.trim() === ""
    ) {
      setError("Item price can not be empty");
      setSuccess("");
    } else if (addOnNewItemData.type === "") {
      setError("Item type can not be empty");
      setSuccess("");
    } else if (!isValidNumber(addOnNewItemData.price)) {
      setError("Please enter valid item price");
      setSuccess("");
    } else {
      addOnNewFormData.items.push(addOnNewItemData);
      setAddOnNewItemData({ name: "", type: "", price: "" });
    }
  };

  const handleAddOnMenuItemDeleteClick = (indexToDelete) => {
    setAddOnMenuFormData((prevFormData) => ({
      ...prevFormData,
      items: prevFormData.items.filter((_, index) => index !== indexToDelete),
    }));
  };

  const handleAddOnNewItemDeleteClick = (indexToDelete) => {
    setAddOnNewFormData((prevFormData) => ({
      ...prevFormData,
      items: prevFormData.items.filter((_, index) => index !== indexToDelete),
    }));
  };

  const handleAddOnMenuSaveChangesClick = async () => {
    if (
      addOnMenuFormData.groupName === "" ||
      addOnMenuFormData.groupName.trim() === ""
    ) {
      setError("Add-on group name can not be empty");
      setSuccess("");
    } else if (addOnMenuFormData.min === "") {
      setError("Please enter minimum add-ons customer can choose");
      setSuccess("");
    } else if (!isValidNumber(addOnMenuFormData.min)) {
      setError("Please enter valid minimum add-ons customer can choose");
      setSuccess("");
    } else if (addOnMenuFormData.max === "") {
      setError("Please enter maximum add-ons customer can choose");
      setSuccess("");
    } else if (!isValidNumber(addOnMenuFormData.max)) {
      setError("Please enter valid maximum add-ons customer can choose");
      setSuccess("");
    } else if (addOnMenuFormData.items.length === 0) {
      setError("Please add atleast one item to create add-on group");
      setSuccess("");
    } else if (
      parseFloat(addOnMenuFormData.min) > addOnMenuFormData.items.length
    ) {
      setError("Minimum value can not be greater than total items");
      setSuccess("");
    } else if (
      parseFloat(addOnMenuFormData.max) > addOnMenuFormData.items.length
    ) {
      setError("Maximum value can not be greater than total items");
      setSuccess("");
    } else {
      const dataToSend = {
        addon_group_name: addOnMenuFormData.groupName,
        addon_type: addOnType === "Menu" ? 0 : 1,
        minimum_item: addOnMenuFormData.min,
        maximum_item: addOnMenuFormData.max,
        items: addOnMenuFormData.items,
      };

      try {
        const response = await axios({
          method: "post",
          url: CreateAddonGroupURL,
          data: dataToSend,
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${token}`,
          },
        });
        if (response.status === 200 && response.data.message) {
          setAddOnType("");
          setShowAddOnForm(false);
          setError("");
          setSuccess("");
          addToast("success", response.data.message);
          setFlag(!flag);
        }
      } catch (error) {
        if (error.response && error.response.data.message) {
          addToast("error", error.response.data.message);
        } else {
          addToast("error", error.message);
        }
        console.log(error);
        setError("");
        setSuccess("");
      }
    }
  };

  const handleAddOnNewSaveChangesClick = async () => {
    if (
      addOnNewFormData.groupName === "" ||
      addOnNewFormData.groupName.trim() === ""
    ) {
      setError("Add-on group name can not be empty");
      setSuccess("");
    } else if (addOnNewFormData.min === "") {
      setError("Please enter minimum add-ons customer can choose");
      setSuccess("");
    } else if (!isValidNumber(addOnNewFormData.min)) {
      setError("Please enter valid minimum add-ons customer can choose");
      setSuccess("");
    } else if (addOnNewFormData.max === "") {
      setError("Please enter maximum add-ons customer can choose");
      setSuccess("");
    } else if (!isValidNumber(addOnNewFormData.max)) {
      setError("Please enter valid maximum add-ons customer can choose");
      setSuccess("");
    } else if (addOnNewFormData.items.length === 0) {
      setError("Please add atleast one item to create add-on group");
      setSuccess("");
    } else if (
      parseFloat(addOnNewFormData.min) > addOnNewFormData.items.length
    ) {
      setError("Minimum value can not be greater than total items");
      setSuccess("");
    } else if (
      parseFloat(addOnNewFormData.max) > addOnNewFormData.items.length
    ) {
      setError("Maximum value can not be greater than total items");
      setSuccess("");
    } else {
      const dataToSend = {
        addon_group_name: addOnNewFormData.groupName,
        addon_type: addOnType === "Menu" ? 0 : 1,
        minimum_item: addOnNewFormData.min,
        maximum_item: addOnNewFormData.max,
        items: addOnNewFormData.items,
      };

      try {
        const response = await axios({
          method: "post",
          url: CreateAddonGroupURL,
          data: dataToSend,
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${token}`,
          },
        });
        if (response.status === 200 && response.data.message) {
          setAddOnType("");
          setShowAddOnForm(false);
          setError("");
          setSuccess("");
          addToast("success", response.data.message);
          setFlag(!flag);
        }
      } catch (error) {
        if (error.response && error.response.data.message) {
          addToast("error", error.response.data.message);
        } else {
          addToast("error", error.message);
        }
        console.log(error);
        setError("");
        setSuccess("");
      }
    }
  };

  const handleAddOnGroupStatusToggle = async (e, itemId) => {
    setIsLoading(true);

    const dataU = { id: itemId, status: e.target.checked ? 1 : 0 };

    try {
      const response = await axios({
        method: "put",
        url: UpdateAddonGroupStatusURL,
        data: dataU,
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        setFlag(!flag);
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        addToast("error", error.response.data.message);
      } else {
        addToast("error", error.message);
      }
      console.log(error);
      setError("");
      setSuccess("");
    }
    setIsLoading(false);
  };

  const handleAddOnGroupNameClick = (item) => {
    setAddOnType("");
    setSelectedProduct({});
    setSearchInput("");
    setFilteredData(null);
    setShowDropDown(false);
    setAddOnMenuFormData({
      groupName: "",
      min: "",
      max: "",
      items: [],
    });
    setAddOnNewFormData({
      groupName: "",
      min: "",
      max: "",
      items: [],
    });
    setAddOnNewItemData({
      name: "",
      type: "",
      price: "",
    });
    setShowAddOnForm(false);
    setAddOnGroupDataId(item.fooders_addons_id);
    setAddOnGroupData(item);
  };

  const handleChangeAddOnGroupData = (e) => {
    const { name, value } = e.target;
    setAddOnGroupData({
      ...addOnGroupData,
      [name]: value,
    });
    setError("");
    setSuccess("");
  };

  const handleAddOnGroupDataUpdate = async () => {
    if (addOnGroupData.minimum_item === "") {
      setError("Please enter minimum add-ons customer can choose");
      setSuccess("");
    } else if (!isValidNumber(addOnGroupData.minimum_item)) {
      setError("Please enter valid minimum add-ons customer can choose");
      setSuccess("");
    } else if (addOnGroupData.maximum_item === "") {
      setError("Please enter maximum add-ons customer can choose");
      setSuccess("");
    } else if (!isValidNumber(addOnGroupData.maximum_item)) {
      setError("Please enter valid maximum add-ons customer can choose");
      setSuccess("");
    } else if (
      parseFloat(addOnGroupData.minimum_item) > addOnGroupData.items.length
    ) {
      setError("Minimum value can not be greater than total items");
      setSuccess("");
    } else if (
      parseFloat(addOnGroupData.maximum_item) > addOnGroupData.items.length
    ) {
      setError("Maximum value can not be greater than total items");
      setSuccess("");
    } else {
      const dataToSend = {
        id: addOnGroupData.fooders_addons_id,
        minimum_item: addOnGroupData.minimum_item,
        maximum_item: addOnGroupData.maximum_item,
      };

      try {
        const response = await axios({
          method: "put",
          url: UpdateAddonGroupDataURL,
          data: dataToSend,
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${token}`,
          },
        });
        if (response.status === 200 && response.data.message) {
          setError("");
          setSuccess("");
          addToast("success", response.data.message);
          setFlag(!flag);
        }
      } catch (error) {
        if (error.response && error.response.data.message) {
          addToast("error", error.response.data.message);
        } else {
          addToast("error", error.message);
        }
        setError("");
        setSuccess("");
        console.log(error);
      }
    }
  };

  const handleAddOnGroupMenuItemAddClick = async () => {
    if (
      searchInput === "" ||
      !selectedProduct ||
      Object.keys(selectedProduct).length === 0
    ) {
      setError("Please select items first");
      setSuccess("");
    } else {
      const dataToSend = {
        id: addOnGroupData.fooders_addons_id,
        addon_type: addOnGroupData.addon_type,
        item: selectedProduct,
      };

      try {
        const response = await axios({
          method: "post",
          url: AddonGroupDataItemAddURL,
          data: dataToSend,
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${token}`,
          },
        });
        if (response.status === 200 && response.data.message) {
          setError("");
          setSuccess("");
          setSelectedProduct({});
          setSearchInput("");
          setFilteredData(null);
          setShowDropDown(false);
          addToast("success", response.data.message);
          setFlag(!flag);
        }
      } catch (error) {
        if (error.response && error.response.data.message) {
          addToast("error", error.response.data.message);
        } else {
          addToast("error", error.message);
        }
        setError("");
        setSuccess("");
        console.log(error);
      }
    }
  };

  const handleAddOnGroupNewItemAddClick = async () => {
    if (addOnNewItemData.name === "" || addOnNewItemData.name.trim() === "") {
      setError("Item name can not be empty");
      setSuccess("");
    } else if (
      addOnNewItemData.price === "" ||
      addOnNewItemData.price.trim() === ""
    ) {
      setError("Item price can not be empty");
      setSuccess("");
    } else if (addOnNewItemData.type === "") {
      setError("Item type can not be empty");
      setSuccess("");
    } else if (!isValidNumber(addOnNewItemData.price)) {
      setError("Please enter valid item price");
      setSuccess("");
    } else {
      const dataToSend = {
        id: addOnGroupData.fooders_addons_id,
        addon_type: addOnGroupData.addon_type,
        item: addOnNewItemData,
      };

      try {
        const response = await axios({
          method: "post",
          url: AddonGroupDataItemAddURL,
          data: dataToSend,
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${token}`,
          },
        });
        if (response.status === 200 && response.data.message) {
          setError("");
          setSuccess("");
          setAddOnNewItemData({ name: "", type: "", price: "" });
          addToast("success", response.data.message);
          setFlag(!flag);
        }
      } catch (error) {
        if (error.response && error.response.data.message) {
          addToast("error", error.response.data.message);
        } else {
          addToast("error", error.message);
        }
        setError("");
        setSuccess("");
        console.log(error);
      }
    }
  };

  const handleDeleteAddOnItemClick = (e, id, items) => {
    e.preventDefault();
    if (items.length === 1) {
      setError("");
      setSuccess("");
      addToast("error", "Please add more items to delete this!");
    } else {
      setDeleteAddOnItemId([id]);
      setShowDeleteAddOnItemModal(!showDeleteAddOnItemModal);
    }
  };

  const handleDeleteAddOnItem = async () => {
    try {
      const response = await axios({
        method: "delete",
        url: DeleteAddonGroupItemURL,
        data: deleteAddOnItemId,
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 200 && response.data.message) {
        setError("");
        setSuccess("");
        addToast("success", response.data.message);
        setFlag(!flag);
        setShowDeleteAddOnItemModal(!showDeleteAddOnItemModal);
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        addToast("error", error.response.data.message);
      } else {
        addToast("error", error.message);
      }
      setError("");
      setSuccess("");
      console.log(error);
    }
  };

  const handleDeleteAddOnClick = (e, id) => {
    e.preventDefault();
    setDeleteAddOnId(id);
    setShowDeleteAddOnModal(!showDeleteAddOnModal);
  };

  const handleDeleteAddOn = async () => {
    try {
      const response = await axios({
        method: "delete",
        url: DeleteAddonGroupURL,
        data: { id: deleteAddOnId },
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 200 && response.data.message) {
        setError("");
        setSuccess("");
        addToast("success", response.data.message);
        setFlag(!flag);
        setShowDeleteAddOnModal(!showDeleteAddOnModal);
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        addToast("error", error.response.data.message);
      } else {
        addToast("error", error.message);
      }
      console.log(error);
      setError("");
      setSuccess("");
    }
  };

  const handleAddOnGroupClose = () => {
    setAddOnType("");
    setSelectedProduct({});
    setSearchInput("");
    setFilteredData(null);
    setShowDropDown(false);
    setAddOnMenuFormData({
      groupName: "",
      min: "",
      max: "",
      items: [],
    });
    setAddOnNewFormData({
      groupName: "",
      min: "",
      max: "",
      items: [],
    });
    setAddOnNewItemData({
      name: "",
      type: "",
      price: "",
    });
    setShowAddOnForm(false);
    setAddOnGroupDataId("");
    setAddOnGroupData({});
  };

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/");
    }
  }, [navigate]);

  useEffect(() => {
    setToken(localStorage.getItem("token"));
  }, [token]);

  useEffect(() => {
    if (localStorage.getItem("currencySymbol")) {
      setCurrencySymbol(localStorage.getItem("currencySymbol"));
    }
  }, []);

  useEffect(() => {
    const existingScript = document.getElementById(ScriptId);
    if (!existingScript && localStorage.getItem("userId")) {
      const userId = localStorage.getItem("userId");
      const script = document.createElement("script");
      script.src = ScriptSRC + userId.replace(/"/g, "");
      script.defer = true;
      script.id = ScriptId;
      document.head.appendChild(script);
    }
  }, []);

  useEffect(() => {
    if (token) {
      async function getData() {
        setIsLoading(true);
        try {
          const response = await axios.get(GetAddonGroupListURL, {
            headers: { authorization: `Bearer ${token}` },
          });

          if (response.status === 200 && response.data.data) {
            setData(response.data.data);
            setAllMenuProductData(
              response.data.data2 ? response.data.data2 : []
            );
          } else if (response.status === 200 && response.data.message) {
            setData([]);
            setAllMenuProductData([]);
          }
        } catch (error) {
          if (error.response && error.response.data.message) {
            addToast("error", error.response.data.message);
          } else {
            addToast("error", error.message);
          }
          console.log(error);
          setError("");
          setSuccess("");
        }
        setIsLoading(false);
      }
      getData();
    }
  }, [token, flag, addToast]);

  useEffect(() => {
    if (addOnGroupDataId && data) {
      let itemData = data.filter(
        (item) => item.fooders_addons_id === addOnGroupDataId
      );
      setAddOnGroupData(itemData[0]);
    }
  }, [data, addOnGroupDataId]);

  useEffect(() => {
    if (error || success) {
      window.scrollTo(0, 0);
    }
  }, [error, success]);

  useEffect(() => {
    const handleSearchChange = async () => {
      const searchTerm = searchInput.toLowerCase();

      if (searchTerm.length >= 3) {
        try {
          const response = await axios.get(SearchProductListForAddonsURL, {
            params: {
              name: searchTerm,
            },
            headers: { authorization: `Bearer ${token}` },
          });

          if (response.status === 200 && response.data.data) {
            setFilteredData(response.data.data);
          } else if (response.status === 200 && response.data.message) {
            setFilteredData([]);
          }
        } catch (error) {
          if (error.response && error.response.data.message) {
            addToast("error", error.response.data.message);
          } else {
            addToast("error", error.message);
          }
          console.log(error);
          setError("");
          setSuccess("");
        }
      } else {
        setFilteredData(null);
      }
    };
    handleSearchChange();
  }, [token, searchInput, addToast]);

  return (
    <>
      {token && (
        <>
          <div
            className={
              toggleSideBar
                ? "container-scroller sidebar-icon-only"
                : "container-scroller"
            }
          >
            <nav className="navbar default-layout col-lg-12 col-12 p-0 fixed-top d-flex align-items-top flex-row">
              <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-start">
                <Link className="navbar-brand brand-logo-mini" to="/live_orders">
                  <img src="images/mini-kr-logo.svg" alt="logo" />
                </Link>
                <div className="me-1">
                  <button
                    className={
                      toggleSideBar
                        ? "navbar-toggler navbar-toggler align-self-center"
                        : "navbar-toggler navbar-toggler align-self-center text-white"
                    }
                    type="button"
                    onClick={handleToggleSidebar}
                  >
                    <span className="icon-menu"></span>
                  </button>
                </div>
                <div>
                  <Link to="/live_orders">
                    <span className="navbar-brand brand-logo">
                      <img src="images/Khateraho_logo_white.png" alt="logo" />
                    </span>
                  </Link>
                </div>
              </div>
              <NavBar handleToggle={handleToggleOffCanvas} />
            </nav>
            <div className="container-fluid page-body-wrapper px-0">
              <SideBar active="add_ons" toggleCanvas={toggleSideBar} />

              <div className="main-panel">
                <div className="content-wrapper">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="d-sm-flex justify-content-between align-items-start mb-2">
                        <div className="vsalign">
                          <h3 className="ukhd mb-3">Add-ons</h3>
                        </div>
                        <div className="vsalign">
                          {/* <span
                            className="btn btn-danger mb-0 me-0"
                            onClick={handleDishesProductsClick}
                          >
                            <i className="menu-icon mdi mdi-arrow-left vsalign"></i>
                            Go Back
                          </span> */}
                        </div>
                      </div>
                      <div className="row flex-grow">
                        <div className="col-12 grid-margin stretch-card">
                          <div className="card card-rounded">
                            <div className="card-body">
                              <div className="row addon">
                                <div className="col-md-7">
                                  <div className="col-12 d-flex justify-content-between align-items-start mb-3">
                                    <div className="col-4 vsalign">
                                      <h4 className="card-title-dash">
                                        Add-ons Listing
                                      </h4>
                                    </div>
                                    <div className="col-8 text-right">
                                      <div className="dropdown">
                                        <button
                                          className="btn btn-outline-info btn-fw text-left dropdown-toggle"
                                          type="button"
                                          data-bs-toggle="dropdown"
                                          aria-expanded="false"
                                        >
                                          Create Add-ons Group
                                        </button>
                                        <ul className="dropdown-menu">
                                          <li
                                            style={{ cursor: "pointer" }}
                                            onClick={(e) => {
                                              e.preventDefault();
                                              handleAddOnMenuGroupButtonClick();
                                            }}
                                          >
                                            <span className="dropdown-item">
                                              <i className="fa fa-plus vsalign"></i>
                                              &nbsp;&nbsp;Select from the menu
                                              <br />
                                              <small>
                                                Eg. Beverages, Sides etc
                                              </small>
                                            </span>
                                          </li>
                                          <li
                                            style={{ cursor: "pointer" }}
                                            onClick={(e) => {
                                              e.preventDefault();
                                              handleAddOnNewGroupButtonClick();
                                            }}
                                          >
                                            <span className="dropdown-item">
                                              <i className="fa fa-plus"></i>
                                              &nbsp;&nbsp; Create New
                                              <br />
                                              <small>
                                                Eg. Dips , Toppings etc
                                              </small>
                                            </span>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                  <hr />
                                  {data &&
                                    data.map((item, index) => (
                                      <React.Fragment key={index}>
                                        <div className="col-12 d-flex align-items-center justify-content-between">
                                          <div className="col-6">
                                            <h5
                                              style={{ cursor: "pointer" }}
                                              onClick={(e) => {
                                                e.preventDefault();
                                                handleAddOnGroupNameClick(item);
                                              }}
                                            >
                                              {item.addon_group_name} (
                                              {item.items.length})
                                            </h5>
                                          </div>
                                          {/* <div className="col-3 togglecss">
                                            <div
                                              className={`button r ${item.status === 1
                                                  ? "active"
                                                  : ""
                                                }`}
                                              id="button-1"
                                            >
                                              <input
                                                type="checkbox"
                                                className="checkbox"
                                                checked={item.status === 1}
                                                onChange={(e) =>
                                                  handleAddOnGroupStatusToggle(
                                                    e,
                                                    item.fooders_addons_id
                                                  )
                                                }
                                              />
                                              <div className="knobs"></div>
                                              <div className="layer"></div>
                                            </div>
                                          </div> */}
                                          <div className="col-2 text-right">
                                            <i
                                              className="fs-20 mdi mdi-archive text-danger vsalign"
                                              title="Delete"
                                              style={{
                                                cursor: "pointer",
                                              }}
                                              onClick={(e) =>
                                                handleDeleteAddOnClick(
                                                  e,
                                                  item.fooders_addons_id
                                                )
                                              }
                                            ></i>
                                          </div>
                                        </div>
                                        {index !== data.length - 1 && <hr />}
                                      </React.Fragment>
                                    ))}
                                </div>
                                <div className="col-md-5">
                                  {error && (
                                    <div className="d-flex justify-content-between align-items-start alert alert-danger">
                                      <div className="vsalign">{error}</div>
                                      <div className="vsalign">
                                        <span
                                          className=".h3 text-muted"
                                          style={{ cursor: "pointer" }}
                                          onClick={() => setError("")}
                                        >
                                          x
                                        </span>
                                      </div>
                                    </div>
                                  )}
                                  {success && (
                                    <div className="d-flex justify-content-between align-items-start alert alert-success">
                                      <div className="vsalign">{success}</div>
                                      <div className="vsalign">
                                        <span
                                          className=".h3 text-muted"
                                          style={{ cursor: "pointer" }}
                                          onClick={() => setSuccess("")}
                                        >
                                          x
                                        </span>
                                      </div>
                                    </div>
                                  )}
                                  {showAddOnForm && (
                                    <>
                                      {addOnType === "Menu" && (
                                        <>
                                          <div className="card card-rounded">
                                            <div className="card-body bordercss">
                                              <div className="col-12 d-flex align-items-center justify-content-between mb-3">
                                                <div className="col-10">
                                                  <div className="input-group mb-2">
                                                    <input
                                                      type="text"
                                                      className="form-control"
                                                      placeholder="Enter Add-on group name"
                                                      name="groupName"
                                                      value={
                                                        addOnMenuFormData.groupName
                                                      }
                                                      onChange={
                                                        handleChangeAddOnMenuFormData
                                                      }
                                                    />
                                                  </div>
                                                </div>
                                                <div className="col-2 text-right">
                                                  <i
                                                    className="fs-20 mdi mdi-archive text-danger hrddlt"
                                                    title="Delete"
                                                    style={{
                                                      cursor: "pointer",
                                                    }}
                                                    onClick={() => {
                                                      setAddOnType("");
                                                      setShowAddOnForm(false);
                                                    }}
                                                  ></i>
                                                </div>
                                              </div>
                                              <div className="row">
                                                <div className="col-6">
                                                  <div className="input-group mb-2">
                                                    <input
                                                      type="text"
                                                      className="form-control"
                                                      placeholder="Min"
                                                      name="min"
                                                      value={
                                                        addOnMenuFormData.min
                                                      }
                                                      onChange={
                                                        handleChangeAddOnMenuFormData
                                                      }
                                                    />
                                                  </div>
                                                </div>
                                                <div className="col-6">
                                                  <div className="input-group mb-2">
                                                    <input
                                                      type="text"
                                                      className="form-control"
                                                      placeholder="Max"
                                                      name="max"
                                                      value={
                                                        addOnMenuFormData.max
                                                      }
                                                      onChange={
                                                        handleChangeAddOnMenuFormData
                                                      }
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="bordercss">
                                                <h6>
                                                  Create items that you want to
                                                  add
                                                </h6>
                                                {addOnMenuFormData &&
                                                  addOnMenuFormData.items && (
                                                    <div>
                                                      {addOnMenuFormData.items.map(
                                                        (item, index) => (
                                                          <div
                                                            key={index}
                                                            className="row mb-2"
                                                          >
                                                            <div className="col-6">
                                                              {
                                                                item.product_name
                                                              }
                                                            </div>
                                                            <div className="col-3">
                                                              {currencySymbol}
                                                              {item.product_proprice
                                                                ? isValidPrice(
                                                                  item.product_proprice
                                                                )
                                                                : isValidPrice(
                                                                  item.product_price
                                                                )}
                                                            </div>
                                                            <div className="col-3">
                                                              <i
                                                                className="fs-20 mdi mdi-archive text-danger hrddlt"
                                                                title="Delete"
                                                                style={{
                                                                  cursor:
                                                                    "pointer",
                                                                }}
                                                                onClick={(
                                                                  e
                                                                ) => {
                                                                  e.preventDefault();
                                                                  handleAddOnMenuItemDeleteClick(
                                                                    index
                                                                  );
                                                                }}
                                                              ></i>
                                                            </div>
                                                          </div>
                                                        )
                                                      )}
                                                    </div>
                                                  )}

                                                <div className="row">
                                                  <div className="col-9">
                                                    <div className="form-group">
                                                      <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Type to search"
                                                        value={searchInput}
                                                        onChange={(e) => {
                                                          setSearchInput(
                                                            e.target.value
                                                          );
                                                          setSelectedProduct(
                                                            {}
                                                          );
                                                          setShowDropDown(true);
                                                        }}
                                                      />
                                                      {searchInput.length >=
                                                        3 &&
                                                        showDropDown && (
                                                          <>
                                                            <div className="dropdown-menu dropdown-menu-right navbar-dropdown preview-list mt-2 p-0 show">
                                                              {filteredData ? (
                                                                <>
                                                                  {filteredData.length ===
                                                                    0 ? (
                                                                    <>
                                                                      <span className="dropdown-item py-3 border-bottom">
                                                                        <p className="mb-0 font-weight-medium float-left">
                                                                          No
                                                                          Data
                                                                          Found
                                                                        </p>
                                                                      </span>
                                                                    </>
                                                                  ) : (
                                                                    filteredData.map(
                                                                      (
                                                                        item,
                                                                        index
                                                                      ) => (
                                                                        <span
                                                                          key={
                                                                            index
                                                                          }
                                                                          style={{
                                                                            cursor:
                                                                              "pointer",
                                                                          }}
                                                                          className="dropdown-item py-3 border-bottom"
                                                                          onClick={(
                                                                            e
                                                                          ) => {
                                                                            e.preventDefault();
                                                                            setSelectedProduct(
                                                                              item
                                                                            );
                                                                            setSearchInput(
                                                                              item.product_name
                                                                            );
                                                                            setShowDropDown(
                                                                              false
                                                                            );
                                                                          }}
                                                                        >
                                                                          <p className="mb-0 font-weight-medium float-left">
                                                                            {
                                                                              item.product_name
                                                                            }
                                                                          </p>
                                                                        </span>
                                                                      )
                                                                    )
                                                                  )}
                                                                </>
                                                              ) : (
                                                                <>
                                                                  <span className="dropdown-item py-3 border-bottom">
                                                                    <p className="mb-0 font-weight-medium float-left">
                                                                      Loading...
                                                                    </p>
                                                                  </span>
                                                                </>
                                                              )}
                                                            </div>
                                                          </>
                                                        )}
                                                    </div>
                                                  </div>
                                                  <div className="col-3">
                                                    <button
                                                      className="btn btn-info btn-sm"
                                                      onClick={(e) => {
                                                        e.preventDefault();
                                                        handleAddOnMenuItemAddClick();
                                                      }}
                                                    >
                                                      Add
                                                    </button>
                                                  </div>
                                                </div>
                                              </div>
                                              <div>
                                                <button
                                                  type="button"
                                                  className="btn btn-outline-info btn-fw mt-2 col-12"
                                                  onClick={(e) => {
                                                    e.preventDefault();
                                                    handleAddOnMenuSaveChangesClick();
                                                  }}
                                                >
                                                  <i className="fs-20 mdi mdi-plus vsalign"></i>
                                                  &nbsp;Save Changes
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                        </>
                                      )}

                                      {addOnType === "New" && (
                                        <>
                                          <div className="card card-rounded">
                                            <div className="card-body bordercss">
                                              <div className="col-12 d-flex align-items-center justify-content-between mb-3">
                                                <div className="col-10">
                                                  <div className="input-group mb-2">
                                                    <input
                                                      type="text"
                                                      className="form-control"
                                                      placeholder="Enter Add-on group name"
                                                      name="groupName"
                                                      value={
                                                        addOnNewFormData.groupName
                                                      }
                                                      onChange={
                                                        handleChangeAddOnNewFormData
                                                      }
                                                    />
                                                  </div>
                                                </div>
                                                <div className="col-2 text-right">
                                                  <i
                                                    className="fs-20 mdi mdi-archive text-danger hrddlt"
                                                    title="Delete"
                                                    style={{
                                                      cursor: "pointer",
                                                    }}
                                                    onClick={() => {
                                                      setAddOnType("");
                                                      setShowAddOnForm(false);
                                                    }}
                                                  ></i>
                                                </div>
                                              </div>
                                              <div className="row">
                                                <div className="col-6">
                                                  <div className="input-group mb-2">
                                                    <input
                                                      type="text"
                                                      className="form-control"
                                                      placeholder="Min"
                                                      name="min"
                                                      value={
                                                        addOnNewFormData.min
                                                      }
                                                      onChange={
                                                        handleChangeAddOnNewFormData
                                                      }
                                                    />
                                                  </div>
                                                </div>
                                                <div className="col-6">
                                                  <div className="input-group mb-2">
                                                    <input
                                                      type="text"
                                                      className="form-control"
                                                      placeholder="Max"
                                                      name="max"
                                                      value={
                                                        addOnNewFormData.max
                                                      }
                                                      onChange={
                                                        handleChangeAddOnNewFormData
                                                      }
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="bordercss">
                                                <h6>
                                                  Create items that you want to
                                                  add
                                                </h6>
                                                {addOnNewFormData &&
                                                  addOnNewFormData.items && (
                                                    <div>
                                                      {addOnNewFormData.items.map(
                                                        (item, index) => (
                                                          <div
                                                            key={index}
                                                            className="row mb-2"
                                                          >
                                                            <div className="col-6">
                                                              {item.name}
                                                            </div>
                                                            <div className="col-3">
                                                              {currencySymbol}
                                                              {isValidPrice(
                                                                item.price
                                                              )}
                                                            </div>
                                                            <div className="col-3">
                                                              <i
                                                                className="fs-20 mdi mdi-archive text-danger hrddlt"
                                                                title="Delete"
                                                                style={{
                                                                  cursor:
                                                                    "pointer",
                                                                }}
                                                                onClick={(
                                                                  e
                                                                ) => {
                                                                  e.preventDefault();
                                                                  handleAddOnNewItemDeleteClick(
                                                                    index
                                                                  );
                                                                }}
                                                              ></i>
                                                            </div>
                                                          </div>
                                                        )
                                                      )}
                                                    </div>
                                                  )}

                                                <div className="row">
                                                  <div className="col-3">
                                                    <div className="form-group">
                                                      <select
                                                        className="form-select"
                                                        name="type"
                                                        value={
                                                          addOnNewItemData.type
                                                        }
                                                        onChange={
                                                          handleChangeAddOnNewItemData
                                                        }
                                                      >
                                                        <option value="">
                                                          Type
                                                        </option>
                                                        <option value="0">
                                                          Veg
                                                        </option>
                                                        <option value="1">
                                                          Non-Veg
                                                        </option>
                                                        <option value="2">
                                                          Egg
                                                        </option>
                                                        <option value="3">
                                                          Alcohol
                                                        </option>
                                                      </select>
                                                    </div>
                                                  </div>
                                                  <div className="col-4">
                                                    <div className="form-group">
                                                      <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Name"
                                                        name="name"
                                                        value={
                                                          addOnNewItemData.name
                                                        }
                                                        onChange={
                                                          handleChangeAddOnNewItemData
                                                        }
                                                      />
                                                    </div>
                                                  </div>
                                                  <div className="col-3">
                                                    <div className="form-group">
                                                      <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Price"
                                                        name="price"
                                                        value={
                                                          addOnNewItemData.price
                                                        }
                                                        onChange={
                                                          handleChangeAddOnNewItemData
                                                        }
                                                      />
                                                    </div>
                                                  </div>
                                                  <div className="col-2 p-0">
                                                    <button
                                                      className="btn btn-info btn-sm"
                                                      onClick={(e) => {
                                                        e.preventDefault();
                                                        handleAddOnNewItemAddClick();
                                                      }}
                                                    >
                                                      Add
                                                    </button>
                                                  </div>
                                                </div>
                                              </div>
                                              <div>
                                                <button
                                                  type="button"
                                                  className="btn btn-outline-info btn-fw mt-2 col-12"
                                                  onClick={(e) => {
                                                    e.preventDefault();
                                                    handleAddOnNewSaveChangesClick();
                                                  }}
                                                >
                                                  <i className="fs-20 mdi mdi-plus vsalign"></i>
                                                  &nbsp;Save Changes
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                        </>
                                      )}
                                    </>
                                  )}

                                  {!showAddOnForm &&
                                    Object.keys(addOnGroupData).length > 0 && (
                                      <>
                                        <div className="col-12 d-flex align-items-center justify-content-between">
                                          <div className="col-8">
                                            <h4 className="card-title-dash">
                                              {" "}
                                              {addOnGroupData.addon_group_name}
                                            </h4>
                                          </div>
                                          <div className="col-4 text-right">
                                            <i
                                              className="fs-20 mdi mdi-close"
                                              style={{ cursor: "pointer" }}
                                              onClick={(e) => {
                                                e.preventDefault();
                                                handleAddOnGroupClose();
                                              }}
                                            ></i>
                                          </div>
                                        </div>

                                        <hr />
                                        <div className="mngrp">
                                          <h6>Manage Add-on Group </h6>
                                          <div className="bordercss">
                                            <div className="col-12 d-flex align-items-center justify-content-between mb-2">
                                              <div className="col-10">
                                                <p className="vsalign">
                                                  {" "}
                                                  <strong>Minimum</strong>{" "}
                                                  add-ons customer can choose
                                                </p>
                                              </div>
                                              <div className="col-2 text-right">
                                                <input
                                                  type="text"
                                                  className="form-control"
                                                  placeholder="Min"
                                                  name="minimum_item"
                                                  value={
                                                    addOnGroupData.minimum_item
                                                  }
                                                  onChange={
                                                    handleChangeAddOnGroupData
                                                  }
                                                />
                                              </div>
                                            </div>
                                            <div className="col-12 d-flex align-items-center justify-content-between">
                                              <div className="col-10">
                                                <p className="vsalign">
                                                  {" "}
                                                  <strong>Maximum</strong>{" "}
                                                  add-ons customer can choose
                                                </p>
                                              </div>
                                              <div className="col-2 text-right">
                                                <input
                                                  type="text"
                                                  className="form-control"
                                                  placeholder="Max"
                                                  name="maximum_item"
                                                  value={
                                                    addOnGroupData.maximum_item
                                                  }
                                                  onChange={
                                                    handleChangeAddOnGroupData
                                                  }
                                                />
                                              </div>
                                            </div>
                                            <button
                                              type="button"
                                              className="btn btn-primary btn-sm"
                                              onClick={(e) => {
                                                e.preventDefault();
                                                handleAddOnGroupDataUpdate();
                                              }}
                                            >
                                              &nbsp;Save
                                            </button>
                                          </div>
                                        </div>

                                        <div className="crtitem mt-4">
                                          <h6>
                                            Create items that you want to add to
                                            your{" "}
                                            {addOnGroupData.addon_group_name}
                                          </h6>
                                          <div className="bordercss">
                                            {addOnGroupData &&
                                              addOnGroupData.items &&
                                              addOnGroupData.items.map(
                                                (item, index) => (
                                                  <React.Fragment key={index}>
                                                    <div className="col-12 d-flex align-items-center justify-content-between mb-2">
                                                      <div className="col-8">
                                                        <p className="vsalign">
                                                          {item.addon_item_type ===
                                                            0 && (
                                                              <img
                                                                src="./images/veg.jpeg"
                                                                className="nveg"
                                                                alt="veg"
                                                              />
                                                            )}
                                                          {item.addon_item_type ===
                                                            1 && (
                                                              <img
                                                                src="./images/non-veg.jpeg"
                                                                className="nveg"
                                                                alt="nonveg"
                                                              />
                                                            )}
                                                          {item.addon_item_type ===
                                                            2 && (
                                                              <img
                                                                src="./images/egg.png"
                                                                className="nveg"
                                                                alt="egg"
                                                              />
                                                            )}
                                                            {item.addon_item_type ===
                                                            3 && (
                                                              <img
                                                                src="./images/alcohol.png"
                                                                className="nveg"
                                                                alt="alcohol"
                                                              />
                                                            )}{" "}
                                                          {item.addon_item_name}
                                                        </p>
                                                      </div>
                                                      <div className="col-2 text-center">
                                                        <p className="vsalign">
                                                          {" "}
                                                          {currencySymbol}
                                                          {
                                                            item.addon_item_price
                                                          }
                                                        </p>
                                                      </div>
                                                      <div className="col-2 text-right">
                                                        <i
                                                          className="fs-20 mdi mdi-archive text-danger vsalign"
                                                          title="Delete"
                                                          style={{
                                                            cursor: "pointer",
                                                          }}
                                                          onClick={(e) =>
                                                            handleDeleteAddOnItemClick(
                                                              e,
                                                              item.fooders_addons_items_id,
                                                              addOnGroupData.items
                                                            )
                                                          }
                                                        ></i>
                                                      </div>
                                                    </div>
                                                    <hr />
                                                  </React.Fragment>
                                                )
                                              )}

                                            {addOnGroupData &&
                                              addOnGroupData.addon_type ===
                                              0 && (
                                                <>
                                                  <div className="row">
                                                    <div className="col-9">
                                                      <div className="form-group">
                                                        <input
                                                          type="text"
                                                          className="form-control"
                                                          placeholder="Type to search"
                                                          value={searchInput}
                                                          onChange={(e) => {
                                                            setSearchInput(
                                                              e.target.value
                                                            );
                                                            setSelectedProduct(
                                                              {}
                                                            );
                                                            setShowDropDown(
                                                              true
                                                            );
                                                          }}
                                                        />
                                                        {searchInput.length >=
                                                          3 &&
                                                          showDropDown && (
                                                            <>
                                                              <div className="dropdown-menu dropdown-menu-right navbar-dropdown preview-list mt-2 p-0 show">
                                                                {filteredData ? (
                                                                  <>
                                                                    {filteredData.length ===
                                                                      0 ? (
                                                                      <>
                                                                        <span className="dropdown-item py-3 border-bottom">
                                                                          <p className="mb-0 font-weight-medium float-left">
                                                                            No
                                                                            Data
                                                                            Found
                                                                          </p>
                                                                        </span>
                                                                      </>
                                                                    ) : (
                                                                      filteredData.map(
                                                                        (
                                                                          item,
                                                                          index
                                                                        ) => (
                                                                          <span
                                                                            key={
                                                                              index
                                                                            }
                                                                            style={{
                                                                              cursor:
                                                                                "pointer",
                                                                            }}
                                                                            className="dropdown-item py-3 border-bottom"
                                                                            onClick={(
                                                                              e
                                                                            ) => {
                                                                              e.preventDefault();
                                                                              setSelectedProduct(
                                                                                item
                                                                              );
                                                                              setSearchInput(
                                                                                item.product_name
                                                                              );
                                                                              setShowDropDown(
                                                                                false
                                                                              );
                                                                            }}
                                                                          >
                                                                            <p className="mb-0 font-weight-medium float-left">
                                                                              {
                                                                                item.product_name
                                                                              }
                                                                            </p>
                                                                          </span>
                                                                        )
                                                                      )
                                                                    )}
                                                                  </>
                                                                ) : (
                                                                  <>
                                                                    <span className="dropdown-item py-3 border-bottom">
                                                                      <p className="mb-0 font-weight-medium float-left">
                                                                        Loading...
                                                                      </p>
                                                                    </span>
                                                                  </>
                                                                )}
                                                              </div>
                                                            </>
                                                          )}
                                                      </div>
                                                    </div>
                                                    <div className="col-3">
                                                      <button
                                                        className="btn btn-info btn-sm"
                                                        onClick={(e) => {
                                                          e.preventDefault();
                                                          handleAddOnGroupMenuItemAddClick();
                                                        }}
                                                      >
                                                        Add
                                                      </button>
                                                    </div>
                                                  </div>
                                                </>
                                              )}

                                            {addOnGroupData &&
                                              addOnGroupData.addon_type ===
                                              1 && (
                                                <>
                                                  <div className="row">
                                                    <div className="col-3">
                                                      <div className="form-group">
                                                        <select
                                                          className="form-select"
                                                          name="type"
                                                          value={
                                                            addOnNewItemData.type
                                                          }
                                                          onChange={
                                                            handleChangeAddOnNewItemData
                                                          }
                                                        >
                                                          <option value="">
                                                            Type
                                                          </option>
                                                          <option value="0">
                                                            Veg
                                                          </option>
                                                          <option value="1">
                                                            Non-Veg
                                                          </option>
                                                          <option value="2">
                                                            Egg
                                                          </option>
                                                          <option value="3">
                                                            Alcohol
                                                          </option>
                                                        </select>
                                                      </div>
                                                    </div>
                                                    <div className="col-4">
                                                      <div className="form-group">
                                                        <input
                                                          type="text"
                                                          className="form-control"
                                                          placeholder="Name"
                                                          name="name"
                                                          value={
                                                            addOnNewItemData.name
                                                          }
                                                          onChange={
                                                            handleChangeAddOnNewItemData
                                                          }
                                                        />
                                                      </div>
                                                    </div>
                                                    <div className="col-3">
                                                      <div className="form-group">
                                                        <input
                                                          type="text"
                                                          className="form-control"
                                                          placeholder="Price"
                                                          name="price"
                                                          value={
                                                            addOnNewItemData.price
                                                          }
                                                          onChange={
                                                            handleChangeAddOnNewItemData
                                                          }
                                                        />
                                                      </div>
                                                    </div>
                                                    <div className="col-2 p-0">
                                                      <button
                                                        className="btn btn-info btn-sm"
                                                        onClick={(e) => {
                                                          e.preventDefault();
                                                          handleAddOnGroupNewItemAddClick();
                                                        }}
                                                      >
                                                        Add
                                                      </button>
                                                    </div>
                                                  </div>
                                                </>
                                              )}
                                          </div>
                                        </div>

                                        {/* <div className="mapdish mt-4">
                                          <h6>
                                            Maps{" "}
                                            {addOnGroupData.addon_group_name} to
                                            dishes{" "}
                                          </h6>
                                          <div className="bordercss">
                                            <div className="col-12 d-flex align-items-center justify-content-between mb-2">
                                              <div className="col-6">
                                                <h6 className="vsalign">
                                                  Mapped items
                                                </h6>
                                              </div>
                                              <div className="col-5 text-right">
                                                <button
                                                  type="button"
                                                  className="btn btn-primary btn-sm"
                                                  data-bs-toggle="offcanvas"
                                                  data-bs-target="#offcanvasRight"
                                                  aria-controls="offcanvasRight"
                                                >
                                                  &nbsp;Manage Items
                                                </button>
                                              </div>
                                            </div>
                                            <div className="col-12 d-flex align-items-center justify-content-between mb-2">
                                              <p className="vsalign">
                                                <img
                                                  src="./images/nonveg.png"
                                                  className="nveg"
                                                  alt=""
                                                />{" "}
                                                Cheese pizza
                                              </p>
                                            </div>
                                            <div className="col-12 d-flex align-items-center justify-content-between mb-2">
                                              <p className="vsalign">
                                                <img
                                                  src="./images/nonveg.png"
                                                  className="nveg"
                                                  alt=""
                                                />{" "}
                                                Cheese pizza
                                              </p>
                                            </div>
                                          </div>
                                        </div> */}
                                      </>
                                    )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="offcanvas offcanvas-end offaddon"
            tabindex="-1"
            id="offcanvasRight"
            aria-labelledby="offcanvasRightLabel"
          >
            <div className="offcanvas-header">
              <h5 id="offcanvasRightLabel">
                Maps Dishes with {addOnGroupData.addon_group_name}
              </h5>
              <button
                type="button"
                className="btn-close text-reset close"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              ></button>
            </div>
            <div className="offcanvas-body">
              {/* <div className="col-12 d-flex align-items-center justify-content-between mb-2">
                <div className="col-7">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search by category, sub-categories"
                    value=""
                  />
                </div>
                <div className="col-4 text-right">
                  <select className="form-select">
                    <option selected>Filter</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </select>
                </div>
              </div> */}

              <div className="accordion" id="accordionExample">
                {allMenuProductData &&
                  allMenuProductData.map((menu, index) => (
                    <div className="accordion-item" key={index}>
                      <h2 className="accordion-header" id={`heading${index}`}>
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target={`#collapse${index}`}
                          aria-expanded="true"
                          aria-controls={`collapse${index}`}
                        >
                          {menu.productMenu}
                          <div className="text-right count vsalign">
                            {menu.products.length} sub-categories&nbsp;|&nbsp;
                            {menu.products.reduce(
                              (total, product) =>
                                total + product.combination.length,
                              0
                            )}{" "}
                            Items
                          </div>
                        </button>
                      </h2>
                      <div
                        id={`collapse${index}`}
                        className="accordion-collapse collapse"
                        aria-labelledby={`heading${index}`}
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <div className="accordion subaccord">
                            {menu.products.map((product, subIndex) => (
                              <div
                                className="accordion-item"
                                key={`${index}-${subIndex}`}
                              >
                                <h2
                                  className="accordion-header"
                                  id={`heading${index}-${subIndex}`}
                                >
                                  <button
                                    className="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target={`#collapse${index}-${subIndex}`}
                                    aria-expanded="true"
                                    aria-controls={`collapse${index}-${subIndex}`}
                                  >
                                    {product.productName} &nbsp;(
                                    {product.combination.length} Items)
                                  </button>
                                </h2>
                                <div
                                  id={`collapse${index}-${subIndex}`}
                                  className="accordion-collapse collapse"
                                  aria-labelledby={`heading${index}-${subIndex}`}
                                  data-bs-parent={`#accordion${index}`}
                                >
                                  <div className="accordion-body">
                                    <div className="mb-2">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        value=""
                                      />
                                      {product.combination.map(
                                        (combo, comboIndex) => (
                                          <React.Fragment
                                            key={`${index}-${subIndex}-${comboIndex}`}
                                          >
                                            <div className="col-12 d-flex align-items-center justify-content-between mb-2">
                                              <div className="col-9">
                                                <p className="vsalign">
                                                  {product.productType ===
                                                    0 && (
                                                      <img
                                                        src="./images/veg.jpeg"
                                                        className="nveg"
                                                        alt="veg"
                                                      />
                                                    )}
                                                  {product.productType ===
                                                    1 && (
                                                      <img
                                                        src="./images/non-veg.jpeg"
                                                        className="nveg"
                                                        alt="nonveg"
                                                      />
                                                    )}
                                                  {product.productType ===
                                                    2 && (
                                                      <img
                                                        src="./images/egg.png"
                                                        className="nveg"
                                                        alt="egg"
                                                      />
                                                    )}{" "}
                                                  {combo.productCombinationDetails.map(
                                                    (detail, detailIndex) => (
                                                      <span
                                                        key={`${index}-${subIndex}-${comboIndex}-${detailIndex}`}
                                                      >
                                                        {detail.attribute_name}(
                                                        {
                                                          detail.attribute_value_name
                                                        }
                                                        )
                                                        {detailIndex !==
                                                          combo
                                                            .productCombinationDetails
                                                            .length -
                                                          1 && " - "}
                                                      </span>
                                                    )
                                                  )}
                                                </p>
                                              </div>
                                              <div className="col-2 text-center">
                                                <p className="vsalign">
                                                  {currencySymbol}
                                                  {
                                                    combo.productCombinationPrice
                                                  }
                                                </p>
                                              </div>
                                              <div className="col-1 text-right">
                                                <input
                                                  className="form-check-input"
                                                  type="checkbox"
                                                  value=""
                                                />
                                              </div>
                                            </div>
                                            {comboIndex !==
                                              product.combination.length -
                                              1 && <hr />}
                                          </React.Fragment>
                                        )
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>

            <div className="offcanvas-footer text-center mb-4">
              <button type="button" className="btn btn-primary btn-md col-10">
                {" "}
                Confirm
              </button>
            </div>
          </div>

          {showDeleteAddOnItemModal && (
            <DeleteModal
              handleModal={setShowDeleteAddOnItemModal}
              handleDelete={handleDeleteAddOnItem}
            />
          )}

          {showDeleteAddOnModal && (
            <DeleteModal
              handleModal={setShowDeleteAddOnModal}
              handleDelete={handleDeleteAddOn}
            />
          )}

          <LoadingModal isLoading={isLoading} />

          <Notification />
        </>
      )}
    </>
  );
};

export default AddOns;
