import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { LoginURL, ScriptId, ScriptSRC } from "../../assets/api/BaseURL";
import { useToast } from "../../../context/ToastContext";
// import LiveChat from "../other/LiveChat";

const Login = () => {
  const navigate = useNavigate();
  const { setPlan } = useToast();
  const [view, setView] = useState(null);
  const [user, setUser] = useState({ username: "", password: "" });
  const [error, setError] = useState("");

  const handleInput = (event) => {
    setUser((prev) => ({ ...prev, [event.target.name]: event.target.value }));
    setError("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (user.username === "") {
      setError("Username can not be empty");
    } else if (user.password === "") {
      setError("Password can not be empty");
    } else {
      const data = { username: user.username, password: user.password };

      try {
        const response = await axios.post(LoginURL, data);

        if (
          response.status === 200 &&
          response.data.token &&
          response.data.currency_symbol &&
          response.data.plan &&
          response.data.encyptedId
        ) {
          localStorage.setItem("token", response.data.token);
          localStorage.setItem("currencySymbol", response.data.currency_symbol);
          localStorage.setItem(
            "planDetails",
            JSON.stringify(response.data.plan)
          );
          localStorage.setItem(
            "userId",
            JSON.stringify(response.data.encyptedId)
          );
          const script = document.createElement("script");
          script.src = ScriptSRC + response.data.encyptedId;
          script.defer = true;
          script.id = ScriptId;

          document.head.appendChild(script);
          setPlan(response.data.plan);
          navigate("/live_orders");
        } else {
          setError("Internal server error");
          setUser({ username: "", password: "" });
        }
      } catch (error) {
        if (error.response && error.response.data.message) {
          setError(error.response.data.message);
          setUser({ username: "", password: "" });
        } else {
          setError(error.message);
          setUser({ username: "", password: "" });
        }
        console.log(error);
      }
    }
  };

  useEffect(() => {
    if (
      localStorage.getItem("token") &&
      localStorage.getItem("planDetails") &&
      localStorage.getItem("userId") &&
      localStorage.getItem("currencySymbol")
    ) {
      navigate("/live_orders");
      setView(false);
    } else {
      localStorage.removeItem("token");
      localStorage.removeItem("planDetails");
      localStorage.removeItem("userId");
      localStorage.removeItem("currencySymbol");
      setView(true);
    }
  }, [navigate]);

  return (
    <>
      {view && (
        <>
          <div className="container-scroller">
            <div className="container-fluid page-body-wrapper full-page-wrapper px-0">
              <div className="content-wrapper d-flex align-items-center auth px-0">
                <div className="row w-100 mx-0">
                  <div className="text-center mb-2">
                    <img
                      src="images/Khateraho_logo.png"
                      alt="logo"
                      className="loginlogo"
                    /> 
                  </div>
                  <div className="col-lg-4 mx-auto">
                    <div className="auth-form-light text-left py-5 px-4 px-sm-5">
                      <h4>Hello! let's get started</h4>
                      <h6 className="fw-light">Sign in to continue.</h6>
                      <form className="pt-3">
                        {error && (
                          <div className="d-flex justify-content-between align-items-start alert alert-danger">
                            <div className="vsalign">{error}</div>
                            <div className="vsalign">
                              <span
                                className=".h3 text-muted"
                                style={{ cursor: "pointer" }}
                                onClick={() => setError("")}
                              >
                                x
                              </span>
                            </div>
                          </div>
                        )}

                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control form-control-lg"
                            placeholder="Username"
                            name="username"
                            value={user.username}
                            onChange={handleInput}
                          />
                        </div>
                        <div className="form-group">
                          <input
                            type="password"
                            className="form-control form-control-lg"
                            placeholder="Password"
                            name="password"
                            value={user.password}
                            onChange={handleInput}
                          />
                        </div>
                        <div className="mt-3">
                          <button
                            className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn col-12"
                            onClick={handleSubmit}
                          >
                            SIGN IN
                          </button>
                        </div>
                        <div className="my-2 d-flex justify-content-between align-items-center">
                          <div className="form-check form-check-inline">
                            {/* <input
                          className="form-check-input"
                          type="checkbox"
                          aria-checked="false"
                        />
                        <label className="form-check-label text-muted">
                          Keep me signed in
                        </label> */}
                          </div>
                          <Link to="/forgot_password">
                            <span className="auth-link text-black">
                              Forgot password?
                            </span>
                          </Link>
                        </div>
                        <div className="mb-2 text-center">
                          <button
                            type="button"
                            className="btn btn-block btn-facebook auth-form-btn"
                            onClick={() => {
                              navigate("/login_with_otp");
                            }}
                          >
                            SIGN IN WITH OTP
                          </button>
                        </div>
                        <div className="text-center mt-2 fw-light">
                          Don't have an account?{" "}
                          <Link to="/signup">
                            <span className="text-primary">Create</span>
                            
                            
                          </Link>
                          <br/><br/>
                          < p style={{fontSize:"12px"}}>
                            Made with ❤️ by <a href="https://vyqda.com/"  target="_blank">Vyqda</a>  Technologies
                         </p>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <LiveChat /> */}
        </>
      )}
    </>
  );
};

export default Login;
