import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import SideBar from "../../other/SideBar";
import NavBar from "../../other/NavBar";
import LoadingModal from "../../../loader/LoadingModal";
import {
  CreatePurchaseReturnURL,
  GetPurchaseReturnNumberURL,
  ScriptId,
  ScriptSRC,
  SearchPurchaseOrderURL,
} from "../../../assets/api/BaseURL";
import Notification from "../../other/Notification";
import { useToast } from "../../../../context/ToastContext";

const CreatePurchaseReturn = () => {
  const navigate = useNavigate();
  const { addToast } = useToast();
  const [token, setToken] = useState("");
  const [toggleSideBar, setToggleSideBar] = useState(false);
  const [canvas, setCanvas] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [flag, setFlag] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [currencySymbol, setCurrencySymbol] = useState("");
  const [formData, setFormData] = useState({
    invoiceNumber: "",
    invoiceDate: getToday(),
    dueDate: getToday(),
    referenceNumber: "",
    note: "",
  });

  const [searchInput, setSearchInput] = useState("");
  const [filteredData, setFilteredData] = useState(null);
  const [showDropDown, setShowDropDown] = useState(false);
  const [selectedPO, setSelectedPO] = useState({});

  const handleToggleSidebar = (e) => {
    e.preventDefault();
    setToggleSideBar(!toggleSideBar);
  };

  const handleToggleOffCanvas = (e) => {
    e.preventDefault();
    setCanvas(!canvas);
  };

  const handleBackClick = (e) => {
    e.preventDefault();
    navigate("/view_purchase_return");
  };

  function getToday() {
    const today = new Date();
    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1;
    let dd = today.getDate();

    if (mm < 10) mm = "0" + mm;
    if (dd < 10) dd = "0" + dd;

    return `${yyyy}-${mm}-${dd}`;
  }

  function convertDateFormat(inputDate) {
    if (!inputDate || inputDate.trim() === "") {
      return "";
    }

    const parts = inputDate.split("-");
    const outputDate = parts[2] + "-" + parts[1] + "-" + parts[0];

    return outputDate;
  }

  const isValidNumber = (value) => {
    return !isNaN(parseFloat(value)) && isFinite(value);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setError("");
    setSuccess("");
  };

  const handleProductInputChange = (itemId, field, value) => {
    setSelectedPO((prevState) => {
      const updatedItems = prevState.items.map((item) => {
        if (item.id === itemId) {
          if (field === "selected") {
            return { ...item, selected: !item.selected };
          } else if (field === "returnQty") {
            return { ...item, returnQty: value };
          }
        }
        return item;
      });
      return { ...prevState, items: updatedItems };
    });
    setError("");
    setSuccess("");
  };

  const handleCheckReturnQtyAndAmountChange = (itemId) => {
    setSelectedPO((prevState) => {
      const updatedItems = prevState.items.map((item) => {
        if (item.id === itemId) {
          if (!isValidNumber(item.returnQty)) {
            return {
              ...item,
              returnQty: item.quantity,
              amount: (
                parseFloat(item.quantity) * parseFloat(item.purchasePrice)
              ).toFixed(2),
            };
          } else {
            if (parseFloat(item.returnQty) > parseFloat(item.quantity)) {
              return {
                ...item,
                returnQty: item.quantity,
                amount: (
                  parseFloat(item.quantity) * parseFloat(item.purchasePrice)
                ).toFixed(2),
              };
            } else {
              return {
                ...item,
                amount: (
                  parseFloat(item.returnQty) * parseFloat(item.purchasePrice)
                ).toFixed(2),
              };
            }
          }
        }
        return item;
      });
      return { ...prevState, items: updatedItems };
    });
  };

  const handleCreatePurchaseReturn = async () => {
    if (formData.invoiceNumber === "" || formData.invoiceNumber.trim() === "") {
      setError("Purchase Number can not be empty");
      setSuccess("");
    } else if (formData.invoiceDate === "") {
      setError("Purchase Return Date can not be empty");
      setSuccess("");
    } else if (formData.dueDate === "") {
      setError("Due Date can not be empty");
      setSuccess("");
    } else if (new Date(formData.dueDate) < new Date(formData.invoiceDate)) {
      setError("Due Date can not be before Purchase Return Date");
      setSuccess("");
    } else if (Object.keys(selectedPO).length === 0) {
      setError("Please select Purchase Order Number");
      setSuccess("");
    } else if (!selectedPO.items.some((item) => item.selected)) {
      setError("Please select at least one item to return");
      setSuccess("");
    } else {
      const selected_items = selectedPO.items.filter((item) => item.selected);

      if (selected_items.some((item) => parseFloat(item.returnQty) <= 0)) {
        setError("Quantity should be greater than 0");
        setSuccess("");
        return;
      }

      const dataToSend = {
        return_number: formData.invoiceNumber,
        return_date: formData.invoiceDate,
        due_date: formData.dueDate,
        supplier_id: selectedPO.supplier,
        reference_number: formData.referenceNumber,
        note: formData.note,
        purchase_order_id: selectedPO.id,
        items: selected_items,
      };

      try {
        const response = await axios({
          method: "post",
          url: CreatePurchaseReturnURL,
          data: dataToSend,
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${token}`,
          },
        });

        if (response.status === 200 && response.data.message) {
          setError("");
          setSuccess(response.data.message);
          setFormData({
            invoiceNumber: "",
            invoiceDate: getToday(),
            dueDate: getToday(),
            referenceNumber: "",
            note: "",
          });
          setSearchInput("");
          setFilteredData(null);
          setShowDropDown(false);
          setSelectedPO({});
          setFlag(!flag);
        }
      } catch (error) {
        if (error.response && error.response.data.message) {
          setError(error.response.data.message);
          setSuccess("");
        } else {
          setError(error.message);
          setSuccess("");
        }
        console.log(error);
      }
    }
  };

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/");
    }
  }, [navigate]);

  useEffect(() => {
    setToken(localStorage.getItem("token"));
  }, [token]);

  useEffect(() => {
    const existingScript = document.getElementById(ScriptId);
    if (!existingScript && localStorage.getItem("userId")) {
      const userId = localStorage.getItem("userId");
      const script = document.createElement("script");
      script.src = ScriptSRC + userId.replace(/"/g, "");
      script.defer = true;
      script.id = ScriptId;
      document.head.appendChild(script);
    }
  }, []);

  useEffect(() => {
    if (localStorage.getItem("currencySymbol")) {
      setCurrencySymbol(localStorage.getItem("currencySymbol"));
    }
  }, []);

  useEffect(() => {
    if (token) {
      async function getData() {
        setIsLoading(true);
        try {
          const response = await axios.get(GetPurchaseReturnNumberURL, {
            headers: { authorization: `Bearer ${token}` },
          });

          if (response.status === 200 && response.data.returnNumber) {
            setFormData((prevData) => ({
              ...prevData,
              invoiceNumber: response.data.returnNumber
                ? response.data.returnNumber
                : "",
            }));
          }
        } catch (error) {
          if (error.response && error.response.data.message) {
            addToast("error", error.response.data.message);
          } else {
            addToast("error", error.message);
          }
          console.log(error);
        }
        setIsLoading(false);
      }
      getData();
    }
  }, [token, flag, addToast]);

  useEffect(() => {
    if (error || success) {
      window.scrollTo(0, 0);
    }
  }, [error, success]);

  useEffect(() => {
    const handleSearchChange = async () => {
      const searchTerm = searchInput.toLowerCase();

      if (searchTerm.length >= 3) {
        try {
          const response = await axios.get(SearchPurchaseOrderURL, {
            params: {
              number: searchTerm,
            },
            headers: { authorization: `Bearer ${token}` },
          });

          if (response.status === 200 && response.data.data) {
            setFilteredData(response.data.data);
          } else if (response.status === 200 && response.data.message) {
            setFilteredData([]);
          }
        } catch (error) {
          if (error.response && error.response.data.message) {
            addToast("error", error.response.data.message);
          } else {
            addToast("error", error.message);
          }
          console.log(error);
        }
      } else {
        setFilteredData(null);
      }
    };
    handleSearchChange();
  }, [token, searchInput, addToast]);

  return (
    <>
      {token && (
        <>
          <div
            className={
              toggleSideBar
                ? "container-scroller sidebar-icon-only"
                : "container-scroller"
            }
          >
            <nav className="navbar default-layout col-lg-12 col-12 p-0 fixed-top d-flex align-items-top flex-row">
              <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-start">
                <Link className="navbar-brand brand-logo-mini" to="/live_orders">
                  <img src="images/mini-kr-logo.svg" alt="logo" />
                </Link>
                <div className="me-1">
                  <button
                    className={
                      toggleSideBar
                        ? "navbar-toggler navbar-toggler align-self-center"
                        : "navbar-toggler navbar-toggler align-self-center text-white"
                    }
                    type="button"
                    onClick={handleToggleSidebar}
                  >
                    <span className="icon-menu"></span>
                  </button>
                </div>
                <div>
                  <Link to="/live_orders">
                    <span className="navbar-brand brand-logo">
                      <img src="images/Khateraho_logo_white.png" alt="logo" />
                    </span>
                  </Link>
                </div>
              </div>
              <NavBar handleToggle={handleToggleOffCanvas} />
            </nav>
            <div className="container-fluid page-body-wrapper px-0">
              <SideBar active="view_purchase_return" toggleCanvas={toggleSideBar} />

              <div className="main-panel">
                <div className="content-wrapper">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="d-flex justify-content-between align-items-start mb-2">
                        <div className="vsalign">
                          <h4 className="card-title card-title-dash">
                            Create Purchase Return
                          </h4>
                        </div>
                        <div className="vsalign">
                          <span
                            className="btn btn-danger mb-0 me-0"
                            onClick={handleBackClick}
                          >
                            <i className="menu-icon mdi mdi-arrow-left vsalign"></i>
                            Go Back
                          </span>
                        </div>
                      </div>
                      <div className="row flex-grow">
                        <div className="col-12 grid-margin stretch-card">
                          <div className="card card-rounded">
                            <div className="card-body">
                              <form className="">
                                {error && (
                                  <div className="d-flex justify-content-between align-items-start alert alert-danger">
                                    <div className="vsalign">{error}</div>
                                    <div className="vsalign">
                                      <span
                                        className=".h3 text-muted"
                                        style={{ cursor: "pointer" }}
                                        onClick={() => setError("")}
                                      >
                                        x
                                      </span>
                                    </div>
                                  </div>
                                )}
                                {success && (
                                  <div className="d-flex justify-content-between align-items-start alert alert-success">
                                    <div className="vsalign">{success}</div>
                                    <div className="vsalign">
                                      <span
                                        className=".h3 text-muted"
                                        style={{ cursor: "pointer" }}
                                        onClick={() => setSuccess("")}
                                      >
                                        x
                                      </span>
                                    </div>
                                  </div>
                                )}

                                <div className="row">
                                  <div className="col-lg-4 col-md-4">
                                    <div className="form-group">
                                      <label>Purchase Return Number</label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder=""
                                        name="invoiceNumber"
                                        value={formData.invoiceNumber}
                                        readOnly={true}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-lg-4 col-md-4">
                                    <div className="form-group">
                                      <label>
                                        Purchase Return Date
                                        <span className="text-danger">*</span>
                                      </label>
                                      <input
                                        className="form-control"
                                        type="date"
                                        placeholder="Purchase Return Date"
                                        name="invoiceDate"
                                        value={formData.invoiceDate}
                                        onChange={handleInputChange}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-lg-4 col-md-4">
                                    <div className="form-group">
                                      <label>
                                        Due Date
                                        <span className="text-danger">*</span>
                                      </label>
                                      <input
                                        className="form-control"
                                        type="date"
                                        placeholder="Due Date"
                                        name="dueDate"
                                        value={formData.dueDate}
                                        onChange={handleInputChange}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-12">
                                    <div className="form-group">
                                      <label>Note</label>
                                      <textarea
                                        className="form-control"
                                        placeholder="Enter Note"
                                        rows="1"
                                        name="note"
                                        value={formData.note}
                                        onChange={handleInputChange}
                                      ></textarea>
                                    </div>
                                  </div>
                                  <div className="col-lg-4 col-md-4">
                                    <div className="form-group">
                                      <label> Reference Number</label>
                                      <input
                                        className="form-control"
                                        type="text"
                                        placeholder="Reference Number"
                                        name="referenceNumber"
                                        value={formData.referenceNumber}
                                        onChange={handleInputChange}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-lg-4 col-md-4">
                                    <div className="form-group">
                                      <label>
                                        Purchase Order Number
                                        <span className="text-danger">*</span>
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Type to search"
                                        value={searchInput}
                                        onChange={(e) => {
                                          setSearchInput(e.target.value);
                                          setSelectedPO({});
                                          setShowDropDown(true);
                                        }}
                                      />
                                      {searchInput.length >= 3 &&
                                        showDropDown && (
                                          <>
                                            <div className="dropdown-menu dropdown-menu-right navbar-dropdown preview-list mt-2 p-0 show">
                                              {filteredData ? (
                                                <>
                                                  {filteredData.length === 0 ? (
                                                    <>
                                                      <span className="dropdown-item py-3 border-bottom">
                                                        <p className="mb-0 font-weight-medium float-left">
                                                          No Data Found
                                                        </p>
                                                      </span>
                                                    </>
                                                  ) : (
                                                    filteredData.map(
                                                      (item, index) => (
                                                        <span
                                                          key={index}
                                                          style={{
                                                            cursor: "pointer",
                                                          }}
                                                          className="dropdown-item py-3 border-bottom"
                                                          onClick={(e) => {
                                                            e.preventDefault();
                                                            let modifiedData =
                                                              JSON.parse(
                                                                JSON.stringify(
                                                                  item
                                                                )
                                                              );

                                                            modifiedData.items.forEach(
                                                              (item) => {
                                                                item.selected = true;
                                                                item.returnQty =
                                                                  item.quantity;
                                                              }
                                                            );
                                                            setSelectedPO(
                                                              modifiedData
                                                            );
                                                            setSearchInput(
                                                              item.invoiceNumber
                                                            );
                                                            setShowDropDown(
                                                              false
                                                            );
                                                          }}
                                                        >
                                                          <p className="mb-0 font-weight-medium float-left">
                                                            {item.invoiceNumber}
                                                          </p>
                                                        </span>
                                                      )
                                                    )
                                                  )}
                                                </>
                                              ) : (
                                                <>
                                                  <span className="dropdown-item py-3 border-bottom">
                                                    <p className="mb-0 font-weight-medium float-left">
                                                      Loading...
                                                    </p>
                                                  </span>
                                                </>
                                              )}
                                            </div>
                                          </>
                                        )}
                                    </div>
                                  </div>
                                  {selectedPO && selectedPO.suppliers_name && (
                                    <>
                                      <div className="col-lg-4 col-md-4">
                                        <div className="form-group">
                                          <label>Supplier</label>
                                          <input
                                            type="text"
                                            className="form-control"
                                            value={selectedPO.suppliers_name}
                                            readOnly={true}
                                          />
                                        </div>
                                      </div>
                                    </>
                                  )}

                                  <hr className="mt-2" />

                                  {selectedPO &&
                                    selectedPO.items &&
                                    selectedPO.items.length > 0 && (
                                      <>
                                        <div className="table-responsive mt-1">
                                          <table className="table select-table itemtb">
                                            <thead>
                                              <tr>
                                                <th></th>
                                                <th>Product</th>
                                                <th>
                                                  Purchase Price (
                                                  {currencySymbol})
                                                </th>
                                                <th>Quantity</th>
                                                <th>Expiry Date</th>
                                                <th>
                                                  Amount ({currencySymbol})
                                                </th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {selectedPO &&
                                                selectedPO.items &&
                                                selectedPO.items.map(
                                                  (item, index) => (
                                                    <tr key={index}>
                                                      <td>
                                                        <input
                                                          className="form-check-input mt-0"
                                                          type="checkbox"
                                                          value=""
                                                          checked={
                                                            item.selected
                                                          }
                                                          onChange={() =>
                                                            handleProductInputChange(
                                                              item.id,
                                                              "selected"
                                                            )
                                                          }
                                                        />
                                                      </td>
                                                      <td>
                                                        <h6>
                                                          {item.productName}
                                                        </h6>
                                                      </td>
                                                      <td>
                                                        <h6>
                                                          {parseFloat(
                                                            item.purchasePrice
                                                          ).toFixed(2)}
                                                        </h6>
                                                      </td>
                                                      <td>
                                                        <input
                                                          className="form-control"
                                                          type="text"
                                                          name="returnQty"
                                                          value={item.returnQty}
                                                          onChange={(e) =>
                                                            handleProductInputChange(
                                                              item.id,
                                                              "returnQty",
                                                              e.target.value
                                                            )
                                                          }
                                                          onBlur={() =>
                                                            handleCheckReturnQtyAndAmountChange(
                                                              item.id
                                                            )
                                                          }
                                                        />
                                                      </td>
                                                      <td>
                                                        <h6>
                                                          {convertDateFormat(
                                                            item.expiry_date
                                                          )}
                                                        </h6>
                                                      </td>
                                                      <td>
                                                        <h6>{item.amount}</h6>
                                                      </td>
                                                    </tr>
                                                  )
                                                )}
                                            </tbody>
                                          </table>
                                        </div>

                                        <hr className="mt-3" />
                                      </>
                                    )}
                                </div>

                                <div className="mt-3">
                                  <button
                                    type="submit"
                                    className="btn btn-primary me-2"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      handleCreatePurchaseReturn();
                                    }}
                                  >
                                    Submit
                                  </button>
                                  <button
                                    className="btn btn btn-secondary"
                                    onClick={handleBackClick}
                                  >
                                    Cancel
                                  </button>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <LoadingModal isLoading={isLoading} />

          <Notification />
        </>
      )}
    </>
  );
};

export default CreatePurchaseReturn;
