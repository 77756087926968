import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import SideBar from "../other/SideBar";
import NavBar from "../other/NavBar";
import {
  GetUserDetailsURL,
  UpdatePasswordURL,
  UpdateUserDetailsURL,
  ScriptId,
  ScriptSRC,
} from "../../assets/api/BaseURL";
import LoadingModal from "../../loader/LoadingModal";
import Notification from "../other/Notification";
import { useToast } from "../../../context/ToastContext";

const Profile = () => {
  const navigate = useNavigate();
  const { addToast } = useToast();
  const [token, setToken] = useState("");
  const [toggleSideBar, setToggleSideBar] = useState(false);
  const [canvas, setCanvas] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [passwordSuccess, setPasswordSuccess] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState({
    contact_name: "",
    login_email: "",
    notification_email: "",
    username: "",
    notification_mobile: "",
    login_mobile_number: "",
  });

  const handleToggleSidebar = (e) => {
    e.preventDefault();
    setToggleSideBar(!toggleSideBar);
  };

  const handleToggleOffCanvas = (e) => {
    e.preventDefault();
    setCanvas(!canvas);
  };

  const handlePassword = (event) => {
    setPassword(event.target.value);
    setPasswordError("");
    setPasswordSuccess("");
  };

  const handleConfirmPassword = (event) => {
    setConfirmPassword(event.target.value);
    setPasswordError("");
    setPasswordSuccess("");
  };

  const handleSavePassword = async (e) => {
    e.preventDefault();

    if (password === "") {
      setPasswordError("New Password can not be empty");
      setPasswordSuccess("");
    } else if (confirmPassword === "") {
      setPasswordError("Re-type New Password can not be empty");
      setPasswordSuccess("");
    } else if (password !== confirmPassword) {
      setPasswordError("Passwords do not match.");
      setPasswordSuccess("");
    } else {
      try {
        const response = await axios({
          method: "put",
          url: UpdatePasswordURL,
          data: { password: password, confirmPassword: confirmPassword },
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${token}`,
          },
        });

        if (response.status === 200 && response.data.message) {
          setPasswordSuccess(response.data.message);
          setPassword("");
          setConfirmPassword("");
        }
      } catch (error) {
        if (error.response && error.response.data.message) {
          setPasswordError(error.response.data.message);
          setPassword("");
          setConfirmPassword("");
          setPasswordSuccess("");
        } else {
          setPasswordError(error.message);
          setPassword("");
          setConfirmPassword("");
          setPasswordSuccess("");
        }
        console.log(error);
      }
    }
  };

  const handleResetPassword = (e) => {
    e.preventDefault();
    setPassword("");
    setConfirmPassword("");
    setPasswordError("");
    setPasswordSuccess("");
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setUser({
      ...user,
      [name]: value,
    });
    setError("");
    setSuccess("");
  };

  const handleUpdateProfile = async (e) => {
    e.preventDefault();

    const email_pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const mobileNumberPattern = /^\d{10}$/;

    if (user.contact_name === "") {
      setError("Contact Name can not be empty");
      setSuccess("");
    } else if (user.login_email === "") {
      setError("Contact Email can not be empty");
      setSuccess("");
    } else if (!email_pattern.test(user.login_email)) {
      setError("Please Enter valid Contact email");
      setSuccess("");
    } else if (user.notification_email === "") {
      setError("Notification Email can not be empty");
      setSuccess("");
    } else if (!email_pattern.test(user.notification_email)) {
      setError("Please Enter valid Notification email");
      setSuccess("");
    } else if (user.notification_mobile === "") {
      setError("Notification Number can not be empty");
      setSuccess("");
    } else if (!mobileNumberPattern.test(user.notification_mobile)) {
      setError("Please Enter valid Notification Number");
      setSuccess("");
    } else if (user.username === "") {
      setError("Username can not be empty");
      setSuccess("");
    }else if (user.username.trim().split(" ").length - 1 !== 0) {
      setError("Spaces in usernames are not allowed, Enter correct username!");
      setSuccess("");
    } else if (user.login_mobile_number === "") {
      setError("Login mobile Number can not be empty");
      setSuccess("");
    } else if (!mobileNumberPattern.test(user.login_mobile_number)) {
      setError("Please Enter valid Login mobile Number");
      setSuccess("");
    } else {
      try {
        const response = await axios({
          method: "put",
          url: UpdateUserDetailsURL,
          data: user,
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${token}`,
          },
        });

        if (response.status === 200 && response.data.message) {
          setError("");
          setSuccess(response.data.message);
        }
      } catch (error) {
        if (error.response && error.response.data.message) {
          setError(error.response.data.message);
          setSuccess("");
        } else {
          setError(error.message);
          setSuccess("");
        }
        console.log(error);
      }
    }
  };

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/");
    }
  }, [navigate]);

  useEffect(() => {
    setToken(localStorage.getItem("token"));
  }, [token]);

  useEffect(() => {
    const existingScript = document.getElementById(ScriptId);
    if (!existingScript && localStorage.getItem("userId")) {
      const userId = localStorage.getItem("userId");
      const script = document.createElement("script");
      script.src = ScriptSRC + userId.replace(/"/g, "");
      script.defer = true;
      script.id = ScriptId;
      document.head.appendChild(script);
    }
  }, []);

  useEffect(() => {
    if (token) {
      async function getUser() {
        setIsLoading(true);
        try {
          const response = await axios.get(GetUserDetailsURL, {
            headers: { authorization: `Bearer ${token}` },
          });

          if (response.status === 200 && response.data.data) {
            setUser({
              contact_name: response.data.data.contact_name
                ? response.data.data.contact_name
                : "",
              login_email: response.data.data.login_email
                ? response.data.data.login_email
                : "",
              notification_email: response.data.data.notification_email
                ? response.data.data.notification_email
                : "",
              username: response.data.data.username
                ? response.data.data.username
                : "",
              notification_mobile: response.data.data.notification_mobile
                ? response.data.data.notification_mobile
                : "",
              login_mobile_number: response.data.data.login_mobile_number
                ? response.data.data.login_mobile_number
                : "",
            });
          }
        } catch (error) {
          if (error.response && error.response.data.message) {
            addToast("error", error.response.data.message);
          } else {
            addToast("error", error.message);
          }
          console.log(error);
        }
        setIsLoading(false);
      }
      getUser();
    }
  }, [token, addToast]);

  useEffect(() => {
    if (error || success || passwordError || passwordSuccess) {
      window.scrollTo(0, 0);
    }
  }, [error, success, passwordError, passwordSuccess]);

  return (
    <>
      {token && (
        <>
          <div
            className={
              toggleSideBar
                ? "container-scroller sidebar-icon-only"
                : "container-scroller"
            }
          >
            <nav className="navbar default-layout col-lg-12 col-12 p-0 fixed-top d-flex align-items-top flex-row">
              <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-start">
                <Link className="navbar-brand brand-logo-mini" to="/live_orders">
                  <img src="images/mini-kr-logo.svg" alt="logo" />
                </Link>
                <div className="me-1">
                  <button
                    className={
                      toggleSideBar
                        ? "navbar-toggler navbar-toggler align-self-center"
                        : "navbar-toggler navbar-toggler align-self-center text-white"
                    }
                    type="button"
                    onClick={handleToggleSidebar}
                  >
                    <span className="icon-menu"></span>
                  </button>
                </div>
                <div>
                  <Link to="/live_orders">
                    <span className="navbar-brand brand-logo">
                      <img src="images/Khateraho_logo_white.png" alt="logo" />
                    </span>
                  </Link>
                </div>
              </div>
              <NavBar handleToggle={handleToggleOffCanvas} />
            </nav>
            <div className="container-fluid page-body-wrapper px-0">
              <SideBar active="account_management" toggleCanvas={toggleSideBar} />

              <div className="main-panel">
                <div className="content-wrapper">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="row">
                        <div className="col-lg-8 d-flex flex-column">
                          <div className="row flex-grow">
                            <div className="col-12 grid-margin">
                              <div className="card card-rounded">
                                <div className="card-body">
                                  <h4 className="card-title">
                                    Contact Details
                                  </h4>
                                  <form className="forms-sample">
                                    {error && (
                                      <div className="d-flex justify-content-between align-items-start alert alert-danger">
                                        <div className="vsalign">{error}</div>
                                        <div className="vsalign">
                                          <span
                                            className=".h3 text-muted"
                                            style={{ cursor: "pointer" }}
                                            onClick={() => setError("")}
                                          >
                                            x
                                          </span>
                                        </div>
                                      </div>
                                    )}

                                    {success && (
                                      <div className="d-flex justify-content-between align-items-start alert alert-success">
                                        <div className="vsalign">{success}</div>
                                        <div className="vsalign">
                                          <span
                                            className=".h3 text-muted"
                                            style={{ cursor: "pointer" }}
                                            onClick={() => setSuccess("")}
                                          >
                                            x
                                          </span>
                                        </div>
                                      </div>
                                    )}
                                    <div className="form-group">
                                      <label>
                                        Contact Name
                                        <span className="text-danger">*</span>
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="contact_name"
                                        placeholder="Contact Name"
                                        value={user.contact_name}
                                        onChange={handleChange}
                                      />
                                    </div>
                                    <div className="form-group">
                                      <label>
                                        Contact Email
                                        <span className="text-danger">*</span>
                                      </label>
                                      <input
                                        type="email"
                                        className="form-control"
                                        name="login_email"
                                        placeholder="Contact Email"
                                        value={user.login_email}
                                        onChange={handleChange}
                                      />
                                    </div>
                                    <div className="form-group">
                                      <label>
                                        Notification Email
                                        <span className="text-danger">*</span>
                                      </label>
                                      <input
                                        type="email"
                                        className="form-control"
                                        name="notification_email"
                                        placeholder="Notification Email"
                                        value={user.notification_email}
                                        onChange={handleChange}
                                      />
                                    </div>
                                    <div className="form-group">
                                      <label>
                                        Notification Mobile No.
                                        <span className="text-danger">*</span>
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="notification_mobile"
                                        maxLength={10}
                                        placeholder="Notification Mobile No."
                                        value={user.notification_mobile}
                                        onChange={handleChange}
                                      />
                                    </div>
                                    <div className="form-group">
                                      <label>
                                        Username
                                        <span className="text-danger">*</span>
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="username"
                                        placeholder="Username"
                                        value={user.username}
                                        onChange={handleChange}
                                      />
                                    </div>
                                    <div className="form-group">
                                      <label>
                                        Registered Mobile No.
                                        <span className="text-danger">*</span>
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="login_mobile_number"
                                        maxLength={10}
                                        placeholder="Registered Mobile No."
                                        value={user.login_mobile_number}
                                        onChange={handleChange}
                                      />
                                    </div>
                                    <button
                                      type="submit"
                                      className="btn btn-primary me-2"
                                      onClick={handleUpdateProfile}
                                    >
                                      Save
                                    </button>
                                  </form>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 d-flex flex-column">
                          <div className="row flex-grow">
                            <div className="col-12 grid-margin">
                              <div className="card card-rounded">
                                <div className="card-body">
                                  <h4>Change Password</h4>
                                  <form className="pt-3">
                                    {passwordError && (
                                      <div className="d-sm-flex justify-content-between align-items-start alert alert-danger">
                                        <div className="vsalign">
                                          {passwordError}
                                        </div>
                                        <div className="vsalign">
                                          <span
                                            className=".h3 text-muted"
                                            style={{ cursor: "pointer" }}
                                            onClick={() => setPasswordError("")}
                                          >
                                            x
                                          </span>
                                        </div>
                                      </div>
                                    )}

                                    {passwordSuccess && (
                                      <div className="d-sm-flex justify-content-between align-items-start alert alert-success">
                                        <div className="vsalign">
                                          {passwordSuccess}
                                        </div>
                                        <div className="vsalign">
                                          <span
                                            className=".h3 text-muted"
                                            style={{ cursor: "pointer" }}
                                            onClick={() =>
                                              setPasswordSuccess("")
                                            }
                                          >
                                            x
                                          </span>
                                        </div>
                                      </div>
                                    )}

                                    <div className="form-group">
                                      <input
                                        type="password"
                                        className="form-control"
                                        placeholder="New Password"
                                        name="password"
                                        value={password}
                                        onChange={handlePassword}
                                      />
                                    </div>
                                    <div className="form-group">
                                      <input
                                        type="password"
                                        className="form-control"
                                        placeholder="Re-type New Password"
                                        name="confirmPassword"
                                        value={confirmPassword}
                                        onChange={handleConfirmPassword}
                                      />
                                    </div>
                                    <div className="mt-3">
                                      <button
                                        type="submit"
                                        className="btn btn-primary me-2"
                                        onClick={handleSavePassword}
                                      >
                                        Save
                                      </button>
                                      <button
                                        className="btn btn btn-secondary"
                                        onClick={handleResetPassword}
                                      >
                                        Reset
                                      </button>
                                    </div>
                                  </form>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <LoadingModal isLoading={isLoading} />

          <Notification />
        </>
      )}
    </>
  );
};

export default Profile;
