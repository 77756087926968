import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import LoadingModal from "../../loader/LoadingModal";
import "../../styles/posstyle.css";
import {
  AddProceedOrderURL,
  GetEaterDetailsURL,
  GetPOSDetailsURL,
  SettleDineOrderForCashPaymentURL,
  SettleDeliveryAndPickUpOrderForCashPaymentURL,
  GenerateKOTURL,
  SettleDeliveryAndPickUpOrderForUnpaidPaymentURL,
  DeleteKOTGeneratedItemURL,
  UpdateSaveOrderItemsURL,
  GetKOTDetailsListURL,
  UpdateKOTStatusURL,
  IncreaseKOTGeneratedItemQuantityURL,
  DecreaseKOTGeneratedItemQuantityURL,
  ScriptId,
  ScriptSRC,
  partialPaymentForDineIn,
  partialPaymentForDelivaryAndTakeAway,
  switchTablePosURL,
  switchTableKOTUpdateURL,
  checkTableCommittedURL,
  clearTablesURL,
  updateCustomerDataURL,
  orderWwhatssappMmassageHistoryURL
} from "../../assets/api/BaseURL";
import clickSound from "../../sound/button_click.wav";
import useSound from "use-sound";
import { useToast } from "../../../context/ToastContext";
import Select from "react-select";
import printJS from "print-js";
import Notification from "../other/Notification";

const POS = () => {
  const navigate = useNavigate();
  const { addToast } = useToast();
  const latestItemRef = useRef(null);
  const [playSound] = useSound(clickSound, {
    volume: 0.9,
    playbackRate: 1.5,
  });
  const [token, setToken] = useState("");

  const [whatsappApiAccessToken, setWhatsappApiAccessToken] = useState("");
  const [whatsappApiVendorUid, setWhatsappApiVendorUid] = useState("");
  const [whatsappMassageingApiBaseUrl, setWhatsappMassageingApiBaseUrl] = useState("");
  const [whatsappMassageingApiUrl, setWhatsappMassageingApiUrl] = useState("");
  const [fooderUpiId, setFooderUpiId] = useState("");
  const [sendWhatesappMessage, setSendWhatesappMessage] = useState("0");



  const [planDetails, setPlanDetails] = useState(null);
  const [logoutModel, setLogoutModel] = useState(false);

  const [currencySymbol, setCurrencySymbol] = useState("");
  const [data, setData] = useState([]);
  const [menus, setMenus] = useState([]);
  const [products, setProducts] = useState([]);
  const [currentMenu, setCurrentMenu] = useState("all");
  const [currentMenuProducts, setCurrentMenuProducts] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [orderList, setOrderList] = useState([]);


  // const [invoiceNumber, setInvoiceNumber] = useState('');


  const [isModal, setIsModal] = useState(false);
  const [isSettleModal, setIsSettleModal] = useState(false);
  const [isKOTItemDeleteModal, setIsKOTItemDeleteModal] = useState(false);
  const [tableView, setTableView] = useState(true);
  const [viewKOT, setViewKOT] = useState(false);
  const [dataKOTDetails, setDataKOTDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isPrintBill, setIsPrintBill] = useState(false);
  const [selectedTable, setSelectedTable] = useState("");
  const [selectedTableDetails, setSelectedTableDetails] = useState(null);
  const [tableDetailsByCategory, setTableDetailsByCategory] = useState([]);
  const [flag, setFlag] = useState(false);
  const [fooderId, setFooderId] = useState("");
  const [date, setDate] = useState("");
  const [currentTime, setCurrentTime] = useState(getCurrentTime());
  const [orderType, setOrderType] = useState("DINE IN");
  const [noOfPeople, setNoOfPeople] = useState("1");
  const [activeTab, setActiveTab] = useState("tables");
  const [waiterList, setWaiterList] = useState([]);
  const [newFilteredOrders, setNewFilteredOrders] = useState([]);
  const [selectedWaiter, setSelectedWaiter] = useState(null);
  const [productNote, setProductNote] = useState("");
  const [deleteReason, setDeleteReason] = useState("");
  const [unpaidReason, setUnpaidReason] = useState("");
  const [deleteKOTItem, setDeleteKOTItem] = useState(null);
  const [customerSuggestion, setCustomerSuggestion] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [filteredProducts, setFilteredProducts] = useState([]);
  const maxCharacterLimit = 200;
  const [customerForm, setCustomerForm] = useState({
    name: "",
    address: "",
    mobile: "",
  });
  const [selectedPaymentOption, setSelectedPaymentOption] = useState("Cash");
  const [discountValues, setDiscountValues] = useState({
    type: "0",
    rate: "0",
  });

  const [printKOTDetails, setPrintKOTDetails] = useState({
    KOT_no: "",
    KOT_items: [],
  });

  const [printKOTDetails2, setPrintKOTDetails2] = useState(null);
  const [printBillDetails, setPrintBillDetails] = useState(null);

  const [subTotal, setSubTotal] = useState(0);
  const [SCHRate, setSCHRate] = useState(0);
  const [discountType, setDiscountType] = useState("0");
  const [discountRate, setDiscountRate] = useState("0");
  const [discountAmount, setDiscountAmount] = useState();
  const [CGSTRate, setCGSTRate] = useState(0);
  const [SGSTRate, setSGSTRate] = useState(0);
  const [schAmount, setSchAmount] = useState();
  const [cgstAmount, setCGSTAmount] = useState();
  const [sgstAmount, setSGSTAmount] = useState();
  const [totalAmountPaid, setTotalAmountPaid] = useState();

  const [cashPaymentDetails, setCashPaymentDetails] = useState({
    customerPaid: "",
    returnToCustomer: "0",
    tip: "0",
    amount: "",
    transaction_id: "",
    due: "0"
  });

  const [cardPaymentDetails, setCardPaymentDetails] = useState({
    customerPaid: "",
    tip: "0",
    note: "",
    amount: "",
    transaction_id: ""
  });

  const [neftPaymentDetails, setNeftPaymentDetails] = useState({
    customerPaid: "",
    tip: "0",
    note: "",
    amount: "",
    transaction_id: ""
  });

  const [upiPaymentDetails, setUpiPaymentDetails] = useState({
    method: "",
    other_method: "",
    tip: "0",
    note: "",
    amount: "",
    transaction_id: ""
  });

  const [paymentSettleTransactionId, setPaymentSettleTransactionId] = useState("")

  const filteredOrders = (orderList[selectedTable]?.orders || []).filter(
    (order) => order.fooder_id === fooderId
  );

  // const pickupOrders = Object.keys(orderList)
  //   .filter((key) => key.startsWith("pk-"))
  //   .map((key) => ({ id: key }));

  const handleSearchChange = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    setSearchInput(searchTerm);

    const filtered = products.filter(
      (product) =>
        product.name.toLowerCase().includes(searchTerm) &&
        ((product.dine_in_service && orderType === "DINE IN") ||
          (product.delivery_service && orderType === "DELIVERY") ||
          (product.pick_up_service && orderType === "TAKE AWAY"))
    );
    setFilteredProducts(filtered);
  };

  const handleSearchClear = (e) => {
    const searchTerm = e.toLowerCase();
    setSearchInput(searchTerm);

    const filtered = products.filter(
      (product) =>
        product.name.toLowerCase().includes(searchTerm) &&
        ((product.dine_in_service && orderType === "DINE IN") ||
          (product.delivery_service && orderType === "DELIVERY") ||
          (product.pick_up_service && orderType === "TAKE AWAY"))
    );
    setFilteredProducts(filtered);
  };

  const handleLogout = async (tableClear) => {
    // e.preventDefault();


    localStorage.removeItem("token");
    localStorage.removeItem("planDetails");
    localStorage.removeItem("userId");
    localStorage.removeItem("currencySymbol");
    if (tableClear) {
      console.log(token)
      try {
        const response = await axios({
          method: "put",
          url: clearTablesURL,
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${token}`,
          },
        });
      } catch (error) {
      }
      localStorage.clear()
    }
    const existingScript = document.getElementById(ScriptId);
    if (existingScript) {
      document.head.removeChild(existingScript);
    }
    // navigate("/");
    window.location.href = "/";
  };

  function isValidPrice(price) {
    const pricePattern = /^\d+(\.\d{1,2})?$/;

    if (!pricePattern.test(price)) {


      return price;
      // return parseFloat(price).toFixed(2);


    }
    return price;
  }

  const formatTimestampForDate = (timestampStr) => {
    const timestamp = parseInt(timestampStr, 10);
    const dateObject = new Date(timestamp * 1000);

    const day = dateObject.getDate();
    const month = dateObject.toLocaleString("default", { month: "short" });
    const year = dateObject.getFullYear();

    return `${day} ${month} ${year}`;
  };

  const formatTimestampForTime = (timestampStr) => {
    const timestamp = parseInt(timestampStr, 10);
    const dateObject = new Date(timestamp * 1000);

    // const day = dateObject.getDate();
    // const month = dateObject.toLocaleString("default", { month: "short" });
    // const year = dateObject.getFullYear();
    let hours = dateObject.getHours();
    const minutes = dateObject.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";

    if (hours > 12) {
      hours -= 12;
    }

    return `${hours}:${minutes.toString().padStart(2, "0")} ${ampm}`;
  };

  const handlePrintIconClick = (table) => {
    setSelectedTableDetails(table);
    const tableData = orderList[table.id];

    const tableOrderList = (orderList[table.id]?.orders || []).filter(
      (order) => order.fooder_id === fooderId
    );

    const amount = tableOrderList.reduce(
      (total, item) => total + item.newPrice * item.quantity,
      0
    );

    let discountedAmount = 0;
    let discount = 0;

    if (tableData.discountValues.type === "0") {
      discount = (amount * (tableData.discountValues.rate || 0)) / 100;
      discountedAmount = amount - discount;
    } else if (tableData.discountValues.type === "1") {
      discount = tableData.discountValues.rate;
      discountedAmount = amount - discount;
    } else {
      discountedAmount = amount;
    }

    const sch = (discountedAmount * (SCHRate || 0)) / 100;
    const taxableAmout = discountedAmount + sch;

    const cgst = (taxableAmout * (CGSTRate || 0)) / 100;
    const sgst = (taxableAmout * (SGSTRate || 0)) / 100;

    const totalPaid = taxableAmout + cgst + sgst;

    setSchAmount(sch.toFixed(2));
    setCGSTAmount(cgst.toFixed(2));
    setSGSTAmount(sgst.toFixed(2));
    setDiscountAmount(parseFloat(discount).toFixed(2));
    setTotalAmountPaid(totalPaid.toFixed(2));

    const result = {
      subTotal: amount.toFixed(2),
      discountRate: tableData.discountValues.rate,
      discountType: tableData.discountValues.type,
      discountAmount: parseFloat(discount).toFixed(2),
      cgstAmount: cgst.toFixed(2),
      schAmount: sch.toFixed(2),
      sgstAmount: sgst.toFixed(2),
      totalAmountPaid: totalPaid.toFixed(2),
      date: formatTimestampForDate(tableData.creation_date),
      time: formatTimestampForTime(tableData.creation_date),
    };



    let subTotal = 0;
    let tempDiscount = 0;
    let tempDiscountRow = 0;

    let tempServicCharge = 0;
    let tempServicChargeRow = 0;

    let tempTax = 0;
    let tempTaxRow = 0;

    let GSTTaxT1 = 0;
    let GSTTaxT2 = 0;
    let GSTTaxT3 = 0;
    let GSTTaxT4 = 0;
    let GSTTaxT5 = 0;



    let tempCartLen = 0;
    let billDiscountRate = tableData.discountValues.rate || 0;

    let packingCharges = 0;

    let fineOrderId = true


    setDiscountRate(billDiscountRate)
    //  for flate amount discount

    let withOutTaxPriceForAmount = 0
    let subTotalForAmount = 0;
    let discountRateForAmount = 0;



    if (tableData.discountValues.type === "1") {
      tableOrderList.forEach((i) => {
        subTotalForAmount += (i.quantity) * i.withOutTaxPrice
        // alert(billDiscountRate)
      })

      discountRateForAmount = (parseFloat(tableData.discountValues.rate || 0) * 100) / subTotalForAmount

    }

    // end


    tableOrderList.forEach((i) => {

      if (!i.orderId) {
        fineOrderId = false
      }

      packingCharges += i.quantity * parseInt(i.packaging_charges)
      tempCartLen += parseInt(i.quantity)

      tempDiscountRow = 0;


      subTotal += (i.quantity) * i.withOutTaxPrice

      // console.log(subTotal)
      // console.log(i.withOutTaxPrice)


      if (tableData.discountValues.type === "0") {
        tempDiscount += (((i.quantity) * i.withOutTaxPrice) * billDiscountRate) / 100
        tempDiscountRow = (((i.quantity) * i.withOutTaxPrice) * billDiscountRate) / 100
        setDiscountType('0')

      } else {
        tempDiscount += (((i.quantity) * i.withOutTaxPrice) * discountRateForAmount) / 100
        tempDiscountRow = (((i.quantity) * i.withOutTaxPrice) * discountRateForAmount) / 100
        setDiscountType('1')
        // alert(tempDiscount)

      }





      tempServicCharge += ((((i.quantity) * i.withOutTaxPrice) - tempDiscountRow) * parseFloat(SCHRate)) / 100
      tempServicChargeRow = ((((i.quantity) * i.withOutTaxPrice) - tempDiscountRow) * parseFloat(SCHRate)) / 100



      tempTax += ((((i.quantity) * i.withOutTaxPrice) + tempServicChargeRow - tempDiscountRow) * parseFloat(i.tax_percent)) / 100
      tempTaxRow = ((((i.quantity) * i.withOutTaxPrice) + tempServicChargeRow - tempDiscountRow) * parseFloat(i.tax_percent)) / 100


      if (i.tax_percent === 0) {

        GSTTaxT1 += ((((i.quantity) * i.withOutTaxPrice) + tempServicChargeRow - tempDiscountRow) * parseFloat(i.tax_percent)) / 100
        setCGSTTaxt1(GSTTaxT1 / 2)
        setSGSTTaxt1(GSTTaxT1 / 2)

      }

      if (i.tax_percent === 5) {

        GSTTaxT2 += ((((i.quantity) * i.withOutTaxPrice) + tempServicChargeRow - tempDiscountRow) * parseFloat(i.tax_percent)) / 100

        setCGSTTaxt2((GSTTaxT2 / 2).toFixed(2))
        setSGSTTaxt2((GSTTaxT2 / 2).toFixed(2))

      }

      if (i.tax_percent === 12) {

        GSTTaxT3 += ((((i.quantity) * i.withOutTaxPrice) + tempServicChargeRow - tempDiscountRow) * parseFloat(i.tax_percent)) / 100

        setCGSTTaxt3((GSTTaxT3 / 2).toFixed(2))
        setSGSTTaxt3((GSTTaxT3 / 2).toFixed(2))

      }

      if (i.tax_percent === 18) {

        GSTTaxT4 += ((((i.quantity) * i.withOutTaxPrice) + tempServicChargeRow - tempDiscountRow) * parseFloat(i.tax_percent)) / 100
        setCGSTTaxt4((GSTTaxT4 / 2).toFixed(2))
        setSGSTTaxt4((GSTTaxT4 / 2).toFixed(2))
      }

      if (i.tax_percent === 28) {

        GSTTaxT5 += ((((i.quantity) * i.withOutTaxPrice) + tempServicChargeRow - tempDiscountRow) * parseFloat(i.tax_percent)) / 100
        setCGSTTaxt5((GSTTaxT5 / 2).toFixed(2))
        setSGSTTaxt5((GSTTaxT5 / 2).toFixed(2))
      }

    })

    setCartProductSubTotal(subTotal.toFixed(2));



    setCartProductServiceCharge(tempServicCharge.toFixed(2))
    setCartProductDiscount(tempDiscount.toFixed(2))
    setCartProductTax(tempTax.toFixed(2))
    setCartProductTotal((subTotal + tempServicCharge - tempDiscount + tempTax).toFixed(2));
    setCartDataLen(tempCartLen)

    setTotalAmountPaid((subTotal + tempServicCharge - tempDiscount + tempTax).toFixed(2))
    setCartProductTotal((subTotal + tempServicCharge - tempDiscount + tempTax).toFixed(2))







    // alert((subTotal + tempServicCharge - tempDiscount + tempTax).toFixed(2))




    if (fineOrderId) {
      setPrintBillDetails(result);
      setIsPrintBill(true);
    } else {
      addToast("error", "Please save bill first");

    }


    // dssdas
  };

  const handleMenuClick = (menuId) => {
    playSound();
    setCurrentMenu(menuId);
    if (menuId === "all") {
      setCurrentMenuProducts(products);
    } else {
      const menuProducts = products.filter(
        (product) => product.menu_id === menuId
      );
      setCurrentMenuProducts(menuProducts);
    }
    setTableView(false);
  };


  // work on variants

  const [selectedPrductForAddedVariants, setSelectedPrductForAddedVariants] = useState({});


  const [productVariantAddonsData, setProductVariantAddonsData] = useState([]);
  const [productVariantAttributesData, setProductVariantAttributesData] = useState([]);
  const [productVariantVariantData, setProductVariantVariantData] = useState([]);


  const [selectedProductVariantAttributesData, setSelectedProductVariantAttributesData] = useState([]);
  const [selectedProductVariant, setSelectedProductVariant] = useState({ 'variantId': "0" });
  const [productVariantPropertiesPriceArray, setProductVariantPropertiesPriceArray] = useState([]);
  const [selectedProductVariantAddonsData, setSelectedProductVariantAddonsData] = useState([]);
  const [selectedProductVariantAddonsIdArray, setSelectedProductVariantAddonsIdArray] = useState([]);

  const [selectedProductVariantAddonsPrice, setSelectedProductVariantAddonsPrice] = useState(0);




  useEffect(() => {

    // console.log(selectedProductVariantAddonsPrice)
    let tempSelectedAddonsPrice = 0
    selectedProductVariantAddonsData.forEach((i) => {
      tempSelectedAddonsPrice += parseFloat(i.addon_item_price)
    })
    setSelectedProductVariantAddonsPrice(tempSelectedAddonsPrice)
  }, [selectedProductVariantAddonsData]);

  const changeSelectedVariants = (index, value) => {
    // console.log(value, index)
    playSound();

    const tempSelectedProperties = [...selectedProductVariantAttributesData];

    // console.log("tempSelectedProperties")
    tempSelectedProperties[index] = value
    // console.log(tempSelectedProperties)
    setSelectedProductVariantAttributesData(tempSelectedProperties)
    setSelectedProductVariantAddonsData([])
    setSelectedProductVariantAddonsIdArray([])
    setSelectedProductVariantAddonsPrice(0)

  }


  const changeSelectedAddons = (checked, value, min, max, addonId) => {
    // console.log(value, checked)
    playSound();
    value.addonId = addonId
    value.minimum_item = min ? parseInt(min) : 0
    value.maximum_item = parseInt(max)





    let tempSelectedAddons = [...selectedProductVariantAddonsData]
    let tempSelectedAddonsIdArray = [...selectedProductVariantAddonsIdArray]

    // console.log(tempSelectedAddonsIdArray)
    let tempSelectedAddonsLen = 0

    tempSelectedAddons.forEach((i) => {
      if (i.addonId === addonId) {
        tempSelectedAddonsLen++
      }
    })


    if (max <= tempSelectedAddonsLen && checked) {
      // alert("hahaha...")
      addToast("error", "Max addons selected");
    } else {
      if (checked) {
        tempSelectedAddons.push(value)
        tempSelectedAddonsIdArray.push(value.addonItemId)

        setSelectedProductVariantAddonsData(tempSelectedAddons)
        setSelectedProductVariantAddonsIdArray(tempSelectedAddonsIdArray)

      }
      else {

        // console.log("remove value")
        let temp = []
        let tempIdArray = []

        tempSelectedAddons.forEach((i) => {
          // console.log(i)
          if (i.addonItemId !== value.addonItemId) {
            temp.push(i)
            tempIdArray.push(i.addonItemId)
          }

        })
        setSelectedProductVariantAddonsData(temp)
        setSelectedProductVariantAddonsIdArray(tempIdArray)




      }
    }




  }

  useEffect(() => {

    if (selectedProductVariantAttributesData.length > 0) {

      productVariantVariantData.forEach((i) => {

        let counter = 0

        i.combination_details.forEach((j, jCount) => {
          if (j.attribute_value_id == selectedProductVariantAttributesData[jCount]) {
            counter++;
          }

        })
        if (counter == selectedProductVariantAttributesData.length) {
          // console.log("variant finded")
          // console.log(i)
          setSelectedProductVariant(i)

        }

      })

      let tempPropertiesPriceArray = []


      productVariantVariantData.forEach((i) => {

        if (i.combination_details[0].attribute_value_id == selectedProductVariantAttributesData[0]) {
          tempPropertiesPriceArray.push(i.combination_price)
          // console.log(i.combination_price)
        }
      })
      setProductVariantPropertiesPriceArray(tempPropertiesPriceArray)



      if (productVariantVariantData.length === productVariantAttributesData[0].value.length) {
        tempPropertiesPriceArray = []
        productVariantVariantData.forEach((i) => {

          tempPropertiesPriceArray.push(i.combination_price)

        })
        setProductVariantPropertiesPriceArray(tempPropertiesPriceArray)
        // console.log("tempPropertiesPriceArray")
        // console.log(tempPropertiesPriceArray)
      }
    }


  }, [selectedProductVariantAttributesData]);





  // useEffect(() => {

  //   if (productVariantAddonsData.length <= 0) {

  //     console.log()
  //   }


  // }, [productVariantVariantData]);




  const handleProductClick = (product) => {

    playSound();
    if (selectedTable) {

      product.quantity = product.min_order_quantity
      setSelectedProduct(product);
      setProductNote("");
      setIsModal(true);

      setSelectedPrductForAddedVariants(product)
      // console.log('handle Product click')
      // console.log(product)

      // setProductVariantAddonsData(product.addons)
      // setProductVariantAttributesData(product.attributes)

      setSelectedProductVariantAddonsData([])
      setSelectedProductVariantAddonsIdArray([])
      setSelectedProductVariant({ 'variantId': "0" })
      if (product.addons) {
        setProductVariantAddonsData(product.addons)
        // alert(product.addons)
        // console.log(product.addons)
      } else {
        setProductVariantAddonsData([])
      }

      if (product.variants) {
        setProductVariantVariantData(product.variants)
      } else {
        setProductVariantVariantData([])
      }



      if (product.attributes) {
        let tempAttributeArray = []
        let tempArrayUnick = []

        product.attributes.forEach((i) => {
          let count = 0;

          tempArrayUnick.forEach((j) => {
            if (i.attributeId === j.attributeId) {
              count++;
            }
          })
          if (count == 0) {
            tempArrayUnick.push({
              'attributeId': i.attributeId,
              'attributeName': i.attributeName,
            });
          }
        });

        tempArrayUnick.forEach((i) => {
          let temp = []
          product.attributes.forEach((j) => {
            if (i.attributeId === j.attributeId) {
              temp.push(j);
            }
          });

          tempAttributeArray.push({
            'attributeId': i.attributeId,
            'attributeName': i.attributeName,
            'value': temp,
          });
        });

        setProductVariantAttributesData(tempAttributeArray)
        // console.log("tempAttributeArray")
        // console.log(tempAttributeArray)
        let tempSelectedProperties = []
        tempAttributeArray.forEach((i) => {
          tempSelectedProperties.push(i.value[0].attributeValueId);
        })

        // console.log(tempSelectedProperties)
        setSelectedProductVariantAttributesData(tempSelectedProperties)
      } else {
        setProductVariantAttributesData([])
      }
      // setShowVariants(true)
    } else {
      addToast("error", "Please select a table first!");
      setSelectedTableDetails(null);
      setSelectedTable("");
      setTableView(true);
    }
  };

  const handleTableView = () => {
    playSound();
    // if (orderType === "DELIVERY") {
    //   clearTableData("dl");
    //   setFlag(!flag);
    // } else if (orderType === "TAKE AWAY") {
    //   clearTableData("pk");
    //   setFlag(!flag);
    // } else {
    //   setSelectedTableDetails(null);
    //   setSelectedTable("");
    //   setActiveTab("tables");
    //   setTableView(true);
    // }
    // setOnlySavePaymentRoundUpCheck(false)
    // setOnlySavePaymentRoundUpAmount(0)
    setSelectedTableDetails(null);
    setSelectedTable("");
    setActiveTab("tables");
    setTableView(true);
    setViewKOT(false);
    setSearchInput('')
    setSwitchTableWithAllTable(false)
  };

  const handleOrderTypeChange = (newOrderType) => {
    if (newOrderType === "DELIVERY") {
      setOrderType(newOrderType);
      setSelectedTable("dl");
      setTableView(false);
    } else if (newOrderType === "TAKE AWAY") {
      setOrderType(newOrderType);
      setSelectedTable("pk");
      setTableView(false);
    } else {
      if (selectedTableDetails) {
        setOrderType(newOrderType);
        setSelectedTable(selectedTableDetails ? selectedTableDetails.id : "");
      } else {
        setOrderType(newOrderType);
        setSelectedTable(selectedTableDetails ? selectedTableDetails.id : "");
        setTableView(true);
      }
    }
    setActiveTab("tables");
    setSelectedPaymentOption("Cash");
    setCustomerForm({
      name: "",
      address: "",
      mobile: "",
    });
    setNoOfPeople("1");
    setCustomerSuggestion("");
    setSelectedWaiter(null);
    setViewKOT(false);
  };

  const capitalizeFirstLetter = (str) => {
    if (str) {
      const words = str.split(" ");

      const capitalizedWords = words.map((word) => {
        if (word.length === 0) {
          return "";
        }

        else
          if (word[0] === '(' || word[0] === '-') {
            return word.charAt(0).toUpperCase() + word.charAt(1).toUpperCase() + word.slice(2).toLowerCase();
          }
          else {
            return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
          }
      });

      const capitalizedString = capitalizedWords.join(" ");

      return capitalizedString;
    }
  };

  const updateQuantity = (increment, min_order_quantity) => {
    playSound();
    // alert(min_order_quantity)
    console.log(selectedProduct)
    if (selectedProduct) {
      const updatedProduct = { ...selectedProduct };
      if (increment) {
        updatedProduct.quantity += 1;
      } else if (updatedProduct.quantity > 1) {
        if (updatedProduct.quantity > min_order_quantity) {
          updatedProduct.quantity -= 1;
        } else {

        }
      }
      setSelectedProduct(updatedProduct);
    }
  };

  const handleAddToOrder = () => {
    playSound();

    // console.log("**** add items to orders ****")
    // console.log(selectedProductVariantAddonsData)
    // console.log(selectedProductVariant)

    let checkMinAddon = false


    productVariantAddonsData.forEach((i) => {
      let tempMin = 0
      selectedProductVariantAddonsData.forEach((j) => {
        if (i.addonId == j.addonId) {
          tempMin++
        }

      })
      // console.log(i)
      // alert(tempMin)
      // alert(i.minimum_item)

      if (i.minimum_item > tempMin) {
        // alert("hahaha...")
        checkMinAddon = true
      }

    })
    if (checkMinAddon) {
      addToast("error", "Please add more addons");

    } else {
      if (selectedTable) {
        //  if (orderList[selectedTable].orderId) {
        //    addToast("error", "Order already saved !");
        //  } else
        if (selectedProduct) {
          const orderItem = {
            ...selectedProduct,
            local_id: new Date().getTime(),
            newPrice: selectedProduct.proprice
              ? selectedProduct.proprice
              : selectedProduct.price,
            fooder_id: fooderId,
            table_id: selectedTable,
            fooder_name: data.fooder_name,
            product_special_note: productNote,
            isKOT: false,
            isSaved: false,
            KOT_id: "",
            KOT_no: "",
            KOT_time: "",
            selectedAddons: selectedProductVariantAddonsData,
            selectedvariants: selectedProductVariant,


            newPrice: (parseFloat(selectedProductVariantAddonsPrice) + parseFloat(selectedProductVariant.combination_price)) ?
              `${(parseFloat(selectedProductVariantAddonsPrice) + parseFloat(selectedProductVariant.combination_price)).toFixed(2)}` :
              `${selectedPrductForAddedVariants.proprice ? (parseFloat(selectedPrductForAddedVariants.proprice) + parseFloat(selectedProductVariantAddonsPrice)).toFixed(2) : (parseFloat(selectedPrductForAddedVariants.price) + parseFloat(selectedProductVariantAddonsPrice)).toFixed(2)}`,

            price: (parseFloat(selectedProductVariantAddonsPrice) + parseFloat(selectedProductVariant.combination_price)) ?
              `${(parseFloat(selectedProductVariantAddonsPrice) + parseFloat(selectedProductVariant.combination_price)).toFixed(2)}` :
              `${selectedPrductForAddedVariants.proprice ? (parseFloat(selectedPrductForAddedVariants.proprice) + parseFloat(selectedProductVariantAddonsPrice)).toFixed(2) : (parseFloat(selectedPrductForAddedVariants.price) + parseFloat(selectedProductVariantAddonsPrice)).toFixed(2)}`,


            proprice: ""

          };

          // console.log("8*********************************")
          // console.log((parseFloat(selectedProductVariantAddonsPrice) + parseFloat(selectedProductVariant.combination_price)) ?
          // `${(parseFloat(selectedProductVariantAddonsPrice) + parseFloat(selectedProductVariant.combination_price)).toFixed(2)}` :
          // `${selectedPrductForAddedVariants.proprice ? selectedPrductForAddedVariants.proprice : selectedPrductForAddedVariants.price}`)

          const existingOrderTable = selectedTable;
          const existingOrderList =
            JSON.parse(localStorage.getItem("orderList")) || {};

          if (!existingOrderList[existingOrderTable]) {
            existingOrderList[existingOrderTable] = {
              isPrinted: false,
              orderId: "",
              orderNo: "",
              creation_date: "",
              orders: [],
              discountValues: {
                type: "0",
                rate: "0",
              },
              customerDetails: {
                name: "",
                address: "",
                mobile: "",
              },
              totalPersons: "1",
              instructions: "",
              staff: null,
            };
          }

          // const itemIndex = existingOrderList[
          //   existingOrderTable
          // ].orders.findIndex((item) => item.id === selectedProduct.id);

          // if (itemIndex !== -1) {
          //   existingOrderList[existingOrderTable].orders[itemIndex] = orderItem;
          // } else {
          //   existingOrderList[existingOrderTable].orders.push(orderItem);
          // }

          // if (itemIndex !== -1) {
          //   existingOrderList[existingOrderTable].orders.splice(itemIndex, 1);
          // }

          existingOrderList[existingOrderTable].orders.push(orderItem);

          localStorage.setItem("orderList", JSON.stringify(existingOrderList));

          setSelectedProduct(null);
          setProductNote("");
          setFlag(!flag);
          setIsModal(false);
          setActiveTab("tables");
        }
      } else {
        addToast("error", "Please select a table first!");
      }
    }


  }

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const updateCustomerDetails = (obj) => {
    const updatedOrderList = { ...orderList };
    if (
      updatedOrderList[selectedTable] &&
      updatedOrderList[selectedTable].customerDetails
    ) {
      updatedOrderList[selectedTable].customerDetails = obj;
    }
    localStorage.setItem("orderList", JSON.stringify(updatedOrderList));
    setOrderList(updatedOrderList);
    setFlag(!flag);
  };

  const updateTotalPersons = (value) => {
    const updatedOrderList = { ...orderList };
    if (updatedOrderList[selectedTable]) {
      updatedOrderList[selectedTable].totalPersons = value;
    }
    localStorage.setItem("orderList", JSON.stringify(updatedOrderList));
    setOrderList(updatedOrderList);
    setFlag(!flag);
  };

  const handleCustomerMobileChange = async (e) => {
    const { name, value } = e.target;
    const mobileNumberPattern = /^\d{10}$/;

    if (name === "mobile" && value.length === 10) {
      setCustomerForm({
        ...customerForm,
        [name]: value,
      });

      updateCustomerDetails({
        ...customerForm,
        [name]: value,
      });

      if (!mobileNumberPattern.test(value)) {
        addToast("error", "Please Enter valid Mobile Number");
      } else {
        try {
          const response = await axios.get(GetEaterDetailsURL, {
            params: {
              eater_phonenumber: value,
            },
            headers: { authorization: `Bearer ${token}` },
          });

          if (
            response.status === 200 &&
            response.data.data &&
            response.data.message
          ) {
            addToast("success", response.data.message);
            // setCustomerForm(response.data.data);
            // updateCustomerDetails(response.data.data);
            setCustomerForm({
              name: response.data.data.name
                ? response.data.data.name
                : customerForm.name,
              address: response.data.data.address
                ? response.data.data.address
                : customerForm.address,
              mobile: response.data.data.mobile
                ? response.data.data.mobile
                : customerForm.mobile,
            });
            updateCustomerDetails({
              name: response.data.data.name
                ? response.data.data.name
                : customerForm.name,
              address: response.data.data.address
                ? response.data.data.address
                : customerForm.address,
              mobile: response.data.data.mobile
                ? response.data.data.mobile
                : customerForm.mobile,
            });
          } else if (
            response.status === 200 &&
            !response.data.data &&
            response.data.message
          ) {
            addToast("success", response.data.message);
            setCustomerForm({
              ...customerForm,
              mobile: value,
            });
            updateCustomerDetails({
              ...customerForm,
              mobile: value,
            });
          }
        } catch (error) {
          if (error.response && error.response.data.message) {
            addToast("error", error.response.data.message);
          } else {
            addToast("error", error.message);
          }
          console.log(error);
        }
      }
    } else {
      setCustomerForm({
        ...customerForm,
        [name]: value,
      });

      updateCustomerDetails({
        ...customerForm,
        [name]: value,
      });
    }
  };

  const handleCustomerFormChange = (e) => {
    const { name, value } = e.target;

    setCustomerForm({
      ...customerForm,
      [name]: value,
    });

    updateCustomerDetails({
      ...customerForm,
      [name]: value,
    });
  };

  const handleCashPaymentDetailsChange = (e) => {
    const { name, value } = e.target;

    if (name === "customerPaid") {
      const customerPaid = parseFloat(value) || 0;
      const amount = parseFloat(cashPaymentDetails.amount) || 0;


      const returnToCustomer = Math.abs(customerPaid - amount);



      if (!isNaN(returnToCustomer)) {
        setCashPaymentDetails({
          ...cashPaymentDetails,
          [name]: value,
          returnToCustomer: returnToCustomer.toFixed(2),
        });
      } else {
        setCashPaymentDetails({
          ...cashPaymentDetails,
          [name]: value,
        });
      }
    } else {
      setCashPaymentDetails({
        ...cashPaymentDetails,
        [name]: value,
      });
    }
  };

  const handleCardPaymentDetailsChange = (e) => {
    const { name, value } = e.target;

    setCardPaymentDetails({
      ...cardPaymentDetails,
      [name]: value,
    });
  };

  const handleNeftPaymentDetailsChange = (e) => {
    const { name, value } = e.target;

    setNeftPaymentDetails({
      ...neftPaymentDetails,
      [name]: value,
    });
  };

  const handleUPIPaymentDetailsChange = (e) => {
    const { name, value } = e.target;

    if (name === "method") {
      setUpiPaymentDetails({
        ...upiPaymentDetails,
        [name]: value,
        other_method: "",
      });
    } else {
      setUpiPaymentDetails({
        ...upiPaymentDetails,
        [name]: value,
      });
    }
  };

  // const calculateReturnToCustomer = () => {
  //   const customerPaid = parseFloat(cashPaymentDetails.customerPaid) || 0;
  //   const amount = parseFloat(cashPaymentDetails.amount) || 0;
  //   // const tip = parseFloat(cashPaymentDetails.tip) || 0;

  //   // const returnToCustomer = customerPaid - amount - tip;
  //   const returnToCustomer = Math.abs(customerPaid - amount);

  //   if (!isNaN(returnToCustomer)) {
  //     setCashPaymentDetails({
  //       ...cashPaymentDetails,
  //       returnToCustomer: returnToCustomer.toFixed(2),
  //     });
  //   }
  // };

  const calculateFinalAmountForCash = () => {
    const amount = parseFloat(totalAmountPaid) || 0;
    const tip = parseFloat(cashPaymentDetails.tip) || 0;

    const finalAmount = Math.abs(amount + tip);

    if (!isNaN(finalAmount)) {
      setCashPaymentDetails({
        ...cashPaymentDetails,
        amount: finalAmount.toFixed(2),
        customerPaid: finalAmount.toFixed(2),
        returnToCustomer: 0,
      });
    }
  };

  const calculateFinalAmountForCard = () => {
    const amount = parseFloat(totalAmountPaid) || 0;
    const tip = parseFloat(cardPaymentDetails.tip) || 0;

    const finalAmount = Math.abs(amount + tip);

    if (!isNaN(finalAmount)) {
      setCardPaymentDetails({
        ...cardPaymentDetails,
        amount: finalAmount.toFixed(2),
        customerPaid: finalAmount.toFixed(2),
      });
    }
  };


  const calculateFinalAmountForNeft = () => {
    const amount = parseFloat(totalAmountPaid) || 0;
    const tip = parseFloat(neftPaymentDetails.tip) || 0;

    const finalAmount = Math.abs(amount + tip);

    if (!isNaN(finalAmount)) {
      setNeftPaymentDetails({
        ...neftPaymentDetails,
        amount: finalAmount.toFixed(2),
        customerPaid: finalAmount.toFixed(2),
      });
    }
  };

  const calculateFinalAmountForUPI = () => {
    const amount = parseFloat(totalAmountPaid) || 0;
    const tip = parseFloat(upiPaymentDetails.tip) || 0;

    const finalAmount = Math.abs(amount + tip);

    if (!isNaN(finalAmount)) {
      setUpiPaymentDetails({
        ...upiPaymentDetails,
        amount: finalAmount.toFixed(2),
        customerPaid: finalAmount.toFixed(2),
      });
    }
  };

  const updateQuantityInList = (itemId, id, newQuantity, table) => {
    const updatedOrderList = { ...orderList };

    if (updatedOrderList[table] && updatedOrderList[table].orders) {
      updatedOrderList[table].orders = updatedOrderList[table].orders.map(
        (order) => {
          if (
            order.id === itemId &&
            order.fooder_id === fooderId &&
            order.local_id === id
          ) {
            return { ...order, quantity: newQuantity };
          }
          return order;
        }
      );
    }

    localStorage.setItem("orderList", JSON.stringify(updatedOrderList));
    setOrderList(updatedOrderList);
    setFlag(!flag);
  };

  const handlePlusClick = (itemId, id, table) => {
    playSound();
    const orderItem = (orderList[table]?.orders || []).find(
      (order) =>
        order.id === itemId &&
        order.fooder_id === fooderId &&
        order.local_id === id
    );

    // console.log(orderList[table]?.orders)
    // console.log(table)


    if (orderItem) {
      const newQuantity = orderItem.quantity + 1;
      updateQuantityInList(itemId, id, newQuantity, table);
    }
  };

  const handlePlusClickAfterKOT = async (itemId, id, table) => {
    playSound();
    const orderItem = (orderList[table]?.orders || []).find(
      (order) =>
        order.id === itemId &&
        order.fooder_id === fooderId &&
        order.local_id === id
    );

    if (orderItem) {
      const newQuantity = orderItem.quantity + 1;

      setIsLoading(true);
      try {
        const response = await axios({
          method: "put",
          url: IncreaseKOTGeneratedItemQuantityURL,
          data: { id: orderItem.KOT_id, product_id: itemId, newQuantity: newQuantity, tableId: selectedTable, is_bill: orderItem.isSaved, local_id: orderItem.local_id, orederId: orderList[selectedTable]?.orderId, },
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${token}`,
          },
        });

        if (response.status === 200 && response.data.message) {
          addToast("success", response.data.message);
          updateQuantityInList(itemId, id, newQuantity, table);
          if (viewKOT) {
            handleKOTClick();
          }
        }
      } catch (error) {
        if (error.response && error.response.data.message) {
          addToast("error", error.response.data.message);
        } else {
          addToast("error", error.message);
        }
        console.log(error);
      }
      setIsLoading(false);
    }
  };

  const handleMinusClick = (itemId, id, table) => {
    playSound();
    const orderItem = (orderList[table]?.orders || []).find(
      (order) =>
        order.id === itemId &&
        order.fooder_id === fooderId &&
        order.local_id === id
    );

    if (orderItem && orderItem.quantity > 1) {
      if (orderItem.quantity > orderItem.min_order_quantity) {
        const newQuantity = orderItem.quantity - 1;
        updateQuantityInList(itemId, id, newQuantity, table);
      }

    }
  };

  const handleMinusClickAfterKOT = async (itemId, id, table) => {
    playSound();
    const orderItem = (orderList[table]?.orders || []).find(
      (order) =>
        order.id === itemId &&
        order.fooder_id === fooderId &&
        order.local_id === id
    );

    if (orderItem && orderItem.quantity > 1) {
      if (orderItem.quantity > orderItem.min_order_quantity) {


        const newQuantity = orderItem.quantity - 1;

        setIsLoading(true);
        try {
          const response = await axios({
            method: "put",
            url: DecreaseKOTGeneratedItemQuantityURL,
            data: { id: orderItem.KOT_id, product_id: itemId, newQuantity: newQuantity, tableId: selectedTable, is_bill: orderItem.isSaved, local_id: orderItem.local_id, orederId: orderList[selectedTable]?.orderId, },

            headers: {
              "Content-Type": "application/json",
              authorization: `Bearer ${token}`,
            },
          });

          if (response.status === 200 && response.data.message) {
            addToast("success", response.data.message);
            updateQuantityInList(itemId, id, newQuantity, table);
            if (viewKOT) {
              handleKOTClick();
            }
          }
        } catch (error) {
          if (error.response && error.response.data.message) {
            addToast("error", error.response.data.message);
          } else {
            addToast("error", error.message);
          }
          console.log(error);
        }
        setIsLoading(false);
      }
    }
  };

  const deleteOrderItem = (itemId, id, table, order_deleted) => {
    const updatedOrderList = { ...orderList };

    if (updatedOrderList[table] && updatedOrderList[table].orders) {
      updatedOrderList[table].orders = updatedOrderList[table].orders.filter(
        (order) =>
          !(
            order.id === itemId &&
            order.fooder_id === fooderId &&
            order.local_id === id
          )
      );

      if (order_deleted) {
        updatedOrderList[table].orderId = ""
        updatedOrderList[table].orderNo = ""
        updatedOrderList[table].isPrinted = false

      }

      localStorage.setItem("orderList", JSON.stringify(updatedOrderList));
      setOrderList(updatedOrderList);
      setFlag(!flag);
    }
  };

  const handleDeleteClick = (itemId, id, table) => {
    playSound();
    deleteOrderItem(itemId, id, table, false);
  };

  const isValidNumber = (value) => {
    return !isNaN(parseFloat(value)) && isFinite(value);
  };

  const updateInstructions = (value) => {
    const updatedOrderList = { ...orderList };
    if (updatedOrderList[selectedTable]) {
      updatedOrderList[selectedTable].instructions = value;
    }
    localStorage.setItem("orderList", JSON.stringify(updatedOrderList));
    setOrderList(updatedOrderList);
    setFlag(!flag);
  };

  const handleSaveCustomerSuggetions = (e) => {
    const inputValue = e.target.value;

    if (inputValue.length <= maxCharacterLimit) {
      setCustomerSuggestion(inputValue);
      updateInstructions(inputValue);
    }
  };

  const handleSaveProductNote = (e) => {
    const inputValue = e.target.value;

    if (inputValue.length <= maxCharacterLimit) {
      setProductNote(inputValue);
    }
  };

  const handleKOTItemDeleteReason = (e) => {
    const inputValue = e.target.value;

    if (inputValue.length <= maxCharacterLimit) {
      setDeleteReason(inputValue);
    }
  };

  const handleKOTItemDeleteClick = (item) => {
    playSound();
    setDeleteKOTItem(item);
    setDeleteReason("");
    setIsKOTItemDeleteModal(true);
  };

  const handleKOTItemDelete = async (e) => {
    e.preventDefault();

    if (deleteReason === "" || deleteReason.trim() === "") {
      addToast("error", "Please enter reason first");
    } else {

      let totalKotItem = 0;
      const orders = orderList[selectedTable]?.orders ?? [];
      totalKotItem = orders.reduce((count, order) => count + (order?.isKOT ? 1 : 0), 0);



      const dataToSend = {
        kot_id: deleteKOTItem.KOT_id,
        tableId: selectedTable,
        is_booked: totalKotItem > 1 ? 1 : 0,
        is_bill: deleteKOTItem.isSaved,
        orederId: orderList[selectedTable]?.orderId,
        local_id: deleteKOTItem.local_id,
        remove_item: {
          product_id: deleteKOTItem.id,
          reason: deleteReason,
          local_id: deleteKOTItem.local_id
        },
        deleteKOTItem: deleteKOTItem,

      };

      try {
        const response = await axios({
          method: "put",
          url: DeleteKOTGeneratedItemURL,
          data: dataToSend,
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${token}`,
          },
        });

        if (response.status === 200 && response.data.message) {
          addToast("success", response.data.message);

          if (response.data.code && response.data.code === "ORDER_DELETED") {
            deleteOrderItem(deleteKOTItem.id, deleteKOTItem.local_id, deleteKOTItem.table_id, true);
          } else {
            deleteOrderItem(deleteKOTItem.id, deleteKOTItem.local_id, deleteKOTItem.table_id, false);
          }

          setIsKOTItemDeleteModal(false);
          if (viewKOT) {
            handleKOTClick();
          }
        }
      } catch (error) {
        if (error.response && error.response.data.message) {
          addToast("error", error.response.data.message);
        } else {
          addToast("error", error.message);
        }
        console.log(error);
      }
    }
  };

  const updateStaff = (value) => {
    const updatedOrderList = { ...orderList };
    if (updatedOrderList[selectedTable]) {
      updatedOrderList[selectedTable].staff = value;
    }
    localStorage.setItem("orderList", JSON.stringify(updatedOrderList));
    setOrderList(updatedOrderList);
    setFlag(!flag);
  };

  const handleAssignWaiter = (selectedOption) => {
    setSelectedWaiter(selectedOption);
    updateStaff(selectedOption);
  };

  const handleDiscountValuesChange = (event) => {
    const { name, value } = event.target;

    setDiscountValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleApplyDiscountClick = (e) => {
    e.preventDefault();
    if (cartProductSubTotal === 0) {
      addToast("error", "Please add items first to apply discount");
    } else if (!isValidNumber(discountValues.rate)) {
      addToast("error", "Please enter a valid number");
    } else if (
      discountValues.type <= 0 &&
      parseFloat(discountValues.rate) > 100
    ) {
      addToast("error", "Discount rate cannot exceed 100%");
    } else if (
      discountValues.type === "1" &&
      // parseInt(discountValues.rate) > subTotal
      parseInt(discountValues.rate) > cartProductSubTotal

    ) {
      addToast("error", "Discount rate cannot exceed subtotal");
    } else {
      const updatedOrderList = { ...orderList };
      if (
        updatedOrderList[selectedTable] &&
        updatedOrderList[selectedTable].discountValues
      ) {
        updatedOrderList[selectedTable].discountValues = {
          type: discountValues.type,
          rate: discountValues.rate <= 0 ? "0" : (parseFloat(discountValues.rate).toFixed(2)).toString(),
        };
      }
      localStorage.setItem("orderList", JSON.stringify(updatedOrderList));
      setOrderList(updatedOrderList);
      setFlag(!flag);
      addToast("success", "Discount applied successfully");
      setDiscountValues({
        type: "0",
        rate: "0",
      });
      setActiveTab("tables");
    }
  };

  const handlePaymentOptionChange = (event) => {
    playSound();
    // setOnlySavePaymentRoundUpAmount(0)
    // setOnlySavePaymentRoundUpCheck(0)
    setSelectedPaymentOption(event.target.value);
    setCashPaymentDetails({
      customerPaid: "",
      returnToCustomer: "0",
      tip: "0",
      amount: "",
      transaction_id: ""
    });
    setCardPaymentDetails({
      customerPaid: "",
      tip: "0",
      note: "",
      amount: "",
      transaction_id: ""
    });
    setNeftPaymentDetails({
      customerPaid: "",
      tip: "0",
      note: "",
      amount: "",
      transaction_id: ""
    });
    setUpiPaymentDetails({
      method: "",
      other_method: "",
      tip: "0",
      note: "",
      amount: "",
      transaction_id: ""
    });
    setUnpaidReason("");
    setPaymentSettleTransactionId("");
  };

  const clearTableData = (table) => {
    const storedOrderList = JSON.parse(localStorage.getItem("orderList")) || {};

    if (storedOrderList[table]) {
      delete storedOrderList[table];
    }

    localStorage.setItem("orderList", JSON.stringify(storedOrderList));

    setFlag(!flag);
  };

  // function isAnyItemKOTForTable(tableNo) {
  //   if (orderList[tableNo]) {
  //     const orders = orderList[tableNo].orders;

  //     for (let i = 0; i < orders.length; i++) {
  //       const order = orders[i];

  //       if (order.isKOT) {
  //         return true;
  //       }
  //     }
  //     return false;
  //   }
  //   return false;
  // }

  function isAnyItemKOTForTable(tableNo) {
    if (orderList && orderList[tableNo] && orderList[tableNo].orders) {
      const orders = orderList[tableNo].orders;

      if (orders && orders.length > 0) {
        for (let i = 0; i < orders.length; i++) {
          const order = orders[i];

          if (order && order.isKOT) {
            return true;
          }
        }
      }
    }
    return false;
  }

  function isAnyItemKOTNotGengeratedForTable(tableNo) {
    if (orderList[tableNo]) {
      const orders = orderList[tableNo].orders;

      for (let i = 0; i < orders.length; i++) {
        const order = orders[i];

        if (!order.isKOT) {
          return true;
        }
      }
      return false;
    }
    return false;
  }

  async function UpdatedSavedOrderItems(tableNo) {
    const updatedOrderList = { ...orderList };
    if (updatedOrderList[tableNo]) {
      const orders = updatedOrderList[tableNo].orders;

      for (let i = 0; i < orders.length; i++) {
        const order = orders[i];

        if (!order.isSaved) {
          order.isSaved = true;
        }
      }
    }

    localStorage.setItem("orderList", JSON.stringify(updatedOrderList));
    setOrderList(updatedOrderList);
    setFlag(!flag);
  }

  async function updateKOTForTable(tableNo) {
    const currentTimeMillis = new Date().getTime();
    const updatedOrderList = { ...orderList };

    if (isAnyItemKOTNotGengeratedForTable(tableNo)) {
      let dataToSend;
      const ordersWithoutKOT = updatedOrderList[tableNo].orders.filter(
        (order) => order.isKOT === false
      );

      const storedOrderList = JSON.parse(localStorage.getItem("orderList")) || {};
      let temp_kot = []
      storedOrderList[selectedTable]?.orders.forEach((item) => {
        if (item.KOT_id) {
          temp_kot.push(item.KOT_id)
        }
      });

      if (orderType === "DELIVERY" || orderType === "TAKE AWAY") {
        dataToSend = ordersWithoutKOT.map((item) => {
          const {
            newPrice,
            table_id,
            isKOT,
            KOT_id,
            KOT_no,
            KOT_time,
            id,
            name,
            price,
            proprice,
            ...rest
          } = item;
          return {
            ...rest,
            product_id: id,
            product_name: name,
            product_price: price,
            product_proprice: proprice,
            order_type: orderType,
            all_kots: temp_kot,
            table_id: 0

          };
        });
      } else {
        dataToSend = ordersWithoutKOT.map((item) => {
          const {
            newPrice,
            isKOT,
            KOT_id,
            KOT_no,
            KOT_time,
            id,
            name,
            price,
            proprice,
            ...rest
          } = item;
          return {
            ...rest,
            product_id: id,
            product_name: name,
            product_price: price,
            product_proprice: proprice,
            order_type: orderType,
            order_id: orderList[selectedTable]?.orderId,
            all_kots: temp_kot,
            table_id: selectedTable

          };
        });
      }


      setIsLoading(true);
      try {
        const response = await axios({
          method: "post",
          url: GenerateKOTURL,
          data: dataToSend,
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${token}`,
          },
        });

        if (response.status === 200 && response.data.message) {


          addToast("success", response.data.message);
          if (updatedOrderList[tableNo]) {
            const orders = updatedOrderList[tableNo].orders;

            for (let i = 0; i < orders.length; i++) {
              const order = orders[i];

              if (!order.isKOT) {
                order.isKOT = true;
                order.KOT_id = response.data.id;
                order.KOT_no = response.data.KOT_no;
                order.KOT_time = currentTimeMillis;
              }
            }
          }

          localStorage.setItem("orderList", JSON.stringify(updatedOrderList));
          setOrderList(updatedOrderList);
          setFlag(!flag);
          // if (orderList[selectedTable]?.orderId) {
          //   document.getElementById('save_bill_button').click()
          // }
        }
      } catch (error) {
        if (error.response && error.response.data.message) {
          addToast("error", error.response.data.message);
        } else {
          addToast("error", error.message);
        }
        console.log(error);
      }
      setIsLoading(false);
    } else {
      addToast("error", "Please add new items to generate KOT");
    }
  }

  async function updateAndPrintKOTForTable(tableNo) {
    const currentTimeMillis = new Date().getTime();
    const updatedOrderList = { ...orderList };

    if (isAnyItemKOTNotGengeratedForTable(tableNo)) {
      let dataToSend;
      const ordersWithoutKOT = updatedOrderList[tableNo].orders.filter(
        (order) => order.isKOT === false
      );
      const storedOrderList = JSON.parse(localStorage.getItem("orderList")) || {};
      let temp_kot = []
      storedOrderList[selectedTable]?.orders.forEach((item) => {
        if (item.KOT_id) {
          temp_kot.push(item.KOT_id)
        }
      });

      if (orderType === "DELIVERY" || orderType === "TAKE AWAY") {
        dataToSend = ordersWithoutKOT.map((item) => {
          const {
            newPrice,
            table_id,
            isKOT,
            KOT_id,
            KOT_no,
            KOT_time,
            id,
            name,
            price,
            proprice,
            ...rest
          } = item;
          return {
            ...rest,
            product_id: id,
            product_name: name,
            product_price: price,
            product_proprice: proprice,
            order_type: orderType,
            all_kots: temp_kot,
            table_id: 0
          };
        });
      } else {
        dataToSend = ordersWithoutKOT.map((item) => {
          const {
            newPrice,
            isKOT,
            KOT_id,
            KOT_no,
            KOT_time,
            id,
            name,
            price,
            proprice,
            ...rest
          } = item;
          return {
            ...rest,
            product_id: id,
            product_name: name,
            product_price: price,
            product_proprice: proprice,
            order_type: orderType,
            order_id: orderList[selectedTable]?.orderId,
            all_kots: temp_kot,
            table_id: selectedTable

          };
        });
      }


      setIsLoading(true);
      try {
        const response = await axios({
          method: "post",
          url: GenerateKOTURL,
          data: dataToSend,
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${token}`,
          },
        });

        if (response.status === 200 && response.data.message) {




          if (updatedOrderList[tableNo]) {
            const orders = updatedOrderList[tableNo].orders;

            for (let i = 0; i < orders.length; i++) {
              const order = orders[i];

              if (!order.isKOT) {
                order.isKOT = true;
                order.KOT_id = response.data.id;
                order.KOT_no = response.data.KOT_no;
                order.KOT_time = currentTimeMillis;
              }
            }
          }
          setPrintKOTDetails((prev) => ({
            ...prev,
            KOT_no: response.data.KOT_no,
            KOT_items: dataToSend,
          }));

          localStorage.setItem("orderList", JSON.stringify(updatedOrderList));
          setOrderList(updatedOrderList);
          setFlag(!flag);
          if (orderList[selectedTable]?.orderId) {
            document.getElementById('save_bill_button').click()
          }
        }
      } catch (error) {
        if (error.response && error.response.data.message) {
          addToast("error", error.response.data.message);
        } else {
          addToast("error", error.message);
        }
        console.log(error);
      }
      setIsLoading(false);
    } else {
      addToast("error", "Please add new items to generate KOT");
    }
  }

  const handleChangeKOTStatus = async (e, id, status) => {
    e.preventDefault();

    const data = { id: id, status: status };

    try {
      const response = await axios({
        method: "put",
        url: UpdateKOTStatusURL,
        data: data,
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200 && response.data.message) {
        await handleKOTClick();
        addToast("success", response.data.message);
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        addToast("error", error.response.data.message);
      } else {
        addToast("error", error.message);
      }
      console.log(error);
    }
  };

  async function updateIsPrinted(tableNo, isPrintedValue) {
    const updatedOrderList = { ...orderList };

    if (updatedOrderList && updatedOrderList[tableNo]) {
      updatedOrderList[tableNo].isPrinted = isPrintedValue;

      localStorage.setItem("orderList", JSON.stringify(updatedOrderList));
      setOrderList(updatedOrderList);
      setFlag(!flag);
    }
  }

  async function updateOrderIdAndNo(tableNo, id, number, date, invoice_number) {
    // setInvoiceNumber(invoice_number)

    // const invoiceNumberElement = document.getElementById("invoice_number_on_bill");
    // if (invoiceNumberElement) {
    //   invoiceNumberElement.innerHTML = invoice_number;
    // }


    const invoiceNumberElements = document.getElementsByClassName("invoice_number_on_bill");
    for (let i = 0; i < invoiceNumberElements.length; i++) {
      invoiceNumberElements[i].innerHTML = invoice_number;
    }

    // localStorage.setItem('invoice_number_on_bill', invoice_number)

    let updatedOrderList = JSON.parse(localStorage.getItem("orderList"));

    if (updatedOrderList && updatedOrderList[tableNo]) {
      updatedOrderList[tableNo].orderId = id;
      updatedOrderList[tableNo].orderNo = number;
      updatedOrderList[tableNo].creation_date = date;
      updatedOrderList[tableNo].invoice_number = invoice_number;

      // console.log(updatedOrderList[tableNo])
      // updatedOrderList[tableNo].fore
      updatedOrderList[tableNo].orders.forEach((i) => {
        i.orderId = id
      })

      localStorage.setItem("orderList", JSON.stringify(updatedOrderList));
      setOrderList(updatedOrderList);
      setFlag(!flag);
    }
  }

  const handlePrintBill = async () => {
    printJS({
      printable: "contentToPrintBill",
      type: "html",
      targetStyles: ["*"],
      font_size: '8pt'
    });


  };

  // const handleSettleAndSaveForCashPayment = async (e) => {
  //   e.preventDefault();

  //   const mobileNumberPattern = /^\d{10}$/;

  //   if (!selectedTable) {
  //     addToast("error", "Please Select Table first !");
  //   } else {
  //     if (orderType === "DELIVERY" || orderType === "TAKE AWAY") {
  //       if (cashPaymentDetails.customerPaid === "") {
  //         addToast("error", "Paid amount can not be empty");
  //       } else if (!isValidNumber(cashPaymentDetails.customerPaid)) {
  //         addToast("error", "Please enter valid Paid amount");
  //       } else if (
  //         cashPaymentDetails.tip &&
  //         !isValidNumber(cashPaymentDetails.tip)
  //       ) {
  //         addToast("error", "Please enter valid Tip amount");
  //       } else if (orderType === "DELIVERY" && customerForm.name === "") {
  //         addToast("error", "Customer Name can not be empty");
  //       } else if (customerForm.name.length > 30) {
  //         addToast("error", "Name can not be not be more 30 characters");
  //         setActiveTab("customer");
  //       } else if (orderType === "DELIVERY" && customerForm.mobile === "") {
  //         addToast("error", "Customer Mobile Number can not be empty");
  //       } else if (
  //         customerForm.mobile &&
  //         !mobileNumberPattern.test(customerForm.mobile)
  //       ) {
  //         addToast("error", "Please Enter valid Customer Mobile Number");
  //       } else if (orderType === "DELIVERY" && customerForm.address === "") {
  //         addToast("error", "Customer address can not be empty");
  //       } else if (orderType === "DELIVERY" && selectedWaiter === null) {
  //         addToast("error", "Please Select Delivery Guy");
  //       } else {
  //         if (isAnyItemKOTNotGengeratedForTable(selectedTable)) {
  //           if (orderList[selectedTable].isPrinted) {
  //             await updateAndPrintKOTForTable(selectedTable);
  //           } else {
  //             await updateKOTForTable(selectedTable);
  //           }
  //         }

  //         const modifiedDetails = filteredOrders.map((item) => {
  //           const { newPrice, id, name, price, proprice, ...rest } = item;
  //           return {
  //             ...rest,
  //             product_id: id,
  //             product_name: name,
  //             product_price: price,
  //             product_proprice: proprice,
  //           };
  //         });

  //         let newPaymentDetails;
  //         if (parseFloat(cashPaymentDetails.customerPaid) < parseFloat(cashPaymentDetails.amount)) {
  //           newPaymentDetails = {
  //             customerPaid: parseFloat(cashPaymentDetails.customerPaid),
  //             due: parseFloat(cashPaymentDetails.returnToCustomer),
  //             tip: parseFloat(cashPaymentDetails.tip),
  //             amount: parseFloat(cashPaymentDetails.amount),
  //           };
  //         } else {
  //           // newPaymentDetails = cashPaymentDetails;

  //           newPaymentDetails = {
  //             customerPaid: parseFloat(cashPaymentDetails.customerPaid),
  //             returnToCustomer: parseFloat(cashPaymentDetails.returnToCustomer),
  //             tip: parseFloat(cashPaymentDetails.tip),
  //             amount: parseFloat(cashPaymentDetails.amount),
  //           };

  //         }

  //         const dataToSend = {
  //           eater_name: customerForm.name,
  //           eater_phonenumber: customerForm.mobile,
  //           payment_type: selectedPaymentOption,
  //           eater_suggestions: customerSuggestion,
  //           address: customerForm.address,
  //           delivery_guy_id: selectedWaiter ? selectedWaiter.value : 0,
  //           table_id: selectedTable,
  //           order_type: orderType,
  //           subtotal: subTotal,
  //           service_charge: parseFloat(schAmount),
  //           tax_amount: parseFloat(cgstAmount) + parseFloat(sgstAmount),
  //           total: parseFloat(totalAmountPaid),
  //           discount_type: discountType,
  //           discount_rate: discountRate ? discountRate : "0",
  //           details: modifiedDetails,
  //           service_charge_details: data.service_charge_details,
  //           tax_details: data.tax_Details,
  //           payment_status: 1,
  //           payment_details: newPaymentDetails,
  //           transaction_id: paymentSettleTransactionId
  //         };

  //         try {
  //           const response = await axios({
  //             method: "post",
  //             url: SettleDeliveryAndPickUpOrderForCashPaymentURL,
  //             data: dataToSend,
  //             headers: {
  //               "Content-Type": "application/json",
  //               authorization: `Bearer ${token}`,
  //             },
  //           });
  //           if (response.status === 200 && response.data.message) {


  //             const invoiceNumberElement = document.getElementById("invoice_number_on_bill");
  //             if (invoiceNumberElement) {
  //               invoiceNumberElement.innerHTML = response.data.invoice_number;
  //             }

  //             addToast("success", response.data.message);
  //             if (viewKOT) {
  //               handleKOTClick();
  //             }
  //             if (orderList[selectedTable].isPrinted) {
  //               handlePrintBill();
  //               clearTableData(selectedTable);
  //             } else {
  //               clearTableData(selectedTable);
  //             }
  //             setSelectedPaymentOption("Cash");
  //             setFlag(!flag);
  //             setCustomerForm({
  //               name: "",
  //               address: "",
  //               mobile: "",
  //             });
  //             setNoOfPeople("1");
  //             setCustomerSuggestion("");
  //             setSelectedWaiter(null);
  //             setCashPaymentDetails({
  //               customerPaid: "",
  //               returnToCustomer: "0",
  //               tip: "0",
  //               amount: "",
  //               transaction_id: "",
  //             });
  //             setPaymentSettleTransactionId("");

  //             setOrderList([]);
  //             setActiveTab("tables");
  //             setIsSettleModal(false);
  //           }
  //         } catch (error) {
  //           if (error.response && error.response.data.message) {
  //             addToast("error", error.response.data.message);
  //           } else {
  //             addToast("error", error.message);
  //           }
  //           console.log(error);
  //         }
  //       }
  //     } else {
  //       if (!orderList[selectedTable]?.orderId) {
  //         addToast("error", "Please save order first");
  //       } else {
  //         if (cashPaymentDetails.customerPaid === "") {
  //           addToast("error", "Paid amount can not be empty");
  //         } else if (!isValidNumber(cashPaymentDetails.customerPaid)) {
  //           addToast("error", "Please enter valid Paid amount");
  //         } else if (
  //           cashPaymentDetails.tip &&
  //           !isValidNumber(cashPaymentDetails.tip)
  //         ) {
  //           addToast("error", "Please enter valid Tip amount");
  //         } else {
  //           let newPaymentDetails;
  //           if (parseFloat(cashPaymentDetails.customerPaid) < parseFloat(cashPaymentDetails.amount)) {
  //             newPaymentDetails = {
  //               customerPaid: parseFloat(cashPaymentDetails.customerPaid),
  //               due: parseFloat(cashPaymentDetails.returnToCustomer),
  //               tip: parseFloat(cashPaymentDetails.tip),
  //               amount: parseFloat(cashPaymentDetails.amount),
  //             };

  //             //  console.log("newPaymentDetails")
  //             //  console.log(newPaymentDetails)



  //           } else {
  //             // newPaymentDetails = cashPaymentDetails;

  //             newPaymentDetails = {
  //               customerPaid: parseFloat(cashPaymentDetails.customerPaid),
  //               returnToCustomer: parseFloat(cashPaymentDetails.returnToCustomer),
  //               tip: parseFloat(cashPaymentDetails.tip),
  //               amount: parseFloat(cashPaymentDetails.amount),
  //             };
  //           }

  //           const dataToSend = {
  //             id: orderList[selectedTable].orderId,
  //             subtotal: subTotal,
  //             service_charge: parseFloat(schAmount),
  //             tax_amount: parseFloat(cgstAmount) + parseFloat(sgstAmount),
  //             total: parseFloat(totalAmountPaid),
  //             discount_type: discountType,
  //             discount_rate: discountRate ? discountRate : "0",
  //             payment_type: selectedPaymentOption,
  //             payment_status: 1,
  //             payment_details: newPaymentDetails,
  //             transaction_id: paymentSettleTransactionId
  //           };

  //           try {
  //             const response = await axios({
  //               method: "post",
  //               url: SettleDineOrderForCashPaymentURL,
  //               data: dataToSend,
  //               headers: {
  //                 "Content-Type": "application/json",
  //                 authorization: `Bearer ${token}`,
  //               },
  //             });

  //             if (response.status === 200 && response.data.message) {
  //               addToast("success", response.data.message);
  //               if (viewKOT) {
  //                 handleKOTClick();
  //               }
  //               clearTableData(selectedTable);
  //               setSelectedPaymentOption("Cash");
  //               setCustomerForm({
  //                 name: "",
  //                 address: "",
  //                 mobile: "",
  //               });
  //               setNoOfPeople("1");
  //               setCustomerSuggestion("");
  //               setSelectedWaiter(null);
  //               setCashPaymentDetails({
  //                 customerPaid: "",
  //                 returnToCustomer: "0",
  //                 tip: "0",
  //                 amount: "",
  //                 transaction_id: "",
  //               });
  //               setPaymentSettleTransactionId("");
  //               setActiveTab("tables");
  //               if (isSettleModal) {
  //                 handleTableView();
  //               }
  //               setIsSettleModal(false);
  //             }
  //           } catch (error) {
  //             if (error.response && error.response.data.message) {
  //               addToast("error", error.response.data.message);
  //             } else {
  //               addToast("error", error.message);
  //             }
  //             console.log(error);
  //           }
  //         }
  //       }
  //     }
  //   }
  // };

  // const handleSettleAndSaveForCardPayment = async (e) => {
  //   e.preventDefault();

  //   const mobileNumberPattern = /^\d{10}$/;

  //   if (!selectedTable) {
  //     addToast("error", "Please Select Table first !");
  //   } else {
  //     if (orderType === "DELIVERY" || orderType === "TAKE AWAY") {
  //       if (cardPaymentDetails.customerPaid === "") {
  //         addToast("error", "Paid amount can not be empty");
  //       } else if (!isValidNumber(cardPaymentDetails.customerPaid)) {
  //         addToast("error", "Please enter valid Paid amount");
  //       } else if (
  //         cardPaymentDetails.tip &&
  //         !isValidNumber(cardPaymentDetails.tip)
  //       ) {
  //         addToast("error", "Please enter valid Tip amount");
  //       } else if (orderType === "DELIVERY" && customerForm.name === "") {
  //         addToast("error", "Customer Name can not be empty");
  //       } else if (customerForm.name.length > 30) {
  //         addToast("error", "Name can not be not be more 30 characters");
  //         setActiveTab("customer");
  //       } else if (orderType === "DELIVERY" && customerForm.mobile === "") {
  //         addToast("error", "Customer Mobile Number can not be empty");
  //       } else if (
  //         customerForm.mobile &&
  //         !mobileNumberPattern.test(customerForm.mobile)
  //       ) {
  //         addToast("error", "Please Enter valid Customer Mobile Number");
  //       } else if (orderType === "DELIVERY" && customerForm.address === "") {
  //         addToast("error", "Customer address can not be empty");
  //       } else if (orderType === "DELIVERY" && selectedWaiter === null) {
  //         addToast("error", "Please Select Delivery Guy");
  //       } else {
  //         if (isAnyItemKOTNotGengeratedForTable(selectedTable)) {
  //           if (orderList[selectedTable].isPrinted) {
  //             await updateAndPrintKOTForTable(selectedTable);
  //           } else {
  //             await updateKOTForTable(selectedTable);
  //           }
  //         }
  //         const modifiedDetails = filteredOrders.map((item) => {
  //           const { newPrice, id, name, price, proprice, ...rest } = item;
  //           return {
  //             ...rest,
  //             product_id: id,
  //             product_name: name,
  //             product_price: price,
  //             product_proprice: proprice,
  //           };
  //         });

  //         const dataToSend = {
  //           eater_name: customerForm.name,
  //           eater_phonenumber: customerForm.mobile,
  //           payment_type: selectedPaymentOption,
  //           eater_suggestions: customerSuggestion,
  //           address: customerForm.address,
  //           delivery_guy_id: selectedWaiter ? selectedWaiter.value : 0,
  //           table_id: selectedTable,
  //           order_type: orderType,
  //           subtotal: subTotal,
  //           service_charge: parseFloat(schAmount),
  //           tax_amount: parseFloat(cgstAmount) + parseFloat(sgstAmount),
  //           total: parseFloat(totalAmountPaid),
  //           discount_type: discountType,
  //           discount_rate: discountRate ? discountRate : "0",
  //           details: modifiedDetails,
  //           service_charge_details: data.service_charge_details,
  //           tax_details: data.tax_Details,
  //           payment_status: 1,
  //           payment_details: cardPaymentDetails,
  //           transaction_id: paymentSettleTransactionId
  //         };

  //         try {
  //           const response = await axios({
  //             method: "post",
  //             url: SettleDeliveryAndPickUpOrderForCashPaymentURL,
  //             data: dataToSend,
  //             headers: {
  //               "Content-Type": "application/json",
  //               authorization: `Bearer ${token}`,
  //             },
  //           });
  //           if (response.status === 200 && response.data.message) {
  //             // console.log("*******************************")

  //             addToast("success", response.data.message);
  //             if (viewKOT) {
  //               handleKOTClick();
  //             }
  //             if (orderList[selectedTable].isPrinted) {
  //               handlePrintBill();
  //               clearTableData(selectedTable);
  //             } else {
  //               clearTableData(selectedTable);
  //             }
  //             setSelectedPaymentOption("Cash");
  //             setCustomerForm({
  //               name: "",
  //               address: "",
  //               mobile: "",
  //             });
  //             setNoOfPeople("1");
  //             setCustomerSuggestion("");
  //             setSelectedWaiter(null);
  //             setCardPaymentDetails({
  //               customerPaid: "",
  //               tip: "0",
  //               note: "",
  //               amount: "",
  //               transaction_id: ""
  //             });
  //             setPaymentSettleTransactionId("");
  //             setOrderList([]);
  //             setActiveTab("tables");
  //             setIsSettleModal(false);
  //           }
  //         } catch (error) {
  //           if (error.response && error.response.data.message) {
  //             addToast("error", error.response.data.message);
  //           } else {
  //             addToast("error", error.message);
  //           }
  //           console.log(error);
  //         }
  //       }
  //     } else {
  //       if (!orderList[selectedTable]?.orderId) {
  //         addToast("error", "Please save order first");
  //       } else {
  //         if (cardPaymentDetails.customerPaid === "") {
  //           addToast("error", "Paid amount can not be empty");
  //         } else if (!isValidNumber(cardPaymentDetails.customerPaid)) {
  //           addToast("error", "Please enter valid Paid amount");
  //         } else if (
  //           cardPaymentDetails.tip &&
  //           !isValidNumber(cardPaymentDetails.tip)
  //         ) {
  //           addToast("error", "Please enter valid Tip amount");
  //         } else {
  //           const dataToSend = {
  //             id: orderList[selectedTable].orderId,
  //             subtotal: subTotal,
  //             service_charge: parseFloat(schAmount),
  //             tax_amount: parseFloat(cgstAmount) + parseFloat(sgstAmount),
  //             total: parseFloat(totalAmountPaid),
  //             discount_type: discountType,
  //             discount_rate: discountRate ? discountRate : "0",
  //             payment_type: selectedPaymentOption,
  //             payment_status: 1,
  //             payment_details: cardPaymentDetails,
  //             transaction_id: paymentSettleTransactionId
  //           };

  //           try {
  //             const response = await axios({
  //               method: "post",
  //               url: SettleDineOrderForCashPaymentURL,
  //               data: dataToSend,
  //               headers: {
  //                 "Content-Type": "application/json",
  //                 authorization: `Bearer ${token}`,
  //               },
  //             });

  //             if (response.status === 200 && response.data.message) {
  //               addToast("success", response.data.message);
  //               if (viewKOT) {
  //                 handleKOTClick();
  //               }
  //               clearTableData(selectedTable);
  //               setSelectedPaymentOption("Cash");
  //               setCustomerForm({
  //                 name: "",
  //                 address: "",
  //                 mobile: "",
  //               });
  //               setNoOfPeople("1");
  //               setCustomerSuggestion("");
  //               setSelectedWaiter(null);
  //               setCardPaymentDetails({
  //                 customerPaid: "",
  //                 tip: "0",
  //                 note: "",
  //                 amount: "",
  //                 transaction_id: ""
  //               });
  //               setPaymentSettleTransactionId("");
  //               setActiveTab("tables");
  //               if (isSettleModal) {
  //                 handleTableView();
  //               }
  //               setIsSettleModal(false);
  //             }
  //           } catch (error) {
  //             if (error.response && error.response.data.message) {
  //               addToast("error", error.response.data.message);
  //             } else {
  //               addToast("error", error.message);
  //             }
  //             console.log(error);
  //           }
  //         }
  //       }
  //     }
  //   }
  // };


  // const handleSettleAndSaveForNeftPayment = async (e) => {
  //   e.preventDefault();

  //   const mobileNumberPattern = /^\d{10}$/;

  //   if (!selectedTable) {
  //     addToast("error", "Please Select Table first !");
  //   } else {
  //     if (orderType === "DELIVERY" || orderType === "TAKE AWAY") {
  //       if (neftPaymentDetails.customerPaid === "") {
  //         addToast("error", "Paid amount can not be empty");
  //       } else if (!isValidNumber(neftPaymentDetails.customerPaid)) {
  //         addToast("error", "Please enter valid Paid amount");
  //       } else if (
  //         neftPaymentDetails.tip &&
  //         !isValidNumber(neftPaymentDetails.tip)
  //       ) {
  //         addToast("error", "Please enter valid Tip amount");
  //       } else if (orderType === "DELIVERY" && customerForm.name === "") {
  //         addToast("error", "Customer Name can not be empty");
  //       } else if (customerForm.name.length > 30) {
  //         addToast("error", "Name can not be not be more 30 characters");
  //         setActiveTab("customer");
  //       } else if (orderType === "DELIVERY" && customerForm.mobile === "") {
  //         addToast("error", "Customer Mobile Number can not be empty");
  //       } else if (
  //         customerForm.mobile &&
  //         !mobileNumberPattern.test(customerForm.mobile)
  //       ) {
  //         addToast("error", "Please Enter valid Customer Mobile Number");
  //       } else if (orderType === "DELIVERY" && customerForm.address === "") {
  //         addToast("error", "Customer address can not be empty");
  //       } else if (orderType === "DELIVERY" && selectedWaiter === null) {
  //         addToast("error", "Please Select Delivery Guy");
  //       } else {
  //         if (isAnyItemKOTNotGengeratedForTable(selectedTable)) {
  //           if (orderList[selectedTable].isPrinted) {
  //             await updateAndPrintKOTForTable(selectedTable);
  //           } else {
  //             await updateKOTForTable(selectedTable);
  //           }
  //         }
  //         const modifiedDetails = filteredOrders.map((item) => {
  //           const { newPrice, id, name, price, proprice, ...rest } = item;
  //           return {
  //             ...rest,
  //             product_id: id,
  //             product_name: name,
  //             product_price: price,
  //             product_proprice: proprice,
  //           };
  //         });

  //         const dataToSend = {
  //           eater_name: customerForm.name,
  //           eater_phonenumber: customerForm.mobile,
  //           payment_type: selectedPaymentOption,
  //           eater_suggestions: customerSuggestion,
  //           address: customerForm.address,
  //           delivery_guy_id: selectedWaiter ? selectedWaiter.value : 0,
  //           table_id: selectedTable,
  //           order_type: orderType,
  //           subtotal: subTotal,
  //           service_charge: parseFloat(schAmount),
  //           tax_amount: parseFloat(cgstAmount) + parseFloat(sgstAmount),
  //           total: parseFloat(totalAmountPaid),
  //           discount_type: discountType,
  //           discount_rate: discountRate ? discountRate : "0",
  //           details: modifiedDetails,
  //           service_charge_details: data.service_charge_details,
  //           tax_details: data.tax_Details,
  //           payment_status: 1,
  //           payment_details: neftPaymentDetails,
  //           transaction_id: paymentSettleTransactionId
  //         };

  //         try {
  //           const response = await axios({
  //             method: "post",
  //             url: SettleDeliveryAndPickUpOrderForCashPaymentURL,
  //             data: dataToSend,
  //             headers: {
  //               "Content-Type": "application/json",
  //               authorization: `Bearer ${token}`,
  //             },
  //           });
  //           if (response.status === 200 && response.data.message) {


  //             addToast("success", response.data.message);
  //             if (viewKOT) {
  //               handleKOTClick();
  //             }
  //             if (orderList[selectedTable].isPrinted) {
  //               handlePrintBill();
  //               clearTableData(selectedTable);
  //             } else {
  //               clearTableData(selectedTable);
  //             }
  //             setSelectedPaymentOption("Cash");
  //             setCustomerForm({
  //               name: "",
  //               address: "",
  //               mobile: "",
  //             });
  //             setNoOfPeople("1");
  //             setCustomerSuggestion("");
  //             setSelectedWaiter(null);
  //             setNeftPaymentDetails({
  //               customerPaid: "",
  //               tip: "0",
  //               note: "",
  //               amount: "",
  //               transaction_id: ""
  //             });
  //             setPaymentSettleTransactionId("");
  //             setOrderList([]);
  //             setActiveTab("tables");
  //             setIsSettleModal(false);
  //           }
  //         } catch (error) {
  //           if (error.response && error.response.data.message) {
  //             addToast("error", error.response.data.message);
  //           } else {
  //             addToast("error", error.message);
  //           }
  //           console.log(error);
  //         }
  //       }
  //     } else {
  //       if (!orderList[selectedTable]?.orderId) {
  //         addToast("error", "Please save order first");
  //       } else {
  //         if (neftPaymentDetails.customerPaid === "") {
  //           addToast("error", "Paid amount can not be empty");
  //         } else if (!isValidNumber(neftPaymentDetails.customerPaid)) {
  //           addToast("error", "Please enter valid Paid amount");
  //         } else if (
  //           neftPaymentDetails.tip &&
  //           !isValidNumber(neftPaymentDetails.tip)
  //         ) {
  //           addToast("error", "Please enter valid Tip amount");
  //         } else {
  //           const dataToSend = {
  //             id: orderList[selectedTable].orderId,
  //             subtotal: subTotal,
  //             service_charge: parseFloat(schAmount),
  //             tax_amount: parseFloat(cgstAmount) + parseFloat(sgstAmount),
  //             total: parseFloat(totalAmountPaid),
  //             discount_type: discountType,
  //             discount_rate: discountRate ? discountRate : "0",
  //             payment_type: selectedPaymentOption,
  //             payment_status: 1,
  //             payment_details: neftPaymentDetails,
  //             transaction_id: paymentSettleTransactionId
  //           };

  //           try {
  //             const response = await axios({
  //               method: "post",
  //               url: SettleDineOrderForCashPaymentURL,
  //               data: dataToSend,
  //               headers: {
  //                 "Content-Type": "application/json",
  //                 authorization: `Bearer ${token}`,
  //               },
  //             });

  //             if (response.status === 200 && response.data.message) {
  //               addToast("success", response.data.message);
  //               if (viewKOT) {
  //                 handleKOTClick();
  //               }
  //               clearTableData(selectedTable);
  //               setSelectedPaymentOption("Cash");
  //               setCustomerForm({
  //                 name: "",
  //                 address: "",
  //                 mobile: "",
  //               });
  //               setNoOfPeople("1");
  //               setCustomerSuggestion("");
  //               setSelectedWaiter(null);
  //               setNeftPaymentDetails({
  //                 customerPaid: "",
  //                 tip: "0",
  //                 note: "",
  //                 amount: "",
  //                 transaction_id: ""
  //               });
  //               setPaymentSettleTransactionId("");

  //               setActiveTab("tables");
  //               if (isSettleModal) {
  //                 handleTableView();
  //               }
  //               setIsSettleModal(false);
  //             }
  //           } catch (error) {
  //             if (error.response && error.response.data.message) {
  //               addToast("error", error.response.data.message);
  //             } else {
  //               addToast("error", error.message);
  //             }
  //             console.log(error);
  //           }
  //         }
  //       }
  //     }
  //   }
  // }

  // const handleSettleAndSaveForUPIPayment = async (e) => {
  //   e.preventDefault();

  //   const mobileNumberPattern = /^\d{10}$/;

  //   if (!selectedTable) {
  //     addToast("error", "Please Select Table first !");
  //   } else {
  //     if (orderType === "DELIVERY" || orderType === "TAKE AWAY") {
  //       if (upiPaymentDetails.method === "") {
  //         addToast("error", "Please select method");
  //       } else if (
  //         upiPaymentDetails.method === "Other" &&
  //         upiPaymentDetails.other_method.trim() === ""
  //       ) {
  //         addToast("error", "Please enter the other UPI method");
  //       } else if (
  //         upiPaymentDetails.tip &&
  //         !isValidNumber(upiPaymentDetails.tip)
  //       ) {
  //         addToast("error", "Please enter valid Tip amount");
  //       } else if (orderType === "DELIVERY" && customerForm.name === "") {
  //         addToast("error", "Customer Name can not be empty");
  //       } else if (customerForm.name.length > 30) {
  //         addToast("error", "Name can not be not be more 30 characters");
  //         setActiveTab("customer");
  //       } else if (orderType === "DELIVERY" && customerForm.mobile === "") {
  //         addToast("error", "Customer Mobile Number can not be empty");
  //       } else if (
  //         customerForm.mobile &&
  //         !mobileNumberPattern.test(customerForm.mobile)
  //       ) {
  //         addToast("error", "Please Enter valid Customer Mobile Number");
  //       } else if (orderType === "DELIVERY" && customerForm.address === "") {
  //         addToast("error", "Customer address can not be empty");
  //       } else if (orderType === "DELIVERY" && selectedWaiter === null) {
  //         addToast("error", "Please Select Delivery Guy");
  //       } else {
  //         if (isAnyItemKOTNotGengeratedForTable(selectedTable)) {
  //           if (orderList[selectedTable].isPrinted) {
  //             await updateAndPrintKOTForTable(selectedTable);
  //           } else {
  //             await updateKOTForTable(selectedTable);
  //           }
  //         }
  //         const modifiedDetails = filteredOrders.map((item) => {
  //           const { newPrice, id, name, price, proprice, ...rest } = item;
  //           return {
  //             ...rest,
  //             product_id: id,
  //             product_name: name,
  //             product_price: price,
  //             product_proprice: proprice,
  //           };
  //         });

  //         const dataToSend = {
  //           eater_name: customerForm.name,
  //           eater_phonenumber: customerForm.mobile,
  //           payment_type: selectedPaymentOption,
  //           eater_suggestions: customerSuggestion,
  //           address: customerForm.address,
  //           delivery_guy_id: selectedWaiter ? selectedWaiter.value : 0,
  //           table_id: selectedTable,
  //           order_type: orderType,
  //           subtotal: subTotal,
  //           service_charge: parseFloat(schAmount),
  //           tax_amount: parseFloat(cgstAmount) + parseFloat(sgstAmount),
  //           total: parseFloat(totalAmountPaid),
  //           discount_type: discountType,
  //           discount_rate: discountRate ? discountRate : "0",
  //           details: modifiedDetails,
  //           service_charge_details: data.service_charge_details,
  //           tax_details: data.tax_Details,
  //           payment_status: 1,
  //           payment_details: upiPaymentDetails,
  //           transaction_id: paymentSettleTransactionId
  //         };

  //         try {
  //           const response = await axios({
  //             method: "post",
  //             url: SettleDeliveryAndPickUpOrderForCashPaymentURL,
  //             data: dataToSend,
  //             headers: {
  //               "Content-Type": "application/json",
  //               authorization: `Bearer ${token}`,
  //             },
  //           });
  //           if (response.status === 200 && response.data.message) {
  //             addToast("success", response.data.message);
  //             if (viewKOT) {
  //               handleKOTClick();
  //             }
  //             if (orderList[selectedTable].isPrinted) {
  //               handlePrintBill();
  //               clearTableData(selectedTable);
  //             } else {
  //               clearTableData(selectedTable);
  //             }
  //             setSelectedPaymentOption("Cash");
  //             setCustomerForm({
  //               name: "",
  //               address: "",
  //               mobile: "",
  //             });
  //             setNoOfPeople("1");
  //             setCustomerSuggestion("");
  //             setSelectedWaiter(null);
  //             setUpiPaymentDetails({
  //               method: "",
  //               other_method: "",
  //               tip: "0",
  //               note: "",
  //               amount: "",
  //               transaction_id: ""
  //             });
  //             setPaymentSettleTransactionId("");
  //             setOrderList([]);
  //             setActiveTab("tables");
  //             setIsSettleModal(false);
  //           }
  //         } catch (error) {
  //           if (error.response && error.response.data.message) {
  //             addToast("error", error.response.data.message);
  //           } else {
  //             addToast("error", error.message);
  //           }
  //           console.log(error);
  //         }
  //       }
  //     } else {
  //       if (!orderList[selectedTable]?.orderId) {
  //         addToast("error", "Please save order first");
  //       } else {
  //         if (upiPaymentDetails.method === "") {
  //           addToast("error", "Please select method");
  //         } else if (
  //           upiPaymentDetails.method === "Other" &&
  //           upiPaymentDetails.other_method.trim() === ""
  //         ) {
  //           addToast("error", "Please enter the other UPI method");
  //         } else if (
  //           upiPaymentDetails.tip &&
  //           !isValidNumber(upiPaymentDetails.tip)
  //         ) {
  //           addToast("error", "Please enter valid Tip amount");
  //         } else {
  //           const dataToSend = {
  //             id: orderList[selectedTable].orderId,
  //             subtotal: subTotal,
  //             service_charge: parseFloat(schAmount),
  //             tax_amount: parseFloat(cgstAmount) + parseFloat(sgstAmount),
  //             total: parseFloat(totalAmountPaid),
  //             discount_type: discountType,
  //             discount_rate: discountRate ? discountRate : "0",
  //             payment_type: selectedPaymentOption,
  //             payment_status: 1,
  //             payment_details: upiPaymentDetails,
  //             transaction_id: paymentSettleTransactionId
  //           };

  //           try {
  //             const response = await axios({
  //               method: "post",
  //               url: SettleDineOrderForCashPaymentURL,
  //               data: dataToSend,
  //               headers: {
  //                 "Content-Type": "application/json",
  //                 authorization: `Bearer ${token}`,
  //               },
  //             });

  //             if (response.status === 200 && response.data.message) {
  //               addToast("success", response.data.message);
  //               if (viewKOT) {
  //                 handleKOTClick();
  //               }
  //               clearTableData(selectedTable);
  //               setSelectedPaymentOption("Cash");
  //               setCustomerForm({
  //                 name: "",
  //                 address: "",
  //                 mobile: "",
  //               });
  //               setNoOfPeople("1");
  //               setCustomerSuggestion("");
  //               setSelectedWaiter(null);
  //               setUpiPaymentDetails({
  //                 method: "",
  //                 other_method: "",
  //                 tip: "0",
  //                 note: "",
  //                 amount: "",
  //               });
  //               setPaymentSettleTransactionId("");
  //               setActiveTab("tables");
  //               if (isSettleModal) {
  //                 handleTableView();
  //               }
  //               setIsSettleModal(false);
  //             }
  //           } catch (error) {
  //             if (error.response && error.response.data.message) {
  //               addToast("error", error.response.data.message);
  //             } else {
  //               addToast("error", error.message);
  //             }
  //             console.log(error);
  //           }
  //         }
  //       }
  //     }
  //   }
  // };

  const handleSettleAndSaveForUnpaidPayment = async (e) => {
    e.preventDefault();

    const mobileNumberPattern = /^\d{10}$/;

    if (!selectedTable) {
      addToast("error", "Please Select Table first !");
    } else {
      if (orderType === "DELIVERY" || orderType === "TAKE AWAY") {
        if (orderType === "DELIVERY" && customerForm.name === "") {
          addToast("error", "Customer Name can not be empty");
        } else if (customerForm.name.length > 30) {
          addToast("error", "Name can not be not be more 30 characters");
          setActiveTab("customer");
        } else if (orderType === "DELIVERY" && customerForm.mobile === "") {
          addToast("error", "Customer Mobile Number can not be empty");
        } else if (
          customerForm.mobile &&
          !mobileNumberPattern.test(customerForm.mobile)
        ) {
          addToast("error", "Please Enter valid Customer Mobile Number");
        } else if (orderType === "DELIVERY" && customerForm.address === "") {
          addToast("error", "Customer address can not be empty");
        } else if (orderType === "DELIVERY" && selectedWaiter === null) {
          addToast("error", "Please Select Delivery Guy");
        } else if (unpaidReason === "" || unpaidReason.trim() === "") {
          addToast("error", "Please enter reason first");
        } else {
          if (isAnyItemKOTNotGengeratedForTable(selectedTable)) {
            if (orderList[selectedTable].isPrinted) {
              await updateAndPrintKOTForTable(selectedTable);
            } else {
              await updateKOTForTable(selectedTable);
            }
          }
          const modifiedDetails = filteredOrders.map((item) => {
            const { newPrice, id, name, price, proprice, ...rest } = item;
            return {
              ...rest,
              product_id: id,
              product_name: name,
              // product_price: price,
              // product_proprice: proprice,
              product_price: newPrice,
              product_proprice: "",
            };
          });


          var tax_details = [];
          if (CGSTTaxT1 > 0) {
            tax_details.push({ "name": "CGST", "percentage": 0, "amount": CGSTTaxT1 });
          }
          if (SGSTTaxT1 > 0) {
            tax_details.push({ "name": "SGST", "percentage": 0, "amount": SGSTTaxT1 });
          }
          if (CGSTTaxT2 > 0) {
            tax_details.push({ "name": "CGST", "percentage": 2.5, "amount": CGSTTaxT2 });
          }
          if (SGSTTaxT2 > 0) {
            tax_details.push({ "name": "SGST", "percentage": 2.5, "amount": SGSTTaxT2 });
          }
          if (CGSTTaxT3 > 0) {
            tax_details.push({ "name": "CGST", "percentage": 6, "amount": CGSTTaxT3 });
          }
          if (SGSTTaxT3 > 0) {
            tax_details.push({ "name": "SGST", "percentage": 6, "amount": SGSTTaxT3 });
          }
          if (CGSTTaxT4 > 0) {
            tax_details.push({ "name": "CGST", "percentage": 9, "amount": CGSTTaxT4 });
          }
          if (SGSTTaxT4 > 0) {
            tax_details.push({ "name": "SGST", "percentage": 9, "amount": SGSTTaxT4 });
          }

          if (CGSTTaxT5 > 0) {
            tax_details.push({ "name": "CGST", "percentage": 14, "amount": CGSTTaxT5 });
          }
          if (SGSTTaxT5 > 0) {
            tax_details.push({ "name": "SGST", "percentage": 14, "amount": CGSTTaxT5 });
          }

          const dataToSend = {
            eater_name: customerForm.name,
            eater_phonenumber: customerForm.mobile,
            payment_type: selectedPaymentOption,
            eater_suggestions: customerSuggestion,
            address: customerForm.address,
            delivery_guy_id: selectedWaiter ? selectedWaiter.value : 0,
            table_id: selectedTable,
            order_type: orderType,
            // subtotal: subTotal,
            // service_charge: parseFloat(schAmount),
            // tax_amount: parseFloat(cgstAmount) + parseFloat(sgstAmount),
            // total: parseFloat(totalAmountPaid),

            subtotal: cartProductSubTotal,
            service_charge: parseFloat(cartProductServiceCharge),
            tax_amount: cartProductTax,
            total: orderType !== "DINE IN" ? parseFloat(cartProductTotal) + parseFloat(cartProductPackingCharges) : parseFloat(cartProductTotal),

            discount_type: discountType,
            discount_rate: discountRate ? discountRate : "0",
            details: modifiedDetails,
            service_charge_details: data.service_charge_details,
            // tax_details: data.tax_Details,
            tax_details: tax_details,

            unpaid_reason: unpaidReason,
          };
          setIsLoading(true)

          try {
            const response = await axios({
              method: "post",
              url: SettleDeliveryAndPickUpOrderForUnpaidPaymentURL,
              data: dataToSend,
              headers: {
                "Content-Type": "application/json",
                authorization: `Bearer ${token}`,
              },
            });
            if (response.status === 200 && response.data.message) {
              addToast("success", response.data.message);
              const invoiceNumberElements = document.getElementsByClassName("invoice_number_on_bill");
              for (let i = 0; i < invoiceNumberElements.length; i++) {
                invoiceNumberElements[i].innerHTML = response.data.invoice_number;
              }

              if (orderList[selectedTable].isPrinted) {
                handlePrintBill();
                clearTableData(selectedTable);
              } else {
                clearTableData(selectedTable);
              }
              setSelectedPaymentOption("Cash");
              setCustomerForm({
                name: "",
                address: "",
                mobile: "",
              });
              setNoOfPeople("1");
              setCustomerSuggestion("");
              setSelectedWaiter(null);
              setOrderList([]);
              setActiveTab("tables");
              setIsSettleModal(false);
            }
            setIsLoading(false)
          } catch (error) {
            if (error.response && error.response.data.message) {
              addToast("error", error.response.data.message);
            } else {
              addToast("error", error.message);
            }
            console.log(error);
            setIsLoading(false)

          }
        }
      } else {
        if (!orderList[selectedTable]?.orderId) {
          addToast("error", "Please save order first");
        } else {
          if (unpaidReason === "" || unpaidReason.trim() === "") {
            addToast("error", "Please enter reason first");
          } else {
            const dataToSend = {
              id: orderList[selectedTable].orderId,
              subtotal: subTotal,
              service_charge: parseFloat(schAmount),
              tax_amount: parseFloat(cgstAmount) + parseFloat(sgstAmount),
              total: parseFloat(totalAmountPaid),
              discount_type: discountType,
              discount_rate: discountRate ? discountRate : "0",
              payment_type: selectedPaymentOption,
              unpaid_reason: unpaidReason,
              payment_status: 2,

            };
            setIsLoading(true)

            try {
              const response = await axios({
                method: "post",
                url: SettleDineOrderForCashPaymentURL,
                data: dataToSend,
                headers: {
                  "Content-Type": "application/json",
                  authorization: `Bearer ${token}`,
                },
              });

              if (response.status === 200 && response.data.message) {
                addToast("success", response.data.message);
                if (viewKOT) {
                  handleKOTClick();
                }
                clearTableData(selectedTable);
                setSelectedPaymentOption("Cash");
                setCustomerForm({
                  name: "",
                  address: "",
                  mobile: "",
                });
                setNoOfPeople("1");
                setCustomerSuggestion("");
                setSelectedWaiter(null);
                setActiveTab("tables");
                if (isSettleModal) {
                  handleTableView();
                }
                setIsSettleModal(false);

                let tempTableDetailsByCategory = tableDetailsByCategory.map(floor => {
                  floor.table_categoryName_data.forEach(table => {
                    if (table.id === selectedTable) {
                      table.kot_committed = 0;
                      table.pos_committed = 0;
                      table.qr_committed = 0;
                      // console.log(table)
                    }
                  });
                  return floor;
                });

                setTableDetailsByCategory(tempTableDetailsByCategory);

              }
              setIsLoading(false)

            } catch (error) {
              if (error.response && error.response.data.message) {
                addToast("error", error.response.data.message);
              } else {
                addToast("error", error.message);
              }
              console.log(error);
              setIsLoading(false)

            }
          }
        }
      }
    }
  };






  const [onlySavePaymentData, setSavePaymentData] = useState([]);


  const [onlySavePaymentTotalPaidedAmount, setOnlySavePaymentTotalPaidedAmount] = useState(0);
  const [onlySavePaymentPaidedAmount, setOnlySavePaymentPaidedAmount] = useState(0);

  const [onlySavePaymentTipAmount, setOnlySavePaymentTipAmount] = useState(0);
  const [onlySavePaymentTipTotalAmount, setOnlySavePaymentTipTotalAmount] = useState(0);

  const [onlySavePaymentNotes, setOnlySavePaymentNotes] = useState("");

  const [onlySavePaymentRoundUpAmount, setOnlySavePaymentRoundUpAmount] = useState(0);
  const [onlySavePaymentRoundUpCheck, setOnlySavePaymentRoundUpCheck] = useState(false);


  // useEffect(() => {
  //   if (onlySavePaymentRoundUpCheck) {
  //   } else {
  //     setOnlySavePaymentRoundUpAmount(0)
  //     setOnlySavePaymentPaidedAmount(parseFloat(totalAmountPaid - onlySavePaymentTotalPaidedAmount).toFixed(2))
  //   }
  // }, [onlySavePaymentRoundUpCheck]);

  // const handleRoundupAmountCheck = async (e) => {
  //   // alert(totalAmountPaid)
  //   var res = getDecimalPart(totalAmountPaid)
  //   // alert(res)
  //   console.log(e.target.checked)
  //   if (e.target.checked) {
  //     // setOnlySavePaymentRoundUpFixAmount(res)
  //     setOnlySavePaymentRoundUpAmount(res)
  //     setOnlySavePaymentPaidedAmount(parseFloat(totalAmountPaid) + res)
  //     // setOnlySavePaymentRoundUpCheck(true)

  //   } else {

  //     // setOnlySavePaymentRoundUpCheck(false)
  //   }

  // }

  function getDecimalPart(number) {
    // Extract the integer part using Math.floor() and subtract it from the original number
    const integerPart = Math.floor(number);
    let decimalPart = number - integerPart;
    // decimalPart = decimalPart.toFixed(2)
    if (decimalPart < 0.5) {
      return -decimalPart
    }
    else {
      return 1 - decimalPart
    }
  }


  const handleOnlySavePayment = async (e) => {
    // console.log("handleOnlySavePayment")
    playSound();
    let tempOnlySavePaymentRoundUpAmount = onlySavePaymentRoundUpAmount

    if (selectedPaymentOption === "UPI" && upiPaymentDetails.method === "") {

      addToast("error", "Select upi method");

    }
    else if (!isValidNumber(onlySavePaymentPaidedAmount)) {

      addToast("error", "Please enter valid Paid amount");

    }
    else if (!isValidNumber(onlySavePaymentTipAmount) && onlySavePaymentTipAmount !== '') {
      addToast("error", "Please enter valid Tip amount");
    }

    // else if (parseFloat(onlySavePaymentPaidedAmount).toFixed(2) > parseFloat(totalAmountPaid - onlySavePaymentTotalPaidedAmount).toFixed(2)) {
    else if ((parseFloat(onlySavePaymentPaidedAmount)).toFixed(2) > parseFloat(totalAmountPaid - onlySavePaymentTotalPaidedAmount) + (tempOnlySavePaymentRoundUpAmount) + 0.00001) {
      addToast("error", "Amount cannot be greater than Total Amount");
    }
    else {
      let tempAmount = parseFloat(onlySavePaymentPaidedAmount).toFixed(2)

      // if(tempOnlySavePaymentRoundUpAmount >= 0){
      //   tempAmount = (parseFloat(onlySavePaymentPaidedAmount) + (tempOnlySavePaymentRoundUpAmount)).toFixed(2)

      // }

      setSavePaymentData([...onlySavePaymentData, {
        method: selectedPaymentOption,
        amount: tempAmount,
        tip: onlySavePaymentTipAmount ? parseFloat(onlySavePaymentTipAmount).toFixed(2) : 0,
        upiType: upiPaymentDetails.method === "Other" ? upiPaymentDetails.other_method : upiPaymentDetails.method,
        note: onlySavePaymentNotes,
        transaction_id: paymentSettleTransactionId
      }])
      let temp = parseFloat(onlySavePaymentTotalPaidedAmount) + parseFloat(onlySavePaymentPaidedAmount)

      // alert(temp, onlySavePaymentPaidedAmount, onlySavePaymentTotalPaidedAmount)

      setOnlySavePaymentTotalPaidedAmount(temp)

      // setOnlySavePaymentPaidedAmount(totalAmountPaid - temp > 0 ? (totalAmountPaid - temp).value.toFixed(2) : 0)
      setOnlySavePaymentPaidedAmount(totalAmountPaid - temp > 0 ? parseFloat((totalAmountPaid - temp) + tempOnlySavePaymentRoundUpAmount).toFixed(2) : 0)


      let tempTip = onlySavePaymentTipAmount + onlySavePaymentTipTotalAmount
      setOnlySavePaymentTipTotalAmount(tempTip)
      setOnlySavePaymentTipAmount(0)
      setOnlySavePaymentNotes("")
      setPaymentSettleTransactionId("")


    }





    // console.log(onlySavePaymentData)


  }


  const handleOnlySavePaymentRemove = async (i, index) => {
    playSound();
    // console.log(index)
    let tempArray = onlySavePaymentData.filter((item, index) => index === i);
    // console.log(tempArray)
    // let tempRoundUpAmount = i === "0" ? onlySavePaymentRoundUpAmount : onlySavePaymentRoundUpAmount

    // alert(tempRoundUpAmount)

    setOnlySavePaymentPaidedAmount((parseFloat(totalAmountPaid) - parseFloat(onlySavePaymentTotalPaidedAmount) + parseFloat(tempArray[0].amount) + onlySavePaymentRoundUpAmount).toFixed(2))


    let temp = parseFloat(onlySavePaymentTotalPaidedAmount) - parseFloat(tempArray[0].amount)
    setOnlySavePaymentTotalPaidedAmount(temp)



    let myArray = onlySavePaymentData.filter((item, index) => index !== i);
    setSavePaymentData(myArray)





  }

  useEffect(() => {
    // setOnlySavePaymentPaidedAmount(totalAmountPaid - onlySavePaymentTotalPaidedAmount)

    if (onlySavePaymentRoundUpCheck) {
      setOnlySavePaymentPaidedAmount((parseFloat(totalAmountPaid) + parseFloat(getDecimalPart(totalAmountPaid))).toFixed(2))


    } else {
      setOnlySavePaymentPaidedAmount(parseFloat(totalAmountPaid).toFixed(2))

    }


    setSavePaymentData([]);

    setOnlySavePaymentTotalPaidedAmount(0)
    setOnlySavePaymentTipAmount(0)
    setOnlySavePaymentNotes("")
    setOnlySavePaymentTipTotalAmount(0)
    // setOnlySavePaymentRoundUpAmount(0)
    // setOnlySavePaymentRoundUpCheck(false)

    if (onlySavePaymentRoundUpCheck) {
      setOnlySavePaymentRoundUpAmount(getDecimalPart(totalAmountPaid))
    }



  }, [totalAmountPaid]);



  // ***********************************************************************************************************




  const submitpartialPayment = async () => {
    //   console.log("submitpartialPayment")
    //   playSound();


    //   const modifiedDetails = filteredOrders.map((item) => {
    //     const { newPrice, id, name, price, proprice, ...rest } = item;
    //     return {
    //       ...rest,
    //       product_id: id,
    //       product_name: name,
    //       product_price: price,
    //       product_proprice: proprice,
    //     };
    //   });

    //   const dataToSend = {
    //     eater_name: customerForm.name,
    //     eater_phonenumber: customerForm.mobile,

    //     payment_data: onlySavePaymentData,

    //     eater_suggestions: customerSuggestion,
    //     address: customerForm.address,
    //     delivery_guy_id: selectedWaiter ? selectedWaiter.value : 0,
    //     table_id: selectedTable,
    //     order_type: orderType,
    //     subtotal: subTotal,
    //     service_charge: parseFloat(schAmount),
    //     tax_amount: parseFloat(cgstAmount) + parseFloat(sgstAmount),
    //     total: parseFloat(totalAmountPaid),
    //     discount_type: discountType,
    //     discount_rate: discountRate ? discountRate : "0",
    //     details: modifiedDetails,
    //     service_charge_details: data.service_charge_details,
    //     tax_details: data.tax_Details,

    //   };


    //   try {
    //     const response = await axios({
    //       method: "post",
    //       url: partialPayment,
    //       data: dataToSend,
    //       headers: {
    //         "Content-Type": "application/json",
    //         authorization: `Bearer ${token}`,
    //       },
    //     });
    //     if (response.status === 200 && response.data.message) {


    //       addToast("success", response.data.message);
    //       if (viewKOT) {
    //         handleKOTClick();
    //       }
    //       if (orderList[selectedTable].isPrinted) {
    //         handlePrintBill();
    //         clearTableData(selectedTable);
    //       } else {
    //         clearTableData(selectedTable);
    //       }
    //       setSelectedPaymentOption("Cash");
    //       setCustomerForm({
    //         name: "",
    //         address: "",
    //         mobile: "",
    //       });
    //       setNoOfPeople("1");
    //       setCustomerSuggestion("");
    //       setSelectedWaiter(null);
    //       setCardPaymentDetails({
    //         customerPaid: "",
    //         tip: "0",
    //         note: "",
    //         amount: "",
    //       });
    //       setOrderList([]);
    //       setActiveTab("tables");
    //       setIsSettleModal(false);
    //     }
    //   } catch (error) {
    //     if (error.response && error.response.data.message) {
    //       addToast("error", error.response.data.message);
    //     } else {
    //       addToast("error", error.message);
    //     }
    //     console.log(error);
    //   }



    // e.preventDefault();

    const mobileNumberPattern = /^\d{10}$/;

    if (!selectedTable) {
      addToast("error", "Please Select Table first !");
    } else {

      if (orderType === "DELIVERY" || orderType === "TAKE AWAY") {
        if (orderType === "DELIVERY" && customerForm.name === "") {
          addToast("error", "Customer Name can not be empty");
        } else if (customerForm.name.length > 30) {
          addToast("error", "Name can not be not be more 30 characters");
          setActiveTab("customer");
        } else if (orderType === "DELIVERY" && customerForm.mobile === "") {
          addToast("error", "Customer Mobile Number can not be empty");
        } else if (
          customerForm.mobile &&
          !mobileNumberPattern.test(customerForm.mobile)
        ) {
          addToast("error", "Please Enter valid Customer Mobile Number");
        } else if (orderType === "DELIVERY" && customerForm.address === "") {
          addToast("error", "Customer address can not be empty");
        } else if (orderType === "DELIVERY" && selectedWaiter === null) {
          addToast("error", "Please Select Delivery Guy");
        } else {
          if (isAnyItemKOTNotGengeratedForTable(selectedTable)) {
            if (orderList[selectedTable].isPrinted) {
              await updateAndPrintKOTForTable(selectedTable);
            } else {
              await updateKOTForTable(selectedTable);
            }
          }
          const modifiedDetails = filteredOrders.map((item) => {
            const { newPrice, id, name, price, proprice, ...rest } = item;
            return {
              ...rest,
              product_id: id,
              product_name: name,
              // product_price: price,
              // product_proprice: proprice,
              product_price: newPrice,
              product_proprice: "",
            };
          });
          var tax_details = [];
          if (CGSTTaxT1 > 0) {
            tax_details.push({ "name": "CGST", "percentage": 0, "amount": CGSTTaxT1 });
          }
          if (SGSTTaxT1 > 0) {
            tax_details.push({ "name": "SGST", "percentage": 0, "amount": SGSTTaxT1 });
          }

          if (CGSTTaxT2 > 0) {
            tax_details.push({ "name": "CGST", "percentage": 2.5, "amount": CGSTTaxT2 });
          }
          if (SGSTTaxT2 > 0) {
            tax_details.push({ "name": "SGST", "percentage": 2.5, "amount": SGSTTaxT2 });
          }
          if (CGSTTaxT3 > 0) {
            tax_details.push({ "name": "CGST", "percentage": 6, "amount": CGSTTaxT3 });
          }
          if (SGSTTaxT3 > 0) {
            tax_details.push({ "name": "SGST", "percentage": 6, "amount": SGSTTaxT3 });
          }

          if (CGSTTaxT4 > 0) {
            tax_details.push({ "name": "CGST", "percentage": 9, "amount": CGSTTaxT4 });
          }
          if (SGSTTaxT4 > 0) {
            tax_details.push({ "name": "SGST", "percentage": 9, "amount": SGSTTaxT4 });
          }

          if (CGSTTaxT5 > 0) {
            tax_details.push({ "name": "CGST", "percentage": 14, "amount": CGSTTaxT5 });
          }
          if (SGSTTaxT5 > 0) {
            tax_details.push({ "name": "SGST", "percentage": 14, "amount": CGSTTaxT5 });
          }

          const dataToSend = {
            eater_name: customerForm.name,
            eater_phonenumber: customerForm.mobile,

            eater_suggestions: customerSuggestion,
            address: customerForm.address,
            delivery_guy_id: selectedWaiter ? selectedWaiter.value : 0,
            table_id: selectedTable,
            order_type: orderType,

            // subtotal: subTotal,
            // service_charge: parseFloat(schAmount),
            // tax_amount: parseFloat(cgstAmount) + parseFloat(sgstAmount),
            // total: parseFloat(totalAmountPaid),

            subtotal: cartProductSubTotal,
            service_charge: parseFloat(cartProductServiceCharge),
            tax_amount: cartProductTax,
            total: parseFloat(totalAmountPaid),



            discount_type: discountType,
            discount_rate: discountRate ? discountRate : "0",
            details: modifiedDetails,
            // service_charge_details: data.service_charge_details,
            service_charge_details: data.service_charge_details,

            // tax_details: data.tax_Details,
            tax_details: tax_details,


            payment_details: onlySavePaymentData,
            due_amount: totalAmountPaid - onlySavePaymentTotalPaidedAmount,
            paided_amount: onlySavePaymentTotalPaidedAmount,
            payment_status: parseFloat(totalAmountPaid - onlySavePaymentTotalPaidedAmount + onlySavePaymentRoundUpAmount).toFixed(2) > 0 ? 3 : 1,

            cart_packing_charges: orderType !== "DINE IN" ? cartProductPackingCharges : 0,

            round_up_amount: onlySavePaymentRoundUpAmount

          };

          setIsLoading(true)

          try {
            const response = await axios({
              method: "post",
              url: partialPaymentForDelivaryAndTakeAway,
              data: dataToSend,
              headers: {
                "Content-Type": "application/json",
                authorization: `Bearer ${token}`,
              },
            });
            if (response.status === 200 && response.data.message) {


              // const invoiceNumberElement = document.getElementById("invoice_number_on_bill");
              // if (invoiceNumberElement) {
              //   invoiceNumberElement.innerHTML = response.data.invoice_number;
              // }

              const invoiceNumberElements = document.getElementsByClassName("invoice_number_on_bill");
              for (let i = 0; i < invoiceNumberElements.length; i++) {
                invoiceNumberElements[i].innerHTML = response.data.invoice_number;
              }


              addToast("success", response.data.message);
              if (viewKOT) {
                handleKOTClick();
              }
              if (orderList[selectedTable].isPrinted) {
                handlePrintBill();
                clearTableData(selectedTable);
              } else {
                clearTableData(selectedTable);
              }
              setSelectedPaymentOption("Cash");
              setCustomerForm({
                name: "",
                address: "",
                mobile: "",
              });
              setNoOfPeople("1");
              setCustomerSuggestion("");
              setSelectedWaiter(null);

              setSavePaymentData([]);
              setOnlySavePaymentTotalPaidedAmount(0)
              setOnlySavePaymentTipAmount(0)
              setOnlySavePaymentNotes("")
              setOnlySavePaymentTipTotalAmount(0)

              // setOnlySavePaymentRoundUpCheck(false)
              setOnlySavePaymentRoundUpAmount(0)

              setOrderList([]);
              setActiveTab("tables");
              setIsSettleModal(false);

            }
            setIsLoading(false)

          } catch (error) {
            if (error.response && error.response.data.message) {
              addToast("error", error.response.data.message);
            } else {
              addToast("error", error.message);
            }
            console.log(error);
            setIsLoading(false)

          }
        }
      } else {

        if (!orderList[selectedTable]?.orderId) {
          addToast("error", "Please save order first");
        } else {
          const modifiedDetails = filteredOrders.map((item) => {
            const { newPrice, id, name, price, proprice, ...rest } = item;
            return {
              product_id: id,
              product_name: name,
            };
          });
          const dataToSend = {

            id: orderList[selectedTable].orderId,
            subtotal: subTotal,
            service_charge: parseFloat(schAmount),
            tax_amount: parseFloat(cgstAmount) + parseFloat(sgstAmount),

            discount_type: discountType,
            discount_rate: discountRate ? discountRate : "0",
            payment_details: onlySavePaymentData,
            due_amount: parseFloat(totalAmountPaid - onlySavePaymentTotalPaidedAmount).toFixed(2),

            paided_amount: parseFloat(onlySavePaymentTotalPaidedAmount).toFixed(2),
            total: parseFloat(totalAmountPaid).toFixed(2),
            payment_status: parseFloat(totalAmountPaid - onlySavePaymentTotalPaidedAmount + onlySavePaymentRoundUpAmount).toFixed(2) > 0 ? 3 : 1,
            round_up_amount: onlySavePaymentRoundUpAmount,
            item: modifiedDetails
          };

          setIsLoading(true)
          try {
            const response = await axios({
              method: "post",
              url: partialPaymentForDineIn,
              data: dataToSend,
              headers: {
                "Content-Type": "application/json",
                authorization: `Bearer ${token}`,
              },
            });

            if (response.status === 200 && response.data.message) {
              addToast("success", response.data.message);
              if (viewKOT) {
                handleKOTClick();
              }
              clearTableData(selectedTable);
              setSelectedPaymentOption("Cash");
              setCustomerForm({
                name: "",
                address: "",
                mobile: "",
              });
              setNoOfPeople("1");
              setCustomerSuggestion("");
              setSelectedWaiter(null);



              setSavePaymentData([]);
              setOnlySavePaymentTotalPaidedAmount(0)
              setOnlySavePaymentTipAmount(0)
              setOnlySavePaymentNotes("")
              setOnlySavePaymentTipTotalAmount(0)

              // setOnlySavePaymentRoundUpCheck(false)
              setOnlySavePaymentRoundUpAmount(0)



              setActiveTab("tables");
              if (isSettleModal) {
                handleTableView();
              }
              setIsSettleModal(false);

              let tempTableDetailsByCategory = tableDetailsByCategory.map(floor => {
                floor.table_categoryName_data.forEach(table => {
                  if (table.id === selectedTable) {
                    table.kot_committed = 0;
                    table.pos_committed = 0;
                    table.qr_committed = 0;
                    // console.log(table)
                  }
                });
                return floor;
              });

              setTableDetailsByCategory(tempTableDetailsByCategory);





              setIsLoading(false)

            }
          } catch (error) {
            if (error.response && error.response.data.message) {
              addToast("error", error.response.data.message);
            } else {
              addToast("error", error.message);
            }
            console.log(error);
            setIsLoading(false)

          }

        }

      }

    }

  }









  // ***********************************************************************************************************

  const handleSaveBillClick = async (e) => {

    // console.log("dataToSend *************** 1")


    // e.preventDefault();
    const mobileNumberPattern = /^\d{10}$/;

    if (orderType === "DELIVERY" || orderType === "TAKE AWAY") {
      if (orderType === "DELIVERY" && customerForm.name === "") {
        addToast("error", "Customer details are mandatory");
        setActiveTab("customer");
      } else if (customerForm.name.length > 30) {
        addToast("error", "Name can not be not be more 30 characters");
        setActiveTab("customer");
      } else if (orderType === "DELIVERY" && customerForm.mobile === "") {
        addToast("error", "Customer details are mandatory");
        setActiveTab("customer");
      } else if (
        customerForm.mobile &&
        !mobileNumberPattern.test(customerForm.mobile)
      ) {
        addToast("error", "Please Enter valid Customer Mobile Number");
        setActiveTab("customer");
      } else if (orderType === "DELIVERY" && customerForm.address === "") {
        addToast("error", "Customer details are mandatory");
        setActiveTab("customer");
      } else if (orderType === "DELIVERY" && selectedWaiter === null) {
        addToast("error", "Please Select Delivery Guy");
        setActiveTab("customer");
      } else {
        setActiveTab("payment");
        return;
      }
    } else if (orderList[selectedTable].orderId) {
      if (isAnyItemKOTNotGengeratedForTable(selectedTable)) {
        await updateKOTForTable(selectedTable);
      }
      const modifiedDetails = filteredOrders.map((item) => {
        const { newPrice, id, name, price, proprice, ...rest } = item;
        return {
          ...rest,
          product_id: id,
          product_name: name,
          product_price: price,
          product_proprice: proprice,
        };
      });

      const dataToSend = {
        order_id: orderList[selectedTable].orderId,
        subtotal: subTotal,
        // service_charge: parseFloat(schAmount),
        service_charge: cartProductServiceCharge,
        tax_amount: parseFloat(cgstAmount) + parseFloat(sgstAmount),
        total: parseFloat(totalAmountPaid),
        discount_type: discountType,
        discount_rate: discountRate ? discountRate : "0",
        details: modifiedDetails,

      };

      // console.log("dataToSend ***************")
      // console.log(dataToSend)

      setIsLoading(true)


      try {
        setIsLoading(true)

        const response = await axios({
          method: "post",
          url: UpdateSaveOrderItemsURL,
          data: dataToSend,
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${token}`,
          },
        });

        if (response.status === 200 && response.data.message) {
          addToast("success", response.data.message);
          await UpdatedSavedOrderItems(selectedTable);
          setSelectedPaymentOption("Cash");
          setCustomerForm({
            name: "",
            address: "",
            mobile: "",
          });
          setNoOfPeople("1");
          setCustomerSuggestion("");
          setSelectedWaiter(null);
          setActiveTab("payment");

          // if (orderList[selectedTable]?.orderId) {

          //   orderList[selectedTable].orders.forEach((i) => {
          //     if (!i.orderId) {
          //       i.orderId = orderList[selectedTable].orderId
          //     }
          //   })
          // }

          let updatedOrderList = JSON.parse(localStorage.getItem("orderList"));
          if (updatedOrderList && updatedOrderList[selectedTable]) {
            updatedOrderList[selectedTable].orders.forEach((i) => {
              i.orderId = orderList[selectedTable]?.orderId
            })
            localStorage.setItem("orderList", JSON.stringify(updatedOrderList));
            setOrderList(updatedOrderList);
          }
        }
        setIsLoading(false)

      } catch (error) {
        setIsLoading(false)

        if (error.response && error.response.data.message) {
          addToast("error", error.response.data.message);
        } else {
          addToast("error", error.message);
        }
        console.log(error);
      }
    } else {
      const mobileNumberPattern = /^\d{10}$/;

      if (
        customerForm.mobile &&
        !mobileNumberPattern.test(customerForm.mobile)
      ) {
        addToast("error", "Please Enter valid Customer Mobile Number");
        setActiveTab("customer");
      } else if (orderType === "DINE IN" && noOfPeople === "") {
        addToast("error", "Please add number of people's on table !");
        setActiveTab("customer");
      } else if (orderType === "DINE IN" && !isValidNumber(noOfPeople)) {
        addToast("error", "Please enter a valid number of people's on table !");
        setActiveTab("customer");
      } else {
        if (isAnyItemKOTNotGengeratedForTable(selectedTable)) {
          await updateKOTForTable(selectedTable);
        }
        // alert("dd")
        const modifiedDetails = filteredOrders.map((item) => {
          const { newPrice, id, name, price, proprice, ...rest } = item;
          return {
            ...rest,
            product_id: id,
            product_name: name,
            // product_price: price,
            // product_proprice: proprice,
            product_price: newPrice,
            product_proprice: '',
          };
        });


        var tax_details = [];
        if (CGSTTaxT1 > 0) {
          tax_details.push({ "name": "CGST", "percentage": 0, "amount": CGSTTaxT1 });
        }
        if (SGSTTaxT1 > 0) {
          tax_details.push({ "name": "SGST", "percentage": 0, "amount": SGSTTaxT1 });
        }

        if (CGSTTaxT2 > 0) {
          tax_details.push({ "name": "CGST", "percentage": 2.5, "amount": CGSTTaxT2 });
        }
        if (SGSTTaxT2 > 0) {
          tax_details.push({ "name": "SGST", "percentage": 2.5, "amount": SGSTTaxT2 });
        }

        if (CGSTTaxT3 > 0) {
          tax_details.push({ "name": "CGST", "percentage": 6, "amount": CGSTTaxT3 });
        }
        if (SGSTTaxT3 > 0) {
          tax_details.push({ "name": "SGST", "percentage": 6, "amount": SGSTTaxT3 });
        }

        if (CGSTTaxT4 > 0) {
          tax_details.push({ "name": "CGST", "percentage": 9, "amount": CGSTTaxT4 });
        }
        if (SGSTTaxT4 > 0) {
          tax_details.push({ "name": "SGST", "percentage": 9, "amount": SGSTTaxT4 });
        }

        if (CGSTTaxT5 > 0) {
          tax_details.push({ "name": "CGST", "percentage": 14, "amount": CGSTTaxT5 });
        }
        if (SGSTTaxT5 > 0) {
          tax_details.push({ "name": "SGST", "percentage": 14, "amount": CGSTTaxT5 });
        }


        const dataToSend = {
          eater_name: customerForm.name,
          eater_phonenumber: customerForm.mobile,
          no_of_eaters: noOfPeople,
          payment_type: selectedPaymentOption,
          eater_suggestions: customerSuggestion,
          address: customerForm.address,
          waiter_id: selectedWaiter ? selectedWaiter.value : 0,
          table_id: selectedTable,
          order_type: orderType,
          subtotal: cartProductSubTotal,

          // service_charge: parseFloat(schAmount),
          // tax_amount: parseFloat(cgstAmount) + parseFloat(sgstAmount),

          service_charge: parseFloat(cartProductServiceCharge),
          tax_amount: cartProductTax,

          total: orderType !== "DINE IN" ? parseFloat(cartProductTotal) + parseFloat(cartProductPackingCharges) : parseFloat(cartProductTotal),
          discount_type: discountType,
          discount_rate: discountRate ? discountRate : "0",
          details: modifiedDetails,
          service_charge_details: data.service_charge_details,
          // tax_details: data.tax_Details,
          tax_details: tax_details,

          cart_packing_charges: orderType !== "DINE IN" ? cartProductPackingCharges : 0, // update 12/03/2023

        };

        // console.log("dataToSend===>", JSON.stringify(dataToSend));
        // console.log("dataToSend===>", dataToSend);

        try {
          setIsLoading(true)
          const response = await axios({
            method: "post",
            url: AddProceedOrderURL,
            data: dataToSend,
            headers: {
              "Content-Type": "application/json",
              authorization: `Bearer ${token}`,
            },
          });

          if (response.status === 200 && response.data.message) {
            addToast("success", response.data.message);
            await updateIsPrinted(selectedTable, true);
            await UpdatedSavedOrderItems(selectedTable);
            await updateOrderIdAndNo(
              selectedTable,
              response.data.order_id,
              response.data.order_number,
              response.data.creation_date,
              response.data.invoice_number
            );

            if (orderType === "DELIVERY") {
              clearTableData("dl");
            } else if (orderType === "TAKE AWAY") {
              clearTableData(selectedTable);
            }
            setSelectedPaymentOption("Cash");
            setCustomerForm({
              name: "",
              address: "",
              mobile: "",
            });
            setNoOfPeople("1");
            setCustomerSuggestion("");
            setSelectedWaiter(null);
            setActiveTab("payment");
          }
          setIsLoading(false)

        } catch (error) {
          setIsLoading(false)

          if (error.response && error.response.data.message) {
            addToast("error", error.response.data.message);
          } else {
            addToast("error", error.message);
          }
          console.log(error);
        }
      }
    }
  };

  const handleSaveAndPrintBillClick = async (e) => {
    e.preventDefault();
    const mobileNumberPattern = /^\d{10}$/;

    if (orderType === "DELIVERY" || orderType === "TAKE AWAY") {
      if (orderType === "DELIVERY" && customerForm.name === "") {
        addToast("error", "Customer details are mandatory");
        setActiveTab("customer");
      } else if (customerForm.name.length > 30) {
        addToast("error", "Name can not be not be more 30 characters");
        setActiveTab("customer");
      } else if (orderType === "DELIVERY" && customerForm.mobile === "") {
        addToast("error", "Customer details are mandatory");
        setActiveTab("customer");
      } else if (
        customerForm.mobile &&
        !mobileNumberPattern.test(customerForm.mobile)
      ) {
        addToast("error", "Please Enter valid Customer Mobile Number");
        setActiveTab("customer");
      } else if (orderType === "DELIVERY" && customerForm.address === "") {
        addToast("error", "Customer details are mandatory");
        setActiveTab("customer");
      } else if (orderType === "DELIVERY" && selectedWaiter === null) {
        addToast("error", "Please Select Delivery Guy");
        setActiveTab("customer");
      } else {
        await updateIsPrinted(selectedTable, true);
        setActiveTab("payment");
        return;
      }
    } else if (orderList[selectedTable].orderId) {
      if (isAnyItemKOTNotGengeratedForTable(selectedTable)) {
        await updateKOTForTable(selectedTable);
      }
      const modifiedDetails = filteredOrders.map((item) => {
        const { newPrice, id, name, price, proprice, ...rest } = item;
        return {
          ...rest,
          product_id: id,
          product_name: name,
          product_price: price,
          product_proprice: proprice,
        };
      });

      const dataToSend = {
        order_id: orderList[selectedTable].orderId,
        subtotal: subTotal,
        service_charge: parseFloat(schAmount),
        tax_amount: parseFloat(cgstAmount) + parseFloat(sgstAmount),
        total: parseFloat(totalAmountPaid),
        discount_type: discountType,
        discount_rate: discountRate ? discountRate : "0",
        details: modifiedDetails,
      };

      try {
        const response = await axios({
          method: "post",
          url: UpdateSaveOrderItemsURL,
          data: dataToSend,
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${token}`,
          },
        });

        if (response.status === 200 && response.data.message) {
          addToast("success", response.data.message);
          await UpdatedSavedOrderItems(selectedTable);
          handlePrintBill();
          setSelectedPaymentOption("Cash");
          setCustomerForm({
            name: "",
            address: "",
            mobile: "",
          });
          setNoOfPeople("1");
          setCustomerSuggestion("");
          setSelectedWaiter(null);
          setActiveTab("payment");
        }
      } catch (error) {
        if (error.response && error.response.data.message) {
          addToast("error", error.response.data.message);
        } else {
          addToast("error", error.message);
        }
        console.log(error);
      }
    } else {
      const mobileNumberPattern = /^\d{10}$/;

      if (
        customerForm.mobile &&
        !mobileNumberPattern.test(customerForm.mobile)
      ) {
        addToast("error", "Please Enter valid Customer Mobile Number");
      } else if (orderType === "DINE IN" && noOfPeople === "") {
        addToast("error", "Please add number of people's on table !");
      } else if (orderType === "DINE IN" && !isValidNumber(noOfPeople)) {
        addToast("error", "Please enter a valid number of people's on table !");
      } else {
        if (isAnyItemKOTNotGengeratedForTable(selectedTable)) {
          await updateKOTForTable(selectedTable);
        }
        const modifiedDetails = filteredOrders.map((item) => {
          const { newPrice, id, name, price, proprice, ...rest } = item;
          return {
            ...rest,
            product_id: id,
            product_name: name,
            // product_price: price,
            // product_proprice: proprice,
            product_price: newPrice,
            product_proprice: '',
          };
        });

        // alert('dd')



        var tax_details = [];
        if (CGSTTaxT1 > 0) {
          tax_details.push({ "name": "CGST", "percentage": 0, "amount": CGSTTaxT1 });
        }
        if (SGSTTaxT1 > 0) {
          tax_details.push({ "name": "SGST", "percentage": 0, "amount": SGSTTaxT1 });
        }
        if (CGSTTaxT2 > 0) {
          tax_details.push({ "name": "CGST", "percentage": 2.5, "amount": CGSTTaxT2 });
        }
        if (SGSTTaxT2 > 0) {
          tax_details.push({ "name": "SGST", "percentage": 2.5, "amount": SGSTTaxT2 });
        }
        if (CGSTTaxT3 > 0) {
          tax_details.push({ "name": "CGST", "percentage": 6, "amount": CGSTTaxT3 });
        }
        if (SGSTTaxT3 > 0) {
          tax_details.push({ "name": "SGST", "percentage": 6, "amount": SGSTTaxT3 });
        }
        if (CGSTTaxT4 > 0) {
          tax_details.push({ "name": "CGST", "percentage": 9, "amount": CGSTTaxT4 });
        }
        if (SGSTTaxT4 > 0) {
          tax_details.push({ "name": "SGST", "percentage": 9, "amount": SGSTTaxT4 });
        }

        if (CGSTTaxT5 > 0) {
          tax_details.push({ "name": "CGST", "percentage": 14, "amount": CGSTTaxT5 });
        }
        if (SGSTTaxT5 > 0) {
          tax_details.push({ "name": "SGST", "percentage": 14, "amount": CGSTTaxT5 });
        }

        const dataToSend = {
          // eater_name: customerForm.name,
          // eater_phonenumber: customerForm.mobile,
          // no_of_eaters: noOfPeople,
          // payment_type: selectedPaymentOption,
          // eater_suggestions: customerSuggestion,
          // address: customerForm.address,
          // waiter_id: selectedWaiter ? selectedWaiter.value : 0,
          // table_id: selectedTable,
          // order_type: orderType,
          // subtotal: subTotal,
          // service_charge: parseFloat(schAmount),
          // tax_amount: parseFloat(cgstAmount) + parseFloat(sgstAmount),
          // total: parseFloat(totalAmountPaid),
          // discount_type: discountType,
          // discount_rate: discountRate ? discountRate : "0",
          // details: modifiedDetails,
          // service_charge_details: data.service_charge_details,
          // tax_details: data.tax_Details,



          eater_name: customerForm.name,
          eater_phonenumber: customerForm.mobile,
          no_of_eaters: noOfPeople,
          payment_type: selectedPaymentOption,
          eater_suggestions: customerSuggestion,
          address: customerForm.address,
          waiter_id: selectedWaiter ? selectedWaiter.value : 0,
          table_id: selectedTable,
          order_type: orderType,
          subtotal: cartProductSubTotal,
          service_charge: parseFloat(cartProductServiceCharge),
          tax_amount: cartProductTax,
          total: orderType !== "DINE IN" ? parseFloat(cartProductTotal) + parseFloat(cartProductPackingCharges) : parseFloat(cartProductTotal),
          discount_type: discountType,
          discount_rate: discountRate ? discountRate : "0",
          details: modifiedDetails,
          service_charge_details: data.service_charge_details,
          tax_details: tax_details,
          cart_packing_charges: orderType !== "DINE IN" ? cartProductPackingCharges : 0, // update 12/03/2023
        };

        // console.log("dataToSend===>", JSON.stringify(dataToSend));
        // console.log("dataToSend===>", dataToSend);

        try {
          setIsLoading(true)
          const response = await axios({
            method: "post",
            url: AddProceedOrderURL,
            data: dataToSend,
            headers: {
              "Content-Type": "application/json",
              authorization: `Bearer ${token}`,
            },
          });

          if (response.status === 200 && response.data.message) {
            addToast("success", response.data.message);
            await updateIsPrinted(selectedTable, true);
            await UpdatedSavedOrderItems(selectedTable);
            await updateOrderIdAndNo(
              selectedTable,
              response.data.order_id,
              response.data.order_number,
              response.data.creation_date,
              response.data.invoice_number

            );

            handlePrintBill();

            if (orderType === "DELIVERY") {
              clearTableData("dl");
            } else if (orderType === "TAKE AWAY") {
              clearTableData(selectedTable);
            }
            setSelectedPaymentOption("Cash");
            setCustomerForm({
              name: "",
              address: "",
              mobile: "",
            });
            setNoOfPeople("1");
            setCustomerSuggestion("");
            setSelectedWaiter(null);
            setActiveTab("payment");
          }
          setIsLoading(false)

        } catch (error) {
          setIsLoading(false)

          if (error.response && error.response.data.message) {
            addToast("error", error.response.data.message);
          } else {
            addToast("error", error.message);
          }
          console.log(error);
        }
      }
    }
  };

  const handleKOTClick = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(GetKOTDetailsListURL, {
        headers: { authorization: `Bearer ${token}` },
      });

      if (response.status === 200 && response.data.data) {
        setDataKOTDetails(response.data.data);
        setViewKOT(true);
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        addToast("error", error.response.data.message);
      } else {
        addToast("error", error.message);
      }
      console.log(error);
    }
    setIsLoading(false);
  };

  function getCurrentTime() {
    const now = new Date();
    const hours = now.getHours();
    const minutes = now.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    const formattedHoursWithLeadingZero =
      formattedHours < 10 ? `0${formattedHours}` : formattedHours;

    return `${formattedHoursWithLeadingZero}:${formattedMinutes} ${ampm}`;
  }

  function getTotalAmountofTable(id) {
    if (orderList[id] && orderList[id].orders) {
      const amount = orderList[id].orders.reduce(
        (total, item) => total + item.newPrice * item.quantity,
        0
      );

      let discountType = orderList[id].discountValues.type;
      let discountRate = orderList[id].discountValues.rate;

      let discountedAmount = 0;
      let discount = 0;

      if (discountType === "0") {
        discount = (amount * (discountRate || 0)) / 100;
        discountedAmount = amount - discount;
      } else if (discountType === "1") {
        discount = discountRate;
        discountedAmount = amount - discount;
      } else {
        discountedAmount = amount;
      }

      const sch = (discountedAmount * (SCHRate || 0)) / 100;
      const taxableAmout = discountedAmount + sch;

      const cgst = (taxableAmout * (CGSTRate || 0)) / 100;
      const sgst = (taxableAmout * (SGSTRate || 0)) / 100;

      const totalPaid = taxableAmout + cgst + sgst;
      if (orderList[id].grandTotal) {

        if (onlySavePaymentRoundUpCheck) {
          return (parseFloat(orderList[id].grandTotal) + parseFloat(getDecimalPart(orderList[id].grandTotal))).toFixed(2)
        }
        return orderList[id].grandTotal;

      } else {


        return totalPaid.toFixed(2)
      }

    }
  }

  const [noOfBillPrinting, setNoOfBillPrinting] = useState(1);
  const [noOfBillPrintingArray, setNoOfBillPrintingArray] = useState([0]);

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/");
    }
  }, [navigate]);

  useEffect(() => {
    setToken(localStorage.getItem("token"));
    if (localStorage.getItem("no_of_bill_printing")) {
      setNoOfBillPrinting(localStorage.getItem("no_of_bill_printing"))
      // localStorage.setItem("round_off_amount", response.data.data.round_off_amount)
      // setOnlySavePaymentRoundUpAmount(true)
      if (localStorage.getItem('round_off_amount') === "1") {
        setOnlySavePaymentRoundUpCheck(true)
      }
    }

    if (localStorage.getItem("whatsapp_api_access_token")) {
      setWhatsappApiAccessToken(localStorage.getItem("whatsapp_api_access_token"));


    }
    if (localStorage.getItem("whatsapp_vendor_uid")) {
      setWhatsappApiVendorUid(localStorage.getItem("whatsapp_vendor_uid"));
    }
    if (localStorage.getItem("whatsapp_massageing_api_base_url")) {
      setWhatsappMassageingApiBaseUrl(localStorage.getItem("whatsapp_massageing_api_base_url"));
    }
    if (localStorage.getItem("whatsapp_massageing_api_url")) {
      setWhatsappMassageingApiUrl(localStorage.getItem("whatsapp_massageing_api_url"));
    }

    if (localStorage.getItem("upi_address")) {
      setFooderUpiId(localStorage.getItem("upi_address"));
    }
    if (localStorage.getItem("whatesapp_message")) {
      setSendWhatesappMessage(localStorage.getItem("whatesapp_message"));
    }



  }, [token]);


  useEffect(() => {
    let temp = []
    for (let i = 0; i < noOfBillPrinting; i++) {
      temp.push(i);
      // alert(noOfBillPrinting)
    }

    if (temp.length > 0) {
      setNoOfBillPrintingArray(temp)
      // console.log(temp)

    }


  }, [noOfBillPrinting]);

  useEffect(() => {
    const existingScript = document.getElementById(ScriptId);
    if (!existingScript && localStorage.getItem("userId")) {
      const userId = localStorage.getItem("userId");
      const script = document.createElement("script");
      script.src = ScriptSRC + userId.replace(/"/g, "");
      script.defer = true;
      script.id = ScriptId;
      document.head.appendChild(script);
    }
  }, []);

  useEffect(() => {
    if (localStorage.getItem("planDetails")) {
      setPlanDetails(JSON.parse(localStorage.getItem("planDetails")));
    }
  }, []);

  useEffect(() => {
    if (localStorage.getItem("currencySymbol")) {
      setCurrencySymbol(localStorage.getItem("currencySymbol"));
    }
  }, []);

  useEffect(() => {
    if (token) {

      getData();
    }
  }, [token, addToast]);

  async function getData() {
    setIsLoading(true);
    try {
      const response = await axios.get(GetPOSDetailsURL, {
        headers: { authorization: `Bearer ${token}` },
      });

      if (
        response.status === 200 &&
        response.data &&
        response.data.menus &&
        response.data.product
      ) {
        setData(response.data);
        setMenus(response.data.menus);
        setProducts(response.data.product);
        setCurrentMenu("all");
        setCurrentMenuProducts(response.data.product);
        setFooderId(response.data.fooder_id);
        setTableDetailsByCategory(response.data.tblCategoryDetails);


        if (
          response.data.waiterDetails &&
          response.data.waiterDetails.length !== 0
        ) {
          const options = response.data.waiterDetails.map((staff) => ({
            value: staff.id,
            label:
              staff.type === 0
                ? `${staff.name}(Waiter)`
                : staff.type === 3
                  ? `${staff.name}(Delivery Guy)`
                  : staff.name,
            name: staff.name,
            phone_number: staff.phone_number,
            email: staff.email,
            type: staff.type,
          }));

          setWaiterList(options);
        }

        if (
          response.data.service_charge_details.percentage !== "0" &&
          !isNaN(
            parseFloat(response.data.service_charge_details.percentage)
          ) &&
          isFinite(response.data.service_charge_details.percentage)
        ) {
          setSCHRate(
            parseFloat(response.data.service_charge_details.percentage)
          );
        }

        if (
          response.data.tax_Details &&
          response.data.tax_Details.length !== 0
        ) {
          response.data.tax_Details.forEach((item) => {
            if (item.name === "CGST") {
              setCGSTRate(item.percentage);
            } else if (item.name === "SGST") {
              setSGSTRate(item.percentage);
            }
          });
        }
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        addToast("error", error.response.data.message);
      } else {
        addToast("error", error.message);
      }
      console.log(error);
    }
    setIsLoading(false);
  }

  useEffect(() => {
    const storedOrderList = JSON.parse(localStorage.getItem("orderList")) || {};

    setOrderList(storedOrderList);
  }, [selectedTable, flag]);









  const [cartProductTotal, setCartProductTotal] = useState(0);

  const [cartProductPackingCharges, setCartProductPackingCharges] = useState(0);


  const [cartProductSubTotal, setCartProductSubTotal] = useState(0);
  const [cartProductServiceCharge, setCartProductServiceCharge] = useState(0);
  const [cartProductDiscount, setCartProductDiscount] = useState(0);

  const [cartProductTax, setCartProductTax] = useState(0); // total tax for bill api




  const [CGSTTaxT1, setCGSTTaxt1] = useState(0);
  const [CGSTTaxT2, setCGSTTaxt2] = useState(0);
  const [CGSTTaxT3, setCGSTTaxt3] = useState(0);
  const [CGSTTaxT4, setCGSTTaxt4] = useState(0);
  const [CGSTTaxT5, setCGSTTaxt5] = useState(0);


  const [SGSTTaxT1, setSGSTTaxt1] = useState(0);
  const [SGSTTaxT2, setSGSTTaxt2] = useState(0);
  const [SGSTTaxT3, setSGSTTaxt3] = useState(0);
  const [SGSTTaxT4, setSGSTTaxt4] = useState(0);
  const [SGSTTaxT5, setSGSTTaxt5] = useState(0);


  const [cartDataLen, setCartDataLen] = useState(0);










  useEffect(() => {
    if (data && filteredOrders) {




      // const amount = filteredOrders.reduce(
      //   (total, item) => total + item.newPrice * item.quantity,
      //   0
      // );
      // setSubTotal(amount);

      // let discountedAmount = 0;
      // let discount = 0;

      // if (discountType === "0") {
      //   discount = (amount * (discountRate || 0)) / 100;
      //   discountedAmount = amount - discount;
      // } else if (discountType === "1") {
      //   discount = discountRate;
      //   discountedAmount = amount - discount;
      // } else {
      //   discountedAmount = amount;
      // }

      // const sch = (discountedAmount * (SCHRate || 0)) / 100;
      // const taxableAmout = discountedAmount + sch;

      // const cgst = (taxableAmout * (CGSTRate || 0)) / 100;
      // const sgst = (taxableAmout * (SGSTRate || 0)) / 100;

      // const totalPaid = taxableAmout + cgst + sgst;

      // setSchAmount(sch.toFixed(2));
      // setCGSTAmount(cgst.toFixed(2));
      // setSGSTAmount(sgst.toFixed(2));
      // setDiscountAmount(parseFloat(discount).toFixed(2));
      // setTotalAmountPaid(totalPaid.toFixed(2));
      // newFilteredOrders.forEach((res) => {
      //   res.items.forEach((i) => {


      //     console.log("########################")
      //     console.log(i)


      //   })
      // });





      // console.log("########################")
      // console.log(filteredOrders)

      // alert("dd")
      if (newFilteredOrders.length > 0) {

        setCGSTTaxt1(0);
        setCGSTTaxt2(0);
        setCGSTTaxt3(0);
        setCGSTTaxt4(0);
        setCGSTTaxt5(0);


        setSGSTTaxt1(0);
        setSGSTTaxt2(0);
        setSGSTTaxt3(0);
        setSGSTTaxt4(0);
        setSGSTTaxt5(0);

        try {

          let subTotal = 0;
          let tempDiscount = 0;
          let tempDiscountRow = 0;

          let tempServicCharge = 0;
          let tempServicChargeRow = 0;

          let tempTax = 0;
          let tempTaxRow = 0;

          let GSTTaxT1 = 0;
          let GSTTaxT2 = 0;
          let GSTTaxT3 = 0;
          let GSTTaxT4 = 0;
          let GSTTaxT5 = 0;



          let tempCartLen = 0;
          let billDiscountRate = discountValues.rate;

          let packingCharges = 0;


          // console.log("newFilteredOrders")
          // console.log(newFilteredOrders)



          //  for flate amount discount

          let withOutTaxPriceForAmount = 0
          let subTotalForAmount = 0;
          let discountRateForAmount = 0;

          if (discountType === "1") {
            newFilteredOrders.forEach((res) => {
              res.items.forEach((i) => {
                subTotalForAmount += (i.quantity) * i.withOutTaxPrice
              })
            });
            discountRateForAmount = (parseFloat(billDiscountRate) * 100) / subTotalForAmount
          }

          // end

          newFilteredOrders.forEach((res) => {
            res.items.forEach((i) => {

              packingCharges += i.quantity * parseFloat(i.packaging_charges) || 0
              tempCartLen += parseInt(i.quantity)
              // console.log("tempCartLen")

              // console.log(tempCartLen)

              tempDiscountRow = 0;


              subTotal += (i.quantity) * i.withOutTaxPrice

              // console.log(subTotal)

              if (discountType === "0") {
                tempDiscount += (((i.quantity) * i.withOutTaxPrice) * billDiscountRate) / 100
                tempDiscountRow = (((i.quantity) * i.withOutTaxPrice) * billDiscountRate) / 100
              } else {
                // tempDiscount += ((i.quantity * i.withOutTaxPrice) - ((i.quantity * i.withOutTaxPrice) - billDiscountRate)) * i.quantity
                // tempDiscountRow = ((i.quantity * i.withOutTaxPrice) - ((i.quantity * i.withOutTaxPrice) - billDiscountRate)) * i.quantity
                tempDiscount += (((i.quantity) * i.withOutTaxPrice) * discountRateForAmount) / 100
                tempDiscountRow = (((i.quantity) * i.withOutTaxPrice) * discountRateForAmount) / 100

              }





              tempServicCharge += ((((i.quantity) * i.withOutTaxPrice) - tempDiscountRow) * parseFloat(SCHRate)) / 100
              tempServicChargeRow = ((((i.quantity) * i.withOutTaxPrice) - tempDiscountRow) * parseFloat(SCHRate)) / 100



              tempTax += ((((i.quantity) * i.withOutTaxPrice) + tempServicChargeRow - tempDiscountRow) * parseFloat(i.tax_percent)) / 100
              tempTaxRow = ((((i.quantity) * i.withOutTaxPrice) + tempServicChargeRow - tempDiscountRow) * parseFloat(i.tax_percent)) / 100


              if (i.tax_percent === 0) {

                GSTTaxT1 += ((((i.quantity) * i.withOutTaxPrice) + tempServicChargeRow - tempDiscountRow) * parseFloat(i.tax_percent)) / 100
                setCGSTTaxt1(GSTTaxT1 / 2)
                setSGSTTaxt1(GSTTaxT1 / 2)

              }

              if (i.tax_percent === 5) {

                GSTTaxT2 += ((((i.quantity) * i.withOutTaxPrice) + tempServicChargeRow - tempDiscountRow) * parseFloat(i.tax_percent)) / 100

                setCGSTTaxt2((GSTTaxT2 / 2).toFixed(2))
                setSGSTTaxt2((GSTTaxT2 / 2).toFixed(2))
              }

              if (i.tax_percent === 12) {

                GSTTaxT3 += ((((i.quantity) * i.withOutTaxPrice) + tempServicChargeRow - tempDiscountRow) * parseFloat(i.tax_percent)) / 100

                setCGSTTaxt3((GSTTaxT3 / 2).toFixed(2))
                setSGSTTaxt3((GSTTaxT3 / 2).toFixed(2))
              }

              if (i.tax_percent === 18) {
                // console.log('tax %')
                // console.log(i.tax_percent)
                GSTTaxT4 += ((((i.quantity) * i.withOutTaxPrice) + tempServicChargeRow - tempDiscountRow) * parseFloat(i.tax_percent)) / 100
                setCGSTTaxt4((GSTTaxT4 / 2).toFixed(2))
                setSGSTTaxt4((GSTTaxT4 / 2).toFixed(2))
              }

              if (i.tax_percent === 28) {
                // console.log('tax %')
                // console.log(i.tax_percent)
                GSTTaxT5 += ((((i.quantity) * i.withOutTaxPrice) + tempServicChargeRow - tempDiscountRow) * parseFloat(i.tax_percent)) / 100
                setCGSTTaxt5((GSTTaxT5 / 2).toFixed(2))
                setSGSTTaxt5((GSTTaxT5 / 2).toFixed(2))
              }



            })
          });


          setCartProductSubTotal(subTotal.toFixed(2));
          // console.log(subTotal)
          // console.log(tempServicCharge)
          setCartProductServiceCharge(tempServicCharge.toFixed(2))
          setCartProductDiscount(tempDiscount.toFixed(2))
          setCartProductTax(tempTax.toFixed(2))
          setCartProductTotal((subTotal + tempServicCharge - tempDiscount + tempTax).toFixed(2));
          setCartDataLen(tempCartLen)

          // setTotalAmountPaid((subTotal + tempServicCharge - tempDiscount + tempTax).toFixed(2) + packingCharges)

          if (orderType !== "DINE IN") {
            setCartProductPackingCharges(packingCharges.toFixed(2))
            setTotalAmountPaid((parseFloat(subTotal + tempServicCharge - tempDiscount + tempTax) + parseFloat(packingCharges)).toFixed(2))
            setCartProductTotal((parseFloat(subTotal + tempServicCharge - tempDiscount + tempTax) + parseFloat(packingCharges)).toFixed(2));

            // alert('dd')
          } else {
            setCartProductPackingCharges(0)
            setTotalAmountPaid((subTotal + tempServicCharge - tempDiscount + tempTax).toFixed(2))



            let updatedOrderList = JSON.parse(localStorage.getItem("orderList"));

            if (updatedOrderList && updatedOrderList[selectedTable] && (subTotal + tempServicCharge - tempDiscount + tempTax) > 0) {
              updatedOrderList[selectedTable].grandTotal = (subTotal + tempServicCharge - tempDiscount + tempTax).toFixed(2);
              localStorage.setItem("orderList", JSON.stringify(updatedOrderList));
              // setOrderList(updatedOrderList);
              // alert("dd")

            }

          }


          // setProductCartData(productCartDataTemp)
        }
        catch (e) {

        }
      }








    }
  }, [
    filteredOrders,
    data,
    discountRate,
    discountType,
    // SCHRate,
    // CGSTRate,
    // SGSTRate,
  ]);


  useEffect(() => {
    function formatDateToCustomString() {
      const currentDate = new Date();

      const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];

      const day = currentDate.getDate();
      const month = months[currentDate.getMonth()];
      const year = currentDate.getFullYear();

      let dayWithSuffix = day + "th";
      if (day === 1 || day === 21 || day === 31) {
        dayWithSuffix = day + "st";
      } else if (day === 2 || day === 22) {
        dayWithSuffix = day + "nd";
      } else if (day === 3 || day === 23) {
        dayWithSuffix = day + "rd";
      }

      const formattedDate = `${dayWithSuffix} ${month} ${year}`;

      return formattedDate;
    }

    const formattedDate = formatDateToCustomString();
    setDate(formattedDate);
  }, [currentTime]);

  useEffect(() => {
    const intervalID = setInterval(() => {
      setCurrentTime(getCurrentTime());
    }, 1000);

    return () => clearInterval(intervalID);
  }, []);

  useEffect(() => {
    if (latestItemRef.current) {
      latestItemRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [orderList, newFilteredOrders]);

  useEffect(() => {
    if (selectedTableDetails) {
      setSelectedTable(selectedTableDetails.id);
    }
  }, [selectedTableDetails]);

  useEffect(() => {
    if (orderList[selectedTable] && orderList[selectedTable].discountValues) {
      setDiscountType(orderList[selectedTable].discountValues.type);
      setDiscountRate(orderList[selectedTable].discountValues.rate);
      setDiscountValues(orderList[selectedTable].discountValues);
    } else {
      setDiscountType("0");
      setDiscountRate("0");
      setDiscountValues({
        type: "0",
        rate: "0",
      });
    }
  }, [selectedTable, orderList]);

  useEffect(() => {
    if (orderList[selectedTable] && orderList[selectedTable].customerDetails) {
      setCustomerForm(orderList[selectedTable].customerDetails);
    } else {
      setCustomerForm({
        name: "",
        address: "",
        mobile: "",
      });
    }
  }, [selectedTable, orderList]);

  useEffect(() => {
    if (orderList[selectedTable] && orderList[selectedTable].totalPersons) {
      setNoOfPeople(orderList[selectedTable].totalPersons);
    }
  }, [selectedTable, orderList]);

  useEffect(() => {
    if (orderList[selectedTable] && orderList[selectedTable].instructions) {
      setCustomerSuggestion(orderList[selectedTable].instructions);
    } else {
      setCustomerSuggestion("");
    }
  }, [selectedTable, orderList]);

  useEffect(() => {
    if (orderList[selectedTable] && orderList[selectedTable].staff) {
      setSelectedWaiter(orderList[selectedTable].staff);
    } else {
      setSelectedWaiter(null);
    }
  }, [selectedTable, orderList]);

  useEffect(() => {
    if (orderList[selectedTable] && orderList[selectedTable].orders) {
      const groupedData = {};

      orderList[selectedTable].orders.forEach((item) => {
        const { KOT_no, KOT_time } = item;

        const groupKey = `${KOT_no}_${KOT_time}`;

        if (!groupedData[groupKey]) {
          groupedData[groupKey] = {
            KOT_no: KOT_no,
            KOT_time: KOT_time,
            items: [],
          };
        }

        groupedData[groupKey].items.push(item);
      });

      const newResult = Object.values(groupedData);

      // console.log(orderList[selectedTable], "fds;jkadfsjkl;dfasjkladfsjkldfjadfsdfsadfsjkl;")
      newResult.forEach((main) => {
        // console.log(main)
        main.items.forEach((i) => {
          // console.log(i)
          // item.withOutTaxPrice = 0
          // item.withTaxPrice = 0

          // if (i.proprice) {

          //   i.withTaxPrice = i.tax_type === '1' ? parseInt(i.proprice) : parseInt(i.proprice) + (parseInt(i.proprice) * parseInt(i.tax_percent) / 100)
          //   i.withOutTaxPrice = i.tax_type === '0' ? parseInt(i.proprice) : (parseInt(i.proprice) / (1 + parseInt(i.tax_percent) / 100))
          // }
          // else {
          //   i.withTaxPrice = i.tax_type === '1' ? parseInt(i.price) : parseInt(i.price) + (parseInt(i.price) * parseInt(i.tax_percent) / 100)
          //   i.withOutTaxPrice = i.tax_type === '0' ? parseInt(i.price) : parseInt(i.price) - (parseInt(i.price) * parseInt(i.tax_percent) / 100)
          // }


          i.withTaxPrice = i.tax_type === 1 ? parseFloat(i.newPrice) : parseFloat(i.newPrice) + (parseFloat(i.newPrice) * parseFloat(i.tax_percent) / 100)
          // i.withOutTaxPrice = i.tax_type === 0 ? parseInt(i.newPrice) : parseInt(i.newPrice) - (parseInt(i.newPrice) * parseInt(i.tax_percent) / 100)
          i.withOutTaxPrice = i.tax_type === 0 ? parseFloat(i.newPrice) : (parseFloat(i.newPrice) * parseFloat(100)) / (parseFloat(100) + parseFloat(i.tax_percent))

        })
      });
      setNewFilteredOrders(newResult);
    } else {
      setNewFilteredOrders([]);
    }
  }, [selectedTable, orderList]);

  useEffect(() => {
    if (selectedPaymentOption && selectedPaymentOption === "Cash") {
      setCashPaymentDetails((prev) => ({
        ...prev,
        customerPaid: totalAmountPaid ? totalAmountPaid : "",
        tip: "0",
        amount: totalAmountPaid ? totalAmountPaid : "",
        transaction_id: "",
        returnToCustomer: "0",
      }));
    }

    if (selectedPaymentOption && selectedPaymentOption === "Card") {
      setCardPaymentDetails((prev) => ({
        ...prev,
        customerPaid: totalAmountPaid ? totalAmountPaid : "",
        tip: "0",
        amount: totalAmountPaid ? totalAmountPaid : "",
        transaction_id: ""
      }));
    }

    if (selectedPaymentOption && selectedPaymentOption === "NEFT") {
      setNeftPaymentDetails((prev) => ({
        ...prev,
        customerPaid: totalAmountPaid ? totalAmountPaid : "",
        tip: "0",
        amount: totalAmountPaid ? totalAmountPaid : "",
        transaction_id: ""
      }));
    }

    if (selectedPaymentOption && selectedPaymentOption === "UPI") {
      setUpiPaymentDetails((prev) => ({
        ...prev,
        amount: totalAmountPaid ? totalAmountPaid : "",
        tip: "0",
        transaction_id: ""
      }));
    }
  }, [selectedPaymentOption, totalAmountPaid, selectedTable, onlySavePaymentData]);

  useEffect(() => {
    if (printKOTDetails.KOT_no) {
      const handlePrintKOT = async () => {
        if (printKOTDetails.KOT_no !== "") {
          printJS({
            printable: "contentToPrintKOT",
            type: "html",
            targetStyles: ["*"],

          });

          setPrintKOTDetails({
            KOT_no: "",
            KOT_items: [],
          });
        }
      };
      handlePrintKOT();
    }
  }, [printKOTDetails.KOT_no]);

  useEffect(() => {
    if (printKOTDetails2) {
      const handlePrintKOT = async () => {
        if (printKOTDetails2) {
          printJS({
            printable: "contentToPrintKOT2",
            type: "html",
            targetStyles: ["*"],
          });

          setPrintKOTDetails2(null);
        }
      };
      handlePrintKOT();
    }
  }, [printKOTDetails2]);

  useEffect(() => {
    if (
      isPrintBill &&
      filteredOrders.length !== 0 &&
      // subTotal &&
      printBillDetails
    ) {
      const handlePrintBill = async () => {
        if (
          isPrintBill &&
          filteredOrders.length !== 0 &&
          // subTotal &&
          printBillDetails
        ) {
          // alert(CGSTTaxT1)
          // alert(CGSTTaxT2)
          // alert(CGSTTaxT3)
          // alert(CGSTTaxT4)


          printJS({
            printable: "contentToPrintBill2",
            type: "html",
            targetStyles: ["*"],
            font_size: '8pt'

          });



          setIsPrintBill(false);
          setSelectedTableDetails(null);
          setSelectedTable("");
        }
      };
      handlePrintBill();
    }
  }, [isPrintBill, filteredOrders, subTotal, printBillDetails]);


  const [switchTableModel, setSwitchTableModel] = useState(false);
  const [switchTableWithAllTable, setSwitchTableWithAllTable] = useState(false);
  const [allFreeTable, setAllFreeTable] = useState([]);


  const handleTableSwitch = async (table) => {
    // console.log(table)
    playSound()
    if (isAnyItemKOTForTable(table.id)) {
      addToast("error", "Table is already book");
    } else if (orderList[table.id]?.isPrinted) {
      addToast("error", "Table is already book");
    }
    else if (orderList[table.id]?.orders && orderList[table.id]?.orders.length > 0) {
      addToast("error", "Table is already book");
    } else {

      // console.log(selectedTable)
      // console.log(orderList[selectedTable])

      const storedOrderList = JSON.parse(localStorage.getItem("orderList")) || {};

      let getAnyErrorInAPI = false

      if (isAnyItemKOTForTable(selectedTable)) {
        // alert("dd")
        let temp_kot = []
        storedOrderList[selectedTable]?.orders.forEach((item) => {
          if (item.KOT_id) {
            temp_kot.push(item.KOT_id)
          }

        });
        // console.log(temp_kot)

        setIsLoading(true);
        try {
          const response = await axios({
            method: "put",
            url: switchTableKOTUpdateURL,
            data: { kot_array: temp_kot, table_id: table.id, order_id: storedOrderList[selectedTable]?.orderId, },
            headers: {
              "Content-Type": "application/json",
              authorization: `Bearer ${token}`,
            },
          });

          if (response.status === 200 && response.data.message) {
            // console.log(response.data.message)
            addToast("success", response.data.message);

          }
        } catch (error) {


          if (error.response && (error.response.data.error_code === 'order_paided' || error.response.data.error_code === 'order_cancelled')) {
            getAnyErrorInAPI = false
          } else {
            getAnyErrorInAPI = true
          }

          if (error.response && error.response.data.message) {
            addToast("error", error.response.data.message);
          } else {
            addToast("error", error.message);
          }
          console.log(error);
        }
        setIsLoading(false);

      }

      if (!getAnyErrorInAPI) {
        if (storedOrderList[selectedTable]?.orderId) {
          setIsLoading(true);
          try {
            const response = await axios({
              method: "put",
              url: switchTablePosURL,
              data: { order_id: storedOrderList[selectedTable]?.orderId, table_id: table.id },
              headers: {
                "Content-Type": "application/json",
                authorization: `Bearer ${token}`,
              },
            });

            if (response.status === 200 && response.data.message) {

              addToast("success", response.data.message);

              let tempTableDetailsByCategory = tableDetailsByCategory.map(floor => {
                floor.table_categoryName_data.forEach(table => {
                  if (table.id === selectedTable) {
                    table.kot_committed = 0;
                    table.pos_committed = 0;
                    table.qr_committed = 0;
                    // console.log(table)
                  }
                });
                return floor;
              });

              setTableDetailsByCategory(tempTableDetailsByCategory);

              if (storedOrderList[selectedTable]) {
                storedOrderList[selectedTable]?.orders.forEach((item) => {
                  item.table_id = table.id;
                });
                storedOrderList[table.id] = (storedOrderList[selectedTable])
                delete storedOrderList[selectedTable];
              }
              localStorage.setItem("orderList", JSON.stringify(storedOrderList));
              setSelectedTable(orderList[table.id])
              setSelectedTableDetails(table)

            }
          } catch (error) {
            if (error.response && error.response.data.message) {
              addToast("error", error.response.data.message);


              if (error.response && (error.response.data.error_code === 'order_paided' || error.response.data.error_code === 'order_cancelled')) {
                // alert("fsdj")

                clearTableData(selectedTable);
                setSelectedPaymentOption("Cash");
                setCustomerForm({
                  name: "",
                  address: "",
                  mobile: "",
                });
                setNoOfPeople("1");
                setCustomerSuggestion("");
                setSelectedWaiter(null);
                setSavePaymentData([]);
                setOnlySavePaymentTotalPaidedAmount(0)
                setOnlySavePaymentTipAmount(0)
                setOnlySavePaymentNotes("")
                setOnlySavePaymentTipTotalAmount(0)

                setOnlySavePaymentRoundUpAmount(0)
                setActiveTab("tables");
                handleTableView();
                setIsSettleModal(false);

              }




            } else {
              addToast("error", error.message);
            }
            console.log(error);
          }
          setIsLoading(false);

        } else {



          let tempTableDetailsByCategory = tableDetailsByCategory.map(floor => {
            floor.table_categoryName_data.forEach(table => {
              if (table.id === selectedTable) {
                table.kot_committed = 0;
                table.pos_committed = 0;
                table.qr_committed = 0;
                // console.log(table)
              }
            });
            return floor;
          });

          setTableDetailsByCategory(tempTableDetailsByCategory);

          if (storedOrderList[selectedTable]) {
            storedOrderList[selectedTable]?.orders.forEach((item) => {
              item.table_id = table.id;
            });
            storedOrderList[table.id] = (storedOrderList[selectedTable])
            delete storedOrderList[selectedTable];
          }
          localStorage.setItem("orderList", JSON.stringify(storedOrderList));
          setSelectedTable(orderList[table.id])
          setSelectedTableDetails(table)
        }
      }



      if (switchTableWithAllTable) {
        handleTableView()

      }


      setSwitchTableModel(false)


    }


  }
  // if (isAnyItemKOTForTable(table.id)) {
  //   addToast("error", "Table is already book");
  // } else if (orderList[table.id]?.isPrinted) {
  //   addToast("error", "Table is already book");
  // }
  // else if (orderList[table.id]?.orders && orderList[table.id]?.orders.length > 0) {
  //   addToast("error", "Table is already book");
  // } else {

  useEffect(() => {
    if (switchTableModel) {
      const tempTable = [];

      tableDetailsByCategory.forEach((floor) => {
        let shouldAddFloor = false;
        const tempRow = {
          id: floor.id,
          table_categoryName: floor.table_categoryName,
          table_categoryName_data: [],
        };

        floor?.table_categoryName_data.forEach((table) => {
          const tableId = table.id;
          const isKOT = isAnyItemKOTForTable(tableId);
          const isPrinted = orderList[tableId]?.isPrinted;
          const hasOrders = orderList[tableId]?.orders?.length > 0;

          if (!isKOT && !isPrinted && !hasOrders && table.qr_committed === 0 && table.pos_committed === 0 && table.kot_committed === 0) {
            shouldAddFloor = true;
            tempRow.table_categoryName_data.push(table);
          }
        });

        if (shouldAddFloor) {
          tempTable.push(tempRow);
        }
      });

      setAllFreeTable(tempTable);
    }


  }, [switchTableModel]);


  const checkTableCommitted = async (table) => {
    playSound();
    if (orderList[table.id]?.orders && orderList[table.id]?.orders.length > 0) {
      setSelectedTableDetails(table);
      setOrderType("DINE IN");
      setActiveTab("tables");
      setTableView(false);
      setIsLoading(false);
    }
    else if (table.qr_committed === 0 && table.pos_committed === 0 && table.kot_committed === 0) {

      setSelectedTableDetails(table);
      setOrderType("DINE IN");
      setActiveTab("tables");
      setTableView(false);
      setIsLoading(false);

    } else {
      if (table.qr_committed === 0) {
        addToast("error", "Table already Booked by other System");
      } else {
        addToast("error", "Table already Booked by QR Code");
      }
    }


  }

  const sendCreateOrderWhateappMassage = async () => {
    playSound();

    let itemsText = ""
    orderList[selectedTable]?.orders.forEach((item, index) => {
      itemsText += index + 1 + ". " + item.name + " x " + item.quantity + "\\n"
    });
    console.log(orderList[selectedTable]?.orderNo)
    setIsLoading(true);

    try {
      const response = await axios({
        method: "post",
        url: whatsappMassageingApiUrl + '/contact/send-template-message',
        data: {
          phone_number: customerForm.mobile,
          template_name: "order_create",
          template_language: "en",
          header_field_1: orderList[selectedTable]?.orderNo,
          field_1: orderList[selectedTable]?.orderNo,
          field_2: capitalizeFirstLetter(data.fooder_name),
          field_3: itemsText,

          // if you want to create contact if it does not exist
          contact: {
            first_name: customerForm.name ? customerForm.name : "no name",

            last_name: ".",
            country: "india",
            language_code: "en",
            groups: ""
          }
        },
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${whatsappApiAccessToken}`,
        },
      });
      await axios({
        method: "post",
        url: orderWwhatssappMmassageHistoryURL,
        data: {
          order_id: orderList[selectedTable]?.orderId,
          mobile: customerForm.mobile,
          templats_name: "order_create",
          w_data: response.data,

        },
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${token}`,
        },
      });

      if (response.data.result) {
        addToast("success", "Message send successfully");
      }
      setIsLoading(false);
    } catch (error) {
      if (error.response && error.response.data.message) {
        addToast("error", error.response.data.message);
      } else {
        addToast("error", error.message);
      }
      console.log(error);
    }
    setIsLoading(false);



  }




  const sendCustomerData = async () => {
    playSound();


    try {
      const response = await axios({
        method: "put",
        url: updateCustomerDataURL,
        data: {
          name: customerForm.name,
          mobile: customerForm.mobile,
          address: customerForm.address,
          order_id: orderList[selectedTable]?.orderId,
        },
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200 && response.data.message) {
        addToast("success", response.data.message);

      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        addToast("error", error.response.data.message);
      } else {
        addToast("error", error.message);
      }
      console.log(error);
    }
    setIsLoading(false);



  }











  const sendOrderPaymentWhateappMassage = async () => {
    playSound();

    let itemsText = ""
    orderList[selectedTable]?.orders.forEach((item, index) => {
      itemsText += index + 1 + ". " + item.name + " x " + item.quantity + "\\n"
    });
    console.log(orderList[selectedTable]?.orderNo)
    setIsLoading(true);

    try {
      const response = await axios({
        method: "post",
        url: whatsappMassageingApiUrl + '/contact/send-template-message',
        data: {
          phone_number: customerForm.mobile,
          template_name: "order_payment",
          template_language: "en",
          header_field_1: orderList[selectedTable]?.orderNo,
          field_1: customerForm.name ? "Hi" + customerForm.name : "Hi",
          field_2: capitalizeFirstLetter(data.fooder_name),
          field_3: orderList[selectedTable]?.orderNo,
          field_4: totalAmountPaid,
          field_5: itemsText,
          field_6: "upi://pay/?pa=" + fooderUpiId + "&am=" + totalAmountPaid + "&cu=INR",

          // button_0: "{upi://pay/?pa=vineetkrishnagupta@okhdfcbank&am=100.23&cu=INR}",



          // if you want to create contact if it does not exist
          contact: {
            first_name: customerForm.name ? customerForm.name : "no name",
            last_name: ".",
            country: "india",
            language_code: "en",
            groups: ""
          }
        },
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${whatsappApiAccessToken}`,
        },
      });

      await axios({
        method: "post",
        url: orderWwhatssappMmassageHistoryURL,
        data: {
          order_id: orderList[selectedTable]?.orderId,
          mobile: customerForm.mobile,
          templats_name: "order_payment",
          w_data: response.data,

        },
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${token}`,
        },
      });

      if (response.data.result) {
        addToast("success", "Message send successfully");
      }
      setIsLoading(false);
    } catch (error) {
      if (error.response && error.response.data.message) {
        addToast("error", error.response.data.message);
      } else {
        addToast("error", error.message);
      }
      console.log(error);
    }
    setIsLoading(false);



  }


  return (
    <>
      {token && (
        <>
          <div className="main-panel layout">
            <div className="content-wrapper contentdiv">
              <div className="row">
                <div
                  className={
                    viewKOT
                      ? "col-lg-12 d-flex flex-column"
                      : "col-lg-9 d-flex flex-column leftdiv"
                  }
                >
                  <div className="headernav">
                    <div className="row">
                      <div className="col-lg-1">
                        <span
                          className="navclck"
                          href="#offcanvas-usage"
                          data-bs-toggle="offcanvas"
                          data-bs-target="#offcanvasExample"
                          aria-controls="offcanvasExample"
                        >
                          <i className="fa fa-navicon text-black"></i>&nbsp;
                        </span>
                        &nbsp;
                        {/* <button
                      className="newordbtn"
                      type="button"
                      onClick={handleTableView}
                    >
                      New Orders
                    </button> */}
                      </div>
                      <div className="col-lg-5 text-left">
                        <h4 className="welcome-text">
                          Hello,{" "}
                          <span className="text-black fw-bold">
                            {data && capitalizeFirstLetter(data.fooder_name)}
                          </span>
                        </h4>
                      </div>
                      <div className="col-lg-6 text-right">

                        {newFilteredOrders.length > 0 && orderType === 'DINE IN' && !viewKOT && (
                          <button
                            className="newordbtn"
                            type="button"
                            onClick={() => { playSound(); setSwitchTableModel(true) }}
                          >
                            Switch Table
                          </button>
                        )}

                        &nbsp;&nbsp;
                        <button
                          className="newordbtn"
                          type="button"
                          onClick={handleTableView}
                        >
                          New Orders
                        </button>
                        &nbsp;&nbsp;
                        {!viewKOT && (
                          <button
                            className="btn btn-dark me-4 kotbtn"
                            type="button"
                            onClick={handleKOTClick}
                          >
                            KOT
                          </button>
                        )}
                      </div>
                    </div>
                  </div>

                  {/* <div className="row flex-grow ps-4 mt-2">
                <div className="col-lg-6 ps-4 mt-2">
                  <button
                    className={
                      orderType === "DINE IN"
                        ? "btn btn-primary"
                        : "btn btn-dark"
                    }
                    type="button"
                    onClick={() => handleOrderTypeChange("DINE IN")}
                  >
                    DINE IN
                  </button>
                  <button
                    className={
                      orderType === "DELIVERY"
                        ? "btn btn-primary mx-2"
                        : "btn btn-dark mx-2"
                    }
                    type="button"
                    onClick={() => handleOrderTypeChange("DELIVERY")}
                  >
                    DELIVERY
                  </button>
                  <button
                    className={
                      orderType === "TAKE AWAY"
                        ? "btn btn-primary"
                        : "btn btn-dark"
                    }
                    type="button"
                    onClick={() => handleOrderTypeChange("TAKE AWAY")}
                  >
                    PICK UP
                  </button>
                </div>
                <div className="col-lg-6 ps-4 mt-2 text-right">
                  <button
                    className={viewKOT ? "btn btn-primary" : "btn btn-dark"}
                    type="button"
                    onClick={handleKOTClick}
                  >
                    KOT
                  </button>
                </div>
              </div> */}

                  {viewKOT ? (
                    <>
                      <div className="row flex-grow">
                        <div className="col-lg-12 d-flex flex-column">
                          <div className="kotslr ps-4 mt-4">
                            <div className="tablenames">
                              <>
                                <div>
                                  <div className="row me-2 mb-2">
                                    <div className="col-8">
                                      <div className="customchk mb-3">
                                        <div className="form-check form-check-inline">
                                          <label className="form-check-label">
                                            <span className="checkcss checkbox6"></span>{" "}
                                            Queue KOT
                                          </label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                          <label className="form-check-label">
                                            <span className="checkcss checkbox7"></span>{" "}
                                            Cooking KOT
                                          </label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                          <label className="form-check-label">
                                            <span className="checkcss checkbox3"></span>{" "}
                                            Ready KOT
                                          </label>
                                        </div>

                                        <div className="form-check form-check-inline">
                                          <label className="form-check-label">
                                            <span className="checkcss checkbox5"></span>{" "}
                                            Cancel KOT
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-4 text-right">
                                      {/* <span
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                          setDataKOTDetails([]);
                                          setViewKOT(false);
                                        }}
                                      >
                                        <i
                                          className="fa fa-window-close closeicon"
                                          aria-hidden="true"
                                        ></i>{" "}
                                        Close
                                      </span> */}
                                      <span
                                        className="btn btn-danger p-2"
                                        onClick={() => {
                                          setDataKOTDetails([]);
                                          setViewKOT(false);
                                        }}
                                      >
                                        <i className="mdi mdi-close vsalign"></i>
                                        &nbsp;&nbsp;Close
                                      </span>
                                    </div>
                                  </div>
                                  <div className="row flex-grow">
                                    {dataKOTDetails.length === 0 ? (
                                      <>
                                        <h4>No KOT Data Found</h4>
                                      </>
                                    ) : (
                                      <div class="row">
                                        <div class="col-sm-12 kottabs">
                                          <div class="home-tab">
                                            <div class="d-sm-flex align-items-center justify-content-between border-bottom">
                                              <ul
                                                class="nav nav-tabs"
                                                role="tablist"
                                              >
                                                <li class="nav-item">
                                                  <a
                                                    class="nav-link active ps-0"
                                                    id="home-tab"
                                                    data-bs-toggle="tab"
                                                    href="#allkot"
                                                    role="tab"
                                                    aria-controls="overview"
                                                    aria-selected="true"
                                                  >
                                                    All
                                                  </a>
                                                </li>
                                                <li class="nav-item">
                                                  <a
                                                    class="nav-link"
                                                    id="profile-tab"
                                                    data-bs-toggle="tab"
                                                    href="#quenekot"
                                                    role="tab"
                                                    aria-selected="false"
                                                  >
                                                    Queue
                                                  </a>
                                                </li>
                                                <li class="nav-item">
                                                  <a
                                                    class="nav-link"
                                                    id="contact-tab"
                                                    data-bs-toggle="tab"
                                                    href="#cookingkot"
                                                    role="tab"
                                                    aria-selected="false"
                                                  >
                                                    Cooking
                                                  </a>
                                                </li>
                                                <li class="nav-item">
                                                  <a
                                                    class="nav-link border-0"
                                                    id="more-tab"
                                                    data-bs-toggle="tab"
                                                    href="#readykot"
                                                    role="tab"
                                                    aria-selected="false"
                                                  >
                                                    Ready
                                                  </a>
                                                </li>
                                              </ul>
                                            </div>
                                            <div class="tab-content tab-content-basic">
                                              <div
                                                class="tab-pane fade show active"
                                                id="allkot"
                                                role="tabpanel"
                                                aria-labelledby="allkot"
                                              >
                                                <div class="row flex-grow">
                                                  <div>
                                                    <div className="row mr10">
                                                      {dataKOTDetails.map(
                                                        (item, index) => (
                                                          <div
                                                            key={index}
                                                            className="col-lg-2 col-md-6 col-12 stretch-card"
                                                          >
                                                            <div className="card card-rounded grid-margin">
                                                              <div className="card-body kotcrd">
                                                                <div
                                                                  className={
                                                                    item.status ===
                                                                      2
                                                                      ? "row ready"
                                                                      : item.status ===
                                                                        1
                                                                        ? "row inprocess"
                                                                        : item.status ===
                                                                          0
                                                                          ? "row inque"
                                                                          : item.status ===
                                                                            4 ? "row cancel" : "row"
                                                                  }
                                                                >
                                                                  <div
                                                                    className={
                                                                      item.status ===
                                                                        0
                                                                        ? "d-sm-flex justify-content-between mt-1 mb-1"
                                                                        : "d-sm-flex justify-content-between mt-1 mb-1 text-white"
                                                                    }
                                                                  >
                                                                    <div className="">
                                                                      <p className="mb-0">
                                                                        <strong>
                                                                          {
                                                                            item.kot
                                                                          }
                                                                        </strong>
                                                                        {/* &nbsp;&nbsp;
                                                          <span>
                                                            Sambilor Thal
                                                          </span> */}
                                                                      </p>
                                                                      <p className="mb-0">
                                                                        {item.table_no
                                                                          ? `${item.table_no} : `
                                                                          : item.order_number
                                                                            ? `#${item.order_number} : `
                                                                            : ""}{" "}
                                                                        {
                                                                          item.order_type
                                                                        }
                                                                      </p>
                                                                    </div>
                                                                    <div className="text-right">
                                                                      <p className="mb-0">
                                                                        <strong>
                                                                          {
                                                                            item.time
                                                                          }
                                                                        </strong>
                                                                      </p>
                                                                      {item.status ===
                                                                        0 && (
                                                                          <div className="badge bg-white">
                                                                            Queue
                                                                          </div>
                                                                        )}
                                                                      {item.status ===
                                                                        1 && (
                                                                          <div className="badge bg-white">
                                                                            Cooking
                                                                          </div>
                                                                        )}
                                                                      {item.status ===
                                                                        2 && (
                                                                          <div className="badge bg-white">
                                                                            Ready
                                                                          </div>
                                                                        )}

                                                                      {item.status ===
                                                                        4 && (
                                                                          <div className="badge bg-white">
                                                                            Cancel
                                                                          </div>
                                                                        )}


                                                                    </div>
                                                                  </div>
                                                                </div>
                                                                <hr />
                                                                <div className="crdscrl">
                                                                  {item.final_kot_details.map(
                                                                    (
                                                                      kotItem,
                                                                      index
                                                                    ) => (
                                                                      <div
                                                                        key={
                                                                          index
                                                                        }
                                                                        className="row"
                                                                      >
                                                                        <p>
                                                                          <strong>
                                                                            {
                                                                              kotItem.quantity
                                                                            }
                                                                          </strong>
                                                                          &nbsp;&nbsp;&nbsp;&nbsp;
                                                                          {capitalizeFirstLetter(
                                                                            kotItem.product_name
                                                                          )}
                                                                        </p>
                                                                      </div>
                                                                    )
                                                                  )}
                                                                </div>
                                                                <div className="row mt-2">
                                                                  <div className="d-sm-flex justify-content-between">
                                                                    <div className="col-9">
                                                                      {item.status ===
                                                                        0 && (
                                                                          <button
                                                                            type="submit"
                                                                            className="btn btn-inque text-center finishbtn"
                                                                            onClick={(
                                                                              e
                                                                            ) =>
                                                                              handleChangeKOTStatus(
                                                                                e,
                                                                                item.id,
                                                                                1
                                                                              )
                                                                            }
                                                                          >
                                                                            Start
                                                                            Cooking
                                                                          </button>
                                                                        )}
                                                                      {item.status ===
                                                                        1 && (
                                                                          <button
                                                                            type="submit"
                                                                            className="btn btn-warning text-center finishbtn"
                                                                            onClick={(
                                                                              e
                                                                            ) =>
                                                                              handleChangeKOTStatus(
                                                                                e,
                                                                                item.id,
                                                                                2
                                                                              )
                                                                            }
                                                                          >
                                                                            Finish
                                                                            Cooking
                                                                          </button>
                                                                        )}
                                                                      {item.status ===
                                                                        2 && (
                                                                          <button
                                                                            type="submit"
                                                                            className="btn btn-ready text-center finishbtn"
                                                                            onClick={(
                                                                              e
                                                                            ) =>
                                                                              handleChangeKOTStatus(
                                                                                e,
                                                                                item.id,
                                                                                3
                                                                              )
                                                                            }
                                                                          >
                                                                            Sent
                                                                          </button>
                                                                        )}

                                                                      {item.status ===
                                                                        4 && (
                                                                          <button
                                                                            type="submit"
                                                                            className="btn btn-cancel text-center finishbtn"
                                                                            onClick={(
                                                                              e
                                                                            ) =>
                                                                              handleChangeKOTStatus(
                                                                                e,
                                                                                item.id,
                                                                                5
                                                                              )
                                                                            }
                                                                          >
                                                                            Cancel
                                                                          </button>
                                                                        )}
                                                                    </div>
                                                                    <div className="text-right col-3">
                                                                      <button
                                                                        type="submit"
                                                                        className="btn btn-outline-dark btn-fw printbtn"
                                                                        onClick={(
                                                                          e
                                                                        ) => {
                                                                          e.preventDefault();
                                                                          setPrintKOTDetails2(
                                                                            item
                                                                          );
                                                                        }}
                                                                      >
                                                                        <i className="fa fa-print"></i>
                                                                      </button>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        )
                                                      )}
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>

                                              <div
                                                class="tab-pane fade"
                                                id="quenekot"
                                                role="tabpanel"
                                                aria-labelledby="quenekot"
                                              >
                                                <div class="row flex-grow">
                                                  <div>
                                                    <div className="row mr10">
                                                      {dataKOTDetails.filter(
                                                        (item) =>
                                                          item.status === 0
                                                      ).length === 0 ? (
                                                        <>
                                                          <h4>
                                                            Record Not Found!
                                                          </h4>
                                                        </>
                                                      ) : (
                                                        <>
                                                          {dataKOTDetails
                                                            .filter(
                                                              (item) =>
                                                                item.status ===
                                                                0
                                                            )
                                                            .map(
                                                              (item, index) => (
                                                                <div
                                                                  key={index}
                                                                  className="col-lg-2 stretch-card"
                                                                >
                                                                  <div className="card card-rounded grid-margin">
                                                                    <div className="card-body kotcrd">
                                                                      <div
                                                                        className={
                                                                          item.status ===
                                                                            2
                                                                            ? "row ready"
                                                                            : item.status ===
                                                                              1
                                                                              ? "row inprocess"
                                                                              : item.status ===
                                                                                0
                                                                                ? "row inque"
                                                                                : "row"
                                                                        }
                                                                      >
                                                                        <div
                                                                          className={
                                                                            item.status ===
                                                                              0
                                                                              ? "d-sm-flex justify-content-between mt-1 mb-1"
                                                                              : "d-sm-flex justify-content-between mt-1 mb-1 text-white"
                                                                          }
                                                                        >
                                                                          <div className="">
                                                                            <p className="mb-0">
                                                                              <strong>
                                                                                {
                                                                                  item.kot
                                                                                }
                                                                              </strong>
                                                                              {/* &nbsp;&nbsp;
                                                          <span>
                                                            Sambilor Thal
                                                          </span> */}
                                                                            </p>
                                                                            <p className="mb-0">
                                                                              {item.table_no
                                                                                ? `Table No - ${item.table_no} : `
                                                                                : item.order_number
                                                                                  ? `#${item.order_number} : `
                                                                                  : ""}{" "}
                                                                              {
                                                                                item.order_type
                                                                              }
                                                                            </p>
                                                                          </div>
                                                                          <div className="text-right">
                                                                            <p className="mb-0">
                                                                              <strong>
                                                                                {
                                                                                  item.time
                                                                                }
                                                                              </strong>
                                                                            </p>
                                                                            {item.status ===
                                                                              0 && (
                                                                                <div className="badge bg-white">
                                                                                  Queue
                                                                                </div>
                                                                              )}
                                                                            {item.status ===
                                                                              1 && (
                                                                                <div className="badge bg-white">
                                                                                  Cooking
                                                                                </div>
                                                                              )}
                                                                            {item.status ===
                                                                              2 && (
                                                                                <div className="badge bg-white">
                                                                                  Ready
                                                                                </div>
                                                                              )}
                                                                          </div>
                                                                        </div>
                                                                      </div>
                                                                      <hr />
                                                                      <div className="crdscrl">
                                                                        {item.final_kot_details.map(
                                                                          (
                                                                            kotItem,
                                                                            index
                                                                          ) => (
                                                                            <div
                                                                              key={
                                                                                index
                                                                              }
                                                                              className="row"
                                                                            >
                                                                              <p>
                                                                                <strong>
                                                                                  {
                                                                                    kotItem.quantity
                                                                                  }
                                                                                </strong>
                                                                                &nbsp;&nbsp;&nbsp;&nbsp;
                                                                                {capitalizeFirstLetter(
                                                                                  kotItem.product_name
                                                                                )}
                                                                              </p>
                                                                            </div>
                                                                          )
                                                                        )}
                                                                      </div>
                                                                      <div className="row mt-2">
                                                                        <div className="d-sm-flex justify-content-between">
                                                                          <div className="col-9">
                                                                            {item.status ===
                                                                              0 && (
                                                                                <button
                                                                                  type="submit"
                                                                                  className="btn btn-inque text-center finishbtn"
                                                                                  onClick={(
                                                                                    e
                                                                                  ) =>
                                                                                    handleChangeKOTStatus(
                                                                                      e,
                                                                                      item.id,
                                                                                      1
                                                                                    )
                                                                                  }
                                                                                >
                                                                                  Start
                                                                                  Cooking
                                                                                </button>
                                                                              )}
                                                                            {item.status ===
                                                                              1 && (
                                                                                <button
                                                                                  type="submit"
                                                                                  className="btn btn-warning text-center finishbtn"
                                                                                  onClick={(
                                                                                    e
                                                                                  ) =>
                                                                                    handleChangeKOTStatus(
                                                                                      e,
                                                                                      item.id,
                                                                                      2
                                                                                    )
                                                                                  }
                                                                                >
                                                                                  Finish
                                                                                  Cooking
                                                                                </button>
                                                                              )}
                                                                            {item.status ===
                                                                              2 && (
                                                                                <button
                                                                                  type="submit"
                                                                                  className="btn btn-ready text-center finishbtn"
                                                                                  onClick={(
                                                                                    e
                                                                                  ) =>
                                                                                    handleChangeKOTStatus(
                                                                                      e,
                                                                                      item.id,
                                                                                      3
                                                                                    )
                                                                                  }
                                                                                >
                                                                                  Sent
                                                                                </button>
                                                                              )}
                                                                          </div>
                                                                          <div className="text-right col-3">
                                                                            <button
                                                                              type="submit"
                                                                              className="btn btn-outline-dark btn-fw printbtn"
                                                                              onClick={(
                                                                                e
                                                                              ) => {
                                                                                e.preventDefault();
                                                                                setPrintKOTDetails2(
                                                                                  item
                                                                                );
                                                                              }}
                                                                            >
                                                                              <i className="fa fa-print"></i>
                                                                            </button>
                                                                          </div>
                                                                        </div>
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              )
                                                            )}
                                                        </>
                                                      )}
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>

                                              <div
                                                class="tab-pane fade"
                                                id="cookingkot"
                                                role="tabpanel"
                                                aria-labelledby="cookingkot"
                                              >
                                                <div class="row flex-grow">
                                                  <div>
                                                    <div className="row mr10">
                                                      {dataKOTDetails.filter(
                                                        (item) =>
                                                          item.status === 1
                                                      ).length === 0 ? (
                                                        <>
                                                          <h4>
                                                            Record Not Found!
                                                          </h4>
                                                        </>
                                                      ) : (
                                                        <>
                                                          {dataKOTDetails
                                                            .filter(
                                                              (item) =>
                                                                item.status ===
                                                                1
                                                            )
                                                            .map(
                                                              (item, index) => (
                                                                <div
                                                                  key={index}
                                                                  className="col-lg-2 stretch-card"
                                                                >
                                                                  <div className="card card-rounded grid-margin">
                                                                    <div className="card-body kotcrd">
                                                                      <div
                                                                        className={
                                                                          item.status ===
                                                                            2
                                                                            ? "row ready"
                                                                            : item.status ===
                                                                              1
                                                                              ? "row inprocess"
                                                                              : item.status ===
                                                                                0
                                                                                ? "row inque"
                                                                                : "row"
                                                                        }
                                                                      >
                                                                        <div
                                                                          className={
                                                                            item.status ===
                                                                              0
                                                                              ? "d-sm-flex justify-content-between mt-1 mb-1"
                                                                              : "d-sm-flex justify-content-between mt-1 mb-1 text-white"
                                                                          }
                                                                        >
                                                                          <div className="">
                                                                            <p className="mb-0">
                                                                              <strong>
                                                                                {
                                                                                  item.kot
                                                                                }
                                                                              </strong>
                                                                              {/* &nbsp;&nbsp;
                                                          <span>
                                                            Sambilor Thal
                                                          </span> */}
                                                                            </p>
                                                                            <p className="mb-0">
                                                                              {item.table_no
                                                                                ? `Table No - ${item.table_no} : `
                                                                                : item.order_number
                                                                                  ? `#${item.order_number} : `
                                                                                  : ""}{" "}
                                                                              {
                                                                                item.order_type
                                                                              }
                                                                            </p>
                                                                          </div>
                                                                          <div className="text-right">
                                                                            <p className="mb-0">
                                                                              <strong>
                                                                                {
                                                                                  item.time
                                                                                }
                                                                              </strong>
                                                                            </p>
                                                                            {item.status ===
                                                                              0 && (
                                                                                <div className="badge bg-white">
                                                                                  Queue
                                                                                </div>
                                                                              )}
                                                                            {item.status ===
                                                                              1 && (
                                                                                <div className="badge bg-white">
                                                                                  Cooking
                                                                                </div>
                                                                              )}
                                                                            {item.status ===
                                                                              2 && (
                                                                                <div className="badge bg-white">
                                                                                  Ready
                                                                                </div>
                                                                              )}
                                                                          </div>
                                                                        </div>
                                                                      </div>
                                                                      <hr />
                                                                      <div className="crdscrl">
                                                                        {item.final_kot_details.map(
                                                                          (
                                                                            kotItem,
                                                                            index
                                                                          ) => (
                                                                            <div
                                                                              key={
                                                                                index
                                                                              }
                                                                              className="row"
                                                                            >
                                                                              <p>
                                                                                <strong>
                                                                                  {
                                                                                    kotItem.quantity
                                                                                  }
                                                                                </strong>
                                                                                &nbsp;&nbsp;&nbsp;&nbsp;
                                                                                {capitalizeFirstLetter(
                                                                                  kotItem.product_name
                                                                                )}
                                                                              </p>
                                                                            </div>
                                                                          )
                                                                        )}
                                                                      </div>
                                                                      <div className="row mt-2">
                                                                        <div className="d-sm-flex justify-content-between">
                                                                          <div className="col-9">
                                                                            {item.status ===
                                                                              0 && (
                                                                                <button
                                                                                  type="submit"
                                                                                  className="btn btn-inque text-center finishbtn"
                                                                                  onClick={(
                                                                                    e
                                                                                  ) =>
                                                                                    handleChangeKOTStatus(
                                                                                      e,
                                                                                      item.id,
                                                                                      1
                                                                                    )
                                                                                  }
                                                                                >
                                                                                  Start
                                                                                  Cooking
                                                                                </button>
                                                                              )}
                                                                            {item.status ===
                                                                              1 && (
                                                                                <button
                                                                                  type="submit"
                                                                                  className="btn btn-warning text-center finishbtn"
                                                                                  onClick={(
                                                                                    e
                                                                                  ) =>
                                                                                    handleChangeKOTStatus(
                                                                                      e,
                                                                                      item.id,
                                                                                      2
                                                                                    )
                                                                                  }
                                                                                >
                                                                                  Finish
                                                                                  Cooking
                                                                                </button>
                                                                              )}
                                                                            {item.status ===
                                                                              2 && (
                                                                                <button
                                                                                  type="submit"
                                                                                  className="btn btn-ready text-center finishbtn"
                                                                                  onClick={(
                                                                                    e
                                                                                  ) =>
                                                                                    handleChangeKOTStatus(
                                                                                      e,
                                                                                      item.id,
                                                                                      3
                                                                                    )
                                                                                  }
                                                                                >
                                                                                  Sent
                                                                                </button>
                                                                              )}
                                                                          </div>
                                                                          <div className="text-right col-3">
                                                                            <button
                                                                              type="submit"
                                                                              className="btn btn-outline-dark btn-fw printbtn"
                                                                              onClick={(
                                                                                e
                                                                              ) => {
                                                                                e.preventDefault();
                                                                                setPrintKOTDetails2(
                                                                                  item
                                                                                );
                                                                              }}
                                                                            >
                                                                              <i className="fa fa-print"></i>
                                                                            </button>
                                                                          </div>
                                                                        </div>
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              )
                                                            )}
                                                        </>
                                                      )}
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>

                                              <div
                                                class="tab-pane fade"
                                                id="readykot"
                                                role="tabpanel"
                                                aria-labelledby="readykot"
                                              >
                                                <div class="row flex-grow">
                                                  <div>
                                                    <div className="row mr10">
                                                      {dataKOTDetails.filter(
                                                        (item) =>
                                                          item.status === 2
                                                      ).length === 0 ? (
                                                        <>
                                                          <h4>
                                                            Record Not Found!
                                                          </h4>
                                                        </>
                                                      ) : (
                                                        <>
                                                          {dataKOTDetails
                                                            .filter(
                                                              (item) =>
                                                                item.status ===
                                                                2
                                                            )
                                                            .map(
                                                              (item, index) => (
                                                                <div
                                                                  key={index}
                                                                  className="col-lg-2 stretch-card"
                                                                >
                                                                  <div className="card card-rounded grid-margin">
                                                                    <div className="card-body kotcrd">
                                                                      <div
                                                                        className={
                                                                          item.status ===
                                                                            2
                                                                            ? "row ready"
                                                                            : item.status ===
                                                                              1
                                                                              ? "row inprocess"
                                                                              : item.status ===
                                                                                0
                                                                                ? "row inque"
                                                                                : "row"
                                                                        }
                                                                      >
                                                                        <div
                                                                          className={
                                                                            item.status ===
                                                                              0
                                                                              ? "d-sm-flex justify-content-between mt-1 mb-1"
                                                                              : "d-sm-flex justify-content-between mt-1 mb-1 text-white"
                                                                          }
                                                                        >
                                                                          <div className="">
                                                                            <p className="mb-0">
                                                                              <strong>
                                                                                {
                                                                                  item.kot
                                                                                }
                                                                              </strong>
                                                                              {/* &nbsp;&nbsp;
                                                          <span>
                                                            Sambilor Thal
                                                          </span> */}
                                                                            </p>
                                                                            <p className="mb-0">
                                                                              {item.table_no
                                                                                ? `Table No - ${item.table_no} : `
                                                                                : item.order_number
                                                                                  ? `#${item.order_number} : `
                                                                                  : ""}{" "}
                                                                              {
                                                                                item.order_type
                                                                              }
                                                                            </p>
                                                                          </div>
                                                                          <div className="text-right">
                                                                            <p className="mb-0">
                                                                              <strong>
                                                                                {
                                                                                  item.time
                                                                                }
                                                                              </strong>
                                                                            </p>
                                                                            {item.status ===
                                                                              0 && (
                                                                                <div className="badge bg-white">
                                                                                  Queue
                                                                                </div>
                                                                              )}
                                                                            {item.status ===
                                                                              1 && (
                                                                                <div className="badge bg-white">
                                                                                  Cooking
                                                                                </div>
                                                                              )}
                                                                            {item.status ===
                                                                              2 && (
                                                                                <div className="badge bg-white">
                                                                                  Ready
                                                                                </div>
                                                                              )}
                                                                          </div>
                                                                        </div>
                                                                      </div>
                                                                      <hr />
                                                                      <div className="crdscrl">
                                                                        {item.final_kot_details.map(
                                                                          (
                                                                            kotItem,
                                                                            index
                                                                          ) => (
                                                                            <div
                                                                              key={
                                                                                index
                                                                              }
                                                                              className="row"
                                                                            >
                                                                              <p>
                                                                                <strong>
                                                                                  {
                                                                                    kotItem.quantity
                                                                                  }
                                                                                </strong>
                                                                                &nbsp;&nbsp;&nbsp;&nbsp;
                                                                                {capitalizeFirstLetter(
                                                                                  kotItem.product_name
                                                                                )}
                                                                              </p>
                                                                            </div>
                                                                          )
                                                                        )}
                                                                      </div>
                                                                      <div className="row mt-2">
                                                                        <div className="d-sm-flex justify-content-between">
                                                                          <div className="col-9">
                                                                            {item.status ===
                                                                              0 && (
                                                                                <button
                                                                                  type="submit"
                                                                                  className="btn btn-inque text-center finishbtn"
                                                                                  onClick={(
                                                                                    e
                                                                                  ) =>
                                                                                    handleChangeKOTStatus(
                                                                                      e,
                                                                                      item.id,
                                                                                      1
                                                                                    )
                                                                                  }
                                                                                >
                                                                                  Start
                                                                                  Cooking
                                                                                </button>
                                                                              )}
                                                                            {item.status ===
                                                                              1 && (
                                                                                <button
                                                                                  type="submit"
                                                                                  className="btn btn-warning text-center finishbtn"
                                                                                  onClick={(
                                                                                    e
                                                                                  ) =>
                                                                                    handleChangeKOTStatus(
                                                                                      e,
                                                                                      item.id,
                                                                                      2
                                                                                    )
                                                                                  }
                                                                                >
                                                                                  Finish
                                                                                  Cooking
                                                                                </button>
                                                                              )}
                                                                            {item.status ===
                                                                              2 && (
                                                                                <button
                                                                                  type="submit"
                                                                                  className="btn btn-ready text-center finishbtn"
                                                                                  onClick={(
                                                                                    e
                                                                                  ) =>
                                                                                    handleChangeKOTStatus(
                                                                                      e,
                                                                                      item.id,
                                                                                      3
                                                                                    )
                                                                                  }
                                                                                >
                                                                                  Sent
                                                                                </button>
                                                                              )}
                                                                          </div>
                                                                          <div className="text-right col-3">
                                                                            <button
                                                                              type="submit"
                                                                              className="btn btn-outline-dark btn-fw printbtn"
                                                                              onClick={(
                                                                                e
                                                                              ) => {
                                                                                e.preventDefault();
                                                                                setPrintKOTDetails2(
                                                                                  item
                                                                                );
                                                                              }}
                                                                            >
                                                                              <i className="fa fa-print"></i>
                                                                            </button>
                                                                          </div>
                                                                        </div>
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              )
                                                            )}
                                                        </>
                                                      )}
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      {tableView ? (
                        <>
                          <div className="row flex-grow">
                            <div className="col-lg-12 d-flex flex-column">
                              <div className="filter_scrl ps-4 mt-4">
                                <div className="tablenames">
                                  <div className="row mb-2 me-0">
                                    <div className="col-lg-5">
                                      <button
                                        // className={
                                        //   orderType === "DINE IN"
                                        //     ? "btn btn-primary"
                                        //     : "btn btn-dark"
                                        // }
                                        className="btn dinebtn"
                                        type="button"
                                        onClick={() =>
                                          handleOrderTypeChange("DINE IN")
                                        }
                                      >
                                        DINE IN
                                      </button>
                                      <button
                                        // className={
                                        //   orderType === "DELIVERY"
                                        //     ? "btn btn-primary mx-2"
                                        //     : "btn btn-dark mx-2"
                                        // }
                                        className="btn deliverybtn mx-2"
                                        type="button"
                                        onClick={() =>
                                          handleOrderTypeChange("DELIVERY")
                                        }
                                      >
                                        DELIVERY
                                      </button>
                                      <button
                                        // className={
                                        //   orderType === "TAKE AWAY"
                                        //     ? "btn btn-primary"
                                        //     : "btn btn-dark"
                                        // }
                                        className="btn counterbtn"
                                        type="button"
                                        onClick={() =>
                                          handleOrderTypeChange("TAKE AWAY")
                                        }
                                      >
                                        COUNTER
                                      </button>
                                      {/* <button
                                        className="btn counterbtn dropdown-toggle"
                                        type="button"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                      >
                                        COUNTER
                                      </button>
                                      <ul className="dropdown-menu">
                                        <li
                                          style={{ cursor: "pointer" }}
                                          onClick={() => {
                                            setOrderType("TAKE AWAY");
                                            const value = new Date().getTime();
                                            const newOrder = `pk-${value}`;
                                            setSelectedTable(newOrder);
                                            setTableView(false);
                                          }}
                                        >
                                          <span className="dropdown-item">
                                            New Order
                                          </span>
                                        </li>
                                        {pickupOrders.map((item, index) => (
                                          <li
                                            key={index}
                                            style={{ cursor: "pointer" }}
                                            onClick={() => {
                                              setOrderType("TAKE AWAY");
                                              setSelectedTable(item.id);
                                              setTableView(false);
                                            }}
                                          >
                                            <span className="dropdown-item">
                                              Order - {index + 1}
                                            </span>
                                          </li>
                                        ))}
                                      </ul> */}
                                    </div>
                                    <div className="col-lg-7 text-right">
                                      <div className="customchk mb-3">
                                        <div className="form-check form-check-inline">
                                          <label className="form-check-label">
                                            <span className="checkcss checkbox1"></span>{" "}
                                            Blank
                                          </label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                          <label className="form-check-label">
                                            <span className="checkcss checkbox8"></span>{" "}
                                            Booked
                                          </label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                          <label className="form-check-label">
                                            <span className="checkcss checkbox2"></span>{" "}
                                            Running
                                          </label>
                                        </div>

                                        <div className="form-check form-check-inline">
                                          <label className="form-check-label">
                                            <span className="checkcss checkbox5"></span>{" "}
                                            Running KOT
                                          </label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                          <label className="form-check-label">
                                            <span className="checkcss checkbox4"></span>{" "}
                                            Printed
                                          </label>
                                        </div>
                                        {/* <div className="form-check form-check-inline">
                              <label className="form-check-label">
                                <span className="checkcss checkbox3"></span>{" "}
                                Paid Table
                              </label>
                            </div> */}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row mb-2 me-0">
                                    <h3 className="mb-2">Tables</h3>
                                  </div>
                                  {tableDetailsByCategory.length === 0 ? (
                                    <>
                                      <div>No Table Data Found</div>
                                    </>
                                  ) : (
                                    <>
                                      {tableDetailsByCategory.map(
                                        (floor, index) => (
                                          <div key={index}>
                                            <div className="row flex-grow">
                                              <div>
                                                <h4 className="mb-3">
                                                  {floor.table_categoryName}
                                                </h4>
                                                <div className="row stretch-card mr10">
                                                  {floor.table_categoryName_data.map(
                                                    (table, tableIndex) => (
                                                      <div
                                                        key={tableIndex}
                                                        className="col-lg-3 stretch-card"
                                                        style={{
                                                          cursor: "pointer",
                                                        }}
                                                        onClick={(e) => {
                                                          e.preventDefault();
                                                          checkTableCommitted(table)


                                                        }}
                                                      >
                                                        <div className="card card-rounded grid-margin">
                                                          <div
                                                            className={
                                                              table ===
                                                                selectedTableDetails
                                                                ? "card-body active"
                                                                : orderList[
                                                                  table.id
                                                                ]?.isPrinted
                                                                  ? "card-body active"
                                                                  : isAnyItemKOTForTable(
                                                                    table.id
                                                                  )
                                                                    ? "card-body active2"
                                                                    : orderList[
                                                                      table.id
                                                                    ]?.orders &&
                                                                      orderList[
                                                                        table.id
                                                                      ]?.orders
                                                                        .length > 0
                                                                      ? "card-body active1"
                                                                      : table.qr_committed || table.pos_committed || table.kot_committed ? "card-body active6" : "card-body"
                                                            }
                                                          >
                                                            {/* {orderList[table.id]
                                                          ?.isPrinted && (
                                                          <button
                                                            type="button"
                                                            className="btn tblicon btn-icon"
                                                            onClick={(e) => {
                                                              e.preventDefault();
                                                              e.stopPropagation();
                                                              playSound();
                                                              setSelectedTableDetails(
                                                                table
                                                              );
                                                              setTableView(
                                                                false
                                                              );
                                                              setIsSettleModal(
                                                                true
                                                              );
                                                            }}
                                                          >
                                                            <i className="fa fa-save"></i>
                                                          </button>
                                                        )} */}

                                                            {orderList[table.id]
                                                              ?.isPrinted ? (
                                                              <span className="tblicon">
                                                                &nbsp;&nbsp;
                                                                <i
                                                                  className="fa fa-save"
                                                                  onClick={(
                                                                    e
                                                                  ) => {
                                                                    e.preventDefault();
                                                                    e.stopPropagation();
                                                                    playSound();
                                                                    setSelectedTableDetails(
                                                                      table
                                                                    );
                                                                    // setTableView(
                                                                    //   false
                                                                    // );
                                                                    // setOnlySavePaymentRoundUpCheck(false)
                                                                    // setOnlySavePaymentRoundUpAmount(0)
                                                                    setIsSettleModal(
                                                                      true
                                                                    );
                                                                  }}
                                                                ></i>
                                                                &nbsp;&nbsp;&nbsp;&nbsp;
                                                                <i
                                                                  className="fa fa-print"
                                                                  onClick={(
                                                                    e
                                                                  ) => {
                                                                    e.preventDefault();
                                                                    e.stopPropagation();
                                                                    playSound();
                                                                    // setSelectedTableDetails(
                                                                    //   table
                                                                    // );
                                                                    // setTableView(
                                                                    //   false
                                                                    // );
                                                                    // setIsPrintBill(
                                                                    //   true
                                                                    // );
                                                                    // alert('dd')
                                                                    handlePrintIconClick(
                                                                      table
                                                                    );
                                                                  }}
                                                                ></i>&nbsp;&nbsp;&nbsp;&nbsp;
                                                                <i
                                                                  className="fa fa-clone"
                                                                  onClick={(
                                                                    e
                                                                  ) => {
                                                                    e.preventDefault();
                                                                    e.stopPropagation();
                                                                    setSelectedTable(table.id)
                                                                    setSwitchTableWithAllTable(true)
                                                                    setSwitchTableModel(true)
                                                                    playSound();


                                                                  }}
                                                                ></i>



                                                              </span>
                                                            ) : orderList[
                                                              table.id
                                                            ]?.orders &&
                                                              orderList[
                                                                table.id
                                                              ]?.orders.length >
                                                              0 ? (
                                                              <>
                                                                <span className="tblicon">
                                                                  <i className="fa fa-eye"></i> &nbsp;&nbsp;&nbsp;&nbsp;
                                                                  <i
                                                                    className="fa fa-clone"
                                                                    onClick={(
                                                                      e
                                                                    ) => {
                                                                      e.preventDefault();
                                                                      e.stopPropagation();
                                                                      setSelectedTable(table.id)
                                                                      setSwitchTableWithAllTable(true)
                                                                      setSwitchTableModel(true)
                                                                      playSound();


                                                                    }}
                                                                  ></i>
                                                                </span>



                                                                {/* <span className="tblicon">
                                                              <i className="fa fa-print"></i>
                                                            </span> */}
                                                              </>
                                                            ) : (
                                                              ""
                                                            )}

                                                            <span className="name">
                                                              {table.table_no}
                                                            </span>
                                                            {orderList[table.id]
                                                              ?.orders &&
                                                              orderList[
                                                                table.id
                                                              ]?.orders.length >
                                                              0 && (
                                                                <p className="price">
                                                                  {
                                                                    currencySymbol
                                                                  }
                                                                  {getTotalAmountofTable(
                                                                    table.id
                                                                  )}

                                                                </p>
                                                              )}
                                                          </div>
                                                        </div>
                                                      </div>
                                                    )
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                            {index <
                                              tableDetailsByCategory.length -
                                              1 && (
                                                <hr className="dottedhr" />
                                              )}
                                          </div>
                                        )
                                      )}
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="row flex-grow">
                            <div className="col-lg-3 d-flex flex-column">
                              <div className="sidemnuovr ps-4 mt-4 menunm">
                                <div
                                  onClick={() => handleMenuClick("all")}
                                  className="card card-rounded grid-margin"
                                  style={{ cursor: "pointer" }}
                                >
                                  <div
                                    className={
                                      currentMenu === "all"
                                        ? "card-body currentcrd"
                                        : "card-body"
                                    }
                                  >
                                    <span className="fw-bold">All Menu</span>
                                    <br />
                                    <p className="itemno">
                                      {products.length} Items
                                    </p>
                                  </div>
                                </div>

                                {menus.map((menu) => (
                                  <div
                                    key={menu.menu_id}
                                    onClick={() =>
                                      handleMenuClick(menu.menu_id)
                                    }
                                    className="card card-rounded grid-margin"
                                    style={{ cursor: "pointer" }}
                                  >
                                    <div
                                      className={
                                        currentMenu === menu.menu_id
                                          ? "card-body currentcrd"
                                          : "card-body"
                                      }
                                    >
                                      <span className="fw-bold">
                                        {capitalizeFirstLetter(menu.menu_name)}
                                      </span>
                                      <br />
                                      <p className="itemno">
                                        {menu.product_count} Items
                                      </p>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>

                            <div className="col-lg-9 d-flex flex-column">
                              <div className="filter_scrl mt-4">
                                <div className="row filtercrd stretch-card mt-2">
                                  {/* <div className="col-lg-6"></div> */}
                                  <div className="col-lg-12 mb-2">
                                    <form
                                      className="search-form searchcss"
                                      action="#"
                                    >
                                      <div className="input-group">
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="Search Here"
                                          onChange={handleSearchChange}
                                          value={searchInput}
                                        />

                                        {searchInput ?

                                          <span onClick={() => { handleSearchClear('') }} className="input-group-text searchicon custom-cursor-pointer">
                                            <i className="fa fa-close"></i>
                                            &nbsp;
                                          </span> :
                                          <span className="input-group-text searchicon">
                                            <i className="fa fa-search"></i>
                                            &nbsp;
                                          </span>
                                        }




                                      </div>
                                    </form>
                                  </div>
                                  {searchInput !== "" ? (
                                    <>
                                      {filteredProducts.length === 0 ? (
                                        <div className="col-lg-12">
                                          <div className="d-sm-flex justify-content-between align-items-start alert alert-danger">
                                            <h5>No Data Found</h5>
                                          </div>
                                        </div>
                                      ) : (
                                        <>
                                          {filteredProducts.map((product) => (
                                            <div
                                              key={product.id}
                                              className="col-lg-3 stretch-card"
                                              style={{ cursor: "pointer" }}
                                            >
                                              <div
                                                className="card card-rounded grid-margin"
                                                onClick={() =>
                                                  handleProductClick(product)
                                                }
                                              >
                                                <div className="card-body itemsize">
                                                  <div className="">
                                                    <span className="name">
                                                      {product.name.length > 30
                                                        ? capitalizeFirstLetter(
                                                          product.name.substring(
                                                            0,
                                                            30
                                                          ) + "..."
                                                        )
                                                        : capitalizeFirstLetter(
                                                          product.name
                                                        )}
                                                      {/* {capitalizeFirstLetter(
                                                    product.name
                                                  )} */}
                                                    </span>
                                                    <div className="row pricepos">
                                                      <div className="col-lg-6">
                                                        <p className="price">
                                                          {currencySymbol}{" "}
                                                          {product.proprice
                                                            ? isValidPrice(
                                                              product.proprice
                                                            )
                                                            : isValidPrice(
                                                              product.price
                                                            )}
                                                        </p>
                                                      </div>
                                                      <div className="col-lg-6 text-right valign">
                                                        {product.product_type ===
                                                          0 && (
                                                            <img
                                                              src="./images/veg.jpeg"
                                                              className="nveg"
                                                              alt="veg"
                                                            />
                                                          )}
                                                        {product.product_type ===
                                                          1 && (
                                                            <img
                                                              src="./images/non-veg.jpeg"
                                                              className="nveg"
                                                              alt="nonveg"
                                                            />
                                                          )}
                                                        {product.product_type ===
                                                          2 && (
                                                            <img
                                                              src="./images/egg.png"
                                                              className="nveg"
                                                              alt="egg"
                                                            />
                                                          )}
                                                        {product.product_type ===
                                                          3 && (
                                                            <img
                                                              src="./images/alcohol.png"
                                                              className="nveg"
                                                              alt="egg"
                                                            />
                                                          )}
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          ))}
                                        </>
                                      )}
                                    </>
                                  ) : (
                                    <>
                                      {currentMenuProducts !== null &&
                                        currentMenuProducts.length === 0 ? (
                                        <div className="col-lg-12">
                                          <div className="d-sm-flex justify-content-between align-items-start alert alert-danger">
                                            <h5>
                                              There are no products available in
                                              this menu.
                                            </h5>
                                          </div>
                                        </div>
                                      ) : (
                                        <>
                                          {currentMenuProducts !== null &&
                                            currentMenuProducts.filter(
                                              (item) =>
                                                (item.dine_in_service &&
                                                  orderType === "DINE IN") ||
                                                (item.delivery_service &&
                                                  orderType === "DELIVERY") ||
                                                (item.pick_up_service &&
                                                  orderType === "TAKE AWAY")
                                            ).length === 0 ? (
                                            <div className="col-lg-12">
                                              <div className="d-sm-flex justify-content-between align-items-start alert alert-danger">
                                                <h5>
                                                  There are no products
                                                  available in this menu for{" "}
                                                  {orderType}.
                                                </h5>
                                              </div>
                                            </div>
                                          ) : (
                                            <>
                                              {currentMenuProducts !== null &&
                                                currentMenuProducts
                                                  .filter(
                                                    (item) =>
                                                      (item.dine_in_service &&
                                                        orderType ===
                                                        "DINE IN") ||
                                                      (item.delivery_service &&
                                                        orderType ===
                                                        "DELIVERY") ||
                                                      (item.pick_up_service &&
                                                        orderType ===
                                                        "TAKE AWAY")
                                                  )
                                                  .map((product) => (
                                                    <div
                                                      key={product.id}
                                                      className="col-lg-3 stretch-card"
                                                      style={{
                                                        cursor: "pointer",
                                                      }}
                                                    >
                                                      <div
                                                        className="card card-rounded grid-margin"
                                                        onClick={() =>
                                                          handleProductClick(
                                                            product
                                                          )
                                                        }
                                                      >
                                                        <div className="card-body itemsize">
                                                          <div className="">
                                                            <span className="name">
                                                              {product.name
                                                                .length > 30
                                                                ? capitalizeFirstLetter(
                                                                  product.name.substring(
                                                                    0,
                                                                    30
                                                                  ) + "..."
                                                                )
                                                                : capitalizeFirstLetter(
                                                                  product.name
                                                                )}
                                                            </span>
                                                            <div className="row pricepos">
                                                              <div className="col-lg-8">
                                                                <p className="price">
                                                                  {
                                                                    currencySymbol
                                                                  }{" "}
                                                                  {product.proprice
                                                                    ? isValidPrice(
                                                                      product.proprice
                                                                    )
                                                                    : isValidPrice(
                                                                      product.price
                                                                    )}
                                                                </p>
                                                              </div>
                                                              <div className="col-lg-4 text-right valign">
                                                                {product.product_type ===
                                                                  0 && (
                                                                    <img
                                                                      src="./images/veg.jpeg"
                                                                      className="nveg"
                                                                      alt="veg"
                                                                    />
                                                                  )}
                                                                {product.product_type ===
                                                                  1 && (
                                                                    <img
                                                                      src="./images/non-veg.jpeg"
                                                                      className="nveg"
                                                                      alt="nonveg"
                                                                    />
                                                                  )}
                                                                {product.product_type ===
                                                                  2 && (
                                                                    <img
                                                                      src="./images/egg.png"
                                                                      className="nveg"
                                                                      alt="nonveg"
                                                                    />
                                                                  )}
                                                                {product.product_type ===
                                                                  3 && (
                                                                    <img
                                                                      src="./images/alcohol.png"
                                                                      className="nveg"
                                                                      alt="alcohol"
                                                                    />
                                                                  )}
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  ))}
                                            </>
                                          )}
                                        </>
                                      )}
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  )}
                </div>

                {!viewKOT && (
                  <div className="col-lg-3 billcrd rightdiv">
                    <div className="address">
                      <div className="mt-3 d-sm-flex justify-content-between">
                        <div className="">
                          <h4 className="me-2 fw-bold">
                            {/* {orderList[selectedTable]?.orderNo &&
                            `#${orderList[selectedTable]?.orderNo} / `} */}
                            {orderType === "TAKE AWAY" ? "PICK UP" : orderType}
                          </h4>
                          {orderList[selectedTable]?.orderNo ? (
                            <p className="time">
                              Order No - #{orderList[selectedTable]?.orderNo}
                            </p>
                          ) : (
                            <p className="time">Order No - Not Generated</p>
                          )}
                          <p className="time">
                            {date} &#183; {currentTime}
                          </p>
                        </div>
                        {orderType === "DINE IN" && selectedTableDetails && (
                          <div
                            className="me-2 tblalign valign"
                            style={{ cursor: "pointer" }}
                            onClick={handleTableView}
                          >
                            <div className="ad">
                              {selectedTableDetails.type === 1
                                ? selectedTableDetails.table_no.charAt(0)
                                : selectedTableDetails.table_no}
                            </div>
                            <span className="btntab groundtxt">
                              {selectedTableDetails.category_name
                                ? selectedTableDetails.category_name
                                : ""}
                            </span>
                          </div>
                        )}
                        {orderType !== "DINE IN" && (
                          <div
                            className="me-2 valign"
                            style={{ cursor: "pointer" }}
                            onClick={handleTableView}
                          >
                            <span className="btntab groundtxt">POS</span>
                          </div>
                        )}
                      </div>
                    </div>

                    {/* <div>
                    <ul className="text-center billtab">
                      <li
                        className={`col-md-4 ${
                          orderType === "DINE IN" ? "active" : ""
                        }`}
                      >
                        <span onClick={() => handleOrderTypeChange("DINE IN")}>
                          DINE IN
                        </span>
                      </li>
                      <li
                        className={`col-md-4 ${
                          orderType === "DELIVERY" ? "active" : ""
                        }`}
                      >
                        <span onClick={() => handleOrderTypeChange("DELIVERY")}>
                          DELIVERY
                        </span>
                      </li>
                      <li
                        className={`col-md-4 ${
                          orderType === "TAKE AWAY" ? "active" : ""
                        }`}
                      >
                        <span
                          onClick={() => handleOrderTypeChange("TAKE AWAY")}
                        >
                          PICK UP
                        </span>
                      </li>
                    </ul>
                  </div> */}
                    {/* <div>
                    <ul className="text-center billtab">
                      <li className="col-md-12 active">
                        <span>{orderType} (POS)</span>
                      </li>
                    </ul>
                  </div> */}
                    <div className="morebtn">
                      <div className="col">
                        <span
                          className={`btntab ${activeTab === "tables" ? "active" : ""
                            }`}
                          onClick={() => handleTabClick("tables")}
                        >
                          <i className="fa fa-cutlery"></i>
                          <br />
                          <span
                            className={
                              activeTab === "tables" ? "actnm" : "actnm1"
                            }
                          >
                            Items
                          </span>
                        </span>
                      </div>
                      <div className="col">
                        <span
                          className={`btntab ${activeTab === "customer" ? "active" : ""
                            }`}
                          onClick={() => {
                            if (
                              orderList[selectedTable]?.orders &&
                              orderList[selectedTable]?.orders.length > 0
                            ) {
                              handleTabClick("customer");
                            } else {
                              addToast("error", "Please add items first");
                            }
                          }}
                        >
                          <i className="fa fa-user"></i>
                          <br />
                          <span
                            className={
                              activeTab === "customer" ? "actnm" : "actnm1"
                            }
                          >
                            Details
                          </span>
                        </span>
                      </div>
                      {/* {orderType === "DINE IN" && (
                      <div className="col">
                        <span
                          className={`btntab ${
                            activeTab === "customers" ? "active" : ""
                          }`}
                          onClick={() => {
                            if (
                              orderList[selectedTable]?.orders &&
                              orderList[selectedTable]?.orders.length > 0
                            ) {
                              handleTabClick("customers");
                            } else {
                              addToast("error", "Please add items first");
                            }
                          }}
                        >
                          <i className="fa fa-users"></i>
                          <br />
                          <span
                            className={
                              activeTab === "customers" ? "actnm" : "actnm1"
                            }
                          >
                            Total Persons
                          </span>
                        </span>
                      </div>
                    )}
                    <div className="col">
                      <span
                        className={`btntab ${
                          activeTab === "edit" ? "active" : ""
                        }`}
                        onClick={() => {
                          if (
                            orderList[selectedTable]?.orders &&
                            orderList[selectedTable]?.orders.length > 0
                          ) {
                            handleTabClick("edit");
                          } else {
                            addToast("error", "Please add items first");
                          }
                        }}
                      >
                        <i className="fa fa-edit"></i>
                        <br />
                        <span
                          className={activeTab === "edit" ? "actnm" : "actnm1"}
                        >
                          Suggestions
                        </span>
                      </span>
                    </div>
                    {(orderType === "DINE IN" || orderType === "DELIVERY") && (
                      <div className="col">
                        <span
                          className={`btntab ${
                            activeTab === "notebook" ? "active" : ""
                          }`}
                          onClick={() => {
                            if (
                              orderList[selectedTable]?.orders &&
                              orderList[selectedTable]?.orders.length > 0
                            ) {
                              handleTabClick("notebook");
                            } else {
                              addToast("error", "Please add items first");
                            }
                          }}
                        >
                          <i className="fa fa-address-book"></i>
                          <br />
                          <span
                            className={
                              activeTab === "notebook" ? "actnm" : "actnm1"
                            }
                          >
                            {orderType === "DINE IN" && "Waiter"}
                            {orderType === "DELIVERY" && "Delivery Guy"}
                          </span>
                        </span>
                      </div>
                    )} */}
                      <div className="col">
                        <span
                          className={`btntab ${activeTab === "discount" ? "active" : ""
                            }`}
                          onClick={() => {
                            if (
                              orderList[selectedTable]?.orders &&
                              orderList[selectedTable]?.orders.length > 0
                            ) {
                              handleTabClick("discount");
                            } else {
                              addToast("error", "Please add items first");
                            }
                          }}
                        >
                          <i className="fa fa-percent"></i>
                          <br />
                          <span
                            className={
                              activeTab === "discount" ? "actnm" : "actnm1"
                            }
                          >
                            Discount
                          </span>
                        </span>
                      </div>
                      <div className="col">
                        <span
                          className={`btntab ${activeTab === "payment" ? "active" : ""
                            }`}
                          onClick={() => {
                            if (orderList[selectedTable]?.orderId) {
                              let fineOrderId = true
                              orderList[selectedTable].orders.forEach((i) => {
                                if (!i.orderId) {
                                  fineOrderId = false
                                }

                              })

                              if (fineOrderId) {
                                handleTabClick("payment");

                              }
                              else {
                                addToast("error", "Please save bill first");

                              }
                            } else {
                              addToast("error", "Please save bill first");
                            }
                          }}
                        >
                          <i className="fa fa-check-square-o"></i>
                          <br />
                          <span
                            className={
                              activeTab === "payment" ? "actnm" : "actnm1"
                            }
                          >
                            Mark Paid
                          </span>
                        </span>
                      </div>
                    </div>

                    {activeTab === "tables" && (
                      <>
                        {newFilteredOrders.length === 0 ? (
                          <h4 className="card-title m-3 fw-bold">
                            No Items Found !
                          </h4>
                        ) : (
                          <>
                            <div className="showdineintbl">
                              <div className="tablediv">
                                {newFilteredOrders.map((item, index) => (
                                  <div key={index}>
                                    {item.KOT_no && item.KOT_time ? (
                                      <div className="itemdiv">
                                        <div className="row">
                                          <div className="col-lg-6">
                                            <strong>{item.KOT_no}</strong>
                                          </div>
                                          <div className="col-lg-6 text-right">
                                            <strong>
                                              {item.KOT_time
                                                ? new Date(
                                                  item.KOT_time
                                                ).toLocaleTimeString([], {
                                                  hour: "2-digit",
                                                  minute: "2-digit",
                                                  hour12: true,
                                                })
                                                : ""}
                                            </strong>
                                          </div>
                                        </div>
                                      </div>
                                    ) : (
                                      orderType === "DINE IN" && (
                                        <div className="itemdiv">
                                          <div className="row">
                                            <div className="col-lg-12">
                                              <strong className="text-danger">
                                                Click on SAVE KOT / PRINT KOT to generate KOT
                                              </strong>
                                            </div>
                                          </div>
                                        </div>
                                      )
                                    )}

                                    {item.items.map((order, index) => (
                                      <div
                                        className="itemdiv"
                                        key={index}
                                        ref={latestItemRef}
                                      >
                                        <div key={index} className="row">
                                          <div className="col-4">
                                            <p className="itemnm">
                                              {order.name.length > 30
                                                ? capitalizeFirstLetter(
                                                  order.name.substring(
                                                    0,
                                                    30
                                                  ) + "..."
                                                )
                                                : capitalizeFirstLetter(
                                                  order.name
                                                )}

                                              {order.product_special_note && (
                                                <span
                                                  className="mx-2"
                                                  data-title={
                                                    order.product_special_note
                                                  }
                                                  style={{ cursor: "pointer" }}
                                                >
                                                  <i className="fa fa-info-circle"></i>
                                                </span>
                                              )}
                                            </p>


                                            {order.selectedvariants && <>
                                              {order.selectedvariants.combination_details && order.selectedvariants.combination_details.map((i) => (
                                                <><b>{i.attribute_name}: </b> {i.attribute_value_name} <br /></>
                                              ))}
                                            </>}

                                            {order.selectedAddons && order.selectedAddons.map((i, no) => (
                                              <><b>{no + 1}.</b> {i.addon_item_name} <br /></>
                                            ))}

                                          </div>
                                          <div className="col-4">
                                            <div className="">
                                              {order.isKOT ? (
                                                <button
                                                  type="button"
                                                  className="btn btn-outline-secondary btn-icon ctlbtn"
                                                  // disabled={order.isSaved}
                                                  // disabled={true}
                                                  disabled={!(localStorage.getItem("kot_items_delete") === "1")}



                                                  onClick={() =>
                                                    handleMinusClickAfterKOT(
                                                      order.id,
                                                      order.local_id,
                                                      order.table_id
                                                    )
                                                  }
                                                >
                                                  <i className="fa fa-minus"></i>
                                                </button>
                                              ) : (
                                                <button
                                                  type="button"
                                                  className="btn btn-outline-secondary btn-icon ctlbtn"
                                                  disabled={order.isSaved}
                                                  onClick={() =>
                                                    handleMinusClick(
                                                      order.id,
                                                      order.local_id,
                                                      order.table_id
                                                    )
                                                  }
                                                >
                                                  <i className="fa fa-minus"></i>
                                                </button>
                                              )}
                                              &nbsp;
                                              <button
                                                type="button"
                                                className="btn btn-outline-secondary btn-icon ctlbtn"
                                              >
                                                <span>{order.quantity}</span>
                                              </button>
                                              &nbsp;
                                              {order.isKOT ? (
                                                <button
                                                  type="button"
                                                  className="btn btn-outline-secondary btn-icon ctlbtn"
                                                  // disabled={order.isSaved}
                                                  // disabled={true}
                                                  disabled={!(localStorage.getItem("kot_items_delete") === "1")}


                                                  onClick={() =>
                                                    handlePlusClickAfterKOT(
                                                      order.id,
                                                      order.local_id,
                                                      order.table_id
                                                    )
                                                  }
                                                >
                                                  <i className="fa fa-plus"></i>
                                                </button>
                                              ) : (
                                                <button
                                                  type="button"
                                                  className="btn btn-outline-secondary btn-icon ctlbtn"
                                                  disabled={order.isSaved}
                                                  onClick={() =>
                                                    handlePlusClick(
                                                      order.id,
                                                      order.local_id,
                                                      order.table_id
                                                    )
                                                  }
                                                >
                                                  <i className="fa fa-plus"></i>
                                                </button>
                                              )}
                                            </div>
                                          </div>
                                          <div className="col-3">
                                            <span className="">
                                              {" "}
                                              {currencySymbol}{" "}
                                              {isValidPrice(
                                                order.quantity * order.newPrice
                                              ).toFixed(2)}
                                            </span>
                                          </div>
                                          <div className="col-1">
                                            {(!order.isSaved || true) && (
                                              <>
                                                {order.isKOT ? (

                                                  <>

                                                    {(localStorage.getItem("kot_items_delete") === "1") ?

                                                      <span
                                                        className=""
                                                        style={{
                                                          cursor: "pointer",
                                                        }}

                                                        onClick={() =>
                                                          handleKOTItemDeleteClick(
                                                            order
                                                          )
                                                        }
                                                      >
                                                        <i
                                                          className="fa fa-window-close closeicon"
                                                          aria-hidden="true"
                                                        ></i>
                                                      </span>
                                                      : <></>}

                                                  </>


                                                ) : (
                                                  <span
                                                    className=""
                                                    style={{
                                                      cursor: "pointer",
                                                    }}
                                                    onClick={() =>
                                                      handleDeleteClick(
                                                        order.id,
                                                        order.local_id,
                                                        order.table_id
                                                      )
                                                    }
                                                  >
                                                    <i
                                                      className="fa fa-window-close closeicon"
                                                      aria-hidden="true"
                                                    ></i>
                                                  </span>
                                                )}
                                              </>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                ))}
                              </div>
                              <div className="footerbtn">
                                <div className="totaldiv wrapper d-flex align-items-center justify-content-between">
                                  <div className="d-flex">
                                    <div className="wrapper">
                                      <span className="totalwrd">
                                        Items({filteredOrders.length})
                                      </span>
                                    </div>
                                  </div>
                                  <div className="">
                                    <span>
                                      {/* {currencySymbol} {cartProductSubTotal.toFixed(2)} */}
                                      {currencySymbol} {cartProductSubTotal}

                                    </span>
                                  </div>
                                </div>

                                {discountRate &&
                                  discountRate !== "0" &&
                                  discountType === "0" && (
                                    <div className="totaldiv wrapper d-flex align-items-center justify-content-between">
                                      <div className="d-flex">
                                        <div className="wrapper">
                                          <span className="totalwrd">
                                            Discount ({discountRate}%)
                                          </span>
                                        </div>
                                      </div>
                                      <div className="">
                                        <span>
                                          {currencySymbol}
                                          {cartProductDiscount}
                                        </span>
                                      </div>
                                    </div>
                                  )}

                                {discountRate &&
                                  discountRate !== "0" &&
                                  discountType === "1" && (
                                    <div className="totaldiv wrapper d-flex align-items-center justify-content-between">
                                      <div className="d-flex">
                                        <div className="wrapper">
                                          <span className="totalwrd">
                                            Discount ({currencySymbol})
                                          </span>
                                        </div>
                                      </div>
                                      <div className="">
                                        <span>
                                          {currencySymbol}
                                          {cartProductDiscount}
                                        </span>
                                      </div>
                                    </div>
                                  )}
                                {SCHRate !== 0 && (
                                  <div className="totaldiv wrapper d-flex align-items-center justify-content-between">
                                    <div className="d-flex">
                                      <div className="wrapper">
                                        <span className="totalwrd">
                                          {data.service_charge_details.name}(
                                          {SCHRate}
                                          %)
                                        </span>
                                      </div>
                                    </div>
                                    <div className="">
                                      <span>
                                        {currencySymbol}
                                        {cartProductServiceCharge}
                                      </span>
                                    </div>
                                  </div>
                                )}

                                {/* {CGSTRate !== 0 && (
                                  <div className="totaldiv wrapper d-flex align-items-center justify-content-between">
                                    <div className="d-flex">
                                      <div className="wrapper">
                                        <span className="totalwrd">
                                          CGST({CGSTRate}%)
                                        </span>
                                      </div>
                                    </div>
                                    <div className="">
                                      <span>
                                        {currencySymbol}
                                        {cgstAmount}
                                      </span>
                                    </div>
                                  </div>
                                )}

                                {SGSTRate !== 0 && (
                                  <div className="totaldiv wrapper d-flex align-items-center justify-content-between">
                                    <div className="d-flex">
                                      <div className="wrapper">
                                        <span className="totalwrd">
                                          SGST({SGSTRate}%)
                                        </span>
                                      </div>
                                    </div>
                                    <div className="">
                                      <span>
                                        {currencySymbol}
                                        {sgstAmount}
                                      </span>
                                    </div>
                                  </div>
                                )} */}

                                {CGSTTaxT2 ? <div className="totaldiv wrapper d-flex align-items-center justify-content-between">
                                  <div className="d-flex">
                                    <div className="wrapper">
                                      <span className="totalwrd">
                                        CGST(2.5%)
                                      </span>
                                    </div>
                                  </div>
                                  <div className="">
                                    <span>
                                      {currencySymbol}
                                      {CGSTTaxT2}
                                    </span>
                                  </div>
                                </div> : <></>}

                                {SGSTTaxT2 ? <div className="totaldiv wrapper d-flex align-items-center justify-content-between">
                                  <div className="d-flex">
                                    <div className="wrapper">
                                      <span className="totalwrd">
                                        SGST(2.5%)
                                      </span>
                                    </div>
                                  </div>
                                  <div className="">
                                    <span>
                                      {currencySymbol}
                                      {SGSTTaxT2}
                                    </span>
                                  </div>
                                </div> : <></>}


                                {CGSTTaxT3 ? <div className="totaldiv wrapper d-flex align-items-center justify-content-between">
                                  <div className="d-flex">
                                    <div className="wrapper">
                                      <span className="totalwrd">
                                        CGST(6%)
                                      </span>
                                    </div>
                                  </div>
                                  <div className="">
                                    <span>
                                      {currencySymbol}
                                      {CGSTTaxT3}
                                    </span>
                                  </div>
                                </div> : <></>}

                                {SGSTTaxT3 ? <div className="totaldiv wrapper d-flex align-items-center justify-content-between">
                                  <div className="d-flex">
                                    <div className="wrapper">
                                      <span className="totalwrd">
                                        SGST(6%)
                                      </span>
                                    </div>
                                  </div>
                                  <div className="">
                                    <span>
                                      {currencySymbol}
                                      {SGSTTaxT3}
                                    </span>
                                  </div>
                                </div> : <></>}


                                {CGSTTaxT4 ? <div className="totaldiv wrapper d-flex align-items-center justify-content-between">
                                  <div className="d-flex">
                                    <div className="wrapper">
                                      <span className="totalwrd">
                                        CGST(9%)
                                      </span>
                                    </div>
                                  </div>
                                  <div className="">
                                    <span>
                                      {currencySymbol}
                                      {CGSTTaxT4}
                                    </span>
                                  </div>
                                </div> : <></>}

                                {SGSTTaxT4 ? <div className="totaldiv wrapper d-flex align-items-center justify-content-between">
                                  <div className="d-flex">
                                    <div className="wrapper">
                                      <span className="totalwrd">
                                        SGST(9%)
                                      </span>
                                    </div>
                                  </div>
                                  <div className="">
                                    <span>
                                      {currencySymbol}
                                      {SGSTTaxT4}
                                    </span>
                                  </div>
                                </div> : <></>}

                                {CGSTTaxT5 ? <div className="totaldiv wrapper d-flex align-items-center justify-content-between">
                                  <div className="d-flex">
                                    <div className="wrapper">
                                      <span className="totalwrd">
                                        CGST(14%)
                                      </span>
                                    </div>
                                  </div>
                                  <div className="">
                                    <span>
                                      {currencySymbol}
                                      {CGSTTaxT5}
                                    </span>
                                  </div>
                                </div> : <></>}

                                {SGSTTaxT5 ? <div className="totaldiv wrapper d-flex align-items-center justify-content-between">
                                  <div className="d-flex">
                                    <div className="wrapper">
                                      <span className="totalwrd">
                                        SGST(14%)
                                      </span>
                                    </div>
                                  </div>
                                  <div className="">
                                    <span>
                                      {currencySymbol}
                                      {SGSTTaxT5}
                                    </span>
                                  </div>
                                </div> : <></>}

                                {cartProductPackingCharges !== 0 && orderType !== "DINE IN" ? <div className="totaldiv wrapper d-flex align-items-center justify-content-between">
                                  <div className="d-flex">
                                    <div className="wrapper">
                                      <span className="totalwrd">
                                        Packaging Charges
                                      </span>
                                    </div>
                                  </div>
                                  <div className="">
                                    <span>
                                      {currencySymbol}
                                      {cartProductPackingCharges}
                                    </span>
                                  </div>
                                </div> : <></>}


                                {onlySavePaymentRoundUpAmount !== 0 ?
                                  <div className="totaldiv wrapper d-flex align-items-center justify-content-between mb-2">
                                    <div className="d-flex">
                                      <div className="wrapper">
                                        <span className="totalwrd">Round off</span>
                                      </div>
                                    </div>
                                    <div className="">
                                      {currencySymbol}
                                      <span className="totalwrd">  {parseFloat(onlySavePaymentRoundUpAmount).toFixed(2)}</span>
                                    </div>
                                  </div> : <></>}


                                <div className="totaldiv wrapper d-flex align-items-center justify-content-between mb-2">
                                  <div className="d-flex">
                                    <div className="wrapper">
                                      <h4 className="fw-bold mb-0">Total</h4>
                                    </div>
                                  </div>
                                  <div className="">
                                    <h4 className="fw-bold mb-0">
                                      {currencySymbol}
                                      {/* {totalAmountPaid} */}
                                      {(parseFloat(cartProductTotal) + parseFloat(onlySavePaymentRoundUpAmount)).toFixed(2)}
                                    </h4>
                                  </div>
                                </div>

                                <div>
                                  <ul className="text-center savetab">
                                    {orderType === "DINE IN" && (
                                      <li
                                        className="col"
                                        onClick={() => {
                                          orderList[selectedTable]?.orderId ? handleSaveBillClick() : updateKOTForTable(selectedTable);
                                        }


                                        }

                                      >
                                        <span>Save KOT</span>
                                      </li>
                                    )}

                                    {orderType === "DINE IN" && (
                                      <li
                                        className="col"
                                        onClick={() =>
                                          updateAndPrintKOTForTable(
                                            selectedTable
                                          )
                                        }
                                      >
                                        <span>Print KOT</span>
                                      </li>
                                    )}

                                    <li
                                      className="col"
                                      id="save_bill_button"
                                      onClick={handleSaveBillClick}
                                    >
                                      <span className="themeclr">
                                        Save Bill
                                      </span>
                                    </li>
                                    <li
                                      className={
                                        orderType === "DINE IN" ? "col" : "col"
                                      }
                                      onClick={handleSaveAndPrintBillClick}
                                    // onClick={handlePrintBill}
                                    >
                                      <span className="themeclr">
                                        Print Bill
                                      </span>
                                    </li>
                                  </ul>
                                </div>

                                {/* <div className="totalbtn text-center">
                            <span>
                              <button className="btn btn-primary btn-lg savebtn">
                                <div className="wrapper d-flex align-items-center justify-content-between">
                                  <div className="d-flex">
                                    <div className="wrapper">
                                      <h4 className="fw-bold mb-0">
                                        Total &nbsp;{currencySymbol}
                                        {totalAmountPaid}
                                      </h4>
                                    </div>
                                  </div>
                                  <div className="">
                                    <h4 className="fw-bold mb-0">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        checked={printCheck}
                                        onChange={(e) =>
                                          setPrintCheck(e.target.checked)
                                        }
                                      />
                                      &nbsp;&nbsp;Print
                                    </h4>
                                  </div>
                                  <div
                                    className=""
                                    onClick={handleSaveBillClick}
                                  >
                                    <h4 className="fw-bold mb-0">
                                      Save &nbsp;&nbsp;
                                      <i
                                        className="fa fa-long-arrow-right"
                                        aria-hidden="true"
                                      ></i>
                                    </h4>
                                  </div>
                                </div>
                              </button>
                            </span>
                          </div> */}
                              </div>
                            </div>
                          </>
                        )}
                      </>
                    )}

                    {activeTab === "customer" && (
                      <>
                        <div className="tableshow">
                          <div className="tshwdiv">
                            {/* <div className="formcss"> */}
                            <div className="mt-3">

                              <form className="mb-1">
                                <div className="row">
                                  <div className="col">
                                    <label className="">
                                      Mobile
                                      {orderType === "DELIVERY" && (
                                        <span className="text-danger">*</span>
                                      )}
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Enter Mobile"
                                      maxLength={10}
                                      name="mobile"
                                      value={customerForm.mobile}
                                      onChange={handleCustomerMobileChange}
                                    />
                                  </div>
                                  <div className="col">
                                    <label className="">
                                      Name
                                      {orderType === "DELIVERY" && (
                                        <span className="text-danger">*</span>
                                      )}
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Enter Name"
                                      name="name"
                                      value={customerForm.name}
                                      onChange={handleCustomerFormChange}
                                    />
                                  </div>
                                </div>
                                <div className="row mt-2">
                                  <div className="col">
                                    <label className="">
                                      Address
                                      {orderType === "DELIVERY" && (
                                        <span className="text-danger">*</span>
                                      )}
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Address"
                                      name="address"
                                      value={customerForm.address}
                                      onChange={handleCustomerFormChange}
                                    />
                                  </div>
                                </div>
                                {orderType === "DINE IN" && (
                                  <div className="row mt-2">
                                    <div className="col">
                                      <label className="">Total People</label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Number of peoples on Table"
                                        value={noOfPeople}
                                        onChange={(e) => {
                                          const value = e.target.value;
                                          setNoOfPeople(value);
                                          updateTotalPersons(value);
                                        }}
                                      />
                                    </div>
                                  </div>
                                )}

                                {(orderType === "DINE IN" ||
                                  orderType === "DELIVERY") && (
                                    <div className="row mt-2">
                                      <div className="col">
                                        <label className="">
                                          {orderType === "DINE IN" && "Assign Waiter"}
                                          {orderType === "DELIVERY" &&
                                            "Assign Delivery Guy"}
                                          {orderType === "DELIVERY" && (
                                            <span className="text-danger">*</span>
                                          )}
                                        </label>
                                        {orderType === "DINE IN" && (
                                          <Select
                                            options={waiterList.filter(
                                              (item) => item.type === 0
                                            )}
                                            value={selectedWaiter}
                                            onChange={handleAssignWaiter}
                                          />
                                        )}

                                        {orderType === "DELIVERY" && (
                                          <Select
                                            options={waiterList.filter(
                                              (item) => item.type === 3
                                            )}
                                            value={selectedWaiter}
                                            onChange={handleAssignWaiter}
                                          />
                                        )}
                                      </div>
                                    </div>
                                  )}

                                {selectedWaiter && (
                                  <div className="row mt-2">
                                    {selectedWaiter.name && (
                                      <div className="col-12">
                                        <h6>
                                          <i
                                            className="fa fa-user"
                                            aria-hidden="true"
                                          ></i>
                                          &nbsp;&nbsp;&nbsp;&nbsp;
                                          {selectedWaiter.name}
                                        </h6>
                                      </div>
                                    )}

                                    {selectedWaiter.phone_number && (
                                      <div className="col-12">
                                        <h6>
                                          <i
                                            className="fa fa-phone"
                                            aria-hidden="true"
                                          ></i>
                                          &nbsp;&nbsp;&nbsp;&nbsp;
                                          {selectedWaiter.phone_number}
                                        </h6>
                                      </div>
                                    )}

                                    {selectedWaiter.email && (
                                      <div className="col-12">
                                        <h6>
                                          <i
                                            className="fa fa-envelope-o"
                                            aria-hidden="true"
                                          ></i>
                                          &nbsp;&nbsp;&nbsp;&nbsp;
                                          {selectedWaiter.email}
                                        </h6>
                                      </div>
                                    )}
                                  </div>
                                )}

                                <div className="row mt-2">
                                  <div className="col">
                                    <label className="">Customer Suggestions</label>
                                    <textarea
                                      className="form-control"
                                      placeholder="Enter Suggestions"
                                      rows={3}
                                      value={customerSuggestion}
                                      onChange={handleSaveCustomerSuggetions}
                                      maxLength={maxCharacterLimit}
                                    ></textarea>
                                    <p className="text-right">
                                      {customerSuggestion.length}/
                                      {maxCharacterLimit}
                                    </p>
                                    {orderList[selectedTable]?.orderId ?
                                      <button
                                        className="btn btn-primary"
                                        onClick={sendCustomerData}
                                        type="button"

                                      >

                                        Save Details
                                      </button>
                                      : <></>}

                                  </div>


                                </div>



                                <div className="mt-2">
                                  {/* <button
                          type="button"
                          className="btn btn-inverse-dark btn-icon"
                        >
                          <i className="fa fa-address-book-o"></i>
                        </button>
                        &nbsp;
                        <button
                          type="button"
                          className="btn btn-inverse-info btn-icon"
                        >
                          <i className="fa fa-pencil"></i>
                        </button>
                        &nbsp; */}
                                  {/* <button
                          type="button"
                          className="btn btn-inverse-success btn-icon"
                          onClick={handleSaveCustomerDetails}
                        >
                          <i className="fa fa-save"></i>
                        </button> */}
                                  {/* &nbsp;
                        <button
                          type="button"
                          className="btn btn-inverse-danger btn-icon"
                        >
                          <i className="fa fa-trash"></i>
                        </button> */}
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>

                      </>
                    )}

                    {/* {activeTab === "customers" && (
                    <>
                      <div className="tableshow">
                        <div className="tshwdiv">
                          <form className="forms-sample">
                            <h4 className="card-title m-3 fw-bold ms-0">
                              Number of people's on Table
                            </h4>

                            <div className="row">
                              <div className="col-8">
                                <div className="form-group">
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Number of peoples on Table"
                                    value={noOfPeople}
                                    onChange={(e) => {
                                      const value = e.target.value;
                                      setNoOfPeople(value);
                                      updateTotalPersons(value);
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </>
                  )}

                  {activeTab === "edit" && (
                    <>
                      <div className="tableshow">
                        <div className="tshwdiv">
                          <form className="forms-sample">
                            <h4 className="card-title m-3 fw-bold ms-0">
                              Customer Suggestions
                            </h4>
                            <div className="form-group">
                              <textarea
                                className="form-control"
                                placeholder="Enter Suggestions"
                                rows={4}
                                value={customerSuggestion}
                                onChange={handleSaveCustomerSuggetions}
                                maxLength={maxCharacterLimit}
                              ></textarea>
                            </div>
                            <p className="text-right">
                              {customerSuggestion.length}/{maxCharacterLimit}
                            </p>
                          </form>
                        </div>
                      </div>
                    </>
                  )}

                  {activeTab === "notebook" && (
                    <>
                      <div className="tableshow">
                        <div className="tshwdiv">
                          <form className="forms-sample">
                            <h4 className="card-title m-3 fw-bold ms-0">
                              {orderType === "DINE IN" && "Assign Waiter"}
                              {orderType === "DELIVERY" &&
                                "Assign Delivery Guy"}
                              {orderType === "DELIVERY" && (
                                <span className="text-danger">*</span>
                              )}
                            </h4>
                            <div className="form-group">
                              {orderType === "DINE IN" && (
                                <Select
                                  options={waiterList.filter(
                                    (item) => item.type === 0
                                  )}
                                  value={selectedWaiter}
                                  onChange={handleAssignWaiter}
                                />
                              )}

                              {orderType === "DELIVERY" && (
                                <Select
                                  options={waiterList.filter(
                                    (item) => item.type === 3
                                  )}
                                  value={selectedWaiter}
                                  onChange={handleAssignWaiter}
                                />
                              )}
                            </div>

                            {selectedWaiter && (
                              <div className="row mt-4">
                                {selectedWaiter.name && (
                                  <div className="col-12">
                                    <h6>
                                      <i
                                        className="fa fa-user"
                                        aria-hidden="true"
                                      ></i>
                                      &nbsp;&nbsp;&nbsp;&nbsp;
                                      {selectedWaiter.name}
                                    </h6>
                                  </div>
                                )}

                                {selectedWaiter.phone_number && (
                                  <div className="col-12">
                                    <h6>
                                      <i
                                        className="fa fa-phone"
                                        aria-hidden="true"
                                      ></i>
                                      &nbsp;&nbsp;&nbsp;&nbsp;
                                      {selectedWaiter.phone_number}
                                    </h6>
                                  </div>
                                )}

                                {selectedWaiter.email && (
                                  <div className="col-12">
                                    <h6>
                                      <i
                                        className="fa fa-envelope-o"
                                        aria-hidden="true"
                                      ></i>
                                      &nbsp;&nbsp;&nbsp;&nbsp;
                                      {selectedWaiter.email}
                                    </h6>
                                  </div>
                                )}
                              </div>
                            )}
                          </form>
                        </div>
                      </div>
                    </>
                  )} */}

                    {activeTab === "discount" && (
                      <>
                        <div className="tableshow">
                          <div className="tshwdiv">
                            <form className="forms-sample">
                              <h4 className="card-title m-3 fw-bold ms-0">
                                Add Discount
                              </h4>
                              <div className="row">
                                <div className="col-8">
                                  <div className="input-group mb-3 disselect">
                                    <button
                                      className="btn btn-outline-secondary dropdown-toggle"
                                      type="button"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="false"
                                    >
                                      {discountValues.type === "0"
                                        ? "%"
                                        : `${currencySymbol}`}
                                    </button>
                                    <ul className="dropdown-menu">
                                      <li
                                        onClick={() =>
                                          setDiscountValues((prevValues) => ({
                                            ...prevValues,
                                            type: "0",
                                          }))
                                        }
                                      >
                                        <span className="dropdown-item">%</span>
                                      </li>
                                      <li
                                        onClick={() =>
                                          setDiscountValues((prevValues) => ({
                                            ...prevValues,
                                            type: "1",
                                          }))
                                        }
                                      >
                                        <span className="dropdown-item">
                                          {currencySymbol}
                                        </span>
                                      </li>
                                    </ul>
                                    <input
                                      type="text"
                                      className="form-control discnt"
                                      name="rate"
                                      onChange={handleDiscountValuesChange}
                                      value={discountValues.rate}
                                    />
                                  </div>
                                </div>
                                <div className="col-4">
                                  <button
                                    type="submit"
                                    className="btn btn-primary me-2"
                                    onClick={handleApplyDiscountClick}
                                  >
                                    Apply
                                  </button>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </>
                    )}


                    <div
                      className={logoutModel ? "modal fade show d-block" : "modal fade"}
                      id="exampleModal"
                      tabIndex={-1}
                      role="dialog"
                      aria-labelledby="exampleModalLabel"
                      aria-hidden="true"
                    >
                      <div className="modal-dialog" role="document">
                        <div className="modal-content w-85">
                          <div className="modal-header py-2">
                            <h5 className="modal-title" id="exampleModalLabel">
                              Logout
                            </h5>
                            <button
                              type="button"
                              className="close"
                              data-dismiss="modal"
                              aria-label="Close"
                              onClick={() => { setLogoutModel(false) }}
                            >
                              <span aria-hidden="true">×</span>
                            </button>
                          </div>

                          <div className="modal-body py-2">
                            <div className="clear_and_logout_text_padding">
                              <h6>
                                Clicking on the “Clear and Logout” buttons will clear the tables.
                              </h6>
                            </div>
                          </div>


                          <div className="modal-footer" style={{ padding: "15px 0px" }}>
                            <div className="row" style={{ width: "100%" }}>
                              <div className="col-3 valign">
                                <button
                                  type="button"
                                  className="btn btn-primary"
                                  data-dismiss="modal"
                                  onClick={() => { handleLogout(false) }}
                                >
                                  Logout
                                </button>{' '}

                              </div>
                              <div className="col-8 valign">
                                <button
                                  type="button"
                                  className="btn btn-danger"
                                  data-dismiss="modal"
                                  onClick={() => { handleLogout(true) }}
                                >
                                  Clear and Logout
                                </button>

                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {activeTab === "payment" && (
                      <>
                        <div className="tableshow">
                          <div className="tshwdiv">
                            <form className="forms-sample">
                              <h4 className="card-title m-3 fw-bold ms-0">
                                Bill Settlement
                              </h4>
                              <h6 className="modal-title">
                                Total Amount: ₹ {(parseFloat(totalAmountPaid) + parseFloat(onlySavePaymentRoundUpAmount)).toFixed(2)}
                              </h6>
                              <h5 className="card-title m-3 ms-0">
                                Select Payment Option
                              </h5>
                              <div className="row">
                                <div className="">
                                  <div className="form-check form-check-inline">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="inlineRadioOptions"
                                      value="Cash"
                                      checked={selectedPaymentOption === "Cash"}
                                      onChange={handlePaymentOptionChange}
                                    />
                                    <label className="form-check-label">
                                      Cash
                                    </label>
                                  </div>
                                  <div className="form-check form-check-inline">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="inlineRadioOptions"
                                      value="Card"
                                      checked={selectedPaymentOption === "Card"}
                                      onChange={handlePaymentOptionChange}
                                    />
                                    <label className="form-check-label">
                                      Card
                                    </label>
                                  </div>

                                  <div className="form-check form-check-inline">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="inlineRadioOptions"
                                      value="NEFT"
                                      checked={selectedPaymentOption === "NEFT"}
                                      onChange={handlePaymentOptionChange}
                                    />
                                    <label className="form-check-label">
                                      NEFT
                                    </label>
                                  </div>

                                  <div className="form-check form-check-inline">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="inlineRadioOptions"
                                      value="UPI"
                                      checked={selectedPaymentOption === "UPI"}
                                      onChange={handlePaymentOptionChange}
                                    />
                                    <label className="form-check-label">
                                      UPI
                                    </label>
                                  </div>

                                  {onlySavePaymentData.length <= 0 && (<div className="form-check form-check-inline">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="inlineRadioOptions"
                                      value="Hold"
                                      checked={selectedPaymentOption === "Hold"}
                                      onChange={handlePaymentOptionChange}
                                    />
                                    <label className="form-check-label">
                                      Hold
                                    </label>
                                  </div>)
                                  }



                                  {/* <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="inlineRadioOptions"
                                value="Part"
                                checked={selectedPaymentOption === "Part"}
                                onChange={handlePaymentOptionChange}
                              />
                              <label className="form-check-label">Part</label>
                            </div> */}
                                </div>
                              </div>
                              {selectedPaymentOption === "Cash" && (
                                <>
                                  <div className="row">
                                    <div className="col-12  mt-3">
                                      <label className="">
                                        Customer Paid
                                        <span className="text-danger">*</span>
                                      </label>
                                      <div className="input-group">
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="Enter Customer Paid"
                                          name="customerPaid"
                                          value={
                                            // cashPaymentDetails.customerPaid
                                            onlySavePaymentPaidedAmount
                                          }
                                          onChange={(e) => { handleCashPaymentDetailsChange(e); setOnlySavePaymentPaidedAmount(e.target.value) }

                                          }

                                        // onChange={handleCashPaymentDetailsChange}
                                        // onBlur={calculateReturnToCustomer}
                                        />
                                        <span className="input-group-text">
                                          {currencySymbol}
                                        </span>
                                      </div>
                                    </div>
                                    <div className="col-12  mt-3">
                                      <label className="">Tip</label>
                                      <div className="input-group">
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="Enter Tip"
                                          name="tip"
                                          // value={cashPaymentDetails.tip}
                                          value={onlySavePaymentTipAmount}
                                          onChange={
                                            (e) => {
                                              handleCashPaymentDetailsChange(e)
                                              setOnlySavePaymentTipAmount(e.target.value)
                                            }

                                          }
                                          onBlur={calculateFinalAmountForCash}
                                        />
                                        <span className="input-group-text">
                                          {currencySymbol}
                                        </span>
                                      </div>
                                    </div>

                                    <div className="col-12  mt-3">
                                      <label className="">Transaction / Reference ID</label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Transaction / Reference ID"
                                        name="transaction_id"
                                        value={paymentSettleTransactionId}

                                        onChange={
                                          (e) => {
                                            setPaymentSettleTransactionId(e.target.value);
                                          }

                                        }
                                      />
                                    </div>

                                    {/* <div className="col-12  mt-3">
                                      <label className="">Amount</label>
                                      <div className="input-group">
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="Enter Amount"
                                          name="amount"
                                          value={cashPaymentDetails.amount}
                                          readOnly={true}
                                        />
                                        <span className="input-group-text">
                                          {currencySymbol}
                                        </span>
                                      </div>
                                    </div> */}
                                  </div>
                                  {onlySavePaymentData.length > 0 ?


                                    <div className="row">



                                      <div className="col-12  mt-3">
                                        {/* <label className="">
                                          {parseFloat(
                                            onlySavePaymentTotalPaidedAmount
                                          ) <
                                            parseFloat(totalAmountPaid)
                                            ? "Due"
                                            : !isValidNumber(
                                              onlySavePaymentTotalPaidedAmount
                                            )
                                              ? "Due"
                                              : "Return to Customer"}
                                        </label> */}

                                        <label className="">
                                          {parseFloat(onlySavePaymentTotalPaidedAmount) + parseFloat(onlySavePaymentPaidedAmount) < parseFloat(totalAmountPaid)
                                            ? "Due"


                                            : "Return to Customer"}
                                        </label>


                                        <div className="input-group">
                                          <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter Return to Customer"
                                            name="returnToCustomer"
                                            value={
                                              Math.abs(parseFloat(((totalAmountPaid) - onlySavePaymentTotalPaidedAmount) - onlySavePaymentPaidedAmount + onlySavePaymentRoundUpAmount).toFixed(2))
                                            }
                                            readOnly={true}
                                          />
                                          <span className="input-group-text">
                                            {currencySymbol}
                                          </span>
                                        </div>
                                      </div>
                                    </div>






                                    : <div className="row">



                                      <div className="col-12  mt-3">
                                        <label className="">
                                          {parseFloat(
                                            cashPaymentDetails.customerPaid
                                          ) <
                                            parseFloat(cashPaymentDetails.amount)
                                            ? "Due"
                                            : !isValidNumber(
                                              cashPaymentDetails.customerPaid
                                            )
                                              ? "Due"
                                              : "Return to Customer"}
                                        </label>
                                        <div className="input-group">
                                          <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter Return to Customer"
                                            name="returnToCustomer"
                                            value={
                                              // cashPaymentDetails.returnToCustomer
                                              Math.abs(parseFloat((totalAmountPaid - onlySavePaymentTotalPaidedAmount) - onlySavePaymentPaidedAmount + onlySavePaymentRoundUpAmount).toFixed(2))

                                            }
                                            readOnly={true}
                                          />
                                          <span className="input-group-text">
                                            {currencySymbol}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  }


                                  {/* {onlySavePaymentData.length <= 0 && (
                                    <button
                                      className="btn btn-primary mt-3"
                                      onClick={handleSettleAndSaveForCashPayment}
                                    >
                                      Settle & Save
                                    </button>
                                  )} */}

                                </>
                              )}



                              {selectedPaymentOption === "Card" && (
                                <>
                                  <div className="row">
                                    <div className="col-12  mt-3">
                                      <label className="">
                                        Customer Paid
                                        <span className="text-danger">*</span>
                                      </label>
                                      <div className="input-group">
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="Enter Customer Paid"
                                          name="customerPaid"
                                          value={
                                            // cardPaymentDetails.customerPaid
                                            onlySavePaymentPaidedAmount

                                          }
                                          // onChange={
                                          //   handleCardPaymentDetailsChange

                                          // }
                                          onChange={(e) => { handleCardPaymentDetailsChange(e); setOnlySavePaymentPaidedAmount(e.target.value) }}
                                        />
                                        <span className="input-group-text">
                                          {currencySymbol}
                                        </span>
                                      </div>
                                    </div>
                                    <div className="col-12  mt-3">
                                      <label className="">Tip</label>
                                      <div className="input-group">
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="Enter Tip"
                                          name="tip"
                                          // value={cardPaymentDetails.tip}
                                          value={onlySavePaymentTipAmount}

                                          // onChange={
                                          //   handleCardPaymentDetailsChange
                                          // }
                                          onChange={(e) => { handleCardPaymentDetailsChange(e); setOnlySavePaymentTipAmount(e.target.value) }}
                                          onBlur={calculateFinalAmountForCard}
                                        />
                                        <span className="input-group-text">
                                          {currencySymbol}
                                        </span>
                                      </div>
                                    </div>


                                    <div className="col-12  mt-3">
                                      <label className="">Transaction / Reference ID</label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Transaction / Reference ID"
                                        name="transaction_id"
                                        value={paymentSettleTransactionId}

                                        onChange={
                                          (e) => {
                                            setPaymentSettleTransactionId(e.target.value);
                                          }

                                        }
                                      />
                                    </div>

                                    <div className="col-12  mt-3">
                                      <label className="">Note</label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Note"
                                        name="note"
                                        // value={cardPaymentDetails.note}
                                        value={onlySavePaymentNotes}
                                        onChange={
                                          (e) => {
                                            handleCardPaymentDetailsChange(e);
                                            setOnlySavePaymentNotes(e.target.value);
                                          }

                                        }
                                      />
                                    </div>
                                    {/* <div className="col-12  mt-3">
                                      <label className="">Amount</label>
                                      <div className="input-group">
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="Enter Amount"
                                          name="amount"
                                          value={cardPaymentDetails.amount}
                                          readOnly={true}
                                        />
                                        <span className="input-group-text">
                                          {currencySymbol}
                                        </span>
                                      </div>
                                    </div> */}
                                  </div>

                                  {/* {onlySavePaymentData.length <= 0 && (

                                    <button
                                      className="btn btn-primary mt-3"
                                      onClick={handleSettleAndSaveForCardPayment}
                                    >
                                      Settle & Save
                                    </button>
                                  )} */}


                                </>
                              )}






                              {selectedPaymentOption === "NEFT" && (
                                <>
                                  <div className="row">
                                    <div className="col-12  mt-3">
                                      <label className="">
                                        Customer Paid
                                        <span className="text-danger">*</span>
                                      </label>
                                      <div className="input-group">
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="Enter Customer Paid"
                                          name="customerPaid"
                                          value={

                                            onlySavePaymentPaidedAmount

                                          }

                                          onChange={(e) => { handleNeftPaymentDetailsChange(e); setOnlySavePaymentPaidedAmount(e.target.value) }}
                                        />
                                        <span className="input-group-text">
                                          {currencySymbol}
                                        </span>
                                      </div>
                                    </div>
                                    <div className="col-12  mt-3">
                                      <label className="">Tip</label>
                                      <div className="input-group">
                                        <input

                                          type="text"
                                          className="form-control"
                                          placeholder="Enter Tip"
                                          name="tip"
                                          value={onlySavePaymentTipAmount}


                                          onChange={(e) => { handleNeftPaymentDetailsChange(e); setOnlySavePaymentTipAmount(e.target.value) }}
                                          onBlur={calculateFinalAmountForNeft}

                                        />
                                        <span className="input-group-text">
                                          {currencySymbol}
                                        </span>
                                      </div>
                                    </div>

                                    <div className="col-12  mt-3">
                                      <label className="">Transaction / Reference ID</label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Transaction / Reference ID"
                                        name="transaction_id"
                                        value={paymentSettleTransactionId}

                                        onChange={
                                          (e) => {
                                            setPaymentSettleTransactionId(e.target.value);
                                          }

                                        }
                                      />
                                    </div>

                                    <div className="col-12  mt-3">
                                      <label className="">Note</label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Note"
                                        name="note"
                                        // value={cardPaymentDetails.note}
                                        value={onlySavePaymentNotes}
                                        onChange={
                                          (e) => {
                                            handleNeftPaymentDetailsChange(e);
                                            setOnlySavePaymentNotes(e.target.value);
                                          }

                                        }
                                      />
                                    </div>

                                  </div>

                                  {/* {onlySavePaymentData.length <= 0 && (

                                    <button
                                      className="btn btn-primary mt-3"
                                      onClick={handleSettleAndSaveForNeftPayment}
                                    >
                                      Settle & Save
                                    </button>
                                  )} */}


                                </>
                              )}









                              {selectedPaymentOption === "UPI" && (
                                <>
                                  <div className="row">

                                    <div className="col-12  mt-3">
                                      <label className="">
                                        Select Method
                                        <span className="text-danger">*</span>
                                      </label>
                                      <select
                                        className="form-select"
                                        name="method"
                                        value={upiPaymentDetails.method}
                                        onChange={handleUPIPaymentDetailsChange}
                                      >
                                        <option value="">Select Method</option>
                                        <option value="Google Pay">
                                          Google Pay
                                        </option>
                                        <option value="PhonePe">PhonePe</option>
                                        <option value="Paytm">Paytm</option>
                                        <option value="Other">Other</option>
                                      </select>
                                      {upiPaymentDetails.method === "Other" && (
                                        <>
                                          &nbsp;
                                          <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter Method"
                                            name="other_method"
                                            value={
                                              upiPaymentDetails.other_method
                                            }
                                            onChange={
                                              handleUPIPaymentDetailsChange
                                            }
                                          />
                                        </>
                                      )}
                                    </div>


                                    <div className="col-12  mt-3">
                                      <label className="">
                                        Customer Paid
                                        <span className="text-danger">*</span>
                                      </label>
                                      <div className="input-group">
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="Enter Customer Paid"
                                          name="customerPaid"
                                          value={
                                            onlySavePaymentPaidedAmount
                                          }
                                          onChange={(e) => { setOnlySavePaymentPaidedAmount(e.target.value) }

                                          }

                                        // onChange={handleCashPaymentDetailsChange}
                                        // onBlur={calculateReturnToCustomer}
                                        />
                                        <span className="input-group-text">
                                          {currencySymbol}
                                        </span>
                                      </div>
                                    </div>


                                    <div className="col-12  mt-3">
                                      <label className="">Tip</label>
                                      <div className="input-group">
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="Enter Tip"
                                          name="tip"
                                          // value={upiPaymentDetails.tip}
                                          // onChange={
                                          //   handleUPIPaymentDetailsChange
                                          // }

                                          value={onlySavePaymentTipAmount}
                                          onChange={(e) => { handleUPIPaymentDetailsChange(e); setOnlySavePaymentTipAmount(e.target.value); }}

                                          onBlur={calculateFinalAmountForUPI}
                                        />
                                        <span className="input-group-text">
                                          {currencySymbol}
                                        </span>
                                      </div>
                                    </div>



                                    <div className="col-12  mt-3">
                                      <label className="">Note</label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Note"
                                        name="note"
                                        // value={upiPaymentDetails.note}
                                        // onChange={handleUPIPaymentDetailsChange}
                                        value={onlySavePaymentNotes}
                                        onChange={
                                          (e) => {
                                            handleUPIPaymentDetailsChange(e);
                                            setOnlySavePaymentNotes(e.target.value);
                                          }

                                        }
                                      />
                                    </div>

                                    <div className="col-12  mt-3">
                                      <label className="">Transaction / Reference ID</label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Transaction / Reference ID"
                                        name="transaction_id"
                                        value={paymentSettleTransactionId}

                                        onChange={
                                          (e) => {
                                            setPaymentSettleTransactionId(e.target.value);
                                          }

                                        }
                                      />
                                    </div>



                                    {/* <div className="col-12  mt-3">
                                      <label className="">Amount</label>
                                      <div className="input-group">
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="Enter Amount"
                                          name="amount"
                                          value={
                                            // upiPaymentDetails.amount
                                            onlySavePaymentPaidedAmount

                                          }
                                          readOnly={true}
                                        />
                                        <span className="input-group-text">
                                          {currencySymbol}
                                        </span>
                                      </div>
                                    </div> */}
                                  </div>
                                  {/* {onlySavePaymentData.length <= 0 && (<button
                                    className="btn btn-primary mt-3"
                                    onClick={handleSettleAndSaveForUPIPayment}
                                  >
                                    Settle & Save
                                  </button>)} */}

                                </>
                              )}

                              {selectedPaymentOption === "Hold" && (
                                <>
                                  <div className="row">
                                    <div className="col-12  mt-3">
                                      <label className="">
                                        Reason
                                        <span className="text-danger">*</span>
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Reason"
                                        value={unpaidReason}
                                        onChange={(e) =>
                                          setUnpaidReason(e.target.value)
                                        }
                                      />
                                    </div>
                                  </div>
                                  <button
                                    className="btn btn-primary mt-3"
                                    onClick={
                                      handleSettleAndSaveForUnpaidPayment
                                    }
                                  >
                                    Save
                                  </button>
                                </>
                              )}

                              {selectedPaymentOption !== "Hold" && onlySavePaymentData.length <= 0 && (

                                <>
                                  <button
                                    className="btn btn-primary mt-3"
                                    onClick={handleOnlySavePayment}
                                    type="button"
                                    disabled={
                                      onlySavePaymentPaidedAmount <= 0

                                    }
                                  >
                                    {/* Save T */}
                                    Pay
                                  </button> {" "}
                                  {orderList[selectedTable]?.orderId ? <a className="btn btn-dark mt-3" href={"/order_details?id=" + orderList[selectedTable]?.orderId}>
                                    Split Bill
                                  </a> : <></>} {" "}


                                  {orderList[selectedTable]?.orderId && whatsappApiAccessToken && whatsappMassageingApiUrl && sendWhatesappMessage === "1" && customerForm.mobile ?
                                    <button
                                      className=" btn btn-info mt-3"
                                      onClick={sendCreateOrderWhateappMassage}
                                      type="button"

                                    >
                                      <i className="mdi mdi-whatsapp vsalign"></i>{" "}
                                      Send Details
                                    </button> : <></>}
                                  {" "}

                                  {/* {orderList[selectedTable]?.orderId && whatsappApiAccessToken && whatsappMassageingApiUrl && sendWhatesappMessage === "1" && fooderUpiId && customerForm.mobile ?
                                    <button
                                      className="btn btn-info mt-3"
                                      onClick={sendOrderPaymentWhateappMassage}
                                      type="button"
                                    >
                                      <i className="mdi mdi-whatsapp vsalign"></i>{" "}
                                      Send Payment
                                    </button> : <></>} */}


                                  {/* <div className="form-check form-check-inline mx-2">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      name="delivery"
                                      defaultValue="delivery"
                                      checked={onlySavePaymentRoundUpCheck}
                                      onChange={handleRoundupAmountCheck}
                                    />
                                    <label className="form-check-label">Click here to Round Off amount.</label>
                                  </div> */}

                                </>


                              )}

                              {selectedPaymentOption !== "Hold" && onlySavePaymentData.length > 0 && (

                                <button
                                  className="btn btn-primary mt-1"
                                  onClick={handleOnlySavePayment}
                                  type="button"
                                  disabled={
                                    // onlySavePaymentPaidedAmount <= 0
                                    totalAmountPaid - onlySavePaymentTotalPaidedAmount <= 0 || onlySavePaymentPaidedAmount <= 0

                                  }
                                >
                                  Add
                                </button>
                              )}





                            </form>
                            {/* {/* <br /> */}

                            {onlySavePaymentData.length > 0 && (<>
                              <div className="itemdiv">
                                <div className="row">

                                  <div className="col-lg-4">
                                    <p className="itemnm">Payment Method</p>
                                  </div>

                                  <div className="col-lg-3">
                                    <p className="itemnm">Amount</p>
                                  </div>

                                  <div className="col-lg-3">
                                    <p className="itemnm">Tip Amount</p>
                                  </div>

                                  <div className="col-lg-2">
                                    <p className="itemnm">Action</p>
                                  </div>

                                </div>


                              </div>
                            </>)}



                            {onlySavePaymentData.map((item, index) => (
                              <div className="itemdiv" key={index}>

                                <div className="row">
                                  <div className="col-lg-4">
                                    <p>{item.method === "UPI" ? item.method + " by " + item.upiType : item.method}</p>
                                  </div>
                                  <div className="col-lg-3">

                                    <p>{item.amount}</p>

                                  </div>
                                  <div className="col-lg-3">
                                    <p>{item.tip}</p>
                                  </div>
                                  <div className="col-lg-2">
                                    <span className="" style={{ cursor: "pointer" }} onClick={() => { handleOnlySavePaymentRemove(index) }}>
                                      <i className="fa fa-window-close closeicon" aria-hidden="true" />
                                    </span>
                                  </div>
                                </div>
                              </div>
                            ))}



                            {onlySavePaymentData.length > 0 && (<>

                              <button
                                className="btn btn-success mt-3"
                                onClick={submitpartialPayment}
                              >
                                Paid
                              </button>
                            </>)}






                          </div>
                        </div>
                      </>
                    )}
                  </div>
                )}

              </div>
            </div>
          </div>

          {/* <div
            className={isModal ? "modal fade show d-block" : "modal fade"}
            id="exampleModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content w-85">
                <div className="modal-header py-2">
                  <h5 className="modal-title" id="exampleModalLabel">
                    {selectedProduct
                      ? selectedProduct.name.length > 30
                        ? capitalizeFirstLetter(
                          selectedProduct.name.substring(0, 30) + "..."
                        )
                        : capitalizeFirstLetter(selectedProduct.name)
                      : ""}
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() => {
                      playSound();
                      setIsModal(false);
                    }}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body py-2">
                  <div className="container">
                    <div className="row">
                      <div className="col-sm-4 d-flex align-items-center">
                        <h5>Quantity</h5>
                      </div>
                      <div className="col-sm-8">
                        <button
                          type="button"
                          className="btn btn-outline-secondary btn-icon ctlbtn"
                          onClick={() => updateQuantity(false)}
                        >
                          <i className="fa fa-minus"></i>
                        </button>
                        &nbsp;
                        <button
                          type="button"
                          className="btn btn-outline-secondary btn-icon ctlbtn"
                        >
                          <span>
                            {selectedProduct ? selectedProduct.quantity : ""}
                          </span>
                        </button>
                        &nbsp;
                        <button
                          type="button"
                          className="btn btn-outline-secondary btn-icon ctlbtn"
                          onClick={() => updateQuantity(true)}
                        >
                          <i className="fa fa-plus"></i>
                        </button>
                      </div>
                    </div>
                    <div className="row my-2">
                      <div className="col-sm-4 d-flex align-items-center">
                        <h5>Special Note</h5>
                      </div>
                      <div className="col-sm-8">
                        <div className="form-group mb-0">
                          <textarea
                            className="form-control"
                            placeholder="Enter Special Note"
                            rows={4}
                            value={productNote}
                            onChange={handleSaveProductNote}
                            maxLength={maxCharacterLimit}
                          ></textarea>
                          <p className="text-right m-0">
                            {productNote.length}/{maxCharacterLimit}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer py-1">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-dismiss="modal"
                    onClick={() => {
                      playSound();
                      setIsModal(false);
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={handleAddToOrder}
                  >
                    Add
                  </button>
                </div>
              </div>
            </div>
          </div> */}

          <div
            className={switchTableModel ? "modal fade show vrttcrd d-block" : "modal fade"}
            id="exampleModal"
            tabIndex={-1}
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-lg" role="document">
              <div className="modal-content w-85">
                <div className="modal-header py-2">
                  <h5 className="modal-title" id="exampleModalLabel">
                    Select Table
                  </h5>

                  {/* <div className="customchk mb-3">
                    <div className="form-check form-check-inline">
                      <label className="form-check-label">
                        <span className="checkcss checkbox1"></span>{" "}
                        Blank
                      </label>
                    </div>

                    <div className="form-check form-check-inline">
                      <label className="form-check-label">
                        <span className="checkcss checkbox2"></span>{" "}
                        Running
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <label className="form-check-label">
                        <span className="checkcss checkbox5"></span>{" "}
                        Running KOT
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <label className="form-check-label">
                        <span className="checkcss checkbox4"></span>{" "}
                        Printed
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <label className="form-check-label">
                        <span className="checkcss checkbox8"></span>{" "}
                        Current
                      </label>
                    </div>
                  </div> */}

                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() => {
                      if (switchTableWithAllTable) {
                        handleTableView();
                      }
                      setSwitchTableModel(false);
                      playSound();
                    }}
                  >
                    <span aria-hidden="true">×</span>
                  </button>

                </div>


                <div className="modal-body py-2">

                  {allFreeTable.map(
                    (floor, index) => (
                      <div key={index}>
                        <div className="row flex-grow">
                          <div>
                            <h4 className="mb-3">
                              {floor.table_categoryName}
                            </h4>
                            <div className="row stretch-card mr10 ">
                              {floor.table_categoryName_data.map(
                                (table, tableIndex) => (
                                  <div
                                    key={tableIndex}
                                    className="col-lg-3 stretch-card"
                                    style={{
                                      cursor: "pointer",
                                    }}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      // playSound();
                                      // setSelectedTableDetails(
                                      //   table
                                      // );
                                      // setOrderType(
                                      //   "DINE IN"
                                      // );
                                      // setActiveTab(
                                      //   "tables"
                                      // );
                                      // setTableView(false);
                                      handleTableSwitch(table)
                                    }}
                                  >
                                    <div className="card card-rounded grid-margin">
                                      <div
                                        className={
                                          table.id ===
                                            selectedTable
                                            ? "card-body active3"
                                            : orderList[
                                              table.id
                                            ]?.isPrinted
                                              ? "card-body active"
                                              : isAnyItemKOTForTable(
                                                table.id
                                              )
                                                ? "card-body active2"
                                                : orderList[
                                                  table.id
                                                ]?.orders &&
                                                  orderList[
                                                    table.id
                                                  ]?.orders
                                                    .length > 0
                                                  ? "card-body active1"
                                                  : "card-body"
                                        }
                                      >


                                        {/* {orderList[table.id]
                                          ?.isPrinted ? (
                                          <span className="tblicon">
                                            &nbsp;&nbsp;
                                            <i
                                              className="fa fa-save"
                                              onClick={(
                                                e
                                              ) => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                playSound();
                                                setSelectedTableDetails(
                                                  table
                                                );
                                                
                                                setIsSettleModal(
                                                  true
                                                );
                                              }}
                                            ></i>
                                            &nbsp;&nbsp;&nbsp;&nbsp;
                                            <i
                                              className="fa fa-print"
                                              onClick={(
                                                e
                                              ) => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                playSound();
                                                 
                                                handlePrintIconClick(
                                                  table
                                                );
                                              }}
                                            ></i>
                                          </span>
                                        ) : orderList[
                                          table.id
                                        ]?.orders &&
                                          orderList[
                                            table.id
                                          ]?.orders.length >
                                          0 ? (
                                          <>
                                            <span className="tblicon">
                                              <i className="fa fa-eye"></i>
                                            </span>

                                         
                                          </>
                                        ) : (
                                          ""
                                        )} */}

                                        <span className="name">
                                          {table.table_no}
                                        </span>
                                        {/* {orderList[table.id]
                                          ?.orders &&
                                          orderList[
                                            table.id
                                          ]?.orders.length >
                                          0 && (
                                            <p className="price">
                                              {
                                                currencySymbol
                                              }
                                              {getTotalAmountofTable(
                                                table.id
                                              )}

                                            </p>
                                          )} */}
                                      </div>
                                    </div>
                                  </div>
                                )
                              )}
                            </div>
                          </div>
                        </div>
                        {index <
                          allFreeTable.length -
                          1 && (
                            <hr className="dottedhr" />
                          )}
                      </div>
                    )
                  )}

                </div>

                <div className="modal-footer pymtftr">
                  <div className="row" style={{ width: "100%" }}>
                    <div className="col-6 valign">

                    </div>
                    <div className="col-lg-6 text-right">
                      <button
                        type="button"
                        className="btn btn-secondary"
                        data-dismiss="modal"
                        onClick={() => {
                          if (switchTableWithAllTable) {
                            handleTableView();
                          }
                          setSwitchTableModel(false);
                          playSound();
                        }}
                      >
                        Cancel
                      </button>{" "}

                    </div>
                  </div>
                </div>

              </div>


            </div>


          </div>



          <div className={isModal ? "modal fade show vrttcrd d-block" : "modal fade"} id="exampleModal" tabIndex={-1}
            role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            {/* <div className={"modal-dialog modal-lg"} role="document"> */}
            <div className={productVariantAttributesData.length > 0 || productVariantAddonsData.length > 0 ? "modal-dialog modal-lg" : "modal-dialog"} role="document">

              <div className="modal-content w-85">
                <div className="modal-header py-2">
                  <h5 className="modal-title" id="exampleModalLabel">
                    {selectedProduct
                      ? selectedProduct.name.length > 30
                        ? capitalizeFirstLetter(
                          selectedProduct.name.substring(0, 30) + "..."
                        )
                        : capitalizeFirstLetter(selectedProduct.name)
                      : ""}
                  </h5>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => {
                    playSound();
                    setIsModal(false);
                  }}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>

                </div>

                <div className="modal-body py-2">
                  <div className="container">
                    <div className="row">
                      <div className="col-sm-4 d-flex align-items-center">
                        <h5>Quantity</h5>
                      </div>
                      <div className="col-sm-8">
                        <button type="button" className="btn btn-outline-secondary btn-icon ctlbtn" onClick={() =>
                          updateQuantity(false, selectedProduct.min_order_quantity)}
                        >
                          <i className="fa fa-minus"></i>
                        </button>
                        &nbsp;
                        <button type="button" className="btn btn-outline-secondary btn-icon ctlbtn">
                          <span>
                            {selectedProduct ? selectedProduct.quantity : ""}
                          </span>
                        </button>
                        &nbsp;
                        <button type="button" className="btn btn-outline-secondary btn-icon ctlbtn" onClick={() =>
                          updateQuantity(true, selectedProduct.min_order_quantity)}
                        >
                          <i className="fa fa-plus"></i>
                        </button>
                      </div>
                    </div>








                    <div className="row my-2">
                      <div className="col-sm-4 d-flex align-items-center">
                        <h5>Special Note</h5>
                      </div>
                      <div className="col-sm-8">
                        <div className="form-group mb-0">
                          <textarea className="form-control" placeholder="Enter Special Note" rows={4} value={productNote}
                            onChange={handleSaveProductNote} maxLength={maxCharacterLimit}></textarea>
                          <p className="text-right m-0">
                            {productNote.length}/{maxCharacterLimit}
                          </p>
                        </div>
                      </div>
                    </div>


                    {productVariantAttributesData.map((i, iCount) => {
                      return (
                        <div className="vrntcss mb-3" key={iCount}>
                          <div className="row">
                            <div className="col-8">
                              <h5 className="pt-1">{i.attributeName}</h5>
                            </div>
                          </div>
                          <div className="row mt-1 ">
                            {i.value.map((j, jCount) => {
                              return (

                                <div className="col-md-3 my-1" key={jCount}>
                                  <div className="card card-rounded ">
                                    <div className={selectedProductVariantAttributesData[iCount] === j.attributeValueId
                                      ? "card-body active custom-cursor-pointer" : "card-body custom-cursor-pointer"} onClick={() => {
                                        changeSelectedVariants(iCount, j.attributeValueId)
                                      }}
                                    >
                                      <span className="fw-bold">{j.attributeValue}</span>
                                      <br />
                                      {/* {iCount + 1 === i.length ? <p className="itemno mb-0 mt-1">{currencySymbol}
                      {productVariantPropertiesPriceArray[jCount]}</p> : <></>} */}
                                      {iCount === selectedProductVariantAttributesData.length - 1 ? <>

                                        <p className="itemno mb-0 mt-1">{currencySymbol}{productVariantPropertiesPriceArray[jCount]}</p>

                                      </> : <></>}
                                    </div>
                                  </div>
                                </div>


                              )
                            })}
                          </div>
                        </div>
                      )
                    })}




                    {productVariantAddonsData.map((i, iCount) => {
                      if (selectedProductVariant.variantId === i.addonVariantId) {
                        return (
                          <div className="vrntcss mb-3" key={iCount}>
                            <div className="row">
                              <div className="col-8">
                                <h5 className="pt-1">{i.addon_group_name}</h5>
                              </div>
                            </div>
                            <div className="row mt-1">
                              {i.addonItems.map((j, jCount) => (
                                <div className="col-md-3 my-1" key={jCount}>
                                  <div className="card card-rounded custom-cursor-pointer">
                                    <div className={selectedProductVariantAddonsIdArray.indexOf(j.addonItemId) !== -1
                                      ? "card-body active custom-cursor-pointer" : "card-body custom-cursor-pointer"} onClick={() => {
                                        selectedProductVariantAddonsIdArray.indexOf(j.addonItemId) !== -1
                                          ? changeSelectedAddons(false, j, i.minimum_item, i.maximum_item, i.addonId)
                                          : changeSelectedAddons(true, j, i.minimum_item, i.maximum_item, i.addonId)
                                      }}>



                                      <span className="fw-bold">{j.addon_item_name}</span>
                                      <br />
                                      <p className="itemno mb-0 mt-1">{currencySymbol}{j.addon_item_price}</p>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                            {i.minimum_item && <p>Add up to more then {i.minimum_item ? i.minimum_item - 1 : 0} items</p>}
                          </div>
                        );
                      } else {
                        return null;
                      }
                    })}

                    {productVariantAttributesData.length <= 0 && <>
                      {productVariantAddonsData.map((i, iCount) => {
                        return (

                          i.addonId !== null &&
                          <div className="vrntcss mb-3" key={iCount}>
                            <div className="row">
                              <div className="col-8">
                                <h5 className="pt-1">{i.addon_group_name}</h5>
                              </div>
                            </div>
                            <div className="row mt-1">
                              {i.addonItems.map((j, jCount) => (
                                <div className="col-md-3 my-1" key={jCount}>
                                  <div className="card card-rounded custom-cursor-pointer">
                                    <div className={selectedProductVariantAddonsIdArray.indexOf(j.addonItemId) !== -1
                                      ? "card-body active custom-cursor-pointer" : "card-body custom-cursor-pointer"} onClick={() => {
                                        selectedProductVariantAddonsIdArray.indexOf(j.addonItemId) !== -1
                                          ? changeSelectedAddons(false, j, i.minimum_item, i.maximum_item, i.addonId)
                                          : changeSelectedAddons(true, j, i.minimum_item, i.maximum_item, i.addonId)
                                      }}>



                                      <span className="fw-bold">{j.addon_item_name}</span>
                                      <br />
                                      <p className="itemno mb-0 mt-1">{currencySymbol}{j.addon_item_price}</p>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                            {i.minimum_item && <p>Add up to more then {i.minimum_item ? i.minimum_item - 1 : 0} items</p>}
                          </div>

                        )



                      })}
                    </>}




                  </div>


                </div>



                <div className="modal-footer pymtftr">
                  <div className="row" style={{ width: "100%" }}>
                    <div className="col-6 valign">
                      <div  >

                        {parseFloat(selectedProductVariantAddonsPrice) + parseFloat(selectedProductVariant.combination_price) ?

                          <h5>
                            {currencySymbol}{(
                              (
                                parseFloat(selectedProductVariantAddonsPrice) +
                                parseFloat(selectedProductVariant.combination_price)
                              ) * (selectedProduct ? parseFloat(selectedProduct.quantity) : 1)
                            ).toFixed(2)
                            }

                          </h5>

                          : <h5>

                            {currencySymbol}{(selectedPrductForAddedVariants.proprice ?
                              ((parseFloat(selectedPrductForAddedVariants.proprice) + parseFloat(selectedProductVariantAddonsPrice)) * (selectedProduct ? parseFloat(selectedProduct.quantity) : 1)).toFixed(2) :
                              ((parseFloat(selectedPrductForAddedVariants.price) + parseFloat(selectedProductVariantAddonsPrice)) * (selectedProduct ? parseFloat(selectedProduct.quantity) : 1)).toFixed(2))}

                          </h5>
                        }


                      </div>
                    </div>
                    <div className="col-lg-6 text-right">
                      <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={() => {
                        playSound();
                        setIsModal(false);
                      }}
                      >
                        Cancel
                      </button>{' '}
                      <button type="button" className="btn btn-primary" onClick={handleAddToOrder}>
                        Add
                      </button>
                    </div>
                  </div>


                </div>









                {/* <div className="modal-footer py-1">
                  {parseFloat(selectedProductVariantAddonsPrice) + parseFloat(selectedProductVariant.combination_price) ?
                    <h6>{currencySymbol}{(parseFloat(selectedProductVariantAddonsPrice) +
                      parseFloat(selectedProductVariant.combination_price)).toFixed(2)}</h6>
                    : <h6>{currencySymbol}{selectedPrductForAddedVariants.proprice ? selectedPrductForAddedVariants.proprice :
                      selectedPrductForAddedVariants.price}</h6>
                  }


                  <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={() => {
                    playSound();
                    setIsModal(false);
                  }}
                  >
                    Cancel
                  </button>
                  <button type="button" className="btn btn-primary" onClick={handleAddToOrder}>
                    Add
                  </button>
                </div> */}
              </div>
            </div>
          </div>







          <div
            className={
              isKOTItemDeleteModal ? "modal fade show d-block" : "modal fade"
            }
            id="exampleModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content w-85">
                <div className="modal-header py-2">
                  <h5 className="modal-title" id="exampleModalLabel">
                    Are you sure ? You want to delete this item ?
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() => {
                      playSound();
                      setIsKOTItemDeleteModal(false);
                    }}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body py-2">
                  <div className="container">
                    <div className="row">
                      <div className="row my-2">
                        <div className="col-sm-2 d-flex align-items-center">
                          <h5>Reason</h5>
                        </div>
                        <div className="col-sm-10">
                          <div className="form-group mb-0">
                            <textarea
                              className="form-control"
                              placeholder="Enter Reason"
                              rows={4}
                              value={deleteReason}
                              onChange={handleKOTItemDeleteReason}
                              maxLength={maxCharacterLimit}
                            ></textarea>
                            <p className="text-right m-0">
                              {deleteReason.length}/{maxCharacterLimit}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer py-1">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-dismiss="modal"
                    onClick={() => {
                      playSound();
                      setIsKOTItemDeleteModal(false);
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={handleKOTItemDelete}
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div
            className={isSettleModal ? "modal fade show d-block" : "modal fade"}
            id="exampleModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content w-85">
                <div className="modal-header py-2">
                  <h5 className="modal-title" id="exampleModalLabel">
                    Bill Settlement
                  </h5>

                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() => {
                      playSound();
                      setIsSettleModal(false);
                      setSelectedTableDetails(null);
                      setSelectedTable("");
                    }}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body py-2">
                  <div className="container">
                    <form className="forms-sample">
                      <h4 className="card-title m-3 fw-bold ms-0">
                        Select Payment Option
                      </h4>
                      <h6 className="card-title m-3 fw-bold ms-0">
                        Total Amount: ₹ {(parseFloat(totalAmountPaid) + parseFloat(onlySavePaymentRoundUpAmount)).toFixed(2)}
                      </h6>
                      <div className="row">
                        <div className="">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="inlineRadioOptions"
                              value="Cash"
                              checked={selectedPaymentOption === "Cash"}
                              onChange={handlePaymentOptionChange}
                            />
                            <label className="form-check-label">Cash</label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="inlineRadioOptions"
                              value="Card"
                              checked={selectedPaymentOption === "Card"}
                              onChange={handlePaymentOptionChange}
                            />
                            <label className="form-check-label">Card</label>
                          </div>

                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="inlineRadioOptions"
                              value="NEFT"
                              checked={selectedPaymentOption === "NEFT"}
                              onChange={handlePaymentOptionChange}
                            />
                            <label className="form-check-label">NEFT</label>
                          </div>

                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="inlineRadioOptions"
                              value="UPI"
                              checked={selectedPaymentOption === "UPI"}
                              onChange={handlePaymentOptionChange}
                            />
                            <label className="form-check-label">UPI</label>
                          </div>

                          {onlySavePaymentData.length <= 0 && <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="inlineRadioOptions"
                              value="Hold"
                              checked={selectedPaymentOption === "Hold"}
                              onChange={handlePaymentOptionChange}
                            />
                            <label className="form-check-label">Hold</label>
                          </div>}



                          {/* <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="inlineRadioOptions"
                                value="Part"
                                checked={selectedPaymentOption === "Part"}
                                onChange={handlePaymentOptionChange}
                              />
                              <label className="form-check-label">Part</label>
                            </div> */}
                        </div>
                      </div>
                      {selectedPaymentOption === "Cash" && (
                        <>
                          <div className="row">
                            <div className="col-12  mt-3">
                              <label className="">
                                Customer Paid
                                <span className="text-danger">*</span>
                              </label>
                              <div className="input-group">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter Customer Paid"
                                  name="customerPaid"
                                  // value={cashPaymentDetails.customerPaid}
                                  // onChange={handleCashPaymentDetailsChange}
                                  value={
                                    // cashPaymentDetails.customerPaid
                                    onlySavePaymentPaidedAmount
                                  }
                                  onChange={(e) => { handleCashPaymentDetailsChange(e); setOnlySavePaymentPaidedAmount(e.target.value) }

                                  }
                                // onBlur={calculateReturnToCustomer}
                                />
                                <span className="input-group-text">
                                  {currencySymbol}
                                </span>
                              </div>
                            </div>
                            <div className="col-12  mt-3">
                              <label className="">Tip</label>
                              <div className="input-group">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter Tip"
                                  name="tip"
                                  // value={cashPaymentDetails.tip}
                                  // onChange={handleCashPaymentDetailsChange}
                                  value={onlySavePaymentTipAmount}
                                  onChange={
                                    (e) => {
                                      handleCashPaymentDetailsChange(e)
                                      setOnlySavePaymentTipAmount(e.target.value)
                                    }

                                  }

                                  onBlur={calculateFinalAmountForCash}
                                />
                                <span className="input-group-text">
                                  {currencySymbol}
                                </span>
                              </div>
                            </div>
                            <div className="col-12  mt-3">
                              <label className="">Transaction / Reference ID</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Transaction / Reference ID"
                                name="transaction_id"
                                value={paymentSettleTransactionId}

                                onChange={
                                  (e) => {
                                    setPaymentSettleTransactionId(e.target.value);
                                  }

                                }
                              />
                            </div>
                            {/* <div className="col-12  mt-3">
                              <label className="">Amount</label>
                              <div className="input-group">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter Amount"
                                  name="amount"
                                  value={cashPaymentDetails.amount}
                                  readOnly={true}
                                />
                                <span className="input-group-text">
                                  {currencySymbol}
                                </span>
                              </div>
                            </div> */}
                          </div>
                          {/* <div className="row">

                            <div className="col-12  mt-3">
                              <label className="">
                                {parseFloat(cashPaymentDetails.customerPaid) <
                                  parseFloat(cashPaymentDetails.amount)
                                  ? "Due"
                                  : !isValidNumber(
                                    cashPaymentDetails.customerPaid
                                  )
                                    ? "Due"
                                    : "Return to Customer"}
                              </label>
                              <div className="input-group">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter Return to Customer"
                                  name="returnToCustomer"
                                  value={cashPaymentDetails.returnToCustomer}
                                  readOnly={true}
                                />
                                <span className="input-group-text">
                                  {currencySymbol}
                                </span>
                              </div>
                            </div>
                          </div> */}

                          {onlySavePaymentData.length > 0 ?


                            <div className="row">



                              <div className="col-12  mt-3">
                                {/* <label className="">
      {parseFloat(
        onlySavePaymentTotalPaidedAmount
      ) <
        parseFloat(totalAmountPaid)
        ? "Due"
        : !isValidNumber(
          onlySavePaymentTotalPaidedAmount
        )
          ? "Due"
          : "Return to Customer"}
    </label> */}

                                <label className="">
                                  {parseFloat(onlySavePaymentTotalPaidedAmount) + parseFloat(onlySavePaymentPaidedAmount) < parseFloat(totalAmountPaid)
                                    ? "Due"


                                    : "Return to Customer"}
                                </label>


                                <div className="input-group">
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Return to Customer"
                                    name="returnToCustomer"
                                    value={
                                      // Math.abs((totalAmountPaid - onlySavePaymentTotalPaidedAmount) - onlySavePaymentPaidedAmount)
                                      Math.abs(parseFloat((totalAmountPaid - onlySavePaymentTotalPaidedAmount) - onlySavePaymentPaidedAmount + onlySavePaymentRoundUpAmount).toFixed(2))
                                    }
                                    readOnly={true}
                                  />
                                  <span className="input-group-text">
                                    {currencySymbol}
                                  </span>
                                </div>
                              </div>
                            </div>






                            : <div className="row">



                              <div className="col-12  mt-3">
                                <label className="">
                                  {parseFloat(
                                    cashPaymentDetails.customerPaid
                                  ) <
                                    parseFloat(cashPaymentDetails.amount)
                                    ? "Due"
                                    : !isValidNumber(
                                      cashPaymentDetails.customerPaid
                                    )
                                      ? "Due"
                                      : "Return to Customer"}
                                </label>
                                <div className="input-group">
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Return to Customer"
                                    name="returnToCustomer"
                                    value={
                                      // cashPaymentDetails.returnToCustomer
                                      Math.abs(parseFloat((totalAmountPaid - onlySavePaymentTotalPaidedAmount) - onlySavePaymentPaidedAmount + onlySavePaymentRoundUpAmount).toFixed(2))

                                    }

                                    readOnly={true}
                                  />
                                  <span className="input-group-text">
                                    {currencySymbol}
                                  </span>
                                </div>
                              </div>
                            </div>
                          }
                        </>
                      )}

                      {selectedPaymentOption === "Card" && (
                        <>
                          <div className="row">
                            <div className="col-12  mt-3">
                              <label className="">
                                Customer Paid
                                <span className="text-danger">*</span>
                              </label>
                              <div className="input-group">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter Customer Paid"
                                  name="customerPaid"
                                  // value={cardPaymentDetails.customerPaid}
                                  // onChange={handleCardPaymentDetailsChange}
                                  value={
                                    // cardPaymentDetails.customerPaid
                                    onlySavePaymentPaidedAmount

                                  }
                                  onChange={(e) => { handleCardPaymentDetailsChange(e); setOnlySavePaymentPaidedAmount(e.target.value) }}
                                />
                                <span className="input-group-text">
                                  {currencySymbol}
                                </span>
                              </div>
                            </div>
                            <div className="col-12  mt-3">
                              <label className="">Tip</label>
                              <div className="input-group">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter Tip"
                                  name="tip"
                                  // value={cardPaymentDetails.tip}
                                  // onChange={handleCardPaymentDetailsChange}
                                  // onBlur={calculateFinalAmountForCard}

                                  // value={cardPaymentDetails.tip}
                                  value={onlySavePaymentTipAmount}


                                  onChange={(e) => { handleCardPaymentDetailsChange(e); setOnlySavePaymentTipAmount(e.target.value) }}
                                  onBlur={calculateFinalAmountForCard}
                                />
                                <span className="input-group-text">
                                  {currencySymbol}
                                </span>
                              </div>
                            </div>
                            <div className="col-12  mt-3">
                              <label className="">Transaction / Reference ID</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Transaction / Reference ID"
                                name="transaction_id"
                                value={paymentSettleTransactionId}

                                onChange={
                                  (e) => {
                                    setPaymentSettleTransactionId(e.target.value);
                                  }

                                }
                              />
                            </div>
                            <div className="col-12  mt-3">
                              <label className="">Note</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Note"
                                name="note"
                                // value={cardPaymentDetails.note}
                                // onChange={handleCardPaymentDetailsChange}

                                value={onlySavePaymentNotes}
                                onChange={
                                  (e) => {
                                    handleCardPaymentDetailsChange(e);
                                    setOnlySavePaymentNotes(e.target.value);
                                  }

                                }

                              />
                            </div>
                            {/* <div className="col-12  mt-3">
                              <label className="">Amount</label>
                              <div className="input-group">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter Amount"
                                  name="amount"
                                  value={cardPaymentDetails.amount}
                                  readOnly={true}
                                />
                                <span className="input-group-text">
                                  {currencySymbol}
                                </span>
                              </div>
                            </div> */}
                          </div>
                        </>
                      )}

                      {selectedPaymentOption === "NEFT" && (
                        <>
                          <div className="row">
                            <div className="col-12  mt-3">
                              <label className="">
                                Customer Paid
                                <span className="text-danger">*</span>
                              </label>
                              <div className="input-group">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter Customer Paid"
                                  name="customerPaid"
                                  // value={neftPaymentDetails.customerPaid}
                                  // onChange={handleNeftPaymentDetailsChange}

                                  value={
                                    onlySavePaymentPaidedAmount
                                  }

                                  onChange={(e) => { handleNeftPaymentDetailsChange(e); setOnlySavePaymentPaidedAmount(e.target.value) }}

                                />
                                <span className="input-group-text">
                                  {currencySymbol}
                                </span>
                              </div>
                            </div>
                            <div className="col-12  mt-3">
                              <label className="">Tip</label>
                              <div className="input-group">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter Tip"
                                  name="tip"
                                  // value={neftPaymentDetails.tip}
                                  // onChange={handleNeftPaymentDetailsChange}
                                  // onBlur={calculateFinalAmountForNeft}

                                  value={onlySavePaymentTipAmount}
                                  onChange={(e) => { handleNeftPaymentDetailsChange(e); setOnlySavePaymentTipAmount(e.target.value) }}
                                  onBlur={calculateFinalAmountForNeft}


                                />
                                <span className="input-group-text">
                                  {currencySymbol}
                                </span>
                              </div>
                            </div>
                            <div className="col-12  mt-3">
                              <label className="">Transaction / Reference ID</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Transaction / Reference ID"
                                name="transaction_id"
                                value={paymentSettleTransactionId}

                                onChange={
                                  (e) => {
                                    setPaymentSettleTransactionId(e.target.value);
                                  }

                                }
                              />
                            </div>
                            <div className="col-12  mt-3">
                              <label className="">Note</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Note"
                                name="note"
                                // value={neftPaymentDetails.note}
                                // onChange={handleNeftPaymentDetailsChange}

                                value={onlySavePaymentNotes}
                                onChange={
                                  (e) => {
                                    handleNeftPaymentDetailsChange(e);
                                    setOnlySavePaymentNotes(e.target.value);
                                  }
                                }

                              />
                            </div>

                            {/* <div className="col-12  mt-3">
                              <label className="">Amount</label>
                              <div className="input-group">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter Amount"
                                  name="amount"
                                  value={neftPaymentDetails.amount}
                                  readOnly={true}
                                />
                                <span className="input-group-text">
                                  {currencySymbol}
                                </span>
                              </div>
                            </div> */}
                          </div>
                        </>
                      )}

                      {selectedPaymentOption === "UPI" && (
                        <>
                          <div className="row">
                            <div className="col-12  mt-3">
                              <label className="">
                                Select Method
                                <span className="text-danger">*</span>
                              </label>
                              <select
                                className="form-select"
                                name="method"
                                value={upiPaymentDetails.method}
                                onChange={handleUPIPaymentDetailsChange}
                              >
                                <option value="">Select Method</option>
                                <option value="Google Pay">Google Pay</option>
                                <option value="PhonePe">PhonePe</option>
                                <option value="Paytm">Paytm</option>
                                <option value="Other">Other</option>
                              </select>
                              {upiPaymentDetails.method === "Other" && (
                                <>
                                  &nbsp;
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Method"
                                    name="other_method"
                                    value={upiPaymentDetails.other_method}
                                    onChange={handleUPIPaymentDetailsChange}
                                  />
                                </>
                              )}
                            </div>


                            <div className="col-12  mt-3">
                              <label className="">
                                Customer Paid
                                <span className="text-danger">*</span>
                              </label>
                              <div className="input-group">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter Customer Paid"
                                  name="customerPaid"
                                  value={
                                    onlySavePaymentPaidedAmount
                                  }
                                  onChange={(e) => { setOnlySavePaymentPaidedAmount(e.target.value) }

                                  }

                                // onChange={handleCashPaymentDetailsChange}
                                // onBlur={calculateReturnToCustomer}
                                />
                                <span className="input-group-text">
                                  {currencySymbol}
                                </span>
                              </div>
                            </div>

                            <div className="col-12  mt-3">
                              <label className="">Tip</label>
                              <div className="input-group">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter Tip"
                                  name="tip"
                                  // value={upiPaymentDetails.tip}
                                  // onChange={handleUPIPaymentDetailsChange}
                                  // onBlur={calculateFinalAmountForUPI}

                                  value={onlySavePaymentTipAmount}
                                  onChange={(e) => { handleUPIPaymentDetailsChange(e); setOnlySavePaymentTipAmount(e.target.value); }}

                                  onBlur={calculateFinalAmountForUPI}
                                />
                                <span className="input-group-text">
                                  {currencySymbol}
                                </span>
                              </div>
                            </div>
                            <div className="col-12  mt-3">
                              <label className="">Transaction / Reference ID</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Transaction / Reference ID"
                                name="transaction_id"
                                value={paymentSettleTransactionId}

                                onChange={
                                  (e) => {
                                    setPaymentSettleTransactionId(e.target.value);
                                  }

                                }
                              />
                            </div>
                            <div className="col-12  mt-3">
                              <label className="">Note</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Note"
                                name="note"
                                // value={upiPaymentDetails.note}
                                // onChange={handleUPIPaymentDetailsChange}

                                value={onlySavePaymentNotes}
                                onChange={
                                  (e) => {
                                    handleUPIPaymentDetailsChange(e);
                                    setOnlySavePaymentNotes(e.target.value);
                                  }

                                }
                              />
                            </div>

                            {/* <div className="col-12  mt-3">
                              <label className="">Amount</label>
                              <div className="input-group">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter Amount"
                                  name="amount"
                                  value={upiPaymentDetails.amount}
                                  readOnly={true}
                                />
                                <span className="input-group-text">
                                  {currencySymbol}
                                </span>
                              </div>
                            </div> */}
                          </div>
                        </>
                      )}

                      {selectedPaymentOption === "Hold" && (
                        <>
                          <div className="row">
                            <div className="col-12  mt-3">
                              <label className="">
                                Reason
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Reason"
                                value={unpaidReason}
                                onChange={(e) =>
                                  setUnpaidReason(e.target.value)
                                }
                              />
                            </div>
                          </div>
                        </>
                      )}
                    </form>
                  </div>
                </div>
                <div className="modal-footer py-1">
                  {/* {selectedPaymentOption !== "Hold" && onlySavePaymentData.length <= 0 && (
                    <div className="form-check form-check-inline mx-2">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="delivery"
                        defaultValue="delivery"
                        checked={onlySavePaymentRoundUpCheck}
                        onChange={handleRoundupAmountCheck}
                      />
                      <label className="form-check-label">Click here to Round Off amount.</label>
                    </div>
                  )} */}

                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-dismiss="modal"
                    onClick={() => {
                      playSound();
                      setIsSettleModal(false);
                      setSelectedTableDetails(null);
                      setSelectedTable("");
                    }}
                  >
                    Cancel
                  </button>
                  {/* {selectedPaymentOption === "Cash" && onlySavePaymentData.length <= 0 && (
                    <>
                      <button
                        className="btn btn-primary"
                        onClick={handleSettleAndSaveForCashPayment}
                      >
                        Settle & Save
                      </button>
                    </>
                  )} */}
                  {/* {selectedPaymentOption === "Card" && onlySavePaymentData.length <= 0 && (
                    <>
                      <button
                        className="btn btn-primary"
                        onClick={handleSettleAndSaveForCardPayment}
                      >
                        Settle & Save
                      </button>
                    </>
                  )} */}
                  {/* {selectedPaymentOption === "NEFT" && onlySavePaymentData.length <= 0 && (
                    <>
                      <button
                        className="btn btn-primary"
                        onClick={handleSettleAndSaveForNeftPayment}
                      >
                        Settle & Save
                      </button>
                    </>
                  )} */}
                  {/* {selectedPaymentOption === "UPI" && onlySavePaymentData.length <= 0 && (
                    <>
                      <button
                        className="btn btn-primary"
                        onClick={handleSettleAndSaveForUPIPayment}
                      >
                        Settle & Save
                      </button>
                    </>
                  )} */}
                  {selectedPaymentOption === "Hold" && (
                    <>
                      <button
                        className="btn btn-primary"
                        onClick={handleSettleAndSaveForUnpaidPayment}
                      >
                        Save
                      </button>
                    </>
                  )}

                  {selectedPaymentOption !== "Hold" && onlySavePaymentData.length <= 0 && (

                    <button
                      className="btn btn-primary"
                      onClick={handleOnlySavePayment}
                      type="button"
                      disabled={
                        onlySavePaymentPaidedAmount <= 0

                      }
                    >
                      {/* Save T */}
                      Pay

                    </button>


                  )}

                  {selectedPaymentOption !== "Hold" && onlySavePaymentData.length > 0 && (

                    <button
                      className="btn btn-primary mt-1"
                      onClick={handleOnlySavePayment}
                      type="button"
                      disabled={
                        // onlySavePaymentPaidedAmount <= 0
                        // totalAmountPaid - onlySavePaymentTotalPaidedAmount <= 0
                        totalAmountPaid - onlySavePaymentTotalPaidedAmount <= 0 || onlySavePaymentPaidedAmount <= 0
                      }
                    >
                      Add
                    </button>
                  )}


                </div>

                {onlySavePaymentData.length > 0 && (<>
                  <div className="itemdiv mx-2">
                    <div className="row">

                      <div className="col-lg-4">
                        <p className="itemnm">Payment Method</p>
                      </div>

                      <div className="col-lg-3">
                        <p className="itemnm">Amount</p>
                      </div>

                      <div className="col-lg-3">
                        <p className="itemnm">Tip Amount</p>
                      </div>

                      <div className="col-lg-2">
                        <p className="itemnm">Action</p>
                      </div>

                    </div>


                  </div>
                </>)}



                {onlySavePaymentData.map((item, index) => (
                  <div className="itemdiv mx-2" key={index}>

                    <div className="row">
                      <div className="col-lg-4">
                        <p>{item.method === "UPI" ? item.method + " by " + item.upiType : item.method}</p>
                      </div>
                      <div className="col-lg-3">

                        <p>{item.amount}</p>

                      </div>
                      <div className="col-lg-3">
                        <p>{item.tip}</p>
                      </div>
                      <div className="col-lg-2">
                        <span className="" style={{ cursor: "pointer" }} onClick={() => { handleOnlySavePaymentRemove(index) }}>
                          <i className="fa fa-window-close closeicon" aria-hidden="true" />
                        </span>
                      </div>
                    </div>
                  </div>
                ))}



                {onlySavePaymentData.length > 0 && (<>
                  <div className="modal-footer">
                    <button
                      className="btn btn-success  mx-2"
                      onClick={submitpartialPayment}
                    >
                      Paid
                    </button>
                  </div>


                </>)}
              </div>
            </div>
          </div>

          <div
            className="offcanvas offcanvas-start navmenu"
            tabIndex="-1"
            id="offcanvasExample"
            aria-labelledby="offcanvasExampleLabel"
          >
            <div className="offcanvas-header">
              <h5 className="offcanvas-title">All Menus</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              ></button>
            </div>
            <div className="offcanvas-body">
              <div>
                <ul className="list-group navli posli">
                  {planDetails && planDetails.dashboard === 1 && (
                    <li className="">
                      <a href="/dashboard">
                        <i className="mdi mdi-grid-large menu-icon me-2"></i>{" "}
                        Dashboard
                      </a>
                    </li>
                  )}

                  {planDetails && planDetails.live_orders === 1 && (
                    <li className="">
                      <a href="/live_orders">
                        <i className="mdi mdi-speedometer-medium menu-icon me-2"></i>{" "}
                        Live Orders
                      </a>
                    </li>
                  )}

                  {planDetails && planDetails.staff_manager === 1 && (
                    <li className="">
                      <a href="/view_staff">
                        <i className="menu-icon mdi mdi-account me-2"></i> Staff
                      </a>
                    </li>
                  )}

                  {planDetails && planDetails.menu_products === 1 && (
                    <li className="">
                      <a
                        data-bs-toggle="collapse"
                        href="#ui-basic1"
                        aria-expanded="false"
                        aria-controls="ui-basic1"
                      >
                        <i className="menu-icon mdi mdi-floor-plan me-2"></i>{" "}
                        Manage Dishes
                        <i className="menu-icon menu-arrow"></i>
                      </a>
                      <div className="collapse" id="ui-basic1">
                        <ul className="nav flex-column sub-menu">
                          <li className="">
                            <a href="/view_menus">
                              <i className="menu-icon mdi mdi-floor-plan me-2"></i>{" "}
                              Category/Menu
                            </a>
                          </li>
                          <li className="">
                            <a href="/view_products">
                              <i className="menu-icon mdi mdi-silverware-fork-knife me-2"></i>{" "}
                              Dishes/Products
                            </a>
                          </li>
                          <li className="">
                            <a href="/add_ons">
                              <i className="menu-icon mdi mdi-cookie me-2"></i>{" "}
                              Add Ons
                            </a>
                          </li>
                        </ul>
                      </div>
                    </li>
                  )}

                  {planDetails && planDetails.orders === 1 && (
                    <li className="">
                      <a href="/orders">
                        <i className="menu-icon mdi mdi-cart me-2"></i> Orders
                      </a>
                    </li>
                  )}

                  {planDetails && planDetails.inventory === 1 && (
                    <li className="">
                      <a
                        data-bs-toggle="collapse"
                        href="#ui-basic"
                        aria-expanded="false"
                        aria-controls="ui-basic1"
                      >
                        <i className="menu-icon mdi mdi-store me-2"></i>{" "}
                        Inventory
                        <i className="menu-icon menu-arrow"></i>
                      </a>
                      <div className="collapse" id="ui-basic">
                        <ul className="nav flex-column sub-menu">
                          <li className="">
                            <a href="/raw_material">
                              <i className="menu-icon mdi mdi-store me-2"></i>{" "}
                              Raw Material
                            </a>
                          </li>
                          <li className="">
                            <a href="/suppliers">
                              <i className="menu-icon mdi mdi-truck me-2"></i>{" "}
                              Suppliers
                            </a>
                          </li>
                          <li className="">
                            <a href="/recipes">
                              <i className="menu-icon mdi mdi-notebook-outline me-2"></i>{" "}
                              Recipes
                            </a>
                          </li>
                          <li className="">
                            <a href="/stocks">
                              <i className="menu-icon mdi mdi-store-24-hour me-2"></i>{" "}
                              Stocks
                            </a>
                          </li>

                          <li className="">
                            <a href="/view_purchase">
                              <i className="menu-icon mdi mdi-wallet-outline me-2"></i>{" "}
                              Purchase
                            </a>
                          </li>
                          <li className="">
                            <a href="/view_purchase_return">
                              <i className="menu-icon mdi mdi-share me-2"></i>{" "}
                              Purchase Returns
                            </a>
                          </li>
                        </ul>
                      </div>
                    </li>
                  )}

                  {planDetails && planDetails.fooder_cp === 1 && (
                    <li className="">
                      <a href="/fooders_cp">
                        <i className="menu-icon mdi mdi-airballoon me-2"></i>{" "}
                        Fooders CP
                      </a>
                    </li>
                  )}

                  {planDetails && planDetails.fooder_cp === 1 && (
                    <li className="">
                      <a href="/whatsapp_marketing">
                        <i className="menu-icon mdi mdi-whatsapp me-2"></i>{" "}
                        WhatsApp Marketing
                      </a>
                    </li>
                  )}

                  {planDetails && planDetails.customers === 1 && (
                    <li className="">
                      <a href="/customers">
                        <i className="menu-icon mdi mdi-account-group me-2"></i>{" "}
                        Customers
                      </a>
                    </li>
                  )}

                  {/* {planDetails && planDetails.verififation_documents === 1 && (
                    <li className="">
                      <a href="/verification">
                        <i className="menu-icon mdi mdi-file-document me-2"></i>{" "}
                        Verification
                      </a>
                    </li>
                  )} */}

                  {planDetails && planDetails.manage_tables === 1 && (
                    <li className="">
                      <a href="/manage_tables">
                        <i className="menu-icon mdi mdi-table me-2"></i> Manage
                        Tables
                      </a>
                    </li>
                  )}

                  {planDetails && planDetails.callwaiter_settings === 1 && (
                    <li className="">
                      <a href="/qrcode_settings">
                        <i className="menu-icon mdi mdi-qrcode-edit me-2"></i>{" "}
                        QR Code Settings
                      </a>
                    </li>
                  )}

                  {/* {planDetails &&
                    planDetails.online_delivery_settings === 1 && (
                      <li className="">
                        <a href="/online_ordering">
                          <i className="menu-icon mdi mdi-truck me-2"></i>{" "}
                          Delivery Settings
                        </a>
                      </li>
                    )} */}

                  {planDetails && planDetails.coupons === 1 && (
                    <li className="">
                      <a href="/manage_coupons">
                        <i className="menu-icon mdi mdi-ticket-percent me-2"></i>{" "}
                        Manage Coupons
                      </a>
                    </li>
                  )}

                  {planDetails && planDetails.reports === 1 && (
                    <li className="">
                      <a href="/reports">
                        <i className="menu-icon mdi mdi-file-chart me-2"></i>{" "}
                        Reports
                      </a>
                    </li>
                  )}

                  <li className="">
                    <a href="/account_management">
                      <i className="menu-icon mdi mdi-account-circle-outline me-2"></i>{" "}
                      Profile
                    </a>
                  </li>
                  <li className="">
                    <a href="/updates">
                      <i className="menu-icon mdi mdi-pencil-box me-2"></i>{" "}
                      Updates
                    </a>
                  </li>

                  <li>
                    <Link
                      // onClick={handleLogout}
                      onClick={() => { setLogoutModel(true) }}
                      // setLogoutModel
                      data-bs-dismiss="offcanvas"
                      aria-label="Close"
                    >
                      <i className="menu-icon mdi mdi-logout me-2"></i> Logout
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div style={{ display: "none" }}>
            <div id="contentToPrintKOT">
              <div className="printcss">
                {/* <div className="col-12">
                  <p className="name">
                    <strong>
                      {data && capitalizeFirstLetter(data.fooder_name)}
                    </strong>
                  </p>
                </div>
                <div className="col-12">
                  {data && data.fooder_gstin && (
                    <p className="gst thank">
                      GST Number : {data && data.fooder_gstin}
                    </p>
                  )}
                </div> */}
                <div className="col-12">
                  {printKOTDetails.KOT_no && (
                    <p className="gst thank">
                      <strong>{printKOTDetails.KOT_no}</strong>
                    </p>
                  )}
                </div>

                <table className="tblecss info">
                  <tbody>
                    <tr>
                      <td>
                        Date : <span>{date}</span>
                      </td>
                      <td className="txtrg">
                        Time : <span>{currentTime}</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>{orderType}</strong>
                      </td>
                      {orderType === "DINE IN" && (
                        <td className="txtrg">
                          {/* Table No:{" "} */}
                          {selectedTableDetails
                            ? selectedTableDetails.table_no
                            : ""}
                        </td>
                      )}
                    </tr>
                  </tbody>
                </table>
                <table className="tblecss2">
                  <thead>
                    <tr>
                      <th className="thcss itemth">Item</th>
                      {/* <th className="thcss qtyth txtct">Special Note</th> */}
                      <th className="thcss qtyth txtrg">Qty</th>
                    </tr>
                  </thead>

                  <tbody>
                    {printKOTDetails.KOT_items.map((order, index) => (
                      <tr key={index}>
                        <td className="tdcss nametd">
                          {capitalizeFirstLetter(order.product_name)}
                          {order.product_special_note ? <> <i>
                            <br />
                            {order.product_special_note}
                          </i></> : <></>}

                          {order.selectedvariants.combination_details && order.selectedvariants.combination_details.map((i) => (
                            <> <br /><i>{i.attribute_name} :  {i.attribute_value_name} </i> </>
                          ))}
                          {order.selectedAddons && order.selectedAddons.map((i) => (
                            <> <br /> <i>{i.addon_item_name}</i></>
                          ))}
                        </td>
                        {/* <td className="tdcss qtytd txtct">
                          {order.product_special_note}
                        </td> */}
                        <td className="tdcss qtytd txtrg">{order.quantity}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div style={{ display: "none" }}>
            <div id="contentToPrintKOT2">
              <div className="printcss">
                {/* <div className="col-12">
                  <p className="name">
                    <strong>
                      {data && capitalizeFirstLetter(data.fooder_name)}
                    </strong>
                  </p>
                </div>
                <div className="col-12">
                  {data && data.fooder_gstin && (
                    <p className="gst thank">
                      GST Number : {data && data.fooder_gstin}
                    </p>
                  )}
                </div> */}
                <div className="col-12">
                  {printKOTDetails2 && (
                    <p className="gst thank">
                      <strong>{printKOTDetails2.kot}
                        {printKOTDetails2.status === 4 ? "(Cancelled)" : ""}
                      </strong>

                    </p>
                  )}
                </div>

                <table className="tblecss info">
                  <tbody>
                    <tr>
                      <td>
                        Date : <span>{date}</span>
                      </td>
                      <td className="txtrg">
                        Time :{" "}
                        <span>{printKOTDetails2 && printKOTDetails2.time}</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>
                          {printKOTDetails2 && printKOTDetails2.order_type}
                        </strong>
                      </td>
                      {printKOTDetails2 && printKOTDetails2.table_no ? (
                        <td className="txtrg">
                          {/* Table No:  */}
                          {printKOTDetails2.table_no}
                        </td>
                      ) : (
                        <td className="txtrg">
                          {printKOTDetails2 &&
                            `Order : #${printKOTDetails2.order_number}`}
                        </td>
                      )}
                    </tr>
                  </tbody>
                </table>
                <table className="tblecss2">
                  <thead>
                    <tr>
                      <th className="thcss itemth">Item</th>
                      {/* <th className="thcss qtyth txtct">Special Note</th> */}
                      <th className="thcss qtyth txtrg">Qty</th>
                    </tr>
                  </thead>

                  <tbody>
                    {printKOTDetails2 &&
                      printKOTDetails2.final_kot_details.map((order, index) => (
                        <tr key={index}>
                          <td className="tdcss nametd">
                            {capitalizeFirstLetter(order.product_name)}

                            {order.selectedvariants && order.selectedvariants.combination_details && order.selectedvariants.combination_details.map((i) => (
                              <> <br /><i>{i.attribute_name} :  {i.attribute_value_name} </i> </>
                            ))}

                            {order.selectedAddons && order.selectedAddons.map((i) => (
                              <> <br /> <i>{i.addon_item_name}</i></>
                            ))}


                            {order.product_special_note ? <>
                              <br />
                              <i>
                                {order.product_special_note}
                              </i>
                            </> : <></>}


                          </td>
                          {/* <td className="tdcss qtytd txtct">
                            {order.product_special_note}
                          </td> */}
                          <td className="tdcss qtytd txtrg">
                            {order.quantity}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div style={{ display: "none" }}>

            <div id="contentToPrintBill">
              {noOfBillPrintingArray.map((i, index) => (
                <div >

                  {/* {orderList ? {selectedTable} :<></>} */}

                  {selectedTable && orderList[selectedTable] && orderList[selectedTable].invoice_number ? (
                    <p id="invoice_number_on_bill " className="name invoice_number_on_bill" style={{ textAlign: "end" }}>
                      {orderList[selectedTable].invoice_number}
                    </p>
                  ) : (
                    <p id="invoice_number_on_bill" className="name invoice_number_on_bill" style={{ textAlign: "end" }}>

                    </p>
                  )}


                  <div className="printcss">
                    <div className="col-12">
                      <p className="name" >
                        <strong>
                          {data && capitalizeFirstLetter(data.fooder_name)}
                        </strong>
                      </p>

                      <p className="name">

                        {data &&
                          capitalizeFirstLetter(data.Fooder_name2)}

                      </p>

                    </div>

                    <p className="name">
                      {data && capitalizeFirstLetter(data.f_address)}{', '}
                      {data &&
                        (data.f_city || data.f_state || data.f_zipcode) && (
                          <>
                            {data.f_city && `${data.f_city}, `}
                            {data.f_state && capitalizeFirstLetter(data.f_state)}
                            {data.f_zipcode && `, ${data.f_zipcode}`}
                          </>
                        )}
                    </p>
                    {data.f_landline ? <p className="name">Phone No :
                      {data.f_landline}

                    </p> : <></>}




                    <div className="col-12">
                      {data && data.fooder_gstin && (
                        <p className="gst thank">
                          GST Number : {data && data.fooder_gstin}
                        </p>
                      )}
                    </div>

                    <table className="tblecss info">

                      <tbody>


                        <tr>
                          <td>
                            Name :{" "}
                            {customerForm.name}
                          </td>

                        </tr>

                        {customerForm.mobile ? <tr>
                          <td>
                            Phone : {" "}
                            {capitalizeFirstLetter(customerForm.mobile)}
                          </td>
                        </tr> : <></>}


                        {customerForm.address && customerForm.address !== '' ? <tr>
                          <td>
                            Address : {" "}
                            {capitalizeFirstLetter(customerForm.address)}
                          </td>
                        </tr> : <></>}
                      </tbody>

                    </table>

                    <table className="tblecss info">
                      <tbody>
                        <tr>
                          <td>
                            Date : <span>{date}</span>
                          </td>
                          <td className="txtrg">
                            Time : <span>{currentTime}</span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>{orderType}</strong>
                          </td>
                          {orderType === "DINE IN" && (
                            <td className="txtrg">
                              Table No : #
                              <span>
                                {selectedTableDetails
                                  ? selectedTableDetails.table_no
                                  : ""}
                              </span>
                            </td>
                          )}
                        </tr>
                        <tr>
                          <th colSpan="2">
                            <p
                              style={{
                                textAlign: "center",
                                padding: "5px 2px",
                                margin: "0",
                                fontSize: "medium",
                              }}
                            >
                              Original Receipt
                            </p>
                          </th>
                        </tr>
                      </tbody>
                    </table>
                    <table className="tblecss2">
                      <thead>
                        <tr>
                          <th className="thcss itemth">Item</th>
                          <th className="thcss qtyth txtct">Qty</th>
                          <th className="thcss rateth txtrg">Rate</th>
                          <th className="thcss amtth txtrg">Amt</th>
                        </tr>
                      </thead>

                      <tbody>
                        {filteredOrders.map((order, index) => (
                          <tr key={index}>
                            <td className="tdcss nametd">
                              {capitalizeFirstLetter(order.name)}

                              {order.selectedvariants && <>
                                {order.selectedvariants.combination_details && order.selectedvariants.combination_details.map((i) => (
                                  <><br /><b>{i.attribute_name}: </b> {i.attribute_value_name} </>
                                ))}
                              </>}

                              {order.selectedAddons && order.selectedAddons.map((i, no) => (
                                <><br /><b>{no + 1}.</b> {i.addon_item_name} </>
                              ))}

                            </td>
                            <td className="tdcss qtytd txtct">{order.quantity}</td>
                            <td className="tdcss ratetd txtrg">
                              {currencySymbol}
                              {isValidPrice(order.newPrice)}
                            </td>
                            <td className="tdcss amttd txtrg">
                              {currencySymbol}
                              {isValidPrice(order.quantity * order.newPrice).toFixed(2)}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <table className="tblecss2">
                      <tbody>
                        <tr>
                          <td className="txtrg brtp subtl">Subtotal</td>
                          <td className="txtrg brtp">
                            {currencySymbol}
                            {cartProductSubTotal}
                          </td>
                        </tr>
                        {discountRate &&
                          discountRate !== "0" &&
                          discountType === "0" && (
                            <tr>
                              <td className="txtrg subtl">
                                Discount ({discountRate}%)
                              </td>
                              <td className="txtrg">
                                {currencySymbol}
                                {cartProductDiscount}
                              </td>
                            </tr>
                          )}
                        {discountRate &&
                          discountRate !== "0" &&
                          discountType === "1" && (
                            <tr>
                              <td className="txtrg subtl">
                                Discount ({currencySymbol})
                              </td>
                              <td className="txtrg">
                                {currencySymbol}
                                {cartProductDiscount}
                              </td>
                            </tr>
                          )}
                        {SCHRate !== 0 && (
                          <tr>
                            <td className="txtrg subtl">
                              {data.service_charge_details &&
                                data.service_charge_details.name}
                              ({SCHRate}
                              %)
                            </td>
                            <td className="txtrg">
                              {currencySymbol}
                              {/* {schAmount} */}
                              {cartProductServiceCharge}
                            </td>
                          </tr>
                        )}
                        {/* {CGSTRate !== 0 && (
<tr>
<td className="txtrg subtl">CGST({CGSTRate}%)</td>
<td className="txtrg">
{currencySymbol}
{cgstAmount}
</td>
</tr>
)}
{SGSTRate !== 0 && (
<tr>
<td className="txtrg subtl">SGST({SGSTRate}%)</td>
<td className="txtrg">
{currencySymbol}
{sgstAmount}
</td>
</tr>
)} */}

                        {CGSTTaxT2 !== 0 &&
                          (<tr>
                            <td className="txtrg subtl">CGST(2.5%)</td>
                            <td className="txtrg">
                              {currencySymbol}
                              {CGSTTaxT2}
                            </td>
                          </tr>)
                        }

                        {SGSTTaxT2 !== 0 &&
                          (<tr>
                            <td className="txtrg subtl">SGST(2.5%)</td>
                            <td className="txtrg">
                              {currencySymbol}
                              {SGSTTaxT2}
                            </td>
                          </tr>)
                        }


                        {CGSTTaxT3 !== 0 &&
                          (<tr>
                            <td className="txtrg subtl">CGST(6%)</td>
                            <td className="txtrg">
                              {currencySymbol}
                              {CGSTTaxT3}
                            </td>
                          </tr>)
                        }

                        {SGSTTaxT3 !== 0 &&
                          (<tr>
                            <td className="txtrg subtl">SGST(6%)</td>
                            <td className="txtrg">
                              {currencySymbol}
                              {SGSTTaxT3}
                            </td>
                          </tr>)
                        }


                        {CGSTTaxT4 !== 0 &&
                          (<tr>
                            <td className="txtrg subtl">CGST(9%)</td>
                            <td className="txtrg">
                              {currencySymbol}
                              {CGSTTaxT4}
                            </td>
                          </tr>)
                        }

                        {SGSTTaxT4 !== 0 &&
                          (<tr>
                            <td className="txtrg subtl">SGST(9%)</td>
                            <td className="txtrg">
                              {currencySymbol}
                              {SGSTTaxT4}
                            </td>
                          </tr>)
                        }


                        {CGSTTaxT5 !== 0 &&
                          (<tr>
                            <td className="txtrg subtl">CGST(14%)</td>
                            <td className="txtrg">
                              {currencySymbol}
                              {CGSTTaxT5}
                            </td>
                          </tr>)
                        }

                        {SGSTTaxT5 !== 0 &&
                          (<tr>
                            <td className="txtrg subtl">SGST(14%)</td>
                            <td className="txtrg">
                              {currencySymbol}
                              {SGSTTaxT5}
                            </td>
                          </tr>)
                        }


                        {cartProductPackingCharges !== 0 && orderType !== "DINE IN" &&
                          (<tr>
                            <td className="txtrg subtl">Packaging Charges</td>
                            <td className="txtrg">
                              {currencySymbol}
                              {cartProductPackingCharges}
                            </td>
                          </tr>)
                        }

                        {onlySavePaymentRoundUpAmount !== 0 &&
                          (<tr>
                            <td className="txtrg subtl">Round off</td>
                            <td className="txtrg">
                              {currencySymbol}
                              {parseFloat(onlySavePaymentRoundUpAmount).toFixed(2)}
                            </td>
                          </tr>)
                        }

                        <tr>
                          <th className="tlt">Total</th>
                          <th className="tlt txtrg pd0">
                            {currencySymbol}
                            {(parseFloat(totalAmountPaid) + parseFloat(onlySavePaymentRoundUpAmount)).toFixed(2)}
                          </th>
                        </tr>
                      </tbody>
                    </table>
                    <table className="tblecss">
                      <tbody>
                        <tr>
                          <td>
                            {data && data.fssai_number && (
                              <p className="thank">
                                FSSAI Number : {data.fssai_number}
                              </p>
                            )}
                          </td>
                        </tr>
                        {data.payment_type ? <tr>
                          <td>
                            <p className="thank">Paid By {data.payment_type}</p>
                          </td>
                        </tr> : <></>}
                        <tr>
                          <td>
                            <p className="thank">{data.billing_notes}</p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p className="thank">Thank you for your visit!</p>


                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <br /><hr /><br />
                </div>
              ))}


            </div>

          </div>

          <div style={{ display: "none" }}>
            <div id="contentToPrintBill2">

              {noOfBillPrintingArray.map((i, index) => (

                <div>

                  <div className="printcss">
                    {selectedTable && orderList[selectedTable] && orderList[selectedTable].invoice_number ? (
                      <p id="invoice_number_on_bill_counter_delivery" className="name" style={{ textAlign: "end" }}>
                        {orderList[selectedTable].invoice_number}
                      </p>
                    ) : (
                      <p id="invoice_number_on_bill_counter_delivery" className="name" style={{ textAlign: "end" }}>

                      </p>
                    )}
                    <div className="col-12">
                      <p className="name">
                        <strong>
                          {data && capitalizeFirstLetter(data.fooder_name)}
                        </strong>
                      </p>

                      <p className="name">

                        {data &&
                          capitalizeFirstLetter(data.Fooder_name2)}

                      </p>

                    </div>

                    <p className="name">
                      {data && capitalizeFirstLetter(data.f_address)}{', '}
                      {data &&
                        (data.f_city || data.f_state || data.f_zipcode) && (
                          <>
                            {data.f_city && `${data.f_city}, `}
                            {data.f_state && capitalizeFirstLetter(data.f_state)}
                            {data.f_zipcode && `, ${data.f_zipcode}`}
                          </>
                        )}
                    </p>
                    {data.f_landline ? <p className="name">Phone No :
                      {data.f_landline}

                    </p> : <></>}
                    <div className="col-12">
                      {data && data.fooder_gstin && (
                        <p className="gst thank">
                          GST Number : {data && data.fooder_gstin}
                        </p>
                      )}
                    </div>

                    <table className="tblecss info">

                      <tbody>


                        <tr>
                          <td>
                            Name :{" "}
                            {customerForm.name}
                          </td>

                        </tr>

                        {customerForm.mobile ? <tr>
                          <td>
                            Phone : {" "}
                            {capitalizeFirstLetter(customerForm.mobile)}
                          </td>
                        </tr> : <></>}


                        {customerForm.address && customerForm.address !== '' ? <tr>
                          <td>
                            Address : {" "}
                            {capitalizeFirstLetter(customerForm.address)}
                          </td>
                        </tr> : <></>}
                      </tbody>

                    </table>

                    <table className="tblecss info">
                      <tbody>
                        <tr>
                          <td>
                            Date :{" "}
                            <span>{printBillDetails && printBillDetails.date}</span>
                          </td>
                          <td className="txtrg">
                            Time :{" "}
                            <span>{printBillDetails && printBillDetails.time}</span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>{orderType}</strong>
                          </td>
                          {orderType === "DINE IN" && (
                            <td className="txtrg">
                              Table No : #
                              <span>
                                {selectedTableDetails
                                  ? selectedTableDetails.table_no
                                  : ""}
                              </span>
                            </td>
                          )}
                        </tr>
                        <tr>
                          <th colSpan="2">
                            <p
                              style={{
                                textAlign: "center",
                                padding: "5px 2px",
                                margin: "0",
                                fontSize: "medium",
                              }}
                            >
                              Original Receipt
                            </p>
                          </th>
                        </tr>
                      </tbody>
                    </table>
                    <table className="tblecss2">
                      <thead>
                        <tr>
                          <th className="thcss itemth">Item</th>
                          <th className="thcss qtyth txtct">Qty</th>
                          <th className="thcss rateth txtrg">Rate</th>
                          <th className="thcss amtth txtrg">Amt</th>
                        </tr>
                      </thead>

                      <tbody>
                        {filteredOrders.map((order, index) => (
                          <tr key={index}>
                            <td className="tdcss nametd">
                              {capitalizeFirstLetter(order.name)}
                              {order.selectedvariants && <>
                                {order.selectedvariants.combination_details && order.selectedvariants.combination_details.map((i) => (
                                  <><br /><b>{i.attribute_name}: </b> {i.attribute_value_name} </>
                                ))}
                              </>}

                              {order.selectedAddons && order.selectedAddons.map((i, no) => (
                                <><br /><b>{no + 1}.</b> {i.addon_item_name} </>
                              ))}
                            </td>
                            <td className="tdcss qtytd txtct">{order.quantity}</td>
                            <td className="tdcss ratetd txtrg">
                              {currencySymbol}
                              {isValidPrice(order.newPrice)}
                            </td>
                            <td className="tdcss amttd txtrg">
                              {currencySymbol}
                              {isValidPrice(order.quantity * order.newPrice).toFixed(2)}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <table className="tblecss2">
                      <tbody>
                        <tr>
                          <td className="txtrg brtp subtl">Subtotal</td>
                          <td className="txtrg brtp">
                            {currencySymbol}
                            {cartProductSubTotal}
                          </td>
                        </tr>
                        {discountRate &&
                          discountRate !== "0" &&
                          discountType === "0" && (
                            <tr>
                              <td className="txtrg subtl">
                                Discount ({discountRate}%)
                              </td>
                              <td className="txtrg">
                                {currencySymbol}
                                {cartProductDiscount}
                              </td>
                            </tr>
                          )}
                        {discountRate &&
                          discountRate !== "0" &&
                          discountType === "1" && (
                            <tr>
                              <td className="txtrg subtl">
                                Discount ({currencySymbol})
                              </td>
                              <td className="txtrg">
                                {currencySymbol}
                                {cartProductDiscount}
                              </td>
                            </tr>
                          )}
                        {SCHRate !== 0 && (
                          <tr>
                            <td className="txtrg subtl">
                              {data.service_charge_details &&
                                data.service_charge_details.name}
                              ({SCHRate}
                              %)
                            </td>
                            <td className="txtrg">
                              {currencySymbol}
                              {/* {schAmount} */}
                              {cartProductServiceCharge}
                            </td>
                          </tr>
                        )}
                        {/* {CGSTRate !== 0 && (
<tr>
<td className="txtrg subtl">CGST({CGSTRate}%)</td>
<td className="txtrg">
{currencySymbol}
{cgstAmount}
</td>
</tr>
)}
{SGSTRate !== 0 && (
<tr>
<td className="txtrg subtl">SGST({SGSTRate}%)</td>
<td className="txtrg">
{currencySymbol}
{sgstAmount}
</td>
</tr>
)} */}

                        {CGSTTaxT2 !== 0 &&
                          (<tr>
                            <td className="txtrg subtl">CGST(2.5%)</td>
                            <td className="txtrg">
                              {currencySymbol}
                              {CGSTTaxT2}
                            </td>
                          </tr>)
                        }

                        {SGSTTaxT2 !== 0 &&
                          (<tr>
                            <td className="txtrg subtl">SGST(2.5%)</td>
                            <td className="txtrg">
                              {currencySymbol}
                              {SGSTTaxT2}
                            </td>
                          </tr>)
                        }


                        {CGSTTaxT3 !== 0 &&
                          (<tr>
                            <td className="txtrg subtl">CGST(6%)</td>
                            <td className="txtrg">
                              {currencySymbol}
                              {CGSTTaxT3}
                            </td>
                          </tr>)
                        }

                        {SGSTTaxT3 !== 0 &&
                          (<tr>
                            <td className="txtrg subtl">SGST(6%)</td>
                            <td className="txtrg">
                              {currencySymbol}
                              {SGSTTaxT3}
                            </td>
                          </tr>)
                        }


                        {CGSTTaxT4 !== 0 &&
                          (<tr>
                            <td className="txtrg subtl">CGST(9%)</td>
                            <td className="txtrg">
                              {currencySymbol}
                              {CGSTTaxT4}
                            </td>
                          </tr>)
                        }

                        {SGSTTaxT4 !== 0 &&
                          (<tr>
                            <td className="txtrg subtl">SGST(9%)</td>
                            <td className="txtrg">
                              {currencySymbol}
                              {SGSTTaxT4}
                            </td>
                          </tr>)
                        }


                        {CGSTTaxT5 !== 0 &&
                          (<tr>
                            <td className="txtrg subtl">CGST(14%)</td>
                            <td className="txtrg">
                              {currencySymbol}
                              {CGSTTaxT5}
                            </td>
                          </tr>)
                        }

                        {SGSTTaxT5 !== 0 &&
                          (<tr>
                            <td className="txtrg subtl">SGST(14%)</td>
                            <td className="txtrg">
                              {currencySymbol}
                              {SGSTTaxT5}
                            </td>
                          </tr>)
                        }

                        {cartProductPackingCharges !== 0 && orderType !== "DINE IN" &&
                          (<tr>
                            <td className="txtrg subtl">Packaging Charges</td>
                            <td className="txtrg">
                              {currencySymbol}
                              {cartProductPackingCharges}
                            </td>
                          </tr>)
                        }

                        {onlySavePaymentRoundUpAmount !== 0 &&
                          (<tr>
                            <td className="txtrg subtl">Round off</td>
                            <td className="txtrg">
                              {currencySymbol}
                              {parseFloat(onlySavePaymentRoundUpAmount).toFixed(2)}
                            </td>
                          </tr>)
                        }

                        <tr>
                          <th className="tlt">Total</th>
                          <th className="tlt txtrg pd0">
                            {currencySymbol}
                            {/* {totalAmountPaid} */}
                            {/* {cartProductTotal} */}
                            {(parseFloat(totalAmountPaid) + parseFloat(onlySavePaymentRoundUpAmount)).toFixed(2)}

                          </th>
                        </tr>
                      </tbody>
                    </table>
                    <table className="tblecss">
                      <tbody>
                        <tr>
                          <td>
                            {data && data.fssai_number && (
                              <p className="thank">
                                FSSAI Number : {data.fssai_number}
                              </p>
                            )}
                          </td>
                        </tr>
                        {data.payment_type ? <tr>
                          <td>
                            <p className="thank">Paid By {data.payment_type}</p>
                          </td>
                        </tr> : <></>}
                        <tr>
                          <td>
                            <p className="thank">{data.billing_notes}</p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p className="thank">Thank you for your visit!</p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <br /><hr /><br />
                </div>
              ))}

            </div>

          </div>

          <LoadingModal isLoading={isLoading} />

          <Notification />
        </>
      )}
    </>
  );
};

export default POS;