import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  UpdateLiveOrdersStatusURL,
  GetOrderDetailsURL,
  orderWwhatssappMmassageHistoryURL,
  // OrderAcceptTime,
} from "../../assets/api/BaseURL";
import { useToast } from "../../../context/ToastContext";
import printJS from "print-js";
import LoadingModal from "../../loader/LoadingModal";

const Notification = ({ handleFlag, flagValue }) => {
  const { addToast } = useToast();
  const [token, setToken] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [whatsappApiAccessToken, setWhatsappApiAccessToken] = useState("");
  const [whatsappApiVendorUid, setWhatsappApiVendorUid] = useState("");
  const [whatsappMassageingApiBaseUrl, setWhatsappMassageingApiBaseUrl] = useState("");
  const [whatsappMassageingApiUrl, setWhatsappMassageingApiUrl] = useState("");
  const [sendWhatesappMessage, setSendWhatesappMessage] = useState("0");


  const [hasChild, setHasChild] = useState(false);
  const [mute, setMute] = useState(true);
  const [noOfOrders, setNoOfOrders] = useState("0");
  const [currencySymbol, setCurrencySymbol] = useState("");
  const [orderData, setOrderData] = useState(null);
  const [isPrintBill, setIsPrintBill] = useState(false);

  const [subTotal, setSubTotal] = useState(0);
  const [SCHRate, setSCHRate] = useState(0);
  const [discountRate, setDiscountRate] = useState();
  const [CGSTRate, setCGSTRate] = useState(0);
  const [SGSTRate, setSGSTRate] = useState(0);
  const [discountAmount, setDiscountAmount] = useState();
  const [schAmount, setSchAmount] = useState();
  const [cgstAmount, setCGSTAmount] = useState();
  const [sgstAmount, setSGSTAmount] = useState();
  const [totalAmountPaid, setTotalAmountPaid] = useState();














  const [cartProductPackingCharges, setCartProductPackingCharges] = useState(0);

  const [CGSTTaxT1, setCGSTTaxt1] = useState(0);
  const [CGSTTaxT2, setCGSTTaxt2] = useState(0);
  const [CGSTTaxT3, setCGSTTaxt3] = useState(0);
  const [CGSTTaxT4, setCGSTTaxt4] = useState(0);
  const [CGSTTaxT5, setCGSTTaxt5] = useState(0);


  const [SGSTTaxT1, setSGSTTaxt1] = useState(0);
  const [SGSTTaxT2, setSGSTTaxt2] = useState(0);
  const [SGSTTaxT3, setSGSTTaxt3] = useState(0);
  const [SGSTTaxT4, setSGSTTaxt4] = useState(0);
  const [SGSTTaxT5, setSGSTTaxt5] = useState(0);







  const formatTimestampForDate = (timestampStr) => {
    const timestamp = parseInt(timestampStr, 10);
    const dateObject = new Date(timestamp * 1000);

    const day = dateObject.getDate();
    const month = dateObject.toLocaleString("default", { month: "short" });
    const year = dateObject.getFullYear();

    return `${day} ${month} ${year}`;
  };

  const formatTimestampForTime = (timestampStr) => {
    const timestamp = parseInt(timestampStr, 10);
    const dateObject = new Date(timestamp * 1000);

    let hours = dateObject.getHours();
    const minutes = dateObject.getMinutes();
    const ampm = hours >= 12 ? "pm" : "am";

    if (hours > 12) {
      hours -= 12;
    }

    return `${hours}:${minutes.toString().padStart(2, "0")} ${ampm}`;
  };

  const capitalizeFirstLetter = (str) => {
    if (str) {
      const words = str.split(" ");

      const capitalizedWords = words.map((word) => {
        if (word.length === 0) {
          return "";
        }
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      });

      const capitalizedString = capitalizedWords.join(" ");

      return capitalizedString;
    }
  };

  function isValidPrice(price) {
    const pricePattern = /^\d+(\.\d{1,2})?$/;

    if (!pricePattern.test(price)) {
      return parseFloat(price).toFixed(2);
    }
    return price;
  }

  function removeAllChildElements() {
    const parentElement = document.getElementById("gritter-notice-wrapper");
    const parentElement2 = document.getElementById(
      "new_order_div_notification"
    );

    if (parentElement) {
      while (parentElement.firstChild) {
        parentElement.removeChild(parentElement.firstChild);
        if (handleFlag && parentElement2 && parentElement2.firstChild) {
          parentElement2.removeChild(parentElement2.firstChild);
          handleFlag(!flagValue);
        }
      }
    }
  }

  // function startTimerForDiv(divId) {
  //   let timer = OrderAcceptTime;

  //   const acceptButton = document.getElementById(`timer_accept_${divId}`);

  //   function updateButtonLabel() {
  //     if (timer >= 0) {
  //       acceptButton.textContent = `Accept Order (${Math.floor(timer / 60)}:${
  //         timer % 60
  //       })`;
  //       timer--;
  //     } else {
  //       clearInterval(intervalId);
  //       acceptButton.click();
  //     }
  //   }

  //   updateButtonLabel();

  //   const intervalId = setInterval(function () {
  //     updateButtonLabel();
  //   }, 1000);
  // }

  const handleMuteClick = () => {
    const audioPlayer = document.getElementById("audioPlayer");
    if (audioPlayer) {
      audioPlayer.pause();
      setMute(false);
    }
  };

  window.mute_sound = () => {
    const audioPlayer = document.getElementById("audioPlayer");
    if (audioPlayer) {
      audioPlayer.pause();
    }
  };

  window.minus_time = (id) => {
    let input = document.getElementById(`time-${id}`);
    let value = parseInt(input.value, 10);
    value = isNaN(value) ? 1 : value;
    if (value > 1) {
      value--;
      input.value = value;
    }
  };

  window.plus_time = (id) => {
    let input = document.getElementById(`time-${id}`);
    let value = parseInt(input.value, 10);
    value = isNaN(value) ? 1 : value;
    value++;
    input.value = value;
  };

  window.change_order_status_reject = async (id) => {
    const data = { id: id, status: 4 };
    setIsLoading(true);
    try {
      const response = await axios({
        method: "put",
        url: UpdateLiveOrdersStatusURL,
        data: data,
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200 && response.data.message) {
        if (sendWhatesappMessage === "1") {
          const response2 = await axios.get(GetOrderDetailsURL, {
            params: {
              id: id,
            },
            headers: { authorization: `Bearer ${token}` },
          });

          sendOrderStatusChangeWhateappMassage('Reject', response2.data.data, id)

        }



        const elementToRemove = document.getElementById(`tb-${id}`);
        const parentElement = document.getElementById(
          "new_order_div_notification"
        );

        if (handleFlag) {
          if (parentElement && parentElement.firstChild) {
            parentElement.removeChild(parentElement.firstChild);
            handleFlag(!flagValue);
          }
        }
        addToast("success", response.data.message);
        if (elementToRemove) {
          elementToRemove.parentNode.removeChild(elementToRemove);
        }
      }
      setIsLoading(false);

    } catch (error) {
      if (error.response && error.response.data.message) {
        addToast("error", error.response.data.message);
      } else {
        addToast("error", error.message);
      }
      console.log(error);
    }
    setIsLoading(false);

  };

  window.change_order_status_accept = async (id) => {
    let input = document.getElementById(`time-${id}`);
    setIsLoading(true);

    if (input) {
      let value = parseInt(input.value, 10);
      const data = { id: id, status: 1, order_preparation_time: value };

      try {
        const response = await axios({
          method: "put",
          url: UpdateLiveOrdersStatusURL,
          data: data,
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${token}`,
          },
        });

        if (response.status === 200 && response.data.message) {

          console.log("fadsfkjl;adfs;kladfs")

          if (sendWhatesappMessage === "1") {
            const response2 = await axios.get(GetOrderDetailsURL, {
              params: {
                id: id,
              },
              headers: { authorization: `Bearer ${token}` },
            });

            sendOrderStatusChangeWhateappMassage('Accept', response2.data.data, id)
          }



          const elementToRemove = document.getElementById(`tb-${id}`);
          const parentElement = document.getElementById(
            "new_order_div_notification"
          );

          if (handleFlag) {
            if (parentElement && parentElement.firstChild) {
              parentElement.removeChild(parentElement.firstChild);
              handleFlag(!flagValue);
            }
          }
          addToast("success", response.data.message);
          if (elementToRemove) {
            elementToRemove.parentNode.removeChild(elementToRemove);
          }

        }
        setIsLoading(false);

      } catch (error) {
        if (error.response && error.response.data.message) {
          addToast("error", error.response.data.message);
        } else {
          addToast("error", error.message);
        }
        console.log(error);
      }
      setIsLoading(true);

    }
  };


  const sendOrderStatusChangeWhateappMassage = async (order_status, order_data, oid) => {


    let itemsText = ""
    order_data.details.forEach((item, index) => {
      itemsText += index + 1 + ". " + item.product_name + " x " + item.quantity + "\\n"
    });
    setIsLoading(true);



    try {
      const response = await axios({
        method: "post",
        url: whatsappMassageingApiUrl + '/contact/send-template-message',
        data: {
          phone_number: order_data.eater_phonenumber,
          template_name: "update_order_status"	,
          template_language: "en",
          header_document_name: "Order # Placed Successfully",
          header_field_1: order_status,
          field_1: order_data.order_number_qrcode,
          field_2: capitalizeFirstLetter(order_data.fooders_name),
          field_3: itemsText,
          // if you want to create contact if it does not exist
          contact: {
            first_name: order_data.eater_name,
            last_name: "_",
            country: "india",
            language_code: "en",
            groups: ""
          }
        },
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${whatsappApiAccessToken}`,
        },
      });
      console.log(order_data)
      await axios({
        method: "post",
        url: orderWwhatssappMmassageHistoryURL,
        data: {
          order_id: oid,
          mobile:order_data.eater_phonenumber,
          templats_name: "update_order_status"	,
          w_data: response.data,

        },
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${token}`,
        },
      });

      if (response.data.result) {
        addToast("success", "Message send successfully");
      }

      setIsLoading(false);


    } catch (error) {
      if (error.response && error.response.data.message) {
        addToast("error", error.response.data.message);
      } else {
        addToast("error", error.message);
      }
      console.log(error);
    }
    setIsLoading(false);

  }



  window.print_order_notification = async (id) => {
    try {
      const response = await axios.get(GetOrderDetailsURL, {
        params: {
          id: id,
        },
        headers: { authorization: `Bearer ${token}` },
      });

      if (response.status === 200 && response.data.data) {
        setOrderData(response.data.data);
        if (
          response.data.data.service_charge_details.percentage !== "0" &&
          !isNaN(
            parseFloat(response.data.data.service_charge_details.percentage)
          ) &&
          isFinite(response.data.data.service_charge_details.percentage)
        ) {
          setSCHRate(
            parseFloat(response.data.data.service_charge_details.percentage)
          );
        }

        if (
          response.data.data.discount_rate !== "0" &&
          !isNaN(parseFloat(response.data.data.discount_rate)) &&
          isFinite(response.data.data.discount_rate)
        ) {
          setDiscountRate(parseFloat(response.data.data.discount_rate));
        } else {
          setDiscountRate();
        }

        if (
          response.data.data.tax_details &&
          response.data.data.tax_details.length !== 0
        ) {
          response.data.data.tax_details.forEach((item) => {
            if (item.name === "CGST") {
              setCGSTRate(parseFloat(item.percentage));
            } else if (item.name === "SGST") {
              setSGSTRate(parseFloat(item.percentage));
            }
          });
        }
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        addToast("error", error.response.data.message);
      } else {
        addToast("error", error.message);
      }
      console.log(error);
    }
  };

  useEffect(() => {
    setToken(localStorage.getItem("token"));
    setWhatsappApiAccessToken(localStorage.getItem("whatsapp_api_access_token"));
    setWhatsappApiVendorUid(localStorage.getItem("whatsapp_vendor_uid"));
    setWhatsappMassageingApiBaseUrl(localStorage.getItem("whatsapp_massageing_api_base_url"));
    setWhatsappMassageingApiUrl(localStorage.getItem("whatsapp_massageing_api_url"));
    setSendWhatesappMessage(localStorage.getItem("whatesapp_message"));



  }, [token]);

  useEffect(() => {
    const checkChildElements = () => {
      const childWrapper = document.getElementById("gritter-notice-wrapper");
      if (childWrapper && childWrapper.children.length > 0) {
        setHasChild(true);
        setNoOfOrders(childWrapper.children.length);
        setMute(true);
      } else {
        setHasChild(false);
      }
    };

    const logNewChildIds = (mutationsList) => {
      mutationsList.forEach((mutation) => {
        if (mutation.type === "childList") {
          mutation.addedNodes.forEach((node) => {
            if (node.id) {
              const idNumbers = node.id.match(/\d+/);
              if (idNumbers) {
                checkChildElements();
                // startTimerForDiv(idNumbers[0]);
              }
            }
          });

          mutation.removedNodes.forEach((node) => {
            if (node.id) {
              checkChildElements();
            }
          });
        }
      });
    };

    checkChildElements();

    const observer = new MutationObserver(logNewChildIds);

    const targetElement = document.getElementById("gritter-notice-wrapper");
    if (targetElement) {
      observer.observe(targetElement, { childList: true, subtree: true });
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  useEffect(() => {
    if (localStorage.getItem("currencySymbol")) {
      setCurrencySymbol(localStorage.getItem("currencySymbol"));
    }
  }, []);

  useEffect(() => {
    if (orderData && orderData.details) {
      // const subtotal = orderData.details.reduce(
      //   (total, item) =>
      //     total +
      //     (item.product_proprice
      //       ? item.product_proprice * item.quantity
      //       : item.product_price * item.quantity),
      //   0
      // );

      // setSubTotal(parseFloat(subtotal).toFixed(2));

      // let discountedAmount = 0;
      // let discount = 0;
      // let deliveryCharge = 0;
      // let packagingFee = 0;

      // if (orderData.discount_type === "percent") {
      //   discount =
      //     (parseFloat(subtotal).toFixed(2) * (discountRate || 0)) / 100;
      //   discountedAmount = parseFloat(subtotal).toFixed(2) - discount;
      // } else if (orderData.discount_type === "amount") {
      //   discount = parseFloat(discountRate) || 0;
      //   discountedAmount = parseFloat(subtotal).toFixed(2) - discount;
      // }

      // const sch = (discountedAmount * (SCHRate || 0)) / 100;
      // const taxableAmount = discountedAmount + sch;

      // const cgst = (taxableAmount * (CGSTRate || 0)) / 100;
      // const sgst = (taxableAmount * (SGSTRate || 0)) / 100;

      // if (
      //   orderData &&
      //   orderData.delivery_charge &&
      //   parseFloat(orderData.delivery_charge) > 0
      // ) {
      //   deliveryCharge = parseFloat(orderData.delivery_charge).toFixed(2);
      // }

      // if (
      //   orderData &&
      //   orderData.packaging_fee &&
      //   parseFloat(orderData.packaging_fee) > 0
      // ) {
      //   packagingFee = parseFloat(orderData.packaging_fee).toFixed(2);
      // }

      // const totalPaid =
      //   parseFloat(taxableAmount) +
      //   parseFloat(cgst) +
      //   parseFloat(sgst) +
      //   parseFloat(deliveryCharge) +
      //   parseFloat(packagingFee);

      // setDiscountAmount(parseFloat(discount).toFixed(2));
      // setSchAmount(parseFloat(sch).toFixed(2));
      // setCGSTAmount(parseFloat(cgst).toFixed(2));
      // setSGSTAmount(parseFloat(sgst).toFixed(2));
      // setTotalAmountPaid(parseFloat(totalPaid).toFixed(2));











      let withOutTaxPrice = 0
      let subTotal = 0;
      let tempDiscount = 0;
      let tempDiscountRow = 0;


      let tempServicCharge = 0;
      let tempServicChargeRow = 0;

      let tempTax = 0;
      let tempTaxRow = 0;

      let GSTTaxT1 = 0;
      let GSTTaxT2 = 0;
      let GSTTaxT3 = 0;
      let GSTTaxT4 = 0;
      let GSTTaxT5 = 0;

      let packingCharges = 0;


      orderData.details.forEach((i) => {


        // newPrice: selectedProduct.proprice
        // ? selectedProduct.proprice
        // : selectedProduct.price,

        packingCharges += i.quantity * parseInt(i.packaging_fee)

        if (i.product_proprice) {
          withOutTaxPrice = parseInt(i.item_tax_type) === 0 ? parseFloat(i.product_proprice) : (parseFloat(i.product_proprice) * parseFloat(100)) / (parseFloat(100) + parseFloat(i.item_tax_percent))

        } else {
          withOutTaxPrice = parseInt(i.item_tax_type) === 0 ? parseFloat(i.product_price) : (parseFloat(i.product_price) * parseFloat(100)) / (parseFloat(100) + parseFloat(i.item_tax_percent))
        }


        subTotal += (i.quantity) * withOutTaxPrice

        if (orderData.discount_type === "percent") {
          tempDiscount += (((i.quantity) * withOutTaxPrice) * parseFloat(orderData.discount_rate)) / 100
          tempDiscountRow = (((i.quantity) * withOutTaxPrice) * parseFloat(orderData.discount_rate)) / 100
        } else {
          // tempDiscount += (((i.quantity) * withOutTaxPrice) - parseFloat(orderData.discount_rate))
          // tempDiscountRow = (((i.quantity) * withOutTaxPrice) - parseFloat(orderData.discount_rate))
          tempDiscount += ((i.quantity * withOutTaxPrice) - ((i.quantity * withOutTaxPrice) - parseFloat(orderData.discount_rate))) * i.quantity
          tempDiscountRow = ((i.quantity * withOutTaxPrice) - ((i.quantity * withOutTaxPrice) - parseFloat(orderData.discount_rate))) * i.quantity
        }


        tempServicCharge += ((((i.quantity) * withOutTaxPrice) - tempDiscountRow) * parseFloat(SCHRate)) / 100
        tempServicChargeRow = ((((i.quantity) * withOutTaxPrice) - tempDiscountRow) * parseFloat(SCHRate)) / 100



        tempTax += ((((i.quantity) * withOutTaxPrice) + tempServicChargeRow - tempDiscountRow) * parseFloat(i.item_tax_percent)) / 100
        tempTaxRow = ((((i.quantity) * withOutTaxPrice) + tempServicChargeRow - tempDiscountRow) * parseFloat(i.item_tax_percent)) / 100

        if (parseInt(i.item_tax_percent) === 0) {

          GSTTaxT1 += ((((i.quantity) * withOutTaxPrice) + tempServicChargeRow - tempDiscountRow) * parseFloat(i.item_tax_percent)) / 100
          setCGSTTaxt1(GSTTaxT1 / 2)
          setSGSTTaxt1(GSTTaxT1 / 2)

        }

        if (parseInt(i.item_tax_percent) === 5) {

          GSTTaxT2 += ((((i.quantity) * withOutTaxPrice) + tempServicChargeRow - tempDiscountRow) * parseFloat(i.item_tax_percent)) / 100

          setCGSTTaxt2((GSTTaxT2 / 2).toFixed(2))
          setSGSTTaxt2((GSTTaxT2 / 2).toFixed(2))
        }

        if (parseInt(i.item_tax_percent) === 12) {

          GSTTaxT3 += ((((i.quantity) * withOutTaxPrice) + tempServicChargeRow - tempDiscountRow) * parseFloat(i.item_tax_percent)) / 100

          setCGSTTaxt3((GSTTaxT3 / 2).toFixed(2))
          setSGSTTaxt3((GSTTaxT3 / 2).toFixed(2))
        }

        if (parseInt(i.item_tax_percent) === 18) {

          GSTTaxT4 += ((((i.quantity) * withOutTaxPrice) + tempServicChargeRow - tempDiscountRow) * parseFloat(i.item_tax_percent)) / 100
          setCGSTTaxt4((GSTTaxT4 / 2).toFixed(2))
          setSGSTTaxt4((GSTTaxT4 / 2).toFixed(2))
        }

        if (parseInt(i.item_tax_percent) === 28) {

          GSTTaxT5 += ((((i.quantity) * withOutTaxPrice) + tempServicChargeRow - tempDiscountRow) * parseFloat(i.item_tax_percent)) / 100
          setCGSTTaxt5((GSTTaxT5 / 2).toFixed(2))
          setSGSTTaxt5((GSTTaxT5 / 2).toFixed(2))
        }



      })
      setSubTotal(subTotal.toFixed(2))
      setDiscountAmount(tempDiscount.toFixed(2))
      setSchAmount(tempServicCharge.toFixed(2))
      setTotalAmountPaid((subTotal + tempServicCharge - tempDiscount + tempTax).toFixed(2))

      if (orderData.order_type !== "dine_in") {
        setCartProductPackingCharges(packingCharges)
        setTotalAmountPaid((parseFloat(subTotal + tempServicCharge - tempDiscount + tempTax) + parseFloat(packingCharges)).toFixed(2))
      }






















      setIsPrintBill(true);
    }
  }, [orderData, discountRate, SCHRate, CGSTRate, SGSTRate]);

  useEffect(() => {
    if (orderData && orderData.details && isPrintBill && totalAmountPaid) {
      const handlePrintBill = async () => {
        if (orderData && orderData.details && isPrintBill && totalAmountPaid) {
          printJS({
            printable: "contentToPrintBillNotification",
            type: "html",
            targetStyles: ["*"],
            font_size: '8pt'
          });

          setIsPrintBill(false);
        }
      };
      handlePrintBill();
    }
  }, [isPrintBill, orderData, totalAmountPaid]);

  return (
    <div>
      <div className="row">
        <div className={`toastmodal ${hasChild ? "" : "d-none"}`}>
          <div className="toast-container position-fixed  p-3 toastcss">
            <div
              className="toast"
              role="alert"
              aria-live="assertive"
              aria-atomic="true"
            >
              <div className="toast-header actionli">
                <strong className="me-auto">{noOfOrders} New Order</strong>
                <button
                  type="button"
                  className={
                    mute
                      ? "btn btn-outline-info btn-fw"
                      : "btn btn-outline-danger btn-fw"
                  }
                  disabled={!mute}
                  onClick={handleMuteClick}
                >
                  Mute
                  {mute ? (
                    <i className="menu-icon mdi mdi-volume-high vsalign"></i>
                  ) : (
                    <i className="menu-icon mdi mdi-volume-mute vsalign"></i>
                  )}
                </button>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="toast"
                  aria-label="Close"
                  onClick={removeAllChildElements}
                ></button>
              </div>
              <div id="gritter-notice-wrapper" className="toastscroll">
                {/* <div class="toast-body">
                  <div class="d-flex justify-content-between align-items-start">
                    <div class="valign">
                      <h6>ID : #332 | 10:30PM</h6>
                      <div class="badge badge-opacity-warning">
                        Don't send curtley. Hdhkhdhhd
                      </div>
                    </div>
                    <div class="valign text-right">
                      <h6>50th order by Shashi Bala</h6>
                      <div class="badge badge-opacity-success">
                        PREMIUM CUSTOMER
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div>
                    <div class="d-flex justify-content-between align-items-start">
                      <div class="valign">
                        <h6>
                          <img
                            src="./images/non-veg.jpeg"
                            class="nveg"
                            alt="nveg"
                          />
                          1&nbsp;&nbsp; X&nbsp;&nbsp; Veg Burger
                        </h6>
                      </div>
                      <div class="valign text-right">
                        <h6>$4220</h6>
                      </div>
                    </div>
                    <div class="d-flex justify-content-between align-items-start mt-2">
                      <div class="valign">
                        <h6>
                          <img src="./images/veg.jpeg" class="nveg" alt="veg" />
                          1&nbsp;&nbsp; X &nbsp;&nbsp;Veg Burger
                        </h6>
                      </div>
                      <div class="valign text-right">
                        <h6>$4220</h6>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div class="d-flex justify-content-between align-items-start">
                    <div class="valign">
                      <h6 class="mb-0">
                        Total Bill &nbsp;&nbsp;
                        <div class="badge badge-opacity-success">PAID</div>
                      </h6>
                    </div>
                    <div class="valign text-right">
                      <h6 class="mb-0">$4220</h6>
                    </div>
                  </div>
                  <hr />
                  <div>
                    <div class="d-flex justify-content-between align-items-start">
                      <div class="valign">
                        <h6 class="mb-0">Set food preparation time</h6>
                      </div>
                      <div class="valign text-right">
                        <h6 class="text-primary mb-0">
                          <i class="menu-icon mdi mdi-printer vsalign"></i>
                          &nbsp; Print Bill
                        </h6>
                      </div>
                    </div>
                    <div class="quandiv1 mt-2">
                      <button type="button" class="minus1">
                        -
                      </button>
                      <input
                        type="text"
                        name="quantity"
                        value="1"
                        title="Qty"
                        class="qty1"
                        size="4"
                        readonly="true"
                      />
                      <button type="button" class="plus1">
                        +
                      </button>
                    </div>
                  </div>
                  <hr />
                  <div>
                    <div class="d-flex justify-content-between align-items-start">
                      <div class="col-3">
                        <button
                          type="button"
                          class="btn btn-outline-danger col-12 btn-fw"
                        >
                          Reject
                        </button>
                      </div>
                      <div class="text-right  col-9">
                        <button
                          type="submit"
                          class="btn btn-success me-2  col-11"
                          // style="border: 1px solid #34B1AA;"
                          style={{ border: "1px solid #34B1AA" }}
                        >
                          Accept Order&nbsp;(00:30)
                        </button>
                      </div>
                    </div>
                  </div>
                  <hr />
                </div>
                <div class="toast-body">
                  <div class="d-flex justify-content-between align-items-start">
                    <div class="valign">
                      <h6>ID : #332 | 10:30PM</h6>
                      <div class="badge badge-opacity-warning">
                        Don't send curtley. Hdhkhdhhd
                      </div>
                    </div>
                    <div class="valign text-right">
                      <h6>50th order by Shashi Bala</h6>
                      <div class="badge badge-opacity-success">
                        PREMIUM CUSTOMER
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div>
                    <div class="d-flex justify-content-between align-items-start">
                      <div class="valign">
                        <h6>
                          <img
                            src="./images/non-veg.jpeg"
                            class="nveg"
                            alt="nveg"
                          />
                          1&nbsp;&nbsp; X&nbsp;&nbsp; Veg Burger
                        </h6>
                      </div>
                      <div class="valign text-right">
                        <h6>$4220</h6>
                      </div>
                    </div>
                    <div class="d-flex justify-content-between align-items-start mt-2">
                      <div class="valign">
                        <h6>
                          <img src="./images/veg.jpeg" class="nveg" alt="veg" />
                          1&nbsp;&nbsp; X &nbsp;&nbsp;Veg Burger
                        </h6>
                      </div>
                      <div class="valign text-right">
                        <h6>$4220</h6>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div class="d-flex justify-content-between align-items-start">
                    <div class="valign">
                      <h6 class="mb-0">
                        Total Bill &nbsp;&nbsp;
                        <div class="badge badge-opacity-success">PAID</div>
                      </h6>
                    </div>
                    <div class="valign text-right">
                      <h6 class="mb-0">$4220</h6>
                    </div>
                  </div>
                  <hr />
                  <div>
                    <div class="d-flex justify-content-between align-items-start">
                      <div class="valign">
                        <h6 class="mb-0">Set food preparation time</h6>
                      </div>
                      <div class="valign text-right">
                        <h6 class="text-primary mb-0">
                          <i class="menu-icon mdi mdi-printer vsalign"></i>
                          &nbsp; Print Bill
                        </h6>
                      </div>
                    </div>
                    <div class="quandiv1 mt-2">
                      <button type="button" class="minus1">
                        -
                      </button>
                      <input
                        type="text"
                        name="quantity"
                        value="1"
                        title="Qty"
                        class="qty1"
                        size="4"
                        readonly="true"
                      />
                      <button type="button" class="plus1">
                        +
                      </button>
                    </div>
                  </div>
                  <hr />
                  <div>
                    <div class="d-flex justify-content-between align-items-start">
                      <div class="col-3">
                        <button
                          type="button"
                          class="btn btn-outline-danger col-12 btn-fw"
                        >
                          Reject
                        </button>
                      </div>
                      <div class="text-right  col-9">
                        <button
                          type="submit"
                          class="btn btn-success me-2  col-11"
                          // style="border: 1px solid #34B1AA;"
                          style={{ border: "1px solid #34B1AA" }}
                        >
                          Accept Order&nbsp;(00:30)
                        </button>
                      </div>
                    </div>
                  </div>
                  <hr />
                </div> */}
              </div>
            </div>
          </div>
        </div>
        <div className="">
          <div id="gritter-notice-wrapper2" className="notify">
            {/* <div className="alert alert-dark alert-dismissible">
              <span
                className="close closeicon2"
                data-bs-dismiss="alert"
                aria-label="close"
              >
                &times;
              </span>
              <strong>Success!</strong> This alert box could indicate a
              successful or positive action.
            </div> */}
          </div>
        </div>
      </div>

      <div style={{ display: "none" }}>
        <div id="contentToPrintBillNotification">
          <p className="name" style={{ textAlign: "end" }}>
            {orderData &&
              orderData.invoice_number}
          </p>
          <div className="printcss">
            <div className="col-12">
              <p className="name">
                <strong>
                  {orderData && capitalizeFirstLetter(orderData.fooders_name)}
                </strong>
              </p>

              <p className="name">

                {orderData && capitalizeFirstLetter(orderData.Fooder_name2)}

              </p>

              <p className="name">
                {orderData && capitalizeFirstLetter(orderData.f_address)}{', '}
                {orderData &&
                  (orderData.f_city || orderData.f_state || orderData.f_zipcode) && (
                    <>
                      {orderData.f_city && `${orderData.f_city}, `}
                      {orderData.f_state && capitalizeFirstLetter(orderData.f_state)}
                      {orderData.f_zipcode && `, ${orderData.f_zipcode}`}
                    </>
                  )}
              </p>
              {orderData && orderData.f_landline ? <p className="name">Phone No : {' '}
                {orderData.f_landline}

              </p> : <></>}
            </div>
            <div className="col-12">
              {orderData && orderData.fooders_gstin && (
                <p className="gst thank">
                  GST Number : {orderData && orderData.fooders_gstin}
                </p>
              )}
            </div>

            {orderData && <table className="tblecss info">
              <tbody>
                <tr>
                  <td>
                    Name : {" "}
                    {capitalizeFirstLetter(orderData.eater_name)}
                  </td>
                </tr>

                {orderData.eater_phonenumber && orderData.eater_phonenumber !== '' ? <tr>
                  <td>
                    Phone : {" "}
                    {capitalizeFirstLetter(orderData.eater_phonenumber)}
                  </td>
                </tr> : <></>}


                {orderData.address && orderData.address !== '' ? <tr>
                  <td>
                    Address : {" "}
                    {capitalizeFirstLetter(orderData.address)}
                  </td>
                </tr> : <></>}
                <tr>
                  <td>
                    Date :{" "}
                    <span>
                      {orderData &&
                        formatTimestampForDate(orderData.creation_date)}
                    </span>
                  </td>
                  <td className="txtrg">
                    Time :{" "}
                    <span>
                      {orderData &&
                        formatTimestampForTime(orderData.creation_date)}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    {orderData && orderData.order_type && (
                      <strong>
                        {orderData.order_type === "dine_in"
                          ? orderData.table_no
                          : orderData.order_type
                            .toUpperCase()
                            .replace("_", " ")}
                      </strong>
                    )}
                  </td>
                  <td className="txtrg">
                    Order : #{orderData && orderData.order_number_qrcode}
                  </td>
                </tr>
                <tr>
                  <th colSpan="2">
                    <p
                      style={{
                        textAlign: "center",
                        padding: "5px 2px",
                        margin: "0",
                        fontSize: "medium",
                      }}
                    >
                      Original Receipt
                    </p>
                  </th>
                </tr>
              </tbody>
            </table>}
            <table className="tblecss2">
              <thead>
                <tr>
                  <th className="thcss itemth">Item</th>
                  <th className="thcss qtyth txtct">Qty</th>
                  <th className="thcss rateth txtrg">Rate</th>
                  <th className="thcss amtth txtrg">Amt</th>
                </tr>
              </thead>

              <tbody>
                {orderData &&
                  orderData.details &&
                  orderData.details.map((order, index) => (
                    <tr key={index}>
                      <td className="tdcss nametd">
                        {capitalizeFirstLetter(order.product_name)}

                        {order.variant_details && <><br /></>}
                        {order.variant_details && <>
                          {JSON.parse(order.variant_details).combination_details && JSON.parse(order.variant_details).combination_details.map((i) => (
                            <><b>{i.attribute_name}: </b> {i.attribute_value_name} <br /></>
                          ))}
                        </>}

                        {order.addons_items_details && JSON.parse(order.addons_items_details).map((i, no) => (
                          <><b>{no + 1}.</b> {i.addon_item_name} <br /></>
                        ))}
                      </td>
                      <td className="tdcss qtytd txtct">{order.quantity}</td>
                      <td className="tdcss ratetd txtrg">
                        {currencySymbol}
                        {order.product_proprice
                          ? isValidPrice(order.product_proprice)
                          : isValidPrice(order.product_price)}



                      </td>
                      <td className="tdcss amttd txtrg">
                        {currencySymbol}
                        {order.product_proprice
                          ? isValidPrice(
                            order.product_proprice * order.quantity
                          )
                          : isValidPrice(order.product_price * order.quantity)}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
            <table className="tblecss2">
              <tbody>
                <tr>
                  <td className="txtrg brtp subtl">Subtotal</td>
                  <td className="txtrg brtp">
                    {currencySymbol}
                    {subTotal}
                  </td>
                </tr>
                {discountRate && orderData.discount_type === "percent" && (
                  <tr>
                    <td className="txtrg subtl">Discount ({discountRate}%)</td>
                    <td className="txtrg">
                      {currencySymbol}
                      {discountAmount}
                    </td>
                  </tr>
                )}
                {discountRate && orderData.discount_type === "amount" && (
                  <tr>
                    <td className="txtrg subtl">Discount ({currencySymbol})</td>
                    <td className="txtrg">
                      {currencySymbol}
                      {discountAmount}
                    </td>
                  </tr>
                )}
                {SCHRate !== 0 && (
                  <tr>
                    <td className="txtrg subtl">
                      {orderData.service_charge_details &&
                        orderData.service_charge_details.name}
                      ({SCHRate}
                      %)
                    </td>
                    <td className="txtrg">
                      {currencySymbol}
                      {schAmount}
                    </td>
                  </tr>
                )}
                {/* {CGSTRate !== 0 && (
                  <tr>
                    <td className="txtrg subtl">CGST({CGSTRate}%)</td>
                    <td className="txtrg">
                      {currencySymbol}
                      {cgstAmount}
                    </td>
                  </tr>
                )}
                {SGSTRate !== 0 && (
                  <tr>
                    <td className="txtrg subtl">SGST({SGSTRate}%)</td>
                    <td className="txtrg">
                      {currencySymbol}
                      {sgstAmount}
                    </td>
                  </tr>
                )} */}
                {CGSTTaxT2 !== 0 &&
                  (<tr>
                    <td className="txtrg subtl">CGST(2.5%)</td>
                    <td className="txtrg">
                      {currencySymbol}
                      {CGSTTaxT2}
                    </td>
                  </tr>)
                }

                {SGSTTaxT2 !== 0 &&
                  (<tr>
                    <td className="txtrg subtl">SGST(2.5%)</td>
                    <td className="txtrg">
                      {currencySymbol}
                      {SGSTTaxT2}
                    </td>
                  </tr>)
                }


                {CGSTTaxT3 !== 0 &&
                  (<tr>
                    <td className="txtrg subtl">CGST(6%)</td>
                    <td className="txtrg">
                      {currencySymbol}
                      {CGSTTaxT3}
                    </td>
                  </tr>)
                }

                {SGSTTaxT3 !== 0 &&
                  (<tr>
                    <td className="txtrg subtl">SGST(6%)</td>
                    <td className="txtrg">
                      {currencySymbol}
                      {SGSTTaxT3}
                    </td>
                  </tr>)
                }


                {CGSTTaxT4 !== 0 &&
                  (<tr>
                    <td className="txtrg subtl">CGST(9%)</td>
                    <td className="txtrg">
                      {currencySymbol}
                      {CGSTTaxT4}
                    </td>
                  </tr>)
                }

                {SGSTTaxT4 !== 0 &&
                  (<tr>
                    <td className="txtrg subtl">SGST(9%)</td>
                    <td className="txtrg">
                      {currencySymbol}
                      {SGSTTaxT4}
                    </td>
                  </tr>)
                }


                {CGSTTaxT5 !== 0 &&
                  (<tr>
                    <td className="txtrg subtl">CGST(14%)</td>
                    <td className="txtrg">
                      {currencySymbol}
                      {CGSTTaxT5}
                    </td>
                  </tr>)
                }

                {SGSTTaxT5 !== 0 &&
                  (<tr>
                    <td className="txtrg subtl">SGST(14%)</td>
                    <td className="txtrg">
                      {currencySymbol}
                      {SGSTTaxT5}
                    </td>
                  </tr>)
                }

                {cartProductPackingCharges !== 0 && orderData.order_type !== "dine_in" &&
                  (<tr>
                    <td className="txtrg subtl">Packaging Charges</td>
                    <td className="txtrg">
                      {currencySymbol}
                      {cartProductPackingCharges}
                    </td>
                  </tr>)
                }
                {orderData &&
                  orderData.delivery_charge &&
                  parseFloat(orderData.delivery_charge) > 0 && (
                    <tr>
                      <td className="txtrg subtl">Delivery Charge</td>
                      <td className="txtrg">
                        {currencySymbol}
                        {parseFloat(orderData.delivery_charge).toFixed(2)}
                      </td>
                    </tr>
                  )}
                {orderData &&
                  orderData.packaging_fee &&
                  parseFloat(orderData.packaging_fee) > 0 && (
                    <tr>
                      <td className="txtrg subtl">Packaging Fee</td>
                      <td className="txtrg">
                        {currencySymbol}
                        {parseFloat(orderData.packaging_fee).toFixed(2)}
                      </td>
                    </tr>
                  )}
                <tr>
                  <th className="tlt">Total</th>
                  <th className="tlt txtrg pd0">
                    {currencySymbol}
                    {totalAmountPaid}
                  </th>
                </tr>
              </tbody>
            </table>
            <table className="tblecss">
              <tbody>
                <tr>
                  <td>
                    {orderData && orderData.fooders_fssai_number && (
                      <p className="thank">
                        FSSAI Number : {orderData.fooders_fssai_number}
                      </p>
                    )}
                  </td>
                </tr>
                {/* {orderData && orderData.payment_type ? <tr>
                  <td>
                    <p className="thank">Paid By {orderData.payment_type}</p>
                  </td>
                </tr> : <></>} */}

                {orderData ? <tr>
                  <td>
                    {/* <p className="thank">Paid By {data.payment_type}</p> */}
                    {orderData.payment_status === 0 && (
                      <p className="thank">Unpaid</p>
                    )
                    }
                    {orderData.payment_status === 1 && (
                      <p className="thank">Paid</p>
                    )
                    }
                    {orderData.payment_status === 3 && (
                      <p className="thank">Partially Paid</p>
                    )
                    }
                    {orderData.payment_status === 2 && (
                      <p className="thank">Hold</p>
                    )
                    }
                  </td>
                </tr> : <></>}
                {orderData && <tr>
                  <td>
                    <p className="thank">{orderData.billing_notes}</p>
                  </td>
                </tr>}

                <tr>
                  <td>
                    <p className="thank">Thank you for your visit!</p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <LoadingModal isLoading={isLoading} />
    </div>
  );
};

export default Notification;
