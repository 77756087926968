import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import SideBar from "../../other/SideBar";
import NavBar from "../../other/NavBar";
import {
    GetAllProductsNameURL,
    GetItemsReportURL,
    ScriptId,
    ScriptSRC,
} from "../../../assets/api/BaseURL";
import LoadingModal from "../../../loader/LoadingModal";
import Notification from "../../other/Notification";
import { useToast } from "../../../../context/ToastContext";
import jsPDF from "jspdf";
import "jspdf-autotable";
import * as XLSX from "xlsx";

import Select from "react-select";


import dayjs from 'dayjs';


import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

const ItemsReport = () => {
    const navigate = useNavigate();
    const { addToast } = useToast();
    const [token, setToken] = useState("");
    const [toggleSideBar, setToggleSideBar] = useState(false);
    const [canvas, setCanvas] = useState(false);
    const [data, setData] = useState([]);
    const [totalSubAmount, setSubAmount] = useState(0);
    const [totalDiscountAmount, setTotalDiscountAmount] = useState(0);
    const [totalSCHAmount, setTotalSCHAmount] = useState(0);
    const [totalTaxAmount, setTotalTaxAmount] = useState(0);
    const [totalFinalAmount, setTotalFinalAmount] = useState(0);
    const [totalQuantity, setTotalQuantity] = useState(0);







    const [productData, setProductData] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState({ value: 'all', label: 'All Product' });



    const [reportData, setReportData] = useState({
        start_date: "",
        end_date: "",
    });
    const [reportDataByType, setReportDataByType] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState({
        start_date: `${getToday()}T00:00`,
        end_date: `${getTomorrow()}T00:00`,
        product_id: "all",
        payment_status: "all"
    });

    const handleToggleSidebar = (e) => {
        e.preventDefault();
        setToggleSideBar(!toggleSideBar);
    };

    const handleToggleOffCanvas = (e) => {
        e.preventDefault();
        setCanvas(!canvas);
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleBackClick = (e) => {
        e.preventDefault();
        navigate("/reports");
    };

    function formatDateRange(inputDate) {
        const parsedDate = new Date(inputDate);
        const day = parsedDate.getDate().toString().padStart(2, "0");
        const month = (parsedDate.getMonth() + 1).toString().padStart(2, "0");
        const year = parsedDate.getFullYear().toString();

        return day + month + year;
    }

    function getToday() {
        const today = new Date();
        const yyyy = today.getFullYear();
        let mm = today.getMonth() + 1;
        let dd = today.getDate();

        if (mm < 10) mm = "0" + mm;
        if (dd < 10) dd = "0" + dd;

        return `${yyyy}-${mm}-${dd}`;
    }
    function getTomorrow() {
        const today = new Date();
        today.setDate(today.getDate() + 1);
        const yyyy = today.getFullYear();
        let mm = today.getMonth() + 1;
        let dd = today.getDate();
    
        if (mm < 10) mm = "0" + mm;
        if (dd < 10) dd = "0" + dd;
    
        return `${yyyy}-${mm}-${dd}`;
    }
    function getCurrentDateFormatted() {
        const today = new Date();
        const day = today.getDate();
        const month = today.getMonth() + 1;
        const year = today.getFullYear();

        const formattedDate = `${day < 10 ? "0" : ""}${day}/${month < 10 ? "0" : ""
            }${month}/${year}`;

        return formattedDate;
    }

    function formatDate(dateTimeStr) {
        // Split the input date and time string into parts
        let parts = dateTimeStr.split('T');
        let datePart = parts[0];
        let timePart = parts[1];

        // Split the date part into year, month, and day
        let dateParts = datePart.split('-');
        let year = dateParts[0];
        let month = dateParts[1];
        let day = dateParts[2];

        // Format the date as dd/mm/yyyy
        let formattedDate = `${day}/${month}/${year}`;

        // Split the time part into hours and minutes
        let timeParts = timePart.split(':');
        let hours = parseInt(timeParts[0], 10);
        let minutes = timeParts[1];

        // Convert hours to 12-hour format with AM/PM
        let period = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12 || 12; // Handle midnight (0) as 12 AM

        // Format the time as hh:mm AM/PM
        let formattedTime = `${hours}:${minutes} ${period}`;

        // Return the combined formatted date and time
        return `${formattedDate} ${formattedTime}`;
    }

    const handleResetClick = (e) => {
        e.preventDefault();
        setFormData({
            start_date: `${getToday()}T00:00`,
            end_date: `${getTomorrow()}T00:00`,
            product_id: "all",
            payment_status: ""
        });
        setSelectedProduct({ value: 'all', label: 'All Product' })
        setData([]);
        setReportData();
    };

    const handleSubmitClick = async (e) => {
        e.preventDefault();

        if (formData.start_date === "") {
            addToast("error", "Start Date can not be empty");
        } else if (formData.end_date === "") {
            addToast("error", "End Date can not be empty");
        } else {
            setIsLoading(true);
            try {
                const response = await axios.get(GetItemsReportURL, {
                    params: {
                        start_date: convertToISOFormat(formData.start_date),
                        end_date: convertToISOFormat(formData.end_date),
                        product_id: formData.product_id,
                        payment_status: formData.payment_status,

                    },
                    headers: { authorization: `Bearer ${token}` },
                });

                if (response.status === 200 && response.data.data) {

                    let totalSubAmount = 0
                    let totalDiscountAmount = 0
                    let totalSCHAmount = 0
                    let totalTaxAmount = 0
                    let totalFinalAmount = 0
                    let totalQuantity = 0





                    response.data.data.forEach(element => {
                        totalSubAmount += element.subtotal_product_price
                        totalDiscountAmount += element.discount_price
                        totalSCHAmount += element.sch_price
                        totalTaxAmount += element.tax_price
                        totalFinalAmount += element.subtotal_product_price - element.discount_price + element.sch_price + element.tax_price
                        totalQuantity += parseInt(element.total_quantity)

                    });
                    setSubAmount(totalSubAmount)
                    setTotalDiscountAmount(totalDiscountAmount)
                    setTotalSCHAmount(totalSCHAmount)
                    setTotalTaxAmount(totalTaxAmount)
                    setTotalFinalAmount(totalFinalAmount)
                    setTotalQuantity(totalQuantity)
                    setData(response.data.data);

                    setReportData({
                        start_date: response.data.start_date,
                        end_date: response.data.end_date,
                    });

                    if (response.data.data.length <= 0) {
                        addToast("error", "Data not avaibale!!!");
                    }

                } else if (response.status === 200 && response.data.message) {
                    addToast("success", response.data.message);
                    setData([]);
                    setSubAmount(0)
                }
            } catch (error) {
                if (error.response && error.response.data.message) {
                    addToast("error", error.response.data.message);
                } else {
                    addToast("error", error.message);
                }
                console.log(error);
            }
            setIsLoading(false);
        }
    };

    function padNumber(number, length) {
        return String(number).padStart(length, '0');
    }

    function convertToISOFormat(inputDate) {
        const [datePart, timePart] = inputDate.split('T');
        const [year, month, day] = datePart.split('-');
        const [hours, minutes] = timePart.split(':');

        const isoFormattedDate = `${year}-${padNumber(month, 2)}-${padNumber(day, 2)}T${padNumber(hours, 2)}:${padNumber(minutes, 2)}`;

        return isoFormattedDate;
    }

    const downloadPdfDocument = () => {
        const doc = new jsPDF();

        doc.autoTable({
            html: "#pdfContent",
            theme: "grid",
            styles: {
                font: "times",
                fontSize: 9,
                cellPadding: 1,
                overflow: "linebreak",
            },
            headStyles: {
                fillColor: [220, 220, 220],
                textColor: [0, 0, 0],
                halign: "center",
                valign: "middle",
                fontStyle: "bold",
            },
            bodyStyles: {
                fillColor: [255, 255, 255],
                textColor: [0, 0, 0],
                halign: "left",
                valign: "middle",
            },
            footStyles: {
                fillColor: [220, 220, 220],
                textColor: [0, 0, 0],
                halign: "left",
                valign: "middle",
                fontStyle: "bold",
            },
            columnStyles: {
                0: { halign: "left", cellWidth: 35 },
                1: { halign: "left", cellWidth: 20 },
                2: { halign: "left" },
                3: { halign: "left" },
                4: { halign: "left" },
                5: { halign: "left" },
                6: { halign: "left", cellWidth: 20 },
                7: { halign: "left" },
            },
            margin: { top: 10, left: 10, right: 10 },
            didParseCell: function (data) {
                if (data.row.section === "body" && data.column.dataKey === 0) {
                    data.cell.styles.halign = "left";
                }
            },
        });

        doc.save(
            `Order_Report-${formatDateRange(reportData.start_date)}-${formatDateRange(
                reportData.end_date
            )}.pdf`
        );
    };

    const exportToExcel = () => {
        var table_elt = document.getElementById("pdfContent");

        var workbook = XLSX.utils.table_to_book(table_elt);

        XLSX.writeFile(
            workbook,
            `Order_Report-${formatDateRange(reportData.start_date)}-${formatDateRange(
                reportData.end_date
            )}.xlsx`
        );
    };

    useEffect(() => {
        if (!localStorage.getItem("token")) {
            navigate("/");
        }
    }, [navigate]);

    useEffect(() => {
        setToken(localStorage.getItem("token"));
    }, [token]);

    useEffect(() => {
        const existingScript = document.getElementById(ScriptId);
        if (!existingScript && localStorage.getItem("userId")) {
            const userId = localStorage.getItem("userId");
            const script = document.createElement("script");
            script.src = ScriptSRC + userId.replace(/"/g, "");
            script.defer = true;
            script.id = ScriptId;
            document.head.appendChild(script);
        }
    }, []);

    //   const handleDetailsClick = (id) => {

    //     // localStorage.setItem("orderID", id);
    //     // navigate(`/order_details`);
    //     window.open(`/order_details?id=${id}`, '_blank');

    //   };

















    useEffect(() => {
        if (token) {

            getData();
        }
    }, [token]);





    async function getData() {
        setIsLoading(true);
        try {
            const response = await axios.get(GetAllProductsNameURL, {

                headers: { authorization: `Bearer ${token}` },
            });

            if (response.status === 200 && response.data.data) {
                // setProductData(response.data.data);

                let modifiedData = [{ value: 'all', label: 'All Product' }]
                response.data.data.map((item) => (

                    modifiedData.push({
                        value: item.id,
                        label: item.name,
                    })
                ));
                setProductData(modifiedData);

            } else if (response.status === 200 && response.data.message) {
                setProductData([]);



            }
        } catch (error) {
            if (error.response && error.response.data.message) {
                addToast("error", error.response.data.message);
            } else {
                addToast("error", error.message);
            }
            console.log(error);
        }
        setIsLoading(false);
    }






    const customStyles = {
        control: (provided) => ({
          ...provided,
          height: '54px', // Set your desired height here
          minHeight: '54px', // Ensure the minimum height is the same to avoid resizing issues
        }),
        valueContainer: (provided) => ({
          ...provided,
          height: '40px', // Match this with the control height
          padding: '0 8px',
        }),
        input: (provided) => ({
          ...provided,
          margin: '0px',
        }),
        indicatorsContainer: (provided) => ({
          ...provided,
          height: '54px',
        }),
      };

    return (
        <>
            {token && (
                <>
                    <div
                        className={
                            toggleSideBar
                                ? "container-scroller sidebar-icon-only"
                                : "container-scroller"
                        }
                    >
                        <nav className="navbar default-layout col-lg-12 col-12 p-0 fixed-top d-flex align-items-top flex-row">

                            <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-start">
                                <Link className="navbar-brand brand-logo-mini" to="/live_orders">
                                    <img src="images/mini-kr-logo.svg" alt="logo" />
                                </Link>
                                <div className="me-1">
                                    <button
                                        className={
                                            toggleSideBar
                                                ? "navbar-toggler navbar-toggler align-self-center"
                                                : "navbar-toggler navbar-toggler align-self-center text-white"
                                        }
                                        type="button"
                                        onClick={handleToggleSidebar}
                                    >
                                        <span className="icon-menu"></span>
                                    </button>
                                </div>
                                <div>
                                    <Link to="/live_orders">
                                        <span className="navbar-brand brand-logo">
                                            <img src="images/Khateraho_logo_white.png" alt="logo" />
                                        </span>
                                    </Link>
                                </div>
                            </div>
                            <NavBar handleToggle={handleToggleOffCanvas} />
                        </nav>
                        <div className="container-fluid page-body-wrapper px-0">
                            <SideBar active="reports" toggleCanvas={toggleSideBar} />

                            <div className="main-panel">
                                <div className="content-wrapper">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="d-flex justify-content-between align-items-start mb-2">
                                                <div className="vsalign">
                                                    <h3 className="ukhd mb-3">Items Report</h3>
                                                    <p className="list-group-item-text mb-0">This report shows sales of each items with their respective quantities sold.</p>
                                                </div>
                                                <div className="vsalign">
                                                    <span
                                                        className="btn btn-danger mb-0 me-0"
                                                        onClick={handleBackClick}
                                                    >
                                                        <i className="menu-icon mdi mdi-arrow-left vsalign"></i>
                                                        Go Back
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="row flex-grow">
                                                <div className="col-12 grid-margin stretch-card">
                                                    <div className="card card-rounded">
                                                        <div className="card-body">
                                                            <div className="row">

                                                            <div className="col-4 vsalign">
                                                                    <div className="form-group">
                                                                        <label>Product</label>
                                                                        {/* <select
                                                                            className="form-select kr-customsales-select"
                                                                            name="product_id"
                                                                            value={formData.product_id}
                                                                            onChange={handleChange}
                                                                        >
                                                                            <option value="">All</option>
                                                                            <option value="dine_in">Dine In</option>
                                                                            <option value="delivery">Delivery</option>
                                                                            <option value="take_away">
                                                                                Take Away
                                                                            </option>
                                                                            <option value="">All</option>

                                                                            {productData.map((item) => (
                                                                                <option value={item.id}>{item.name}</option>
                                                                            ))}

                                                                            
                                                                        </select> */}
                                                                        <div style={{marginTop:'5px'}}>
                                                                        <Select
                                                                            options={productData}
                                                                            value={selectedProduct}
                                                                            styles={customStyles}

                                                                            onChange={(selectedOption) => {
                                                                                setSelectedProduct(selectedOption)
                                                                                setFormData({
                                                                                    ...formData,
                                                                                    product_id: selectedOption.value,
                                                                                });
                                                                            }}
                                                                        />

                                                                        </div>

                                                                    
                                                                    </div>
                                                                </div>

                                                                <div className="col-4 vsalign">
                                                                    <div className="form-group">
                                                                        <label>
                                                                            Start Date
                                                                            <span className="text-danger">*</span>
                                                                        </label>
                                                                        {/* <input
                                      type="datetime-local"
                                      className="form-control"
                                      name="start_date"
                                      max={getToday()}
                                      value={formData.start_date}
                                      onChange={handleChange}
                                    />   */}

                                                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                            <DemoContainer components={['DateTimePicker']}>
                                                                                <DateTimePicker style={{ width: '100px' }}
                                                                                    // name="start_date"
                                                                                    inputFormat="dd/mm/yyyy HH:mm"
                                                                                    value={dayjs(formData.start_date)}
                                                                                    onChange={(e) => { setFormData({ ...formData, start_date: `${e.$y}-${e.$M + 1}-${e.$D}T${e.$H}:${e.$m}` }) }}
                                                                                
                                                                                
                                                                                />
                                                                            </DemoContainer>
                                                                        </LocalizationProvider>       
                                                                    </div>

                                                                </div>
                                                                <div className="col-4 vsalign">
                                                                    <div className="form-group">
                                                                        <label>
                                                                            End Date
                                                                            <span className="text-danger">*</span>
                                                                        </label>
                                                                        {/* <input
                                      type="datetime-local"
                                      className="form-control"
                                      name="end_date"
                                      max={getToday()}
                                      value={formData.end_date}
                                      onChange={handleChange}
                                    /> */}
                                                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                            <DemoContainer components={['DateTimePicker']}>
                                                                                <DateTimePicker
                                                                                    // label="Enter Date and Time"
                                                                                    value={dayjs(formData.end_date)}

                                                                                    onChange={(e) => { setFormData({ ...formData, end_date: `${e.$y}-${e.$M + 1}-${e.$D}T${e.$H}:${e.$m}` }) }}
                                                                                />
                                                                            </DemoContainer>
                                                                        </LocalizationProvider>
                                                                    </div>
                                                                </div>
                                                               
                                                                {/* <div className="col-2 vsalign">
                                                                    <div className="form-group">
                                                                        <label>Payment Status</label>
                                                                        <select
                                                                            className="form-select kr-customsales-select"
                                                                            name="payment_status"
                                                                            value={formData.payment_status}
                                                                            onChange={handleChange}
                                                                        >
                                                                            <option value="all">All</option>
                                                                            <option value="0">Unpaid</option>
                                                                            <option value="1">Paid</option>
                                                                            <option value="2">Hold</option>
                                                                            <option value="3">Partially Unpaid</option>


                                                                        </select>
                                                                    </div>
                                                                </div> */}

                                                                <div className="col-4 vsalign">
                                                                    <button
                                                                        type="submit"
                                                                        className="btn btn-primary me-2"
                                                                        onClick={handleSubmitClick}
                                                                    >
                                                                        Submit
                                                                    </button>
                                                                    <button
                                                                        className="btn btn btn-secondary"
                                                                        onClick={handleResetClick}
                                                                    >
                                                                        Reset
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            {data && data.length !== 0 && (
                                                                <>
                                                                    <div className="d-sm-flex justify-content-between align-items-start">
                                                                        <div className="vsalign"></div>
                                                                        <div className="vsalign">
                                                                            <button
                                                                                type="submit"
                                                                                className="btn btn-primary me-2"
                                                                                onClick={downloadPdfDocument}
                                                                            >
                                                                                Generate PDF
                                                                            </button>
                                                                            <button
                                                                                type="submit"
                                                                                className="btn btn-primary me-2"
                                                                                onClick={exportToExcel}
                                                                            >
                                                                                Export To Excel
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="table-responsive mt-1">
                                                                            <table
                                                                                id="pdfContent"
                                                                                className="table table-bordered"
                                                                            >
                                                                                <thead>
                                                                                    <tr className="text-center">
                                                                                        <th colSpan={7}>
                                                                                            <h4 className="p-0 mb-0">
                                                                                                Items Report
                                                                                            </h4>
                                                                                        </th>
                                                                                    </tr>
                                                                                    <tr className="text-center">
                                                                                        <th colSpan={7}>
                                                                                            Report Date :{" "}
                                                                                            {getCurrentDateFormatted()}
                                                                                        </th>
                                                                                    </tr>
                                                                                    <tr className="text-left">
                                                                                        <th colSpan={7}>

                                                                                            <p>

                                                                                                Date Range:{" "}
                                                                                                {reportData.start_date &&
                                                                                                    formatDate(
                                                                                                        reportData.start_date
                                                                                                    )}{" "}
                                                                                                -{" "}
                                                                                                {reportData.end_date &&
                                                                                                    formatDate(reportData.end_date)}
                                                                                            </p>
                                                                                            {' '}






                                                                                        </th>
                                                                                    </tr>

                                                                                    <tr>


                                                                                        <th>Product Name</th>
                                                                                        <th>Quantity</th>
                                                                                        <th>Sub Total</th>
                                                                                        <th>Discount</th>
                                                                                        <th>Service Charge</th>
                                                                                        <th>Tax</th>
                                                                                        <th>Total Amount</th>






                                                                                    </tr>
                                                                                </thead>

                                                                                <tbody>
                                                                                    <>
                                                                                        {data.length === 0 ? (
                                                                                            <tr>
                                                                                                <td
                                                                                                    colSpan="3"
                                                                                                    className="text-center"
                                                                                                >
                                                                                                    No data found
                                                                                                </td>
                                                                                            </tr>
                                                                                        ) : (
                                                                                            <>
                                                                                                {data.map((item, index) => (
                                                                                                    <tr key={index}>

                                                                                                        <td>
                                                                                                            {
                                                                                                                item.product_name
                                                                                                            }
                                                                                                        </td>
                                                                                                        <td >
                                                                                                            {
                                                                                                                item.total_quantity
                                                                                                            }
                                                                                                        </td>
                                                                                                        <td>
                                                                                                            Rs. {parseFloat(item.subtotal_product_price).toFixed(2)}
                                                                                                        </td>

                                                                                                        <td>
                                                                                                            Rs. {parseFloat(item.discount_price).toFixed(2)}
                                                                                                        </td>
                                                                                                        <td>
                                                                                                            Rs. {parseFloat(item.sch_price).toFixed(2)}
                                                                                                        </td>
                                                                                                        <td>
                                                                                                            Rs. {parseFloat(item.tax_price).toFixed(2)}
                                                                                                        </td>
                                                                                                        <td>
                                                                                                            Rs. {parseFloat(item.subtotal_product_price - item.discount_price + item.sch_price + item.tax_price).toFixed(2)}
                                                                                                        </td>
                                                                                                        
                                                                                                    </tr>
                                                                                                ))}

                                                                                                {/* {reportDataByType &&
                                                  Object.keys(
                                                    reportDataByType
                                                  ).map((orderType, index) => (
                                                    <tr key={index}>
                                                      <th colSpan={5}>
                                                        {orderType}
                                                      </th>
                                                      <th>
                                                        <p className="mb-0">
                                                          (Subtotal)
                                                        </p>
                                                        <br />
                                                        Rs.
                                                        {
                                                          reportDataByType[
                                                            orderType
                                                          ].subtotal
                                                        }
                                                      </th>
                                                      <th>
                                                        <p className="mb-0">
                                                          (Discount)
                                                        </p>
                                                        <br />
                                                        Rs.
                                                        {
                                                          reportDataByType[
                                                            orderType
                                                          ].discount
                                                        }
                                                      </th>
                                                      <th>
                                                        <p className="mb-0">
                                                          (Service Charge)
                                                        </p>
                                                        <br />
                                                        Rs.
                                                        {
                                                          reportDataByType[
                                                            orderType
                                                          ].serviceCharge
                                                        }
                                                      </th>
                                                      
                                                      <th>
                                                        <p className="mb-0">
                                                          (Tax)
                                                        </p>
                                                        <br />
                                                        Rs.
                                                        {
                                                          reportDataByType[
                                                            orderType
                                                          ].tax
                                                        }
                                                      </th>

                                                      <th>
                                                        <p className="mb-0">
                                                          (Packaging Charges)
                                                        </p>
                                                        <br />
                                                        Rs.
                                                        {
                                                          reportDataByType[
                                                            orderType
                                                          ].totalPackingCharges
                                                        }
                                                      </th>

                                                      
                                                      <th>
                                                        <p className="mb-0">
                                                          (Round Off Amount)
                                                        </p>
                                                        <br />
                                                        Rs.
                                                        {
                                                          reportDataByType[
                                                            orderType
                                                          ].totalRoundUpAmount
                                                        }
                                                      </th>

                                                      <th>
                                                        <p className="mb-0">
                                                          (Total Amount)
                                                        </p>
                                                        <br />
                                                        Rs.
                                                        {
                                                          reportDataByType[
                                                            orderType
                                                          ].total
                                                        }
                                                      </th>

                                                      <th>
                                                        <p className="mb-0">
                                                          (Total Paid Amount)
                                                        </p>
                                                        <br />
                                                        Rs.
                                                        {
                                                          reportDataByType[
                                                            orderType
                                                          ].totalCustomerPaidAmount
                                                        }
                                                      </th>
                                                      <th>
                                                        <p className="mb-0">
                                                          (Total Due Amount)
                                                        </p>
                                                        <br />
                                                        Rs.
                                                        {
                                                          reportDataByType[
                                                            orderType
                                                          ].totalCustomerDueAmount
                                                        }
                                                      </th>
                                                    </tr>
                                                  ))} */}
                                                                                                <tr>
                                                                                                    <th colSpan={1}>Total ({data.length && data.length})</th>
                                                                                                    <th colSpan={1}>Total Quantity : ({totalQuantity})</th>

                                                                                                    
                                                                                                    <th>
                                                                                                        <p className="mb-0">
                                                                                                            (Total Subtotal)
                                                                                                        </p>
                                                                                                        <br />
                                                                                                        Rs.
                                                                                                         
                                                                                                        {parseFloat(totalSubAmount).toFixed(2)}
                                                                                                    </th>

                                                                                                    <th>
                                                                                                        <p className="mb-0">
                                                                                                            (Total Discount)
                                                                                                        </p>
                                                                                                        <br />
                                                                                                        Rs.
                                                                                                         
                                                                                                        {parseFloat(totalDiscountAmount).toFixed(2)}
                                                                                                    </th>

                                                                                                    <th>
                                                                                                        <p className="mb-0">
                                                                                                            (Total Service Charge)
                                                                                                        </p>
                                                                                                        <br />
                                                                                                        Rs.
                                                                                                         
                                                                                                        {parseFloat(totalSCHAmount).toFixed(2)}
                                                                                                    </th>

                                                                                                    <th>
                                                                                                        <p className="mb-0">
                                                                                                            (Total Tax)
                                                                                                        </p>
                                                                                                        <br />
                                                                                                        Rs.
                                                                                                         
                                                                                                        {parseFloat(totalTaxAmount).toFixed(2)}
                                                                                                    </th>

                                                                                                    <th>
                                                                                                        <p className="mb-0">
                                                                                                            (Total Amount)
                                                                                                        </p>
                                                                                                        <br />
                                                                                                        Rs.
                                                                                                         
                                                                                                        {parseFloat(totalFinalAmount).toFixed(2)}
                                                                                                    </th>

                                                                                                    
                                                                                                </tr>
                                                                                                {/* <tr>
                                                  <th colSpan={5}>Total ({data.length && data.length})</th>
                                                  <th>
                                                    <p className="mb-0">
                                                      (Subtotal)
                                                    </p>
                                                    <br />
                                                    Rs.
                                                    {reportData.totalSubtotal}
                                                  </th>
                                                  <th>
                                                    <p className="mb-0">
                                                      (Discount)
                                                    </p>
                                                    <br />
                                                    Rs.
                                                    {reportData.totalDiscount}
                                                  </th>
                                                  <th>
                                                    <p className="mb-0">
                                                      (Service Charge)
                                                    </p>
                                                    <br />
                                                    Rs.
                                                    {
                                                      reportData.totalServiceCharge
                                                    }
                                                  </th>
                                                  <th>
                                                    <p className="mb-0">
                                                      (Tax)
                                                    </p>
                                                    <br />
                                                    Rs.
                                                    {reportData.totalTax}
                                                  </th>
                                                  <th>
                                                    <p className="mb-0">
                                                      (Packaging Charges)
                                                    </p>
                                                    <br />
                                                    Rs.
                                                    {reportData.totalPackingCharges}
                                                  </th>

                                                  <th>
                                                    <p className="mb-0">
                                                      (Total Round Off Amount)
                                                    </p>
                                                    <br />
                                                    Rs.
                                                    {reportData.totalRoundUpAmount}
                                                  </th>
                                                  <th>
                                                    <p className="mb-0">
                                                      (Total Amount)
                                                    </p>
                                                    <br />
                                                    Rs.
                                                    {reportData.totalTotal}
                                                  </th>

                                                  <th>
                                                    <p className="mb-0">
                                                      (Total Paid Amount)
                                                    </p>
                                                    <br />
                                                    Rs.
                                                    {reportData.totalCustomerPaidAmount}
                                                  </th>

                                                  <th>
                                                    <p className="mb-0">
                                                      (Total Due Amount)
                                                    </p>
                                                    <br />
                                                    Rs.
                                                    {reportData.totalCustomerDueAmount}
                                                  </th>
                                                </tr> */}
                                                                                            </>
                                                                                        )}
                                                                                    </>
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <LoadingModal isLoading={isLoading} />

                    <Notification />
                </>
            )}
        </>
    );
};

export default ItemsReport;
