import React, { useEffect, useState } from 'react'
import LoadingModal from '../../loader/LoadingModal';
import Notification from "../other/Notification";
import { useToast } from "../../../context/ToastContext";
import { GetOrderDetailsURL, clearTablesURL, getDishForOrderEdit, orderEdit } from '../../assets/api/BaseURL';
import clickSound from "../../sound/button_click.wav";

import axios from 'axios';
import useSound from 'use-sound';
import { Link } from 'react-router-dom';
import {
    ScriptId,
    ScriptSRC,
} from "../../assets/api/BaseURL";
export default function EditOrder() {




    const [token, setToken] = useState("");
    const [orderID, setOrderID] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [planDetails, setPlanDetails] = useState(null);

    const [allMenus, setAllMenus] = useState([]);
    const [allProducts, setAllProducts] = useState([]);
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [orderType, setOrderType] = useState("dine_in");
    const [currencySymbol, setCurrencySymbol] = useState("");
    const [currentMenu, setCurrentMenu] = useState("all");
    const [orderData, setOrderData] = useState({});

    const [cartData, setCartData] = useState([]);

    const [activeTab, setActiveTab] = useState("items");
    const [selectedPaymentOption, setSelectedPaymentOption] = useState("Cash");
    // const [fooderId, setFooderId] = useState("0");

    const [logoutModel, setLogoutModel] = useState(false);
    const [editPassword, setEditPassword] = useState("");
    const [isOpenPassword, setIsOpenPassword] = useState(true);
    const [isOpenSaveBill, setIsOpenSaveBill] = useState(false);




    const [customerForm, setCustomerForm] = useState({
        name: "",
        address: "",
        mobile: "",
        customer_suggestions: "",
        total_people: "1"
    });

    const maxCharacterLimit = 200;

    const { addToast } = useToast();
    const [playSound] = useSound(clickSound, {
        volume: 0.9,
        playbackRate: 1.5,
    });
    async function getData() {

        if (!(token && orderID)) {



        } else if (editPassword.trim() === "") {
            addToast("error", "Enter password");
        }


        else {
            try {
                setIsLoading(true)
                const response = await axios.get(getDishForOrderEdit, {
                    params: { id: orderID, editPassword: editPassword },
                    headers: { authorization: `Bearer ${token}` },
                });

                // console.log(response)
                if (response.status === 200 && response.data && response.data.menu && response.data.product) {
                    const OrderDetailsResponse = await axios.get(GetOrderDetailsURL, {
                        params: { id: orderID, },
                        headers: { authorization: `Bearer ${token}` },
                    });

                    // console.log(OrderDetailsResponse)
                    if (OrderDetailsResponse.data) {

                        setAllMenus(response.data.menu);
                        setAllProducts(response.data.product);
                        if (OrderDetailsResponse.data.data) {
                            setOrderData(OrderDetailsResponse.data.data)
                            setOrderType(OrderDetailsResponse.data.data.order_type)

                            if (OrderDetailsResponse.data.data.service_charge_details?.name) {
                                setSCHName(OrderDetailsResponse.data.data.service_charge_details.name)
                            }
                            if (OrderDetailsResponse.data.data.service_charge_details?.percentage) {
                                setSCHRate(OrderDetailsResponse.data.data.service_charge_details?.percentage)
                            }
                            if (OrderDetailsResponse.data.data.round_up_amount === 0) {
                                setOnlySavePaymentRoundUpCheck(false)
                            } else {
                                setOnlySavePaymentRoundUpCheck(true)
                            }

                            if (OrderDetailsResponse.data.data.discount_rate) {
                                setDiscountRate(OrderDetailsResponse.data.data.discount_rate)
                                if (OrderDetailsResponse.data.data.discount_type === "percent") {
                                    setDiscountType("0")
                                    setDiscountValues({ type: "0", rate: OrderDetailsResponse.data.data.discount_rate })

                                } else {
                                    setDiscountType("1")
                                    setDiscountValues({ type: "1", rate: OrderDetailsResponse.data.data.discount_rate })

                                }

                            }

                            setCustomerForm({
                                name: OrderDetailsResponse.data.data.eater_name ? OrderDetailsResponse.data.data.eater_name : "",
                                mobile: OrderDetailsResponse.data.data.eater_phonenumber ? OrderDetailsResponse.data.data.eater_phonenumber : "",
                                customer_suggestions: OrderDetailsResponse.data.data.eater_suggestions ? OrderDetailsResponse.data.data.eater_suggestions : "",
                                address: OrderDetailsResponse.data.data.address ? OrderDetailsResponse.data.data.address : "",
                                total_people: OrderDetailsResponse.data.data.totalPerson ? OrderDetailsResponse.data.data.totalPerson : "1"
                            })




                            const modifiedDetails = OrderDetailsResponse.data.data.details.map((item) => {
                                const { id, fooder_id, product_kot_id, phone, cookie_basket, last_updated, batchid, creation_date, packaging_fee, item_tax_percent, item_tax_type, product_id, variant_details, addons_items_details, product_name, product_price, product_proprice, ...rest } = item;
                                return {
                                    ...rest,
                                    id: product_id,
                                    name: product_name,
                                    price: product_price,
                                    proprice: "",
                                    newPrice: product_price,
                                    local_id: id,
                                    tax_percent: parseFloat(item_tax_percent),
                                    tax_type: item_tax_type,
                                    min_order_quantity: 1,

                                    isKOT: true,
                                    isSaved: false,
                                    KOT_id: product_kot_id,
                                    KOT_no: "",
                                    KOT_time: "",

                                    withTaxPrice: item_tax_type === 1 || item_tax_type === "1" ? parseFloat(product_price) : parseFloat(product_price) + (parseFloat(product_price) * parseFloat(item_tax_percent) / 100),
                                    withOutTaxPrice: item_tax_type === 0 || item_tax_type === "0" ? parseFloat(product_price) : (parseFloat(product_price) * parseFloat(100)) / (parseFloat(100) + parseFloat(item_tax_percent)),


                                    selectedAddons: JSON.parse(addons_items_details),
                                    selectedvariants: JSON.parse(variant_details),
                                    packaging_charges: packaging_fee,


                                };
                            });

                            setCartData(modifiedDetails)
                            setIsOpenPassword(false)
                            // console.log(modifiedDetails)
                            // console.log("++++++++++++++++++++++++++++++++++++++")



                        }
                    }


                    // setCartData([])
                    setIsLoading(false)

                }


            } catch (error) {
                console.log(error);
                setIsLoading(false)
                if (error.response && error.response.data.message) {
                    addToast("error", error.response.data.message);
                } else {
                    addToast("error", error.message);
                }
            }
        }



    }




    useEffect(() => {
        setToken(localStorage.getItem("token"));
        if (localStorage.getItem("currencySymbol")) {
            setCurrencySymbol(localStorage.getItem("currencySymbol"));
        }

        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        if (urlParams.has("orderID")) {
            const orderID = urlParams.get("orderID");
            if (orderID) {
                setOrderID(orderID);
            }

        }
    }, [token]);

    useEffect(() => {
        if (localStorage.getItem("planDetails")) {
            setPlanDetails(JSON.parse(localStorage.getItem("planDetails")));
        }
    }, []);


    useEffect(() => {
        handleSearchChange("")
    }, [allProducts, currentMenu]);

    useEffect(() => {
        const existingScript = document.getElementById(ScriptId);
        if (!existingScript && localStorage.getItem("userId")) {
            const userId = localStorage.getItem("userId");
            const script = document.createElement("script");
            script.src = ScriptSRC + userId.replace(/"/g, "");
            script.defer = true;
            script.id = ScriptId;
            document.head.appendChild(script);
        }
    }, []);


    const [searchInput, setSearchInput] = useState("");

    const handleSearchChange = (e) => {
        const searchTerm = e.toLowerCase();
        setSearchInput(searchTerm);

        const filtered = allProducts.filter(
            (product) =>
                product.name.toLowerCase().includes(searchTerm) &&
                (product.menu_id === currentMenu || currentMenu === "all") &&

                ((product.dine_in_service && orderType === "dine_in") ||
                    (product.delivery_service && orderType === "delivery") ||
                    (product.pick_up_service && orderType === "take_away"))
        );
        setFilteredProducts(filtered);
    };





    const capitalizeFirstLetter = (str) => {
        if (str) {
            const words = str.split(" ");

            const capitalizedWords = words.map((word) => {
                if (word.length === 0) {
                    return "";
                }

                else
                    if (word[0] === '(' || word[0] === '-') {
                        return word.charAt(0).toUpperCase() + word.charAt(1).toUpperCase() + word.slice(2).toLowerCase();
                    }
                    else {
                        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
                    }
            });

            const capitalizedString = capitalizedWords.join(" ");

            return capitalizedString;
        }
    };


    function isValidPrice(price) {
        const pricePattern = /^\d+(\.\d{1,2})?$/;
        if (!pricePattern.test(price)) {
            return price;
        }
        return price;
    }

    const handleMenuClick = (menuId) => {
        playSound();
        setCurrentMenu(menuId);
    };

    const [selectedProduct, setSelectedProduct] = useState(null);
    const [productNote, setProductNote] = useState("");
    const [isModal, setIsModal] = useState(false);



    const [selectedPrductForAddedVariants, setSelectedPrductForAddedVariants] = useState({});


    const [productVariantAddonsData, setProductVariantAddonsData] = useState([]);
    const [productVariantAttributesData, setProductVariantAttributesData] = useState([]);
    const [productVariantVariantData, setProductVariantVariantData] = useState([]);


    const [selectedProductVariantAttributesData, setSelectedProductVariantAttributesData] = useState([]);
    const [selectedProductVariant, setSelectedProductVariant] = useState({ 'variantId': "0" });
    const [productVariantPropertiesPriceArray, setProductVariantPropertiesPriceArray] = useState([]);
    const [selectedProductVariantAddonsData, setSelectedProductVariantAddonsData] = useState([]);
    const [selectedProductVariantAddonsIdArray, setSelectedProductVariantAddonsIdArray] = useState([]);

    const [selectedProductVariantAddonsPrice, setSelectedProductVariantAddonsPrice] = useState(0);

    useEffect(() => {

        // console.log(selectedProductVariantAddonsPrice)
        let tempSelectedAddonsPrice = 0
        selectedProductVariantAddonsData.forEach((i) => {
            tempSelectedAddonsPrice += parseFloat(i.addon_item_price)
        })
        setSelectedProductVariantAddonsPrice(tempSelectedAddonsPrice)
    }, [selectedProductVariantAddonsData]);

    const handleProductClick = (product) => {

        playSound();


        product.quantity = product.min_order_quantity
        setSelectedProduct(product);
        setProductNote("");
        setIsModal(true);

        setSelectedPrductForAddedVariants(product)


        setSelectedProductVariantAddonsData([])
        setSelectedProductVariantAddonsIdArray([])
        setSelectedProductVariant({ 'variantId': "0" })
        if (product.addons) {
            setProductVariantAddonsData(product.addons)

        } else {
            setProductVariantAddonsData([])
        }

        if (product.variants) {
            setProductVariantVariantData(product.variants)
        } else {
            setProductVariantVariantData([])
        }



        if (product.attributes) {
            let tempAttributeArray = []
            let tempArrayUnick = []

            product.attributes.forEach((i) => {
                let count = 0;

                tempArrayUnick.forEach((j) => {
                    if (i.attributeId === j.attributeId) {
                        count++;
                    }
                })
                if (count == 0) {
                    tempArrayUnick.push({
                        'attributeId': i.attributeId,
                        'attributeName': i.attributeName,
                    });
                }
            });

            tempArrayUnick.forEach((i) => {
                let temp = []
                product.attributes.forEach((j) => {
                    if (i.attributeId === j.attributeId) {
                        temp.push(j);
                    }
                });

                tempAttributeArray.push({
                    'attributeId': i.attributeId,
                    'attributeName': i.attributeName,
                    'value': temp,
                });
            });

            setProductVariantAttributesData(tempAttributeArray)
            // console.log("tempAttributeArray")
            // console.log(tempAttributeArray)
            let tempSelectedProperties = []
            tempAttributeArray.forEach((i) => {
                tempSelectedProperties.push(i.value[0].attributeValueId);
            })

            // console.log(tempSelectedProperties)
            setSelectedProductVariantAttributesData(tempSelectedProperties)
        } else {
            setProductVariantAttributesData([])
        }
        // setShowVariants(true)

    };

    const changeSelectedAddons = (checked, value, min, max, addonId) => {
        // console.log(value, checked)
        playSound();
        value.addonId = addonId
        value.minimum_item = min ? parseInt(min) : 0
        value.maximum_item = parseInt(max)





        let tempSelectedAddons = [...selectedProductVariantAddonsData]
        let tempSelectedAddonsIdArray = [...selectedProductVariantAddonsIdArray]

        // console.log(tempSelectedAddonsIdArray)
        let tempSelectedAddonsLen = 0

        tempSelectedAddons.forEach((i) => {
            if (i.addonId === addonId) {
                tempSelectedAddonsLen++
            }
        })


        if (max <= tempSelectedAddonsLen && checked) {
            // alert("hahaha...")
            addToast("error", "Max addons selected");
        } else {
            if (checked) {
                tempSelectedAddons.push(value)
                tempSelectedAddonsIdArray.push(value.addonItemId)

                setSelectedProductVariantAddonsData(tempSelectedAddons)
                setSelectedProductVariantAddonsIdArray(tempSelectedAddonsIdArray)

            }
            else {

                // console.log("remove value")
                let temp = []
                let tempIdArray = []

                tempSelectedAddons.forEach((i) => {
                    // console.log(i)
                    if (i.addonItemId !== value.addonItemId) {
                        temp.push(i)
                        tempIdArray.push(i.addonItemId)
                    }

                })
                setSelectedProductVariantAddonsData(temp)
                setSelectedProductVariantAddonsIdArray(tempIdArray)




            }
        }




    }


    const changeSelectedVariants = (index, value) => {
        // console.log(value, index)
        playSound();

        const tempSelectedProperties = [...selectedProductVariantAttributesData];

        // console.log("tempSelectedProperties")
        tempSelectedProperties[index] = value
        // console.log(tempSelectedProperties)
        setSelectedProductVariantAttributesData(tempSelectedProperties)
        setSelectedProductVariantAddonsData([])
        setSelectedProductVariantAddonsIdArray([])
        setSelectedProductVariantAddonsPrice(0)

    }

    const updateQuantity = (increment, min_order_quantity) => {
        playSound();
        // alert(min_order_quantity)
        // console.log(selectedProduct)
        if (selectedProduct) {
            const updatedProduct = { ...selectedProduct };
            if (increment) {
                updatedProduct.quantity += 1;
            } else if (updatedProduct.quantity > 1) {
                if (updatedProduct.quantity > min_order_quantity) {
                    updatedProduct.quantity -= 1;
                } else {

                }
            }
            setSelectedProduct(updatedProduct);
        }
    };

    const handleSaveProductNote = (e) => {
        const inputValue = e.target.value;

        if (inputValue.length <= maxCharacterLimit) {
            setProductNote(inputValue);
        }
    };

    const handleAddToOrder = () => {
        playSound();



        let checkMinAddon = false


        productVariantAddonsData.forEach((i) => {
            let tempMin = 0
            selectedProductVariantAddonsData.forEach((j) => {
                if (i.addonId == j.addonId) {
                    tempMin++
                }

            })

            if (i.minimum_item > tempMin) {
                // alert("hahaha...")
                checkMinAddon = true
            }

        })
        if (checkMinAddon) {
            addToast("error", "Please add more addons");

        } else {
            if (selectedProduct) {
                const orderItem = {
                    ...selectedProduct,
                    local_id: new Date().getTime(),
                    newPrice: selectedProduct.proprice
                        ? selectedProduct.proprice
                        : selectedProduct.price,
                    fooder_id: orderData.fooder_id,
                    table_id: orderData.table_id,
                    fooder_name: orderData.fooders_name,
                    product_special_note: productNote,
                    isKOT: false,
                    isSaved: false,
                    KOT_id: "",
                    KOT_id: "",
                    KOT_no: "",
                    KOT_time: "",
                    selectedAddons: selectedProductVariantAddonsData,
                    selectedvariants: selectedProductVariant,
                    newPrice: (parseFloat(selectedProductVariantAddonsPrice) + parseFloat(selectedProductVariant.combination_price)) ?
                        `${(parseFloat(selectedProductVariantAddonsPrice) + parseFloat(selectedProductVariant.combination_price)).toFixed(2)}` :
                        `${selectedPrductForAddedVariants.proprice ? (parseFloat(selectedPrductForAddedVariants.proprice) + parseFloat(selectedProductVariantAddonsPrice)).toFixed(2) : (parseFloat(selectedPrductForAddedVariants.price) + parseFloat(selectedProductVariantAddonsPrice)).toFixed(2)}`,

                    price: (parseFloat(selectedProductVariantAddonsPrice) + parseFloat(selectedProductVariant.combination_price)) ?
                        `${(parseFloat(selectedProductVariantAddonsPrice) + parseFloat(selectedProductVariant.combination_price)).toFixed(2)}` :
                        `${selectedPrductForAddedVariants.proprice ? (parseFloat(selectedPrductForAddedVariants.proprice) + parseFloat(selectedProductVariantAddonsPrice)).toFixed(2) : (parseFloat(selectedPrductForAddedVariants.price) + parseFloat(selectedProductVariantAddonsPrice)).toFixed(2)}`,

                    proprice: ""
                };


                orderItem.withTaxPrice = orderItem.tax_type === 1 ? parseFloat(orderItem.newPrice) : parseFloat(orderItem.newPrice) + (parseFloat(orderItem.newPrice) * parseFloat(orderItem.tax_percent) / 100)
                orderItem.withOutTaxPrice = orderItem.tax_type === 0 ? parseFloat(orderItem.newPrice) : (parseFloat(orderItem.newPrice) * parseFloat(100)) / (parseFloat(100) + parseFloat(orderItem.tax_percent))

                // console.log(orderItem)
                let cartDataTemp = [...cartData]
                cartDataTemp.push(orderItem)

                setCartData(cartDataTemp);


                // console.log(cartData)
                setSelectedProduct(null);
                setProductNote("");
                setIsModal(false);

            }
        }


    }


    const handleQuantityClick = (itemId, id, quantity) => {
        // console.log(itemId, id)
        playSound();
        const orderItem = cartData.find((order) => order.id === itemId && order.local_id === id);
        if (orderItem && orderItem.quantity + quantity > 0) {
            if (orderItem.quantity + quantity >= orderItem.min_order_quantity) {
                const newQuantity = orderItem.quantity + quantity;
                let updatedOrderList = cartData.map((order) => {
                    if (order.id === itemId && order.local_id === id) {
                        return { ...order, quantity: newQuantity };
                    }
                    return order;
                });
                setCartData(updatedOrderList)
            }
        }
    };

    const deleteCartItem = (itemId, id) => {
        playSound();
        let updatedOrderList = cartData.filter((order) => !(order.id === itemId && order.local_id === id));
        setCartData(updatedOrderList)
    };


    function getDateTimeFromUnix(unixTimestamp) {
        // Create a new Date object from the timestamp
        const date = new Date(parseInt(unixTimestamp * 1000));

        // Get date components
        const day = date.getDate();            // Day of the month (1-31)
        const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        const month = monthNames[date.getMonth()];  // Month (0-11, so we use an array for names)
        const year = date.getFullYear();            // Get the year
        let hours = date.getHours(); // Hours (0-23)
        const minutes = date.getMinutes().toString().padStart(2, '0'); // Minutes (0-59)
        const seconds = date.getSeconds().toString().padStart(2, '0'); // Seconds (0-59)

        // Determine AM or PM
        const ampm = hours >= 12 ? 'PM' : 'AM';

        // Convert hours to 12-hour format
        hours = hours % 12;
        hours = hours ? hours : 12; // The hour '0' should be '12'
        hours = hours.toString().padStart(2, '0');

        // Format the date
        const formattedDate = `${day}th ${month} ${year} - ${hours}:${minutes} ${ampm}`;

        return formattedDate;
    }





    const handleApplyDiscountClick = (e) => {
        e.preventDefault();
        if (cartProductSubTotal === 0) {
            addToast("error", "Please add items first to apply discount");
        } else if (!isValidNumber(discountValues.rate)) {
            addToast("error", "Please enter a valid number");
        } else if (
            discountValues.type <= 0 &&
            parseFloat(discountValues.rate) > 100
        ) {
            addToast("error", "Discount rate cannot exceed 100%");
        } else if (
            discountValues.type === "1" &&
            // parseInt(discountValues.rate) > subTotal
            parseInt(discountValues.rate) > cartProductSubTotal

        ) {
            addToast("error", "Discount rate cannot exceed subtotal");
        } else {

            setDiscountType(discountValues.type)
            setDiscountRate(discountValues.rate)
            setActiveTab("items");
            addToast("success", "Discount applied successfully");

        }
    };


    const handleCustomerFormChange = (e) => {
        const { name, value } = e.target;

        setCustomerForm({
            ...customerForm,
            [name]: value,
        });


    };


    const isValidNumber = (value) => {
        return !isNaN(parseFloat(value)) && isFinite(value);
    };


    const handlePaymentOptionChange = (event) => {
        playSound();

        setSelectedPaymentOption(event.target.value);

        // setUpiPaymentDetails({
        //     method: "",
        //     other_method: "",
        //     tip: "0",
        //     note: "",
        //     amount: "",
        //     transaction_id: ""
        // });

    };







    const [cartProductTotal, setCartProductTotal] = useState(0);
    const [cartProductPackingCharges, setCartProductPackingCharges] = useState(0);
    const [cartProductSubTotal, setCartProductSubTotal] = useState(0);
    const [cartProductServiceCharge, setCartProductServiceCharge] = useState(0);
    const [cartProductDiscount, setCartProductDiscount] = useState(0);
    const [cartProductTax, setCartProductTax] = useState(0);

    const [discountType, setDiscountType] = useState("0");
    const [discountRate, setDiscountRate] = useState("0");

    const [discountValues, setDiscountValues] = useState({
        type: "0",
        rate: "0",
    });



    const [SCHName, setSCHName] = useState("SCH");
    const [SCHRate, setSCHRate] = useState(0);

    const [CGSTTaxT1, setCGSTTaxt1] = useState(0);
    const [CGSTTaxT2, setCGSTTaxt2] = useState(0);
    const [CGSTTaxT3, setCGSTTaxt3] = useState(0);
    const [CGSTTaxT4, setCGSTTaxt4] = useState(0);
    const [CGSTTaxT5, setCGSTTaxt5] = useState(0);


    const [SGSTTaxT1, setSGSTTaxt1] = useState(0);
    const [SGSTTaxT2, setSGSTTaxt2] = useState(0);
    const [SGSTTaxT3, setSGSTTaxt3] = useState(0);
    const [SGSTTaxT4, setSGSTTaxt4] = useState(0);
    const [SGSTTaxT5, setSGSTTaxt5] = useState(0);


    useEffect(() => {




        setCGSTTaxt1(0);
        setCGSTTaxt2(0);
        setCGSTTaxt3(0);
        setCGSTTaxt4(0);
        setCGSTTaxt5(0);


        setSGSTTaxt1(0);
        setSGSTTaxt2(0);
        setSGSTTaxt3(0);
        setSGSTTaxt4(0);
        setSGSTTaxt5(0);
        try {

            let subTotal = 0;
            let tempDiscount = 0;
            let tempDiscountRow = 0;

            let tempServicCharge = 0;
            let tempServicChargeRow = 0;

            let tempTax = 0;
            let tempTaxRow = 0;

            let GSTTaxT1 = 0;
            let GSTTaxT2 = 0;
            let GSTTaxT3 = 0;
            let GSTTaxT4 = 0;
            let GSTTaxT5 = 0;



            let tempCartLen = 0;
            let billDiscountRate = discountRate;

            let packingCharges = 0;




            let withOutTaxPriceForAmount = 0
            let subTotalForAmount = 0;
            let discountRateForAmount = 0;

            if (discountType === "1") {
                cartData.forEach((i) => {

                    subTotalForAmount += (i.quantity) * i.withOutTaxPrice

                });
                discountRateForAmount = (parseFloat(billDiscountRate) * 100) / subTotalForAmount
            }

            // end

            cartData.forEach((i) => {



                packingCharges += i.quantity * parseFloat(i.packaging_charges) || 0
                tempCartLen += parseInt(i.quantity)
                // console.log("tempCartLen")

                // console.log(tempCartLen)

                tempDiscountRow = 0;


                subTotal += (i.quantity) * i.withOutTaxPrice

                // console.log(subTotal)

                if (discountType === "0") {
                    tempDiscount += (((i.quantity) * i.withOutTaxPrice) * billDiscountRate) / 100
                    tempDiscountRow = (((i.quantity) * i.withOutTaxPrice) * billDiscountRate) / 100
                } else {

                    tempDiscount += (((i.quantity) * i.withOutTaxPrice) * discountRateForAmount) / 100
                    tempDiscountRow = (((i.quantity) * i.withOutTaxPrice) * discountRateForAmount) / 100

                }





                tempServicCharge += ((((i.quantity) * i.withOutTaxPrice) - tempDiscountRow) * parseFloat(SCHRate)) / 100
                tempServicChargeRow = ((((i.quantity) * i.withOutTaxPrice) - tempDiscountRow) * parseFloat(SCHRate)) / 100



                tempTax += ((((i.quantity) * i.withOutTaxPrice) + tempServicChargeRow - tempDiscountRow) * parseFloat(i.tax_percent)) / 100
                tempTaxRow = ((((i.quantity) * i.withOutTaxPrice) + tempServicChargeRow - tempDiscountRow) * parseFloat(i.tax_percent)) / 100


                if (i.tax_percent === 0) {

                    GSTTaxT1 += ((((i.quantity) * i.withOutTaxPrice) + tempServicChargeRow - tempDiscountRow) * parseFloat(i.tax_percent)) / 100
                    setCGSTTaxt1(GSTTaxT1 / 2)
                    setSGSTTaxt1(GSTTaxT1 / 2)

                }

                if (i.tax_percent === 5) {

                    GSTTaxT2 += ((((i.quantity) * i.withOutTaxPrice) + tempServicChargeRow - tempDiscountRow) * parseFloat(i.tax_percent)) / 100

                    setCGSTTaxt2((GSTTaxT2 / 2).toFixed(2))
                    setSGSTTaxt2((GSTTaxT2 / 2).toFixed(2))
                }

                if (i.tax_percent === 12) {

                    GSTTaxT3 += ((((i.quantity) * i.withOutTaxPrice) + tempServicChargeRow - tempDiscountRow) * parseFloat(i.tax_percent)) / 100

                    setCGSTTaxt3((GSTTaxT3 / 2).toFixed(2))
                    setSGSTTaxt3((GSTTaxT3 / 2).toFixed(2))
                }

                if (i.tax_percent === 18) {

                    GSTTaxT4 += ((((i.quantity) * i.withOutTaxPrice) + tempServicChargeRow - tempDiscountRow) * parseFloat(i.tax_percent)) / 100
                    setCGSTTaxt4((GSTTaxT4 / 2).toFixed(2))
                    setSGSTTaxt4((GSTTaxT4 / 2).toFixed(2))
                }

                if (i.tax_percent === 28) {

                    GSTTaxT5 += ((((i.quantity) * i.withOutTaxPrice) + tempServicChargeRow - tempDiscountRow) * parseFloat(i.tax_percent)) / 100
                    setCGSTTaxt5((GSTTaxT5 / 2).toFixed(2))
                    setSGSTTaxt5((GSTTaxT5 / 2).toFixed(2))
                }



            })


            setCartProductSubTotal(subTotal.toFixed(2));

            // console.log(subTotal.toFixed(2))
            // console.log(".......................")


            setCartProductServiceCharge(tempServicCharge.toFixed(2))
            setCartProductDiscount(tempDiscount.toFixed(2))
            setCartProductTax(tempTax.toFixed(2))
            setCartProductTotal((subTotal + tempServicCharge - tempDiscount + tempTax).toFixed(2));


            if (orderType !== "dine_in") {
                setCartProductPackingCharges(packingCharges.toFixed(2))
                setCartProductTotal((parseFloat(subTotal + tempServicCharge - tempDiscount + tempTax) + parseFloat(packingCharges)).toFixed(2));

                // alert('dd')
            } else {
                setCartProductPackingCharges(0)
                setCartProductTotal((subTotal + tempServicCharge - tempDiscount + tempTax).toFixed(2))
            }


        }
        catch (e) {
            console.log(e)
        }

    }, [cartData, discountRate, discountType]);

    useEffect(() => {

        if (discountType === '1') {
            setDiscountRate("0")
            setDiscountValues({ type: "0", rate: "0", })

        }


    }, [cartData]);

    useEffect(() => {

        if (selectedProductVariantAttributesData.length > 0) {

            productVariantVariantData.forEach((i) => {

                let counter = 0

                i.combination_details.forEach((j, jCount) => {
                    if (j.attribute_value_id == selectedProductVariantAttributesData[jCount]) {
                        counter++;
                    }

                })
                if (counter == selectedProductVariantAttributesData.length) {
                    // console.log("variant finded")
                    // console.log(i)
                    setSelectedProductVariant(i)

                }

            })

            let tempPropertiesPriceArray = []


            productVariantVariantData.forEach((i) => {

                if (i.combination_details[0].attribute_value_id == selectedProductVariantAttributesData[0]) {
                    tempPropertiesPriceArray.push(i.combination_price)
                    // console.log(i.combination_price)
                }
            })
            setProductVariantPropertiesPriceArray(tempPropertiesPriceArray)



            if (productVariantVariantData.length === productVariantAttributesData[0].value.length) {
                tempPropertiesPriceArray = []
                productVariantVariantData.forEach((i) => {

                    tempPropertiesPriceArray.push(i.combination_price)

                })
                setProductVariantPropertiesPriceArray(tempPropertiesPriceArray)
                // console.log("tempPropertiesPriceArray")
                // console.log(tempPropertiesPriceArray)
            }
        }


    }, [selectedProductVariantAttributesData]);

    const [onlySavePaymentRoundUpAmount, setOnlySavePaymentRoundUpAmount] = useState(0);
    const [onlySavePaymentRoundUpCheck, setOnlySavePaymentRoundUpCheck] = useState(false);






    const [onlySavePaymentTotalPaidedAmount, setOnlySavePaymentTotalPaidedAmount] = useState(0);
    const [onlySavePaymentPaidedAmount, setOnlySavePaymentPaidedAmount] = useState(0);
    const [onlySavePaymentTipAmount, setOnlySavePaymentTipAmount] = useState(0);
    const [onlySavePaymentTipTotalAmount, setOnlySavePaymentTipTotalAmount] = useState(0);
    const [onlySavePaymentData, setSavePaymentData] = useState([]);
    const [onlySavePaymentNotes, setOnlySavePaymentNotes] = useState("");

    const [upiPaymentDetails, setUpiPaymentDetails] = useState({
        method: "",
        other_method: "",
        tip: "0",
        note: "",
        amount: "",
        transaction_id: ""
    });
    const [paymentSettleTransactionId, setPaymentSettleTransactionId] = useState("")



    const handleOnlySavePayment = async (e) => {
        playSound();
        let tempOnlySavePaymentRoundUpAmount = onlySavePaymentRoundUpAmount
        if (selectedPaymentOption === "UPI" && upiPaymentDetails.method === "") {
            addToast("error", "Select upi method");
        }
        else if (!isValidNumber(onlySavePaymentPaidedAmount)) {
            addToast("error", "Please enter valid Paid amount")
        }
        else if (!isValidNumber(onlySavePaymentTipAmount) && onlySavePaymentTipAmount !== '') {
            addToast("error", "Please enter valid Tip amount");
        }
        else if ((parseFloat(onlySavePaymentPaidedAmount)).toFixed(2) > parseFloat(cartProductTotal - onlySavePaymentTotalPaidedAmount) + (tempOnlySavePaymentRoundUpAmount) + 0.00001) {
            addToast("error", "Amount cannot be greater than Total Amount");
        }
        else {
            let tempAmount = parseFloat(onlySavePaymentPaidedAmount).toFixed(2)

            setSavePaymentData([...onlySavePaymentData, {
                method: selectedPaymentOption,
                amount: tempAmount,
                tip: onlySavePaymentTipAmount ? parseFloat(onlySavePaymentTipAmount).toFixed(2) : 0,
                upiType: upiPaymentDetails.method === "Other" ? upiPaymentDetails.other_method : upiPaymentDetails.method,
                note: onlySavePaymentNotes,
                transaction_id: paymentSettleTransactionId
            }])
            let temp = parseFloat(onlySavePaymentTotalPaidedAmount) + parseFloat(onlySavePaymentPaidedAmount)


            setOnlySavePaymentTotalPaidedAmount(temp)

            // setOnlySavePaymentPaidedAmount(totalAmountPaid - temp > 0 ? (totalAmountPaid - temp).value.toFixed(2) : 0)
            setOnlySavePaymentPaidedAmount(cartProductTotal - temp > 0 ? parseFloat((cartProductTotal - temp) + tempOnlySavePaymentRoundUpAmount).toFixed(2) : 0)


            let tempTip = onlySavePaymentTipAmount + onlySavePaymentTipTotalAmount
            setOnlySavePaymentTipTotalAmount(tempTip)
            setOnlySavePaymentTipAmount(0)
            setOnlySavePaymentNotes("")
            setPaymentSettleTransactionId("")


        }





        // console.log(onlySavePaymentData)


    }

    const handleUPIPaymentDetailsChange = (e) => {
        const { name, value } = e.target;

        if (name === "method") {
            setUpiPaymentDetails({
                ...upiPaymentDetails,
                [name]: value,
                other_method: "",
            });
        } else {
            setUpiPaymentDetails({
                ...upiPaymentDetails,
                [name]: value,
            });
        }
    };


    const handleOnlySavePaymentRemove = async (i, index) => {
        playSound();
        let tempArray = onlySavePaymentData.filter((item, index) => index === i);
        setOnlySavePaymentPaidedAmount((parseFloat(cartProductTotal) - parseFloat(onlySavePaymentTotalPaidedAmount) + parseFloat(tempArray[0].amount) + onlySavePaymentRoundUpAmount).toFixed(2))

        let temp = parseFloat(onlySavePaymentTotalPaidedAmount) - parseFloat(tempArray[0].amount)
        setOnlySavePaymentTotalPaidedAmount(temp)
        let myArray = onlySavePaymentData.filter((item, index) => index !== i);
        setSavePaymentData(myArray)

    }
    function getDecimalPart(number) {
        // Extract the integer part using Math.floor() and subtract it from the original number
        const integerPart = Math.floor(number);
        let decimalPart = number - integerPart;
        // decimalPart = decimalPart.toFixed(2)
        if (decimalPart < 0.5) {
            return -decimalPart
        }
        else {
            return 1 - decimalPart
        }
    }


    useEffect(() => {

        if (onlySavePaymentRoundUpCheck) {
            setOnlySavePaymentPaidedAmount((parseFloat(cartProductTotal) + parseFloat(getDecimalPart(cartProductTotal))).toFixed(2))
        } else {
            setOnlySavePaymentPaidedAmount(parseFloat(cartProductTotal).toFixed(2))
        }
        setSavePaymentData([]);
        setOnlySavePaymentTotalPaidedAmount(0)
        setOnlySavePaymentTipAmount(0)
        setOnlySavePaymentNotes("")
        setOnlySavePaymentTipTotalAmount(0)
        if (onlySavePaymentRoundUpCheck) {
            setOnlySavePaymentRoundUpAmount(getDecimalPart(cartProductTotal))
        }




    }, [cartProductTotal]);

    const clickOrderSaveButton = async (showModel) => {
        playSound();

        if (!cartData.length > 0) {
            addToast("error", "Please add items first")
        }
        else if (!onlySavePaymentData.length > 0) {
            addToast("error", "Please mark payment")
        } else if (showModel) {
            setIsOpenSaveBill(true)

        }

        else {
            setIsLoading(true)

            var tax_details = [];
            if (CGSTTaxT1 > 0) {
                tax_details.push({ "name": "CGST", "percentage": 0, "amount": CGSTTaxT1 });
            }
            if (SGSTTaxT1 > 0) {
                tax_details.push({ "name": "SGST", "percentage": 0, "amount": SGSTTaxT1 });
            }
            if (CGSTTaxT2 > 0) {
                tax_details.push({ "name": "CGST", "percentage": 2.5, "amount": CGSTTaxT2 });
            }
            if (SGSTTaxT2 > 0) {
                tax_details.push({ "name": "SGST", "percentage": 2.5, "amount": SGSTTaxT2 });
            }
            if (CGSTTaxT3 > 0) {
                tax_details.push({ "name": "CGST", "percentage": 6, "amount": CGSTTaxT3 });
            }
            if (SGSTTaxT3 > 0) {
                tax_details.push({ "name": "SGST", "percentage": 6, "amount": SGSTTaxT3 });
            }
            if (CGSTTaxT4 > 0) {
                tax_details.push({ "name": "CGST", "percentage": 9, "amount": CGSTTaxT4 });
            }
            if (SGSTTaxT4 > 0) {
                tax_details.push({ "name": "SGST", "percentage": 9, "amount": SGSTTaxT4 });
            }
            if (CGSTTaxT5 > 0) {
                tax_details.push({ "name": "CGST", "percentage": 14, "amount": CGSTTaxT5 });
            }
            if (SGSTTaxT5 > 0) {
                tax_details.push({ "name": "SGST", "percentage": 14, "amount": CGSTTaxT5 });
            }

            const modifiedDetails = cartData.map((item) => {
                const { newPrice, id, name, price, proprice, packaging_charges, ...rest } = item;
                return {
                    ...rest,
                    product_id: id,
                    product_name: name,
                    product_price: newPrice,
                    product_proprice: "",
                    packaging_charges: orderType === "dine_in" ? 0 : packaging_charges,

                };
            });
            console.log(reverseObject(modifiedDetails))
            console.log((modifiedDetails))



            const dataToSend = {
                order_id: orderID,
                order_type: orderType,
                table_id: orderData.table_id,
                creation_date: orderData.creation_date,
                subtotal: cartProductSubTotal,
                service_charge: parseFloat(cartProductServiceCharge),
                tax_amount: cartProductTax,
                total: parseFloat(cartProductTotal),
                discount_type: discountType,
                discount_rate: discountRate ? discountRate : "0",
                details: modifiedDetails,
                service_charge_details: { "name": SCHName, "percentage": SCHRate, "amount": cartProductServiceCharge },
                tax_details: tax_details,
                payment_details: onlySavePaymentData,
                due_amount: cartProductTotal - onlySavePaymentTotalPaidedAmount,
                paided_amount: onlySavePaymentTotalPaidedAmount,
                payment_status: parseFloat(cartProductTotal - onlySavePaymentTotalPaidedAmount + onlySavePaymentRoundUpAmount).toFixed(2) > 0 ? 3 : 1,
                cart_packing_charges: orderType !== "DINE IN" ? cartProductPackingCharges : 0,
                round_up_amount: onlySavePaymentRoundUpAmount,
                eater_detail: customerForm,


            };

            console.log(dataToSend)

            try {

                const response = await axios({
                    method: "put",
                    url: orderEdit,
                    data: dataToSend,
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bearer ${token}`,
                    },
                });
                // console.log(response)
                if (response.status === 200) {
                    addToast("success", response.data.message);
                    setIsOpenSaveBill(false)
                    setIsOpenPassword(true)
                    setEditPassword("")
                    setCartData([])
                    setActiveTab("items")
                    setAllMenus([])
                    setAllProducts([])
                    setOrderData({})

                    setSelectedPaymentOption("Cash")
                    setCurrentMenu("all")
                    setOrderType("dine_in")
                    setSearchInput("")
                }
                setIsLoading(false)

            } catch (error) {
                if (error.response && error.response.data.message) {
                    addToast("error", error.response.data.message);
                } else {
                    addToast("error", error.message);
                }
                console.log(error);
                setIsLoading(false)

            }
        }

    }

    function reverseObject(obj) {
        const reversed = [];
        const keys = Object.keys(obj).reverse();

        keys.forEach(key => {
            reversed[key] = obj[key];
        });

        return reversed;
    }

    const handleLogout = async (tableClear) => {
        // e.preventDefault();


        localStorage.removeItem("token");
        localStorage.removeItem("planDetails");
        localStorage.removeItem("userId");
        localStorage.removeItem("currencySymbol");
        if (tableClear) {
            console.log(token)
            try {
                const response = await axios({
                    method: "put",
                    url: clearTablesURL,
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bearer ${token}`,
                    },
                });
            } catch (error) {
            }
            localStorage.clear()
        }
        const existingScript = document.getElementById(ScriptId);
        if (existingScript) {
            document.head.removeChild(existingScript);
        }
        // navigate("/");
        window.location.href = "/";
    };


    return (
        <div>
            <div>
                <div className="main-panel layout">
                    <div className="content-wrapper contentdiv">
                        <div className="row">
                            <div className="col-lg-9 d-flex flex-column leftdiv">
                                <div className="headernav">
                                    <div className="row">
                                        <div className="col-lg-1">
                                            <span
                                                className="navclck"
                                                href="#offcanvas-usage"
                                                data-bs-toggle="offcanvas"
                                                data-bs-target="#offcanvasExample"
                                                aria-controls="offcanvasExample"
                                            >
                                                <i className="fa fa-navicon text-black" />
                                                &nbsp;
                                            </span>
                                            &nbsp;
                                        </div>
                                        <div className="col-lg-5 text-left">
                                            <h4 className="welcome-text">
                                                Hello,{" "}
                                                <span className="text-black fw-bold">
                                                    {orderData.fooders_name}
                                                </span>
                                            </h4>
                                        </div>

                                    </div>
                                </div>
                                <div className="row flex-grow">
                                    <div className="col-lg-3 d-flex flex-column">
                                        <div className="sidemnuovr ps-4 mt-4 menunm">
                                            <div
                                                className="card card-rounded grid-margin"
                                                style={{ cursor: "pointer" }}
                                                onClick={() => handleMenuClick("all")}
                                            >
                                                <div className={
                                                    currentMenu === "all"
                                                        ? "card-body currentcrd"
                                                        : "card-body"
                                                }>
                                                    <span className="fw-bold">All Menu</span>
                                                    <br />
                                                    <p className="itemno">{allProducts.length} Items</p>
                                                </div>


                                            </div>
                                            {allMenus.map((menu) => (
                                                <div
                                                    className="card card-rounded grid-margin"
                                                    style={{ cursor: "pointer" }}
                                                    key={menu.menu_id}
                                                    onClick={() =>
                                                        handleMenuClick(menu.menu_id)
                                                    }
                                                >
                                                    <div className={
                                                        currentMenu === menu.menu_id
                                                            ? "card-body currentcrd"
                                                            : "card-body"
                                                    }>
                                                        <span className="fw-bold">{menu.menu_name}</span>
                                                        <br />
                                                        <p className="itemno">{menu.product_count} Items</p>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <div className="col-lg-9 d-flex flex-column">
                                        <div className="filter_scrl mt-4">
                                            <div className="row filtercrd stretch-card mt-2">
                                                <div className="col-lg-12 mb-2">
                                                    <form className="search-form searchcss" action="#">
                                                        <div className="input-group">
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="Search Here"
                                                                defaultValue=""
                                                                onChange={(e) => { handleSearchChange(e.target.value) }}
                                                                value={searchInput}
                                                            />
                                                            {searchInput ?
                                                                <span onClick={() => { playSound(); handleSearchChange('') }} className="input-group-text searchicon custom-cursor-pointer">
                                                                    <i className="fa fa-close"></i>
                                                                    &nbsp;
                                                                </span> :
                                                                <span className="input-group-text searchicon">
                                                                    <i className="fa fa-search"></i>
                                                                    &nbsp;
                                                                </span>
                                                            }
                                                        </div>
                                                    </form>
                                                </div>



                                                {filteredProducts.map((product) => (
                                                    <div
                                                        key={product.id}
                                                        className="col-lg-3 stretch-card"
                                                        style={{ cursor: "pointer" }}
                                                    >
                                                        <div
                                                            className="card card-rounded grid-margin"
                                                            onClick={() =>
                                                                handleProductClick(product)
                                                            }
                                                        >
                                                            <div className="card-body itemsize">
                                                                <div className="">
                                                                    <span className="name">
                                                                        {product.name.length > 30
                                                                            ? capitalizeFirstLetter(
                                                                                product.name.substring(
                                                                                    0,
                                                                                    30
                                                                                ) + "..."
                                                                            )
                                                                            : capitalizeFirstLetter(
                                                                                product.name
                                                                            )}

                                                                    </span>
                                                                    <div className="row pricepos">
                                                                        <div className="col-lg-6">
                                                                            <p className="price">
                                                                                {currencySymbol}{" "}
                                                                                {product.proprice
                                                                                    ? isValidPrice(
                                                                                        product.proprice
                                                                                    )
                                                                                    : isValidPrice(
                                                                                        product.price
                                                                                    )}
                                                                            </p>
                                                                        </div>
                                                                        <div className="col-lg-6 text-right valign">
                                                                            {product.product_type ===
                                                                                0 && (
                                                                                    <img
                                                                                        src="./images/veg.jpeg"
                                                                                        className="nveg"
                                                                                        alt="veg"
                                                                                    />
                                                                                )}
                                                                            {product.product_type ===
                                                                                1 && (
                                                                                    <img
                                                                                        src="./images/non-veg.jpeg"
                                                                                        className="nveg"
                                                                                        alt="nonveg"
                                                                                    />
                                                                                )}
                                                                            {product.product_type ===
                                                                                2 && (
                                                                                    <img
                                                                                        src="./images/egg.png"
                                                                                        className="nveg"
                                                                                        alt="egg"
                                                                                    />
                                                                                )}
                                                                            {product.product_type ===
                                                                                3 && (
                                                                                    <img
                                                                                        src="./images/alcohol.png"
                                                                                        className="nveg"
                                                                                        alt="egg"
                                                                                    />
                                                                                )}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 billcrd rightdiv">
                                <div className="address">
                                    <div className="mt-3 d-sm-flex justify-content-between">
                                        <div className="">
                                            <h4 className="me-2 fw-bold">{orderType === "dine_in" ? "DINE IN" : orderType === "delivery" ? "DELIVERY" : "PICK UP"}</h4>
                                            <p className="time">Order No - {orderData.order_number} (#{orderData.order_number_qrcode})</p>
                                            <p className="time"> {orderData.creation_date ? getDateTimeFromUnix(orderData.creation_date) : ""}</p>
                                        </div>
                                        <div
                                            className="me-2 tblalign valign"
                                            style={{ cursor: "pointer" }}
                                        >
                                            <span className="btntab groundtxt">{orderData.table_no}</span><br />
                                            <span className="btntab groundtxt">{orderData.order_mode_label}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="morebtn">
                                    <div className="col">
                                        <span onClick={() => { playSound(); setActiveTab("items") }} className={activeTab === "items" ? "btntab active" : "btntab"}>
                                            <i className="fa fa-cutlery" />
                                            <br />
                                            <span className={activeTab === "items" ? "actnm" : "actnm1"} >Items</span>
                                        </span>
                                    </div>
                                    <div className="col">
                                        <span onClick={() => { playSound(); cartData.length > 0 ? setActiveTab("details") : addToast("error", "Please add items first") }} className={activeTab === "details" ? "btntab active" : "btntab"}>
                                            <i className="fa fa-user" />
                                            <br />
                                            <span className={activeTab === "details" ? "actnm" : "actnm1"}>Details</span>
                                        </span>
                                    </div>
                                    <div className="col">
                                        <span onClick={() => { playSound(); cartData.length > 0 ? setActiveTab("discount") : addToast("error", "Please add items first") }} className={activeTab === "discount" ? "btntab active" : "btntab"}>
                                            <i className="fa fa-percent" />
                                            <br />
                                            <span className={activeTab === "discount" ? "actnm" : "actnm1"}>Discount</span>
                                        </span>
                                    </div>
                                    <div className="col">
                                        <span className={activeTab === "payment" ? "btntab active" : "btntab"} onClick={() => { playSound(); cartData.length > 0 ? setActiveTab("payment") : addToast("error", "Please add items first") }}>
                                            <i className="fa fa-check-square-o" />
                                            <br />
                                            <span className={activeTab === "payment" ? "actnm" : "actnm1"}>Mark Paid</span>
                                        </span>
                                    </div>
                                </div>
                                {cartData.length > 0 && activeTab === "items" ?
                                    <div className="showdineintbl">

                                        <div className="tablediv">
                                            <div>
                                                {/* <div className="itemdiv">
                                                    <div className="row">
                                                        <div className="col-lg-12">
                                                            <strong className="text-danger">
                                                                Click on SAVE KOT / PRINT KOT to generate KOT
                                                            </strong>
                                                        </div>
                                                    </div>
                                                </div> */}

                                                {cartData.map((item, index) => (

                                                    <div className="itemdiv" key={index}>
                                                        <div className="row">
                                                            <div className="col-4">
                                                                <p className="itemnm">
                                                                    {item.name.length > 30
                                                                        ? capitalizeFirstLetter(
                                                                            item.name.substring(
                                                                                0,
                                                                                30
                                                                            ) + "..."
                                                                        )
                                                                        : capitalizeFirstLetter(
                                                                            item.name
                                                                        )}

                                                                    {item.product_special_note && (
                                                                        <span
                                                                            className="mx-2"
                                                                            data-title={
                                                                                item.product_special_note
                                                                            }
                                                                            style={{ cursor: "pointer" }}
                                                                        >
                                                                            <i className="fa fa-info-circle"></i>
                                                                        </span>
                                                                    )}
                                                                </p>
                                                                {item.selectedvariants && <>
                                                                    {item.selectedvariants.combination_details && item.selectedvariants.combination_details.map((i) => (
                                                                        <><b>{i.attribute_name}: </b> {i.attribute_value_name} <br /></>
                                                                    ))}
                                                                </>}

                                                                {item.selectedAddons && item.selectedAddons.map((i, no) => (
                                                                    <><b>{no + 1}.</b> {i.addon_item_name} <br /></>
                                                                ))}
                                                            </div>
                                                            <div className="col-4">
                                                                <div className="">
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-outline-secondary btn-icon ctlbtn"
                                                                        onClick={() => { handleQuantityClick(item.id, item.local_id, -1) }}
                                                                        style={{ height: "30px" }}
                                                                    >
                                                                        <i className="fa fa-minus" />
                                                                    </button>
                                                                    &nbsp;
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-outline-secondary btn-icon ctlbtn"
                                                                        style={{ height: "30px", width: "30px" }}
                                                                    >
                                                                        {item.quantity}
                                                                    </button>
                                                                    &nbsp;
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-outline-secondary btn-icon ctlbtn"
                                                                        onClick={() => { handleQuantityClick(item.id, item.local_id, 1) }}
                                                                        style={{ height: "30px" }}
                                                                    >
                                                                        <i className="fa fa-plus" />
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            <div className="col-3">
                                                                <span className="">   {isValidPrice(
                                                                    item.quantity * item.newPrice
                                                                ).toFixed(2)}</span>
                                                            </div>
                                                            <div className="col-1">
                                                                <span style={{ cursor: "pointer" }}
                                                                    onClick={() => { deleteCartItem(item.id, item.local_id) }}

                                                                >
                                                                    <i
                                                                        className="fa fa-window-close closeicon"
                                                                        aria-hidden="true"
                                                                    />
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                ))}


                                            </div>
                                        </div>
                                        <div className="footerbtn">
                                            <div className="totaldiv wrapper d-flex align-items-center justify-content-between">
                                                <div className="d-flex">
                                                    <div className="wrapper">
                                                        <span className="totalwrd">
                                                            Items({cartData.length})
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="">
                                                    <span>
                                                        {/* {currencySymbol} {cartProductSubTotal.toFixed(2)} */}
                                                        {currencySymbol} {cartProductSubTotal}

                                                    </span>
                                                </div>
                                            </div>

                                            {discountRate &&
                                                discountRate !== "0" &&
                                                discountType === "0" && (
                                                    <div className="totaldiv wrapper d-flex align-items-center justify-content-between">
                                                        <div className="d-flex">
                                                            <div className="wrapper">
                                                                <span className="totalwrd">
                                                                    Discount ({discountRate}%)
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="">
                                                            <span>
                                                                {currencySymbol}
                                                                {cartProductDiscount}
                                                            </span>
                                                        </div>
                                                    </div>
                                                )}

                                            {discountRate &&
                                                discountRate !== "0" &&
                                                discountType === "1" && (
                                                    <div className="totaldiv wrapper d-flex align-items-center justify-content-between">
                                                        <div className="d-flex">
                                                            <div className="wrapper">
                                                                <span className="totalwrd">
                                                                    Discount ({currencySymbol})
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="">
                                                            <span>
                                                                {currencySymbol}
                                                                {cartProductDiscount}
                                                            </span>
                                                        </div>
                                                    </div>
                                                )}
                                            {SCHRate !== 0 && (
                                                <div className="totaldiv wrapper d-flex align-items-center justify-content-between">
                                                    <div className="d-flex">
                                                        <div className="wrapper">
                                                            <span className="totalwrd">
                                                                {SCHName}(
                                                                {SCHRate}
                                                                %)
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="">
                                                        <span>
                                                            {currencySymbol}
                                                            {cartProductServiceCharge}
                                                        </span>
                                                    </div>
                                                </div>
                                            )}


                                            {CGSTTaxT2 ? <div className="totaldiv wrapper d-flex align-items-center justify-content-between">
                                                <div className="d-flex">
                                                    <div className="wrapper">
                                                        <span className="totalwrd">
                                                            CGST(2.5%)
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="">
                                                    <span>
                                                        {currencySymbol}
                                                        {CGSTTaxT2}
                                                    </span>
                                                </div>
                                            </div> : <></>}

                                            {SGSTTaxT2 ? <div className="totaldiv wrapper d-flex align-items-center justify-content-between">
                                                <div className="d-flex">
                                                    <div className="wrapper">
                                                        <span className="totalwrd">
                                                            SGST(2.5%)
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="">
                                                    <span>
                                                        {currencySymbol}
                                                        {SGSTTaxT2}
                                                    </span>
                                                </div>
                                            </div> : <></>}


                                            {CGSTTaxT3 ? <div className="totaldiv wrapper d-flex align-items-center justify-content-between">
                                                <div className="d-flex">
                                                    <div className="wrapper">
                                                        <span className="totalwrd">
                                                            CGST(6%)
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="">
                                                    <span>
                                                        {currencySymbol}
                                                        {CGSTTaxT3}
                                                    </span>
                                                </div>
                                            </div> : <></>}

                                            {SGSTTaxT3 ? <div className="totaldiv wrapper d-flex align-items-center justify-content-between">
                                                <div className="d-flex">
                                                    <div className="wrapper">
                                                        <span className="totalwrd">
                                                            SGST(6%)
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="">
                                                    <span>
                                                        {currencySymbol}
                                                        {SGSTTaxT3}
                                                    </span>
                                                </div>
                                            </div> : <></>}


                                            {CGSTTaxT4 ? <div className="totaldiv wrapper d-flex align-items-center justify-content-between">
                                                <div className="d-flex">
                                                    <div className="wrapper">
                                                        <span className="totalwrd">
                                                            CGST(9%)
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="">
                                                    <span>
                                                        {currencySymbol}
                                                        {CGSTTaxT4}
                                                    </span>
                                                </div>
                                            </div> : <></>}

                                            {SGSTTaxT4 ? <div className="totaldiv wrapper d-flex align-items-center justify-content-between">
                                                <div className="d-flex">
                                                    <div className="wrapper">
                                                        <span className="totalwrd">
                                                            SGST(9%)
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="">
                                                    <span>
                                                        {currencySymbol}
                                                        {SGSTTaxT4}
                                                    </span>
                                                </div>
                                            </div> : <></>}

                                            {CGSTTaxT5 ? <div className="totaldiv wrapper d-flex align-items-center justify-content-between">
                                                <div className="d-flex">
                                                    <div className="wrapper">
                                                        <span className="totalwrd">
                                                            CGST(14%)
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="">
                                                    <span>
                                                        {currencySymbol}
                                                        {CGSTTaxT5}
                                                    </span>
                                                </div>
                                            </div> : <></>}

                                            {SGSTTaxT5 ? <div className="totaldiv wrapper d-flex align-items-center justify-content-between">
                                                <div className="d-flex">
                                                    <div className="wrapper">
                                                        <span className="totalwrd">
                                                            SGST(14%)
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="">
                                                    <span>
                                                        {currencySymbol}
                                                        {SGSTTaxT5}
                                                    </span>
                                                </div>
                                            </div> : <></>}

                                            {cartProductPackingCharges !== 0 && orderType !== "dine_in" ? <div className="totaldiv wrapper d-flex align-items-center justify-content-between">
                                                <div className="d-flex">
                                                    <div className="wrapper">
                                                        <span className="totalwrd">
                                                            Packaging Charges
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="">
                                                    <span>
                                                        {currencySymbol}
                                                        {cartProductPackingCharges}
                                                    </span>
                                                </div>
                                            </div> : <></>}


                                            {onlySavePaymentRoundUpAmount !== 0 ?
                                                <div className="totaldiv wrapper d-flex align-items-center justify-content-between mb-2">
                                                    <div className="d-flex">
                                                        <div className="wrapper">
                                                            <span className="totalwrd">Round off</span>
                                                        </div>
                                                    </div>
                                                    <div className="">
                                                        {currencySymbol}
                                                        <span className="totalwrd">  {parseFloat(onlySavePaymentRoundUpAmount).toFixed(2)}</span>
                                                    </div>
                                                </div> : <></>}


                                            <div className="totaldiv wrapper d-flex align-items-center justify-content-between mb-2">
                                                <div className="d-flex">
                                                    <div className="wrapper">
                                                        <h4 className="fw-bold mb-0">Total</h4>
                                                    </div>
                                                </div>
                                                <div className="">
                                                    <h4 className="fw-bold mb-0">
                                                        {currencySymbol}
                                                        {/* {totalAmountPaid} */}
                                                        {(parseFloat(cartProductTotal) + parseFloat(onlySavePaymentRoundUpAmount)).toFixed(2)}
                                                    </h4>
                                                </div>
                                            </div>




                                        </div>
                                    </div>
                                    : <>
                                        {cartData.length <= 0 ?

                                            <h4 className="card-title m-3 fw-bold">
                                                No Items Found !
                                            </h4>
                                            : <></>}

                                    </>}


                                {cartData.length > 0 && activeTab === "details" ?
                                    <div className="formcss">
                                        <form className="mb-1">
                                            <div className="row">
                                                <div className="col">
                                                    <label className="">Mobile</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Enter Mobile"
                                                        maxLength={10}
                                                        name="mobile"
                                                        defaultValue=""
                                                        value={customerForm.mobile}
                                                        onChange={handleCustomerFormChange}
                                                        disabled={true}
                                                    />
                                                </div>
                                                <div className="col">
                                                    <label className="">Name</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Enter Name"
                                                        name="name"
                                                        defaultValue=""
                                                        value={customerForm.name}
                                                        onChange={handleCustomerFormChange}
                                                        disabled={true}

                                                    />
                                                </div>
                                            </div>
                                            <div className="row mt-2">
                                                <div className="col">
                                                    <label className="">Address</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Address"
                                                        name="address"
                                                        defaultValue=""
                                                        value={customerForm.address}
                                                        onChange={handleCustomerFormChange}
                                                        disabled={true}

                                                    />
                                                </div>
                                            </div>

                                            <div className="row mt-2">
                                                <div className="col">
                                                    <label className="">Total People</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Total People"
                                                        name="total_people"
                                                        defaultValue=""
                                                        value={customerForm.total_people}
                                                        onChange={handleCustomerFormChange}
                                                        disabled={true}

                                                    />
                                                </div>
                                            </div>
                                            <div className="row mt-2">
                                                <div className="col">
                                                    <label className="">Customer Suggestions</label>
                                                    <textarea
                                                        className="form-control"
                                                        placeholder="Enter Suggestions"
                                                        rows={5}
                                                        maxLength={200}
                                                        name="customer_suggestions"
                                                        defaultValue={""}
                                                        value={customerForm.customer_suggestions}
                                                        onChange={handleCustomerFormChange}
                                                        disabled={true}

                                                    />
                                                    {/* <p className="text-right">0/200</p> */}
                                                </div>
                                            </div>
                                            <div className="mt-2" />
                                        </form>
                                    </div>

                                    : <>
                                        {/* <h4 className="card-title m-3 fw-bold">
                                        No Items Found !
                                    </h4> */}
                                    </>}

                                {cartData.length > 0 && activeTab === "discount" ?

                                    <div className="tableshow">
                                        <div className="tshwdiv">
                                            <form className="forms-sample">
                                                <h4 className="card-title m-3 fw-bold ms-0">
                                                    Add Discount
                                                </h4>
                                                <div className="row">
                                                    <div className="col-8">
                                                        <div className="input-group mb-3 disselect">
                                                            <button
                                                                className="btn btn-outline-secondary dropdown-toggle"
                                                                type="button"
                                                                data-bs-toggle="dropdown"
                                                                aria-expanded="false"
                                                            >
                                                                {discountValues.type === "0"
                                                                    ? "%"
                                                                    : `${currencySymbol}`}
                                                            </button>
                                                            <ul className="dropdown-menu">
                                                                <li
                                                                    onClick={() =>
                                                                        setDiscountValues((prevValues) => ({
                                                                            ...prevValues,
                                                                            type: "0",
                                                                        }))
                                                                    }
                                                                >
                                                                    <span className="dropdown-item">%</span>
                                                                </li>
                                                                <li
                                                                    onClick={() =>
                                                                        setDiscountValues((prevValues) => ({
                                                                            ...prevValues,
                                                                            type: "1",
                                                                        }))
                                                                    }
                                                                > <span className="dropdown-item">{currencySymbol}</span></li>
                                                            </ul>
                                                            <input
                                                                type="text"
                                                                className="form-control discnt"
                                                                name="rate"
                                                                onChange={(e) => {
                                                                    setDiscountValues((prevValues) => ({
                                                                        ...prevValues,
                                                                        rate: e.target.value,
                                                                    }))
                                                                }}
                                                                value={discountValues.rate}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-4">
                                                        <button
                                                            type="submit"
                                                            className="btn btn-primary me-2"
                                                            onClick={handleApplyDiscountClick}

                                                        >
                                                            Apply
                                                        </button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    : <></>}
                                {cartData.length > 0 && activeTab === "payment" ?

                                    <div className="tableshow">
                                        <div className="tshwdiv">
                                            <form className="forms-sample">
                                                <h4 className="card-title m-3 fw-bold ms-0">
                                                    Bill Settlement
                                                </h4>
                                                <h6 className="modal-title">
                                                    Total Amount: ₹ {(parseFloat(cartProductTotal) + parseFloat(onlySavePaymentRoundUpAmount)).toFixed(2)}
                                                </h6>
                                                <h5 className="card-title m-3 ms-0">
                                                    Select Payment Option
                                                </h5>
                                                <div className="row">
                                                    <div>
                                                        <div className="form-check form-check-inline">
                                                            <input
                                                                className="form-check-input"
                                                                type="radio"
                                                                name="inlineRadioOptions"
                                                                value="Cash"
                                                                checked={selectedPaymentOption === "Cash"}
                                                                onChange={handlePaymentOptionChange}
                                                            />
                                                            <label className="form-check-label">
                                                                Cash
                                                            </label>
                                                        </div>
                                                        <div className="form-check form-check-inline">
                                                            <input
                                                                className="form-check-input"
                                                                type="radio"
                                                                name="inlineRadioOptions"
                                                                value="Card"
                                                                checked={selectedPaymentOption === "Card"}
                                                                onChange={handlePaymentOptionChange}
                                                            />
                                                            <label className="form-check-label">
                                                                Card
                                                            </label>
                                                        </div>

                                                        <div className="form-check form-check-inline">
                                                            <input
                                                                className="form-check-input"
                                                                type="radio"
                                                                name="inlineRadioOptions"
                                                                value="NEFT"
                                                                checked={selectedPaymentOption === "NEFT"}
                                                                onChange={handlePaymentOptionChange}
                                                            />
                                                            <label className="form-check-label">
                                                                NEFT
                                                            </label>
                                                        </div>

                                                        <div className="form-check form-check-inline">
                                                            <input
                                                                className="form-check-input"
                                                                type="radio"
                                                                name="inlineRadioOptions"
                                                                value="UPI"
                                                                checked={selectedPaymentOption === "UPI"}
                                                                onChange={handlePaymentOptionChange}
                                                            />
                                                            <label className="form-check-label">
                                                                UPI
                                                            </label>
                                                        </div>




                                                    </div>
                                                </div>

                                                <div className="row">
                                                    {selectedPaymentOption === "UPI" && (<>

                                                        <div className="col-12  mt-3">
                                                            <label className="">
                                                                Select Method
                                                                <span className="text-danger">*</span>
                                                            </label>
                                                            <select
                                                                className="form-select"
                                                                name="method"
                                                                value={upiPaymentDetails.method}
                                                                onChange={handleUPIPaymentDetailsChange}
                                                            >
                                                                <option value="">Select Method</option>
                                                                <option value="Google Pay">
                                                                    Google Pay
                                                                </option>
                                                                <option value="PhonePe">PhonePe</option>
                                                                <option value="Paytm">Paytm</option>
                                                                <option value="Other">Other</option>
                                                            </select>
                                                            {upiPaymentDetails.method === "Other" && (
                                                                <>
                                                                    &nbsp;
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        placeholder="Enter Method"
                                                                        name="other_method"
                                                                        value={
                                                                            upiPaymentDetails.other_method
                                                                        }
                                                                        onChange={
                                                                            handleUPIPaymentDetailsChange
                                                                        }
                                                                    />
                                                                </>
                                                            )}
                                                        </div>
                                                    </>)}


                                                    <div className="col-12  mt-3">
                                                        <label className="">
                                                            Customer Paid
                                                            <span className="text-danger">*</span>
                                                        </label>
                                                        <div className="input-group">
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="Enter Customer Paid"
                                                                name="customerPaid"
                                                                value={onlySavePaymentPaidedAmount}
                                                                onChange={(e) => { setOnlySavePaymentPaidedAmount(e.target.value) }}


                                                            />
                                                            <span className="input-group-text">
                                                                {currencySymbol}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="col-12  mt-3">
                                                        <label className="">Tip</label>
                                                        <div className="input-group">
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="Enter Tip"
                                                                name="tip"
                                                                value={onlySavePaymentTipAmount}
                                                                onChange={(e) => { setOnlySavePaymentTipAmount(e.target.value) }}
                                                            />
                                                            <span className="input-group-text">
                                                                {currencySymbol}
                                                            </span>
                                                        </div>
                                                    </div>

                                                    <div className="col-12  mt-3">
                                                        <label className="">Transaction / Reference ID</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Enter Transaction / Reference ID"
                                                            name="transaction_id"
                                                            value={onlySavePaymentTipAmount}
                                                            onChange={(e) => { setPaymentSettleTransactionId(e.target.value) }}

                                                        />
                                                    </div>


                                                </div>

                                                <button
                                                    className="btn btn-primary mt-3"
                                                    onClick={handleOnlySavePayment}
                                                    type="button"
                                                    disabled={
                                                        onlySavePaymentPaidedAmount <= 0

                                                    }
                                                >

                                                    Pay
                                                </button>
                                            </form>

                                            {onlySavePaymentData.length > 0 && (<>
                                                <div className="itemdiv">
                                                    <div className="row">

                                                        <div className="col-lg-4">
                                                            <p className="itemnm">Payment Method</p>
                                                        </div>

                                                        <div className="col-lg-3">
                                                            <p className="itemnm">Amount</p>
                                                        </div>

                                                        <div className="col-lg-3">
                                                            <p className="itemnm">Tip Amount</p>
                                                        </div>

                                                        <div className="col-lg-2">
                                                            <p className="itemnm">Action</p>
                                                        </div>

                                                    </div>


                                                </div>
                                            </>)}

                                            {onlySavePaymentData.map((item, index) => (
                                                <div className="itemdiv" key={index}>

                                                    <div className="row">
                                                        <div className="col-lg-4">
                                                            <p>{item.method === "UPI" ? item.method + " by " + item.upiType : item.method}</p>
                                                        </div>
                                                        <div className="col-lg-3">

                                                            <p>{item.amount}</p>

                                                        </div>
                                                        <div className="col-lg-3">
                                                            <p>{item.tip}</p>
                                                        </div>
                                                        <div className="col-lg-2">
                                                            <span className="" style={{ cursor: "pointer" }} onClick={() => { handleOnlySavePaymentRemove(index) }}>
                                                                <i className="fa fa-window-close closeicon" aria-hidden="true" />
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}

                                            {onlySavePaymentData.length > 0 ?

                                                <button
                                                    className="btn btn-success mt-3"
                                                    onClick={() => clickOrderSaveButton(true)}
                                                    type="button"
                                                    
                                                >

                                                    Save
                                                </button>
                                                : <></>}

                                        </div>
                                    </div>
                                    : <></>}


                                <div
                                    className={logoutModel ? "modal fade show d-block" : "modal fade"}
                                    id="exampleModal"
                                    tabIndex={-1}
                                    role="dialog"
                                    aria-labelledby="exampleModalLabel"
                                    aria-hidden="true"
                                >
                                    <div className="modal-dialog" role="document">
                                        <div className="modal-content w-85">
                                            <div className="modal-header py-2">
                                                <h5 className="modal-title" id="exampleModalLabel">
                                                    Logout
                                                </h5>
                                                <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    onClick={() => { setLogoutModel(false) }}
                                                >
                                                    <span aria-hidden="true">×</span>
                                                </button>
                                            </div>
                                            <div className="modal-body py-2">
                                                <div className="clear_and_logout_text_padding">
                                                    <h6>
                                                        Clicking on the “Clear and Logout” buttons will clear the
                                                        tables.
                                                    </h6>
                                                </div>
                                            </div>
                                            <div className="modal-footer" style={{ padding: "15px 0px" }}>
                                                <div className="row" style={{ width: "100%" }}>
                                                    <div className="col-3 valign">
                                                        <button
                                                            type="button"
                                                            className="btn btn-primary"
                                                            data-dismiss="modal"
                                                            onClick={() => { handleLogout(false) }}
                                                        >
                                                            Logout
                                                        </button>{" "}
                                                    </div>
                                                    <div className="col-8 valign">
                                                        <button
                                                            type="button"
                                                            className="btn btn-danger"
                                                            data-dismiss="modal"
                                                            onClick={() => { handleLogout(true) }}
                                                        >
                                                            Clear and Logout
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className={isOpenPassword ? "modal fade show d-block" : "modal fade"}
                                    id="exampleModal"
                                    tabIndex={-1}
                                    role="dialog"
                                    aria-labelledby="exampleModalLabel"
                                    aria-hidden="true"
                                >
                                    <div className="modal-dialog" role="document">
                                        <div className="modal-content w-85">
                                            <div className="modal-header py-2">
                                                <h5 className="modal-title" id="exampleModalLabel">
                                                    Enter Password
                                                </h5>

                                            </div>
                                            <div className="modal-body py-2">
                                                <div className="clear_and_logout_text_padding">
                                                    <h6>
                                                        If you can edit the order, please enter your edit order password.
                                                    </h6>

                                                    <div className="input-group">
                                                        <input

                                                            className="form-control"
                                                            placeholder="Enter Order Edit Password"
                                                            defaultValue=""
                                                            onChange={(e) => { setEditPassword(e.target.value) }}
                                                            value={editPassword}
                                                            type={'password'}
                                                        />

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="modal-footer" style={{ padding: "15px 8px" }}>
                                                <div className="row" style={{ width: "100%" }}>
                                                    <div className="col-6 valign">
                                                        <button
                                                            type="button"
                                                            className="btn btn-success"
                                                            data-dismiss="modal"
                                                            onClick={() => { playSound(); getData() }}
                                                        >
                                                            Submit
                                                        </button>{" "}

                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className={isOpenSaveBill ? "modal fade show d-block" : "modal fade"}
                                    id="exampleModal"
                                    tabIndex={-1}
                                    role="dialog"
                                    aria-labelledby="exampleModalLabel"
                                    aria-hidden="true"
                                >
                                    <div className="modal-dialog" role="document">
                                        <div className="modal-content w-85">
                                            <div className="modal-header py-2">
                                                <h5 className="modal-title" id="exampleModalLabel">
                                                    Confirmation
                                                </h5>
                                                <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    onClick={() => { playSound(); setIsOpenSaveBill(false) }}
                                                >
                                                    <span aria-hidden="true">×</span>
                                                </button>
                                            </div>
                                            <div className="modal-body py-2">
                                                <div className="clear_and_logout_text_padding">
                                                    <h3>
                                                        Are you sure?
                                                    </h3>
                                                    <h6>
                                                        If you edit this order stock will not be updated.
                                                    </h6>

                                                </div>
                                            </div>
                                            <div className="modal-footer" style={{ padding: "15px 0px" }}>
                                                <div className="row" style={{ width: "100%" }}>
                                                    <div className="col-12 valign">
                                                        <button
                                                            type="button"
                                                            className="btn btn-success"
                                                            data-dismiss="modal"
                                                            onClick={() => { clickOrderSaveButton(false) }}
                                                        >
                                                            Save
                                                        </button>{" "}
                                                        <button
                                                            type="button"
                                                            className="btn btn-secondary"
                                                            data-dismiss="modal"
                                                            onClick={() => { playSound(); setIsOpenSaveBill(false) }}
                                                        >
                                                            Cancel
                                                        </button>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div
                    className="offcanvas offcanvas-start navmenu"
                    tabIndex="-1"
                    id="offcanvasExample"
                    aria-labelledby="offcanvasExampleLabel"
                >
                    <div className="offcanvas-header">
                        <h5 className="offcanvas-title">All Menus</h5>
                        <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="offcanvas"
                            aria-label="Close"
                        ></button>
                    </div>
                    <div className="offcanvas-body">
                        <div>
                            <ul className="list-group navli posli">
                                {planDetails && planDetails.dashboard === 1 && (
                                    <li className="">
                                        <a href="/dashboard">
                                            <i className="mdi mdi-grid-large menu-icon me-2"></i>{" "}
                                            Dashboard
                                        </a>
                                    </li>
                                )}

                                {planDetails && planDetails.live_orders === 1 && (
                                    <li className="">
                                        <a href="/live_orders">
                                            <i className="mdi mdi-speedometer-medium menu-icon me-2"></i>{" "}
                                            Live Orders
                                        </a>
                                    </li>
                                )}

                                {planDetails && planDetails.staff_manager === 1 && (
                                    <li className="">
                                        <a href="/view_staff">
                                            <i className="menu-icon mdi mdi-account me-2"></i> Staff
                                        </a>
                                    </li>
                                )}

                                {planDetails && planDetails.menu_products === 1 && (
                                    <li className="">
                                        <a
                                            data-bs-toggle="collapse"
                                            href="#ui-basic1"
                                            aria-expanded="false"
                                            aria-controls="ui-basic1"
                                        >
                                            <i className="menu-icon mdi mdi-floor-plan me-2"></i>{" "}
                                            Manage Dishes
                                            <i className="menu-icon menu-arrow"></i>
                                        </a>
                                        <div className="collapse" id="ui-basic1">
                                            <ul className="nav flex-column sub-menu">
                                                <li className="">
                                                    <a href="/view_menus">
                                                        <i className="menu-icon mdi mdi-floor-plan me-2"></i>{" "}
                                                        Category/Menu
                                                    </a>
                                                </li>
                                                <li className="">
                                                    <a href="/view_products">
                                                        <i className="menu-icon mdi mdi-silverware-fork-knife me-2"></i>{" "}
                                                        Dishes/Products
                                                    </a>
                                                </li>
                                                <li className="">
                                                    <a href="/add_ons">
                                                        <i className="menu-icon mdi mdi-cookie me-2"></i>{" "}
                                                        Add Ons
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </li>
                                )}

                                {planDetails && planDetails.orders === 1 && (
                                    <li className="">
                                        <a href="/orders">
                                            <i className="menu-icon mdi mdi-cart me-2"></i> Orders
                                        </a>
                                    </li>
                                )}

                                {planDetails && planDetails.inventory === 1 && (
                                    <li className="">
                                        <a
                                            data-bs-toggle="collapse"
                                            href="#ui-basic"
                                            aria-expanded="false"
                                            aria-controls="ui-basic1"
                                        >
                                            <i className="menu-icon mdi mdi-store me-2"></i>{" "}
                                            Inventory
                                            <i className="menu-icon menu-arrow"></i>
                                        </a>
                                        <div className="collapse" id="ui-basic">
                                            <ul className="nav flex-column sub-menu">
                                                <li className="">
                                                    <a href="/raw_material">
                                                        <i className="menu-icon mdi mdi-store me-2"></i>{" "}
                                                        Raw Material
                                                    </a>
                                                </li>
                                                <li className="">
                                                    <a href="/suppliers">
                                                        <i className="menu-icon mdi mdi-truck me-2"></i>{" "}
                                                        Suppliers
                                                    </a>
                                                </li>
                                                <li className="">
                                                    <a href="/recipes">
                                                        <i className="menu-icon mdi mdi-notebook-outline me-2"></i>{" "}
                                                        Recipes
                                                    </a>
                                                </li>
                                                <li className="">
                                                    <a href="/stocks">
                                                        <i className="menu-icon mdi mdi-store-24-hour me-2"></i>{" "}
                                                        Stocks
                                                    </a>
                                                </li>

                                                <li className="">
                                                    <a href="/view_purchase">
                                                        <i className="menu-icon mdi mdi-wallet-outline me-2"></i>{" "}
                                                        Purchase
                                                    </a>
                                                </li>
                                                <li className="">
                                                    <a href="/view_purchase_return">
                                                        <i className="menu-icon mdi mdi-share me-2"></i>{" "}
                                                        Purchase Returns
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </li>
                                )}

                                {planDetails && planDetails.fooder_cp === 1 && (
                                    <li className="">
                                        <a href="/fooders_cp">
                                            <i className="menu-icon mdi mdi-airballoon me-2"></i>{" "}
                                            Fooders CP
                                        </a>
                                    </li>
                                )}

                                {planDetails && planDetails.fooder_cp === 1 && (
                                    <li className="">
                                        <a href="/whatsapp_marketing">
                                            <i className="menu-icon mdi mdi-whatsapp me-2"></i>{" "}
                                            WhatsApp Marketing
                                        </a>
                                    </li>
                                )}

                                {planDetails && planDetails.customers === 1 && (
                                    <li className="">
                                        <a href="/customers">
                                            <i className="menu-icon mdi mdi-account-group me-2"></i>{" "}
                                            Customers
                                        </a>
                                    </li>
                                )}

                                {/* {planDetails && planDetails.verififation_documents === 1 && (
                    <li className="">
                      <a href="/verification">
                        <i className="menu-icon mdi mdi-file-document me-2"></i>{" "}
                        Verification
                      </a>
                    </li>
                  )} */}

                                {planDetails && planDetails.manage_tables === 1 && (
                                    <li className="">
                                        <a href="/manage_tables">
                                            <i className="menu-icon mdi mdi-table me-2"></i> Manage
                                            Tables
                                        </a>
                                    </li>
                                )}

                                {planDetails && planDetails.callwaiter_settings === 1 && (
                                    <li className="">
                                        <a href="/qrcode_settings">
                                            <i className="menu-icon mdi mdi-qrcode-edit me-2"></i>{" "}
                                            QR Code Settings
                                        </a>
                                    </li>
                                )}

                                {/* {planDetails &&
                    planDetails.online_delivery_settings === 1 && (
                      <li className="">
                        <a href="/online_ordering">
                          <i className="menu-icon mdi mdi-truck me-2"></i>{" "}
                          Delivery Settings
                        </a>
                      </li>
                    )} */}

                                {planDetails && planDetails.coupons === 1 && (
                                    <li className="">
                                        <a href="/manage_coupons">
                                            <i className="menu-icon mdi mdi-ticket-percent me-2"></i>{" "}
                                            Manage Coupons
                                        </a>
                                    </li>
                                )}

                                {planDetails && planDetails.reports === 1 && (
                                    <li className="">
                                        <a href="/reports">
                                            <i className="menu-icon mdi mdi-file-chart me-2"></i>{" "}
                                            Reports
                                        </a>
                                    </li>
                                )}

                                <li className="">
                                    <a href="/account_management">
                                        <i className="menu-icon mdi mdi-account-circle-outline me-2"></i>{" "}
                                        Profile
                                    </a>
                                </li>
                                <li className="">
                                    <a href="/updates">
                                        <i className="menu-icon mdi mdi-pencil-box me-2"></i>{" "}
                                        Updates
                                    </a>
                                </li>

                                <li>
                                    <Link
                                        // onClick={handleLogout}
                                        onClick={() => { setLogoutModel(true) }}
                                        // setLogoutModel
                                        data-bs-dismiss="offcanvas"
                                        aria-label="Close"
                                    >
                                        <i className="menu-icon mdi mdi-logout me-2"></i> Logout
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className={isModal ? "modal fade show vrttcrd d-block" : "modal fade"} id="exampleModal" tabIndex={-1}
                    role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    {/* <div className={"modal-dialog modal-lg"} role="document"> */}
                    <div className={productVariantAttributesData.length > 0 || productVariantAddonsData.length > 0 ? "modal-dialog modal-lg" : "modal-dialog"} role="document">

                        <div className="modal-content w-85">
                            <div className="modal-header py-2">
                                <h5 className="modal-title" id="exampleModalLabel">
                                    {selectedProduct
                                        ? selectedProduct.name.length > 30
                                            ? capitalizeFirstLetter(
                                                selectedProduct.name.substring(0, 30) + "..."
                                            )
                                            : capitalizeFirstLetter(selectedProduct.name)
                                        : ""}
                                </h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => {
                                    playSound();
                                    setIsModal(false);
                                }}
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>

                            </div>

                            <div className="modal-body py-2">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-sm-4 d-flex align-items-center">
                                            <h5>Quantity</h5>
                                        </div>
                                        <div className="col-sm-8">
                                            <button type="button" className="btn btn-outline-secondary btn-icon ctlbtn" onClick={() =>
                                                updateQuantity(false, selectedProduct.min_order_quantity)}
                                            >
                                                <i className="fa fa-minus"></i>
                                            </button>
                                            &nbsp;
                                            <button type="button" className="btn btn-outline-secondary btn-icon ctlbtn">
                                                <span>
                                                    {selectedProduct ? selectedProduct.quantity : ""}
                                                </span>
                                            </button>
                                            &nbsp;
                                            <button type="button" className="btn btn-outline-secondary btn-icon ctlbtn" onClick={() =>
                                                updateQuantity(true, selectedProduct.min_order_quantity)}
                                            >
                                                <i className="fa fa-plus"></i>
                                            </button>
                                        </div>
                                    </div>








                                    <div className="row my-2">
                                        <div className="col-sm-4 d-flex align-items-center">
                                            <h5>Special Note</h5>
                                        </div>
                                        <div className="col-sm-8">
                                            <div className="form-group mb-0">
                                                <textarea className="form-control" placeholder="Enter Special Note" rows={4} value={productNote}
                                                    onChange={handleSaveProductNote} maxLength={maxCharacterLimit}></textarea>
                                                <p className="text-right m-0">
                                                    {productNote.length}/{maxCharacterLimit}
                                                </p>
                                            </div>
                                        </div>
                                    </div>


                                    {productVariantAttributesData.map((i, iCount) => {
                                        return (
                                            <div className="vrntcss mb-3" key={iCount}>
                                                <div className="row">
                                                    <div className="col-8">
                                                        <h5 className="pt-1">{i.attributeName}</h5>
                                                    </div>
                                                </div>
                                                <div className="row mt-1 ">
                                                    {i.value.map((j, jCount) => {
                                                        return (

                                                            <div className="col-md-3 my-1" key={jCount}>
                                                                <div className="card card-rounded ">
                                                                    <div className={selectedProductVariantAttributesData[iCount] === j.attributeValueId
                                                                        ? "card-body active custom-cursor-pointer" : "card-body custom-cursor-pointer"} onClick={() => {
                                                                            changeSelectedVariants(iCount, j.attributeValueId)
                                                                        }}
                                                                    >
                                                                        <span className="fw-bold">{j.attributeValue}</span>
                                                                        <br />
                                                                        {/* {iCount + 1 === i.length ? <p className="itemno mb-0 mt-1">{currencySymbol}
                      {productVariantPropertiesPriceArray[jCount]}</p> : <></>} */}
                                                                        {iCount === selectedProductVariantAttributesData.length - 1 ? <>

                                                                            <p className="itemno mb-0 mt-1">{currencySymbol}{productVariantPropertiesPriceArray[jCount]}</p>

                                                                        </> : <></>}
                                                                    </div>
                                                                </div>
                                                            </div>


                                                        )
                                                    })}
                                                </div>
                                            </div>
                                        )
                                    })}




                                    {productVariantAddonsData.map((i, iCount) => {
                                        if (selectedProductVariant.variantId === i.addonVariantId) {
                                            return (
                                                <div className="vrntcss mb-3" key={iCount}>
                                                    <div className="row">
                                                        <div className="col-8">
                                                            <h5 className="pt-1">{i.addon_group_name}</h5>
                                                        </div>
                                                    </div>
                                                    <div className="row mt-1">
                                                        {i.addonItems.map((j, jCount) => (
                                                            <div className="col-md-3 my-1" key={jCount}>
                                                                <div className="card card-rounded custom-cursor-pointer">
                                                                    <div className={selectedProductVariantAddonsIdArray.indexOf(j.addonItemId) !== -1
                                                                        ? "card-body active custom-cursor-pointer" : "card-body custom-cursor-pointer"} onClick={() => {
                                                                            selectedProductVariantAddonsIdArray.indexOf(j.addonItemId) !== -1
                                                                                ? changeSelectedAddons(false, j, i.minimum_item, i.maximum_item, i.addonId)
                                                                                : changeSelectedAddons(true, j, i.minimum_item, i.maximum_item, i.addonId)
                                                                        }}>



                                                                        <span className="fw-bold">{j.addon_item_name}</span>
                                                                        <br />
                                                                        <p className="itemno mb-0 mt-1">{currencySymbol}{j.addon_item_price}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                    {i.minimum_item && <p>Add up to more then {i.minimum_item ? i.minimum_item - 1 : 0} items</p>}
                                                </div>
                                            );
                                        } else {
                                            return null;
                                        }
                                    })}

                                    {productVariantAttributesData.length <= 0 && <>
                                        {productVariantAddonsData.map((i, iCount) => {
                                            return (

                                                i.addonId !== null &&
                                                <div className="vrntcss mb-3" key={iCount}>
                                                    <div className="row">
                                                        <div className="col-8">
                                                            <h5 className="pt-1">{i.addon_group_name}</h5>
                                                        </div>
                                                    </div>
                                                    <div className="row mt-1">
                                                        {i.addonItems.map((j, jCount) => (
                                                            <div className="col-md-3 my-1" key={jCount}>
                                                                <div className="card card-rounded custom-cursor-pointer">
                                                                    <div className={selectedProductVariantAddonsIdArray.indexOf(j.addonItemId) !== -1
                                                                        ? "card-body active custom-cursor-pointer" : "card-body custom-cursor-pointer"} onClick={() => {
                                                                            selectedProductVariantAddonsIdArray.indexOf(j.addonItemId) !== -1
                                                                                ? changeSelectedAddons(false, j, i.minimum_item, i.maximum_item, i.addonId)
                                                                                : changeSelectedAddons(true, j, i.minimum_item, i.maximum_item, i.addonId)
                                                                        }}>



                                                                        <span className="fw-bold">{j.addon_item_name}</span>
                                                                        <br />
                                                                        <p className="itemno mb-0 mt-1">{currencySymbol}{j.addon_item_price}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                    {i.minimum_item && <p>Add up to more then {i.minimum_item ? i.minimum_item - 1 : 0} items</p>}
                                                </div>

                                            )



                                        })}
                                    </>}




                                </div>


                            </div>



                            <div className="modal-footer pymtftr">
                                <div className="row" style={{ width: "100%" }}>
                                    <div className="col-6 valign">
                                        <div  >

                                            {parseFloat(selectedProductVariantAddonsPrice) + parseFloat(selectedProductVariant.combination_price) ?

                                                <h5>
                                                    {currencySymbol}{(
                                                        (
                                                            parseFloat(selectedProductVariantAddonsPrice) +
                                                            parseFloat(selectedProductVariant.combination_price)
                                                        ) * (selectedProduct ? parseFloat(selectedProduct.quantity) : 1)
                                                    ).toFixed(2)
                                                    }

                                                </h5>

                                                : <h5>

                                                    {currencySymbol}{(selectedPrductForAddedVariants.proprice ?
                                                        ((parseFloat(selectedPrductForAddedVariants.proprice) + parseFloat(selectedProductVariantAddonsPrice)) * (selectedProduct ? parseFloat(selectedProduct.quantity) : 1)).toFixed(2) :
                                                        ((parseFloat(selectedPrductForAddedVariants.price) + parseFloat(selectedProductVariantAddonsPrice)) * (selectedProduct ? parseFloat(selectedProduct.quantity) : 1)).toFixed(2))}

                                                </h5>
                                            }


                                        </div>
                                    </div>
                                    <div className="col-lg-6 text-right">
                                        <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={() => {
                                            playSound();
                                            setIsModal(false);
                                        }}
                                        >
                                            Cancel
                                        </button>{' '}
                                        <button type="button" className="btn btn-primary" onClick={handleAddToOrder}>
                                            Add
                                        </button>
                                    </div>
                                </div>


                            </div>








                        </div>
                    </div>
                </div>

            </div>
            <LoadingModal isLoading={isLoading} />

            <Notification />
        </div>
    )
}
