import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import SideBar from "../other/SideBar";
import NavBar from "../other/NavBar";
import {
  GetEditDishesProductURL,
  ScriptId,
  ScriptSRC,
  AddNewProductPropertyURL,
  AddNewPropertyAttributeURL,
  DeleteNewPropertyAttributeURL,
  DeleteProductPropertyURL,
  AddProductPropertyCombinationPriceURL,
  GetAddonGroupListURL,
  CreateAddonGroupURL,
  DeleteAddonGroupURL,
  AssignAddonGroupToProuductURL,
  DeleteAssignAddonGroupURL,
} from "../../assets/api/BaseURL";
import LoadingModal from "../../loader/LoadingModal";
import DeleteModal from "../../deleteModal/DeleteModal";
import Notification from "../other/Notification";
import { useToast } from "../../../context/ToastContext";

const ManageVariants = () => {
  const navigate = useNavigate();
  const { addToast } = useToast();
  const [token, setToken] = useState("");
  const [productID, setProductID] = useState("");
  const [toggleSideBar, setToggleSideBar] = useState(false);
  const [canvas, setCanvas] = useState(false);
  const [currencySymbol, setCurrencySymbol] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [flag, setFlag] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [deletePropertyId, setDeletePropertyId] = useState();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showPropertyDeleteModal, setShowPropertyDeleteModal] = useState(false);
  const [product, setProduct] = useState({
    menu_id: "",
    name: "",
    price: "",
    proprice: "",
    tax: "",
    tax_type: "",
    status: true,
    picture: "",
    product_type: "",
    top_rated: false,
    best_seller: false,
    most_ordered: false,
    dine_in: false,
    delivery: false,
    pick_up: false,
    feature: false,
    preparation_time: "5",
    description: "",
    variant1: "",
    variant2: "",
    variant3: "",
    variant4: "",
  });

  const [isLoading, setIsLoading] = useState(false);

  const [properties, setProperties] = useState([]);
  const [pricingData, setPricingData] = useState([]);
  const [pricingCombinationData, setPricingCombinationData] = useState([]);
  const [showAddNew, setShowAddNew] = useState(properties.map(() => false));
  const [showAddNewProperty, setShowAddNewProperty] = useState(false);
  const [newAttribute, setNewAttribute] = useState("");
  const [newPropertyDetails, setNewPropertyDetails] = useState({
    property_name: "",
    value1: "",
    value2: "",
  });

  const [data, setData] = useState([]);
  const [addOnGroupDataId, setAddOnGroupDataId] = useState();
  const [addOnGroupName, setAddOnGroupName] = useState("");
  const [deleteAddOnId, setDeleteAddOnId] = useState();
  const [deleteAssignAddOnId, setDeleteAssignAddOnId] = useState();
  const [showDeleteAddOnModal, setShowDeleteAddOnModal] = useState(false);
  const [showDeleteAssignAddOnModal, setShowDeleteAssignAddOnModal] =
    useState(false);
  const [showAddOnForm, setShowAddOnForm] = useState(false);
  const [addOnNewFormData, setAddOnNewFormData] = useState({
    groupName: "",
    min: "",
    max: "",
    items: [],
  });
  const [addOnNewItemData, setAddOnNewItemData] = useState({
    name: "",
    type: "",
    price: "",
  });
  const [modifiedCombinationData, setModifiedCombinationData] = useState([]);
  const [productBasedAddonsData, setProductBasedAddonsData] = useState([]);
  const [isAaaignAddOnModal, setIsAaaignAddOnModal] = useState(false);

  const handleToggleSidebar = (e) => {
    e.preventDefault();
    setToggleSideBar(!toggleSideBar);
  };

  const handleToggleOffCanvas = (e) => {
    e.preventDefault();
    setCanvas(!canvas);
  };

  const isValidNumber = (value) => {
    return !isNaN(parseFloat(value)) && isFinite(value);
  };

  const handleDishesProductsClick = (e) => {
    e.preventDefault();
    navigate("/view_products");
  };

  const handleDelete = (e, id, item) => {
    e.preventDefault();
    if (item.values.length === 1) {
      setSuccess("");
      setError("Please add more property values to delete this!");
    } else {
      setDeleteId([id]);
      setShowDeleteModal(!showDeleteModal);
    }
  };

  const handleDeleteProductVariant = async () => {
    try {
      const response = await axios({
        method: "delete",
        url: DeleteNewPropertyAttributeURL,
        data: deleteId,
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 200 && response.data.message) {
        setError("");
        setSuccess("");
        addToast("success", response.data.message);
        setFlag(!flag);
        setShowDeleteModal(!showDeleteModal);
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        addToast("error", error.response.data.message);
      } else {
        addToast("error", error.message);
      }
      setError("");
      setSuccess("");
      console.log(error);
    }
  };

  const handleDeleteProperty = (e, id) => {
    e.preventDefault();
    setDeletePropertyId(id);
    setShowPropertyDeleteModal(!showPropertyDeleteModal);
  };

  const handleDeleteProductProperty = async () => {
    try {
      const response = await axios({
        method: "delete",
        url: DeleteProductPropertyURL,
        data: { id: deletePropertyId },
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 200 && response.data.message) {
        setError("");
        setSuccess("");
        addToast("success", response.data.message);
        setFlag(!flag);
        setShowPropertyDeleteModal(!showPropertyDeleteModal);
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        addToast("error", error.response.data.message);
      } else {
        addToast("error", error.message);
      }
      setError("");
      setSuccess("");
      console.log(error);
    }
  };

  const handleAddNewClick = (index) => {
    setNewAttribute("");
    const newShowAddNew = [...showAddNew];
    newShowAddNew[index] = true;
    setShowAddNew(newShowAddNew);
  };

  const handleNewPropetyDetailsChange = (event) => {
    const { name, value } = event.target;

    setNewPropertyDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
    setError("");
    setSuccess("");
  };

  const handleAddNewProperty = async () => {
    if (
      newPropertyDetails.property_name === "" ||
      newPropertyDetails.property_name.trim() === ""
    ) {
      setError("Property name can not be empty");
      setSuccess("");
    } else if (
      newPropertyDetails.value1 === "" ||
      newPropertyDetails.value1.trim() === ""
    ) {
      setError("Property value 1 name can not be empty");
      setSuccess("");
    } else {
      const dataToSend = {
        product_id: productID,
        display_name: newPropertyDetails.property_name,
        attribute_value: [
          { value: newPropertyDetails.value1 },
          { value: newPropertyDetails.value2 },
        ],
        price: product.price,
      };

      setIsLoading(true);
      try {
        const response = await axios({
          method: "post",
          url: AddNewProductPropertyURL,
          data: dataToSend,
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${token}`,
          },
        });

        if (response.status === 200 && response.data.message) {
          setError("");
          setSuccess(response.data.message);
          setShowAddNewProperty(false);
          setFlag(!flag);
        }
      } catch (error) {
        if (error.response && error.response.data.message) {
          setError(error.response.data.message);
          setSuccess("");
        } else {
          setError(error.message);
          setSuccess("");
        }
        console.log(error);
      }
      setIsLoading(false);
    }
  };

  const handleAddNewAttribute = async (id, index) => {
    if (newAttribute === "" || newAttribute.trim() === "") {
      setError("Property value name can not be empty");
      setSuccess("");
    } else {
      const dataToSend = {
        attribute_id: id,
        attribute_value: newAttribute,
        price: product.price,
      };

      setIsLoading(true);
      try {
        const response = await axios({
          method: "post",
          url: AddNewPropertyAttributeURL,
          data: dataToSend,
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${token}`,
          },
        });

        if (response.status === 200 && response.data.message) {
          setError("");
          setSuccess(response.data.message);
          setShowAddNewProperty(false);
          setShowAddNew([
            ...showAddNew.slice(0, index),
            false,
            ...showAddNew.slice(index + 1),
          ]);
          setFlag(!flag);
        }
      } catch (error) {
        if (error.response && error.response.data.message) {
          setError(error.response.data.message);
          setSuccess("");
        } else {
          setError(error.message);
          setSuccess("");
        }
        console.log(error);
      }
      setIsLoading(false);
    }
  };

  const handleChangePriceForSingleProperty = (index, newValue) => {
    const updatedValues = [...pricingData];
    updatedValues[index].price = newValue;
    setPricingData(updatedValues);
    setError("");
    setSuccess("");
  };

  const handleChangePriceForProperties = (index, comboIndex, newValue) => {
    const updatedValues = [...pricingData];
    updatedValues[index] = {
      ...updatedValues[index],
      combination: updatedValues[index].combination.map((comboItem, i) =>
        i === comboIndex ? { ...comboItem, price: newValue } : comboItem
      ),
    };
    setPricingData(updatedValues);
  };

  function createCombinationDetails(combination, propertyData) {
    return Object.keys(combination).map((attribute_id) => {
      const property = propertyData.find(
        (property) => property.id === parseInt(attribute_id)
      );
      const value = property.values.find(
        (val) => val.id === combination[attribute_id]
      );
      return {
        attribute_id,
        attribute_name: property.display_name,
        attribute_value_id: value.id.toString(),
        attribute_value_name: value.attribute_value,
      };
    });
  }

  const handleSubmitPriceForSingleProperty = async () => {
    const allPricesValid = pricingData.every((item) =>
      isValidNumber(item.price)
    );
    if (!allPricesValid) {
      setError("Please enter valid variant prices");
      setSuccess("");
    } else {
      const combinationData = pricingData.map((item) => ({
        combination: { [item.attribute_id]: item.id },
        price: item.price,
      }));

      const modifiedCombinationData = combinationData.map((data) => ({
        ...data,
        combination_details: createCombinationDetails(
          data.combination,
          properties
        ),
      }));

      const dataToSend = {
        product_id: productID,
        combinationData: modifiedCombinationData,
      };

      setIsLoading(true);
      try {
        const response = await axios({
          method: "post",
          url: AddProductPropertyCombinationPriceURL,
          data: dataToSend,
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${token}`,
          },
        });

        if (response.status === 200 && response.data.message) {
          setError("");
          setSuccess(response.data.message);
          setFlag(!flag);
        }
      } catch (error) {
        if (error.response && error.response.data.message) {
          setError(error.response.data.message);
          setSuccess("");
        } else {
          setError(error.message);
          setSuccess("");
        }
        console.log(error);
      }
      setIsLoading(false);
    }
  };

  const handleSubmitPriceForProperties = async () => {
    let allPricesValid = true;

    pricingData.forEach((item) => {
      item.combination.forEach((comboItem) => {
        if (!isValidNumber(comboItem.price)) {
          allPricesValid = false;
        }
      });
    });

    if (!allPricesValid) {
      setError("Please enter valid variant prices");
      setSuccess("");
    } else {
      const combinationData = [];

      pricingData.forEach((item) => {
        item.combination.forEach((combination) => {
          const combinationObj = {
            combination: {
              [item.attribute_id]: item.id,
              [combination.attribute_id]: combination.id,
            },
            price: combination.price,
          };
          combinationData.push(combinationObj);
        });
      });

      const modifiedCombinationData = combinationData.map((data) => ({
        ...data,
        combination_details: createCombinationDetails(
          data.combination,
          properties
        ),
      }));

      const dataToSend = {
        product_id: productID,
        combinationData: modifiedCombinationData,
      };

      setIsLoading(true);
      try {
        const response = await axios({
          method: "post",
          url: AddProductPropertyCombinationPriceURL,
          data: dataToSend,
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${token}`,
          },
        });

        if (response.status === 200 && response.data.message) {
          setError("");
          setSuccess(response.data.message);
          setFlag(!flag);
        }
      } catch (error) {
        if (error.response && error.response.data.message) {
          setError(error.response.data.message);
          setSuccess("");
        } else {
          setError(error.message);
          setSuccess("");
        }
        console.log(error);
      }
      setIsLoading(false);
    }
  };

  /****************************************************************************************/
  function isValidPrice(price) {
    const pricePattern = /^\d+(\.\d{1,2})?$/;

    if (!pricePattern.test(price)) {
      return parseFloat(price).toFixed(2);
    }
    return price;
  }

  const handleAddOnNewGroupButtonClick = () => {
    if (!showAddOnForm) {
      setAddOnNewFormData({
        groupName: "",
        min: "",
        max: "",
        items: [],
      });
      setAddOnNewItemData({
        name: "",
        type: "",
        price: "",
      });
      setShowAddOnForm(true);
    }
  };

  const handleChangeAddOnNewFormData = (e) => {
    const { name, value } = e.target;
    setAddOnNewFormData({
      ...addOnNewFormData,
      [name]: value,
    });
    setError("");
    setSuccess("");
  };

  const handleChangeAddOnNewItemData = (e) => {
    const { name, value } = e.target;
    setAddOnNewItemData({
      ...addOnNewItemData,
      [name]: value,
    });
    setError("");
    setSuccess("");
  };

  const handleAddOnNewItemAddClick = () => {
    if (addOnNewItemData.name === "" || addOnNewItemData.name.trim() === "") {
      setError("Item name can not be empty");
      setSuccess("");
    } else if (
      addOnNewItemData.price === "" ||
      addOnNewItemData.price.trim() === ""
    ) {
      setError("Item price can not be empty");
      setSuccess("");
    } else if (addOnNewItemData.type === "") {
      setError("Item type can not be empty");
      setSuccess("");
    } else if (!isValidNumber(addOnNewItemData.price)) {
      setError("Please enter valid item price");
      setSuccess("");
    } else {
      addOnNewFormData.items.push(addOnNewItemData);
      setAddOnNewItemData({ name: "", type: "", price: "" });
    }
  };

  const handleAddOnNewItemDeleteClick = (indexToDelete) => {
    setAddOnNewFormData((prevFormData) => ({
      ...prevFormData,
      items: prevFormData.items.filter((_, index) => index !== indexToDelete),
    }));
  };

  const handleAddOnNewSaveChangesClick = async () => {
    if (
      addOnNewFormData.groupName === "" ||
      addOnNewFormData.groupName.trim() === ""
    ) {
      setError("Add-on group name can not be empty");
      setSuccess("");
    } else if (addOnNewFormData.min === "") {
      setError("Please enter minimum add-ons customer can choose");
      setSuccess("");
    } else if (!isValidNumber(addOnNewFormData.min)) {
      setError("Please enter valid minimum add-ons customer can choose");
      setSuccess("");
    } else if (addOnNewFormData.max === "") {
      setError("Please enter maximum add-ons customer can choose");
      setSuccess("");
    } else if (!isValidNumber(addOnNewFormData.max)) {
      setError("Please enter valid maximum add-ons customer can choose");
      setSuccess("");
    } else if (addOnNewFormData.items.length === 0) {
      setError("Please add atleast one item to create add-on group");
      setSuccess("");
    } else if (
      parseFloat(addOnNewFormData.min) > addOnNewFormData.items.length
    ) {
      setError("Minimum value can not be greater than total items");
      setSuccess("");
    } else if (
      parseFloat(addOnNewFormData.max) > addOnNewFormData.items.length
    ) {
      setError("Maximum value can not be greater than total items");
      setSuccess("");
    } else {
      const dataToSend = {
        addon_group_name: addOnNewFormData.groupName,
        addon_type: 1,
        minimum_item: addOnNewFormData.min,
        maximum_item: addOnNewFormData.max,
        items: addOnNewFormData.items,
      };

      try {
        const response = await axios({
          method: "post",
          url: CreateAddonGroupURL,
          data: dataToSend,
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${token}`,
          },
        });
        if (response.status === 200 && response.data.message) {
          setShowAddOnForm(false);
          setError("");
          setSuccess("");
          addToast("success", response.data.message);
          setFlag(!flag);
        }
      } catch (error) {
        if (error.response && error.response.data.message) {
          addToast("error", error.response.data.message);
        } else {
          addToast("error", error.message);
        }
        setError("");
        setSuccess("");
        console.log(error);
      }
    }
  };

  const handleDeleteAddOnClick = (e, id) => {
    e.preventDefault();
    setDeleteAddOnId(id);
    setShowDeleteAddOnModal(!showDeleteAddOnModal);
  };

  const handleDeleteAddOn = async () => {
    try {
      const response = await axios({
        method: "delete",
        url: DeleteAddonGroupURL,
        data: { id: deleteAddOnId },
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 200 && response.data.message) {
        setError("");
        setSuccess("");
        addToast("success", response.data.message);
        setFlag(!flag);
        setShowDeleteAddOnModal(!showDeleteAddOnModal);
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        addToast("error", error.response.data.message);
      } else {
        addToast("error", error.message);
      }
      setError("");
      setSuccess("");
      console.log(error);
    }
  };

  const handleVariantCheckboxChange = (e, id) => {
    setModifiedCombinationData((prevData) => {
      return prevData.map((item) => {
        if (item.id === id) {
          item.selected = e.target.checked;
        }
        return item;
      });
    });
  };

  const handleAssignAddOnGroupPlusClick = async (id, name) => {
    if (modifiedCombinationData.length > 0) {
      setAddOnGroupDataId(id);
      setAddOnGroupName(name);
      const data = modifiedCombinationData.map((obj) => {
        return { ...obj, selected: false };
      });
      setModifiedCombinationData(data);
      setIsAaaignAddOnModal(true);
    } else {
      // addToast("error", "Please add varients");
      const dataToSend = {
        product_id: productID,
        menu_id: product.menu_id,
        fooders_addons_id: id,
        fooders_variants_id: 0,
      };

      try {
        const response = await axios({
          method: "post",
          url: AssignAddonGroupToProuductURL,
          data: dataToSend,
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${token}`,
          },
        });
        if (response.status === 200 && response.data.message) {
          setError("");
          setSuccess("");
          addToast("success", response.data.message);
          setFlag(!flag);
        }
      } catch (error) {
        if (error.response && error.response.data.message) {
          addToast("error", error.response.data.message);
        } else {
          addToast("error", error.message);
        }
        setError("");
        setSuccess("");
        console.log(error);
      }
    }
  };

  const handleAssignAddOnGroupSubmitClick = async () => {
    const checkedIds = modifiedCombinationData
      .filter((item) => item.selected)
      .map((item) => item.id);

    if (checkedIds.length === 0) {
      setError("");
      setSuccess("");
      addToast("error", "Please select atleast one variant");
    } else {
      const dataToSend = {
        product_id: productID,
        menu_id: product.menu_id,
        fooders_addons_id: addOnGroupDataId,
        fooders_variants_id: checkedIds,
      };

      try {
        const response = await axios({
          method: "post",
          url: AssignAddonGroupToProuductURL,
          data: dataToSend,
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${token}`,
          },
        });
        if (response.status === 200 && response.data.message) {
          setIsAaaignAddOnModal(false);
          setError("");
          setSuccess("");
          addToast("success", response.data.message);
          setFlag(!flag);
        }
      } catch (error) {
        if (error.response && error.response.data.message) {
          addToast("error", error.response.data.message);
        } else {
          addToast("error", error.message);
        }
        setError("");
        setSuccess("");
        console.log(error);
      }
    }
  };

  const handleDeleteAssignAddOnClick = (e, id) => {
    e.preventDefault();
    setDeleteAssignAddOnId([id]);
    setShowDeleteAssignAddOnModal(!showDeleteAssignAddOnModal);
  };

  const handleDeleteAssignAddOn = async () => {
    try {
      const response = await axios({
        method: "delete",
        url: DeleteAssignAddonGroupURL,
        data: deleteAssignAddOnId,
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 200 && response.data.message) {
        setError("");
        setSuccess("");
        addToast("success", response.data.message);
        setFlag(!flag);
        setShowDeleteAssignAddOnModal(!showDeleteAssignAddOnModal);
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        addToast("error", error.response.data.message);
      } else {
        addToast("error", error.message);
      }
      setError("");
      setSuccess("");
      console.log(error);
    }
  };

  /****************************************************************************************/

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/");
    }
  }, [navigate]);

  useEffect(() => {
    setToken(localStorage.getItem("token"));
  }, [token]);

  useEffect(() => {
    if (localStorage.getItem("currencySymbol")) {
      setCurrencySymbol(localStorage.getItem("currencySymbol"));
    }
  }, []);

  useEffect(() => {
    const existingScript = document.getElementById(ScriptId);
    if (!existingScript && localStorage.getItem("userId")) {
      const userId = localStorage.getItem("userId");
      const script = document.createElement("script");
      script.src = ScriptSRC + userId.replace(/"/g, "");
      script.defer = true;
      script.id = ScriptId;
      document.head.appendChild(script);
    }
  }, []);

  useEffect(() => {
    setProductID(localStorage.getItem("productID"));
  }, [productID]);

  useEffect(() => {
    if (error || success) {
      window.scrollTo(0, 0);
    }
  }, [error, success]);

  useEffect(() => {
    if (token && productID) {
      async function getData() {
        setIsLoading(true);
        try {
          const response = await axios.get(
            GetEditDishesProductURL + `?id=${productID}`,
            {
              headers: { authorization: `Bearer ${token}` },
            }
          );

          if (response.status === 200 && response.data.data) {
            setProduct({
              menu_id: response.data.data.menu_id,
              name: response.data.data.product_name,
              price: response.data.data.price,
              proprice: response.data.data.proprice,
              tax: response.data.data.tax_percent,
              tax_type: response.data.data.tax_type,
              status: response.data.data.status === 1 ? true : false,
              picture: response.data.data.picture,
              product_type: response.data.data.product_type,
              top_rated: response.data.data.top_rated === 1 ? true : false,
              best_seller: response.data.data.best_seller === 1 ? true : false,
              most_ordered:
                response.data.data.most_ordered === 1 ? true : false,
              dine_in: response.data.data.dine_in_service === 1 ? true : false,
              delivery:
                response.data.data.delivery_service === 1 ? true : false,
              pick_up: response.data.data.pick_up_service === 1 ? true : false,
              feature: response.data.data.feature === 1 ? true : false,
              preparation_time: response.data.data.preparation_time,
              description: response.data.data.description,
              variant1: response.data.data.variant1 || "",
              variant2: response.data.data.variant2 || "",
              variant3: response.data.data.variant3 || "",
              variant4: response.data.data.variant4 || "",
            });
            setProperties(response.data.property ? response.data.property : []);
            setPricingCombinationData(
              response.data.combinationData ? response.data.combinationData : []
            );
            setProductBasedAddonsData(
              response.data.productBasedAddons
                ? response.data.productBasedAddons
                : []
            );
            setPricingData([]);
          }
        } catch (error) {
          if (error.response && error.response.data.message) {
            addToast("error", error.response.data.message);
          } else {
            addToast("error", error.message);
          }
          setError("");
          setSuccess("");
          console.log(error);
        }
        setIsLoading(false);
      }
      getData();
    }
  }, [token, productID, flag, addToast]);

  useEffect(() => {
    if (token) {
      async function getData() {
        setIsLoading(true);
        try {
          const response = await axios.get(GetAddonGroupListURL, {
            headers: { authorization: `Bearer ${token}` },
          });

          if (response.status === 200 && response.data.data) {
            setData(response.data.data);
          } else if (response.status === 200 && response.data.message) {
            setData([]);
          }
        } catch (error) {
          if (error.response && error.response.data.message) {
            addToast("error", error.response.data.message);
          } else {
            addToast("error", error.message);
          }
          setError("");
          setSuccess("");
          console.log(error);
        }
        setIsLoading(false);
      }
      getData();
    }
  }, [token, flag, addToast]);

  useEffect(() => {
    if (properties.length === 2) {
      if (pricingCombinationData.length > 0) {
        const modifiedProperty = properties[0].values.map((sizeValue) => {
          return {
            ...sizeValue,
            combination: properties[1].values.map((layerValue) => ({
              ...layerValue,
              price:
                pricingCombinationData.find(
                  (item) =>
                    item.combination[properties[0].id.toString()] ===
                    sizeValue.id.toString() &&
                    item.combination[properties[1].id.toString()] ===
                    layerValue.id.toString()
                )?.price || layerValue.price,
            })),
          };
        });

        setPricingData(modifiedProperty);
      } else {
        const modifiedProperty = properties[0].values;
        modifiedProperty.forEach((sizeValue) => {
          sizeValue.combination = properties[1].values;
        });
        setPricingData(modifiedProperty);
      }
    } else if (properties.length === 1) {
      if (pricingCombinationData.length > 0) {
        const modifiedProperty = properties[0].values;

        const updatedData = modifiedProperty.map((item) => {
          const priceToUpdate = pricingCombinationData.find(
            (updatedItem) =>
              updatedItem.combination[item.attribute_id.toString()] ===
              item.id.toString()
          );
          if (priceToUpdate) {
            item.price = priceToUpdate.price;
          }
          return item;
        });

        setPricingData(updatedData);
      } else {
        const modifiedProperty = properties[0].values;
        setPricingData(modifiedProperty);
      }
    }
  }, [properties, pricingCombinationData]);

  useEffect(() => {
    if (properties && pricingCombinationData) {
      function generateModifiedData(combinationData, property) {
        return combinationData.map((combination) => {
          const names = Object.entries(combination.combination).map(
            ([propertyId, valueId]) => {
              const prop = property.find(
                (prop) => prop.id.toString() === propertyId
              );
              if (prop) {
                const value = prop.values.find(
                  (val) => val.id.toString() === valueId
                );
                if (value) {
                  return `${prop.display_name} (${value.attribute_value})`;
                }
              }
              return null;
            }
          );

          const name = names.join(" - ");

          return {
            ...combination,
            name: name,
          };
        });
      }

      const finalData = generateModifiedData(
        pricingCombinationData,
        properties
      );

      setModifiedCombinationData(finalData);
    }
  }, [properties, pricingCombinationData]);

  return (
    <>
      {token && (
        <>
          <div
            className={
              toggleSideBar
                ? "container-scroller sidebar-icon-only"
                : "container-scroller"
            }
          >
            <nav className="navbar default-layout col-lg-12 col-12 p-0 fixed-top d-flex align-items-top flex-row">
              <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-start">
                <Link className="navbar-brand brand-logo-mini" to="/live_orders">
                  <img src="images/mini-kr-logo.svg" alt="logo" />
                </Link>
                <div className="me-1">
                  <button
                    className={
                      toggleSideBar
                        ? "navbar-toggler navbar-toggler align-self-center"
                        : "navbar-toggler navbar-toggler align-self-center text-white"
                    }
                    type="button"
                    onClick={handleToggleSidebar}
                  >
                    <span className="icon-menu"></span>
                  </button>
                </div>
                <div>
                  <Link to="/live_orders">
                    <span className="navbar-brand brand-logo">
                      <img src="images/Khateraho_logo_white.png" alt="logo" />
                    </span>
                  </Link>
                </div>
              </div>
              <NavBar handleToggle={handleToggleOffCanvas} />
            </nav>
            <div className="container-fluid page-body-wrapper px-0">
              <SideBar active="view_products" toggleCanvas={toggleSideBar} />

              <div className="main-panel">
                <div className="content-wrapper">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="d-sm-flex justify-content-between align-items-start mb-2">
                        <div className="vsalign">
                          <h3 className="ukhd mb-3">
                            Create Customization For{" "}
                            {product && product.name && product.name}{" "}
                          </h3>
                        </div>
                        <div className="vsalign">
                          <span
                            className="btn btn-danger mb-0 me-0"
                            onClick={handleDishesProductsClick}
                          >
                            <i className="menu-icon mdi mdi-arrow-left vsalign"></i>
                            Go Back
                          </span>
                        </div>
                      </div>
                      <div className="row flex-grow">
                        <div className="col-12 grid-margin stretch-card">
                          <div className="card card-rounded">
                            <div className="card-body">
                              {error && (
                                <div className="d-flex justify-content-between align-items-start alert alert-danger">
                                  <div className="vsalign">{error}</div>
                                  <div className="vsalign">
                                    <span
                                      className=".h3 text-muted"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => setError("")}
                                    >
                                      x
                                    </span>
                                  </div>
                                </div>
                              )}
                              {success && (
                                <div className="d-flex justify-content-between align-items-start alert alert-success">
                                  <div className="vsalign">{success}</div>
                                  <div className="vsalign">
                                    <span
                                      className=".h3 text-muted"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => setSuccess("")}
                                    >
                                      x
                                    </span>
                                  </div>
                                </div>
                              )}
                              <div className="home-tab">
                                <div className="d-sm-flex align-items-center justify-content-between border-bottom">
                                  <ul className="nav nav-tabs" role="tablist">
                                    <li
                                      className="nav-item"
                                      role="presentation"
                                    >
                                      <a
                                        className="nav-link ps-0 active"
                                        data-bs-toggle="tab"
                                        href="#properties"
                                        role="tab"
                                        aria-controls="properties"
                                        aria-selected="true"
                                      >
                                        Properties({properties.length})
                                      </a>
                                    </li>
                                    <li
                                      className="nav-item"
                                      role="presentation"
                                    >
                                      <a
                                        className="nav-link"
                                        data-bs-toggle="tab"
                                        href="#addon"
                                        role="tab"
                                        aria-selected="false"
                                        tabIndex="-1"
                                      >
                                        Add-Ons
                                      </a>
                                    </li>
                                  </ul>
                                  <div></div>
                                </div>
                                <div className="tab-content tab-content-basic">
                                  <div
                                    className="tab-pane fade active show accordvrt"
                                    id="properties"
                                    role="tabpanel"
                                    aria-labelledby="properties"
                                  >
                                    <div className="ordertbl">
                                      <div className="row">
                                        <div className="col-md-7">
                                          <h4 className="card-title-dash mb-4">
                                            {" "}
                                            Variants of this items can be create
                                            by defining its properties below
                                          </h4>
                                          <div className="row">
                                            {properties.map((item, index) => (
                                              <div
                                                key={index}
                                                className="col-6 grid-margin"
                                              >
                                                <div className="card card-rounded">
                                                  <div className="card-body vrtcrd">
                                                    <div className="col-12 d-flex align-items-center justify-content-between mb-3">
                                                      <div className="col-10">
                                                        <i className="mdi mdi-apps menu-icon vsalign"></i>
                                                        &nbsp;&nbsp;
                                                        <b>
                                                          {item.display_name}
                                                        </b>
                                                        ({item.values.length}{" "}
                                                        Variants)
                                                      </div>
                                                      <div className="col-2 text-right">
                                                        <i
                                                          className="fs-20 mdi mdi-archive text-danger hrddlt"
                                                          title="Delete"
                                                          style={{
                                                            cursor: "pointer",
                                                          }}
                                                          onClick={(e) =>
                                                            handleDeleteProperty(
                                                              e,
                                                              item.id
                                                            )
                                                          }
                                                        ></i>
                                                      </div>
                                                    </div>
                                                    <div>
                                                      {item.values.map(
                                                        (subItem, index) => (
                                                          <div
                                                            key={index}
                                                            className="input-group mb-2"
                                                          >
                                                            <input
                                                              type="text"
                                                              className="form-control"
                                                              placeholder="Value"
                                                              value={
                                                                subItem.attribute_value
                                                              }
                                                              readOnly={true}
                                                            />
                                                            <div className="input-group-append">
                                                              <span className="input-group-text">
                                                                <i
                                                                  className="fs-20 mdi mdi-archive text-danger"
                                                                  title="Delete"
                                                                  style={{
                                                                    cursor:
                                                                      "pointer",
                                                                  }}
                                                                  onClick={(
                                                                    e
                                                                  ) =>
                                                                    handleDelete(
                                                                      e,
                                                                      subItem.id,
                                                                      item
                                                                    )
                                                                  }
                                                                ></i>
                                                              </span>
                                                            </div>
                                                          </div>
                                                        )
                                                      )}
                                                    </div>
                                                    <div>
                                                      {showAddNew[index] ? (
                                                        <>
                                                          <div className="input-group mb-2">
                                                            <input
                                                              type="text"
                                                              className="form-control"
                                                              placeholder="Enter New Value"
                                                              value={
                                                                newAttribute
                                                              }
                                                              onChange={(e) => {
                                                                setError("");
                                                                setSuccess("");
                                                                setNewAttribute(
                                                                  e.target.value
                                                                );
                                                              }}
                                                            />
                                                          </div>
                                                          <button
                                                            type="button"
                                                            className="btn btn-outline-info btn-fw col-12"
                                                            onClick={(e) => {
                                                              e.preventDefault();
                                                              handleAddNewAttribute(
                                                                item.id,
                                                                index
                                                              );
                                                            }}
                                                          >
                                                            <i className="fs-20 mdi mdi-plus vsalign"></i>
                                                            &nbsp;Save New{" "}
                                                            {item.display_name}
                                                          </button>
                                                        </>
                                                      ) : (
                                                        <button
                                                          type="button"
                                                          className="btn btn-outline-info btn-fw mt-2 col-12"
                                                          onClick={() =>
                                                            handleAddNewClick(
                                                              index
                                                            )
                                                          }
                                                        >
                                                          <i className="fs-20 mdi mdi-plus vsalign"></i>
                                                          &nbsp;Add New{" "}
                                                          {item.display_name}
                                                        </button>
                                                      )}
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            ))}

                                            {properties.length < 2 && (
                                              <div className="col-6 grid-margin">
                                                {showAddNewProperty ? (
                                                  <div className="card card-rounded">
                                                    <div className="card-body vrtcrd">
                                                      <div className="col-12 d-flex align-items-center justify-content-between mb-3">
                                                        <div className="col-10">
                                                          <div className="input-group mb-2">
                                                            <input
                                                              type="text"
                                                              className="form-control"
                                                              placeholder="Property"
                                                              name="property_name"
                                                              value={
                                                                newPropertyDetails.property_name
                                                              }
                                                              onChange={
                                                                handleNewPropetyDetailsChange
                                                              }
                                                            />
                                                          </div>
                                                        </div>
                                                        <div className="col-2 text-right">
                                                          <i
                                                            className="fs-20 mdi mdi-archive text-danger hrddlt"
                                                            title="Delete"
                                                            style={{
                                                              cursor: "pointer",
                                                            }}
                                                            onClick={(e) => {
                                                              e.preventDefault();
                                                              setShowAddNewProperty(
                                                                false
                                                              );
                                                            }}
                                                          ></i>
                                                        </div>
                                                      </div>
                                                      <div>
                                                        <div className="input-group mb-2">
                                                          <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Value 1"
                                                            name="value1"
                                                            value={
                                                              newPropertyDetails.value1
                                                            }
                                                            onChange={
                                                              handleNewPropetyDetailsChange
                                                            }
                                                          />
                                                        </div>
                                                        <div className="input-group mb-2">
                                                          <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Value 2"
                                                            name="value2"
                                                            value={
                                                              newPropertyDetails.value2
                                                            }
                                                            onChange={
                                                              handleNewPropetyDetailsChange
                                                            }
                                                          />
                                                        </div>
                                                      </div>
                                                      <div>
                                                        <button
                                                          type="button"
                                                          className="btn btn-outline-info btn-fw mt-2 col-12"
                                                          onClick={(e) => {
                                                            e.preventDefault();
                                                            handleAddNewProperty();
                                                          }}
                                                        >
                                                          <i className="fs-20 mdi mdi-plus vsalign"></i>
                                                          &nbsp;Add New Property
                                                        </button>
                                                      </div>
                                                    </div>
                                                  </div>
                                                ) : (
                                                  <div>
                                                    <button
                                                      type="button"
                                                      className="btn btn-outline-info btn-fw mt-2 col-12"
                                                      onClick={(e) => {
                                                        e.preventDefault();
                                                        setShowAddNewProperty(
                                                          true
                                                        );
                                                        setNewPropertyDetails({
                                                          property_name: "",
                                                          value1: "",
                                                          value2: "",
                                                        });
                                                      }}
                                                    >
                                                      <i className="fs-20 mdi mdi-plus vsalign"></i>
                                                      &nbsp;Add New Property
                                                    </button>
                                                  </div>
                                                )}
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                        {properties.length !== 0 && (
                                          <div className="col-md-5">
                                            <h4 className="card-title-dash mb-3">
                                              {" "}
                                              Variant Pricing
                                            </h4>
                                            <h5>Enter Variant pricing here</h5>

                                            <div className="mb-3 mt-4 proscroll">
                                              <>
                                                {properties.length === 1 && (
                                                  <div className="accordion-body">
                                                    <div className="row mb-2  mr-3">
                                                      <div className="valign col-6">
                                                        <h5>Type </h5>
                                                      </div>
                                                      <div className="vsalign  col-4">
                                                        <h5 className="">
                                                          Price (
                                                          {currencySymbol})
                                                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                        </h5>
                                                      </div>
                                                      <div className="text-right col-2"></div>
                                                    </div>
                                                    {pricingData &&
                                                      pricingData.map(
                                                        (item, index) => (
                                                          <div
                                                            key={index}
                                                            className="row mb-2 varwdt"
                                                          >
                                                            <div className="col-6">
                                                              <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder=""
                                                                value={
                                                                  item.attribute_value
                                                                }
                                                                readOnly={true}
                                                              />
                                                            </div>
                                                            <div className="col-4">
                                                              <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="0"
                                                                value={
                                                                  item.price
                                                                }
                                                                onChange={(e) =>
                                                                  handleChangePriceForSingleProperty(
                                                                    index,
                                                                    e.target
                                                                      .value
                                                                  )
                                                                }
                                                              />
                                                            </div>
                                                            <div className="col-2 vsalign">
                                                              {isValidNumber(
                                                                item.price
                                                              ) && (
                                                                  <i className="fs-25 mdi mdi-check-circle-outline text-success vsalign"></i>
                                                                )}
                                                              {!isValidNumber(
                                                                item.price
                                                              ) && (
                                                                  <i className="fs-25 mdi mdi-close-circle-outline text-danger vsalign"></i>
                                                                )}
                                                            </div>
                                                          </div>
                                                        )
                                                      )}

                                                    <button
                                                      type="button"
                                                      className="btn btn-outline-info btn-fw col-12"
                                                      onClick={(e) => {
                                                        e.preventDefault();
                                                        handleSubmitPriceForSingleProperty();
                                                      }}
                                                    >
                                                      <i className="fs-20 mdi mdi-plus vsalign"></i>
                                                      &nbsp;Save
                                                    </button>
                                                  </div>
                                                )}
                                              </>
                                              <div
                                                className="accordion"
                                                id="accordionPanelsStayOpenExample"
                                              >
                                                <>
                                                  {properties &&
                                                    properties?.length ===
                                                    2 && (
                                                      <>
                                                        {pricingData &&
                                                          pricingData.map(
                                                            (item, index) => (
                                                              <div
                                                                key={index}
                                                                className="accordion-item mb-0"
                                                              >
                                                                <h2
                                                                  className="accordion-header"
                                                                  id={`panelsStayOpen-heading${index}`}
                                                                >
                                                                  <button
                                                                    className="accordion-button"
                                                                    type="button"
                                                                    data-bs-toggle="collapse"
                                                                    data-bs-target={`#panelsStayOpen-collapse${index}`}
                                                                    aria-expanded="true"
                                                                    aria-controls={`panelsStayOpen-collapse${index}`}
                                                                  >
                                                                    <b>
                                                                      {
                                                                        item.attribute_value
                                                                      }
                                                                    </b>
                                                                    ({" "}
                                                                    {
                                                                      item
                                                                        ?.combination
                                                                        ?.length
                                                                    }{" "}
                                                                    Variant)
                                                                  </button>
                                                                </h2>
                                                                <div
                                                                  id={`panelsStayOpen-collapse${index}`}
                                                                  className="accordion-collapse collapse show"
                                                                  aria-labelledby={`panelsStayOpen-heading${index}`}
                                                                >
                                                                  <div className="accordion-body">
                                                                    <div className="row mb-2  mr-3">
                                                                      <div className="valign col-6">
                                                                        <h5>
                                                                          Type{" "}
                                                                        </h5>
                                                                      </div>
                                                                      <div className="vsalign  col-4">
                                                                        <h5 className="">
                                                                          Price
                                                                          (
                                                                          {
                                                                            currencySymbol
                                                                          }
                                                                          )
                                                                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                                        </h5>
                                                                      </div>
                                                                      <div className="text-right col-2"></div>
                                                                    </div>
                                                                    {item.combination.map(
                                                                      (
                                                                        comboItem,
                                                                        comboIndex
                                                                      ) => (
                                                                        <div
                                                                          key={
                                                                            comboIndex
                                                                          }
                                                                          className="row mb-2 varwdt"
                                                                        >
                                                                          <div className="col-6">
                                                                            <input
                                                                              type="text"
                                                                              className="form-control"
                                                                              placeholder=""
                                                                              value={
                                                                                comboItem.attribute_value
                                                                              }
                                                                              readOnly={
                                                                                true
                                                                              }
                                                                            />
                                                                          </div>
                                                                          <div className="col-4">
                                                                            <input
                                                                              type="text"
                                                                              className="form-control"
                                                                              placeholder=""
                                                                              value={
                                                                                comboItem.price
                                                                              }
                                                                              onChange={(
                                                                                e
                                                                              ) =>
                                                                                handleChangePriceForProperties(
                                                                                  index,
                                                                                  comboIndex,
                                                                                  e
                                                                                    .target
                                                                                    .value
                                                                                )
                                                                              }
                                                                            />
                                                                          </div>
                                                                          <div className="col-2 vsalign">
                                                                            {isValidNumber(
                                                                              comboItem.price
                                                                            ) && (
                                                                                <i className="fs-25 mdi mdi-check-circle-outline text-success vsalign"></i>
                                                                              )}
                                                                            {!isValidNumber(
                                                                              comboItem.price
                                                                            ) && (
                                                                                <i className="fs-25 mdi mdi-close-circle-outline text-danger vsalign"></i>
                                                                              )}
                                                                          </div>
                                                                        </div>
                                                                      )
                                                                    )}
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            )
                                                          )}
                                                        <button
                                                          type="button"
                                                          className="btn btn-outline-info btn-fw col-12"
                                                          onClick={(e) => {
                                                            e.preventDefault();
                                                            handleSubmitPriceForProperties();
                                                          }}
                                                        >
                                                          <i className="fs-20 mdi mdi-plus vsalign"></i>
                                                          &nbsp;Save
                                                        </button>
                                                      </>
                                                    )}
                                                </>
                                                {/* <div className="accordion-item mb-3">
                                                    <h2
                                                      className="accordion-header"
                                                      id="panelsStayOpen-headingOne"
                                                    >
                                                      <button
                                                        className="accordion-button"
                                                        type="button"
                                                        data-bs-toggle="collapse"
                                                        data-bs-target="#panelsStayOpen-collapseOne"
                                                        aria-expanded="true"
                                                        aria-controls="panelsStayOpen-collapseOne"
                                                      >
                                                        <b>6 Inches </b>( 2
                                                        Variant)
                                                      </button>
                                                    </h2>
                                                    <div
                                                      id="panelsStayOpen-collapseOne"
                                                      className="accordion-collapse collapse show"
                                                      aria-labelledby="panelsStayOpen-headingOne"
                                                    >
                                                      <div className="accordion-body">
                                                        <div className="row mb-2  mr-3">
                                                          <div className="valign col-6">
                                                            <h5>Type </h5>
                                                          </div>
                                                          <div className="vsalign  col-4">
                                                            <h5 className="">
                                                              Price
                                                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                            </h5>
                                                          </div>
                                                          <div className="text-right col-2"></div>
                                                        </div>
                                                        <div className="row mb-2 varwdt">
                                                          <div className="col-6">
                                                            <input
                                                              type="text"
                                                              className="form-control"
                                                              placeholder="Chinese"
                                                              value="Chinese"
                                                            />
                                                          </div>
                                                          <div className="col-4">
                                                            <input
                                                              type="text"
                                                              className="form-control"
                                                              placeholder=""
                                                              value="$4220"
                                                            />
                                                          </div>
                                                          <div className="col-2 vsalign">
                                                            <i className="fs-25 mdi mdi-check-circle-outline text-success vsalign"></i>
                                                          </div>
                                                        </div>
  
                                                        <div className="row mb-2 varwdt">
                                                          <div className="col-6">
                                                            <input
                                                              type="text"
                                                              className="form-control"
                                                              placeholder="Thin Crust"
                                                              value="Thin Crust"
                                                            />
                                                          </div>
                                                          <div className="col-4">
                                                            <input
                                                              type="text"
                                                              className="form-control"
                                                              placeholder=""
                                                              value="$4220"
                                                            />
                                                          </div>
                                                          <div className="col-2 vsalign">
                                                            <i className="fs-25 mdi mdi-close-circle-outline text-danger vsalign"></i>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div> */}
                                              </div>
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className="tab-pane fade addon accordvrt1"
                                    id="addon"
                                    role="tabpanel"
                                    aria-labelledby="addon"
                                  >
                                    <div className="row">
                                      <div className="col-md-6">
                                        {productBasedAddonsData &&
                                          productBasedAddonsData.length > 0 && (
                                            <>
                                              <div className="bordercss mb-2">
                                                <h4 className="mb-2">
                                                  Product Based Add-ons
                                                </h4>
                                                <hr />
                                                <div className="bordercss mb-2">
                                                  {productBasedAddonsData &&
                                                    productBasedAddonsData.map(
                                                      (item, index) => (
                                                        <React.Fragment
                                                          key={index}
                                                        >
                                                          <div className="col-12 d-flex align-items-center justify-content-between">
                                                            <div className="col-10">
                                                              <h5>
                                                                {
                                                                  item.addon_group_name
                                                                }
                                                              </h5>
                                                            </div>
                                                            <div className="col-2 text-right">
                                                              <i
                                                                className="fs-20 mdi mdi-archive text-danger vsalign"
                                                                title="Delete"
                                                                style={{
                                                                  cursor:
                                                                    "pointer",
                                                                }}
                                                                onClick={(e) =>
                                                                  handleDeleteAssignAddOnClick(
                                                                    e,
                                                                    item.id
                                                                  )
                                                                }
                                                              ></i>
                                                            </div>
                                                          </div>
                                                          {index !==
                                                            productBasedAddonsData.length -
                                                            1 && <hr />}
                                                        </React.Fragment>
                                                      )
                                                    )}
                                                </div>
                                              </div>
                                            </>
                                          )}

                                        {modifiedCombinationData &&
                                          modifiedCombinationData.length > 0 &&
                                          modifiedCombinationData.some(
                                            (item) =>
                                              item.addons &&
                                              item.addons.length > 0
                                          ) && (
                                            <>
                                              <div className="bordercss mb-2">
                                                <h4 className="mb-2">
                                                  Properties Based Add-ons
                                                </h4>
                                                <hr />
                                                {modifiedCombinationData &&
                                                  modifiedCombinationData.map(
                                                    (item, index) => (
                                                      <React.Fragment
                                                        key={index}
                                                      >
                                                        {item.addons &&
                                                          item.addons.length >
                                                          0 && (
                                                            <>
                                                              <h5 className="mb-2">
                                                                {item.name}
                                                              </h5>
                                                              <div className="bordercss mb-2">
                                                                {item.addons.map(
                                                                  (
                                                                    addOn,
                                                                    subIndex
                                                                  ) => (
                                                                    <React.Fragment
                                                                      key={
                                                                        subIndex
                                                                      }
                                                                    >
                                                                      <div className="col-12 d-flex align-items-center justify-content-between">
                                                                        <div className="col-10">
                                                                          <h5>
                                                                            {
                                                                              addOn.addon_group_name
                                                                            }
                                                                          </h5>
                                                                        </div>
                                                                        <div className="col-2 text-right">
                                                                          <i
                                                                            className="fs-20 mdi mdi-archive text-danger vsalign"
                                                                            title="Delete"
                                                                            style={{
                                                                              cursor:
                                                                                "pointer",
                                                                            }}
                                                                            onClick={(
                                                                              e
                                                                            ) =>
                                                                              handleDeleteAssignAddOnClick(
                                                                                e,
                                                                                addOn.id
                                                                              )
                                                                            }
                                                                          ></i>
                                                                        </div>
                                                                      </div>
                                                                      {subIndex !==
                                                                        item
                                                                          .addons
                                                                          .length -
                                                                        1 && (
                                                                          <hr />
                                                                        )}
                                                                    </React.Fragment>
                                                                  )
                                                                )}
                                                              </div>
                                                            </>
                                                          )}
                                                      </React.Fragment>
                                                    )
                                                  )}
                                              </div>
                                            </>
                                          )}

                                        {/* <div className="scrollvrt">
                                            <div
                                              className="accordion"
                                              id="accordionPanelsStayOpenExample"
                                            >
                                              <div className="accordion-item mb-3">
                                                <h2
                                                  className="accordion-header"
                                                  id="panelsStayOpen-headingOne"
                                                >
                                                  <button
                                                    className="accordion-button"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseOne"
                                                    aria-expanded="true"
                                                    aria-controls="panelsStayOpen-collapseOne"
                                                  >
                                                    <b>12 Inches </b>( 3 Variant)
                                                  </button>
                                                </h2>
                                                <div
                                                  id="panelsStayOpen-collapseOne"
                                                  className="accordion-collapse collapse show"
                                                  aria-labelledby="panelsStayOpen-headingOne"
                                                >
                                                  <div className="accordion-body">
                                                    <h6 className="mb-2">Type</h6>
  
                                                    <div className="row mb-2 varwdt">
                                                      <div className="col">
                                                        <input
                                                          type="text"
                                                          className="form-control"
                                                          placeholder="Chinese"
                                                          value="Chinese"
                                                        />
                                                      </div>
                                                      <div className="col">
                                                        <div className="dropdown">
                                                          <button
                                                            className="btn btn-outline-info btn-fw col-12 dropdown-toggle"
                                                            type="button"
                                                            id="dropdownMenuButton1"
                                                            data-bs-toggle="dropdown"
                                                            aria-expanded="false"
                                                          >
                                                            Add Ons&nbsp;(1)
                                                          </button>
                                                          <ul
                                                            className="dropdown-menu"
                                                            aria-labelledby="dropdownMenuButton1"
                                                          >
                                                            <li>
                                                              <span class="dropdown-item">
                                                                Action
                                                              </span>
                                                            </li>
                                                            <li>
                                                              <span class="dropdown-item">
                                                                Another action
                                                              </span>
                                                            </li>
                                                            <li>
                                                              <span class="dropdown-item">
                                                                Something else
                                                                here
                                                              </span>
                                                            </li>
                                                          </ul>
                                                        </div>
                                                      </div>
                                                      <div className="col">
                                                        <div className="dropdown">
                                                          <button
                                                            className="btn btn-outline-info btn-fw col-12 dropdown-toggle "
                                                            type="button"
                                                            id="dropdownMenuButton1"
                                                            data-bs-toggle="dropdown"
                                                            aria-expanded="false"
                                                          >
                                                            Drinks&nbsp;(4)
                                                          </button>
                                                          <ul
                                                            className="dropdown-menu"
                                                            aria-labelledby="dropdownMenuButton1"
                                                          >
                                                            <li>
                                                              <span class="dropdown-item">
                                                                Action
                                                              </span>
                                                            </li>
                                                            <li>
                                                              <span class="dropdown-item">
                                                                Another action
                                                              </span>
                                                            </li>
                                                            <li>
                                                              <span class="dropdown-item">
                                                                Something else
                                                                here
                                                              </span>
                                                            </li>
                                                          </ul>
                                                        </div>
                                                      </div>
                                                      <div className="col">
                                                        <button
                                                          type="button"
                                                          className="btn btn-outline-info btn-fw col-12"
                                                        >
                                                          <i className="fs-20 mdi mdi-plus vsalign"></i>
                                                          &nbsp;Add Ons
                                                        </button>
                                                      </div>
                                                    </div>
  
                                                    <div className="row mb-2 varwdt">
                                                      <div className="col">
                                                        <input
                                                          type="text"
                                                          className="form-control"
                                                          placeholder="Thin Crust"
                                                          value="Thin Crust"
                                                        />
                                                      </div>
                                                      <div className="col">
                                                        <div className="dropdown">
                                                          <button
                                                            className="btn btn-outline-info btn-fw col-12 dropdown-toggle"
                                                            type="button"
                                                            id="dropdownMenuButton1"
                                                            data-bs-toggle="dropdown"
                                                            aria-expanded="false"
                                                          >
                                                            Add Ons&nbsp;(1)
                                                          </button>
                                                          <ul
                                                            className="dropdown-menu"
                                                            aria-labelledby="dropdownMenuButton1"
                                                          >
                                                            <li>
                                                              <span class="dropdown-item">
                                                                Action
                                                              </span>
                                                            </li>
                                                            <li>
                                                              <span class="dropdown-item">
                                                                Another action
                                                              </span>
                                                            </li>
                                                            <li>
                                                              <span class="dropdown-item">
                                                                Something else
                                                                here
                                                              </span>
                                                            </li>
                                                          </ul>
                                                        </div>
                                                      </div>
                                                      <div className="col">
                                                        <div className="dropdown">
                                                          <button
                                                            className="btn btn-outline-info btn-fw col-12 dropdown-toggle "
                                                            type="button"
                                                            id="dropdownMenuButton1"
                                                            data-bs-toggle="dropdown"
                                                            aria-expanded="false"
                                                          >
                                                            Drinks&nbsp;(4)
                                                          </button>
                                                          <ul
                                                            className="dropdown-menu"
                                                            aria-labelledby="dropdownMenuButton1"
                                                          >
                                                            <li>
                                                              <span class="dropdown-item">
                                                                Action
                                                              </span>
                                                            </li>
                                                            <li>
                                                              <span class="dropdown-item">
                                                                Another action
                                                              </span>
                                                            </li>
                                                            <li>
                                                              <span class="dropdown-item">
                                                                Something else
                                                                here
                                                              </span>
                                                            </li>
                                                          </ul>
                                                        </div>
                                                      </div>
                                                      <div className="col">
                                                        <button
                                                          type="button"
                                                          className="btn btn-outline-info btn-fw col-12"
                                                        >
                                                          <i className="fs-20 mdi mdi-plus vsalign"></i>
                                                          &nbsp;Add Ons
                                                        </button>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="accordion-item mb-3">
                                                <h2
                                                  className="accordion-header"
                                                  id="panelsStayOpen-headingOne"
                                                >
                                                  <button
                                                    className="accordion-button"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseOne"
                                                    aria-expanded="true"
                                                    aria-controls="panelsStayOpen-collapseOne"
                                                  >
                                                    <b>9 Inches </b>( 2 Variant)
                                                  </button>
                                                </h2>
                                                <div
                                                  id="panelsStayOpen-collapseOne"
                                                  className="accordion-collapse collapse show"
                                                  aria-labelledby="panelsStayOpen-headingOne"
                                                >
                                                  <div className="accordion-body">
                                                    <h6 className="mb-2">Type</h6>
  
                                                    <div className="row mb-2 varwdt">
                                                      <div className="col">
                                                        <input
                                                          type="text"
                                                          className="form-control"
                                                          placeholder="Chinese"
                                                          value="Chinese"
                                                        />
                                                      </div>
                                                      <div className="col">
                                                        <div className="dropdown">
                                                          <button
                                                            className="btn btn-outline-info btn-fw col-12 dropdown-toggle"
                                                            type="button"
                                                            id="dropdownMenuButton1"
                                                            data-bs-toggle="dropdown"
                                                            aria-expanded="false"
                                                          >
                                                            Add Ons&nbsp;(1)
                                                          </button>
                                                          <ul
                                                            className="dropdown-menu"
                                                            aria-labelledby="dropdownMenuButton1"
                                                          >
                                                            <li>
                                                              <span class="dropdown-item">
                                                                Action
                                                              </span>
                                                            </li>
                                                            <li>
                                                              <span class="dropdown-item">
                                                                Another action
                                                              </span>
                                                            </li>
                                                            <li>
                                                              <span class="dropdown-item">
                                                                Something else
                                                                here
                                                              </span>
                                                            </li>
                                                          </ul>
                                                        </div>
                                                      </div>
                                                      <div className="col">
                                                        <div className="dropdown">
                                                          <button
                                                            className="btn btn-outline-info btn-fw col-12 dropdown-toggle "
                                                            type="button"
                                                            id="dropdownMenuButton1"
                                                            data-bs-toggle="dropdown"
                                                            aria-expanded="false"
                                                          >
                                                            Drinks&nbsp;(4)
                                                          </button>
                                                          <ul
                                                            className="dropdown-menu"
                                                            aria-labelledby="dropdownMenuButton1"
                                                          >
                                                            <li>
                                                              <span class="dropdown-item">
                                                                Action
                                                              </span>
                                                            </li>
                                                            <li>
                                                              <span class="dropdown-item">
                                                                Another action
                                                              </span>
                                                            </li>
                                                            <li>
                                                              <span class="dropdown-item">
                                                                Something else
                                                                here
                                                              </span>
                                                            </li>
                                                          </ul>
                                                        </div>
                                                      </div>
                                                      <div className="col">
                                                        <button
                                                          type="button"
                                                          className="btn btn-outline-info btn-fw col-12"
                                                        >
                                                          <i className="fs-20 mdi mdi-plus vsalign"></i>
                                                          &nbsp;Add Ons
                                                        </button>
                                                      </div>
                                                    </div>
  
                                                    <div className="row mb-2 varwdt">
                                                      <div className="col">
                                                        <input
                                                          type="text"
                                                          className="form-control"
                                                          placeholder="Thin Crust"
                                                          value="Thin Crust"
                                                        />
                                                      </div>
                                                      <div className="col">
                                                        <div className="dropdown">
                                                          <button
                                                            className="btn btn-outline-info btn-fw col-12 dropdown-toggle"
                                                            type="button"
                                                            id="dropdownMenuButton1"
                                                            data-bs-toggle="dropdown"
                                                            aria-expanded="false"
                                                          >
                                                            Add Ons&nbsp;(1)
                                                          </button>
                                                          <ul
                                                            className="dropdown-menu"
                                                            aria-labelledby="dropdownMenuButton1"
                                                          >
                                                            <li>
                                                              <span class="dropdown-item">
                                                                Action
                                                              </span>
                                                            </li>
                                                            <li>
                                                              <span class="dropdown-item">
                                                                Another action
                                                              </span>
                                                            </li>
                                                            <li>
                                                              <span class="dropdown-item">
                                                                Something else
                                                                here
                                                              </span>
                                                            </li>
                                                          </ul>
                                                        </div>
                                                      </div>
                                                      <div className="col">
                                                        <div className="dropdown">
                                                          <button
                                                            className="btn btn-outline-info btn-fw col-12 dropdown-toggle "
                                                            type="button"
                                                            id="dropdownMenuButton1"
                                                            data-bs-toggle="dropdown"
                                                            aria-expanded="false"
                                                          >
                                                            Drinks&nbsp;(4)
                                                          </button>
                                                          <ul
                                                            className="dropdown-menu"
                                                            aria-labelledby="dropdownMenuButton1"
                                                          >
                                                            <li>
                                                              <span class="dropdown-item">
                                                                Action
                                                              </span>
                                                            </li>
                                                            <li>
                                                              <span class="dropdown-item">
                                                                Another action
                                                              </span>
                                                            </li>
                                                            <li>
                                                              <span class="dropdown-item">
                                                                Something else
                                                                here
                                                              </span>
                                                            </li>
                                                          </ul>
                                                        </div>
                                                      </div>
                                                      <div className="col">
                                                        <button
                                                          type="button"
                                                          className="btn btn-outline-info btn-fw col-12"
                                                        >
                                                          <i className="fs-20 mdi mdi-plus vsalign"></i>
                                                          &nbsp;Add Ons
                                                        </button>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="accordion-item mb-3">
                                                <h2
                                                  className="accordion-header"
                                                  id="panelsStayOpen-headingOne"
                                                >
                                                  <button
                                                    className="accordion-button"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseOne"
                                                    aria-expanded="true"
                                                    aria-controls="panelsStayOpen-collapseOne"
                                                  >
                                                    <b>6 Inches </b>( 1 Variant)
                                                  </button>
                                                </h2>
                                                <div
                                                  id="panelsStayOpen-collapseOne"
                                                  className="accordion-collapse collapse show"
                                                  aria-labelledby="panelsStayOpen-headingOne"
                                                >
                                                  <div className="accordion-body">
                                                    <h6 className="mb-2">Type</h6>
  
                                                    <div className="row mb-2 varwdt">
                                                      <div className="col">
                                                        <input
                                                          type="text"
                                                          className="form-control"
                                                          placeholder="Chinese"
                                                          value="Chinese"
                                                        />
                                                      </div>
                                                      <div className="col">
                                                        <div className="dropdown">
                                                          <button
                                                            className="btn btn-outline-info btn-fw col-12 dropdown-toggle"
                                                            type="button"
                                                            id="dropdownMenuButton1"
                                                            data-bs-toggle="dropdown"
                                                            aria-expanded="false"
                                                          >
                                                            Add Ons&nbsp;(1)
                                                          </button>
                                                          <ul
                                                            className="dropdown-menu"
                                                            aria-labelledby="dropdownMenuButton1"
                                                          >
                                                            <li>
                                                              <span class="dropdown-item">
                                                                Action
                                                              </span>
                                                            </li>
                                                            <li>
                                                              <span class="dropdown-item">
                                                                Another action
                                                              </span>
                                                            </li>
                                                            <li>
                                                              <span class="dropdown-item">
                                                                Something else
                                                                here
                                                              </span>
                                                            </li>
                                                          </ul>
                                                        </div>
                                                      </div>
                                                      <div className="col">
                                                        <div className="dropdown">
                                                          <button
                                                            className="btn btn-outline-info btn-fw col-12 dropdown-toggle "
                                                            type="button"
                                                            id="dropdownMenuButton1"
                                                            data-bs-toggle="dropdown"
                                                            aria-expanded="false"
                                                          >
                                                            Drinks&nbsp;(4)
                                                          </button>
                                                          <ul
                                                            className="dropdown-menu"
                                                            aria-labelledby="dropdownMenuButton1"
                                                          >
                                                            <li>
                                                              <span class="dropdown-item">
                                                                Action
                                                              </span>
                                                            </li>
                                                            <li>
                                                              <span class="dropdown-item">
                                                                Another action
                                                              </span>
                                                            </li>
                                                            <li>
                                                              <span class="dropdown-item">
                                                                Something else
                                                                here
                                                              </span>
                                                            </li>
                                                          </ul>
                                                        </div>
                                                      </div>
                                                      <div className="col">
                                                        <button
                                                          type="button"
                                                          className="btn btn-outline-info btn-fw col-12"
                                                        >
                                                          <i className="fs-20 mdi mdi-plus vsalign"></i>
                                                          &nbsp;Add Ons
                                                        </button>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div> */}
                                      </div>
                                      <div className="col-md-1"></div>
                                      <div className="col-md-5">
                                        <button
                                          type="button"
                                          className="btn btn-outline-info btn-fw mb-4 col-12"
                                          onClick={
                                            handleAddOnNewGroupButtonClick
                                          }
                                        >
                                          <i className="fs-20 mdi mdi-plus vsalign"></i>
                                          &nbsp; Manage Add Ons Groups
                                        </button>

                                        {showAddOnForm && (
                                          <>
                                            <div className="card card-rounded mb-4">
                                              <div className="card-body bordercss">
                                                <div className="col-12 d-flex align-items-center justify-content-between mb-3">
                                                  <div className="col-10">
                                                    <div className="input-group mb-2">
                                                      <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Enter Add-on group name"
                                                        name="groupName"
                                                        value={
                                                          addOnNewFormData.groupName
                                                        }
                                                        onChange={
                                                          handleChangeAddOnNewFormData
                                                        }
                                                      />
                                                    </div>
                                                  </div>
                                                  <div className="col-2 text-right">
                                                    <i
                                                      className="fs-20 mdi mdi-archive text-danger hrddlt"
                                                      title="Delete"
                                                      style={{
                                                        cursor: "pointer",
                                                      }}
                                                      onClick={() => {
                                                        setShowAddOnForm(false);
                                                      }}
                                                    ></i>
                                                  </div>
                                                </div>
                                                <div className="row">
                                                  <div className="col-6">
                                                    <div className="input-group mb-2">
                                                      <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Min"
                                                        name="min"
                                                        value={
                                                          addOnNewFormData.min
                                                        }
                                                        onChange={
                                                          handleChangeAddOnNewFormData
                                                        }
                                                      />
                                                    </div>
                                                  </div>
                                                  <div className="col-6">
                                                    <div className="input-group mb-2">
                                                      <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Max"
                                                        name="max"
                                                        value={
                                                          addOnNewFormData.max
                                                        }
                                                        onChange={
                                                          handleChangeAddOnNewFormData
                                                        }
                                                      />
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="bordercss">
                                                  <h6>
                                                    Create items that you want
                                                    to add
                                                  </h6>
                                                  {addOnNewFormData &&
                                                    addOnNewFormData.items && (
                                                      <div>
                                                        {addOnNewFormData.items.map(
                                                          (item, index) => (
                                                            <div
                                                              key={index}
                                                              className="row mb-2"
                                                            >
                                                              <div className="col-6">
                                                                {item.name}
                                                              </div>
                                                              <div className="col-3">
                                                                {currencySymbol}
                                                                {isValidPrice(
                                                                  item.price
                                                                )}
                                                              </div>
                                                              <div className="col-3">
                                                                <i
                                                                  className="fs-20 mdi mdi-archive text-danger hrddlt"
                                                                  title="Delete"
                                                                  style={{
                                                                    cursor:
                                                                      "pointer",
                                                                  }}
                                                                  onClick={(
                                                                    e
                                                                  ) => {
                                                                    e.preventDefault();
                                                                    handleAddOnNewItemDeleteClick(
                                                                      index
                                                                    );
                                                                  }}
                                                                ></i>
                                                              </div>
                                                            </div>
                                                          )
                                                        )}
                                                      </div>
                                                    )}

                                                  <div className="row">
                                                    <div className="col-3">
                                                      <div className="form-group">
                                                        <select
                                                          className="form-select"
                                                          name="type"
                                                          value={
                                                            addOnNewItemData.type
                                                          }
                                                          onChange={
                                                            handleChangeAddOnNewItemData
                                                          }
                                                        >
                                                          <option value="">
                                                            Type
                                                          </option>
                                                          <option value="0">
                                                            Veg
                                                          </option>
                                                          <option value="1">
                                                            Non-Veg
                                                          </option>
                                                          <option value="2">
                                                            Egg
                                                          </option>
                                                          <option value="3">
                                                            Alcohol
                                                          </option>
                                                        </select>
                                                      </div>
                                                    </div>
                                                    <div className="col-4">
                                                      <div className="form-group">
                                                        <input
                                                          type="text"
                                                          className="form-control"
                                                          placeholder="Name"
                                                          name="name"
                                                          value={
                                                            addOnNewItemData.name
                                                          }
                                                          onChange={
                                                            handleChangeAddOnNewItemData
                                                          }
                                                        />
                                                      </div>
                                                    </div>
                                                    <div className="col-3">
                                                      <div className="form-group">
                                                        <input
                                                          type="text"
                                                          className="form-control"
                                                          placeholder="Price"
                                                          name="price"
                                                          value={
                                                            addOnNewItemData.price
                                                          }
                                                          onChange={
                                                            handleChangeAddOnNewItemData
                                                          }
                                                        />
                                                      </div>
                                                    </div>
                                                    <div className="col-2 p-0">
                                                      <button
                                                        className="btn btn-info btn-sm"
                                                        onClick={(e) => {
                                                          e.preventDefault();
                                                          handleAddOnNewItemAddClick();
                                                        }}
                                                      >
                                                        Add
                                                      </button>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div>
                                                  <button
                                                    type="button"
                                                    className="btn btn-outline-info btn-fw mt-2 col-12"
                                                    onClick={(e) => {
                                                      e.preventDefault();
                                                      handleAddOnNewSaveChangesClick();
                                                    }}
                                                  >
                                                    <i className="fs-20 mdi mdi-plus vsalign"></i>
                                                    &nbsp;Save Changes
                                                  </button>
                                                </div>
                                              </div>
                                            </div>
                                          </>
                                        )}

                                        {data && data.length > 0 && (
                                          <>
                                            <h5>Existing Add Ons</h5>
                                            <hr />
                                            {data &&
                                              data.map((item, index) => (
                                                <React.Fragment key={index}>
                                                  <div className="col-12 d-flex align-items-center justify-content-between">
                                                    <div className="col-8">
                                                      <h5>
                                                        {item.addon_group_name}{" "}
                                                        ({item.items.length})
                                                      </h5>
                                                    </div>
                                                    <div className="col-2">
                                                      <i
                                                        className="fs-20 mdi mdi-archive text-danger vsalign"
                                                        title="Delete"
                                                        style={{
                                                          cursor: "pointer",
                                                        }}
                                                        onClick={(e) =>
                                                          handleDeleteAddOnClick(
                                                            e,
                                                            item.fooders_addons_id
                                                          )
                                                        }
                                                      ></i>
                                                    </div>
                                                    <div className="col-2 text-right">
                                                      <i
                                                        className="fs-20 fa fa-plus text-primary vsalign"
                                                        style={{
                                                          cursor: "pointer",
                                                        }}
                                                        onClick={(e) => {
                                                          e.preventDefault();
                                                          handleAssignAddOnGroupPlusClick(
                                                            item.fooders_addons_id,
                                                            item.addon_group_name
                                                          );
                                                        }}
                                                      ></i>
                                                    </div>
                                                  </div>
                                                  {index !==
                                                    data.length - 1 && <hr />}
                                                </React.Fragment>
                                              ))}
                                          </>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className={
              isAaaignAddOnModal ? "modal fade show d-block" : "modal fade"
            }
            id="exampleModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content w-85">
                <div className="modal-header py-2">
                  <h5 className="modal-title" id="exampleModalLabel">
                    Assign Add Ons ({addOnGroupName})
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() => {
                      setIsAaaignAddOnModal(false);
                    }}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="card-body">
                  <h4>Select Varants to Assign Add On</h4>
                  {modifiedCombinationData &&
                    modifiedCombinationData.map((item, index) => (
                      <div className="row mt-2" key={index}>
                        <div className="col-12">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            checked={item.selected || false}
                            onChange={(e) =>
                              handleVariantCheckboxChange(e, item.id)
                            }
                          />
                          &nbsp;&nbsp;&nbsp;&nbsp;
                          <span className="cstnm">{item.name}</span>
                        </div>
                      </div>
                    ))}
                </div>
                <div className="modal-footer py-1">
                  <button
                    type="submit"
                    className="btn btn-primary me-2"
                    onClick={(e) => {
                      e.preventDefault();
                      handleAssignAddOnGroupSubmitClick();
                    }}
                  >
                    Submit
                  </button>
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-dismiss="modal"
                    onClick={() => {
                      setIsAaaignAddOnModal(false);
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>

          {showDeleteModal && (
            <DeleteModal
              handleModal={setShowDeleteModal}
              handleDelete={handleDeleteProductVariant}
            />
          )}

          {showPropertyDeleteModal && (
            <DeleteModal
              handleModal={setShowPropertyDeleteModal}
              handleDelete={handleDeleteProductProperty}
            />
          )}

          {showDeleteAddOnModal && (
            <DeleteModal
              handleModal={setShowDeleteAddOnModal}
              handleDelete={handleDeleteAddOn}
            />
          )}

          {showDeleteAssignAddOnModal && (
            <DeleteModal
              handleModal={setShowDeleteAssignAddOnModal}
              handleDelete={handleDeleteAssignAddOn}
            />
          )}

          <LoadingModal isLoading={isLoading} />

          <Notification />
        </>
      )}
    </>
  );
};

export default ManageVariants;
