import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import SideBar from "../other/SideBar";
import NavBar from "../other/NavBar";
import LoadingModal from "../../loader/LoadingModal";
import {
  EditCategoryMenuURL,
  GetEditCategoryMenuURL,
  ScriptId,
  ScriptSRC,
} from "../../assets/api/BaseURL";
import Notification from "../other/Notification";
import { useToast } from "../../../context/ToastContext";

const EditMenu = () => {
  const navigate = useNavigate();
  const { addToast } = useToast();
  const [token, setToken] = useState("");
  const [menuID, setMenuID] = useState("");
  const [toggleSideBar, setToggleSideBar] = useState(false);
  const [canvas, setCanvas] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    description: "",
  });

  const [tags, setTags] = useState([]);
  const [inputValue, setInputValue] = useState("");

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
    setError("");
    setSuccess("");
  };

  const handleInputKeyPress = (e) => {
    if (e.key === "Enter" && inputValue.trim() !== "") {
      e.preventDefault();
      setTags([...tags, inputValue.trim()]);
      setInputValue("");
      setError("");
      setSuccess("");
    }
  };

  const handleTagClick = (tagIndex) => {
    const newTags = [...tags];
    newTags.splice(tagIndex, 1);
    setTags(newTags);
    setError("");
    setSuccess("");
  };

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
    setError("");
    setSuccess("");
  };

  const handleToggleSidebar = (e) => {
    e.preventDefault();
    setToggleSideBar(!toggleSideBar);
  };

  const handleToggleOffCanvas = (e) => {
    e.preventDefault();
    setCanvas(!canvas);
  };

  const handleCategoryMenuClick = (e) => {
    e.preventDefault();
    navigate("/view_menus");
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setError("");
    setSuccess("");
  };

  const handleSaveMenu = async (e) => {
    e.preventDefault();

    if (formData.name === "") {
      setError("Menu name can not be empty");
    } else {
      const data = {
        id: menuID,
        name: formData.name,
        tags: tags.join(", "),
        description: formData.description,
        status: isChecked ? 1 : 0,
      };

      try {
        const response = await axios({
          method: "put",
          url: EditCategoryMenuURL,
          data: data,
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${token}`,
          },
        });

        if (response.status === 200 && response.data.message) {
          setError("");
          setSuccess(response.data.message);
        }
      } catch (error) {
        if (error.response && error.response.data.message) {
          setSuccess("");
          setError(error.response.data.message);
        } else {
          setError(error.message);
          setSuccess("");
        }
        console.log(error);
      }
    }
  };

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/");
    }
  }, [navigate]);

  useEffect(() => {
    setToken(localStorage.getItem("token"));
  }, [token]);

  useEffect(() => {
    const existingScript = document.getElementById(ScriptId);
    if (!existingScript && localStorage.getItem("userId")) {
      const userId = localStorage.getItem("userId");
      const script = document.createElement("script");
      script.src = ScriptSRC + userId.replace(/"/g, "");
      script.defer = true;
      script.id = ScriptId;
      document.head.appendChild(script);
    }
  }, []);

  useEffect(() => {
    setMenuID(localStorage.getItem("menuID"));
  }, [menuID]);

  useEffect(() => {
    if (token && menuID) {
      async function getData() {
        setIsLoading(true);
        try {
          const response = await axios.get(
            GetEditCategoryMenuURL + `?id=${menuID}`,
            {
              headers: { authorization: `Bearer ${token}` },
            }
          );

          if (response.status === 200 && response.data.data) {
            setFormData({
              name: response.data.data.name,
              description: response.data.data.description,
            });
            setTags(
              response.data.data.tags ? response.data.data.tags.split(", ") : []
            );
            setIsChecked(response.data.data.status === 1 ? true : false);
          }
        } catch (error) {
          if (error.response && error.response.data.message) {
            addToast("error", error.response.data.message);
          } else {
            addToast("error", error.message);
          }
          console.log(error);
        }
        setIsLoading(false);
      }
      getData();
    }
  }, [token, menuID, addToast]);

  useEffect(() => {
    if (error || success) {
      window.scrollTo(0, 0);
    }
  }, [error, success]);

  return (
    <>
      {token && (
        <>
          <div
            className={
              toggleSideBar
                ? "container-scroller sidebar-icon-only"
                : "container-scroller"
            }
          >
            <nav className="navbar default-layout col-lg-12 col-12 p-0 fixed-top d-flex align-items-top flex-row">
              <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-start">
                <Link className="navbar-brand brand-logo-mini" to="/live_orders">
                  <img src="images/mini-kr-logo.svg" alt="logo" />
                </Link>
                <div className="me-1">
                  <button
                    className={
                      toggleSideBar
                        ? "navbar-toggler navbar-toggler align-self-center"
                        : "navbar-toggler navbar-toggler align-self-center text-white"
                    }
                    type="button"
                    onClick={handleToggleSidebar}
                  >
                    <span className="icon-menu"></span>
                  </button>
                </div>
                <div>
                  <Link to="/live_orders">
                    <span className="navbar-brand brand-logo">
                      <img src="images/Khateraho_logo_white.png" alt="logo" />
                    </span>
                  </Link>
                </div>
              </div>
              <NavBar handleToggle={handleToggleOffCanvas} />
            </nav>
            <div className="container-fluid page-body-wrapper px-0">
              <SideBar active="view_menus" toggleCanvas={toggleSideBar} />

              <div className="main-panel">
                <div className="content-wrapper">
                  <div className="row">
                    <div className="col-sm-12">
                      <h3 className="ukhd mb-3">Category</h3>
                      <div className="row flex-grow">
                        <div className="col-12 grid-margin stretch-card">
                          <div className="card card-rounded">
                            <div className="card-body">
                              <div className="d-flex justify-content-between align-items-start mb-2">
                                <div className="vsalign">
                                  <h4 className="card-title card-title-dash">
                                    Edit Category
                                  </h4>
                                </div>
                                <div className="vsalign">
                                  <span
                                    className="btn btn-danger mb-0 me-0"
                                    onClick={handleCategoryMenuClick}
                                  >
                                    <i className="menu-icon mdi mdi-arrow-left vsalign"></i>
                                    Go Back
                                  </span>
                                </div>
                              </div>
                              <form className="forms-sample">
                                {error && (
                                  <div className="d-flex justify-content-between align-items-start alert alert-danger">
                                    <div className="vsalign">{error}</div>
                                    <div className="vsalign">
                                      <span
                                        className=".h3 text-muted"
                                        style={{ cursor: "pointer" }}
                                        onClick={() => setError("")}
                                      >
                                        x
                                      </span>
                                    </div>
                                  </div>
                                )}
                                {success && (
                                  <div className="d-flex justify-content-between align-items-start alert alert-success">
                                    <div className="vsalign">{success}</div>
                                    <div className="vsalign">
                                      <span
                                        className=".h3 text-muted"
                                        style={{ cursor: "pointer" }}
                                        onClick={() => setSuccess("")}
                                      >
                                        x
                                      </span>
                                    </div>
                                  </div>
                                )}
                                <div className="form-group">
                                  <label>
                                    Menu Name
                                    <span className="text-danger">*</span>
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Menu Name"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleChange}
                                  />
                                </div>
                                <div className="form-group">
                                  <label>Tags</label>
                                  <div id="tags">
                                    {tags.map((tag, index) => (
                                      <span
                                        key={index}
                                        onClick={() => handleTagClick(index)}
                                      >
                                        {tag}
                                      </span>
                                    ))}
                                    <input
                                      type="text"
                                      className=""
                                      value={inputValue}
                                      placeholder="Add a tag & hit Enter"
                                      onChange={handleInputChange}
                                      onKeyDown={handleInputKeyPress}
                                    />
                                  </div>
                                </div>
                                <div className="form-group">
                                  <label>Description</label>
                                  <textarea
                                    className="form-control"
                                    name="description"
                                    placeholder="Enter Description"
                                    value={formData.description}
                                    onChange={handleChange}
                                  ></textarea>
                                </div>
                                <div className="form-group togglecss">
                                  <label>Status</label>
                                  <div
                                    className={`button r ${isChecked ? "active" : ""
                                      }`}
                                    id="button-1"
                                  >
                                    <input
                                      type="checkbox"
                                      className="checkbox"
                                      checked={isChecked}
                                      onChange={handleCheckboxChange}
                                    />
                                    <div className="knobs"></div>
                                    <div className="layer"></div>
                                  </div>
                                </div>
                                <button
                                  type="submit"
                                  className="btn btn-primary me-2"
                                  onClick={handleSaveMenu}
                                >
                                  Submit
                                </button>
                                <button
                                  className="btn btn btn-secondary"
                                  onClick={handleCategoryMenuClick}
                                >
                                  Cancel
                                </button>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <LoadingModal isLoading={isLoading} />

          <Notification />
        </>
      )}
    </>
  );
};

export default EditMenu;
