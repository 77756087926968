import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import SideBar from "../../other/SideBar";
import NavBar from "../../other/NavBar";
import LoadingModal from "../../../loader/LoadingModal";
import {
  GetProductAndVariantsURL,
  GetSingleRecipeURL,
  UpdateRecipeItemURL,
  ScriptId,
  ScriptSRC,
  // UpdateRecipeStatusURL,
  AddRecipeItemURL,
  DeleteRecipeItemURL,
} from "../../../assets/api/BaseURL";
import Notification from "../../other/Notification";
import { useToast } from "../../../../context/ToastContext";
import DeleteModal from "../../../deleteModal/DeleteModal";
import Select from "react-select";

const areaOptions = [
  { value: "dine_in", label: "Dine in" },
  { value: "delivery", label: "Delivery" },
  { value: "take_away", label: "Take away" },
];

const EditRecipe = () => {
  const navigate = useNavigate();
  const { addToast } = useToast();
  const [token, setToken] = useState("");
  const [toggleSideBar, setToggleSideBar] = useState(false);
  const [canvas, setCanvas] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [recipeID, setRecipeID] = useState("");
  const [flag, setFlag] = useState(false);
  const [recipeData, setRecipeData] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [recipeItems, setRecipeItems] = useState([]);
  const [rawMaterialsOptions, setRawMaterialsOptions] = useState([]);
  // const [measurementOptions, setMeasurementOptions] = useState([]);
  const [recipeItem, setRecipeItem] = useState({
    raw_material_id: "",
    raw_material_name: "",
    quantity: "",
    unit: "",
    unit_name: "",
    area: [],
  });

  const handleAreaChange = (selected) => {
    setRecipeItem((prevData) => ({
      ...prevData,
      area: selected,
    }));
    setError("");
    setSuccess("");
  };

  const handleToggleSidebar = (e) => {
    e.preventDefault();
    setToggleSideBar(!toggleSideBar);
  };

  const handleToggleOffCanvas = (e) => {
    e.preventDefault();
    setCanvas(!canvas);
  };

  const handleBackClick = (e) => {
    e.preventDefault();
    navigate("/recipes");
  };

  const handleItemChange = (e) => {
    const { name, value } = e.target;

    if (name === "raw_material_id") {
      const selectedRawMaterial = rawMaterialsOptions.find(
        (item) => item.id === parseInt(value)
      );
      const updatedUnit = selectedRawMaterial
        ? selectedRawMaterial.consumption_unit
        : "";
      const updatedUnitName = selectedRawMaterial
        ? selectedRawMaterial.unit_name
        : "";
      const materialName = selectedRawMaterial
        ? selectedRawMaterial.material_name
        : "";
      setRecipeItem((prevData) => ({
        ...prevData,
        [name]: value,
        unit: updatedUnit,
        raw_material_name: materialName,
        unit_name: updatedUnitName,
      }));
    } else {
      setRecipeItem((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }

    setError("");
    setSuccess("");
  };

  const handleRecipeItemsChange = (item, property, value) => {
    const updatedRecipeItems = [...recipeItems];

    const itemIndex = updatedRecipeItems.findIndex((i) => i.id === item.id);

    if (property === "raw_material_id") {
      const selectedRawMaterial = rawMaterialsOptions.find(
        (rawMaterial) => rawMaterial.id === parseInt(value)
      );
      const updatedUnit = selectedRawMaterial
        ? selectedRawMaterial.consumption_unit
        : "";
      const updatedUnitName = selectedRawMaterial
        ? selectedRawMaterial.unit_name
        : "";
      const materialName = selectedRawMaterial
        ? selectedRawMaterial.material_name
        : "";
      updatedRecipeItems[itemIndex][property] = value;
      updatedRecipeItems[itemIndex]["unit"] = updatedUnit;
      updatedRecipeItems[itemIndex]["material_name"] = materialName;
      updatedRecipeItems[itemIndex]["unit_name"] = updatedUnitName;
    } else {
      updatedRecipeItems[itemIndex][property] = value;
    }

    setRecipeItems(updatedRecipeItems);
  };

  const handleDeleteRecipeItemClick = (e, id) => {
    e.preventDefault();
    if (recipeData && !(recipeData.recipes_items.length > 1)) {
      addToast("error", "Please add more items to delete this item!");
    } else {
      setDeleteId([id]);
      setShowDeleteModal(!showDeleteModal);
    }
  };

  const handleDeleteRecipeItem = async () => {
    try {
      const response = await axios({
        method: "delete",
        url: DeleteRecipeItemURL,
        data: deleteId,
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 200 && response.data.message) {
        setFlag(!flag);
        setShowDeleteModal(!showDeleteModal);
        addToast("success", response.data.message);
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        addToast("error", error.response.data.message);
      } else {
        addToast("error", error.message);
      }
      console.log(error);
    }
  };

  const isValidNumber = (value) => {
    return !isNaN(parseFloat(value)) && isFinite(value);
  };

  const handleAddItemClick = async (e) => {
    e.preventDefault();

    if (recipeItem.raw_material_id === "") {
      setError("Please select raw material");
      setSuccess("");
    } else if (
      recipeItems.some(
        (item) => item.raw_material_id === recipeItem.raw_material_id
      )
    ) {
      setError("An item with the same raw material has already been added");
      setSuccess("");
    } else if (recipeItem.quantity === "") {
      setError("Quantity can not be empty");
      setSuccess("");
    } else if (!isValidNumber(recipeItem.quantity)) {
      setError("Please enter valid Quantity");
      setSuccess("");
    } else if (recipeItem.unit === "") {
      setError("Please select measurement unit");
      setSuccess("");
    } else {
      const data = {
        dish_recipes_id: recipeData.id,
        raw_material_id: recipeItem.raw_material_id,
        quantity: recipeItem.quantity,
        unit: recipeItem.unit,
      };

      try {
        const response = await axios({
          method: "post",
          url: AddRecipeItemURL,
          data: data,
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${token}`,
          },
        });

        if (response.status === 200 && response.data.message) {
          setError("");
          setSuccess(response.data.message);
          setRecipeItem({
            raw_material_id: "",
            raw_material_name: "",
            quantity: "",
            unit: "",
            unit_name: "",
            area: [],
          });
          setFlag(!flag);
        }
      } catch (error) {
        if (error.response && error.response.data.message) {
          setError(error.response.data.message);
          setSuccess("");
        } else {
          setError(error.message);
          setSuccess("");
        }
        console.log(error);
      }
    }
  };

  const handleUpdateItemClick = async (item) => {
    if (item.raw_material_id === "") {
      setError("Please select raw material");
      setSuccess("");
    } else if (item.quantity === "") {
      setError("Quantity can not be empty");
      setSuccess("");
    } else if (!isValidNumber(item.quantity)) {
      setError("Please enter valid Quantity");
      setSuccess("");
    } else if (item.unit === "") {
      setError("Please select measurement unit");
      setSuccess("");
    } else {
      setIsLoading(true);
      try {
        const response = await axios({
          method: "put",
          url: UpdateRecipeItemURL,
          data: item,
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${token}`,
          },
        });

        if (response.status === 200 && response.data.message) {
          setError("");
          setSuccess(response.data.message);
          setFlag(!flag);
        }
      } catch (error) {
        if (error.response && error.response.data.message) {
          setError(error.response.data.message);
          setSuccess("");
        } else {
          setError(error.message);
          setSuccess("");
        }
        console.log(error);
      }
      setIsLoading(false);
    }
  };

  // const handleStatusToggle = async (e) => {
  //   setIsLoading(true);

  //   const dataU = { id: recipeID, status: e.target.checked ? 1 : 0 };

  //   try {
  //     const response = await axios({
  //       method: "put",
  //       url: UpdateRecipeStatusURL,
  //       data: dataU,
  //       headers: {
  //         "Content-Type": "application/json",
  //         authorization: `Bearer ${token}`,
  //       },
  //     });

  //     if (response.status === 200) {
  //       setFlag(!flag);
  //     }
  //   } catch (error) {
  //     if (error.response && error.response.data.message) {
  //       addToast("error", error.response.data.message);
  //     } else {
  //       addToast("error", error.message);
  //     }
  //     console.log(error);
  //   }
  //   setIsLoading(false);
  // };

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/");
    }
  }, [navigate]);

  useEffect(() => {
    setToken(localStorage.getItem("token"));
  }, [token]);

  useEffect(() => {
    const existingScript = document.getElementById(ScriptId);
    if (!existingScript && localStorage.getItem("userId")) {
      const userId = localStorage.getItem("userId");
      const script = document.createElement("script");
      script.src = ScriptSRC + userId.replace(/"/g, "");
      script.defer = true;
      script.id = ScriptId;
      document.head.appendChild(script);
    }
  }, []);

  useEffect(() => {
    setRecipeID(localStorage.getItem("recipeID"));
  }, [recipeID]);

  useEffect(() => {
    if (token) {
      async function getData() {
        try {
          const response = await axios.get(GetProductAndVariantsURL, {
            headers: { authorization: `Bearer ${token}` },
          });

          if (response.status === 200 && response.data) {
            setRawMaterialsOptions(
              response.data.rawMaterialsList
                ? response.data.rawMaterialsList
                : []
            );
            // setMeasurementOptions(
            //   response.data.measurement_unit
            //     ? response.data.measurement_unit
            //     : []
            // );
          }
        } catch (error) {
          if (error.response && error.response.data.message) {
            addToast("error", error.response.data.message);
          } else {
            addToast("error", error.message);
          }
          console.log(error);
        }
      }
      getData();
    }
  }, [token, addToast]);

  useEffect(() => {
    if (token && recipeID) {
      async function getData() {
        setIsLoading(true);
        try {
          const response = await axios.get(
            GetSingleRecipeURL + `?id=${recipeID}`,
            {
              headers: { authorization: `Bearer ${token}` },
            }
          );

          if (response.status === 200 && response.data.data) {
            setRecipeData(response.data.data);
            if (response.data.data.recipes_items) {
              setRecipeItems(response.data.data.recipes_items);
            }
          }
        } catch (error) {
          if (error.response && error.response.data.message) {
            addToast("error", error.response.data.message);
          } else {
            addToast("error", error.message);
          }
          console.log(error);
        }
        setIsLoading(false);
      }
      getData();
    }
  }, [token, flag, recipeID, addToast]);

  useEffect(() => {
    if (error || success) {
      window.scrollTo(0, 0);
    }
  }, [error, success]);

  return (
    <>
      {token && (
        <>
          <div
            className={
              toggleSideBar
                ? "container-scroller sidebar-icon-only"
                : "container-scroller"
            }
          >
            <nav className="navbar default-layout col-lg-12 col-12 p-0 fixed-top d-flex align-items-top flex-row">
              <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-start">
                <Link className="navbar-brand brand-logo-mini" to="/live_orders">
                  <img src="images/mini-kr-logo.svg" alt="logo" />
                </Link>
                <div className="me-1">
                  <button
                    className={
                      toggleSideBar
                        ? "navbar-toggler navbar-toggler align-self-center"
                        : "navbar-toggler navbar-toggler align-self-center text-white"
                    }
                    type="button"
                    onClick={handleToggleSidebar}
                  >
                    <span className="icon-menu"></span>
                  </button>
                </div>
                <div>
                  <Link to="/live_orders">
                    <span className="navbar-brand brand-logo">
                      <img src="images/Khateraho_logo_white.png" alt="logo" />
                    </span>
                  </Link>
                </div>
              </div>
              <NavBar handleToggle={handleToggleOffCanvas} />
            </nav>
            <div className="container-fluid page-body-wrapper px-0">
              <SideBar active="recipes" toggleCanvas={toggleSideBar} />

              <div className="main-panel">
                <div className="content-wrapper">
                  <div className="row">
                    <div className="col-sm-12">
                      <h3 className="ukhd mb-3">Recipes</h3>
                      <div className="row flex-grow">
                        <div className="col-12 grid-margin stretch-card">
                          <div className="card card-rounded">
                            <div className="card-body">
                              <div className="d-flex justify-content-between align-items-start mb-2">
                                <div className="vsalign">
                                  <h4 className="card-title card-title-dash">
                                    Edit Recipe
                                  </h4>
                                </div>
                                <div className="vsalign">
                                  <span
                                    className="btn btn-danger mb-0 me-0"
                                    onClick={handleBackClick}
                                  >
                                    <i className="menu-icon mdi mdi-arrow-left vsalign"></i>
                                    Go Back
                                  </span>
                                </div>
                              </div>
                              <form className="forms-sample">
                                {error && (
                                  <div className="d-flex justify-content-between align-items-start alert alert-danger">
                                    <div className="vsalign">{error}</div>
                                    <div className="vsalign">
                                      <span
                                        className=".h3 text-muted"
                                        style={{ cursor: "pointer" }}
                                        onClick={() => setError("")}
                                      >
                                        x
                                      </span>
                                    </div>
                                  </div>
                                )}
                                {success && (
                                  <div className="d-flex justify-content-between align-items-start alert alert-success">
                                    <div className="vsalign">{success}</div>
                                    <div className="vsalign">
                                      <span
                                        className=".h3 text-muted"
                                        style={{ cursor: "pointer" }}
                                        onClick={() => setSuccess("")}
                                      >
                                        x
                                      </span>
                                    </div>
                                  </div>
                                )}

                                <div className="row">
                                  <div className="col-6">
                                    <div className="form-group">
                                      <label>Product Name</label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        value={recipeData.name || ""}
                                        readOnly={true}
                                      />
                                    </div>
                                  </div>

                                  {recipeData && recipeData.variant_name && (
                                    <div className="col-6">
                                      <div className="form-group">
                                        <label>Variant Name</label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          value={recipeData.variant_name || ""}
                                          readOnly={true}
                                        />
                                      </div>
                                    </div>
                                  )}
                                </div>

                                {recipeItems.map((item) => (
                                  <div key={item.id} className="row">
                                    <div className="col-lg-2">
                                      <div className="form-group">
                                        <select
                                          className="form-select"
                                          name="raw_material_id"
                                          value={item.raw_material_id}
                                          onChange={(e) =>
                                            handleRecipeItemsChange(
                                              item,
                                              "raw_material_id",
                                              e.target.value
                                            )
                                          }
                                        >
                                          <option value="">
                                            Select Raw Material
                                          </option>
                                          {rawMaterialsOptions.map((item) => (
                                            <option
                                              key={item.id}
                                              value={item.id}
                                            >
                                              {item.material_name}
                                            </option>
                                          ))}
                                        </select>
                                      </div>
                                    </div>
                                    <div className="col-lg-2">
                                      <div className="form-group">
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="Enter Quantity"
                                          name="quantity"
                                          value={item.quantity}
                                          onChange={(e) =>
                                            handleRecipeItemsChange(
                                              item,
                                              "quantity",
                                              e.target.value
                                            )
                                          }
                                        />
                                      </div>
                                    </div>
                                    <div className="col-lg-2 ">
                                      {/* <div className="form-group">
                                        <select
                                          className="form-select"
                                          name="unit"
                                          value={item.unit}
                                          readOnly={true}
                                          // onChange={(e) =>
                                          //   handleRecipeItemsChange(
                                          //     item,
                                          //     "unit",
                                          //     e.target.value
                                          //   )
                                          // }
                                        >
                                          <option value="">
                                            Select Measurement Unit
                                          </option>
                                          {measurementOptions.map((item) => (
                                            <option
                                              key={item.id}
                                              value={item.id}
                                            >
                                              {item.unit_name}
                                            </option>
                                          ))}
                                        </select>
                                      </div> */}
                                      <div className="form-group">
                                        <input
                                          type="text"
                                          className="form-control"
                                          value={item.unit_name}
                                          readOnly={true}
                                        />
                                      </div>
                                    </div>
                                    <div className="col-lg-3">
                                      <div className="form-group">
                                        <Select
                                          value={item.area || []}
                                          options={areaOptions}
                                          placeholder="Select Area"
                                          isMulti
                                          onChange={(selectedAreas) =>
                                            handleRecipeItemsChange(
                                              item,
                                              "area",
                                              selectedAreas
                                            )
                                          }
                                        />
                                      </div>
                                    </div>
                                    <div className="col-lg-3">
                                      <button
                                        type="submit"
                                        className="btn btn-primary me-2 btn-sm btnMarginTop0 mb-2"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          handleUpdateItemClick(item);
                                        }}
                                      >
                                        {/* Update */}
                                        <i className="menu-icon mdi mdi-content-save-edit-outline iconalg"></i>
                                      </button>
                                      <button
                                        className="btn btn btn-danger btn-sm btnMarginTop0 mb-2"
                                        onClick={(e) =>
                                          handleDeleteRecipeItemClick(
                                            e,
                                            item.id
                                          )
                                        }
                                      >
                                        {/* Delete */}
                                        <i className="menu-icon mdi mdi-archive iconalg"></i>
                                      </button>
                                    </div>
                                  </div>
                                ))}

                                <div className="row">
                                  <div className="col-lg-2 col-md-2">
                                    <div className="form-group">
                                      <select
                                        className="form-select"
                                        name="raw_material_id"
                                        value={recipeItem.raw_material_id}
                                        onChange={handleItemChange}
                                      >
                                        <option value="">
                                          Select Raw Material
                                        </option>
                                        {rawMaterialsOptions.map((item) => (
                                          <option key={item.id} value={item.id}>
                                            {item.material_name}
                                          </option>
                                        ))}
                                      </select>
                                    </div>
                                  </div>
                                  <div className="col-lg-2 col-md-2">
                                    <div className="form-group">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Quantity"
                                        name="quantity"
                                        value={recipeItem.quantity}
                                        onChange={handleItemChange}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-lg-2 col-md-2">
                                    {/* <div className="form-group">
                                      <select
                                        className="form-select"
                                        name="unit"
                                        value={recipeItem.unit}
                                        readOnly={true}
                                        // onChange={handleItemChange}
                                      >
                                        <option value="">
                                          Select Measurement Unit
                                        </option>
                                        {measurementOptions.map((item) => (
                                          <option key={item.id} value={item.id}>
                                            {item.unit_name}
                                          </option>
                                        ))}
                                      </select>
                                    </div> */}
                                    <div className="form-group">
                                      <input
                                        type="text"
                                        className="form-control"
                                        value={recipeItem.unit_name}
                                        readOnly={true}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-lg-4 col-md-4">
                                    <div className="form-group">
                                      <Select
                                        value={recipeItem.area}
                                        onChange={handleAreaChange}
                                        options={areaOptions}
                                        placeholder="Select Area"
                                        isMulti
                                      />
                                    </div>
                                  </div>
                                  <div className="col-lg-2 col-md-2">
                                    <button
                                      className="btn btn-info btn-sm btnMarginTop0"
                                      onClick={handleAddItemClick}
                                    >
                                      <i className="menu-icon mdi mdi-plus iconalg"></i>
                                      Add
                                    </button>
                                  </div>
                                </div>
                                {/* <div className="row">
                                  <div className="col-6">
                                    <div className="form-group togglecss">
                                      <label>Status</label>
                                      <div className="button r" id="button-1">
                                        <input
                                          type="checkbox"
                                          className="checkbox"
                                          name="status"
                                          checked={recipeData.status === 1}
                                          onChange={(e) =>
                                            handleStatusToggle(e)
                                          }
                                        />
                                        <div className="knobs"></div>
                                        <div className="layer"></div>
                                      </div>
                                    </div>
                                  </div>
                                </div> */}

                                {/* <button
                                  type="submit"
                                  className="btn btn-primary me-2"
                                  onClick={handleSaveRecipe}
                                >
                                  Submit
                                </button>
                                <button
                                  className="btn btn btn-secondary"
                                  onClick={handleBackClick}
                                >
                                  Cancel
                                </button> */}
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {showDeleteModal && (
            <DeleteModal
              handleDelete={handleDeleteRecipeItem}
              handleModal={setShowDeleteModal}
            />
          )}

          <LoadingModal isLoading={isLoading} />

          <Notification />
        </>
      )}
    </>
  );
};

export default EditRecipe;
